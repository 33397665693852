import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
	AddCircleOutline,
} from '@material-ui/icons';
import { Button, TextField, Checkbox } from '@material-ui/core';
import dashFetch from '../utils/dashFetch';
import history from '../history';
import { withRouter } from 'react-router-dom';

import ReactTable from "react-table";
import "react-table/react-table.css";

const styles = (theme) => ({
    root: {
    },
	addRequestIcon: {
		float: 'left',
		marginTop: 5,
		marginRight: 5,
		width: '20px',
		height: '20px'
	},
	addRequestButton: {
		float: 'right',
		marginLeft: 20,
		marginRight: 20,
		marginTop: 10,
		marginBottom: 10,
		width: 200,
		height: 45
	},
})

const RequestListing = (props) => {
	const { classes } = props;

	const [requests, setRequests] = React.useState([])

	useEffect(() => {
		async function fetchdata() {
			let data = await dashFetch(`${global.dashServerUrl}/dashboard/api/v1/request/getRequestList`, {}, true);
			let requests = formatRequests(data.json.requests);
			console.log(JSON.stringify(requests))
			setRequests(requests);
		}
        fetchdata();
        global.set({headerTitle: 'REQUEST LISTING'})
	}, []);


    const formatRequests = (requestList) => {
        return requestList.map((request) => {
			return {
                id: request._id,
                restaurantGroup: request.data && request.data.restaurantGroup && request.data.restaurantGroup.name,
                type: request.type.name,
                status: request.status,
                assignedTo: request.assignedTo && request.assignedTo.firstName + ' ' +request.assignedTo.lastName,
                createdAt: request.createdAt && new Date(request.createdAt).toLocaleString()
                // installCode: request.restaurant && request.restaurant.installCode,
			};
		});
	};

	const addRequest = async () => {
		navigateToRequest(null) // pass null to creat new restaurant
	};

	const navigateToRequest = async (id) => {
		console.log("NAVIGATE TO LISDTING " + id)
		// global.selectedRestaurantId = id
		let path = (id) ? id : 'new'
        history.push(`/restaurantOnboarding/?req=${path}`)
	}

	return (
		<div  className={classes.listing}>
			<Grid container>
                <Paper style={{ width: '100%' }} className={classes.rootTable}>
                    <div>
                        <Grid container>
                            <Grid item lg={9}></Grid>
                            <Grid item lg={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.addRequestButton}
                                    onClick={addRequest}
                                >
                                    <div>
                                        <AddCircleOutline className={classes.addRequestIcon} />
                                        <Typography variant="subtitle1" style={{ color: 'white', width: 160 }}>
                                            Add Request
                                        </Typography>
                                    </div>
                                </Button>
                            </Grid>
                        </Grid>
                        <ReactTable
                            data={requests}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
                            }
                            defaultSorted={[
                                {
                                    id: "createdAt",
                                    desc: true
                                }
                            ]}
                            columns={[
                                {
                                    Header: "Requests",
                                    columns: [
                                        {
                                            Header: "Site Group",
                                            accessor: "restaurantGroup"
                                        },
                                        {
                                            Header: "Request Type",
                                            accessor: "type"
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "status"
                                        },
                                        {
                                            Header: "Assinged To",
                                            accessor: "assignedTo"
                                        },
                                        {
                                            Header: "Created At",
                                            accessor: "createdAt"
                                        }
                                    ]
                                },
                            ]}
                            getTrProps={(state, rowInfo) => {
                                if (rowInfo && rowInfo.row) {
                                    // console.log('TYLER ' + JSON.stringify(rowInfo))
                                    // console.log('TYLER 1 ' + JSON.stringify(rowInfo.row))
                                    return {
                                        onClick: (e) => {
                                            navigateToRequest(rowInfo.row._original.id)
                                        },
                                        // style: {
                                        //     background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                                        //     color: rowInfo.index === this.state.selected ? 'white' : 'black'
                                        // }
                                    }
                                } else {
                                    return {}
                                }
                            }}
                            defaultPageSize={15}
                            className="-striped -highlight"
                        />
                        <br />
                    </div>
                </Paper>
                <div style={{ textAlign: "center" }}>
                    <em>Tip: Hold shift when sorting to multi-sort!</em>
                </div>;
            </Grid>
        </div>
	);
};

RequestListing.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withRouter(withStyles(styles, { withTheme: true })(RequestListing));