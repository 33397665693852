import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Paper, Drawer, Zoom, TableHead, TableBody, TableRow, TableCell, Table, TableContainer, TablePagination, CircularProgress, LinearProgress, Popper, Button, ClickAwayListener, Menu, Slide } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import DFlag from '../components/dash/DFlag'
import { Box } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import theme from '../theme'
import DTableHead from '../components/dash/DTableHead'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import log from 'loglevel'
import _ from 'lodash';
import SiteAdmin from '../components/dash/SiteAdmin'
import GlobalSearchFilter from './components/GlobalSearchFilter';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';


import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {
    },
    scrolling: {
        overflow: 'auto',
        height: 'auto'
    },
    payments: {
        paddingLeft: 5,
        paddingRight: 5,
        maxHeight: 'calc(100% - 3em)',
    },
    paper: {
        // paddingRight:8,
        // paddingleft:8,
        // margin: 8,
        // minHeight: '100%',
        // maxHeight: '100%',
        width:'100%',
        height:'100%'
    },
    iconWrapper: {
        height: '2.7em', 
        display: 'flex',
        alignItems: 'center' 
    }
}))


export default (props) => {
    const classes = useStyles();
    let [restaurants] = global.get('restaurants')
    let [restaurant] = global.get('restaurant')
    let [filteredRestaurants, setFilteredRestaurants] = useState(restaurants)
    let [dialogContent, setDialogContent] = useState()
    let [selectedSite, setSelectedSite] = useState()
    let [loading, setLoading] = useState(true)
    let [order, setOrder] = useState('asc');
    let [orderBy, setOrderBy] = useState('name');


    let smallScreen = useMediaQuery(theme().breakpoints.down('sm'));
    let [restaurantSearchFilter] = global.get('restaurantSearchFilter', '')

    let [filters, setFilters] = useState({status: [], appName: []})
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    
    const closeContent = (reload = false) => {
        setDialogContent(null)
        if (reload == true) {
            fetchData()
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
    
    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    // TODO possibly remove columns for agent view: appName, Agent

    const headCells = [
        { id: 'name', align: 'left', label: 'Site Name' },
        { id: 'status', align: 'left', label: 'Status' },
        { id: 'appName', align: 'left', label: 'App' },
        { id: 'posType', align: 'left', label: 'Pos Type' },
        { id: 'groupName', align: 'left', label: 'Group Name' },
        { id: 'cityState', align: 'left', label: 'City' },
        { id: 'externalNotes', align: 'left', label: 'Notes' },
        { id: 'agentName', align: 'left', label: 'Agent' },
        { id: 'primaryContactAccountName', align: 'left', label: 'Primary Contact' },
        { id: 'createdAt', align: 'left', label: 'Created On' },
        { id: 'primaryContactAccountLastLoginTime', align: 'left', label: 'Primary Contact Last Login Time' },
    ];

    

    const fetchData = async () => {
        setPage(0)
        //refreshrestaurants
        global.set({refreshRestaurants:true})
        
    }
    


    const filterData = (restaurantSearchFilter) => {
        let fr = restaurants
        setLoading(true)
        if (fr) {
            for (let field in filters) {
                if (filters[field] && filters[field].length > 0) {
                    fr = fr.filter(r => filters[field].includes(r[field]) )
                }
            }
        }
        if (!restaurantSearchFilter) {
            restaurantSearchFilter = ''
            // setFilteredRestaurants(restaurants)
            setPage(0)
        } else {


            const fields = restaurantSearchFilter.toUpperCase().replace('.', '').split('|')
            if (fr) {
                for (let field of fields) {
                    fr = fr.filter(restaurant => {
                        return (
                            (restaurant.name && restaurant.name.toString().toUpperCase().includes(field)) 
                            ||  (restaurant.getVal('restaurantGroup.name') && restaurant.restaurantGroup.name.toString().toUpperCase().includes(field)) 
                            ||  (restaurant.getVal('status') && restaurant.status.toString().toUpperCase().includes(field)) 
                            ||  (restaurant.getVal('locationInfo.addressLine1') && restaurant.locationInfo.addressLine1.toString().toUpperCase().includes(field)) 
                            ||  (restaurant.getVal('agent.fullName') && restaurant.agent.fullName.toString().toUpperCase().includes(field)) 
                            ||  (restaurant.getVal('primaryContactAccount.fullName') && restaurant.primaryContactAccount.fullName.toString().toUpperCase().includes(field))
                            ||  (restaurant.getVal('externalNotes') && restaurant.externalNotes.toString().toUpperCase().includes(field))
                            ||  (restaurant.getVal('posType') && restaurant.posType.toString().toUpperCase().includes(field))
                            ||  (restaurant.getVal('appName') && restaurant.appName.toString().toUpperCase().includes(field))
                            ||  (restaurant.getVal('locationInfo.city') && restaurant.locationInfo.city.toString().toUpperCase().includes(field))
                            ||  (restaurant.getVal('locationInfo.state') && restaurant.locationInfo.state.toString().toUpperCase().includes(field))
                            ||  (field === 'NEVER' && !restaurant.getVal('primaryContactAccount.lastLoginTime'))
                        )
                    })
                }                
            }
        }
        fr = fr && fr.map(r=> {
            let extNotes = r.getVal('externalNotes', '').indexOf('\n') > -1 ? r.getVal('externalNotes', '').substring(0, r.getVal('externalNotes', '').indexOf('\n')) : r.getVal('externalNotes', '')
            let city =  r.getVal('locationInfo.city','').toProperCase()         // to handle sorting lowercase city
            let citySt = city + r.getVal('locationInfo.state', '')              // neither or one
            if (city.length && r.getVal('locationInfo.state', '').length) {
                citySt = city + ", " + r.getVal('locationInfo.state', '')       // both 
            }
            let res = {
                ...r,
                groupName: r.getVal('restaurantGroup.name') ? r.getVal('restaurantGroup.name').toString() : '',
                agentName: r.getVal('agent.fullName') ? r.getVal('agent.fullName') : '',
                externalNotes: extNotes,
                posType: r.getVal('posType.posType', ''),
                primaryContactAccountName: r.getVal('primaryContactAccount.fullName') ? r.getVal('primaryContactAccount.fullName') : '',
                appName: r.getVal('appName', ''),
                cityState: citySt,
            }
            return res
        })
        setLoading(false)
        setFilteredRestaurants(fr)
    }

    const handleStatusFiltersChanged = (_, newStatusFilters) => {
        setFilters({...filters, status: newStatusFilters})
    }

    const handleAppNameFiltersChanged = (_, newAppNameFilters) => {
        setFilters({...filters, appName: newAppNameFilters})
    }



    useEffect(() => {
        setLoading(true)
        if (restaurants && restaurants.length) setLoading(false)
    }, [restaurants])

    
    useEffect(() => {
        filterData(restaurantSearchFilter)
    }, [restaurantSearchFilter, restaurants, filters])




	return (
        <Grid container style={{ height:"100%", padding: 10}}>
            {!selectedSite && 
                <>
                    <Grid container style={{ width: '100%', padding: 5, alignContent: 'center'}}>
                        <Grid container item xs={12} md style={{padding: 8, alignContent: 'center'}}>
                            <GlobalSearchFilter var="restaurantSearchFilter" searchText="Search by Site Name, status, address, etc."/>
                        </Grid>
                        <Grid container item xs={12} md style={{overflow: 'auto', width: '100%'}}>
                            <Grid item style={{padding:8, overflow: 'auto'}}>
                                <ToggleButtonGroup value={filters.status} onChange={handleStatusFiltersChanged}>
                                    <ToggleButton value="enrolled" aria-label="enrolled">
                                        <Typography color="primary">Enrolled</Typography>
                                    </ToggleButton>
                                    <ToggleButton value="installed" aria-label="installed">
                                        <Typography color="primary">Installed</Typography>
                                    </ToggleButton>
                                    <ToggleButton value="testing" aria-label="testing">
                                        <Typography color="primary">Testing</Typography>
                                    </ToggleButton>
                                    <ToggleButton value="live" aria-label="live">
                                        <Typography color="primary">Live</Typography>
                                    </ToggleButton>
                                    <ToggleButton value="ineligible" aria-label="ineligible">
                                        <Typography color="primary">Ineligible</Typography>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item style={{padding:8, overflow: 'auto'}}>
                                <ToggleButtonGroup value={filters.appName} onChange={handleAppNameFiltersChanged}>
                                    <ToggleButton value="TextPay" aria-label="TextPay">
                                        <Typography color="primary">TextPay</Typography>
                                    </ToggleButton>
                                    <ToggleButton value="DashNow" aria-label="DashNow">
                                        <Typography color="primary">DashNow</Typography>
                                    </ToggleButton>
                                    <ToggleButton value="Curbly" aria-label="Curbly">
                                        <Typography color="primary">Curbly</Typography>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container style={{ width:'100%', maxHeight:'100%', height: 'calc(100% - 50px)', overflow: 'none'}} >


                        <Grid item xs={12} container direction='row' style={{ width: '100%', height: smallScreen ? 'auto' : 'calc(100% - 50px)', minHeight: smallScreen ? 'calc(100% - 50px)' : '0', margin: 0 }}>
                            <Grid item xs={12} style={{ height: smallScreen ? 'auto' : '100%', width: '100%', padding: 5}} >
                                <Slide in={!selectedSite} direction="right">
                                    <Paper className={classes.paper}>
                                        {loading && <LinearProgress />}
                                        {!loading && filteredRestaurants &&
                                            <TableContainer style={{height: '100%' }}>
                                                <Table id="payments-table" size="small" aria-label="a dense sticky table" stickyHeader style={{position: 'sticky'}}>
                                                    <DTableHead
                                                        // classes={classes}
                                                        order={order}
                                                        headCells={headCells}
                                                        style={{backgroundColor:'white'}}
                                                        orderBy={orderBy}
                                                        onRequestSort={handleRequestSort}
                                                    />
                                                    {/* <TableHead id="payments-table-head">
                                                        <TableRow>
                                                            <TableCell style={{backgroundColor: 'var(--white)'}}>Site Name</TableCell>
                                                            <TableCell style={{backgroundColor: 'var(--white)'}}>Status</TableCell>
                                                            <TableCell style={{backgroundColor: 'var(--white)'}}>Group Name</TableCell>
                                                            <TableCell style={{backgroundColor: 'var(--white)'}}>Address</TableCell>
                                                            <TableCell style={{backgroundColor: 'var(--white)'}}>Agent</TableCell>
                                                            <TableCell style={{backgroundColor: 'var(--white)'}}>Primary Contact</TableCell>
                                                            <TableCell style={{backgroundColor: 'var(--white)'}}>Created On</TableCell>
                                                        </TableRow>
                                                    </TableHead> */}
                                                    <TableBody id="payments-table-body">
                                                        {filteredRestaurants && stableSort(filteredRestaurants, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(rest => {

                                                            return (
                                                                <>
                                                                    <TableRow id={rest._id} selected={restaurant && restaurant._id == rest._id} onClick={() => {let rest2 = restaurants.find(r=> r._id == rest._id); global.set({restaurant: rest2}); setSelectedSite(rest2)}} style={{ cursor: 'pointer' }}>
                                                                        <TableCell>{rest.name}</TableCell>
                                                                        <TableCell>{rest.status.toProperCase()}</TableCell>
                                                                        <TableCell>{rest.appName}</TableCell>
                                                                        <TableCell>{rest.posType.toUpperCase()}</TableCell>
                                                                        <TableCell>{rest.groupName}</TableCell>
                                                                        <TableCell>{rest.cityState}</TableCell>
                                                                        <TableCell>{rest.externalNotes}</TableCell>
                                                                        <TableCell>{rest.agentName}</TableCell>
                                                                        <TableCell>{rest.primaryContactAccountName}</TableCell>
                                                                        <TableCell style={{minWidth: '185px'}}>{rest.createdAt ? moment(rest.createdAt).format("M/D/YY h:mm a") : ''}</TableCell>
                                                                        <TableCell style={{minWidth: '185px'}}>{rest.getVal('primaryContactAccount.lastLoginTime') ? moment(rest.primaryContactAccount.lastLoginTime).format("M/D/YY h:mm a") : 'NEVER'}</TableCell>
                                                                    </TableRow>
                                                                </>
                                                            )
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Paper>
                                </Slide>
                            </Grid>
                        </Grid>

                        {filteredRestaurants && filteredRestaurants.length > rowsPerPage && 
                            <Grid container item md={12} style={{ width: '100%', padding: 0, marginTop: -5}} justify='flex-end'>
                                <TablePagination
                                    rowsPerPageOptions={[25, 50, 100]}
                                    align='right'
                                    component="div"
                                    count={filteredRestaurants ? filteredRestaurants.length : 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Grid>
                        }

                        <Grid item lg={12} >
                            {dialogContent}
                        </Grid>
                    </Grid>
                </>
            }
            {selectedSite &&
                <Paper style={{width:'100%'}}>
                    <div style={{display:'flex', justifyContent:'flex-start', flexDirection:'column'}}>
                        <div id="top" style={{borderBottom:'1px solid var(--divider)'}}>
                            <IconButton color="primary" className={classes.iconButton}  onClick={() => { setSelectedSite(null) }} aria-label="directions">
                                <ArrowBackIosIcon/> <span style={{fontSize:14, color:'var(--text)'}}>BACK</span>
                            </IconButton>
                        </div>
                        <Slide in={selectedSite} direction="left" mountOnEnter unmountOnExit>
                            <div>
                                <SiteAdmin site={selectedSite}/>
                            </div>
                        </Slide>

                    </div>
                </Paper>
            }
        </Grid>
	);
};
