import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import { Grid, Dialog, DialogActions, DialogContent, Button, Typography, Checkbox, Input, FormControlLabel, FormGroup, Select, MenuItem, Paper, ButtonGroup, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import SelectMui from '@material-ui/core/Select';
import upsertAgencyApi from '../../actions/upsertAgencyApi';
import DialogTitle from '../../dashboard/components/DialogTitle';

import log from 'loglevel'
import dashFetch from '../../utils/dashFetch';
import AssignmentIcon from '@material-ui/icons/Assignment';
import theme from '../../theme';
import DInput from '../../components/dash/DInput';
import DForm2 from '../../components/dash/DForm2';
import DRecord from '../../components/dash/DRecord';
import { Autocomplete } from '@material-ui/lab';
import Branding from '../../dashboard/setup/branding';
import getProductPackageGroupListApi from '../../actions/getProductPackageGroupListApi';

const styles = {
	infoBanner: {
		padding: 5,
		paddingTop: 15
	},
	paper: {
		padding: 10,
		width: '100%',
		height: '100%'
	},
	table: {
    },
    row: {
        padding:0
    }
}

const MODEL_NAME = 'Agency'

export default function (props) {
	// alert(JSON.stringify(props.permissions))
    const [record, setRecord] = useState(props.record)
    const [agents] = useState(props.agents)
    const [filteredAgents, setFilteredAgents] = useState([])
    const [masterBrandingSave, setMasterBrandingSave] = global.get("masterBrandingSave")
    const [productPackageGroups, setProductPackageGroups] = global.get("productPackageGroups")
    
    useEffect(() => {
        fetch()
    }, [])


	const copyToClipboard = (e) => {
		navigator.clipboard.writeText(`ag=${record._id}`);
		global.showNotification("Copied", "success")
	}

   
    const fetch = async () => {
        if (!record) {
            let resp = await dashFetch(`${global.dashServerUrl}/dashboard/api/v1/dashboard/newSchemaInstance`, { schema: MODEL_NAME }, true);
			setRecord(resp.json.instance)
		}
        if (!productPackageGroups) {
            let ppgs = await getProductPackageGroupListApi()
            if (ppgs) setProductPackageGroups(ppgs)
        }
    }

	const upsertRecord = async (record) => {
		try {
            record = await upsertAgencyApi(record)
            if (record) {
                global.showNotification("Success", 'success')
                props.refresh(true)
                setMasterBrandingSave(true)
            }
			props.close()
		} catch (err) {
			log.error(err)
		}
	}

    useEffect(() => {
		if (agents && record && record.agents) {
			setFilteredAgents(agents.filter(a => record.agents.indexOf(a._id) >= 0));
		}
	}, [agents, record])
    
    useEffect(() => {
		if (masterBrandingSave == true) {
			setMasterBrandingSave(false);
		}
	}, [masterBrandingSave])
    
	return (
		<Dialog
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			maxWidth={'md'}
			open={true}
			style={{ margin: 0, height: '100%', width: '100%'}}
			onClose={ props.close }
		>
			{record && 
			<>
				<DialogTitle onClose={ props.close}>
					{!props.record? 'NEW' : 'UPDATE'} AGENCY
				</DialogTitle>
				<DialogContent dividers style={{backgroundColor:'var(--background)'}}>
                    <DForm2 name="priceTemplate" record={record} margin="10px">
                        <Grid container>
                            <Grid item lg={12} container spacing={2}>
                                <Grid item lg={9}>
                                    <DInput
                                        label="Name"
                                        name="name"
                                    />
                                </Grid>
                                <Grid item lg={3}>
                                    <DInput
                                        label="Code"
                                        name="code"
                                        disabled
                                    />
                                </Grid>
                                <Grid item container lg={6}>
                                    <Paper style={{ padding: 20, paddingTop:12, width:'100%'}}>
                                        {/* <Typography color="primary" variant="h5">Credit Card</Typography> */}
                                        <Grid container style={{padding:8}} spacing={2}>
                                           
                                            <Grid item xs={12} sm={6} md={12}>
                                                <DInput
                                                    label="Signup Source"
                                                    name="signupSource"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={12}>
                                                <DInput
                                                    width="100%"
                                                    label="Primary Contact"
                                                    name="primaryContactAccount"
                                                    fieldType="select"
                                                >
                                                    {agents.map((rec) => <MenuItem value={rec._id}>{rec.fullName}</MenuItem>)}
                                                </DInput>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={12}>
                                                <DInput
                                                    width="100%"
                                                    label="Product Package Group"
                                                    name="productPackageGroup"
                                                    fieldType="select"
                                                >
                                                    {productPackageGroups && productPackageGroups.map((rec) => <MenuItem value={rec._id}>{rec.name}</MenuItem>)}
                                                </DInput>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <DInput
                                                    label="Est. Site Count"
                                                    name="estimatedSiteCount"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Autocomplete
													multiple
													id="tags-outlined"
													required
													options={agents && agents.sort((a,b) => (a.fullName.toLowerCase() > b.fullName.toLowerCase()) ? 1 : -1)}
													getOptionLabel={(option) => option.fullName}
													value={filteredAgents ? filteredAgents : []}
													filterSelectedOptions
													renderInput={(params) => (
													<TextField
														{...params}
														variant="outlined"
														label="Select agent(s)"
													/>
													)}
													onChange={(e,agents) => setRecord({...record, agents: agents.map(a=> a._id)})}
												/>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>

                                <Grid item container lg={6}>
                                    <Grid item container lg={12}>
                                        <Paper style={{ padding: 20, paddingTop:12, width:'100%' }}>
                                           {<Branding save={false} agency={record} refresh={props.refresh}/>}
                                        </Paper>
                                    </Grid>
                                </Grid>
                                
                            </Grid>
                        </Grid>
                    </DForm2>
				</DialogContent>
			</>}
			<DialogActions>
                <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
                    <div>
                        {props.record &&
                            <Button style={{ float: 'left'}} variant="outlined" color="primary" onClick={ ()=> copyToClipboard() }><AssignmentIcon style={{paddingRight: '10px'}}></AssignmentIcon>Copy Demo Param</Button>
                        }
                    </div>
                    <div>
                        <Button onClick={props.close}>
                            Close
                        </Button>
                        <Button onClick={() => {upsertRecord(record);}}>
                            Save
                        </Button>
                    </div>
                </div>
			</DialogActions>
		</Dialog>
	)

}
