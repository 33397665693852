import React, { useEffect } from 'react';
import packageJson from '../package.json';
import ip from 'ip';
import log from 'loglevel';
import { domains, wsDomains, spreedlyEnvironmentKeys } from './config';
import _ from 'lodash';
import ReactGA from 'react-ga'

export default (props) => {

    setupEnvironment()
    setupGlobalProperties()
    setupLogging()
    setupGlobalFunctions()
    setupGoogleAnalytics()
}

const setupGlobalProperties = function () {
    //SETUP GLOBAL VARIABLES
    let serverIp = ip.address()
    log.info("   IP: " + serverIp)
    global.serverIp = serverIp
    global.appName = packageJson.name
    global.spreedlyEnvironmentKey = spreedlyEnvironmentKeys[global.environment]
    global.appVersion = packageJson.version.toString()
    const dashNowIn = (window.location.href.indexOf('.dashnow.com') > -1 || window.location.href.indexOf('.dnow.app') > -1)
    const textPayIn = (window.location.href.indexOf('.textpay.app') > -1)
    const curblyIn = (window.location.href.indexOf('.curbly.app') > -1)
    
    global.appName = dashNowIn ? 'DashNow' : textPayIn ? 'TextPay' : curblyIn ? 'Curbly' : ''


    if (global.environment != 'prod') {
        document.title = document.title + ' [' + global.environment + ']'
    }

    global._ = _
    global.f = (obj, propsStr, defaultVal = null) => getWithNullCheck.apply(this, [obj, defaultVal, ...propsStr.split('.')]) //GROOVY NULL OPERATOR
    global.ifNull = (obj, defaultVal = null) => {return obj == null ? defaultVal : obj}
    global.hasPermission = (code) => {
        let permissions = localStorage.getItem('permissions')
        if (permissions) {
            permissions = JSON.parse(permissions)

            if (code.indexOf('*') > -1) {
                code = code.replace('*', '')
            }
            for (let permission of permissions) {
                if (permission.code.startsWith(code)) {
                    return true
                }
            }
        }
        return false
    }
    global.adminPermissionCode = 'admin.restaurant.gateway.edit'
    global.isAdmin = () => {
        let code = global.adminPermissionCode
        let permissions = localStorage.getItem('permissions')
        if (permissions) {
            permissions = JSON.parse(permissions)

            for (let permission of permissions) {
                if (permission.code.startsWith(code)) {
                    return true
                }
            }
        }
        return false

    }
    global.showNotification = (message, severity = 'error', duration = 2500) => {
        global.set({
            'alert2': {
                message,
                severity,
                show: true,
                duration
            }
        })
    }

    global.hideNotification = (alert2, event, reason) => {
		if (reason === 'clickaway') {
			return;
        }
		global.set({
            'alert2': {
                message: alert2.message,
                severity: alert2.severity,
                show: false,
            }
        })
	};
    
    global.copyToClipboard = (text) => {
		navigator.clipboard.writeText(text);
		global.showNotification("Copied", "success")
	}

    global.showTermsOfUse = () => {
        global.set({
            'showTermsOfUse': true
        })
    }

    global.showQboConnectionDialog = () => {
        global.set({
            'showQboConnectionDialog': true
        })
    }


    global.formatMoney = (val) => { return (val && val!="") ? (Math.round(val) / 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'): (0).toFixed(2) };
    global.formatPhoneNumber = (pnStr) => {
        if (!pnStr) {
            pnStr = ''
        } else if (pnStr.length < 4) {
            pnStr = pnStr.replace(/(\d{1,3})/g, "($1");
        } else if (pnStr.length < 7) {
            pnStr = pnStr.replace(/(\d{3})(\d{1,3})/g, "($1) $2");
        } else if (pnStr.length <= 10) {
            pnStr = pnStr.replace(/(\d{3})(\d{3})(\d{1,4})/g, "($1) $2-$3");
        } else if (pnStr.length <= 15) {
            pnStr = pnStr.replace(/(\d+)(\d{3})(\d{3})(\d{4})/g, "+$1 ($2)$3-$4");
        }
        return pnStr;
    }
    global.capitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    global.removeItemFromArray = function(arr, item, index) { 
        if (!index) index = arr.indexOf(item)
        if (index > -1) {
            arr = arr.slice(0,index).concat(arr.slice(index+1, arr.length))
        }
        return arr
    }
    global.formatUnitOfTime = (option, unit = 'minute') => {
		if (!option) {
			return ""
		}
		let unitTmp = unit
		let optionTmp = option
		let remainder 
		// if (unitTmp == 'minute' || unitTmp == 'second' || unitTmp == 'hour') {
        if (unit == "second" && option >= 60) {
            unitTmp = "minute"
            remainder = getRemainderStr(option, 60)
            optionTmp = Math.floor(option/60).toString() + remainder
        }
        if (unit == "minute" && option >= 60) {
            unitTmp = "hour"
            remainder = getRemainderStr(option, 60)
            optionTmp = Math.floor(option/60).toString() + remainder
        }
        if (unit == "hour" && option >= 24) {
            unitTmp = "day"
            remainder = getRemainderStr(option, 24)
            optionTmp = Math.floor(option/60).toString() + remainder
        }
        return optionTmp + unitTmp.substring(0,1)
	}

    global.generateWebUrl = function(appName, whiteLabelCode) {
        let prefix = ''
        let server = 'dnow.app'
        if (appName == 'TextPay') {
            server = 'textpay.app'
        } else if (appName == 'Curbly') {
            prefix = 'm.'
            server = 'curbly.app'
        }
        if (global.environment == 'dev') {
            prefix = 'local.'
        } else if (global.environment == 'stage') {
            prefix = 'stage.'
        }
        
        let prePrefix = whiteLabelCode.substring(0,1) //TODO look here when branding
        if (whiteLabelCode != 'dashnow' && prePrefix && global.environment != 'dev') prefix = prePrefix + (!global.isProd ? '-' : '.') + prefix
        
        return "https://" + prefix + server
    }

	global.generateDashboardUrl = function(appName, url, whiteLabelCode) {
		let prefix = 'dashboard'
		let server = 'dashnow.com'
		if (global.environment == 'dev') {
			prefix = 'local'
			server = 'dnow.app'
		} else if (global.environment == 'stage') {
			prefix = 'stage-' + prefix
		}
		if (appName == 'TextPay') {
			if (whiteLabelCode != 'dashnow') {
				if (global.environment == 'stage') {
					prefix = whiteLabelCode + '-test'
				} else {
				    prefix = whiteLabelCode + "-dashboard"
                }
			}
			server = 'textpay.app'
		} else if (appName == 'Curbly') {
			server = 'curbly.app'
		}
		prefix = prefix ? prefix + '.' : ''
		return "https://" + prefix + server + url
	}
    global.validatePhoneNumber = (value) => /^\(\d{3}\) \d{3}-\d{4}$/.test(value)
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation
    // global.validateEmail = (value) => /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(value)
    global.validateEmail = (value) => /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(value)
}

const setupGoogleAnalytics = function () {
	// if (global.environment === "prod") { //TODO turn on once tested
		ReactGA.initialize('UA-149374087-5');
    // }
}

function setupGlobalFunctions() {
	String.prototype.toProperCase = function(opt_lowerCaseTheRest) {
        return (opt_lowerCaseTheRest ? this.toLowerCase():this).replace(/(^|[\s\xA0])[^\s\xA0]/g, function(s){ return s.toUpperCase(); });
    }

    Array.prototype.replaceItem = function(record, prop = '_id', index) {
        if (!index) index = -1
        if (prop == '_id' && index == -1) {
            index = this.indexOf(record)
        }
        if (index == -1) {
            index = _.findIndex(this, rec => { return rec[prop] == record[prop]})
        }
        if (index > -1) {
            this.splice(index, 1, record)
        }
        return this
    }

    Array.prototype.removeItem = function(record, prop = '_id', index) {
        if (!index) index = -1
        if (prop == '_id' && index == -1) {
            index = this.indexOf(record)
        }
        if (index == -1) {
            index = _.findIndex(this, rec => { return rec[prop] == record[prop]})
        }

        if (index > -1) {
            this.splice(index, 1)
        }
        return this
    }

    Array.prototype.removeItemAtIndex = function(index = -1) {
        if (index > -1) {
            this.splice(index, 1)
        }
        return this
    }

    Array.prototype.sortByProperty = function(prop, desc = true) {
        let arr = this.sort((a, b) => {
            if (a[prop] && b[prop] && a[prop].toLowerCase() > b[prop].toLowerCase()) {
                return 1
            } else {
                return -1
            } 
        })
        if (!desc) {
            arr.reverse()
        }
        return arr
    }

    Object.defineProperty(Object.prototype, 'getVal', {
        value: function(path = '', defaultValue) {
            return getWithNullCheck.apply(this, [this, defaultValue, ...path.split('.')])
        },
        enumerable: false,
    });

    Object.defineProperty(Object.prototype, 'has', {
        value: function(path = '', defaultValue) {
            return !!(getWithNullCheck.apply(this, [this, defaultValue, ...path.split('.')]))
        },
        enumerable: false,
    });
                

    Object.defineProperty(Object.prototype, 'setVal', {
        value: function(path = '', newValue) {
            return _.set(this, path, newValue)
        },
        enumerable: false,
    });
}

const setupEnvironment = function () {
    if (!global.environment) {
        if (process.env.REACT_APP_ENV) {
            global.environment = process.env.REACT_APP_ENV;
        } else {
            global.environment = "prod"
        }
        global.isProd = global.environment === "prod"
        console.log("SETTING ENVIRONMENT = " + global.environment)
    }
    global.dashServerUrl = domains[global.environment];
    global.dashServerWsUrl = wsDomains[global.environment];

    log.info("global.dashServerUrl " + global.dashServerUrl)
    log.info("global.dashServerWsUrl " + global.dashServerWsUrl)
}

const setupLogging = function () {
    if (global.environment === "prod") {
        log.setDefaultLevel("warn")
    } else {
        log.setDefaultLevel("trace")
    }
}

function getWithNullCheck(obj, defaultVal, ...props) {
    if (obj instanceof Object) {
        let val = obj[props.shift()];
        let returnVal = (props.length && val) ? getWithNullCheck(val, defaultVal, ...props) : val;
        return returnVal &&
            (
                (returnVal instanceof Object) && (Object.keys(returnVal).length > 0)
                || ((returnVal instanceof Array) && (returnVal.length > 0))
                || (!(returnVal instanceof Object) && !(returnVal instanceof Array))
            )
            ? returnVal : defaultVal
    } else {
        return defaultVal
    }
}

const getRemainderStr = (number, modBy, prefix = ":") => {
    return number%modBy > 0 ? prefix + minTwoDigits(number%modBy) : ""
}

function minTwoDigits(n) {
    return (n < 10 ? '0' : '') + n;
}

