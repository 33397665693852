import React, {useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import PaletteIcon from '@material-ui/icons/Palette';

// This component accepts an array of menu items as props i.e.:
    // const items = [{
    //     text: 'Item 1',
    //     icon: <PaletteIcon />,
    //     clickAction: () => alert("yas"),
    //     permission: 'restaurant.qrcodes',
    //     value: 'qrcodes'
    // }]

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex', 
        justifyContent:'center', 
        alignItems:'flex-start', 
        flexDirection:'column',
        width:'100%',
    },
    menuItem: {
        border: '1px solid #e2e8f0',
        borderRight:'none',
        borderRadius:'4px 0px 0px 4px',
        '&:hover': {
            backgroundColor:'rgb(226, 232, 240)'
        },
        display:'flex',
        alignItems:'center',
        width:'100%',
        height:44,
        transition: '0.4s',
        marginBottom:4,
        cursor: 'pointer'
    },
}))

export default function VerticalMenu(props) {
    const classes = useStyles();
    const [items, setItems] = useState(props.items ? props.items : [])

    return (
        <div className={classes.container}>
            {items && items.map((item, index) => {

                    const isSelected = (props.selected == item.value)
                    
                    if (item.permission && !global.hasPermission(item.permission) || !item.show) {
                        return
                    }

                    return (
                        <div className={classes.menuItem} 
                            style={{
                                backgroundColor: isSelected ? 'var(--white)' : '', 
                                borderLeft: isSelected ? `2px solid var(--${global.branding})`: '', 
                                zIndex: isSelected ? 5 : 0,
                                borderTop: isSelected ? '1px solid #FFF' : '1px solid #e2e8f0',
                                boxShadow: isSelected ? '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px, 0px 1px 3px 0px rgb(0 0 0 / 12%), 0px 1px 1px 0px rgb(0 0 0 / 14%)' :''
                            }}
                            onClick={ item.clickAction ? ()=> item.clickAction() : ()=>alert('set click action for menu item')}
                        > 

                            {item.icon &&
                                <div style={{color: isSelected ? `var(--${global.branding}` : 'var(--text)', padding: isSelected ? '0px 16px 0px 14px' : '0px 16px 0px 16px'}}>
                                    {item.icon}
                                </div>
                            }

                            <div style={{fontSize:16 }}>
                                {item.text}
                            </div>

                        </div> 
                    )
                })
            }
        </div>
    );
}