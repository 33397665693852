import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import {ReactComponent as AffiliateGraphic} from '../assets/svgs/affiliate_graphic.svg';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import theme from '../theme'
import useMediaQuery from '@material-ui/core/useMediaQuery';

function getModalStyle() {
	return {
	  top: `50%`,
	  left: `50%`,
	  transform: `translate(-50%, -50%)`,
	}
}

export default function EarnMoneyDialog(props) {
  	const [modalStyle] = React.useState(getModalStyle);
  	const [open, setOpen] = React.useState(props.open);
	const [copied, setCopied] = React.useState(false);

  	const handleClose = () => {
		setOpen(false)
		if (props.onClose) {
			props.onClose(false)
		}
  	}

	let xs = useMediaQuery(theme().breakpoints.down('xs'));
	let sm = useMediaQuery(theme().breakpoints.down('sm'));

	const useStyles = makeStyles(theme => ({
		paper: {
			position: 'absolute',
			width: xs || sm ? '95%' : 750,
			height: xs || sm ? 600 : 500,
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
			borderRadius: 5,
		},
		title: {
			marginTop: 25,
			fontSize: '1.1rem', 
			fontWeight: 500
		},
		text: {
			color: '#686868',
			marginBottom: 15,
		},
		input: {
			display: 'flex',
			alignItems: 'center', 
			border: copied ? 'solid 1px #E86C00' : 'solid 1px #CCC',
			borderRadius: 5,
			padding: 6,
			'& > p': {
				marginLeft: 8,
				fontWeight: xs ? 400 : 600,
				fontSize: xs ? '0.8rem' : 'inherit',
			},
			'& > svg': {
				marginLeft: 5,
			},
		},
		helper: {
			color: '#686868',
			fontStyle: 'italic',
			fontSize: '0.8rem', 
			fontWeight: 400,
			'& p': {
				marginTop: 10,
			}
		},
		table: {
			marginBottom: 15,
			'& td': {
				textAlign: 'center',
			},
			'& td.left': {
				textAlign: 'left',
			}
		}
	}))
	const classes = useStyles();

  	return (
    	<Modal onClose={handleClose} open={open}>
			<div style={modalStyle} className={classes.paper}>
				<div style={{display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'stretch', alignItems: 'stretch'}}>
					<div style={{width: '100%', textAlign: 'center', marginTop: 15}}>
						<Typography variant={'h5'}>
							Share TextPay with your friends and earn money!
						</Typography>
					</div>

					<div style={{display: 'flex', height: '100%', justifyContent: 'stretch', alignItems: 'stretch'}}>
						<div style={{display: xs ? 'none' : 'flex' , alignItems: 'center', justifyContent: 'center', width: '40%'}}>
							<AffiliateGraphic />
						</div>
						<div style={{width: xs ? '100%' : '60%', paddingTop: 10}}>
							<div className={classes.title}>How does it work?</div>
							<div className={classes.text}>Simply share the link below with your friends and when they sign up and use TextPay, you get paid.</div>

							<CopyToClipboard text={'https://www.textpay.app?ref=' + localStorage.affiliateCode} onCopy={() => setCopied(true)} style={{cursor: 'pointer'}}>
								<div className={classes.input}>
									<FileCopyIcon color="primary" />
									<Typography color="primary">https://www.textpay.app?ref={localStorage.affiliateCode}</Typography>
									{copied && <div style={{color: '#666', fontStyle: 'italic', fontSize: '0.8rem', marginLeft: 35}}>Copied!</div>}
								</div>
							</CopyToClipboard>

							<div className={classes.title}>How much can I earn?</div>
							<div className={classes.text}>Every month we share 15% commission to our partners of the income we generate for each live account. Commissions are paid out based on the following two-tier schedule.</div>
							<div>
								<table className={classes.table} style={{width: '100%'}}>
									<tr style={{borderBottom: 'solid 1px #CCC'}}>
										<td></td>
										<td>Tier 1 (direct)</td>
										<td>Tier 2</td>
									</tr>
									<tr>
										<td class="left">Commission</td>
										<td style={{color: '#E86C00', fontWeight: 600}}>10%</td>
										<td style={{color: '#E86C00', fontWeight: 600}}>5%</td>
									</tr>
								</table>
							</div>
							<div className={classes.helper}>
								<p>Tier 1 = Commission earned from accounts that signed up for TextPay that you referred directly.</p>
								<p>Tier 2 = Commission earned from accounts that signed up for TextPay by a sub account that you referred.</p>
							</div>
						</div>
					</div>
					
				</div>
				
				{/* <Typography variant="h5">
					Share TextPay with your friends and earn money!
				</Typography>
				<p id="simple-modal-description">
					Test
				</p> */}
			</div>
    	</Modal>
  	);
}

