import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, Paper, MenuItem, Select, Checkbox, ListItemText, FormControl, FormControlLabel, Switch, FormGroup, CircularProgress} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
	AddCircleOutline,
} from '@material-ui/icons';
import { Button, Input } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import getAccountListApi from '../../actions/getAccountListApi'
import getAccountRolesApi from '../../actions/getAccountRolesApi'
import log from 'loglevel'
import ReactTable from "react-table";
import AddEditAccount from "../../admin/components/AddEditAccount"
import getSiteAccountDetails from "../../actions/getSiteAccountDetailsApi"
import "react-table/react-table.css";
const moment = require('moment')

const styles = (theme) => ({
    root: {
    },
	addIcon: {
		float: 'left',
		marginTop: 5,
		marginRight: 5,
		width: '20px',
		height: '20px'
	},
	addButton: {
		// float: 'right',
		// marginLeft: 20,
		width: 200,
		height: 45
    },
    tabs: {
        width: '100%'
    }
    
})

const AccountSetup = (props) => {
    const { classes } = props;
    const [restaurant] = global.get('restaurant')
    const [restaurants] = global.get('restaurants')
    const [accounts, setAccounts] = useState([])
    const [accountRoles, setAccountRoles] = useState(props.roles ? props.roles : [])
    const [sites, setSites] = useState([])
    const [siteGroups, setSiteGroups] = useState([])
    const [dialogContent, setDialogContent] = useState()
    const isAdmin = props.isAdmin
    const [refreshAccounts, setRefreshAccounts] = useState(isAdmin)
    const [loading, setLoading] = useState(true)
    const [accountsFiltered, setAccountsFiltered] = useState([])
    let [sortByRestaurant, setSortByRestaurant] = React.useState(true);
    let hasAdminAccess = global.hasPermission('admin.users.*')

    async function fetchdata() {
        let data = {}
        setLoading(true)
        if (!isAdmin) {
            data = await getSiteAccountDetails(restaurant)
        } else {
            data.accounts = await getAccountListApi()
            data.roles = await getAccountRolesApi()
        }
        if (data) {
            // alert(JSON.stringify(data))
            if (data.accounts) setAccounts(data.accounts)
            if (data.roles) setAccountRoles(data.roles)
            if (hasAdminAccess) {
                setSites(restaurants)
                let siteGroupsTmp = []
                if (restaurants) {
                    for (let restaurant of restaurants) {
                        if (restaurant.restaurantGroup && siteGroups.indexOf(restaurant.restaurantGroup)) {
                            siteGroupsTmp.push(restaurant.restaurantGroup)
                        }
                    }
                }
                setSiteGroups(siteGroupsTmp)
            } else {
                if (data.sitesWithAccess) setSites(data.sitesWithAccess)
                if (data.siteGroupsWithAccess) setSiteGroups(data.siteGroupsWithAccess)
            }
        }
        setLoading(false)
    }


    useEffect(() => {
        if (!isAdmin || refreshAccounts) {
            fetchdata();
            setRefreshAccounts(false)
        }
    }, [restaurant, refreshAccounts])
    
    useEffect(() => {
        if (isAdmin) {
            let accountsForRestaurant = accounts;
            if (sortByRestaurant) {
                accountsForRestaurant = accounts.filter(account=> account.info && ((account.info.restaurants && account.info.restaurants.includes(restaurant && restaurant.id)) || (account.info.restaurantGroups && account.info.restaurantGroups.includes(global.f(restaurant, 'restaurantGroup._id', false)))))
            } 
            setAccountsFiltered(accountsForRestaurant)
        } else {
            let filteredAccounts = accounts;
            if (sortByRestaurant) {
                filteredAccounts = accounts.filter(account=> {
                    if(account.roles){
                        let role = accountRoles.find((a) => account.roles.indexOf(a._id) > -1 )
                        if(role && role.code && role.code != "textpay-agent"){
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return false
                    }
                })
            } 
            setAccountsFiltered(filteredAccounts)
        }
    }, [restaurant, accounts, accountRoles, sortByRestaurant])

    const openAddEditAccountWindow = (account, newAccount = true) => {
        // alert('role ' + JSON.stringify(role))

        setDialogContent(
            <AddEditAccount refresh={setRefreshAccounts} account={account} create={newAccount} roles={accountRoles} close={closeContent} isAdmin={isAdmin} sites={sites} siteGroups={siteGroups}/>
        )
    }

    const closeContent = () => {
        setDialogContent(null)
    }

    const getAccountRoleName = (account) => {
		let accountHasRoles = account.roles && account.roles.length > -1
        let role = accountHasRoles ? accountRoles.find((a) => account.roles.indexOf(a._id) > -1 ) : ''
        if (role && role.name) return role.name
	}

    const showaccounts = () => {
        return (
            <div className={classes.listing}>
                <Grid container style={{ width: '100%' }}>
                    <div style={{display:'flex', justifyContent:'space-between', paddingBottom:10, width: '100%'}}>
                        <Typography variant="h6" color="primary">
                            Users
                        </Typography>
                        {isAdmin &&
                            <FormGroup row style={{margin: '15px 0px 0px 20px'}}>
                                <FormControlLabel
                                    control={<Switch checked={sortByRestaurant} onChange={()=>setSortByRestaurant(!sortByRestaurant)} name="checkedA" color="primary" />}
                                    label="Filter by current restaurant"
                                />
                                
                            </FormGroup>
                        }
                        {global.hasPermission('restaurant.users.create') &&
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.addButton}
                                onClick={openAddEditAccountWindow}
                            >
                                <div>
                                    <AddCircleOutline className={classes.addIcon} />
                                    <Typography variant="subtitle1" style={{ color: 'white', width: 190 }}>
                                        Add User
                                    </Typography>
                                </div>
                            </Button>
                        }
                    </div>
                    {loading && 
                        <div style={{position:'relative', top: 200, left:'50%', marginLeft:-18, height:0}}>
                            <CircularProgress/>
                        </div>
                    }
                    <ReactTable
                        data={accountsFiltered}
                        filterable
                        style={{ width: '100%' }}
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
                        }
                        defaultSorted={[
                            {
                                id: "name",
                                desc: true
                            }
                        ]}
                        columns={[
                            {
                                columns: [
                                    {
                                        Header: "First Name",
                                        accessor: "firstName",
                                    },
                                    {
                                        Header: "Last Name",
                                        accessor: "lastName",
                                    },
                                    {
                                        Header: "Role",
                                        accessor: (original)=> {
                                            return getAccountRoleName(original)
                                        },
                                        id:'role'
                                    },
                                    {
                                        Header: "Phone #",
                                        accessor: (original)=> {
                                            return original.phoneNumber? global.formatPhoneNumber(original.phoneNumber) : ''
                                        },
                                        id:'phoneNumber',
                                        filterMethod: (filter, row) => {
                                            return String(row[filter.id]).replace(/\D/g, '').includes(filter.value)
                                        }
                                    },
                                    {
                                        Header: "Email",
                                        accessor: "email",
                                    },
                                    {
                                        Header: "Last Login Time",
                                        accessor: (user) => user.lastLoginTime ? moment(user.lastLoginTime).format("YYYY/MM/DD hh:mm:ss") : '',
                                        id: 'lastLoginTime'
                                    },
                                ]
                            },
                        ]}
                        getTrProps={(state, rowInfo) => {
                            if (rowInfo && rowInfo.row && global.hasPermission('restaurant.users.create')) {
                                return {
                                    onClick: (e) => {
                                        openAddEditAccountWindow(rowInfo.row._original, false)
                                    },
                                    // style: {
                                    //     background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                                    //     color: rowInfo.index === this.state.selected ? 'white' : 'black'
                                    // }
                                }
                            } else {
                                return {}
                            }
                        }}
                        defaultPageSize={15}
                        className="-striped -highlight"
                    />
                    <br />
                </Grid>
            </div>
        )
    }

    return (
        <Grid container >
            <div className={styles.root} style={{ width: '100%' }}>
                <Grid container>

                    <Grid item xs={12} >
                        {showaccounts()}
                    </Grid>
                    <Grid item lg={12} >
                        {dialogContent}
                    </Grid>
                </Grid>
            </div>
        </Grid>
    );
};

AccountSetup.propTypes = {
    classes: PropTypes.shape({}).isRequired
};

export default withRouter(withStyles(styles, { withTheme: true })(AccountSetup));
