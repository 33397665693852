import log from 'loglevel';
import queryString from 'query-string';
var Url = require('url-parse');

export const urlParse = (params) => {
    var url = new Url(params);
    // log.info(url)
    var queryObject = queryString.parse(url.query.toString());
    // log.info(queryObject)
    return { url: url, params: queryObject };
}
