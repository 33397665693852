import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, Paper, InputLabel } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
	AddCircleOutline, CheckBox,
} from '@material-ui/icons';
import { Button, TextField, Checkbox, IconButton} from '@material-ui/core';
import moment from 'moment'
import SupportDetails from "../../dashboard/components/SupportDetails";
// import getTransactionListApi from '../actions/getTransactionListApi'
import updateRestaurantDashboardApi from '../../actions/updateRestaurantDashboardApi'
import logoutRestaurantDashboardApi from '../../actions/logoutRestaurantDashboardApi'
import log from 'loglevel'
import { withRouter } from 'react-router-dom';
import StopIcon from '@material-ui/icons/Stop';

import ReactTable from "react-table";
import "react-table/react-table.css";

const styles = (theme) => ({
    root: {
    },
	addRequestIcon: {
		float: 'left',
		marginTop: 5,
		marginRight: 5,
		width: '20px',
		height: '20px'
	},
	addRequestButton: {
		float: 'right',
		marginLeft: 20,
		marginRight: 20,
		marginTop: 10,
		marginBottom: 10,
		width: 200,
		height: 45
	},
})

const Support = (props) => {
    const { classes } = props;
    
    const pickedUp = props.checks ? true : false


    const [transactions, setTransactions] = React.useState([])
    const [transactionsFiltered, setTransactionsFiltered] = React.useState([])
    const [restaurant] = global.get('restaurant')
    const [checks] = global.get('checks')
    const [textToPayEnabled] = global.get('textToPayEnabled')
    const [imHereEnabled] = global.get('imHereEnabled')
    const [orderUpEnabled] = global.get('orderUpEnabled')
    const [dialogContent, setDialogContent] = useState()
    const [includeClosed, setIncludeClosed] = useState(true)
    const [includeUnpaid, setIncludeUnpaid] = useState(true)
    const [filterAll, setFilterAll] = useState("")
    const [callAheadFilter, setCallAheadFilter] = useState(!!props.callAhead)

    const closeContent = () => {
        setDialogContent(null)
    }

    const showSupportDetails = (check) => {
        setDialogContent(
            <SupportDetails check={check} close={closeContent} />
        )
    }

	useEffect(() => {
        if (checks) {
            // log.info('checks ' + JSON.stringify(checks))
            setTransactions(formatTransactions(checks))
        }
        // setFilteredData()
        global.set({headerTitle: 'TRANSACTION LISTING'})
    }, []);
    
    

    useEffect(() => {
        setFilteredData()
    }, [includeUnpaid, includeClosed, transactions, filterAll]);

    useEffect(() => {
        if (checks) {
            setTransactions(formatTransactions(checks))
        }
    }, [checks]);

    
    
    const setFilteredData = () => {
        let filtered = transactions.filter((rec) => {
            if ((!includeClosed && rec.origCheck.closed) || (!includeUnpaid && rec.paidAt == 'N/A')) { //Well i hate this code. sorry future zach (syler)
                return false
            } else if (callAheadFilter) {
                if (!rec.origCheck.pickupDetails || !rec.origCheck.pickupDetails.phoneNumber) { //only show call ahead orders
                    return false
                } else if (rec.origCheck.pickupDetails.pickedUpAt || (moment(rec.origCheck.createdAt) < moment().subtract(12, 'hours'))) {
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }
        })
        if (filterAll) {
            let filterAllLowerCase = filterAll.toLowerCase()
            filtered = filtered.filter((rec) => {
                return (
                    // (global.f(rec, 'origCheck.restaurant') && rec.origCheck.restaurant.toLowerCase().includes(filterAll)) ||
                    // (global.f(rec, 'origCheck.restaurantStation') && rec.origCheck.restaurantStation.toLowerCase().includes(filterAll)) ||
                    (global.f(rec, 'origCheck.tableNumber') && rec.origCheck.tableNumber.toLowerCase().includes(filterAll)) ||
                    (global.f(rec, 'checkNumber') && rec.checkNumber.toLowerCase().includes(filterAll)) ||
                    (global.f(rec, 'checkNumberPrefix') && rec.checkNumberPrefix.toLowerCase().includes(filterAll)) ||
                    (global.f(rec, 'origCheck.tableSession') && rec.origCheck.tableSession.toLowerCase().includes(filterAll)) ||
                    // (global.f(rec, 'origCheck.payments') && rec.origCheck.payments.indexof(filterAll) > -1 )||
                    (global.f(rec, 'origCheck.createdFromRestaurantStationPrintJob') && rec.origCheck.createdFromRestaurantStationPrintJob.toLowerCase().includes(filterAll)) ||
                    (global.f(rec, 'origCheck.posTicketId') && rec.origCheck.posTicketId.toLowerCase().includes(filterAll)) ||
                    (global.f(rec, 'origCheck.description') && rec.origCheck.description.toLowerCase().includes(filterAll)) ||
                    (global.f(rec, 'origCheck.customer.firstName') && rec.origCheck.customer.firstName.toLowerCase().includes(filterAll)) ||
                    (global.f(rec, 'origCheck.customer.lastName') && rec.origCheck.customer.lastName.toLowerCase().includes(filterAll)) ||
                    (global.f(rec, 'origCheck.customer.phone') && rec.origCheck.customer.phone.toLowerCase().includes(filterAll)) ||
                    (global.f(rec, 'origCheck.pickupDetails.guestName') && rec.origCheck.pickupDetails.guestName.toLowerCase().includes(filterAll)) ||
                    // (global.f(rec, 'origCheck.pickupDetails.lastName') && rec.origCheck.pickupDetails.customer.lastName.toLowerCase().includes(filterAll)) ||
                    (global.f(rec, 'origCheck.pickupDetails.phoneNumber') && rec.origCheck.pickupDetails.phoneNumber.toLowerCase().includes(filterAll)) ||
                    (global.f(rec, 'checkAmount') && rec.checkAmount.includes(filterAll)) ||
                    (global.f(rec, 'tip') && rec.tip.includes(filterAll)) ||
                    (global.f(rec, 'loyalty') && rec.loyalty.includes(filterAll)) ||
                    (global.f(rec, 'total') && rec.total.includes(filterAll)) 

                    // (global.f(rec, 'origCheck.totals.subTotal') && rec.origCheck.totals.subTotal.toString().toLowerCase().includes(filterAll)) ||
                    // (global.f(rec, 'origCheck.totals.total') && rec.origCheck.totals.total.toString().toLowerCase().includes(filterAll))
                )
            
            })
        }
        setTransactionsFiltered(filtered)
    }


    const formatTransactions = (transactions) => {
        return transactions.map((transaction) => {
            let checkAmount = (transaction.totals && transaction.totals.total) ? Number(((transaction.totals.total)/100)).toFixed(2) : 0
            let tip = (transaction.totals && transaction.totals.tipsDashNow) ? Number(((transaction.totals.tipsDashNow)/100)).toFixed(2) : 0
            let loyalty = (transaction.totals && transaction.totals.loyaltyRewardsAmount) ? Number(((transaction.totals.loyaltyRewardsAmount)/100)).toFixed(2) : 0
            let paidDashNow = (transaction.totals && transaction.totals.paidDashNow) ? Number(((transaction.totals.paidDashNow) / 100)).toFixed(2) : 0
            let loyaltyTaxAmountRemoved = (transaction.totals && transaction.totals.loyaltyTaxAmountRemoved) ? Number(((transaction.totals.loyaltyTaxAmountRemoved) / 100).toFixed(2)) : 0
            let total = (Number(paidDashNow) + Number(tip) - Number(loyalty) - Number(loyaltyTaxAmountRemoved)).toFixed(2)
            let tmpPayment
            let lastPaymentTime 
            if (transaction.payments) {
                tmpPayment = transaction.payments.sort((a, b) => moment(a.createdAt) - moment(b.createdAt))[0]
                if (tmpPayment) {
                    lastPaymentTime = tmpPayment.createdAt
                }
            } 

            let paidDashNowFlag = <center key={paidDashNow > 0}><Checkbox checked={paidDashNow > 0} style={{padding: 0}} color="primary" /></center> 
                
            let paidAt = lastPaymentTime ? moment(lastPaymentTime).fromNow() : 'N/A'
            return {
                id: transaction._id,
                checkNumber: transaction.checkNumber,
                tableNumber: transaction.tableNumber,
                phoneNumber: transaction.pickupDetails.phoneNumber,
                guestName: transaction.pickupDetails.guestName,
                checkAmount: `$${checkAmount}`, 
                tip: `$${tip}`,
                loyalty: `$${loyalty}`,
                total: `$${total}`,
                paidAt,
                paidDashNowFlag,
                checkNumberPrefix: transaction.checkNumberPrefix ? transaction.checkNumberPrefix : '',
                tableSession: transaction.tableSession,
                payments: transaction.payments ? transaction.payments.map((p) => p._id): '',
                origCheck: transaction,
                
            };
		});
	};

	return (
        <div className={classes.listing} style={{ width: '100%' }}>
			<Grid container style={{width:'100%'}}>
            
            <Grid item xs={12} style={{ paddingTop:6, paddingBottom:2}}>
                <Typography variant="h6" color="primary">
                    Support
                </Typography>
			</Grid>
                <Grid container direction="row" style={{padding:8, paddingLeft:12}}>
                    {restaurant &&
                        <Paper style={{margin:4, padding:4, width:'100%'}}>
                            <Grid container item lg={12} alignItems="center" alignContent="space-between">
                                <Typography style={{padding:8}}>Rest Id: {restaurant ? restaurant._id : ''}</Typography> | 
                                <Typography style={{ padding: 8 }}>PosType: {global.f(restaurant, 'posType.posType', "")}</Typography> |
                                <Typography style={{padding:8}}>Text To Pay: <Checkbox color="primary" checked={textToPayEnabled}/></Typography> |
                                <Typography style={{padding:8}}>Im Here: <Checkbox color="primary" checked={imHereEnabled}/></Typography> |
                                <Typography style={{padding:8}}>Order Up: <Checkbox color="primary" checked={orderUpEnabled}/></Typography>
                                <Button variant="contained" onClick={() => updateRestaurantDashboardApi(restaurant._id)}>Force Dashboard Update</Button>
                                <Button variant="contained" onClick={() => logoutRestaurantDashboardApi(restaurant._id)}>Force Dashboard Logout</Button>
                            </Grid>
                        </Paper>
                    }
                </Grid>
                <Paper style={{ width: '100%' }} className={classes.rootTable}>
                    <div>
                        <Grid container>
                            <Grid item style={{padding: 10}} lg={10}>
                                <InputLabel shrink >SEARCH</InputLabel>
                                <TextField style={{ padding: 4, width:'80%'}} value={filterAll} onChange={(e) => setFilterAll(e.target.value)} />
                            </Grid>
                            <Grid item lg={1}>
                                <Typography >Include Unpaid
                                    <Checkbox
                                        color="primary"
                                        value={includeUnpaid}
                                        checked={includeUnpaid}
                                        onChange={(e) => setIncludeUnpaid(!includeUnpaid)}
                                    >
                                    </Checkbox>
                                </Typography>
                            </Grid>
                            <Grid item lg={1}>
                                <Typography >Include Closed
                                    <Checkbox
                                        color="primary"
                                        value={includeClosed}
                                        checked={includeClosed}
                                        onChange={(e) => setIncludeClosed(!includeClosed)}
                                    >
                                    </Checkbox>
                                </Typography>
                            </Grid>
                        </Grid>
                        <ReactTable
                            data={transactionsFiltered}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
                            }
                            defaultSorted={[
                                {
                                    id: "createdAt",
                                    desc: false
                                }
                            ]}
                            columns={[
                                {
                                    Header: "Transactions",
                                    columns: [
                                        {
                                            Header: "Check Id",
                                            accessor: "id"
                                        },
                                        {
                                            Header: "Check Number",
                                            accessor: "checkNumber",
                                            width: 200
                                        },
                                        {
                                            Header: "Check# Prefix",
                                            accessor: "checkNumberPrefix"
                                        },
                                        {
                                            Header: "Table Number",
                                            accessor: "tableNumber",
                                            width: 100
                                        },
                                        {
                                            Header: "POS Label",
                                            accessor: "posLabel"
                                        },
                                        {
                                            Header: "Phone Number",
                                            accessor: "phoneNumber"
                                        },
                                        {
                                            Header: "Guest Name",
                                            accessor: "guestName"
                                        },
                                        {
                                            Header: "Check Amount",
                                            accessor: "checkAmount",
                                            width: 100
                                        },
                                        {
                                            Header: "Tip",
                                            accessor: "tip",
                                            width: 100
                                        },
                                        {
                                            Header: "Total",
                                            accessor: "total",
                                            width: 100

                                        },
                                        {
                                            Header: "Paid",
                                            accessor: "paidAt"
                                        },
                                        {
                                            Header: "TS ID",
                                            accessor: "tableSession"
                                        },
                                        {
                                            Header: "Payments",
                                            accessor: "payments"
                                        },
                                        {
                                            Header: "Dash Now",
                                            accessor: "paidDashNowFlag",
                                            width: 100,
                                            sortMethod: (a,b) => {
                                                if (a.key == b.key) {
                                                    return 0
                                                }
                                                return (a.key > b.key) ? 1 : -1
                                            }
                                        },
                                    ]
                                },
                            ]}
                            getTrProps={(state, rowInfo) => {
                                if (rowInfo && rowInfo.row) {
                                    // console.log('TYLER ' + JSON.stringify(rowInfo))
                                    // console.log('TYLER 1 ' + JSON.stringify(rowInfo.row))
                                    return {
                                        onClick: (e) => {
                                            showSupportDetails(rowInfo.row._original.origCheck)
                                        },
                                        // style: {
                                        //     background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                                        //     color: rowInfo.index === this.state.selected ? 'white' : 'black'
                                        // }
                                    }
                                } else {
                                    return {}
                                }
                            }}
                            defaultPageSize={15}
                            className="-striped -highlight"
                        />
                        <br />
                    </div>
                </Paper>
                <div style={{ textAlign: "center" }}>
                    <em>Tip: Hold shift when sorting to multi-sort!</em>
                </div>;
                 <Grid item lg={12} >
                    {dialogContent}
                </Grid>
            </Grid>
        </div>
	);
};

Support.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withRouter(withStyles(styles, { withTheme: true })(Support));