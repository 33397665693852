import log from 'loglevel';
import moment from 'moment';
import dashFetch from '../utils/dashFetch';


export default async function (data, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('deleteAccountDataApi');
		const url = `${global.dashServerUrl}/dashboard/api/v1/dashboard/deleteAccountData`;

		const body = {
			...data
		};


		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info('>>> deleteAccountData JSON');
			log.info(json)
			if (json.success) {
				resolve(json.data);
			} else {
				resolve(null)
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}