import log from 'loglevel';
import moment from 'moment';
import dashFetch from '../utils/dashFetch';


export default async function (siteCode, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('getLastConnectedAtApi');
		const url = `${global.dashServerUrl}/api/v1/restaurant/getLastConnectedAt`;

		const body = {
			siteCode
		};


		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info('>>> getLastConnectedAt JSON');
			log.info(json)
			if (json.success) {
				resolve(json.data.lastConnectedAt ? moment(json.data.lastConnectedAt) : null);
			} else {
				resolve(null)
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}