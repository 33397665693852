import log from 'loglevel';
import getToken from '../actions/getToken';
import { NotificationManager } from 'react-notifications';
import accessUtils from './accessUtils';

export default async function(url, body, debug = false, allowWithoutToken = false) {
	console.log(url)
	return new Promise(async (resolve, reject) => {
		let token = localStorage.token;
		let res = {};
		try {
			if (debug) log.info(`FETCHING FROM: ${url}`);
			if (debug) log.info('BODY');
			if (debug) log.info(body);
			const refreshToken = localStorage.refreshToken;
			const currentTime = Math.floor((new Date()).getTime() / 1000);
			const tokenTimeout = localStorage.tokenTimeout;
			const refreshTimeout = localStorage.refreshTimeout;
			if (refreshToken) {
				if (tokenTimeout < currentTime) {
					if (refreshTimeout < currentTime) {
						if (!token) {
							accessUtils.logout()
							let msg = "Please sign in again"
							res = { json: { success: false, errors: [msg] } }
							global.showNotification(msg, "error")
							reject()
						} else {
							token = await getToken(debug);
						}
					} else {
						token = await getToken(debug);
					}
				} else {
					token = await getToken(debug);
				}
			}
			if (!token && !allowWithoutToken) {
				let msg = "Please sign in"
				accessUtils.logout()
				reject(msg)
			}
			if (token || allowWithoutToken) {
				let options = {
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						'x-access-token': token,
					},
					body: JSON.stringify(body),
					cache: "no-store",
				}
				fetch(url, options, debug).then((res) => {
					if (debug) log.info(`STATUS: ${res.status}`);
					if (debug) log.info(`RESPONSE:`);
					if (debug) log.info(JSON.stringify(res));
					if (res.status === 401) {
						log.info("401 ERROR")
						res.json().then((json) => {
							if (debug) log.info("JSON RESPONSE");
							if (debug) log.info(json);
							if (json.userMessage) {
								accessUtils.logout()
								global.showNotification(json.userMessage, "error")
							}
							reject(json.userMessage);
						}).catch((err) => {
							reject(err.message);
						})
					}
					if (!res) {
						if (debug) console.error('(dashFetch) Could not fetch endpoint');
					} else if (res.ok) {
						res.json().then((json) => {
							if (debug) log.info("JSON RESPONSE");
							if (debug) log.info(json);
							if (json.userMessage) {
								global.showNotification(json.userMessage, "error")
							}
							resolve({ res: res, json: json })
						}).catch((err) => {
							reject(err.message);
						})
					} else {
						reject("An error occurred trying to connect to the Dash Server");
					}
				}).catch((err) => {
					reject(err.message);
				});
			}
		} catch (err) {
			if (debug) console.error(err);
			reject(err);
		}
	})
}
