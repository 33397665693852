import React, { useState } from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import ArrowDown from '@material-ui/icons/ArrowDownward'
import ArrowUp from '@material-ui/icons/ArrowUpward'

export default function Arrow(props) {

	let positive = props.percent >= 0

		const thatGood = {
			color: '#22BB22'
		}

		const thatBad = {
			color: '#B22222'
		}


	return (
		<Grid container justify="center">
			{positive ?
				<ArrowUp style={thatGood}/>
			:
				<ArrowDown style={thatBad}/>
			}
			{ props.label ?
				<Typography style={positive ? thatGood : thatBad}>{props.label}</Typography>
			: 
				<Typography style={positive ? thatGood : thatBad}>{props.percent}%</Typography>
			}
		</Grid>
	);
}
