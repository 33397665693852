import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import { Grid, Dialog, DialogActions, DialogContent, Button, Typography, Checkbox, Input, FormControlLabel, FormGroup, Select, MenuItem, Paper, ButtonGroup, IconButton, TableCell, TableBody, Table, TableRow, TableHead } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import SelectMui from '@material-ui/core/Select';
import upsertDocumentToCollectionApi from '../../actions/upsertDocumentToCollectionApi';
import DialogTitle from '../../dashboard/components/DialogTitle';

import log from 'loglevel'
import dashFetch from '../../utils/dashFetch';
import { DeleteForever } from '@material-ui/icons';
import theme from '../../theme';
import DInput from '../../components/dash/DInput';
import DForm2 from '../../components/dash/DForm2';
import DRecord from '../../components/dash/DRecord';

const styles = {
	infoBanner: {
		padding: 5,
		paddingTop: 15
	},
	paper: {
		padding: 10,
		width: '100%',
		height: '100%'
	},
	table: {
    },
    row: {
        padding:0
    }
}

const MODEL_NAME = 'PriceTemplate'

export default function (props) {
	// alert(JSON.stringify(props.permissions))
    const [record, setRecord] = useState(props.record)
    
    useEffect(() => {
        fetch()
    }, [])
   
    useEffect(() => {
        if (record && record.scheduledFees && record.scheduledFees.length == 0) {
            addFee()
        }
    }, [record])

    const fetch = async () => {
        if (!record) {
            let resp = await dashFetch(`${global.dashServerUrl}/dashboard/api/v1/dashboard/newSchemaInstance`, { schema: MODEL_NAME }, true);
			setRecord(resp.json.instance)
		}
    }

	const upsertRecord = async (record) => {
		try {
            record = await upsertDocumentToCollectionApi(record, MODEL_NAME)
            if (record) {
                global.showNotification("Success", 'success')
                props.refresh(true)
            }
			props.close()
		} catch (err) {
			log.error(err)
		}
	}
    
    const addFee = async () => {
        const newFee = {
            label: "",
            amount: 0,
        }
        let recordCopy = {...record}
        recordCopy.scheduledFees.push(newFee)
        setRecord({...record, scheduledFees: recordCopy.scheduledFees})
	}
    const removeFee = async (fee) => {
        setRecord({...record, scheduledFees: record.scheduledFees.removeItem(fee)})
	}

	return (
		<Dialog
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			maxWidth={'md'}
			open={true}
			style={{ margin: 0, height: '100%', width: '100%'}}
			onClose={ props.close }
		>
			{record && 
			<>
				<DialogTitle onClose={ props.close}>
					NEW PRICE TEMPLATE
				</DialogTitle>
				<DialogContent dividers style={{backgroundColor:'var(--background)'}}>
                    <DForm2 name="priceTemplate" record={record} margin="10px">
                        <Grid container>
                            <Grid item lg={12} container spacing={2}>
                                <Grid item lg={9}>
                                    <DInput
                                        label="Name"
                                        name="name"
                                    />
                                </Grid>
                                <Grid item lg={3}>
                                    <DInput
                                        label="Code"
                                        name="code"
                                        disabled
                                    />
                                </Grid>
                                <Grid item container lg={6}>
                                    <Paper style={{ padding: 20, paddingTop:12 }}>
                                        <Typography color="primary" variant="h5">Credit Card</Typography>
                                        <Grid container style={{padding:8}} spacing={2}>
                                            <Grid item lg={12}>
                                                <DInput
                                                    label="Price Type"
                                                    name="creditCard.priceType"
                                                    fieldType="Select"
                                                >
                                                    {record.priceTypes.map((pt) => <MenuItem value={pt}>{global.capitalize(pt)}</MenuItem>)}
                                                </DInput>
                                            </Grid>
                                            <Grid item lg={12}>
                                                <DInput
                                                    label="Monthly Minimum"
                                                    name="creditCard.monthlyMinimum"
                                                    InputProps={{startAdornment:<InputAdornment position="start">$</InputAdornment>}}
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid item lg={12}>
                                                <DInput
                                                    label="Authorization Fee"
                                                    name="creditCard.authorizationFee"
                                                    InputProps={{startAdornment:<InputAdornment position="start">$</InputAdornment>}}
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid item lg={12}>
                                                <DInput
                                                    label="AVS Fee"
                                                    name="creditCard.avsFee"
                                                    InputProps={{startAdornment:<InputAdornment position="start">$</InputAdornment>}}
                                                    type="number"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item lg={12}>
                                            <Table >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Card Type</TableCell><TableCell>Discount Fee</TableCell><TableCell>Transaction Fee</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell style={{color:'var(--dash-purple)'}}>Discover</TableCell>
                                                        <TableCell style={{paddingRight:10}}><DInput margin="none" type="number" name="creditCard.discover.discountFee" InputProps={{margin:'dense', endAdornment:<InputAdornment position="end">%</InputAdornment>}}/></TableCell>
                                                        <TableCell style={{paddingRight:10}}><DInput margin="none" type="number" name="creditCard.discover.transactionFee" InputProps={{margin:'dense', startAdornment:<InputAdornment position="start">$</InputAdornment>}}/></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{color:'var(--dash-purple)'}}>Master Card</TableCell>
                                                        <TableCell style={{paddingRight:10}}><DInput margin="none" type="number" name="creditCard.masterCard.discountFee" InputProps={{margin:'dense', endAdornment:<InputAdornment position="end">%</InputAdornment>}}/></TableCell>
                                                        <TableCell style={{paddingRight:10}}><DInput margin="none" type="number" name="creditCard.masterCard.transactionFee" InputProps={{margin:'dense', startAdornment:<InputAdornment position="start">$</InputAdornment>}}/></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{color:'var(--dash-purple)'}}>Visa</TableCell>
                                                        <TableCell style={{paddingRight:10}}><DInput margin="none" type="number" name="creditCard.visa.discountFee" InputProps={{margin:'dense', endAdornment:<InputAdornment position="end">%</InputAdornment>}}/></TableCell>
                                                        <TableCell style={{paddingRight:10}}><DInput margin="none" type="number" name="creditCard.visa.transactionFee" InputProps={{margin:'dense', startAdornment:<InputAdornment position="start">$</InputAdornment>}}/></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{color:'var(--dash-purple)'}}>American Express</TableCell>
                                                        <TableCell style={{paddingRight:10}}><DInput margin="none" type="number" name="creditCard.americanExpress.discountFee" InputProps={{margin:'dense', endAdornment:<InputAdornment position="end">%</InputAdornment>}}/></TableCell>
                                                        <TableCell style={{paddingRight:10}}><DInput margin="none" type="number" name="creditCard.americanExpress.transactionFee" InputProps={{margin:'dense', startAdornment:<InputAdornment position="start">$</InputAdornment>}}/></TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Paper>
                                </Grid>

                                <Grid item container lg={6}>
                                    <Grid item container lg={12}>
                                        <Paper style={{ padding: 20, paddingTop:12 }}>
                                            <Typography color="primary" variant="h5">ACH</Typography>
                                            <Grid container style={{padding:8}} spacing={2}>
                                                <Grid item lg={12}>
                                                    <DInput
                                                        label="Batch Fee"
                                                        name="ach.batchFee"
                                                        InputProps={{startAdornment:<InputAdornment position="start">$</InputAdornment>}}
                                                        type="number"
                                                    />
                                                </Grid>
                                                <Grid item lg={12}>
                                                    <DInput
                                                        label="Transaction Fee"
                                                        name="ach.transactionFee"
                                                        InputProps={{startAdornment:<InputAdornment position="start">$</InputAdornment>}}
                                                        type="number"
                                                    />
                                                </Grid>
                                                <Grid item lg={12}>
                                                    <DInput
                                                        label="Discount Fee"
                                                        name="ach.discountFee"
                                                        InputProps={{margin:'dense', endAdornment:<InputAdornment position="end">%</InputAdornment>}}
                                                        InputLabelProps={{shrink:true}}
                                                        type="number"
                                                    />
                                                </Grid>
                                                <Grid item lg={12}>
                                                    <DInput
                                                        label="Monthly Minimum"
                                                        name="ach.monthlyMinimum"
                                                        InputProps={{startAdornment:<InputAdornment position="start">$</InputAdornment>}}
                                                        type="number"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    <Grid item container lg={12} style={{marginTop:10}}>
                                        <Paper style={{ padding: 20, paddingTop:12 }} >
                                            <Grid item container lg={12} justify="space-between">
                                                <Typography color="primary" variant="h5">Scheduled Fees</Typography>
                                                <Button color="primary" variant="contained" onClick={addFee}>+ Add Fee</Button>
                                            </Grid>
                                            {record.scheduledFees.map((fee, index) => {
                                                return (
                                                    <DRecord record={record.scheduledFees[index]}>
                                                        <Grid container style={{marginTop:8, marginBottom:8, borderTop:'1px solid #ccc'}} spacing={2}>
                                                            <Grid container justify="space-between">
                                                                <Typography style={{fontSize:18, fontWeight:500}} color="primary">Fee #{index + 1}</Typography>
                                                                {index > 0 &&
                                                                    <IconButton style={{padding:4}} onClick={() => removeFee(fee)}><DeleteForever/></IconButton>
                                                                }
                                                            </Grid>
                                                            <Grid item lg={12}>
                                                                <DInput
                                                                    label="Period"
                                                                    name="period"
                                                                    fieldType="Select"
                                                                >
                                                                    {record.periods.map((p) => <MenuItem value={p}>{global.capitalize(p)}</MenuItem>)}
                                                                </DInput>
                                                            </Grid>
                                                            <Grid item lg={12}>
                                                                <DInput
                                                                    label="Label"
                                                                    name="label"
                                                                />
                                                            </Grid>
                                                            <Grid item lg={12}>
                                                                <DInput
                                                                    label="Amount"
                                                                    name="amount"
                                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                                    type="number"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </DRecord>
                                                )
                                            })}
                                        </Paper>
                                    </Grid>
                                </Grid>
                                
                            </Grid>
                        </Grid>
                    </DForm2>
				</DialogContent>
			</>}
			<DialogActions>
				<Button onClick={props.close}>
					Close
				</Button>
				<Button onClick={() => {upsertRecord(record);}}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	)

}
