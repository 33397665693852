import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { Redirect } from 'react-router-dom';
import { Grid, Tab, Tabs } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import log from 'loglevel';
import { withStyles } from '@material-ui/core/styles';
import getDashboardDataApi from '../actions/getDashboardDataApi';
import getFeedbackDataApi from '../actions/getFeedbackDataApi';
import LoyaltyChart from './components/LoyaltyChart'
import RevenueChart from './components/RevenueChart'
import DashUtilizationPie from './components/DashUtilizationPie'
import LoyalCustomersChart from './components/LoyalCustomersChart'
import TopWaitStaff from './components/TopWaitStaff'
import TopCustomersTable from './components/TopCustomersTable'
import TopItemsTable from './components/TopItemsTable'
import Banner from './components/Banner'
import Banner2 from './components/Banner2'
import Paper from '@material-ui/core/Paper';
import { lightGreen } from '@material-ui/core/colors';
import NumberFormat from 'react-number-format';
import SidePanel from './components/SidePanel'
import AverageFSA from './components/AverageFSA'
import FeedbackPanel from './components/FeedbackPanel'
import BuildIcon from '@material-ui/icons/Build';
import Arrow from './components/Arrow'


const styles = (theme) => ({
	'@global': {
		body: {
			backgroundColor: 'var(--background)',
		},
	},
	paper: {
		marginTop: "80",
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	paperWrapper: {
		// paddingTop: 2,
		// display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		height: '100%'
	},
	avatar: {
		margin: "10",
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '30%', // Fix IE 11 issue.
		marginTop: "10",
	},
	submit: {
		margin: "30, 0, 20",
	},
});

const DashboardSummary = ({classes, props}) => {
	const [dashboardData] = global.get('dashboardData')
	const [restaurant] = global.get('restaurant')

	const initialFilter = () => Number(localStorage.filterDays) ||  7
	const [activeDateRange, setActiveDateRange] = useState(initialFilter)

	const bannerData = {
		loyalCustomersTotal: (dashboardData && (dashboardData.length > 0)) ? dashboardData[dashboardData.length - 1].loyaltyTotalCustomers : 0,
		loyalCustomersTotalPercent: (dashboardData && (dashboardData.length > 0)) ? calculateNewLoyalCustomersAllTimePercent(dashboardData) : 0,
		loyalCustomersNew: (dashboardData && (dashboardData.length > 0)) ? dashboardData[dashboardData.length - 1].loyaltyTotalCustomers - dashboardData[0].loyaltyTotalCustomers : 0,
		loyalCustomersNewPercent: (dashboardData && (dashboardData.length > 0)) ? calculateNewLoyalCustomersPercent(dashboardData) : 0,
		
		loyaltyAverageCover: (dashboardData && (dashboardData.length > 0)) ? calculateAverageLoyalCover(dashboardData, activeDateRange) : 0,
		loyaltyAverageCoverPercent: (dashboardData && (dashboardData.length > 0)) ? calculateLoyaltyAverageCoverPercentInc(dashboardData, activeDateRange) : 0,
		averageCover: 0, // (dashboardData && (dashboardData.length > 0)) ? dashboardData[dashboardData.length - 1].loyaltyCustomerPurchasesDollars : 3210,
		averageCoverPercent: '0' //(dashboardData && (dashboardData.length > 0)) ? (dashboardData[dashboardData.length - 1].loyaltyCustomerPurchasesDollars - dashboardData[0].loyaltyCustomerPurchasesDollars).toFixed(2) : 3210,
	}

	const [activeTab, setActiveTab] = useState(0);
	const [feedbackData] = global.get('feedbackData')
	

	if (dashboardData) {
		// log.info(dashboardData)
	}

	const fetchData = async function() {
		let restaurantId = restaurant ? restaurant._id : ''
		if (restaurantId) {
			let data = await getDashboardDataApi(restaurantId, true)
			if (data) {
				if (data.dashboardCustomerData) {
					data.dashboardCustomerData = data.dashboardCustomerData.sort((a,b) => b.customerPaymentDollars - a.customerPaymentDollars)
				}
				if (data.dashboardPaidItemsData) {
					data.dashboardPaidItemsData = data.dashboardPaidItemsData.sort((a,b) => b.qty - a.qty)
				}
				if (data.dashboardPaidItemsData) {
					data.dashboardPaidItemsData = data.dashboardPaidItemsData.sort((a,b) => b.qty - a.qty)
				}
				// console.log("====================== pre:  data.dashboardLoyaltyData")
				// console.log(data.dashboardLoyaltyData)
				// if (data.dashboardLoyaltyData) {
				// 	data.dashboardLoyaltyData = data.dashboardLoyaltyData.sort((a,b) => b.aggregateDate < a.aggregateDate)
				// }
				// console.log("====================== post:  data.dashboardLoyaltyData")
				// console.log(data.dashboardLoyaltyData)

				if (data.dashboardWaiterData) {
					data.dashboardWaiterData = data.dashboardWaiterData.sort((a,b) => {
						if ((a.waiterServiceStars / a.waiterReviewCount) > (b.waiterServiceStars / b.waiterReviewCount)) return -1
						if ((a.waiterServiceStars / a.waiterReviewCount) < (b.waiterServiceStars / b.waiterReviewCount)) return 1
						if ((a.waiterServiceStars / a.waiterReviewCount) == (b.waiterServiceStars / b.waiterReviewCount)) return 0
					})
				}

				global.set({ dashboardDataUnfiltered: data.dashboardLoyaltyData, dashboardCustomerData: data.dashboardCustomerData, 
					dashboardPaidItemsData: data.dashboardPaidItemsData, dashboardWaiterData: data.dashboardWaiterData })
			}
			let fbackData = await getFeedbackDataApi(restaurantId)
		}
	}

	useEffect(() => {
		fetchData()
	}, [restaurant]) 

	const showSummary = () => {
		return (
			<>
				<div className={classes.paperWrapper} component="main" maxWidth="xs">
					<Grid container>
						<Grid item lg={9}>
							<Grid container>
								<Grid item lg={3} xs={11}>
									<Banner data={{ number: bannerData.loyalCustomersTotal, percent: bannerData.loyalCustomersTotalPercent }} label={"Loyal Customers"} />
								</Grid>
								<Grid item lg={3} xs={11}>
									<Banner data={{ number: bannerData.loyalCustomersNew, percent: bannerData.loyalCustomersNewPercent }} label={"New Loyal Customers"}/>
								</Grid>
								<Grid item lg={3} xs={11}>
									<Banner data={{ number: bannerData.loyaltyAverageCover, percent: 0,  prefix: "$" }} label={"Avg. Loyal Cover"} />
								</Grid>
								<Grid item lg={3} xs={11}>
									<Banner data={{ number: bannerData.averageCover, percent: bannerData.averageCoverPercent, prefix: "$" }} label={"Avg. Cover"} tooltipTitle="Coming soon!" />
								</Grid>
								<Grid item lg={6}>
									<Grid container direction="column" >
										<Grid item lg={12} xs={11}>
											<LoyaltyChart data={[1, 3, 5, 6, 8]} />
										</Grid>
										<Grid item lg={12} xs={11}>
											<LoyalCustomersChart data={[1, 3, 5, 6, 8]} />
										</Grid>
										<Grid item lg={12} xs={11}>
											<TopItemsTable />
										</Grid>
									</Grid>
								</Grid>
								<Grid item lg={6} xs={11}>
									<Grid container direction="column" >
										<Grid item lg={12} xs={11}>
											<Grid container >
												<AverageFSA data={''} />
											</Grid>
										</Grid>
										<Grid item lg={12} xs={11} style={{paddingTop: '1em'}}>
											<TopCustomersTable />
										</Grid>
										<Grid item lg={12} xs={11}>
											<TopWaitStaff />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid item lg={3} xs={11}>
							<Grid container direction="column">
								<FeedbackPanel data={feedbackData} />
							</Grid>
						</Grid>
					</Grid>
				</div>
			</>
		)
	}

	const showReview = () => {
		return (
			<center>
				<br />
				<br />
				<br />
				<Typography variant='h2'> Coming Soon!</Typography>
				<br />
				<BuildIcon fontSize={'large'} />
			</center>
		)
	}

	const showCampaigns = () => {
		return (
			<center>
				<br />
				<br />
				<br />
				<Typography variant='h2'> Coming Soon!</Typography>
				<br />
				<BuildIcon fontSize={'large'} />
			</center>
		)
	}

	const showShowCustomer = () => {
		return (
			<center>
				<br />
				<br />
				<br />
				<Typography variant='h2'> Coming Soon!</Typography>
				<br />
				<BuildIcon fontSize={'large'} />
			</center>
		)
	}

	const showStaff = () => {
		return (
			<center>
				<br />
				<br />
				<br />
				<Typography variant='h2'> Coming Soon!</Typography>
				<br />
				<BuildIcon fontSize={'large'} />
			</center>
		)
	}

	const showTransactions = () => {
		return (
			<center>
				<br />
				<br />
				<br />
				<Typography variant='h2'> Coming Soon!</Typography>
				<br />
				<BuildIcon fontSize={'large'} />
			</center>
		)
	}

	const showItems = () => {
		return (
			<center>
				<br />
				<br />
				<br />
				<Typography variant='h2'> Coming Soon!</Typography>
				<br />
				<BuildIcon fontSize={'large'} />
			</center>
		)
	}




	return (
		<Grid container>
			<div className={classes.root}>
				{dashboardData && showSummary()}
				{/* <Grid container spacing={0}>
          <Grid className={classes.tabs} item lg={12}>
            <Tabs
              value={activeTab}
              onChange={(e, n) => {
                setActiveTab(n);
              }}
              classes={{
                indicator: classes.indicator
              }}
              style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
            >
              <Tab selected label="Summary" />
              <Tab label="Review" />
              <Tab label="Campaigns" />
              <Tab label="Customer" />
              <Tab label="Staff" />
              <Tab label="Transactions " />
            </Tabs>
          </Grid>
          {console.log('activeTab: ' + activeTab)}
          <Grid item lg={12} className={classes.restaurantForm}>
            {activeTab == 0 && showSummary()}
            {activeTab == 1 && showReview()}
            {activeTab == 2 && showCampaigns()}
            {activeTab == 3 && showShowCustomer()}
            {activeTab == 4 && showStaff()}
            {activeTab == 5 && showTransactions ()}
          </Grid>
        </Grid> */}

			</div>
		</Grid>
	);
}


const calculateLoyaltyAverageCoverPercentInc = (dashboardData, activeDateRange) => {
	const currentPurchase = dashboardData.slice(0, activeDateRange).reduce((s, f) => s + f.loyaltyCustomerPurchasesDollars, 0)
	const previousPurchase = dashboardData.slice(activeDateRange).reduce((s, f) => s + f.loyaltyCustomerPurchasesDollars, 0)

	const currentPurchaseCount = dashboardData.slice(0, activeDateRange).reduce((s, f) => s + f.loyaltyCustomerPurchasesCount, 0)
	const previousPurchaseCount = dashboardData.slice(activeDateRange).reduce((s, f) => s + f.loyaltyCustomerPurchasesCount, 0)

	const currentPurchaseAvg = currentPurchase / currentPurchaseCount
	const previousPurchaseAvg = previousPurchase / previousPurchaseCount
	
	return ((currentPurchaseAvg - previousPurchaseAvg) / previousPurchaseAvg).toFixed(2)
}

const calculateAverageLoyalCover = (loyaltyData, activeDateRange) => {
	let totalLoyaltyCustomerPurchasesCount = 0
	let totalLoyaltyCustomerPurchasesDollars = 0
	
	for (let i = 0; i < activeDateRange-1; i++) {  //loop through the array
		if (loyaltyData[i]) {
			totalLoyaltyCustomerPurchasesCount += loyaltyData[i].loyaltyCustomerPurchasesCount
			totalLoyaltyCustomerPurchasesDollars  += loyaltyData[i].loyaltyCustomerPurchasesDollars
		}
	}

	return totalLoyaltyCustomerPurchasesDollars / (totalLoyaltyCustomerPurchasesCount ? totalLoyaltyCustomerPurchasesCount : 1)
}

const calculateNewLoyalCustomersPercent = (dashboardData) => {
	const currentLoyaltyCustomers = dashboardData[dashboardData.length - 1].loyaltyTotalCustomers
	const beginningOfRangeLoyaltyCustomers = dashboardData[0].loyaltyTotalCustomers > 0 ? dashboardData[0].loyaltyTotalCustomers : 1
	return ((currentLoyaltyCustomers - beginningOfRangeLoyaltyCustomers) / beginningOfRangeLoyaltyCustomers).toFixed(2)
}

const calculateNewLoyalCustomersAllTimePercent = (dashboardData) => {
	const currentLoyaltyCustomers = dashboardData[dashboardData.length - 1].loyaltyTotalCustomers
	const beginningOfRangeLoyaltyCustomers = dashboardData[0].loyaltyTotalCustomers > 0 ? dashboardData[0].loyaltyTotalCustomers : 1
	return ((currentLoyaltyCustomers - beginningOfRangeLoyaltyCustomers) / currentLoyaltyCustomers).toFixed(2)
}


export default withStyles(styles, { withTheme: true })(DashboardSummary);