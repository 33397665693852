import log from 'loglevel';
import { NotificationManager } from 'react-notifications';
import dashFetchUpload from '../utils/dashFetchUpload';


export default async function (customer, amount, restaurant, orderNumber, description, accountId, textOnHoldUntil, attachment, sendEmail = true, sendText = true, persists = false, debug) {
	return new Promise(async(resolve, reject) => {
		if (debug) log.info('createOrAddToOrder api');
		const url = `${global.dashServerUrl}/dashboard/api/v1/textToPay/createOrAddToOrder`;

		const formData = new FormData();


		if (attachment) formData.append('file', attachment);
		if (customer) formData.append('customer', JSON.stringify(customer));
		if (orderNumber) formData.append('orderNumber', orderNumber);
		if (amount) formData.append('amount', amount);
		if (description) formData.append('description', description);
		if (restaurant) formData.append('restaurant', restaurant);
		if (accountId) formData.append('accountId', accountId);
		if (textOnHoldUntil) formData.append('textOnHoldUntil', textOnHoldUntil);
		if (accountId) formData.append('accountId', accountId);
		if (persists != undefined) formData.append('persists', persists);
		if (sendEmail != undefined) formData.append('sendEmail', sendEmail);
		if (sendText != undefined) formData.append('sendText', sendText);


		try {
			let response = await dashFetchUpload(url, formData)
			if (response.data && response.data.success) {
				if (debug) log.info('success');
				log.info(response.data);
				resolve(true);
			} else {
				if (response.data) {
					log.error(response.data.errors)
					global.showNotification(response.data.errors, 'error')
				}
				resolve(false)
			}

		} catch (e) {
			log.error(e)
			reject(false);
		}

	})

}
