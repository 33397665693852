import React, { useEffect, useState } from 'react';
// import { slide as Menu } from 'react-burger-menu'
import theme from '../theme.js'
import Header from './Header'
import TopNav from './TopNav'
import { Box, Grid, MenuItem, Menu, Button, IconButton, Paper, Typography, Snackbar, Slide, Tooltip} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import accessUtils from '../utils/accessUtils';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function TransitionDown(props) {
	return <Slide {...props} direction="down" />; 
}
export default (props) => {	

	let isMobile = useMediaQuery('(max-width:600px)')

	let signedIn = accessUtils.isLoggedIn()
	let [alert2] = global.get('alert2')
	let [navBar] = global.get('navBar')
	let [restaurantId] = global.get('restaurantId')

	const { children, classes } = props;
	return (
		<>
			<Snackbar TransitionComponent={TransitionDown} open={alert2 && alert2.show} autoHideDuration={alert2 && alert2.duration ? alert2.duration : 2500} anchorOrigin={{vertical:'top', horizontal:'center'}} onClose={(event, reason) => global.hideNotification(alert2, event, reason)} style={{zIndex: 1111111}}>
				<MuiAlert elevation={6} variant="filled" severity={alert2 && alert2.severity} onClose={(event, reason) => global.hideNotification(alert2, event, reason)}>
					{alert2 && alert2.message}
				</MuiAlert>
			</Snackbar>

			<Grid direction="column" alignItems="stretch" container style={{height: '100%', overflow: 'hidden'}}>

				{(signedIn || restaurantId) && props.showHeader && 
					<Grid item>
						<Header />
					</Grid>
				}

				{signedIn && props.showTopNav && !isMobile && (global.isDashNow() || navBar == 'admin') &&
					<Grid item style={{height: '3.5em'}}>
						<TopNav />
					</Grid>
				}

				<Grid id="bodylayout" xs item style={{overflowX: 'hidden', overflowY: 'auto', backgroundColor: 'var(--background)'}}>
					{children}
				</Grid>

			</Grid>

		</>
	)
}