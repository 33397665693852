import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Typography, Divider, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from '../../theme'
import PaidStamp from '../../assets/svgs/PaidStamp.js';
import { renderToStaticMarkup } from 'react-dom/server';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 370,
  },
  tableHeader: {
      fontSize: '12px',
      lineHeight: '1.1'
  }
});

export default function StickyHeadTable(props) {
    const classes = useStyles();
    const [restaurant] = global.get('restaurant')
    let [displayItemName] = global.get('displayItemName')
    let [displayItemDescription] = global.get('displayItemDescription')
    let [displayItemModifiers] = global.get('displayItemModifiers')
    const isTextPay = global.isTextPay()
    let check = props.data
    const isComplete = check.paidInFull
    const xsSmallScreen = useMediaQuery(theme().breakpoints.down('xs'));

    let renderItems = check.itemsGrouped
    let checkTotals = check.totals
    const small = props.small


    const totalsRows = [
        {  
            label:'Subtotal',
            value: global.f(checkTotals, 'subTotal', 0),
        },
        {  
            label:'Taxes',
            value: global.f(checkTotals, 'discountsAndDuties.taxesTotal', 0)
        },
        {  
            label:'Discounts',
            value: global.f(checkTotals, 'discountsAndDuties.discountsTotal', 0)
        },
        {  
            label:'Other',
            value: global.f(checkTotals, 'discountsAndDuties.otherTotal', 0)
        },
        {  
            label:'Auto-Tip',
            value: global.f(checkTotals, 'autoTip', 0)
        },

    ]
    
    totalsRows.push(
        {  
            label: restaurant.getVal('config.mobile.serviceCharges.ccServiceChargeLabel'),
            value: global.f(checkTotals, 'ccServiceChargeAmount', 0)
        },

    )
    totalsRows.push(
        {  
            label: restaurant.getVal('config.mobile.serviceCharges.achServiceChargeLabel'),
            value: global.f(checkTotals, 'achServiceChargeAmount', 0)
        },

    )

    let prevPaidAmount = checkTotals.getVal('paidOtherCards', 0) + checkTotals.getVal('paidCash', 0) + checkTotals.getVal('paidDashNow', 0)
    
    if (isTextPay) totalsRows.push({label:'Paid', value: prevPaidAmount})

    const balanceDue = checkTotals.getVal('due', 0)



    const hideSubtotalsDiv = global.f(checkTotals, 'subTotal', 0) == global.f(checkTotals, 'total', 0)

    const qtyColWidth = '20%'
    const itemColWidth = '60%'
    const priceColWidth = '20%'

    const smallScreenSize = useMediaQuery(theme().breakpoints.down('sm'));
    const conditionalTableScrollStyles = (small || smallScreenSize) ? {overflow:'auto'} : {minHeight: '100px', height: 'calc(100vh - 425px)', maxHeight: '280px', overflow:'auto', marginBottom: isComplete ? '-32.5px' : '0'}

    // const svgString = encodeURIComponent(renderToStaticMarkup(<PaidStamp opacity={.5} height={xsSmallScreen ? 120 : 180} width={xsSmallScreen ? 110 : 170}/>))
    const svgString = encodeURIComponent(renderToStaticMarkup(<PaidStamp opacity={.5} height={xsSmallScreen ? 120 : 180} width={xsSmallScreen ? 110 : 170} fill={global.primaryColor}/>))
    const dataUri = `url("data:image/svg+xml,${svgString}")`;

    return (
        <>
            <Grid container direction="row" justify="space-between" alignItems="stretch" alignContent="stretch" style={{height:'calc(100% - 30px)', backgroundImage:isComplete && dataUri, backgroundRepeat:'no-repeat', backgroundPosition:'center'}}>
                <Grid item xs={12} md={12} lg={12} style={{height:'min-content'}}>
                    <Table size="small" style={{height:'100%'}}>
                        <TableHead>
                            <TableRow style={{position:'sticky'}}>
                                <TableCell align="left" style={{position:'sticky', top:0, width: qtyColWidth}} classes={{root: classes.tableHeader}}>
                                    Qty
                                </TableCell>
                                <TableCell align="left" style={{width: itemColWidth}} classes={{root: classes.tableHeader}}>
                                    Item
                                </TableCell>
                                <TableCell align="right" style={{width: priceColWidth}} classes={{root: classes.tableHeader}}>
                                    Price
                                </TableCell>
                            </TableRow>
                        
                        </TableHead>
                    </Table>
                    <div style={conditionalTableScrollStyles}>
                        <Table size="small">
                            <TableHead style={{display:'none'}}>
                                <TableRow>
                                    <TableCell align="left" style={{width:qtyColWidth}} classes={{root: classes.tableHeader}}>
                                        Qty
                                    </TableCell>
                                    <TableCell align="left" style={{width:itemColWidth}} classes={{root: classes.tableHeader}}>
                                        Item
                                    </TableCell>
                                    <TableCell align="right" style={{width:priceColWidth}} classes={{root: classes.tableHeader}}>
                                        Price
                                    </TableCell>
                                </TableRow>
                            
                            </TableHead>
                            <TableBody style={{ width: '100%'}}>
                                {renderItems.map((item, index) => {
                                    let itemDisplayName = ""
                                    if(restaurant && displayItemName && item.name){
                                        itemDisplayName += item.name
                                    }
                                    if(restaurant && displayItemDescription && item.description){
                                        if(itemDisplayName != ""){
                                            itemDisplayName += " - "
                                        }
                                        itemDisplayName += item.description
                                    }
                                    //Don't show the row if there is no name or price
                                    if (!((!itemDisplayName || itemDisplayName.trim() == "") && (!item.price || item.price == 0))) {
                                        return (
                                            <>
                                                <TableRow key={item.name + '-' + item.description} style={{border:'0px'}}>
                                                    <TableCell style={{border:'0px', width: qtyColWidth}}>{item.qty && item.qty > 0 ? item.qty : ' '} </TableCell>
                                                    <TableCell style={{border:'0px', width:itemColWidth}}>{itemDisplayName}</TableCell>
                                                    <TableCell style={{border:'0px', textAlign:'right', width: priceColWidth}}>{(item.price && item?.price != 0) ? global.formatMoney(item?.price) : ""}</TableCell>
                                                </TableRow>
                                                <TableRow key={index+"modifier"}>
                                                    {restaurant && displayItemModifiers && item.modifierString && <>
                                                        <TableCell style={{paddingTop:0}}></TableCell>
                                                        <TableCell style={{fontSize: '.9em', paddingLeft: '2em', paddingTop:0}}>{'- '}{item.modifierString}</TableCell>
                                                        <TableCell style={{paddingTop:0}}></TableCell>
                                                        </>
                                                    }
                                                </TableRow>
                                            </>
                                        );
                                    }
                                })}
                            </TableBody>    
                        </Table>
                    </div>
                </Grid>
                
                {(check.customerMessage || check.memo) && <Grid item xs={12} style={{width: '100%', padding:0}} id="extrasContainer">
                    <Table id="receiptTableExtras" style={{marginTop: 5}} size='small'>
                        <TableBody>
                            {check.customerMessage && 
                                <TableRow style={{border:'0px'}}><TableCell style={{border:'0px'}}>Customer Message: {check.customerMessage}</TableCell></TableRow>}
                            {check.memo && 
                                <TableRow style={{border:'0px'}}><TableCell style={{border:'0px'}}>Memo: {check.memo}</TableCell></TableRow>}
                        </TableBody>
                    </Table>
                </Grid>}

                
                <Grid item xs={12} style={{width: '100%', borderTop: !hideSubtotalsDiv && '1px solid var(--divider)', padding:0}} id="totalsContainer">
                    <Table id="receiptTableTotals" style={{marginTop: 5}} size='small'>
                        <TableBody>
                            {totalsRows.map((item, index) => {
                                if(item.value == 0) return

                                if (!small || item.value > 0) {
                                    return (
                                        <TableRow key={item.label} style={{border:'0px', borderTop: index == totalsRows - 1 && '1px solid var(--divider)'}}>
                                            <TableCell style={{border:'0px'}}>{item.label}</TableCell>
                                            <TableCell style={{border:'0px', textAlign:'right'}}>{global.formatMoney(item.value)}</TableCell>
                                        </TableRow>
                                    );
                                }
                            })}
                            <TableRow id="receiptTableTotalsRow" key={"check-total"} style={{borderTop: '1px solid var(--divider)', padding:0 }}>
                                <TableCell style={{border:'0px', fontWeight:'500', fontSize:'16px'}}>{isTextPay ? 'Balance Due' : 'Total'}</TableCell>
                                <TableCell style={{border:'0px', fontWeight:'500', textAlign:'right', fontSize:'16px'}}>$ {isTextPay ? global.formatMoney(balanceDue) : global.formatMoney(checkTotals.total)}</TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </Grid>

            </Grid>

        </>
    );
}