import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import log from 'loglevel';
import _ from 'lodash';
import {
	Grid,
	Typography,
	Tabs,
	Tab,
	Paper,
	Checkbox,
	FormControlLabel,
	FormControl,
	InputLabel,
	ClickAwayListener,
	MenuItem,
	Tooltip,
	Button,
	Box,
} from '@material-ui/core';
import { 
	ToggleButtonGroup,
	ToggleButton,
} from '@material-ui/lab'
import SelectMui from '@material-ui/core/Select';
import tokenizeAchApi from "../../actions/tokenizeAchApi";
import DForm2 from '../../components/dash/DForm2';
import DRecord from '../../components/dash/DRecord';
import DInput from '../../components/dash/DInput';

import AddCardPanel from './AddCardPanel'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CreditCardIcon from '@material-ui/icons/CreditCard';


const styles = (theme) => ({
	root: {},
	toggleSelected: {
		backgroundColor: "#8B6BD9 !important;",
		color: "var(--white) !important;",
		'& p': {
			color: "var(--white) !important;",
			fontWeight: '500 !important',
		}
	}
});

const Dash = (props) => {
	
	const { classes } = props;
	let [restaurant] = global.get('restaurant', null);
	let defaultForm = {paymentType: "ach", ach: {bankName: '', nameOnAccount: '', routingNumber: '', accountNumber: ''}}
	if (!restaurant.config.billing.acceptAch && restaurant.config.billing.acceptCreditCard) {
		defaultForm.paymentType = 'creditCard';
	}

	// GET EncryptedCard from billingAppliesTo source
    let encryptedCard = restaurant && restaurant.billingInfo && restaurant.billingInfo.encryptedCard ? restaurant.billingInfo.encryptedCard : null
	if (restaurant && restaurant.billingInfo.billingAppliesTo !== "restaurant") {
		if (restaurant.billingInfo.billingAppliesTo === "restaurantGroup") {
			encryptedCard = restaurant.restaurantGroup.billingInfo.encryptedCard
		} else if (restaurant.billingInfo.billingAppliesTo === "agency") {
			encryptedCard = restaurant.agent.info.agency.billingInfo.encryptedCard
		}
	}

    const [ formData, setFormData ] = useState(defaultForm)
    const [ billingState, setBillingState ] = useState("")
	const [ saving, setSaving ] = useState(false)
	const [ editing, setEditing ] = useState(false)

	let expYear = encryptedCard ? encryptedCard.expYear : ''
	if (expYear && expYear > 2000) {
		expYear = expYear - 2000
	}

	useEffect(() => {
		setSaving(false)
		setEditing(false)
	}, [restaurant])


	const tokenizePayment = async (encryptedCard) => {
		if (formData) {
			console.log("TOKENIZING ACH PAYMENT DATA")
			console.log("formData")
			console.log(JSON.stringify(formData))
			if (restaurant && formData.ach.bankName && formData.ach.nameOnAccount && formData.ach.routingNumber && formData.ach.accountNumber) {
				console.log("verified")
				let encryptedCard = await tokenizeAchApi(restaurant, formData, true)

				let rest = {...restaurant}
				//THIS CODE IS DUPLICATED IN AddCardPanel.js FYI
				if (rest.billingInfo.billingAppliesTo === "restaurant") {
					rest.billingInfo.encryptedCard = encryptedCard
					rest.billingInfo.encryptedCardStatus = ''
				} else if (rest.billingInfo.billingAppliesTo === "restaurantGroup") {
					rest.restaurantGroup.billingInfo.encryptedCard = encryptedCard
					rest.restaurantGroup.billingInfo.encryptedCardStatus = ''
				} else if (rest.billingInfo.billingAppliesTo === "agency") {
					rest.agent.info.agency.billingInfo.encryptedCard = encryptedCard
					rest.agent.info.agency.billingInfo.encryptedCardStatus = ''
				}
				global.set({restaurant: rest})
				return encryptedCard
			}
		}
		return null;
	}

	let saveForm = () => {
        setSaving(true)
		let onValid = async () => {
			setSaving(false)
			setEditing(false)
			await tokenizePayment()
			setFormData(defaultForm)
		}
		let onError = async () => {
			setSaving(false)
		}
		global.set({onValid, onError, validateNow: true})
	};

	let cancelForm = () => {
        setSaving(false)
		setEditing(false)
		setFormData(defaultForm)
	};

	return (
		<Grid item style={{display: 'flex', flexGrow: 1, width: '100%'}}>
			{restaurant && 
				<Paper elevation={1} style={{width: '100%'}}>
					<Typography align="center" variant="h5" style={{paddingTop: 10, paddingBottom: 20}}>Payment Method</Typography>
					{!encryptedCard && !editing && 
						<Box align="center">
							<Typography variant="body2" style={{paddingTop: 10, paddingBottom: 20, fontStyle: 'italic'}}>Please add your payment method to prevent a disruption in service</Typography>
							<Button variant="contained" color="primary" onClick={() => setEditing(true) }>
								Add Payment Method
							</Button>
						</Box>
					}
					{encryptedCard && !editing && 
						<Box align="center">
							{encryptedCard.paymentType == "creditCard" && 
								<Grid container alignItems="center" justify="center" style={{marginBottom: 25}}>
									<Grid item xs={12}>
										<Typography style={{padding: 5}}>{encryptedCard.fullName}</Typography>
									</Grid>
									<Grid item>
										<CreditCardIcon fontSize="medium" />
									</Grid>
									<Grid item>
										<Typography style={{padding: 5, paddingRight: 30}}>{encryptedCard.type.toUpperCase()}</Typography>
									</Grid>
									<Grid item>
										<Typography style={{padding: 5}}>********** {encryptedCard.lastFour}</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography style={{padding: 5}}>(Expires {encryptedCard.expMonth}/{expYear})</Typography>
									</Grid>
								</Grid>
							}
							{encryptedCard.paymentType == "ach" && 
								<Grid container alignItems="center" justify="center" style={{marginBottom: 25}}>
									<Grid container alignItems="center" justify="center" item xs={12}>
										<Grid item>
											<AccountBalanceIcon fontSize="medium" />
										</Grid>
										<Grid item>
											<Typography style={{padding: 5, paddingRight: 30}}>{encryptedCard.ach.bankName}</Typography>
										</Grid>
									</Grid>
									<Grid item xs={5} align="left">
										<Typography style={{padding: 5}}>Name on Account:</Typography>
									</Grid>
									<Grid item xs={5} align="left">
										<Typography style={{padding: 5}}>{encryptedCard.ach.nameOnAccount}</Typography>
									</Grid>
									<Grid item xs={5} align="left">
										<Typography style={{padding: 5}}>Routing Number</Typography>
									</Grid>
									<Grid item xs={5} align="left">
										<Typography style={{padding: 5}}>*** *** {encryptedCard.ach.routingNumberLast4}</Typography>
									</Grid>
									<Grid item xs={5} align="left">
										<Typography style={{padding: 5}}>Account Number</Typography>
									</Grid>
									<Grid item xs={5} align="left">
										<Typography style={{padding: 5}}>*** *** {encryptedCard.ach.accountNumberLast4}</Typography>
									</Grid>
								</Grid>
									
							}
							
							<Button variant="contained" color="primary" onClick={() => setEditing(true) }>
								Update
							</Button>
						</Box>
					}

					{editing && 
						
						<DForm2 name="billingInfo" validate={true}>
							<Grid item xs={12} style={{marginLeft: 20, marginRight: 20}}>
								<DRecord record={formData} name="formData" />
									<>
										<DRecord record={formData.ach} name="ach" />
										<Typography variant="body2" style={{marginBottom: 25}}>All payment information is securely stored through our partner Spreedly, with the highest level of PCI compliance.</Typography>

										{restaurant.config.billing.acceptAch && restaurant.config.billing.acceptCreditCard && 
											<ToggleButtonGroup name="paymentType" style={{width: '100%', marginBottom: 20}} exclusive rerender>
												<ToggleButton onClick={() => setFormData({...formData, paymentType: "ach"})} selected={formData.paymentType == "ach"} classes={{selected: classes.toggleSelected}} value="ach">
													<Typography variant="body1" color="primary" className={classes.subheadingText}>ACH</Typography>
												</ToggleButton>
												<ToggleButton onClick={() => setFormData({...formData, paymentType: "creditCard"})} selected={formData.paymentType == "creditCard"} classes={{selected: classes.toggleSelected}} value="creditCard">
													<Typography variant="body1" color="primary" className={classes.subheadingText}>Credit Card</Typography>
												</ToggleButton>
											</ToggleButtonGroup>
										}
										
										{formData.paymentType == "ach" && 
											<Grid container spacing={1}>
												{/* https://docs.spreedly.com/guides/echecks/ */}
												{/* <form accept-charset="UTF-8" action="https://core.spreedly.com/v1/payment_methods" method="POST">
													<fieldset>
														<label for="full_name">Name on Account</label>
														<input id="full_name" name="full_name" type="text" />

														<label for="bank_name">Bank Name</label>
														<input id="bank_name" name="bank_name" type="text" />

														<label for="bank_routing_number">Bank Routing Number</label>
														<input id="bank_routing_number" name="bank_routing_number" type="text" />

														<label for="bank_account_number">Account Number</label>
														<input id="bank_account_number" name="bank_account_number" type="text" />

														<button type='submit'>Submit Payment</button>
													</fieldset>
												</form> */}
												<Grid item xs={12} sm={6}>
													<DInput name="bankName" label="Bank Name" required />
												</Grid>
												<Grid item xs={12} sm={6}>
													<DInput name="nameOnAccount" label="Name on Account" required />
												</Grid>
												<Grid item xs={12} sm={6}>
													<DInput name="routingNumber" label="Routing Number" required />
												</Grid>
												<Grid item xs={12} sm={6}>
													<DInput name="accountNumber" label="Account Number" required />
												</Grid>
												<Grid item xs={12} sm={6} style={{marginTop: 15}}>
													<Button id="submit-button" variant='contained' color='primary' onClick={() => saveForm()} disabled={saving} style={{marginBottom: 15}}>
														<Typography>Save</Typography>
													</Button>
													<Button id="submit-button" variant='contained' color='secondary' onClick={() => cancelForm()} disabled={saving} style={{marginLeft: 10, marginBottom: 15}}>
														<Typography>Cancel</Typography>
													</Button>
												</Grid>
											</Grid>
										}
										<Grid container spacing={1}>
											{formData.paymentType  == "ach" && false && <>
												<Grid item xs={12} md={12} lg={12} xl={12}>
													<Typography variant="body1" color="primary" className={styles.subheadingText}>
														Billing Address
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<div>
														<DInput name="addressLine1" label="Address Line 1" required />
													</div>
												</Grid>
												<Grid item xs={12}>
													<div>
														<DInput name="addressLine2" label="Address Line 2" />
													</div>
												</Grid>
												<Grid item xs={12}>
													<div>
														<DInput name="city" label="City" required />
													</div>
												</Grid>
												<Grid item xs={12}>
													<FormControl style={{ width: '98%', }} InputLabelProps={{ shrink: true }}>
														<InputLabel shrink htmlFor="state">State</InputLabel>
														<SelectMui
															InputLabelProps={{ shrink: true }}
															inputProps={{ id: 'state' }}
															value={billingState}
															// onChange={(e) => setRequest(e, `data.state`)}  //todo fix
														>
															<MenuItem disabled value=""><em>State</em></MenuItem>
															{states.map((pt) => <MenuItem value={pt.value}>{pt.label}</MenuItem>)}
														</SelectMui>
													</FormControl>
												</Grid>
												<Grid item xs={12}>
													<div style={{  }}>
														<DInput name="zipCode" label="Zip Code" required />
													</div>
												</Grid>
											</>}
										</Grid>
									</>
								{formData.paymentType != "ach" &&
									<AddCardPanel cancelForm={() => cancelForm()} />
								}
							</Grid>
						</DForm2>

					}
				</Paper>
			}
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(Dash);



const states = [
	{ value: 'AL', label: 'Alabama' },
	{ value: 'AK', label: 'Alaska' },
	{ value: 'AZ', label: 'Arizona' },
	{ value: 'AR', label: 'Arkansas' },
	{ value: 'CA', label: 'California' },
	{ value: 'CO', label: 'Colorado' },
	{ value: 'CT', label: 'Connecticut' },
	{ value: 'DE', label: 'Delaware' },
	{ value: 'FL', label: 'Florida' },
	{ value: 'GA', label: 'Georgia' },
	{ value: 'HI', label: 'Hawaii' },
	{ value: 'ID', label: 'Idaho' },
	{ value: 'IL', label: 'Illinois' },
	{ value: 'IN', label: 'Indiana' },
	{ value: 'IA', label: 'Iowa' },
	{ value: 'KS', label: 'Kansas' },
	{ value: 'KY', label: 'Kentucky' },
	{ value: 'LA', label: 'Louisiana' },
	{ value: 'ME', label: 'Maine' },
	{ value: 'MD', label: 'Maryland' },
	{ value: 'MA', label: 'Massachusetts' },
	{ value: 'MI', label: 'Michigan' },
	{ value: 'MN', label: 'Minnesota' },
	{ value: 'MS', label: 'Mississippi' },
	{ value: 'MO', label: 'Missouri' },
	{ value: 'MT', label: 'Montana' },
	{ value: 'NE', label: 'Nebraska' },
	{ value: 'NV', label: 'Nevada' },
	{ value: 'NH', label: 'New Hampshire' },
	{ value: 'NJ', label: 'New Jersey' },
	{ value: 'NM', label: 'New Mexico' },
	{ value: 'NY', label: 'New York' },
	{ value: 'NC', label: 'North Carolina' },
	{ value: 'ND', label: 'North Dakota' },
	{ value: 'OH', label: 'Ohio' },
	{ value: 'OK', label: 'Oklahoma' },
	{ value: 'OR', label: 'Oregon' },
	{ value: 'PA', label: 'Pennsylvania' },
	{ value: 'RI', label: 'Rhode Island' },
	{ value: 'SC', label: 'South Carolina' },
	{ value: 'SD', label: 'South Dakota' },
	{ value: 'TN', label: 'Tennessee' },
	{ value: 'TX', label: 'Texas' },
	{ value: 'UT', label: 'Utah' },
	{ value: 'VT', label: 'Vermont' },
	{ value: 'VA', label: 'Virginia' },
	{ value: 'WA', label: 'Washington' },
	{ value: 'WV', label: 'West Virginia' },
	{ value: 'WI', label: 'Wisconsin' },
	{ value: 'WY', label: 'Wyoming' }
];