import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import "./css/build/tailwind.css";
import './css/index.css';
import App from './App';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme'
import dash from './dash';


import history from './history';
import 'typeface-barlow';
import { useEffect } from 'reactn';

dash();


function Index (props) {	
	const [primaryColor] = global.get('primaryColor')
	const [darkMode] = global.get('darkMode')

	
	return (
		<Router history={history}>
			<ThemeProvider theme={theme(primaryColor, darkMode)}>
				<App />
			</ThemeProvider>
		</Router>
	)
};

const element = <Index/>
ReactDOM.render(
  element,
  document.getElementById('root')
);
