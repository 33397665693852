import log from 'loglevel';
import dashFetch from '../utils/dashFetch';


export default async function (requestId, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('findRestaurantOnboardingRequest api');
		const url = `${global.dashServerUrl}/dashboard/api/v1/request/findRestaurantOnboardingRequest`;


		const body = {
			requestId,
		};
		let options = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		}

		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info("json")
			log.info(json)
			if (json.success) {
				//account has password
				resolve(json.data);
			} else {
				resolve(false)
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}