import React, { useState, useEffect } from 'react';
import { useGlobal, setGlobal } from 'reactn';
import log from 'loglevel';
import _ from 'lodash';
import DRecord from './DRecord';

const uuidv4 = require('uuid/v4');
global.get = useGlobal;
global.set = setGlobal;

export default function (props) {
	let [validateNow] = global.get("validateNow")
	let [hasChanges, setHasChanges] = global.get("hasChanges")
	let [onValid] = global.get('onValid');
	let [onError] = global.get('onError');
	let [dform] = global.get('dform');
	let [records] = useState({})
	let [lastRecord] = useState()
	let [hidden] = useState(false)
    let [dformId] = useState(uuidv4())

	// log.info("RENDERING DFORM: " +  props.name + " > " + dformId)

	let validate = (validateRulesOnly = false) => {
		let isValid = true
		if (validateNow || validateRulesOnly) {
			if (!hidden) {
				log.info("VALIDATING DFORM: " +  props.name ? props.name : '')
				if (props.validate !== false) {
					if (records) {
						// log.info(JSON.stringify(records))
						for (let dformId in records) {
                            for (let x in records[dformId]) {
                                let record = records[dformId][x]
								log.info('RECORD: dformId: ' + record.dform.dformId + " > drecordId: " + record.dform.drecordId)
								if (record.dform.hidden) {
                                    log.info("YES THE RECORD IS HIDDEN DO NOT VALIDATE")
								} else {
                                    log.info(JSON.stringify(record))	
									let requireds = record.dform.requireds
									let errorIfs = record.dform.errorIfs
                                    
									for (let fieldName in requireds) {
                                       let req = _.get(requireds, fieldName)
                                       let value = _.get(record, fieldName)
										if (!validateRulesOnly && typeof(req) === 'boolean' || req instanceof Boolean) {
                                            if (req) {
                                                if ((!_.get(record, fieldName))) {
                                                    if (!record.dform.errors) {
                                                        record.dform.errors = {}
                                                    }
													record.dform.errors[fieldName] = true
													isValid = false
												}
											}
										} else if (typeof(req) === 'function') {

											if (req()) {
												if (!value) {
                                                    if (!record.dform.errors) {
                                                        record.dform.errors = {}
                                                    }
                                                    record.dform.errors[fieldName] = true
													isValid = false
												} else {
													delete record.dform.errors[fieldName]
												}
											} else {
                                                delete record.dform.errors[fieldName]
											}
                                        }
                                        const errorIf = _.get(record, 'errorIf')
                                        if (errorIfs) {
                                            let errorIf = _.get(errorIfs, fieldName)
                                            if (errorIf && typeof errorIf=='function' && isValid) {
                                                if (errorIf(value)) {
                                                    if (!record.dform.errors) {
                                                        record.dform.errors = {}
                                                    }
                                                    record.dform.errors[fieldName] = true
                                                    isValid = false
                                                }
                                            }
                                        }
                                    }
									record.dform.readyToValidate = true
								}
							}
						}
					}
				}
				if (isValid) {
					setHasChanges(false)
					if (onValid) {
						onValid()
					}
				} else {
					if (onError) {
						onError()
					}
				}
				// global.set({dform: global.dform})
				global.set({validateNow: false}) //, dform: global.dform
			}
		}
		
		return isValid
	}

	if (!dform || !dform.records || dform.dformId != dformId) {
		global.set({dform: {dformId: dformId, records: records, lastRecord: lastRecord, readOnly: props.readOnly, errors: {}, margin: props.margin, validate}})	
	}
	
	useEffect(() => {
		log.info("USE EFFECT DFORM: " +  props.name)
		return () => {
			log.info("USE EFFECT DFORM IN RETURN: " +  props.name)
        };
	}, [])

	if (dform) {
		records = dform.records
		lastRecord = dform.lastRecord
	}

	validate()
	

	return (
		<>
			{props.record && <DRecord name={props.name} record={props.record} afterSetVal={props.afterSetVal} setRecord={props.setRecord} dform={dform} />}
			{props.children}
		</>
	)
}