import React from 'react';
import CardActions from '@material-ui/core/CardActions';
import { DividermakeStyles, IconButton, Grid } from '@material-ui/core';


import Sedan from '../../assets/svgs/vehicles/Sedan.js';
import Midsuv from '../../assets/svgs/vehicles/Midsuv.js';
import Suv from '../../assets/svgs/vehicles/Suv.js';
import Minivan from '../../assets/svgs/vehicles/Minivan.js';
import Pickup from '../../assets/svgs/vehicles/Pickup.js';
import Van from '../../assets/svgs/vehicles/Van.js';
import Hatchback from '../../assets/svgs/vehicles/Hatchback.js';
import Mini from '../../assets/svgs/vehicles/Mini.js';
import Supercar from '../../assets/svgs/vehicles/Supercar.js';


const getCar = (carType, carColor) => {
	let carComponent
	let color = `var(--vehicle-${carColor})`
	const inverted = (color && (carColor == 'white' || carColor == 'silver'))
	switch (carType) {
		case 'sedan':
			carComponent = <Sedan width="100%" height={40} color={color} inverted={inverted}/>
			break;
	
		case 'midsuv':
			carComponent = <Midsuv width="100%" height={40} color={color} inverted={inverted}/>
			break;
	
		case 'suv':
			carComponent = <Suv width="100%" height={40} color={color} inverted={inverted}/>
			break;
	
		case 'minivan':
			carComponent = <Minivan width="100%" height={40} color={color} inverted={inverted}/>
			break;
	
		case 'pickup':
			carComponent = <Pickup width="100%" height={40} color={color} inverted={inverted}/>
			break;
	
		case 'van':
			carComponent = <Van width="100%" height={40} color={color} inverted={inverted}/>
			break;
	
		case 'hatchback':
			carComponent = <Hatchback width="100%" height={40} color={color} inverted={inverted}/>
			break;
	
		case 'mini':
			carComponent = <Mini width="100%" height={40} color={color} inverted={inverted}/>
			break;
	
		default:
			break;
	}
	return carComponent
}


const DashCarIcon = (props) => {
	const data = props.data
	const color = data && data.vehicleColor ? data.vehicleColor : ''
    const type = data && data.vehicleType ? data.vehicleType : ''
    
    if(!data || !data.vehicleColor || !data.vehicleType){
		if (data && data.otherDescription) {
			return <div>{data.otherDescription}</div>	
		} else {
        	return <div>unknown</div>
		}
    }
    
	return (
		<Grid container spacing={4} alignItems="flex-end">
			<Grid item xs align="center">
				{getCar(type, color)}
			</Grid>
		</Grid>
	);
};


export default DashCarIcon;