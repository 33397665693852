import log from 'loglevel';
import dashFetch from '../utils/dashFetch';

export default async function (restaurantId, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('getRestaurantMenusApi api');
		const url = `${global.dashServerUrl}/api/v1/restaurant/getRestaurantMenusApi`;

		const body = {
			restaurantId, debug
		};

		let allowWithoutToken = restaurantId ? true : false

		dashFetch(url, body, debug, allowWithoutToken).then(({ res, json }) => {
			log.info('>>> getRestaurantMenusApi JSON');
			if (json.success) {
				resolve(json.data);
			} else {
				resolve(false)
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}