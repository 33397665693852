import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, Paper, Tab, Tabs, Dialog, Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
	AddCircleOutline,
} from '@material-ui/icons';
import { Button, TextField, Checkbox, DialogTitle, DialogActions } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import getRolesAndPermissionsApi from "../../actions/getRolesAndPermissionsApi";
import AddEditPermission from "../components/AddEditPermission";
import AddEditRole from "../components/AddEditRole";
import PeopleIcon from '@material-ui/icons/People';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DLoader from '../../components/dash/DLoader'
import searchAccountDataToDeleteApi from '../../actions/searchAccountDataToDeleteApi';
import deleteAccountDataApi from '../../actions/deleteAccountDataApi';


import ReactTable from "react-table";
import "react-table/react-table.css";
import AccountSetup from '../../dashboard/setup/accountSetup';

const styles = (theme) => ({
    root: {
    },
	addIcon: {
		float: 'left',
		marginTop: 5,
		marginRight: 5,
		width: '20px',
		height: '20px'
	},
	addButton: {
		float: 'right',
		marginLeft: 20,
		marginRight: 20,
		marginTop: 10,
		marginBottom: 10,
		width: 200,
		height: 45
    },
    tabs: {
        width: '100%',
        background: 'var(--background)',
		width: 60,
		borderRight: 'solid 1px #CCC',
		height: '100%',
    }
    
})

const RolePermissionManager = (props) => {
	const { classes } = props;


	const [roles, setRoles] = useState([])
    const [permissions, setPermissions] = useState([])
    const [dialogContent, setDialogContent] = useState()
    const [activeTab, setActiveTab] = useState('Accounts')
    const [accountInfo, setAccountInfo] = useState({})
    const [searchAccountsResponse, setSearchAccountsResponse] = useState(null)
    const [requireConfirm, setRequireConfirm] = useState(false)
    
    async function fetchdata() {
        let data = await getRolesAndPermissionsApi()
        if (data) {
            setRoles(data.roles)
            setPermissions(data.permissions)
        }
    }

	useEffect(() => {
        fetchdata();
        global.set({ headerTitle: 'SITE LISTING' })
	}, []);


	const upsertRole = (role) => {
        // alert('role ' + JSON.stringify(role))
        setDialogContent(
            <AddEditRole role={role} permissions={permissions} close={closeContent}/>
        )
	}
    
  
    const upsertPermission = (permission) => {
        setDialogContent(
            <AddEditPermission permission={permission} close={closeContent} />
        )
    }

    const closeContent = () => {
        setDialogContent(null)
    }

    const searchAccounts = async () => {
        let data = await searchAccountDataToDeleteApi(accountInfo)
        setSearchAccountsResponse(data)
    }

    const deleteData = () => {
        setDialogContent(
            <Dialog
                open={true}
                onClose={closeContent}
            >
                <DialogTitle>Are you sure you want to delete all these data?</DialogTitle>
                <DialogActions>
                <Button onClick={closeContent} color="primary">
                    NO
                </Button>
                <Button onClick={showNukeSwitch} variant="contained" style={{backgroundColor: 'red', color: 'white'}}>
                    YES
                </Button>
                </DialogActions>
            </Dialog>
        )
    }

    const showNukeSwitch = () => {
        setDialogContent(
            <Dialog
                open={true}
                onClose={closeContent}
                >
                <div onClick={showNukeButton}><img src="https://i.etsystatic.com/10105210/r/il/046bb1/1223087115/il_570xN.1223087115_904n.jpg"></img></div>
                <Button onClick={closeContent} variant="contained" color="primary" fullWidth>CANCEL</Button>
            </Dialog>
        )
    }

    const showNukeButton = () => {
        setDialogContent(
            <Dialog
                open={true}
                onClose={closeContent}
                >
                <div onClick={confirmDelete}><img src="https://ih1.redbubble.net/image.782602336.7719/st,small,845x845-pad,1000x1000,f8f8f8.u1.jpg"></img></div>
                <Button onClick={closeContent} variant="contained" color="primary" fullWidth>CANCEL</Button>
            </Dialog>
        )
    }

    const confirmDelete = async () => {
        closeContent()
        await deleteAccountDataApi(accountInfo)
        let data = await searchAccountDataToDeleteApi(accountInfo)
        setSearchAccountsResponse(data)
    }

    const showRoles = () => {
        if(!permissions){
            return <DLoader/>
        }
        
        return (
                <Grid container>
                    <Paper style={{ width: '100%' }} className={classes.rootTable}>
                        <div>
                            <Grid container>
                                <Grid item xs={9} style={{ padding: 14, textAlign:'left', marginTop:'-8px'}}>
                                    <Typography variant="h6" color="primary">
                                        Roles
                                    </Typography>
                                </Grid>

                                <Grid item lg={3} alignItems="flex-end">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.addButton}
                                        onClick={upsertRole}
                                    >
                                        <div>
                                            <AddCircleOutline className={classes.addIcon} />
                                            <Typography variant="subtitle1" style={{ color: 'white', width: 190 }}>
                                                Add Role
                                            </Typography>
                                        </div>
                                    </Button>
                                </Grid>
                            </Grid>
                            <ReactTable
                                data={roles}
                                filterable
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
                                }
                                defaultSorted={[
                                    {
                                        id: "name",
                                        desc: true
                                    }
                                ]}
                                columns={[
                                    {
                                        Header: "Roles",
                                        columns: [
                                            {
                                                Header: "Name",
                                                accessor: "name",
                                            },
                                            {
                                                Header: "Code",
                                                accessor: "code",
                                            },
                                            {
                                                Header: "Description",
                                                accessor: "description",
                                            },
                                            {
                                                Header: "Deleted",
                                                accessor: "deleted",
                                            },
                                        ]
                                    },
                                ]}
                                getTrProps={(state, rowInfo) => {
                                    if (rowInfo && rowInfo.row) {
                                        // console.log('TYLER ' + JSON.stringify(rowInfo))
                                        // console.log('TYLER 1 ' + JSON.stringify(rowInfo.row))
                                        return {
                                            onClick: (e) => {
                                                upsertRole(rowInfo.row._original)
                                            },
                                            // style: {
                                            //     background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                                            //     color: rowInfo.index === this.state.selected ? 'white' : 'black'
                                            // }
                                        }
                                    } else {
                                        return {}
                                    }
                                }}
                                defaultPageSize={15}
                                className="-striped -highlight"
                            />
                            <br />
                        </div>
                    </Paper>
                    <div style={{ textAlign: "center" }}>
                        <em>Tip: Hold shift when sorting to multi-sort!</em>
                    </div>
                </Grid>
        )
    }

    const showPermissions = () => {

        if(!permissions){
            return <DLoader/>
        }

        return (

                <Grid container>
                    <Paper style={{ width: '100%' }} className={classes.rootTable}>
                        <div>
                            <Grid container>
                                <Grid item xs={9} style={{ padding: 14, textAlign:'left', marginTop:'-8px'}}>
                                    <Typography variant="h6" color="primary">
                                        Permissions
                                    </Typography>
                                </Grid>
                                <Grid item lg={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.addButton}
                                        onClick={upsertPermission}
                                    >
                                        <div>
                                            <AddCircleOutline className={classes.addIcon} />
                                            <Typography variant="subtitle1" style={{ color: 'white', width: 190 }}>
                                                Add Permission
                                            </Typography>
                                        </div>
                                    </Button>
                                </Grid>
                            </Grid>
                            <ReactTable
                                data={permissions}
                                filterable
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
                                }
                                defaultSorted={[
                                    {
                                        id: "name",
                                        desc: true
                                    }
                                ]}
                                columns={[
                                    {
                                        Header: "Permissions",
                                        columns: [
                                            {
                                                Header: "Name",
                                                accessor: "name",
                                            },
                                            {
                                                Header: "Code",
                                                accessor: "code",
                                            },
                                            {
                                                Header: "Description",
                                                accessor: "description",
                                            },
                                        ]
                                    },
                                ]}
                                getTrProps={(state, rowInfo) => {
                                    if (rowInfo && rowInfo.row) {
                                        // console.log('TYLER ' + JSON.stringify(rowInfo))
                                        // console.log('TYLER 1 ' + JSON.stringify(rowInfo.row))
                                        return {
                                            onClick: (e) => {
                                                upsertPermission(rowInfo.row._original)
                                            },
                                            // style: {
                                            //     background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                                            //     color: rowInfo.index === this.state.selected ? 'white' : 'black'
                                            // }
                                        }
                                    } else {
                                        return {}
                                    }
                                }}
                                defaultPageSize={15}
                                className="-striped -highlight"
                            />
                            <br />
                        </div>
                    </Paper>
                    <div style={{ textAlign: "center" }}>
                        <em>Tip: Hold shift when sorting to multi-sort!</em>
                    </div>
                </Grid>
        )
    }

    const showDeleteData = () => {
        return (
            <>
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <Grid container spacing={2} alignItems="center" justify="flex-end">
                        <Grid item xs={12} lg>
                            <TextField
                                label="Account ID"
                                onChange={(e) => setAccountInfo({...accountInfo, accountId: e.target.value})}
                                fullWidth/>
                        </Grid>
                        <Grid item xs={12} lg>
                            <TextField
                                label="Email"
                                type="email"
                                onChange={(e)=>setAccountInfo({...accountInfo, email: e.target.value})}
                                fullWidth
                                />
                        </Grid>
                        <Grid item xs={12} lg>
                            <TextField
                                inputProps={{ maxLength: 14 }}
                                type="tel"
                                pattern="[0-9]*"
                                label="Phone"
                                value={global.formatPhoneNumber(accountInfo.phone ?? '')}
                                onChange={(e)=>setAccountInfo({...accountInfo, phone: e.target.value.replace(/\D/g, '')})}
                                fullWidth
                                />
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={searchAccounts}>Search</Button>
                        </Grid>
                        {searchAccountsResponse && (
                            <Grid item container xs={12} direction="column" alignItems="flex-start">
                                <Grid item>Found {searchAccountsResponse.accounts.length} accounts</Grid>
                                {searchAccountsResponse.accounts.map((account) =>
                                        <Grid item container xs={12} direction="column" alignItems="flex-start" style={{margin: '16px 0'}}>
                                            <Grid item>Account ID: {account._id}</Grid>
                                            <Grid item>First name: {account.firstName}</Grid>
                                            <Grid item>Last name: {account.lastName}</Grid>
                                            <Grid item>Email: {account.email}</Grid>
                                            <Grid item>Phone: {account.phoneNumber ? global.formatPhoneNumber(account.phoneNumber) : ''}</Grid>
                                            <Grid item>Has password: {account.hash ? 'true' : 'false'}</Grid>
                                        </Grid>)
                                 }
                                <Grid item container xs={12} direction="column" alignItems="flex-start" style={{margin: '16px 0'}}>
                                    <Grid item>Account query: {JSON.stringify(searchAccountsResponse.accountQuery)}</Grid>
                                    <Grid item>Check query: {JSON.stringify(searchAccountsResponse.checkQuery)}</Grid>
                                    <Grid item>Payment query: {JSON.stringify(searchAccountsResponse.paymentQuery)}</Grid>
                                    <Grid item>Email query: {JSON.stringify(searchAccountsResponse.emailQuery)}</Grid>
                                    <Grid item>Text query: {JSON.stringify(searchAccountsResponse.textQuery)}</Grid>
                                    <Grid item>Encrypted card query: {JSON.stringify(searchAccountsResponse.cardQuery)}</Grid>
                                    <Grid item>Site customer query: {JSON.stringify(searchAccountsResponse.siteCustomerQuery)}</Grid>
                                    
                                    <Grid item>Check count: {searchAccountsResponse.checkCount}</Grid>
                                    <Grid item>Payment count: {searchAccountsResponse.paymentCount}</Grid>
                                    <Grid item>Email count: {searchAccountsResponse.emailCount}</Grid>
                                    <Grid item>Text count: {searchAccountsResponse.textCount}</Grid>
                                    <Grid item>Encrypted card count: {searchAccountsResponse.cardCount}</Grid>
                                    <Grid item>Site customer count: {searchAccountsResponse.siteCustomerCount}</Grid>
                                </Grid>
                                <Grid item container xs={12} alignItems="flex-start" style={{margin: '16px 0'}}>
                                    <Button variant="contained" style={{backgroundColor: 'red', color: 'white'}} onClick={deleteData}>DELETE DATA</Button>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </>)
        }

    return (
        <div style={{width:'100%', height:'100%', textAlign:'center'}}>

            <Grid container spacing={0} style={{width: '100%', height: '100%'}}>
                <Grid item style={{width: 60}}>
                    <Tabs
                        orientation="vertical"
                        value={activeTab}
                        onChange={(e, n) => {
                            setActiveTab(n);
                        }}
                        className={classes.tabs}
                        indicatorColor='primary'
                    >	

                        {global.hasPermission('admin.users.*') &&
                            <Tooltip title="Accounts">
                                <Tab value="Accounts" icon={<PeopleIcon color={activeTab == 'Accounts' ? 'primary' : ''} />} style={{minWidth: 0}} />
                            </Tooltip>
                        }

                        {global.hasPermission('admin.user.rolesAndPermissions') &&
                            <Tooltip title="Roles">
                                <Tab value="Roles" icon={<AssignmentIndIcon color={activeTab == 'Roles' ? 'primary' : ''} />} style={{minWidth: 0}} />
                            </Tooltip>
                        }

                        {global.hasPermission('admin.user.rolesAndPermissions') &&
                            <Tooltip title="Permissions">
                                <Tab value="Permissions" icon={<LockOpenIcon color={activeTab == 'Permissions' ? 'primary' : ''} />} style={{minWidth: 0}} />
                            </Tooltip>
                        }

                        {global.isAdmin() &&
                            <Tooltip title="DeleteAccountData">
                                <Tab value="DeleteAccountData" icon={<HighlightOffIcon color={activeTab == 'DeleteAccountData' ? 'primary' : ''} />} style={{minWidth: 0}} />
                            </Tooltip>
                        }
                       
                    </Tabs>
                </Grid>
                <Grid container xs item style={{padding:14, height: '100%', overflowX: 'hidden', overflowY: 'auto'}}>
                    {global.hasPermission('admin.users.*') && activeTab == 'Accounts' && <Paper style={{width:'100%', padding:10}}><AccountSetup isAdmin={true} roles={roles}/></Paper>}
                    {global.hasPermission('admin.user.rolesAndPermissions') && activeTab == 'Roles' && showRoles()}
                    {global.hasPermission('admin.user.rolesAndPermissions') && activeTab == 'Permissions' && showPermissions()}
                    {global.isAdmin() && activeTab == 'DeleteAccountData' && showDeleteData()}
                </Grid>
			</Grid>
            {dialogContent}
        </div>
    );
};

RolePermissionManager.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withRouter(withStyles(styles, { withTheme: true })(RolePermissionManager));