import React, { useState } from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import NumberFormat from 'react-number-format';
import Arrow from './Arrow';
import Tooltip from '@material-ui/core/Tooltip';

// var NumberFormat = require('react-number-format');

export default function Banner2(props) {
	const [loyaltyData, setLoyaltyData] = useState("yy");

	let loyaltyProp = global.f(props, "data")

	if (loyaltyProp && (loyaltyProp != loyaltyData)) {
		setLoyaltyData(props.data)
	}

	const styles = {
		infoBanner: {
			padding: 15
		},
		paper: {
			padding: 10,
			width: '96%',
			height: '7.2em',
			position: 'relative'
		}
	}
	let decimalScale = 0
	if (props.data.prefix == '$') {
		decimalScale = 2
		props.data.number = parseFloat(props.data.number).toFixed(2)
	}
	return (

		<Grid containter justify="center" style={styles.infoBanner} >
			<Paper style={styles.paper}>
				{/* <Grid containter wrap="nowrap" alignItems="center"> */}
					<center>
						<Tooltip title={props.tooltipTitle ? props.tooltipTitle : ''}>
							<Typography color="secondary" variant="h2">
								<NumberFormat value={props.data.number} displayType={'text'} decimalScale={decimalScale} thousandSeparator={true} prefix={props.data.prefix} />
								{props.data.percent &&<span style={{ textAlign: 'center', position: 'absolute', top: '25%', paddingLeft: '.105em' }}><Arrow percent={props.data.percent} /></span>}
							</Typography>
						</Tooltip>
					</center>
				{/* </Grid> */}
				<Typography align='center' variant="h5">{props.label}</Typography>
				<center>
					<Typography align='center' color="primary" variant="h7">{props.suffix}</Typography>
				</center>
			</Paper>
		</Grid>
	);
}
