import log from 'loglevel';
import dashFetch from '../utils/dashFetch';


export default async function (invoiceId, debug = false) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('getInvoice api');
		const url = `${global.dashServerUrl}/dashboard/api/v1/billing/getInvoice`;

		const body = {
			invoiceId
		};

		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info('>>> getInvoice JSON');
			log.info(json)
			if (json.success) {
				resolve(json.data ? json.data.invoice : null);
			} else {
				global.showNotification(json.error, "error")
				resolve(false)
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}