import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import log from 'loglevel';
import _ from 'lodash';
import {
	Grid,
	Button,
	Typography,
	FormControl,
	MenuItem,
	Modal,
	Paper,
	InputLabel,
    Tooltip,
    Divider,
    TextField
} from '@material-ui/core';
import SelectMui from '@material-ui/core/Select';
import DSwitch from '../../../components/dash/DSwitch';
import DSubmitButton from '../../../components/dash/DSubmitButton';
import DForm2 from '../../../components/dash/DForm2';
import DRecord from '../../../components/dash/DRecord';
import DInput from '../../../components/dash/DInput';
import dashFetch from '../../../utils/dashFetch'

const styles = (theme) => ({
	root: {
		height: '100%',
		width: '100%'
	},
	paper: {
		// position: 'absolute',
		// width: 400,
		backgroundColor: theme.palette.background.paper,
		border: '1px solid var(--black)',
		boxShadow: ["none"],
	},
	tabs: {
		// paddingBottom: 0,
		position: 'absolute',
		width: '100%',
		zIndex: 10,
		background: theme.palette.background.paper
	},
	addRestaurantIcon: {
		// position: 'relative',
		float: 'left',
		marginTop: 5,
		marginRight: 5,
		width: '20px',
		height: '20px'
	},
	searchBarWrapper: {
		display: 'flex'
		// backgroundColor: 'gray'
	},
	restaurantForm: {
		marginTop: '5.0%',
		marginLeft: '2.5%',
		marginRight: '2.5%'
	},
	searchBar: {
		width: '80%',
		'&$selected': {
			backgroundColor: '#5220A8'
		}
	},
	addRestaurantButton: {
		marginLeft: 20,
		marginTop: 15,
		width: 200,
		height: 45
	},
	restaurantFieldInputItem: {
		width: '100%',
		// marginTop: '15px'
	},
	muiSelect: {
		marginBottom: '15px',
	},
	submitButton: {
		position: 'absolute',
		bottom: '10px',
		right: '50%',
		left: '50%',
		marginLeft: '-75px',
	},
	rootTable: {
		margin: 30
	},
	table: {
		width: '100%',
		minWidth: 650
	},
	locationInfoInput: {
		width: '45%'
	},
	subheadingText: {
		fontSize: '16px',
	},
	paymentGatewaySelect: {
		width: '60%',
		marginBottom: '15px'
	},
	addButton: {
		// backgroundColor: '#5220A8'
		margin: 10,
		minWidth: 28,
		backgroundColor: global.primaryColor,
		borderRadius: 15
	},
	indicator: {
		backgroundColor: '#5220A8'
	},
	box: {
		backgroundColor: 'var(--white)',
        border: '1px solid #EAEAEA',
        boxShadow: '0 0 10px 5px rgba(231,231,231,0.50)',
        padding: '15px 30px 30px 30px',
        marginBottom: 20,
        borderRadius: 6,
	},
});

const CompanyInfo = (props) => {
	const { classes } = props;

	const owners = props.record ? props.record.owners: ''
	const loyaltyProgram = props.record ? props.record.restaurant: ''
	const restaurantGroup = props.record ? props.record.restaurantGroup: ''
    const [posTypes, setPosTypes] = useState(props.dropDownOptions ? props.dropDownOptions.posTypes : [])

    const findYear = () => {
        let value = new Date()
        return value.getFullYear()
    }

    const businessTypes = ['Individual/Sole Proprietor', 'S Corp', 'C Corp', 'Partnership', 'LLC (Limited Liability Corporation)', 'Non-Profit']
    const locationTypes = ['Residence', 'Retail', 'Storefront', 'Office Building', 'Industrial Building']

	return (
		<DForm2 record={props.record.businessInfo}>
			<Grid container>
				<Grid container>
					<Grid item xs={12} sm={12} md={12}>
						<Typography variant="subtitle1" color="primary" gutterBottom>
							Please tell us about your company
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12}>
						<Paper className={classes.box}>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={12} md={6} lg={6}>
									<DInput name="legalName" label="Company Legal Name" required />
								</Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
									<DInput name="dba" label="DBA" required />
								</Grid>
								<Grid item xs={12} sm={12} md={3} lg={3}>
									<DInput name="businessTaxId" label="Tax ID" required/>
								</Grid>
								<Grid item xs={12} sm={6} md={3} lg={3}>
                                    <FormControl style={{ width: '100%' }}>
                                            <DInput name="businessType" label="Business Type" required fieldType="Select">
                                                <MenuItem disabled value=""><em>Business Type</em></MenuItem>
                                                {businessTypes.map((type) => <MenuItem value={type}>{type}</MenuItem>)}
                                            </DInput>
                                    </FormControl>
								</Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <DInput name="stateIncorporated" label="State Incorporated" required fieldType="Select">
                                        <MenuItem disabled value=""><em>State Incorporated</em></MenuItem>
                                        {states.map((state) => <MenuItem value={state.value}>{state.label}</MenuItem>)}
                                    </DInput>
								</Grid>
								<Grid item xs={12} sm={6} md={3} lg={3}>
									<DInput name="yearBusinessStarted" label="Year Founded" type="tel" max={findYear()} required/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
									<DInput name="addressInfo.address" label="Street Address" required/>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
									<DInput name="addressInfo.city" label="City" required/>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <DInput name="addressInfo.state" label="State" required fieldType="Select">
                                        <MenuItem disabled value=""><em>State</em></MenuItem>
                                        {states.map((state) => <MenuItem value={state.value}>{state.label}</MenuItem>)}
                                    </DInput>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
									<DInput name="addressInfo.zip" label="Zip" type="tel" required format='#####' errorIf={(value)=> {return !(/^\d{5}$/.test(value))}} helperText="Use 5 digit zip code"/>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <FormControl style={{ width: '100%' }}>
                                            <DInput name="locationType" label="Location Type" required fieldType="Select">
                                                <MenuItem disabled value=""><em>Location Type</em></MenuItem>
                                                {locationTypes.map((type) => <MenuItem value={type}>{type}</MenuItem>)}
                                            </DInput>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
									<DInput
										required
                                        format="(###) ###-####"
                                        fieldType="phonenumber"
										type="tel"
										name="phone"
										pattern="[0-9]*"
										margin="normal"
										label="Phone Number"
										InputLabelProps={{ shrink: true }}
										fullWidth={true}
										// inputProps={{ maxLength: 14 }}
										style={{paddingTop:0, marginTop:'0px'}}
									/>
									{/* <DInput name="phone" validateId="phone1" label="Phone" overrideValue={phoneNumber} type="tel" style={{display:'none'}} required></DInput> */}
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
									<DInput name="website" label="Website" required/>
                                </Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</Grid>
		</DForm2>
	);
};

export default withStyles(styles, { withTheme: true })(CompanyInfo);

const states = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' }
];