import React, { useState, useEffect } from 'react';
import SelectMui from '@material-ui/core/Select';
import { Grid, MenuItem, Select, Checkbox, ListItemText, FormControl, Button, Input, Dialog, Typography, DialogActions, TextField, Divider, DialogContent, Tabs, Tooltip, Tab, CircularProgress } from '@material-ui/core';
// import upsertAccountApi from '../../actions/upsertAccountApi';
import createAccountApi from '../../actions/createAccountApi'
import getDropdownApi from '../../actions/getDropdownApi'
import dashFetch from '../../utils/dashFetch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PublishIcon from '@material-ui/icons/Publish';
import AssignmentIcon from '@material-ui/icons/Assignment';
import log from 'loglevel'
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '../../dashboard/components/DialogTitle';
import { withRouter } from 'react-router-dom';

import DInput from '../../components/dash/DInput';
import DForm2 from '../../components/dash/DForm2';
import DRecord from '../../components/dash/DRecord';
import PaletteIcon from '@material-ui/icons/Palette';
import PeopleIcon from '@material-ui/icons/People';
import theme from '../../theme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Branding from '../../dashboard/setup/branding'
import Menus from '../../dashboard/setup/menus'
import RestaruantGroupConfig from '../../dashboard/setup/restaruantGroupConfig'
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import downloadRestaurantGroupExport from '../../actions/downloadRestaurantGroupExportApi'
import GetAppIcon from '@material-ui/icons/GetApp';
import SettingsIcon from '@material-ui/icons/Settings';


const styles = (theme) => ({
	root: {
	  margin: 0,
	  padding: theme.spacing(2),
	},
	closeButton: {
	  position: 'absolute',
	  right: theme.spacing(1),
	  top: theme.spacing(1),
	  color: theme.palette.grey[500],
    },
    tabs: {
        width: '100%',
        background: 'var(--background)',
		borderRight: 'solid 1px #CCC',
		height: '100%',
    }
  });

function AddEditRestaurantGroup (props) {
    const GENERAL_TAB= 0;
    const BRANDING_TAB = 1;
    const MENUS_TAB = 2;
    const CONFIG_TAB = 3;
    const EXPORT_TAB = 4;
    
    const [ group, setGroup ] = useState(props.group ? props.group : {}) 
    const [ posTypes, setPosTypes ] = useState([])
    const [ agents, setAgents ] = useState([]);
    const [ restaurantAccounts, setRestaurantAccounts ] = useState([]);
    const [ activeTab, setActiveTab ] = useState(GENERAL_TAB)
    const {classes} = props
    const [restaurant] = global.get('restaurant')
    const [loading, setLoading] = useState(false)

    const isMobile = useMediaQuery('(max-width:500px)')

    const fetchData = async () => {
        let data = await dashFetch(`${global.dashServerUrl}/dashboard/api/v1/dashboard/getDropdownList`, {}, true);
        if (data.json) {
            console.log(data.json)
            setPosTypes(data.json.posTypes.sort((a, b) => (a.posType.toLowerCase() > b.posType.toLowerCase()) ? 1 : -1));
            setAgents(data.json.agents.sort((a, b) => (a.firstName.toLowerCase() > b.firstName.toLowerCase()) ? 1 : -1));
            setRestaurantAccounts(data.json.restaurantAccounts.sort((a, b) => (a.firstName.toLowerCase() > b.firstName.toLowerCase()) ? 1 : -1));
            setPosTypes(data.json.posTypes.sort((a, b) => (a.posType.toLowerCase() > b.posType.toLowerCase()) ? 1 : -1));
        }
    }

    useEffect(()=>{
        fetchData()
    },[restaurant])

    const clickSave = async () => {
        if (group && group.name) {
            let resp = await dashFetch(`${global.dashServerUrl}/api/v1/restaurantGroup/upsertGroup`, group, true);
            if (resp.json.success){
                setGroup(resp.json.data)
                global.showNotification('Success', 'success')
            }
        } else {
            global.showNotification('Name is required', 'warning')
        }
    }

    const showForm = () => {
        return (
            <DForm2 name={'restaurantGroup'} record={group}>
                <Grid container style={{ overflowY:'auto', marginLeft: 0, width:'100%'}} spacing={2}>
                    <Grid item xs={12}>
                        <DInput label="Name" name="name"/>
                    </Grid>
                    <Grid item xs={12}>
                        <DInput label="Primary Contact Account" name="primaryContactAccount" fieldType="select">
                            {restaurantAccounts.map((rec) => <MenuItem value={rec._id}>{rec.fullName}</MenuItem>)}
                        </DInput>
                    </Grid>
                    <Grid item xs={12}>
                        <DInput label="POS Type" name="posType" fieldType="Select">
                            <MenuItem disabled value=""><em>POS Type</em></MenuItem>
                            {posTypes.map((type) => <MenuItem value={type._id}>{type.posType}</MenuItem>)}
                        </DInput>
                    </Grid>
                    <Grid item xs={12}>
                        <DInput label="Agent" name="agent" fieldType="select">
                            {agents.map((rec) => <MenuItem value={rec._id}>{rec.fullName}</MenuItem>)}
                        </DInput>
                    </Grid>
                    <Grid item xs={12}>
                        <DInput label="Sales Rep" name="salesRep" fieldType="select">
                            {restaurantAccounts.map((rec) => <MenuItem value={rec._id}>{rec.fullName}</MenuItem>)}
                        </DInput>
                    </Grid>
                    <Grid item xs={12} style={{textAlign:'center'}}>
                        {loading ? 
                            <CircularProgress size={30} color="#000000" style={{zIndex:2000}}/> 
                                : 
                            <Button variant="contained" color="primary" style={{width:'180px', zIndex:1, margin:'auto'}} onClick={clickSave}>
                                <>
                                    <PublishIcon style={{marginRight: 10, marginLeft: -2, height: '15px'}}/> 
                                    <span style={{fontSize:'13px'}}>
                                        {!group._id ? 'Create' : 'Save'}
                                    </span>
                                </> 
                            </Button>
                        }
                    </Grid>
                </Grid>
            </DForm2>
        )
    }
    
	return (
		<Dialog
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
            fullWidth={true}
			open={true}
			style={{ margin: 'auto', padding:'0px'}}
			onClose={ props.close }
            aria-labelledby="customized-dialog-title"
		>
			<DialogTitle onClose={ props.close } id="customized-dialog-title">
				ADD/EDIT RESTAURANT GROUP
			</DialogTitle>
            <DialogContent dividers style={{padding: 0, height:'80vh'}}>
                <Grid container alignItems="stretch" style={{height:'100%', overflow:'hidden'}}>
                    <Grid item xs={isMobile && 12} style={{height:isMobile ? '60px' : '100%', overflow:'hidden'}} alignContent="stretch">
                        <Tabs
                            orientation={isMobile ? "horizontal" : "vertical"}
                            value={activeTab}
                            onChange={(e, n) => {
                                setActiveTab(n);
                            }}
                            className={classes.tabs}
                            style={{height: isMobile ? '60px' : '1000px', width: isMobile ? '100%' : 60}}
                            indicatorColor='primary'
                        >	

                            {global.hasPermission('admin.users.*') &&
                                <Tooltip title="Form">
                                    <Tab value={GENERAL_TAB} icon={<PeopleIcon color={activeTab == GENERAL_TAB ? 'primary' : ''} />} style={{minWidth: 0}} />
                                </Tooltip>
                            }

                            {global.hasPermission('admin.user.*') &&
                                <Tooltip title="Branding">
                                    <Tab value={BRANDING_TAB} icon={<PaletteIcon color={activeTab == BRANDING_TAB ? 'primary' : ''} />} 
                                        style={{minWidth: 0}} disabled={!group._id}
                                    />
                                </Tooltip>
                            }

                            {global.hasPermission('admin.user.*') &&
                                <Tooltip title="Menus">
                                    <Tab value={MENUS_TAB} icon={<ImportContactsIcon color={activeTab == MENUS_TAB ? 'primary' : ''} />} 
                                        style={{minWidth: 0}} disabled={!group._id}
                                    />
                                </Tooltip>
                            }

                            {global.hasPermission('admin.user.*') &&
                                <Tooltip title="Config">
                                    <Tab value={CONFIG_TAB} icon={<SettingsIcon color={activeTab == CONFIG_TAB ? 'primary' : ''} />} 
                                        style={{minWidth: 0}} disabled={!group._id}
                                    />
                                </Tooltip>
                            }

                            {global.hasPermission('admin.user.*') &&
                                <Tooltip title="Export Group .CSV">
                                    <Tab value={EXPORT_TAB} icon={<GetAppIcon onClick={()=> downloadRestaurantGroupExport(null, group._id)} />} 
                                        style={{minWidth: 0}} disabled={!group._id}
                                    />
                                </Tooltip>
                            }
                        </Tabs>
                    </Grid>
                    <Grid item xs style={{padding:14, height:'100%', width:'100%', overflow:'auto'}}>
                        <div style={{overflow:'auto'}}>
                            {global.hasPermission('admin.users.*') && activeTab == GENERAL_TAB && showForm()}
                            {global.hasPermission('admin.users.*') && activeTab == BRANDING_TAB && <Branding group={group} refreshList={props.refreshList}/>}
                            {global.hasPermission('admin.users.*') && activeTab == MENUS_TAB && <Menus hidePaper group={group}/>}
                            {global.hasPermission('admin.users.*') && activeTab == CONFIG_TAB && <RestaruantGroupConfig hidePaper group={group}/>}
                        </div>
                    </Grid>
                </Grid>

			</DialogContent>
		</Dialog>
	)
}

export default withRouter(withStyles(styles, { withTheme: true })(AddEditRestaurantGroup));