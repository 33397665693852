import log from 'loglevel';
import dashFetch from '../utils/dashFetch';


export default async function (productPackageGroup, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('upsertProductPackageGroupApi api');
		const url = `${global.dashServerUrl}/dashboard/api/v1/dashboard/upsertProductPackageGroup`;

		const body = {
			productPackageGroup,
			debug
		};

		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info('>>> upsertProductPackageGroupApi JSON');
			log.info(json)
			if (json.success) {
				resolve(json.record);
			} else {
				resolve(false)
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}