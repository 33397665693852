import log from 'loglevel';
import dashFetch from '../utils/dashFetch';


export default async function (checkId, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('cancelCheckApi api');
		const url = `${global.dashServerUrl}/dashboard/api/v1/dashboard/cancelCheck`;

		const body = {
			checkId
		};

		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info('>>> cancelCheckIn JSON');
			log.info(json)
            resolve(json.success ?? false);
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}