import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import log from 'loglevel';
import _ from 'lodash';
import {
	Grid,
	Button,
	Typography,
	FormControl,
	MenuItem,
	Modal,
	Paper,
	InputLabel,
	Tooltip,
} from '@material-ui/core';
import SelectMui from '@material-ui/core/Select';
import DSwitch from '../../../components/dash/DSwitch';
import DSubmitButton from '../../../components/dash/DSubmitButton';
import DForm2 from '../../../components/dash/DForm2';
import DRecord from '../../../components/dash/DRecord';
import DInput from '../../../components/dash/DInput';
import dashFetch from '../../../utils/dashFetch'

const styles = (theme) => ({
	root: {
		height: '100%',
		width: '100%'
	},
	paper: {
		// position: 'absolute',
		// width: 400,
		backgroundColor: theme.palette.background.paper,
		border: '1px solid var(--black)',
		boxShadow: ["none"],
	},
	tabs: {
		// paddingBottom: 0,
		position: 'absolute',
		width: '100%',
		zIndex: 10,
		background: theme.palette.background.paper
	},
	addRestaurantIcon: {
		// position: 'relative',
		float: 'left',
		marginTop: 5,
		marginRight: 5,
		width: '20px',
		height: '20px'
	},
	searchBarWrapper: {
		display: 'flex'
		// backgroundColor: 'gray'
	},
	restaurantForm: {
		marginTop: '5.0%',
		marginLeft: '2.5%',
		marginRight: '2.5%'
	},
	searchBar: {
		width: '80%',
		'&$selected': {
			backgroundColor: '#5220A8'
		}
	},
	addRestaurantButton: {
		marginLeft: 20,
		marginTop: 15,
		width: 200,
		height: 45
	},
	restaurantFieldInputItem: {
		width: '100%',
		// marginTop: '15px'
	},
	muiSelect: {
		marginBottom: '15px',
	},
	submitButton: {
		position: 'absolute',
		bottom: '10px',
		right: '50%',
		left: '50%',
		marginLeft: '-75px',
	},
	rootTable: {
		margin: 30
	},
	table: {
		width: '100%',
		minWidth: 650
	},
	locationInfoInput: {
		width: '45%'
	},
	subheadingText: {
		fontSize: '16px',
	},
	paymentGatewaySelect: {
		width: '60%',
		marginBottom: '15px'
	},
	addButton: {
		// backgroundColor: '#5220A8'
		margin: 10,
		minWidth: 28,
		backgroundColor: global.primaryColor,
		borderRadius: 15
	},
	indicator: {
		backgroundColor: '#5220A8'
	},
	box: {
		backgroundColor: 'var(--white)',
		border: '1px solid #EAEAEA',
		boxShadow: '0 0 10px 5px rgba(231,231,231,0.50)',
		padding: 30,
		marginBottom: 20,
		borderRadius: 6,
	},
});

const Dash = (props) => {
	const { classes } = props;

	const restaurant = props.record ? props.record.restaurant : ''
	
	const restaurantGroup = props.record ? props.record.restaurantGroup : ''
	const [posTypes, setPosTypes] = useState(props.dropDownOptions ? props.dropDownOptions.posTypes : [])


	const leftSideInputWidth = '100%'


	return (
		<DForm2 record={props.record} readOnly={true} validate={false} >
			<Grid container>
				<Grid container>
					<Grid item xs={12} sm={12} md={12}>
						<Typography variant="subtitle1" color="primary" gutterBottom>
							Please tell us about your company
						</Typography>
					</Grid>
					{restaurant.installCode && (
						<Paper style={{ padding: '10px' }}>
							<div>
								<Typography
									variant="body"
									color="secondary"
								>
									Install Code:
									<Typography
										variant="body2"
										color="primary"
									>
										{restaurant.installCode}
									</Typography>
								</Typography>
							</div>
						</Paper>
					)}
					<Grid item xs={12} sm={12} md={12}>
						<Paper className={classes.box}>
							<DRecord record={restaurantGroup} />
							<Grid container spacing={3}>
								<Grid item xs={12} sm={6} md={6} lg={6}>
									<DInput name="name" label="Company Name" required />
								</Grid>
								<Grid item xs={12} sm={6} md={3} lg={3}>
									<DInput name="locationCount" label="# of Locations" type="number" min={1} max={50} maxLength="2" />
								</Grid>

								{/* Primary Contact Info */}
								<Grid item lg={12}>
									<Typography variant="h6" color="primary" className={styles.subheadingText} gutterBottom>
										Primary Contact
									</Typography>
									<Grid container spacing={3}>
										<DRecord record={restaurantGroup.primaryContactInfo} name="primaryContactInfo" />
										<Grid item xs={12} sm={6} md={6} lg={6}>
											<div>
												<DInput name="firstName" label="First Name" required />
											</div>
										</Grid>
										<Grid item xs={12} sm={6} md={6} lg={6}>
											<div>
												<DInput name="lastName" label="Last Name" required />
											</div>
										</Grid>
										<Grid item xs={12} sm={12} md={6} lg={6}>
											<div>
												<DInput name="title" label="Title" required />
											</div>
										</Grid>
										<Grid item xs={12} sm={12} md={6} lg={6}>
											<div>
												<DInput name="phone" type="phone" label="Phone" required />
											</div>
										</Grid>
										<Grid item xs={12} sm={12} md={6} lg={6}>
											<div>
												<DInput name="email" type="email" label="Email" required />
											</div>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</Grid>
		</DForm2>
	);
};

export default withStyles(styles, { withTheme: true })(Dash);

