import React, {useState, useEffect} from 'react';
import { makeStyles, Slide, ButtonBase } from '@material-ui/core';
import moment from 'moment'
import log from 'loglevel'

import PaymentIcon from '@material-ui/icons/Payment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DashCarIcon from './DashCarIcon'
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PhoneIcon from '@material-ui/icons/Phone';

import theme from '../../theme'
import DFlag from '../../components/dash/DFlag'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import paymentLinks from '../paymentLinks';
import { ScheduleOutlined } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
	root: {
		// minWidth: 200,
		textAlign: 'center',
		// backgroundColor: 'rgb(238, 238, 238)'
	},
	header: {
		// textAlign: 'center',
		padding:4,
		// spacing: 5,
	},
	list: {
		padding: '20px',
	},
	button: {
		margin: theme.spacing(1),
	},
	action: {
		display: 'flex',
		justifyContent: 'space-around',
	},
	overflowText: {
		textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth:'100%'
	},
	  
}));


const DashCard = (props) => {

	const isMobile = useMediaQuery('(max-width:600px)')
	const [data, setData] = useState(props.data)
	let paymentLink = props.paymentLink
	const [showCustomerAccountName] = global.get('showCustomerAccountName')

	let lastPaymentTime
	let tmpPayment
	if (data.payments) {
		tmpPayment = data.payments.sort((a, b) => moment(a.createdAt) - moment(b.createdAt))[0]
		if (tmpPayment) {
			lastPaymentTime = tmpPayment.createdAt
		}
	}

	let orderedAt = data.createdAt
	let paidAt = data.paidInFull && lastPaymentTime ? lastPaymentTime : ''
	let arrivedAt = data.pickupDetails.arrivedAt ? data.pickupDetails.arrivedAt : ''
	let completedAt = data.isToGo && data.pickupDetails.pickedUpAt ? data.pickupDetails.pickedUpAt : data.closedAt ? data.closedAt : ''
	let statusDate = orderedAt
	let [ statusDateStr, setStatusDateStr ] = useState(statusDate)

	useEffect(()=>{
		if (data !== props.data){
			setData(props.data)
		}
		let updatedStatus = ''
		if (data.status == 'paid') {
			updatedStatus = paidAt ? paidAt : statusDate
		} else if (data.status == 'arrived') {
			updatedStatus = arrivedAt ? arrivedAt : statusDate
		} else if (data.status == 'completed') {
			updatedStatus = completedAt ? completedAt : statusDate
		} else if (!data.textSendTime && !data.emailSendTime && data.textOnHoldUntil) {
			updatedStatus = data.textOnHoldUntil
		} else {
			updatedStatus = data.createdAt
		}
		setStatusDateStr(updatedStatus)
	}, [props.data, data.status, data.createdAt])

	let statusIcon = <AccessTimeIcon fontSize="small" style={{marginRight:'.4em', marginTop: '.1em'}} />
	if (data.status == 'ordered' && data.isToGo) {
		statusIcon = <PhoneIcon fontSize="small" style={{marginRight:'.4em', marginTop: '.1em'}} />
	} else if (data.status == 'paid') {
		statusIcon = <PaymentIcon fontSize="small" style={{marginRight:'.4em', marginTop: '.1em'}} />
	} else if (data.status == 'arrived') {
		// statusIcon = <DashCarIcon fontSize="small" style={{marginRight:'.4em', marginTop: '.1em'}} />
	} else if (data.status == 'completed') {
		statusIcon = <CheckCircleIcon fontSize="small" style={{marginRight:'.4em', marginTop: '.1em'}} />
	}


	function findTotalPaid(check) {
		const tip = (check.totals && check.totals.tipsDashNow) ? Number(check.totals.tipsDashNow) : 0
		const paidDashNow = (check.totals && check.totals.paidDashNow) ? Number(check.totals.paidDashNow) : 0
		const total = global.formatMoney(Number(paidDashNow) + Number(tip) - Number(data.totals.ccServiceChargeAmount)- Number(data.totals.achServiceChargeAmount))
		return total;
	}

	let totalPaid = findTotalPaid(data)

	let balance = data.totals.due

	let isBalanceDue = balance > 0 && balance != data.totals.total

	let showBalanceDue = !!props.showBalanceDue && isBalanceDue
	let showTotalPaid = (!!props.showTotalPaid || showBalanceDue) && data.totals.paidDashNow > 0
	let showCheckTotal = !showTotalPaid
	let isCallAheadCheck = (data.pickupDetails && data.isToGo)
	let classes = useStyles();
	const showPickupDetail = (data.pickupDetails.vehicleType || data.pickupDetails.pickupLocation)


	let color = '#a3a3a3'
	if (data.paidInFull && data.totals && data.totals.paidDashNow > 0) {
		color = `var(--${global.branding})`
	}

	let guestName = (data.getVal('pickupDetails.guestName', '').trim() && data.pickupDetails.guestName != 'undefined') ? data.pickupDetails.guestName : ""
	let customerName = (data.getVal('customer.firstName', '').trim()) ? data.customer.firstName + " " + data.customer.lastName : guestName
	let accountName = (data.getVal('customer.accountName', '').trim() && showCustomerAccountName) ? data.customer.accountName : ''


	return (
		<Slide in direction="right">
			<ButtonBase style={{width:'100%'}} focusRipple>
				<div className={classes.root}
					style={{ 
						width: '100%',
						border: 'none',
						backgroundColor: (props.selected || (paymentLink && isMobile)) ? 'var(--white)' : '', 
						borderLeft: props.selected ? `2px solid var(--${global.branding})`: (paymentLink && !isMobile) ? 'solid 1px #E5E5E5': '', 
						zIndex: props.selected ? 5 : 0,
						boxShadow: props.selected ? '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px, 0px 1px 3px 0px rgb(0 0 0 / 12%), 0px 1px 1px 0px rgb(0 0 0 / 14%)' :'',
						borderTopLeftRadius: paymentLink ? '4px ':'',
						borderBottomLeftRadius: paymentLink ? '4px ':'',
						borderTopRightRadius: paymentLink && isMobile ? '4px ':'',
						borderBottomRightRadius: paymentLink && isMobile ? '4px ':'',
						borderTop: (props.hideTopBorder) ? '' : 'solid 1px #E5E5E5',
						borderBottom: 'solid 1px #E5E5E5',
						padding: isMobile ? '5px 5px 5px 0px' : '10px 10px 10px 0px',
						overflow: 'hidden'
					}}> 
					{/* {!data.closed && data.totals && data.totals.paidDashNow > 0 && <IconButton style={{padding:0, color: "white", marginLeft:6}}>< NotificationsIcon/></IconButton>} */}
					{/* {isCallAheadCheck && orderUpEnabled && data.pickupDetails.orderUpAt && <ChatBubbleOutlineIcon fontSize="small" style={{marginRight:'.3em'}}/>} */}
						
					<div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
						<div style={{display: 'flex', flexDirection: 'row', alignItems: "flex-start", width: paymentLink ? '75%' :'50%', justifyContent:"space-between"}}>
							<div style={{display: 'flex', flexDirection: 'column', alignItems:'center', width:'2.2em', justifyContent: "center", height: '100%'}}> 
								{!data.cancelled ? <>
									{(!paymentLink && !data.paidInFull) && (
										!data.textSendTime && !data.emailSendTime && data.textOnHoldUntil ?
												<ScheduleOutlined fontSize="inherit" style={{fontSize: 16}}/>
												:
												<div style={{borderRadius: '9999px', width: '12px', height: '12px', border: `2px solid ${color}`}}/>
										)} 
									{(data.paidInFull || paymentLink) && <div style={{borderRadius: '9999px', width: '12px', height: '12px', backgroundColor: color}}/>} 
								</>:<>
									<DFlag fontSize="small"/>
								</>}
							</div>
							<div style={{display: 'flex', flexDirection: 'column', alignItems: "flex-start", justifyContent:'center', width:'calc(100% - 2.2em)', paddingLeft:4}}>
								{(data.checkNumber || data.orderNumber) &&
									<span className={classes.overflowText} style={{fontFamily: 'Roboto', fontSize: '20px', paddingBottom: 2}}>
										{data.orderNumber ? data.orderNumber : (data.checkNumberDisplay ? data.checkNumberDisplay : data.checkNumber)}
									</span>
								}
								{accountName &&
									<span className={classes.overflowText} style={{fontSize: (data.checkNumber || data.orderNumber) ? '16px' : '20px', color: 'var(--gray)', textAlign:'left', paddingBottom: 2}}>
										{accountName}
									</span>
								}
								{customerName && !paymentLink &&
									<span className={classes.overflowText} style={{fontSize: (data.checkNumber || data.orderNumber) ? '16px' : '20px', color: 'var(--gray)', textAlign:'left', paddingBottom: 2}}>
										{customerName}
									</span>
								}
								{paymentLink && data.description &&
									<span className={classes.overflowText} style={{fontSize: (data.checkNumber || data.orderNumber) ? '16px' : '20px', color: 'var(--gray)', textAlign:'left', paddingBottom: 2}}>
										{data.description}
									</span>
								}
								{!isCallAheadCheck && data.tableNumber &&
									<span style={{fontSize: '16px', color: 'var(--gray)'}}>
										Table: {data.tableNumber}
									</span>
								}
								<span className={classes.overflowText} style={{fontSize: '16px', color: 'var(--gray)', textAlign:'left', width:'max-content'}}>
									{(data.pickupDetails || data.customer) && `${data.pickupDetails.phoneNumber ? global.formatPhoneNumber(data.pickupDetails.phoneNumber) : data.customer.phone ? global.formatPhoneNumber(data.customer.phone) : ''}`}
								</span>
								{global.isTextPay() && data.customer.email && (data.emailSendTime || data.emailOnHoldUntil) &&
									<span className={classes.overflowText} style={{fontSize: '16px', color: 'var(--gray)', textAlign:'left', width:'max-content'}}>
										{data.customer && `${data.customer.email ? data.customer.email : ''}`}
									</span>
								}
							</div>
						</div>
						{ showPickupDetail &&
							<div style={{display: 'flex', flexDirection: 'column', justifyContent: "center", width:'20%'}}>
									{data.pickupDetails && data.pickupDetails.vehicleColor && data.pickupDetails.vehicleType &&
										<DashCarIcon data={data.pickupDetails} />
									}
									{data.pickupDetails && data.pickupDetails.vehicleType == 'other' && data.pickupDetails.otherDescription &&
										<span variant="subtitle1" style={{fontFamily: 'Roboto-Light', fontSize: '16px', color: 'var(--dark-gray)'}} align="center">
											{data.pickupDetails.otherDescription}
										</span>
									}
									{data.pickupDetails && data.pickupDetails.pickupLocation &&
										<span variant="subtitle1" style={{fontFamily: 'Roboto-Light', fontSize: '16px', color: 'var(--dark-gray)'}} align="center">
											{data.pickupDetails.pickupLocation}
										</span>
									}
							</div>
						}
						<div style={{display: 'flex', flexDirection: 'column', justifyContent: "center", width: showPickupDetail? '50%': 'auto'}}> {/* showPickupDetail ? '40%': '50%' */}
							{showCheckTotal &&
								<div style={{display: 'flex', justifyContent: 'flex-end'}}>
									<span style={{ fontFamily: 'Roboto', fontSize: '20px', marginTop: 4, marginRight: 2}}>$</span>
									<span style={{ fontFamily: 'Roboto', fontSize: '30px',}}>{global.formatMoney(data.totals.total)}</span>
								</div>
							}
							{showTotalPaid && <>
								{!showBalanceDue && <span style={{ fontFamily: 'Roboto', fontWeight:300, fontSize: '15px', alignSelf:'flex-end'}}>Total Paid:</span>}
								<div style={{display: 'flex', justifyContent: 'flex-end'}}>
									{showBalanceDue && <span style={{ float: 'left', fontFamily: 'Roboto-Light', fontSize: '15px', color: 'var(--dark-gray)', marginTop: 6, marginRight: 4}}>Paid:</span>}
									<span style={{ fontFamily: 'Roboto', fontSize: '20px', marginTop: 4, marginRight: 2}}>$</span>
									<span style={{ fontFamily: 'Roboto', fontSize: '30px'}}>{totalPaid}</span>
								</div>
							</>}
							{showBalanceDue && <>
								<div style={{display: 'flex', alignItems:'flex-start', borderTop:'1px solid var(--light-gray)'}}>
									<span style={{ justifySelf:'flex-start', float:'left', fontFamily: 'Roboto-Light', color: 'var(--dark-gray)', fontWeight:300, fontSize: '15px', marginTop: 2, marginRight: 4}}>Due:</span>
									<div style={{display: 'flex', justifyContent: 'flex-end', alignItems:'flex-start', width:'100%'}}>
										<span style={{ fontFamily: 'Roboto', fontSize: '12px', marginTop: 4, marginRight: 2}}>$</span>
										<span style={{ fontFamily: 'Roboto', fontSize: '20px'}}>{global.formatMoney(balance)}</span>
									</div>
								</div>
							</>}
							{!paymentLink &&
								<span align="right" style={{ display: 'flex', justifyContent: 'flex-end', fontFamily: 'Roboto', fontSize: '15px', color: 'var(--dark-gray)', margin: '-2px 0px', fontWeight: '300' }}>
									{moment(statusDateStr).isAfter(moment()) ?
										moment(statusDateStr).isAfter(moment().add(1, 'years')) ? 'On hold' :
											`Scheduled for ${moment(statusDateStr).format('MM/DD')}` :
										moment(statusDateStr).fromNow()}
								</span>
							}
						</div>
					</div>
				</div>
			</ButtonBase>
		</Slide>
	);
};


export default DashCard;