import log from 'loglevel';
import dashFetch from '../utils/dashFetch';


export default async function (restaurant, debug) {
	return new Promise((resolve, reject) => {
		log.info('>>> getFeedbackDataApi api');
		if (debug) log.info('getFeedbackDataApi api');
		const url = `${global.dashServerUrl}/dashboard/api/v1/dashboard/getFeedbackData`;

		const body = {
			restaurant, debug
		};

		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info('>>> getFeedbackDataApi JSON');
			log.info(json)
			if (json.success) {
				global.set({ 'feedbackData': json.data })
				resolve(json.data);
			} else {
				resolve(false)
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}