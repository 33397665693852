import React, { useState, useEffect } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import theme from '../theme'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Grid, MenuItem, Menu, Button, IconButton, Paper, Typography, Tab, Tabs, MenuList, Switch, Tooltip } from '@material-ui/core';
import { useHistory, useLocation } from "react-router-dom";
import { purple } from '@material-ui/core/colors';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';

export default (props) => {
	const history = useHistory();

	let [activePages] = global.get('activePages')
	if (!activePages) {
		activePages = {'basic': 'today', 'admin': 'admin/restaurants/addEditRestaurant'}
	}
	let [restaurants] = global.get('restaurants');
	let [restaurant] = global.get('restaurant')
	const [checkInEnabled] = global.get('checkInEnabled')
	let [navBar] = global.get('navBar')
	if (!navBar) {
		navBar = localStorage.navBar ? localStorage.navBar : 'basic'
	}

	const useStyles = makeStyles((theme) => ({
		tabs: {
			position: 'fixed', 
			borderRadius: 0, 
			width: '100%', 
			top: props.top, 
			height: props.navHeight, 
			borderBottom: '1px solid #CCC', 
			backgroundColor: theme.palette.background.default, 
			zIndex: 10,
			margin: 0,
			padding: 0,
		},
		tab: {
			margin: 0,
			padding: 0,
			fontWeight: 500,
			display:'inline-block'
		},
		selectedTab: {
			margin: 0,
			padding: 0,
			fontWeight: 600, 
            color: theme.palette.primary.main,
            opacity:1
		},
		tabsAdmin: {
			position: 'fixed', 
			borderRadius: 0, 
			width: '100%', 
			top: props.top, 
			height: props.navHeight, 
			borderBottom: '1px solid #CCC', 
			zIndex: 10,
			margin: 0,
			padding: 0,
			backgroundColor: theme.palette.background.default, 
		},
		tabAdmin: {
			margin: 0,
			padding: 0,
			fontWeight: 500,
			display:'inline-block',
		},
		selectedTabAdmin: {
			margin: 0,
			padding: 0,
			fontWeight: 600,
			color: theme.palette.primary.main
		},
		toggle: {
			position: 'fixed', 
			borderRadius: 0, 
			width: '100px', 
			top: props.top, 
			height: props.navHeight, 
			right: 15,
			zIndex: 12,
			margin: 0,
			padding: 0,
			textAlign: 'right',
			marginTop: 3,
		},
	}))

	function setNavBar(navBar) {
		handleNavigate(navBar)
	}
	
	const classes = useStyles();

	function handleNavigate(navBar, activePage) {
		localStorage.navBar = navBar
		if (!activePage) {
			activePage = activePages[navBar]
		}
		// alert('navBar: ' + navBar + ' >> activePage: ' + activePage)
		activePages = {...activePages}
		activePages[navBar] = activePage
		global.set({navBar, activePages})
		console.log("activePages: " + JSON.stringify(activePages))
		if (activePage) {
			history.push(`/` + activePage)
		}
	}

	useEffect(() => {
		if (navBar == 'basic' && activePages[navBar] != 'setup' && restaurants != undefined && restaurants.length == 0) {
			handleNavigate(navBar, 'setup')
		}
	}, [restaurants]);

	useEffect(() => {
		let navBar = 'basic'
		let activePage = history.location.pathname.replace('/', '');
		if (activePage.indexOf('/') > -1) {
			let vars = activePage.split('/')
            navBar = vars[0] == 'admin' ? 'admin' : 'basic'
        }
        if (activePages.basic.indexOf('restaurantInfo') > -1 ) {
            activePages.basic = 'restaurantInfo'
        }
		handleNavigate(navBar, activePage)
	}, [window.onpopstate]);

	//   const options = ['Create a merge commit', 'Squash and merge', 'Rebase and merge'];

	  	let options = [
			{
				key: 'addSite',
				name: 'Add Site', 
				callback: () => {
					global.set({ 'restaurant': null})
					history.push(`/admin/restaurants/addEditRestaurant/new`)
				}
			},
			{
				key: 'siteListing',
				name: 'List Sites', 
				callback: () => {
					history.push(`/admin/restaurants/restaurantListing`)
				}
			},
			{
				key: 'restaurantGroup',
				name: 'List Groups', 
				callback: () => {
					history.push(`/admin/restaurants/restaurantGroups`)
				}
			},
			{
				key: 'unassociatedGateways',
				name: 'Unassociated Gateways', 
				callback: () => {
					history.push(`/admin/gateways`)
				}
			},
        ]
        
	  	let setupOptions = [
			{
				key: 'restaurantInfo',
				name: 'Restaurant Info', 
				callback: () => {
					history.push(`/restaurantInfo`)
                },
                permission: global.hasPermission('restaurant.branding.style')
			}
		]

	  	const [open, setOpen] = React.useState(false);
	  	const [setupMenuOpen, setSetupMenuOpen] = React.useState(false);
		const anchorRef = React.useRef(null);
        const [selectedIndex, setSelectedIndex] = React.useState(1);
        const [anchorEl, setAnchorEl] = React.useState(null);

		const handleClick = () => {
			console.info(`You clicked ${options[selectedIndex]}`);
		};

		const handleMenuItemClick = (event, index) => {
			setSelectedIndex(index);
			setOpen(false);
		};

		const handleToggle = () => {
			setOpen((prevOpen) => !prevOpen);
        };
        
		const handleSetupToggle = (event) => {
            setAnchorEl(event.target);
			setSetupMenuOpen(setupMenuOpen? false : true);
        };

        const closeSetupToggle = () => {
            setSetupMenuOpen(false)
        }

		const handleClose = (event) => {
			if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
			}
			setOpen(false);
        };
	return (
		<div>
			<Grid container item xs={12}>
                {restaurant && navBar == 'basic' && 
                    <>
                        <Tabs className={classes.tabs}
                            style={{ maxHeight: '3.5em', minHeight: '2.7em'}}
                            value={activePages.basic}
                            onChange={(e, value) =>{
                                global.set({value})
                                handleNavigate('basic', value)
                            }}
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: 'var(--background)'
                                }
                            }}
                        >
                            { global.hasPermission('restaurant.*') && !restaurant.driveThruOnly && <Tab className={activePages.basic == '' || activePages.basic == 'today' ? classes.selectedTab : classes.tab}  label="Today" value="today" />}
                            { checkInEnabled && global.hasPermission('restaurant.*') && <Tab className={activePages.basic == '' || activePages.basic == 'checkIn' ? classes.selectedTab : classes.tab}  label="Check-In" value="checkIn" />}
                            {/* { global.hasPermission('dashboard.view') && <Tab className={activePages.basic == 'dashboard' ? classes.selectedTab : classes.tab}  label="Dashboard" value="dashboard" />} */}
                            { !global.isCurbly() && global.hasPermission('restaurant.customers') && false && <Tab className={activePages.basic == 'customerListing' ? classes.selectedTab : classes.tab} label="Customers" value="customerListing" />}
                            { !global.isCurbly() && global.hasPermission('restaurant.payments') && <Tab className={activePages.basic == 'payments' ? classes.selectedTab : classes.tab} label="Payments" value='payments' />}
                            { !global.isCurbly() && global.hasPermission('restaurant.*') && global.f(restaurant, 'config.mobile.allowFeedback', false) && <Tab className={activePages.basic == 'reviewsListing' ? classes.selectedTab : classes.tab} label="Reviews" value='reviewsListing' />}
                            { !global.isCurbly() && global.hasPermission('restaurant.*') && <Tab className={activePages.basic.indexOf('restaurantInfo') > -1 ? classes.selectedTab : classes.tab} label="Setup" value='restaurantInfo' />}
                            { !global.isCurbly() && global.hasPermission('request.view') && false && <Tab className={activePages.basic == 'requestListing' ? classes.selectedTab : classes.tab} label="Requests" value="requestListing" />}
                        </Tabs>
                        
                    </>
				}

				{navBar == 'admin' && 
					<Tabs className={classes.tabsAdmin}
						style={{ maxHeight: '3.5em', minHeight: '2.7em'}}
						value={activePages.admin}
						onChange={(e, value) =>{
							global.set({value})
							handleNavigate('admin', value)
						}}
						TabIndicatorProps={{
							style: {
								backgroundColor: 'var(--background)'
							}
						}}
					>
						<Tab label="Site" value="admin/restaurants/addEditRestaurant" className={activePages.admin == '' || (activePages.admin && activePages.admin.indexOf('admin/restaurants') > -1) ? classes.selectedTabAdmin : classes.tabAdmin} style={{minWidth: 80, paddingLeft: 15}} />
						<Button
							color="primary"
							size="small"
							aria-controls={open ? 'split-button-menu' : undefined}
							aria-expanded={open ? 'true' : undefined}
							aria-label="select merge strategy"
							aria-haspopup="menu"
							style={{minWidth: 20}}
							onClick={handleToggle}
						>
							<ArrowDropDownIcon />
						</Button>
						{ global.hasPermission('admin.users.*') && 
							<Tab label="Accounts" value="admin/accounts" className={(activePages.admin && activePages.admin.indexOf('admin/accounts') > -1) ? classes.selectedTabAdmin : classes.tabAdmin} />
						}
						{ global.hasPermission('admin.billing.*') && 
							<Tab label="Billing" value="admin/billing" className={activePages.admin == '' || (activePages.admin && activePages.admin.indexOf('admin/billing') > -1) ? classes.selectedTabAdmin : classes.tabAdmin} />
						}
						<Tab label="Products" value="admin/products" className={(activePages.admin && activePages.admin.indexOf('admin/products') > -1) ? classes.selectedTabAdmin : classes.tabAdmin} />
						{global.isAdmin() && 
							<Tab label="Refund" value="admin/refund" className={(activePages.admin && activePages.admin.indexOf('admin/refund') > -1) ? classes.selectedTabAdmin : classes.tabAdmin} />}
                        {global.hasPermission('admin.*') && 
						    <Tab label="Config" value="admin/config" className={(activePages.admin && activePages.admin.indexOf('admin/config') > -1) ? classes.selectedTabAdmin : classes.tabAdmin} />
                        }
					</Tabs>
				}

				<Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{top: 98, zIndex: 11111}}>
					{({ TransitionProps, placement }) => (
						<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom' ? 'center bottom' : 'center bottom',
						}}
						>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu">
									{options.map((option, index) => (
										<MenuItem key={option.key} onClick={(event) => option.callback()}>{option.name}</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
						</Grow>
					)}
					</Popper>

				{global.hasPermission('admin.*') && 
					<div className={classes.toggle}>
						<Tooltip title={navBar == 'basic'? 'Admin' : 'Restaurant'}>
							<Switch
								onChange={() => {
									setNavBar(navBar == 'basic' ? 'admin' : 'basic')
								}}
								value={navBar}
								checked={navBar != 'basic'}
								color={"primary"}
								variant="standard"
							/>
						</Tooltip>
					</div>
				}
			</Grid>
		</div>
	)
};