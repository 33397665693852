import log from 'loglevel';
import dashFetch from '../utils/dashFetch';
const uuidv4 = require('uuid/v4');

export default async function (firstName, lastName, email, phoneNumber, role, restaurantGroups, restaurants, agency, createUser = false, appName = '', whiteLabelCode = '', affiliate = false, debug = false) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('create account api');

		const url = `${global.dashServerUrl}/api/v1/account/createAccountDashboard`;
		let deviceId = localStorage.deviceId;
		const utcDateOffset = new Date().getTimezoneOffset()
		const body = {
			firstName: firstName ? firstName.trim() : "",
			lastName: lastName ? lastName.trim() : "",
			phoneNumber,
			email: email ? email.trim().toLowerCase() : "",
			appName,
			whiteLabelCode,
			createUser,
			role,
			restaurantGroups,
			restaurants,
			mobileDeviceInfo: {
				deviceId,
			},
			utcDateOffset,
			agency,
			affiliate
		};

		let options = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		}

		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info("json")
			log.info(json)
			if (json.success) {
				let data = json.data
				if (debug) log.info('success');
				/* SAVE ACCOUNT DATA LOCALLY */
				if (data && data.token) {
					localStorage.token = data.token
					localStorage.refreshToken = data.refreshToken
					localStorage.tokenTimeout = data.tokenTimeout
					localStorage.refreshTimeout = data.refreshTimeout
					localStorage.tosAcceptance = data.tosAcceptance
					localStorage.permissions = JSON.stringify(data.permissions)
					localStorage.accountFirstName = data.firstName
					localStorage.accountLastName = data.lastName
					if (data.affiliate && data.affiliateCode) {
						localStorage.affiliateCode = data.affiliateCode
					} else {
						delete localStorage.affiliateCode
					}
				}
				global.showNotification('User saved successfully', 'success')
				resolve(true);
			} else {
				log.error(json.errors)
				global.showNotification(json.errors, 'error')
				reject(json.errors);
			}
		}).catch((err) => { 
			log.error(err)
			reject(err.message);
		 })
	})
}