import React, { useState } from 'react';
import dashFetch from '../../utils/dashFetch'
import {
	Typography,
	Button,
} from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';

export default function DSubmitButton(props) {
	const collectionsToSave = props.collectionsToSave;
	let saveableObjects = []
	if (props.parentState && props.parentState.saveableObjects) {
		saveableObjects = props.parentState.saveableObjects
	}
	const [isSaving, setIsSaving] = useState(false);

	const getStateByKeys = (keys) => {
		let keyList = {};
		keys.forEach((key) => {
			const objectId = key.getAttribute("dashkey")
			const collection = key.getAttribute("collection")
			if (saveableObjects[objectId]) {
				keyList[`${collection}.${objectId}`] = {...saveableObjects[key.getAttribute("dashkey")]}; // e.g. th213eh-sdfkje12-sdj2312s
			}
		});
		return keyList;
	};

	const saveIt = async (preSave, next, debug = false) => {

		setIsSaving(true)
		if (preSave) {
			preSave()
		}
		let updatedDocs
		let data
		const items = Array.from(window.document.querySelectorAll('.DForm')).filter((itm) => {
			return collectionsToSave.indexOf(itm.getAttribute('collection')) >= 0;
		});
		if (debug) console.log("ITEMS ")
		if (debug) console.log(items)
		let saveableObjects = getStateByKeys(items);
		if (saveableObjects) {
			if (debug) console.log("SENDING SAVEABLE OBJECTS TO UPSERT")
			data = await dashFetch(`${global.dashServerUrl}/dashboard/api/v1/dashboard/objectUpsert`, saveableObjects, true)
			if (data.json.success) {
				updatedDocs = data.json.updatedDocs
			}
		}
		if (next) {
			if (debug) console.log('Entering "next" callback')
			if (debug) console.log(updatedDocs)
			next(updatedDocs)
		}
	};

	return (
		<Button 
			align="center"
			variant="contained"
			color="primary"
			disabled={props.disabled || isSaving}
			className={props.className}
			onClick={() => saveIt(props.preSave, props.next, true)}
			style={{
				// marginTop: '5%',
				width: 150,
				height: 45
			}}
			endIcon={isSaving ? <CircularProgress size="24px" thickness={3.6}/> : null}
			>
			<Save/>
			<Typography variant="subtitle1" style={{ color: 'white', width: 160 }}>
				Save
			</Typography>
		</Button>
	)
}
