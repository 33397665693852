import log from 'loglevel';
import { NotificationManager } from 'react-notifications'
import dashFetch from '../utils/dashFetch';

export default async function (data, debug) {
	return new Promise(async (resolve, reject) => {

		if (debug) log.info('***** Save Card Token API ****')
		const url = `${global.dashServerUrl}/api/v1/account/saveCardToken`;

		log.info('save card url:', url);
		log.info('data')
		data.fromDashboard = true
		log.info(data)
		

		dashFetch(url, data, debug).then(({ res, json }) => {
			log.info('>>> saveCardToken JSON');
			log.info(json)
			if (!json.success) {
				NotificationManager.warning('', json.errors, 3000)
				reject(json.errors)
			} else {
				resolve({success:true, encryptedCard: json.encryptedCard});
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}