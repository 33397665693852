import log from 'loglevel';
import moment from 'moment';

export default async (check, debug = false) => {
	return new Promise(async (resolve, reject) => {
		try {
			if (debug) log.info('***** downloadAttachment ****');

			const url = `${global.dashServerUrl}/api/v1/check/downloadAttachment`;
			const body = {
				check: check._id,
			};

			let options = {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(body),
				cache: "no-store",
			}

			let fileName = check.attachment? check.attachment: `invoice-${moment().format('DD-MM-YYYY')}`

            fetch(url, options, debug).then((res => res.blob()))
			.then(blob => {
				if (blob) {
					var url = window.URL.createObjectURL(blob);
					var a = document.createElement('a');
					a.href = url;
					a.download = fileName;
					document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
					a.click();
					a.remove();  //afterwards we remove the element again         
					resolve(true)
				} else {
					reject(false)
				}
			}).catch((err) => {
				log.error(err)
				reject(err.message);
			})
		} catch (e) {
			log.info(e)
		}
	})
};