import log from 'loglevel';
import dashFetch from '../utils/dashFetch';


export default async function (product, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('upsertProductApi api');
		const url = `${global.dashServerUrl}/dashboard/api/v1/dashboard/upsertProduct`;

		if (product.unitPrice) {
			product.unitPrice = product.unitPrice * 100
		}
		if (product.mininumPrice) {
			product.mininumPrice = product.mininumPrice * 100
		}

		const body = {
			product,
			debug
		};

		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info('>>> upsertProductApi JSON');
			log.info(json)
			if (json.success) {
				resolve(json.record);
			} else {
				resolve(false)
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}