import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import log from 'loglevel';
import _ from 'lodash';
import {
	Grid,
	Typography,
	Paper,
	TextField,
	Checkbox,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core';
import DForm2 from '../../../components/dash/DForm2';

const styles = (theme) => ({
	root: {},
	paper: {
		padding: '2%',
		margin: '1%',
	},
	disabledField: {
		width: '85%',
	},
	bottomless: {
		borderBottom: 'none'
	}
});

const Dash = (props) => {
	const { classes } = props;


	const restaurantGroup = props.record.restaurantGroup
	const restaurants = props.record.restaurants
	const sameBillingInfoForAllRestaurants = restaurantGroup.billingInfo.enabled

	const buildRestaurantPaperTable = (idx) => {
		return (
			<DForm2 record={props.record} validate={false} >
				<Grid item lg={6}>
					<Paper className={classes.paper}>
						<Typography variant={"body1"} color="primary">
							{restaurants[idx].name} - {restaurants[idx].locationName}
						</Typography>
						<Typography variant={"body2"} style={{paddingTop: '1%'}}>
							Address Information
						</Typography>
						<Grid container>
							<Grid item lg={6}>
								<TextField
									disabled
									className={classes.disabledField}
									value={restaurants[idx].locationInfo.addressLine1}
								/>
							</Grid>
							<Grid item lg={6}>
								<TextField
									disabled
									className={classes.disabledField}
									value={restaurants[idx].locationInfo.addressLine2}
								/>
							</Grid>
							<Grid item lg={6}>
								<TextField
									disabled
									className={classes.disabledField}
									value={restaurants[idx].locationInfo.city}
								/>
							</Grid>
							<Grid item lg={6}>
								<TextField
									disabled
									className={classes.disabledField}
									value={restaurants[idx].locationInfo.state}
								/>
							</Grid>
							<Grid item lg={6}>
								<TextField
									disabled
									className={classes.disabledField}
									value={restaurants[idx].locationInfo.zipCode}
								/>
							</Grid>
						</Grid>
						{!sameBillingInfoForAllRestaurants && 
							<Grid container style={{paddingTop: '1%'}}>
								<Grid item lg={12}>
									<Typography variant="body2">
										Billing information
									</Typography>
								</Grid>
								<Grid item lg={6}>
									<TextField 
										
										value={"Last four acc number / cc number from card connect token"}
									/>
								</Grid>
							</Grid>
						}
					</Paper>
				</Grid>
			</DForm2>
		)
	}

	async function tokenizeAch(bankName, nameOnAccount, routingNumber, accountNumber, encryptedCard) {
		
	}
	
	return (
		<div className={classes.root}>
			<Grid container>
				<Grid item lg={4}>
					<Paper className={classes.paper}>
						<Typography variant="h5" color="primary">
							Site Group
						</Typography>
						<Typography variant="body1">
							{restaurantGroup.name}
						</Typography>
						<Typography variant="body2" color="primary">
							Point of Contact
						</Typography>
						<Grid container>
							<Grid item lg={6}>
								<TextField
									disabled
									className={classes.disabledField}
									value={restaurantGroup.primaryContactInfo && restaurantGroup.primaryContactInfo.firstName}
								/>
							</Grid>
							<Grid item lg={6}>
								<TextField
									disabled
									className={classes.disabledField}
									value={restaurantGroup.primaryContactInfo && restaurantGroup.primaryContactInfo.lastName}
								/>
							</Grid>
							<Grid item lg={6}>
								<TextField
									disabled
									className={classes.disabledField}
									value={restaurantGroup.primaryContactInfo && restaurantGroup.primaryContactInfo.title}
								/>
							</Grid>
							<Grid item lg={6}>
								<TextField
									disabled
									className={classes.disabledField}
									value={restaurantGroup.primaryContactInfo && restaurantGroup.primaryContactInfo.phone}
								/>
							</Grid>
							<Grid item lg={6}>
								<TextField
									disabled
									className={classes.disabledField}
									value={restaurantGroup.primaryContactInfo && restaurantGroup.primaryContactInfo.email}
								/>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<Grid item lg={4}>
					<Paper className={classes.paper}>
						<Typography variant="h5" color="primary">
							Product Info
						</Typography>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell>
										One Year Contract Discount ($987 savings!)
									</TableCell>
									<TableCell>
										<Checkbox
											color='primary'
											disabled
											checked={restaurantGroup.productSelection.chosenOneYearDiscount}
										/>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>
										Pay at the table {restaurantGroup.productSelection.payAtTheTable}
									</TableCell>
									<TableCell>
										<Checkbox
											color='primary'
											disabled
											checked={true}
										/>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>
										Loyalty
									</TableCell>
									<TableCell>
										<Checkbox
											color='primary'
											disabled
											checked={restaurantGroup.productSelection.chosenLoyaltyProgram}
										/>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell className={classes.bottomless}>
										Free loyalty for the first year
									</TableCell>
									<TableCell className={classes.bottomless}>
										<Checkbox
											color='primary'
											disabled
											checked={restaurantGroup.productSelection.chosenLoyaltyProgram}
										/>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Paper>
				</Grid>
				<Grid item lg={4}>
					test
				</Grid>

				{_.times(restaurants.length, (idx) => {
					return (
						buildRestaurantPaperTable(idx)
					)
				})}
			</Grid>
		</div>
	);
};

Dash.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withStyles(styles, { withTheme: true })(Dash);
