import React from 'react';

export default (props) => {
    return (
        <svg width={props.width ? props.width : "70px"} height={props.height ? props.height :"38px"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 116.309 75.806">
            <g id="elavon" transform="translate(0 -0.02)">
                <path id="Path_1" data-name="Path 1" d="M93.86,55.32h3.39V43.71c0-3.7,2-6.45,6.09-6.45a3.88,3.88,0,0,1,4.22,4.12V55.32h3.39V41.78c0-4.42-1.67-7.52-7.09-7.52A7.15,7.15,0,0,0,97.17,38h-.08V34.71H93.86Zm-20-10.27c0-5,2.87-7.81,6.41-7.81s6.41,2.79,6.41,7.81-2.88,7.76-6.42,7.76-6.41-2.78-6.41-7.76m-3.59,0c0,6,3.47,10.75,10,10.75s10-4.74,10-10.75-3.47-10.79-10-10.79-10,4.74-10,10.79m-.98-10.34H65.74L60.12,51.9h-.08L54.27,34.71H50.48L58.13,55.3h3.63ZM50.05,52.62a3.06,3.06,0,0,1-1,.19c-1.08,0-1.08-.71-1.08-2.31V39.91c0-4.82-4-5.65-7.72-5.65-4.58,0-8.53,1.79-8.73,6.89h3.39c.16-3,2.27-3.91,5.14-3.91,2.12,0,4.51.47,4.51,3.47,0,2.55-3.19,2.31-6.93,3-3.51.68-7,1.68-7,6.34,0,4.1,3.06,5.77,6.81,5.77a9.14,9.14,0,0,0,7.25-3.18c0,2.23,1.11,3.18,2.86,3.18a4.52,4.52,0,0,0,2.43-.56Zm-5.49-4.46c0,1.87-1.83,4.65-6.3,4.65-2.07,0-4-.79-4-3,0-2.51,1.91-3.31,4.14-3.71s4.84-.45,6.16-1.39ZM23.63,55.32h3.39V26.89H23.63ZM0,55.32H19.75V52.14h-16V42.3H18.56V39.12H3.78v-9H19.63V26.89H0Z"/>
                <path id="Path_2" data-name="Path 2" d="M84.41,12.99a26.532,26.532,0,0,0-.08-3.4c-10.12.86-24.32,7.36-37.48,17.94q-2.22,1.8-4.29,3.64h.84l.72-.6c13.85-11.14,29-17.61,39.24-17.61q.54,0,1.05.03Zm11.15-.46a10.51,10.51,0,0,0-6-2.76c.15,1.35.24,2.81.26,4.37a9.6,9.6,0,0,1,3.08,2.05,9.8,9.8,0,0,1,2.53,7.79,23.17,23.17,0,0,1-1.78,7.19h.54a27.55,27.55,0,0,0,3.76-11.21,9.35,9.35,0,0,0-2.39-7.43Z" fill="#0c2074"/>
                <path id="Path_3" data-name="Path 3" d="M87.3,4.02c-.9-2.66-2.22-4-3.91-4-3.1,0-7,4.59-10.85,12.48.67-.25,1.33-.48,2-.7,2.7-4.54,5.26-7.2,7.12-7.2h.16c1,.09,1.79,1.09,2.23,3,1.14,4.81-.12,14.25-2.92,24.2a12.62,12.62,0,0,1,5.62,1.57c2.13-12.47,2.51-23.58.55-29.35ZM70.38,17.21q-1.32,3.13-2.6,6.73c-.83,2.37-1.62,4.79-2.34,7.23h.83a112.523,112.523,0,0,1,4.06-11.08c.52-1.19,1-2.3,1.55-3.37Z" fill="#0075c9"/>
                <path id="Path_4" data-name="Path 4" d="M115.56,52.25a1.65,1.65,0,0,1,0,2.33,1.57,1.57,0,0,1-1.16.48,1.65,1.65,0,1,1,1.16-2.81Zm.19-.19a1.92,1.92,0,0,0-2.69,0,1.87,1.87,0,0,0-.56,1.35,1.83,1.83,0,0,0,.55,1.35,1.909,1.909,0,1,0,2.7-2.7Zm-1.07,1.26a1,1,0,0,1-.35,0H114v-.71h.32a1,1,0,0,1,.44.08.357.357,0,0,1-.13.63Zm-1,1.13h.37v-.82h.29a.94.94,0,0,1,.41.06.52.52,0,0,1,.2.48v.26h.34a.751.751,0,0,1,0-.11v-.31a.56.56,0,0,0-.12-.33.47.47,0,0,0-.36-.2,1,1,0,0,0,.31-.1.48.48,0,0,0,.2-.42.53.53,0,0,0-.32-.53,1.64,1.64,0,0,0-.58-.07h-.86Z"/>
            </g>
        </svg>
    )
}