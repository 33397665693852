import React from 'react';
import theme from '../theme'
import moment from 'moment'
import acceptTermsOfServiceApi from '../actions/acceptTermsOfServiceApi'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import log from 'loglevel';

export default function EarnMoneyDialog(props) {
    const [showTermsOfUse, setShowTermsOfUse] = global.get('showTermsOfUse')



    const agreeAndClose = async() => {
        setShowTermsOfUse(false)
        await acceptTermsOfServiceApi()
    }

  	return (
        <Dialog open={showTermsOfUse} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Terms of Service</DialogTitle>
            <DialogContent dividers>
                <span style={{fontWeight:500}}>COMPLIANCE WITH SMS/TEXT AND EMAIL MESSAGING LAWS</span>
                <br/><br/>

               
                
                <span>You understand and agree that all messages you send via the Site will be sent in compliance with all federal, state, and local laws, statutes and regulations as well as applicable text/SMS/MMS messaging/telecommunications industry guidelines, including, but not limited to, the following laws and guidelines, and to check for any revisions, as they may be amended over time.</span>
                <br/><br/>
                <ul>
                    <li>Telephone Consumer Protection Act ("TCPA"), 47 U.S.C. § 227, et seq., and related regulations, 47 C.F.R. Part 64.1200, et seq; </li>
                    <li>Telemarketing Sales Rule ("TSR"), 16 C.F.R. Part 310, et seq.; </li>
                    <li>Controlling the Assault of Non-Solicited Pornography and Marketing ("CAN-SPAM") Act of 2003; </li>
                    <li>Mobile Marketing Association ("MMA") </li>
                    <li>U.S. Consumer Best Practices Guidelines for Messaging; </li>
                    <li>Cellular Telecommunications Industry Association ("CTIA") </li>
                    <li>Best Practices and Guidelines for Location-Based Services; </li>
                    <li>CTIA Messaging Principles and Best Practices; </li>
                    <li>CTIA SMS Interoperability Guidelines; and </li>
                    <li>CTIA Short Code Monitoring Program Handbook. </li>
                </ul>
                <br/><br/>
                <span>The Site provides the software and carrier-connectivity through which you send your messages; you are solely and exclusively responsible for complying with applicable messaging laws (and for defending and indemnifying the Site from any claims in which it is alleged that you failed to do so).</span>

                
                <br/><br/>
                <span style={{fontWeight:500}}>COMPLIANCE WITH CONVENIENCE FEE AND SURCHARGING LAWS</span>
                <br/><br/>
                <span>You understand and agree that all convenience fees or surcharges administered by you via the Site will be done in compliance with all federal, state, and local laws, statutes and regulations as well as applicable credit card network guidelines, including but not limited to rules set Discover Card, American Express, Mastercard, and Visa. You are solely and exclusively responsible for complying with applicable convenience fee or surcharging laws (and for defending and indemnifying the Site from any claims in which it is alleged that you failed to do so.)</span>
                    
     
     
            </DialogContent>
            <DialogActions>
                <Button onClick={agreeAndClose} color="primary">
                    Accept
                </Button>
            </DialogActions>
        </Dialog>
  	);
}

