import log from 'loglevel';
import axios from 'axios';
import dashFetch from '../utils/dashFetch';

export default async function (restaurantMenuId) {
	return new Promise( async(resolve, reject) => {

		try {
			const url = `${global.dashServerUrl}/api/v1/restaurant/deleteRestaurantMenu`;
	
			const body = {
				restaurantMenuId
			}

			let json = await dashFetch(url, body);

			if (json.data && json.data.success) {
				resolve(json.data.data);
				log.info('success: ' + json.data.data)
			} else {
				resolve(json)
				log.info('FAILURE ')
			}
		} catch(err) {
			log.error(err)
		}

	})
}