import React, { useState, useEffect } from 'react';
import { Grid, Dialog, DialogActions, Button, Typography, Checkbox, Table, TableRow, TableCell, TableBody, TableHead, Paper, Input, CircularProgress, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import log from 'loglevel'
import moment from 'moment'
import theme from '../../theme'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogTitle from '../../dashboard/components/DialogTitle';
import requestRefundApi from '../../actions/requestRefundApi';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import getRestaurantCheckList from '../../actions/getRestaurantCheckListApi';

const useStyles = makeStyles({
	infoBanner: {
		padding: 5,
		paddingTop: 15
	},
	paper: {
		padding: 10,
		width: '100%',
		height: '100%'
	},
	root: {
        backgroundColor: '#F5F5F5',
        padding: 8
    },
    sectionHeader: {
        fontSize: '16px',
        padding: '6px 0px 8px 0px',
        fontWeight: 400
    },
})

const RefundRequestWindow = (props) => {
    // alert(JSON.stringify(props.permissions))
    const classes = useStyles();
	const check = props.check
	const payment = props.payment
	const [selectedPayment, setSelectedPayment] = useState(payment ? payment : check.payments && check.payments.filter(p => p.fromDash).length > 0 ? check.payments.filter(p => p.fromDash)[0]: null)
	const [refundAmount, setRefundAmount] = useState('')
	const [refundAmountFormatted, setRefundAmountFormatted] = useState('')
	const [newAmount, setNewAmount] = useState(0)
	const [fullRefund, setFullRefund] = useState(true)
	const [refundReason, setRefundReason] = useState('')
	const [loadingAsync, setLoadingAsync] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [restaurant] = global.get('restaurant')

	const smallScreen = useMediaQuery(theme().breakpoints.down('sm'));

	const requestRefund = async () => {
		if (!selectedPayment) {
			log.info('no payment selected')
			return
		}
		let confirmation = 'Are you sure you want to refund this payment of $' + global.formatMoney(selectedPayment.paymentAmount) + ' to the new total $' + global.formatMoney(newAmount) + '?'

		if (window.confirm(confirmation)) {
			setLoadingAsync(true)
			let success = await requestRefundApi(selectedPayment._id, refundAmount, refundReason)
			// if (success) {
			// 	showSuccess(true)
			// }
			setLoadingAsync(false)
			props.close(true)
        }

        // reload checks
        let newChecks
        let newChecksData = await getRestaurantCheckList(restaurant._id)
        if (newChecksData && newChecksData.checks) {
            newChecks = newChecksData.checks
        }
        const checks = newChecks
        global.set({checks})
    };
    
	const handleToggleChange = (e, value) => {
		setFullRefund(value)
	}

	const handleRefundAmount = (refundAmountSafe) => {
		// remove non numeric values from string & set state
		refundAmountSafe = refundAmountSafe.replace(/\D/g, '');
		if (refundAmountSafe !== refundAmount) {
			setRefundAmount(refundAmountSafe);
			setNewAmount(selectedPayment.paymentAmount - refundAmountSafe)
		}
	}

	useEffect(() => {
		setRefundAmountFormatted('$' + (refundAmount / 100).toFixed(2));
	}, [refundAmount])

	useEffect(() => {
		if (fullRefund) {
			handleRefundAmount(selectedPayment.paymentAmount? selectedPayment.paymentAmount.toString() : '')
		}
	}, [fullRefund])
	return (
		<Dialog
			aria-labelledby="customized-dialog-title"
			aria-describedby="simple-modal-description"
			open={true}
			onClose={() => props.close() }
			style={{ margin: 0, height: '100%', width: '100%'}}
			PaperProps={smallScreen ? {style:{margin: 0, width: '100%'}} : {style: {minWidth: 800}}}
		>

			<DialogTitle onClose={() => props.close()} id="customized-dialog-title">
				{check.orderNumber ? check.orderNumber : (check.checkNumberDisplay ? check.checkNumberDisplay : check.checkNumber) }
            </DialogTitle>
			<DialogContent dividers className={classes.root}>
				{showSuccess && 
					<Grid container direction='column' alignItems='stretch' style={{marginBottom:20}}>
						<Grid item ><CheckCircleIcon color="primary" style={{ fontSize: 80 }} /></Grid>
						<Grid item ><Typography variant="h6"> Refund Complete</Typography></Grid>
					</Grid>
				}
				{!showSuccess && <>
					<Grid container item>
						{loadingAsync && <Grid container justify="center"><CircularProgress /></Grid>}
						{!loadingAsync && payment && <>
							<Grid item xs={12} style={{ padding: 10 }}>
                                <span className={classes.sectionHeader}>Payment details</span>
                                <Paper style={{overflowY: "scroll"}}>
                                    <Table style={{margin: 'auto'}}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">Amount</TableCell>
                                                <TableCell align="left">Refund Amount</TableCell>
                                                <TableCell align="left">Status</TableCell>
                                                <TableCell align="left">Payment Method</TableCell>
                                                <TableCell align="left">Paid By</TableCell>
                                                <TableCell align="left">Paid At</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {check.payments.map((payment) => {
                                            if (payment.fromDash && payment.successful || payment.status == 'captured') {
                                                //TODO make sure this doesnt return the refund records
                                                return (
                                                    <TableRow style={selectedPayment._id != payment._id ? { backgroundColor: 'lightgray' } : {}} onClick={() => setSelectedPayment(payment)}>
                                                        <TableCell>
                                                            ${global.formatMoney(payment.paymentAmount)}
                                                        </TableCell>
                                                        <TableCell>
                                                            ${global.formatMoney(payment.refundAmount)}
                                                        </TableCell>
                                                        <TableCell>{payment.status}</TableCell>
                                                        <TableCell>{payment.paymentMethod}</TableCell>
                                                        <TableCell>{payment.paidBy}</TableCell>
                                                        <TableCell>{moment(payment.paidAt).fromNow()}</TableCell>
                                                    </TableRow>
                                                );
                                            }
                                        })}
                                    </Table>
                                    {check.payments.length == 0 && (
                                        <center>
                                            <Typography variant="h6">No payments found</Typography>
                                        </center>
                                    )}

                                </Paper>
							</Grid>
						</>}{selectedPayment && 
                            <>
                                <Grid xs={12} container item style={{padding:10}}>
                                    <span className={classes.sectionHeader} style={{paddingBottom:0}}>Enter a reason</span>
                                    <Paper style={{padding: 10, width:'100%'}}>
                                        <Grid container>
                                            <Grid item lg={12} style={{ paddingBottom: 12 }}>
                                                Please provide a reason why this refund took place
                                            </Grid>
                                            <Grid item md={12} xs={12} lg={12} style={{ padding: "0 20px 10px 0" }}>
                                                <Input
                                                    required
                                                    style={{ width:'100%' }}
                                                    value={refundReason}
                                                    helperText={!refundReason ? 'Reason for refund is required' : ''}
                                                    onChange={(event) => setRefundReason(event.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} style={{padding:10}}>
                                    <span className={classes.sectionHeader}>Select Full or Partial refund</span>
                                    <Paper style={{padding: 10, width: '100%', opacity: refundReason ? '' :.7 }}>
                                        <Grid container justify="center" alignItems="center">
                                            <Grid item xs={12} sm={6} md={6} lg={6} spacing="1">
                                                <ToggleButtonGroup
                                                    exclusive
                                                    size="small"
                                                    value={fullRefund}
                                                    onChange={handleToggleChange}
                                                >
                                                    <ToggleButton
                                                        style={{ color: global.primaryColor }}
                                                        key={0}
                                                        // style={{ background: 'white'}}
                                                        value={true}
                                                        disabled={!refundReason}
                                                    >
                                                        Full
                                                    </ToggleButton>
                                                    <ToggleButton
                                                        style={{ color: global.primaryColor }}
                                                        key={0}
                                                        // style={{ background: 'white'}}
                                                        disabled={!refundReason}
                                                        value={false}
                                                    >
                                                        Partial
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                                {!fullRefund && <>
                                                    <Typography>Refund Amount: (Subtracted from total)</Typography>
                                                    <Input
                                                        style={{ width: '100%' }}
                                                        value={refundAmountFormatted}
                                                        required
                                                        disabled={!refundReason}
                                                        error={refundAmount > selectedPayment.paymentAmount}
                                                        helperText={refundAmount > selectedPayment.paymentAmount ? "Refund amount cannot be greater than payment amount" : ""}
                                                        onChange={(event) => handleRefundAmount(event.target.value)}
                                                    />
                                                </>}
                                            </Grid>
                                            <Grid container direction="row" item xs={12} sm={6} md={6} lg={6} style={{ paddingBottom: 12 }}>
                                                <Grid item sm={6} md={6} lg={6} xs={6} lg={6}>
                                                    <Typography variant="h6" style={{ marginRight: 4 }}>Original Amount:</Typography>
                                                </Grid>
                                                <Grid item sm={6} md={6} lg={6} xs={6} lg={6}>
                                                    <Typography color="primary" variant="h4" style={{ marginRight: 4, float: 'right' }}>
                                                        ${global.formatMoney(selectedPayment.paymentAmount)}
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={6} md={6} lg={6} xs={6} lg={6} >
                                                    <Typography variant="h6" style={{ marginRight: 4 }}>After Refund:</Typography>
                                                </Grid>
                                                <Grid item sm={6} md={6} lg={6} xs={6} lg={6}>
                                                    <Typography color="primary" variant="h4" style={{ marginRight: 4, float: 'right' }}>
                                                        ${global.formatMoney(selectedPayment.paymentAmount-refundAmount)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item lg={6} style={{ padding: "0 20px 10px 0" }}>
                                                
                                            </Grid>
                                        </Grid>
                                    </Paper>

                                
                                </Grid>
                                </>
						}
					</Grid>
					<DialogActions style={{ borderTop: '1px solid var(--divider)', padding: '10px 15px' }}>
						<Grid container>
							<Grid container direction="column" item lg={12}>
								<Button style={{ height: 'auto', float: 'right' }} color="primary" variant="contained" disabled={!selectedPayment || !refundAmount || !refundReason || refundAmount > selectedPayment.paymentAmount} onClick={() => { requestRefund(); }}>
									Request Refund
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				</>}
			</DialogContent>
		</Dialog>
	)

}

export default RefundRequestWindow;




