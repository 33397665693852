import log from 'loglevel';
import dashFetch from '../utils/dashFetch';

export default async function (restaurantId, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('sendEmailUpdatePaymentInfo API');
		const url = `${global.dashServerUrl}/dashboard/api/v1/dashboard/sendEmailUpdatePaymentInfo`;

		const body = {restaurantId, debug}
		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info('>>> sendEmailUpdatePaymentInfo JSON');
			log.info(json)
			resolve(json.success);
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}