import React, { useState, useEffect } from 'react';
import { Grid, Button, Dialog, DialogActions, DialogContent, CircularProgress } from '@material-ui/core';
import log from 'loglevel'
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '../../dashboard/components/DialogTitle';
import DForm2 from '../../components/dash/DForm2';
import DInput from '../../components/dash/DInput';
import PublishIcon from '@material-ui/icons/Publish';
import upsertDocumentToCollectionApi from '../../actions/upsertDocumentToCollectionApi';

export default function (props) {
    let [posType, setPosType] = useState(props.posType ? props.posType : {})
    const [loading, setLoading] = useState(false)

    const clickSave = async () => {
        try {
            posType = await upsertDocumentToCollectionApi(posType, 'PosType')
            if (posType) {
                global.showNotification("Success", 'success')
                props.refresh(true)
            }
			props.close()
		} catch (err) {
			log.error(err)
		}
    }
    
    const showForm = () => {
        return (
            <DForm2 name={'posType'} record={posType}>
                <Grid container style={{ overflowY:'auto', marginLeft: 0, width:'100%'}} spacing={2}>

                    <Grid item xs={12}>
                        <DInput label="POS Type Name" name="posType"/>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <DInput label="POS code" name="code"/>
                    </Grid>

                    <Grid item xs={12}>
                        <DInput label="Supported" name="supported" fieldType={'switch'}/>
                    </Grid>

                    <Grid item xs={12}>
                        <DInput label="sendPaymentDataToBackOffice" name="config.sendPaymentDataToBackOffice" fieldType={'switch'}/>
                    </Grid>
                   
                    <Grid item xs={12}>
                        <DInput label="Cloud-based" name="config.cloudBased" fieldType={'switch'}/>
                    </Grid>

                    <Grid item xs={12}>
                        <DInput label="Cloud-based config fields" name="config.cloudBasedConfigFields"/>
                    </Grid>
                    
                    <Grid item xs={12} style={{textAlign:'center'}}>
                        {loading && <CircularProgress size={30} color="#000000" style={{zIndex:2000}}/>}
                    </Grid>
                </Grid>
            </DForm2>
        )
    }

	return (
		<Dialog
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			maxWidth={'sm'}
			open={true}
			style={{ margin: 0, height: '100%', padding:'20px'}}
			onClose={ props.close }
			aria-labelledby="customized-dialog-title"
		>
			<DialogTitle onClose={ props.close } id="customized-dialog-title">
				ADD/EDIT POS TYPE
			</DialogTitle>
			<DialogContent dividers>
			 {showForm()}
			</DialogContent>
						
			<DialogActions >
				<Button onClick={props.close}>
					Close
				</Button>
				<Button onClick={clickSave}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	)

}
