import log from 'loglevel';
import dashFetch from '../utils/dashFetch';

export default async function (agencyName) {
	return new Promise((resolve, reject) => {
		const url = `${global.dashServerUrl}/api/v1/account/createAgencyDashboard`;

		const body = {
			agencyName: agencyName
		};

		let options = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		}

		dashFetch(url, body, false).then(({ res, json }) => {
			log.info("json")
			log.info(json)
			if (json.success) {
				global.showNotification('Agency saved successfully', 'success')
				resolve(json.agency);
			} else {
				log.error(json.errors)
				global.showNotification(json.errors, 'error')
				reject(json.errors);
			}
		}).catch((err) => { 
			log.error(err)
			reject(err.message);
		 })
	})
}