import log from 'loglevel';
import dashFetchUpload from '../utils/dashFetchUpload';

export default async function (obj) {
    return new Promise( async(resolve, reject) => {
        const url = `${global.dashServerUrl}/dashboard/api/v1/dashboard/uploadMenuRestaurantOrGroup`;
            
        // let menuObj = {
        //     isGroup,
        //     id: entity._id,
        //     menuUrl,
        //     menuFile,
        //     schedule: {
        //         startTime,
        //         endTime,
        //         fromDate,
        //         thruDate,
        //         daysPicked,
        //         allDayChecked
        //     }
        // }

        if(!obj.id){
            return resolve('ID required from either Restaurant or Restaurant Group')
        }

        const formData = new FormData();
        formData.append('id', obj.id )
        formData.append('startTime', obj.schedule.startTime)
        formData.append('endTime', obj.schedule.endTime)
        formData.append('allDayChecked', obj.schedule.allDayChecked)
        if (obj.isGroup) formData.append('isGroup', obj.isGroup)
        if (obj.schedule.fromDate) formData.append('fromDate', obj.schedule.fromDate)
        if (obj.schedule.thruDate) formData.append('thruDate', obj.schedule.thruDate)
        if (obj.schedule.daysPicked) formData.append('daysPicked', obj.schedule.daysPicked)
        if (obj.menuUrl) formData.append('menuUrl', obj.menuUrl)
        if (obj.menuFile) formData.append('file', obj.menuFile)

        let response = await dashFetchUpload(url, formData)

        if (response && response.data.success) {
            resolve(response.data);
            log.info('success: ' + response.data)
        } else {
            resolve(response.data)
            log.info('FAILURE ')
        }
    
	})
}