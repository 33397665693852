import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress } from '@material-ui/core';

const DConfirm = (props) => {
  const { title, children, open, setOpen, onConfirm } = props;

  return (
    <Dialog
      	open={open}
      	onClose={() => setOpen(false)}
	  	aria-labelledby="confirm-dialog"
	  	onKeyPress={(e) => {
			if (e.key === 'Enter') {
				setOpen(false);
            	onConfirm();
			}
			if (e.key === 'Esc') {
				setOpen(false);
			}
		}}
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          color="secondary"
          disabled={props.loading == true}
        >
          No
        </Button>
        <Button
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          color="primary"
        >
          {props.loading == true ? <CircularProgress/>: 'Yes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DConfirm;