import log from 'loglevel';
import axios from 'axios';
import dashFetch from '../utils/dashFetch';

export default async function (menuId, scheduleObject, debug) {
	return new Promise( async(resolve, reject) => {

        // const scheduleObject = {
        //     startTime: allDayChecked ? '00:00' : startTime,
        //     endTime: allDayChecked ? '23:59' : endTime,
        //     fromDate: moment(fromDate, 'ddd MMM DD YYYY HH:mm:ss GMTZZ').format(),
        //     thruDate: moment(thruDate, 'ddd MMM DD YYYY HH:mm:ss GMTZZ').format(),
        //     daysPicked
        // }

		try {
			const url = `${global.dashServerUrl}/api/v1/restaurant/updateMenuSchedule`;
			const body={
				menuId: menuId,
				startTime: scheduleObject.startTime,
				endTime: scheduleObject.endTime,
                daysPicked: scheduleObject.daysPicked,
                fromDate: scheduleObject.fromDate,
                thruDate: scheduleObject.thruDate,
                scheduleBy: scheduleObject.scheduleBy
			}

            let response = await dashFetch(url, body);
			if (response && response.json.success) {
				resolve(response.json);
				log.info('success: ' + response.json.data)
			} else {
				resolve(response.json)
				log.info('FAILURE ')
			}
		} catch(err) {
			log.error(err)
		}

	})
}