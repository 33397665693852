import React, { useState, useEffect } from 'react';

import SetupCurbly from './setupCurbly';
import SetupTextPay from './setupTextPay';
import SetupDashNow from './setupDashNow';
import SetupAffiliate from './setupAffiliate';

export default (props) => {
	return (
        <>
            {global.isCurbly() &&
                <SetupCurbly/>
            }
			{global.isTextPay() && localStorage.lastSelectedRestaurantId &&
                <SetupTextPay/>
            }
			{global.isTextPay() && !localStorage.lastSelectedRestaurantId && localStorage.affiliateCode &&
                <SetupAffiliate />
            }
			{global.isDashNow() &&
                <SetupDashNow/>
            }
        </>
	);
};