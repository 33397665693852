import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import DashIcon from '../../assets/svgs/dashIcon';


export default (props) => {

	return (
		<Grid container direction="row" justify={props.left ?"flex-start" :"center"} alignItems="center">
			<Typography style={{ color: 'var(--light-gray)' }}>powered by&nbsp;</Typography>
			{!props.noLogo && <DashIcon width={17} height={17} viewBox={'0, 0, 34, 34'} /> }
			<Typography style={{ color: 'var(--dark-gray)' }}>{!props.noLogo && <span>&nbsp;</span>}dashnow{!props.noCopy && <span>&copy; 2020</span>}</Typography>
		</Grid>
	);
};
