import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import { Grid, Paper, Drawer, Zoom, MenuItem, Menu, Badge, IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Button, TextField, Checkbox, Box, CircularProgress, Tabs, Tab, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import PublishIcon from '@material-ui/icons/Publish'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import theme from '../theme'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import log from 'loglevel'
import history from '../history.js'
import _ from 'lodash';
import DateFnsUtils from '@date-io/date-fns';


import DashCard from './components/DashCard'
import CheckDetails from "./components/CheckDetails";

import fetchSiteCustomersApi from '../actions/fetchSiteCustomersApi';
import TodaySearch from './components/TodaySearch';
import accessUtils from '../utils/accessUtils';

import moment from 'moment'
import Sound from 'react-sound';
import AddEditOrder from './components/AddEditOrder';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    scrolling: {
        overflow: 'auto',
        height: 'auto'
    },
    checks: {
        paddingLeft: 5,
        paddingRight: 5,
        maxHeight: 'calc(100% - 3em)',
    },
    addRestaurantIcon: {
		float: 'left',
		// marginTop: 5,
		// marginRight: 5,
		// width: '20px',
		height: '20px'
	},
    paper: {
        // paddingRight:8,
        // paddingleft:8,
        // margin: 8,
        // minHeight: '100%',
        // maxHeight: '100%',
        width:'100%',
        height:'100%'
    },
    iconWrapper: {
        height: '2.7em', 
        display: 'flex',
        alignItems: 'center' 
    },
    fabButtonMobile: {
        position: 'absolute',
        zIndex: 1000,
        left: 0,
        right: 0,
        margin: '0 auto',
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1000,
        // left: 0,
        right: 40,
        margin: '0 auto',
    },
}))


export default (props) => {
    const maxRangeDays = 30
    const classes = useStyles();
    let [restaurant] = global.get('restaurant')
    let [checks, setChecks] = global.get('checks')
    let [filteredChecks, setFilteredChecks] = React.useState([])
    let [orderedChecks, setOrderedChecks] = React.useState([])
    let [paidChecks, setPaidChecks] = React.useState([])
    let [closedChecks, setClosedChecks] = React.useState([])
    let [drawerOpen, setDrawerOpen] = React.useState(false)
    let [showAddOrder, setShowAddOrder] = React.useState(global.hasPermission('check.create'))
    let [dialogContent, setDialogContent] = useState()
    let [filters, setFilters] = useState(["sent", "pending"])

    if (!localStorage.tabSelected) localStorage.tabSelected = 'ordered'

    let [selectedTab, setSelectedTab] = React.useState(localStorage.tabSelected)

    let smallScreen = useMediaQuery(theme().breakpoints.down('sm'));
    let [textToPayEnabled] = global.get('textToPayEnabled')
    let [imHereEnabled] = global.get('imHereEnabled')
    let [orderUpEnabled] = global.get('orderUpEnabled')
    let [newCustomerArrival] = global.get('newCustomerArrival', false)
    let [todaySearchFilter] = global.get('todaySearchFilter', '')
    let [checkTypeFilter] = global.get('checkTypeFilter')
    let [refreshCustomers] = global.get('refreshCustomers')
    let [allowPartialPayments] = global.get('allowPartialPayments')
    let [showNonTextpayChecks] = global.get('showNonTextpayChecks')
    let [showPendingChecks] = global.get('showPendingChecks')
    let signedIn = accessUtils.isLoggedIn()
    let [showAll, setShowAll] = global.get('showAll', false)
    
    let [showOrderedColumn, setShowOrderedColumn] = React.useState(true)
    let [showPaidColumn, setShowPaidColumn] = React.useState(true)
    let [showClosedColumn, setshowClosedColumn] = React.useState(true)
    let [expandedClosedColumn, setExpandedClosedColumn] = React.useState(false)
    let [isLoading, setIsLoading] = React.useState(false)
    let [canViewOthersOrders, setCanViewOthersOrders] = React.useState(global.hasPermission('check.view.all'))
    
    let [numberOfColumns, setNumberOfColumns] = React.useState(3)
    let [checkLookback, setCheckLookback] = global.get('checkLookback')
    let [startDate, setStartDate] = useState(moment().subtract(checkLookback ?? 0, 'day').startOf('day'))
    let [endDate, setEndDate] = useState(moment().endOf('day'))
    let [checkLookbackDateRange, setCheckLookbackDateRange] = global.get('checkLookbackDateRange')
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    let showSearch = true

    const closeContent = () => {
        setDialogContent(null)
    }

    const showCheckDetails = (check = null) => {
        if (check) {
            setDialogContent(
                <CheckDetails check={check} close={closeContent} isTextPay/>
            )
        } else {
            setDialogContent(
                <AddEditOrder check={check} close={closeContent} />
            )
        }
    }

    let showTabs = smallScreen
    useEffect(() => {
        showTabs = smallScreen
    }, [smallScreen]);
    
    useEffect(() => {
        if (checkLookback) localStorage.setItem('checkLookback', checkLookback)
    }, [checkLookback]);


    useEffect(() => {
        if (checks) {
            // REMOVE CHECKS OLDER THAN 24 HOURS FROM THE CHECKS ARRAY TO KEEP MEMORY USAGE DOWN
            let origChecksLength = checks.length
            checks = checks.filter(rec => !rec.deleted)

            checks = canViewOthersOrders ? checks : checks.filter(rec => rec.createdBy && rec.createdBy._id == localStorage.accountId)
            if (origChecksLength != checks.length) {
                setChecks(checks)
            }

            filteredChecks = checks

            filteredChecks = filteredChecks.filter(rec =>{
                
                if (rec.persisits) return false
                if (!filters || !filters.length) return true;

                let sentChecks = (filters.includes('sent') && (rec.textSendTime || rec.emailSendTime))
                let pendingChecks = (filters.includes('pending') && (rec.customer?.phone || rec.customer?.email) && !(rec.textSendTime || rec.emailSendTime))
                let nonTextPayChecks = (filters.includes('non-textpay') && (!rec.customer?.phone && !rec.customer?.email))
                
                return sentChecks || pendingChecks || nonTextPayChecks
            })

            if (todaySearchFilter) {
                setShowAll(true)
                setSelectedTab("")
                let todaySearchFilterUpperCase = todaySearchFilter.toUpperCase()
                todaySearchFilterUpperCase = todaySearchFilterUpperCase.replace(/-/g, "").replace(/\./g, "")
                filteredChecks = filteredChecks.filter(rec => {
                    return (
                        (rec.checkNumber && rec.checkNumber.toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                        || (rec.orderNumber && rec.orderNumber.toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                        || (rec.tableNumber && rec.tableNumber.toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                        || (rec.totals.total && rec.totals.total.toString().toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                        || (rec.pickupDetails && rec.pickupDetails.guestName && rec.pickupDetails.guestName.toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                        || (rec.customer && rec.customer.firstName && rec.customer.firstName.toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                        || (rec.customer && rec.customer.lastName && rec.customer.lastName.toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                        || (rec.pickupDetails && rec.pickupDetails.phoneNumber && rec.pickupDetails.phoneNumber.replace(/-/g, "").replace(/\./g, "").toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                    )
                })
            } else {
                // if (showTabs) {
                //     filteredChecks = filteredChecks.filter(rec => {
                //         return rec.status == selectedTab
                //     })
                // }
                if (selectedTab == "") {
                    setSelectedTab(localStorage.tabSelected) // sets it back to pre cleared value
                }
                setShowAll(false)
            }

            setupColumns()
            //REMOVE CHECKS OLDER THAN 12 HOURS FROM THE UI
            // filteredChecks = filteredChecks.filter(rec => moment(rec.createdAt) > moment().subtract(12, 'hours'))
            // filteredChecks = filteredChecks.map(rec => { 
            //     rec.payments = rec.payments ? rec.payments.filter(pmt => pmt.status == 'captured') : []
            //     return rec
            // })

            setFilteredChecks(filteredChecks)
            if (signedIn) {
                localStorage.setItem('checkTypeFilter', checkTypeFilter)
            }
        }
    }, [checks, checkTypeFilter, todaySearchFilter, selectedTab, showTabs, filters]);

    useEffect(() => {
        setOrderedChecks(filteredChecks.filter((rec) => { return rec.status == 'ordered' }))
        setPaidChecks(filteredChecks.filter((rec) => {  return rec.status == 'paid' }))
        setClosedChecks(filteredChecks.filter((rec) => { return rec.status == 'completed'}))
    }, [filteredChecks])


    const setupColumns = () => {
        setShowOrderedColumn(!showTabs || selectedTab == 'ordered')
        setShowPaidColumn(!showTabs || selectedTab == 'paid')
        setshowClosedColumn(!showTabs || selectedTab == 'completed')
    }

    const setDefaultCheckTypeFilter = () => {
        let checkTypeFilterTmp = 'dineIn'
        if (textToPayEnabled) {
            checkTypeFilterTmp = (localStorage.checkTypeFilter) ? localStorage.checkTypeFilter :'toGo'
        }
        global.set({ checkTypeFilter: checkTypeFilterTmp })
    }

    useEffect(() => {
        setDefaultCheckTypeFilter()
    }, []);

    useEffect(() => {
        if (selectedTab != "") {
            localStorage.setItem('tabSelected', selectedTab)
        }
    }, [selectedTab]);


    useEffect(() => {
        setDefaultCheckTypeFilter()
    }, [restaurant, textToPayEnabled])

    useEffect(() => {
        let numOfCols = 0
        if (showOrderedColumn) numOfCols++
        if (showPaidColumn) numOfCols++
        if (showClosedColumn) numOfCols++
        if (showAddOrder) numOfCols++
        setNumberOfColumns(numOfCols)
	}, [showClosedColumn, showOrderedColumn, showPaidColumn, showAddOrder])

    useEffect(()=>{
        if(restaurant && checks){
            setIsLoading(false)
        } else {
            setIsLoading(true)
        }
        console.log('in useEffect - restaurant', restaurant)
        console.log('in useEffect - checks', checks)
        console.log('is loading', isLoading)
    },[restaurant, checks])

    useEffect(() => {
        fetchCustomers()
    }, [restaurant])
    
    useEffect(() => {
        if (refreshCustomers) {
            global.set({refreshCustomers:false})
            fetchCustomers()
        }
    }, [refreshCustomers])

    const fetchCustomers = async () => {
        if (restaurant) {
            let customers = await fetchSiteCustomersApi(restaurant._id)
            global.set({customers})
        }
    }


	const setcheckTypeFilter = (newValue) => {
        if (newValue) global.set({ checkTypeFilter: newValue }); 
    }
    
    const handleLookbackDays = (e, value) => {
        setCheckLookback(value);
        setCheckLookbackDateRange(null);
        setStartDate(moment().subtract(value, 'days').startOf('day'))
        setEndDate(moment().endOf('day'))
        setAnchorEl(null)
    }
    
    if(isLoading){
        return <div style={{width:'100%', height:'100%'}}><CircularProgress style={{position: 'absolute', top:'50%', left:'50%', height:'100px', width:'100px', marginTop:'-50px', marginLeft:'-50px', opacity:'20%'}}/></div>
    }

	return (
        <Grid container style={{ height:"100%", padding: smallScreen ? 2 : 10}}>
            {restaurant && <>
                <Box display="flex" style={{ width:'100%', height: smallScreen ? '52px' : '60px', overflow: 'none', padding: 5}}>
                    {showSearch && checks &&
                        <Grid container spacing={1}>
                            <Grid item xs>
                                <TodaySearch/>
                            </Grid>
                            {(showNonTextpayChecks || showPendingChecks) &&
                                <Grid item xsstyle={{marginTop:0}}>
                                    <Paper>
                                        <ToggleButtonGroup size="small" value={filters} onChange={(e, value) => setFilters(value)} style={{height: 45}}>
                                            <ToggleButton key={0} value={'sent'} style={{height: '100%', paddingLeft: smallScreen ? 10 : 20, paddingRight: smallScreen ? 10 : 20, borderTop: 'none', borderBottom: 'none', borderLeft: 'none'}}>Sent</ToggleButton>
                                            <ToggleButton key={1} value={'pending'} style={{height: '100%', paddingLeft: smallScreen ? 10 : 20, paddingRight: smallScreen ? 10 : 20, borderTop: 'none', borderBottom: 'none'}}>Pending</ToggleButton>
                                            {showNonTextpayChecks && <ToggleButton key={1} value={'non-textpay'} style={{height: '100%', paddingLeft: smallScreen ? 10 : 20, paddingRight: smallScreen ? 10 : 20, borderTop: 'none', borderBottom: 'none'}}>Non-TextPay</ToggleButton>}
                                        </ToggleButtonGroup>
                                    </Paper>
                                </Grid>
                            }
                            <Grid item xs={4} sm={2} lg={1} xsstyle={{marginTop:0}}>
                                <Paper>
                                    <ToggleButton
                                        style={{
                                            backgroundColor: 'var(--white)',
                                            width: '100%',
                                            paddingLeft: 4,
                                            paddingRight: 0,
                                            height: '45px',
                                            border: 'none',
                                            color: `var(--${global.branding})`,
                                            boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);'
                                        }}
                                        onClick={(e) => { handleClick(e) }}>
                                        {checkLookbackDateRange ? `${checkLookbackDateRange.startDate.format('MM/DD')} - ${checkLookbackDateRange.endDate.format('MM/DD')}` : `${!smallScreen ? 'Last' : ''} ${checkLookback} Days`}
                                        <ArrowDropDownIcon style={{ color: "gray", float: 'right' }} />
                                    </ToggleButton>
                                </Paper>

                            </Grid>
                            <Menu
                                id="simple-Menu"
                                anchorEl={anchorEl}
                                keepMounted
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                MenuListProps={{ style: { padding: 0 } }}
                                open={!!anchorEl}
                                style={{ padding: 0, margin: 0 }}
                                onClose={handleClose}
                            >

                                <Paper style={{ backgroundColor: 'var(--background)', padding: 5, }}>
                                    <ToggleButtonGroup exclusive size="small" value={(endDate?.isSame(moment().endOf('day')) && endDate?.diff(startDate, 'days')) ?? checkLookback} onChange={handleLookbackDays} style={{ height: 44, marginBottom: 5, width: '100%' }}>
                                        <ToggleButton key={0} value={7} style={{ height: '100%', paddingLeft: 20, paddingRight: 20, borderTop: 'none', width: '100%', borderLeft: 'none' }}>{!smallScreen ? "Last" : ""} 7 Days</ToggleButton>
                                        <ToggleButton key={1} value={30} style={{ height: '100%', paddingLeft: 20, paddingRight: 20, borderTop: 'none', width: '100%', borderLeft: 'none' }}>{!smallScreen ? "Last" : ""} 30 days</ToggleButton>
                                        <ToggleButton key={2} value={90} style={{ height: '100%', paddingLeft: 20, paddingRight: 20, borderTop: 'none', width: '100%' }}>{!smallScreen ? "Last" : ""} 90 days</ToggleButton>
                                    </ToggleButtonGroup>
                                    <Paper style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                autoOk
                                                initialFocusedDate=""
                                                showTodayButton
                                                format="MM/dd/yyyy"
                                                id="date-picker-inline"
                                                label="Start Date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                style={{ width: 150, margin: '10px' }}
                                                value={startDate}
                                                onChange={date => {
                                                    setStartDate(moment(date).startOf('day'))
                                                    let maxEndDate = moment(date).add(maxRangeDays, 'days').endOf('day')
                                                    if (endDate.isAfter(maxEndDate)) setEndDate(maxEndDate)
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                maxDate={endDate?.clone()?.endOf('day') ?? moment().endOf('day')}
                                            />

                                            <KeyboardDatePicker
                                                autoOk
                                                initialFocusedDate=""
                                                showTodayButton
                                                format="MM/dd/yyyy"
                                                id="date-picker-inline"
                                                label="End Date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                style={{ width: 150, margin: '10px' }}
                                                value={endDate}
                                                onChange={date => {
                                                    setEndDate(moment(date).endOf('day'))
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                minDate={startDate?.clone()?.endOf('day')}
                                                maxDate={startDate?.isBefore(moment().startOf('day').subtract(maxRangeDays, 'days')) ? startDate.clone().endOf('day').add(maxRangeDays, 'days') : moment().endOf('day')}
                                            />
                                        </MuiPickersUtilsProvider>
                                        <div style={{ display: 'flex', alignItems: 'center', padding: 5 }}>
                                            <Button variant="contained" color="primary" onClick={() => { setCheckLookbackDateRange({ startDate, endDate }); setAnchorEl(null) }}>Apply</Button>
                                        </div>
                                    </Paper>

                                </Paper>

                            </Menu>
                        </Grid>
                        
                    }
                </Box>

                <Grid container style={{ width:'100%', maxHeight:'100%', height: 'calc(100% - 50px)', overflow: 'none', }} >

    
                    {(smallScreen || showAll) && <>
                        <Grid item xs={12} container direction='row' style={{ width: '100%', height: '100%', minHeight: '100%', margin: 0, paddingBottom: 10, overflow: 'hidden' }}>
                            <Grid item xs={12} style={{ height: '100%', width: '100%', padding: 5}} >
                                <Paper className={classes.paper} style={{overflow: 'hidden', height: '100%', width: '100%'}}>

                                    {showTabs && !todaySearchFilter &&
                                        <Tabs style={{width: '100%', borderBottom: 'solid 1px #DDD'}}
                                            // style={{ maxHeight: '3.5em', minHeight: '2.7em'}}
                                            value={selectedTab}
                                            variant="fullWidth"
                                            indicatorColor="primary"
                                            TabIndicatorProps={{
                                                style: {
                                                    backgroundColor: selectedTab == 'ordered' ? 'var(--dash-orange)' : selectedTab == 'paid' ? `var(--${global.branding})` : 'var(--dash-gray)',
                                                    height: 3,
                                                }
                                            }}
                                            onChange={(e, value) =>{
                                                setSelectedTab(value)
                                            }}
                                        >
                                            <Tab label="Open" value="ordered" />
                                            <Tab label={<Badge classes={{badge:classes.badge}} color="primary" badgeContent={paidChecks.length > 0 ? paidChecks.length : null}>Paid</Badge>} value="paid" />
                                            <Tab label="Closed" value="completed" />
                                        </Tabs>
                                    }

                                    <Grid container className={`${classes.scrolling} ${classes.checks}`}>
                                        {filteredChecks && filteredChecks.filter((rec) => { return selectedTab == "" || rec.status == selectedTab }).map((data, idx) => 
                                            <Grid item xs={12} md={6} lg={4} style={{padding: 0, paddingLeft: 8, paddingRight: 8}} onClick={() => showCheckDetails(data)}>
                                                <DashCard showBalanceDue={restaurant.backOffice || allowPartialPayments} data={data} hideTopBorder={smallScreen && idx == 0} />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </>}

                    {!showAll && !smallScreen && <>
                        <Grid item lg={12} sm={12} container direction='row' style={{ width: '100%', height: '100%', minHeight: '0', margin: 0, paddingBottom: 5 }}>
                            {showAddOrder && 
                                <Grid item sm={!expandedClosedColumn} style={{ height: '100%', width: expandedClosedColumn ? '65px' : '100%', padding: 5, paddingRight: 10, paddingBottom: 10, paddingTop: showTabs ? 0 : 5, cursor: expandedClosedColumn ? 'pointer' : 'inherit'}} onClick={() => { if (expandedClosedColumn) setExpandedClosedColumn(!expandedClosedColumn) }}>
                                    <Paper className={classes.paper} > {/* style={{borderTop: 'solid 4px var(--dark-gray)'}} */}
                                        <Grid item lg={12} container alignItems="center" className={classes.columnHeader}>
                                            {!expandedClosedColumn && 
                                                <Grid item style={{width: 50}}></Grid>
                                            }
                                            {!expandedClosedColumn && 
                                                <Grid item xs container justify="center" alignItems="center" onClick={() => { if (expandedClosedColumn) setExpandedClosedColumn(!expandedClosedColumn)}}>
                                                    <Typography style={{ paddingTop: 3, fontSize: '1.4em', color:  theme().palette.text.primary }} display="inline">Request Payment</Typography>
                                                </Grid>
                                            }
                                            <Grid item style={{width: 50}} container alignItems="center">
                                                {expandedClosedColumn && 
                                                    <IconButton onClick={() => {if (expandedClosedColumn) setExpandedClosedColumn(!expandedClosedColumn)}}>
                                                            <ChevronRightIcon/>
                                                    </IconButton>
                                                }
                                            </Grid>
                                            
                                            
                                        </Grid>
                                        
                                        {expandedClosedColumn && 
                                            <Grid xs container style={{height: 'calc(100% - 42px)'}} justify="center" alignItems="center">
                                                <Typography style={{ paddingTop: 3, fontSize: '1.4em', color:  theme().palette.text.primary, transform: 'rotate(-90deg)', height: 35, width: '100%', whiteSpace: 'nowrap' }}>Request Payment</Typography>
                                            </Grid>
                                        }

                                        {!expandedClosedColumn && 
                                            <div style={{display:'flex', justifyContent:'flex-start', height: 'calc(100% - 32px)'}}>
                                                <AddEditOrder/>
                                            </div>
                                        }
                                    </Paper>
                                </Grid>
                            }
                            {showOrderedColumn &&
                                <Grid item sm style={{ height: '100%', width: '100%', maxWidth:`calc((100% - 65px) / ${numberOfColumns - 1})`, padding: 5, paddingRight: 10, paddingBottom: 10, paddingTop: showTabs ? 0 : 5}} >
                                    <Paper className={classes.paper} style={{borderTop: 'solid 4px var(--dash-orange)'}}>
                                        <Grid item lg={12} className={classes.iconWrapper} justify="center">
                                            <Typography style={{ paddingTop: 3, fontSize: '1.4em', color:  theme().palette.text.primary }} display="inline" align="center">Open</Typography>
                                        </Grid>
                                        <Grid container className={`${classes.scrolling} ${classes.checks}`}>
                                            {orderedChecks && orderedChecks.map(data => 
                                                <Grid item lg={12} style={{ padding: 0, paddingLeft: 8, paddingRight: 8, width:'100%' }} onClick={() => showCheckDetails(data)}>
                                                    <DashCard showBalanceDue={restaurant.backOffice || allowPartialPayments} data={data}/>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Paper>
                                </Grid>
                            }{showPaidColumn &&
                                <Grid item sm style={{ width: '100%', maxWidth:`calc((100% - 65px) / ${numberOfColumns - 1})`, maxHeight: '100%', padding: 5, paddingBottom: 10, paddingTop: showTabs ? 0 : 5 }} >
                                    <Paper className={classes.paper} style={{borderTop: `solid 4px var(--${global.branding})`}}>
                                        <Grid item lg={12} className={classes.iconWrapper} justify="center" >
                                            <Typography style={{ paddingTop: 3, fontSize: '1.4em', color:  theme().palette.text.primary }} display="inline" align="center">Paid</Typography>
                                        </Grid>
                                        <Grid container className={`${classes.scrolling} ${classes.checks}`} >
                                            {paidChecks && paidChecks.map(data => 
                                                <Grid item lg={12} style={{ padding: 0, paddingLeft: 8, paddingRight: 8, width:'100%' }} onClick={() => showCheckDetails(data)}>
                                                    <DashCard showBalanceDue={restaurant.backOffice || allowPartialPayments} data={data}/>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Paper>
                                </Grid>
                            }
                            {showClosedColumn &&
                                <Grid item sm={expandedClosedColumn} style={{ width: !expandedClosedColumn ? '65px' : '100%', maxWidth:`calc((100% - 65px) / ${numberOfColumns - 1})`, maxHeight: '100%', padding: 5, paddingLeft: 10, paddingBottom: 10, paddingTop: showTabs ? 0 : 5 }} >
                                    <Paper className={classes.paper} elevation={expandedClosedColumn ? 1 : 3} style={{borderTop: 'solid 4px var(--dash-gray)', cursor: !expandedClosedColumn ? 'pointer' : 'inherit'}} onClick={() => { if (!expandedClosedColumn) setExpandedClosedColumn(!expandedClosedColumn) }}>
                                        <Grid item lg={12} container alignItems="center" className={classes.columnHeader}>
                                            {expandedClosedColumn && 
                                                <Grid item style={{width: 50}}></Grid>
                                            }
                                            {expandedClosedColumn && 
                                                <Grid item xs container justify="center" alignItems="center" style={{cursor: 'pointer'}} onClick={() => setExpandedClosedColumn(!expandedClosedColumn)}>
                                                    <Typography style={{ paddingTop: 3, fontSize: '1.4em', color:  theme().palette.text.primary }} display="inline">Closed</Typography>
                                                </Grid>
                                            }
                                            <Grid item style={{width: 50}} container alignItems="center">
                                                <IconButton onClick={() => setExpandedClosedColumn(!expandedClosedColumn)}>
                                                    {expandedClosedColumn && 
                                                        <ChevronRightIcon/>
                                                    }
                                                    {!expandedClosedColumn && 
                                                        <ChevronLeftIcon/>
                                                    }
                                                </IconButton>
                                            </Grid>
                                            
                                            
                                        </Grid>
                                        
                                        {!expandedClosedColumn && 
                                            <Grid xs container style={{height: 'calc(100% - 48px)'}} justify="center" alignItems="center">
                                                <Typography style={{ paddingTop: 3, fontSize: '1.4em', color:  theme().palette.text.primary, transform: 'rotate(-90deg)', height: 35, width: '100%', whiteSpace: 'nowrap' }}>Closed</Typography>
                                            </Grid>
                                        }

                                        {expandedClosedColumn && 
                                            <Grid container className={`${classes.scrolling} ${classes.checks}`} >
                                                {closedChecks && closedChecks.map((data, idx) =>
                                                    <Grid item lg={12} style={{ padding: 0, paddingLeft: 8, paddingRight: 8, width:'100%', overflow: 'hidden' }} onClick={() => showCheckDetails(data)}>
                                                        <DashCard showBalanceDue={restaurant.backOffice} data={data} hideTopBorder={idx > 0} slideDirection='left' />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        }

                                    </Paper>
                                </Grid>
                            }
                        </Grid>
                    </>}
                    <Grid item lg={12} >
                        {dialogContent}
                    </Grid>
                </Grid>
                {smallScreen && !dialogContent && global.hasPermission('check.create') &&
                    <Fab color="primary" aria-label="add" className={smallScreen? classes.fabButtonMobile :classes.fabButton} style={{bottom: smallScreen ? 30 : 75}} onClick={() => {showCheckDetails(null)}}>
                        <AddIcon />
                    </Fab>
                }
            </>
            }
        </Grid>
	);
};