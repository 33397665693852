import React, { useState } from 'react';
import SelectMui from '@material-ui/core/Select';
import { Grid, Paper, Button, Input, Typography, Divider, CircularProgress, FormControl, InputLabel } from '@material-ui/core';
import generateQrCodePageApi from '../../actions/generateQrCodePageApi';
import { makeStyles } from '@material-ui/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import log from 'loglevel'

const useStyles = makeStyles((theme) => ({
    root: {
    },
    scrolling: {
        overflow: 'auto',
        height: 'auto'
    },
    paper: {
        width:'100%',
        height: '100%',
        padding: '15px',
        textAlign: 'center'
    }
}))

export default function (props) {
    const classes = useStyles();

	const [restaurant] = global.get('restaurant')
	const [tableNumbers, setTableNumbers] = useState('1-40')
	const [qrCodesPerTable, setQrCodesPerTable] = useState(2)
    const [pdfData, setPdfData] = useState('')
    const [isLoading, setLoading] = useState(false);

   
    
	const generate = async () => {
        setLoading(true);
        setPdfData('')
		if (restaurant && restaurant._id) {
			let newTables = []
			tableNumbers.split(",").map((tbl) => {
				if (tbl.indexOf('-') >= 0) {
					let startNum = parseInt(tbl.slice(0, tbl.indexOf('-')))
					let endNum = parseInt(tbl.slice(tbl.indexOf('-')+1))
					let tableRange = range(endNum + 1 - startNum, startNum)
					newTables = newTables.concat(tableRange)
				} else {
					newTables.push(tbl)
				}
			})
			let newTablesStr = newTables.toString()
			if (qrCodesPerTable > 5 || (newTables.length > 100)) {
				alert('You can only have up to 5 QR codes per table and 100 tables generated per PDF. If you need more, please generate multiple PDFs.')
				return
			}
			let resp = await generateQrCodePageApi(restaurant._id, newTablesStr, qrCodesPerTable, false)
			if (resp) {
				let url = window.URL.createObjectURL(new Blob([resp], {
					type: "application/pdf"
                }))
                setPdfData(url)
            }
            setLoading(false);
		}
	}
	function range(size, startAt = 0) {
		return [...Array(size).keys()].map(i => i + startAt);
    }

	return (
        <Grid container style={{padding: 15, width:'100%', margin: 'auto', textAlign: 'center'}} justify="center" alignItems="flex-start" spacing={2}>
            <Grid container item style={{borderRadius:'4px', border:'solid 1px #e2e8f0'}} justify="center">
                <Grid item sm={12} md={4} alignItems="center">
                    <FormControl style={{ width: '98%', }} InputLabelProps={{ shrink: true }}>
                    <InputLabel>Table Numbers (Ex: '1,2,3,4' or '1-4')</InputLabel>
                    <Input
                        style={{ width: 200 }}
                        value={tableNumbers}
                        onChange={(e) => setTableNumbers(e.target.value)}
                    />
                    </FormControl>
                </Grid>

                <Grid item sm={12} md={4}>
                    <FormControl style={{ width: '98%', }} InputLabelProps={{ shrink: true }}>
                        <InputLabel># of QR Codes per table </InputLabel>
                        <Input
                            style={{ width: 200 }}
                            value={qrCodesPerTable}
                            onChange={(e) => setQrCodesPerTable(e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <br/>

                <Grid item sm={12} style={{textAlign: 'center', marginTop:'10px', display:'flex', justifyContent: 'center'}}>
                    <Button variant="contained" color="primary" onClick={generate} style={{margin:'auto'}}>Generate</Button>
                </Grid>
            </Grid>
            
            {(isLoading || pdfData) &&
                <>    
                    {isLoading && 
                        <Grid container 
                            style={{height: '100%', width: '100%',marginTop:16}}
                            justify="center"
                            alignItems="center"
                            direction="column"><Grid item style={{marginTop:'20%'}}><CircularProgress/></Grid>
                        </Grid>
                    }
                    
                    {pdfData && 
                        <Grid item xs={12}>
                                <div style={{display: 'inline-block', width: '80%', marginTop:'15%'}}>
                                    <embed width="90%" style={{ minHeight: 400, paddingBottom: -10, border: '1px solid gray'}} title="PDF Generator" src={pdfData} view="Fit" frameBorder="0" allowFullScreen></embed>
                                    <a href={pdfData} style={{textDecoration: 'none'}} download="Printable QR codes"><Button variant="contained" color="primary" style={{marginTop:'16px'}}>Download</Button></a>
                                </div>
                        </Grid>
                    }
                </>
                
            }
        </Grid>  
	)
}
