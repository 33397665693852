import log from 'loglevel';
import dashFetch from '../utils/dashFetch';


export default async function (restaurantId, amount, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('verifyTestTransactionAmount api');
		const url = `${global.dashServerUrl}/api/v1/restaurant/verifyTestTransactionAmount`;

		const body = {
            restaurantId,
            amount,
		};

		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info('>>> verifyTestTransactionAmount JSON');
			log.info(json)
			resolve(json)
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}