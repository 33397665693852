import React from 'react';

class TableIcon extends React.Component {
    render() {
        return (
            <svg width={this.props.width ? this.props.width : "118px"} height={this.props.height ? this.props.height : "80px"} viewBox="0 0 118 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <ellipse id="Oval" stroke="#332F2F" fill="#39393A" cx="59" cy="15" rx="58" ry="14"></ellipse>
                    <path d="M59,29 L59,79.5" id="Line" stroke="#332F2F" strokeLinecap="square"></path>
                    <path d="M38.5,79.5 L79.5,79.5" id="Line-2" stroke="#332F2F" strokeLinecap="square"></path>
                </g>
            </svg>
        );
    }
}

export default TableIcon;