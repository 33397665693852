import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import { Box, Tooltip } from '@material-ui/core';
import accessUtils from '../utils/accessUtils';
import PoweredByDashnow from './components/PoweredByDashnow'
import browser from 'browser-detect';

const useStyles = makeStyles((theme) => ({
}));

export default function Footer(props) {
    const classes = useStyles();
    const signedIn = accessUtils.isLoggedIn()
    const currentVersion = process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : global.appVersion

    function getBrowserVersion() {
		const result = browser();
		return (<div>{JSON.stringify(result)}</div>)
	}
   
    return (
        <div style={{borderTop: 'solid 1px var(--divider)'}}>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', padding: 6}}>
                <div style={{paddingLeft: 3}}>
                    {signedIn && <Typography>{localStorage.accountFirstName && localStorage.accountLastName? `${localStorage.accountFirstName} ${localStorage.accountLastName}`: ""} </Typography>}
                </div>
                {/* <div item xs={6} align="center">
                    <PoweredByDashnow/>
                </div> */}
                <div style={{paddingRight: 3}}>
                    {currentVersion && 
                        <Tooltip title={getBrowserVersion()} ><Typography>v{currentVersion}</Typography></Tooltip>
                    } 
                </div>
            </div>
        </div>
    );
}