import React from 'react'
import PropTypes from 'prop-types';
import styled from "styled-components";
const { TableHead, TableRow, TableCell, TableSortLabel } = require("@material-ui/core");

export default function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        if (onRequestSort) {
            onRequestSort(event, property);
        }
    };

    let StyledTableCell = styled(({ color, ...otherProps }) => (
		<TableCell {...otherProps} />
	  ))`
		background-color: white;
	`;

    return (
        <TableHead>
            <TableRow>
                {headCells && headCells.filter(cell => cell.show != false).map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        align={headCell.align}
                        style={headCell.width? {width:headCell.width}:{}}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span style={{
                                    border: 0,
                                    clip: 'rect(0 0 0 0)',
                                    height: 1,
                                    margin: -1,
                                    overflow: 'hidden',
                                    padding: 0,
                                    position: 'absolute',
                                    top: 20,
                                    width: 1,
                                }}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};