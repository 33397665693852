import log from 'loglevel';


export default async function (string, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('publish api');
		const url = `https://webapp.commissionbuddies.com/api/encrypt`;


		let options = {
			method: 'POST',
			body: string,
		}
		fetch(url, options, debug).then((res) => {
            let response = res.text()
            log.info(response)
			resolve(response)
		}).catch((err) => { 
			log.error(err)
			reject(err.message);
		})
	})
}