import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function CustomizedInputBase(props) {
  const classes = useStyles();
  let searchFilterVar = props.var
  const [searchFilter, setSearchFilter] = global.get(searchFilterVar, '')

  return (
    <Paper className={classes.root}>
      <IconButton className={classes.iconButton} aria-label="menu">
        <SearchIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder={props.searchText ? props.searchText : "Search"}
        inputProps={{ 'aria-label': props.searchText ? props.searchText : "Search"}}
        value={searchFilter ? searchFilter : ''}
        onChange={e => setSearchFilter(e.target.value) }
      />
      {searchFilter && 
        <>
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton color="primary" className={classes.iconButton} aria-label="directions">
              <ClearIcon onClick={() => { setSearchFilter('') }} />
          </IconButton>
        </>
      }
    </Paper>
  );
}
