import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import log from 'loglevel';
import _ from 'lodash';
import {
	Grid,
	Stepper,
	Step,
	StepButton,
	Button,
	CircularProgress,
	Container,
	Box,
	Paper,
	Typography,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {ReactComponent as DashNowLogo} from '../../assets/svgs/dash-now-logo-white.svg';
import theme from '../../theme'
import SelectPackagePage from './pages/SelectPackagePage'
import RestaurantLocations from './pages/RestaurantLocations'
import BillingInfo from './pages/BillingInfo'
import RestaurantInfo from './pages/RestaurantInfo'
import Summary from './pages/Summary'
import LoyaltySetup from './pages/LoyaltySetup';
import RequestComplete from '../requestComplete';
import dashFetch from '../../utils/dashFetch'
import publishApi from '../../actions/publishApi';
import goToPageApi from '../../actions/goToPageApi';
import { urlParse } from '../../utils/urlParser'
import findRestaurantEnrollmentRequestApi from '../../actions/findRestaurantEnrollmentRequestApi'
import createRestaurantEnrollmentRequestApi from '../../actions/createRestaurantEnrollmentRequestApi'
import history from '../../history'
import getDropdownListApi from '../../actions/getDropdownListApi';

const headerHeight = 80
const activeButtonBarHeight = 60

const styles = (theme) => ({
	root: {
		width: '100%',
		height: '100%',
		backgroundColor: theme().palette.background.default,
		overflow: 'hidden',
	},
	stepper: {
		width: '100%',
		backgroundColor: theme().palette.background.default,
		'&$selected': {
			backgroundColor: '#5220A8'
		}
	},
	lowerPage: {
		height: '100%',
		width: '100%',
		// marginTop: stepperHeight + 50,
	},
	header: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		height: `${headerHeight}px`,
		zIndex: 11111,
	},
	content: {
		position: 'absolute',
		top: `${headerHeight}px`,
		bottom: `${activeButtonBarHeight}px`,
		left: 0,
		right: 0,
		overflow: 'auto',
		paddingTop: 30,
		paddingBottom: 20,
	},
	actionButtonBar: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
		height: `${activeButtonBarHeight}px`,
		zIndex: 11111,
		paddingTop: 20,
	},
});

function camelize(str) {
	return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
		return index == 0 ? word.toLowerCase() : word.toUpperCase();
	}).replace(/\s+/g, '');
}

const Dash = (props) => {
	const { classes } = props;
	const [steps, setSteps] = useState([])
	const [restaurantClone, setRestaurantClone ] = useState([])
	const [posTypes, setPosTypes] = useState([]);
	const [activeStep, setActiveStep] = useState(0);
	const [stepName, setStepName] = useState('Restaurant Info');
	const [loadingAsync, setLoadingAsync] = useState(true)
	const [showStepper, setShowStepper] = useState(false)
	const [completed, setCompleted] = useState(new Set());
	
	let [request, setRequest] = global.get('request', {});
	
	const requestType = "RestaurantEnrollmentRequest"

	// const [request, setRequest] = useState({})

	const [showButtons, setShowButtons] = useState(true)
	const [showBackButton, setShowBackButton] = useState(true)
	const [showNextButton, setShowNextButton] = useState(true)

	const smartSetRequest = (e, fieldName = null, value = null) => {
		const val = (e && e.target && e.target.value) ? e.target.value : value;
		const requestClone = { ...request };
		console.log(val)
		fieldName = fieldName ? fieldName : e.target.getAttribute('dfieldname');
		// console.log(request.data.restaurants[0].gateways[0].spreedlyGatewayType)
		console.log(fieldName)
		_.set(requestClone, fieldName, val);
		setRequest(requestClone);
		console.log(request)
		// addObjectToSave(request);
	}


	const handleStep = (step) => {
		setActiveStep(step);
		setStepName(steps[step])
			
		let showBackBtn = true
		let showNextBtn = (step != steps.length)
		setShowBackButton(showBackBtn)
		setShowNextButton(showNextBtn)
		if (showBackBtn || showNextBtn) {
			setShowButtons(true)
		}
	};
	
	let backStep = (step) => {
		if (step > 0) {
			handleStep(--step);
		} else {
			setShowStepper(false)
		}
	};

	let nextStep = (step) => {
		console.log("forward step")

		let onValid = async () => {
			if (await goToPageApi(request, step, requestType)) {
				handleStep(++step);
				if (step > steps.length -1) {
					submit()
				}
			}
		}
		global.set({onValid: onValid, validateNow: true})
	};

	const submit = async () => {
		log.info('submitting request')
		setLoadingAsync(true)
		await publishApi(request, requestType)
		// nextStep(activeStep)
		setLoadingAsync(false)
	}


	function isStepComplete(step) {
		return completed.has(step);
	}


	useEffect(() => {

		let params = urlParse(window.location.href).params
		
		async function fetchRequest() {
			let data
			if (params.req) {
				data = await findRestaurantEnrollmentRequestApi(params.req)
			} else {
				data = await createRestaurantEnrollmentRequestApi(params.req, params.ag, params.sr, params.packageId)
			}
			let thereq = data.request
			let steps = data.request.pages
			let restaurantTemplate = thereq.data.restaurants[0]
			restaurantTemplate.billingInfo.encryptedCard.dirty = false
			delete restaurantTemplate._id
			let rc = JSON.parse(JSON.stringify(restaurantTemplate))
			// alert(JSON.stringify(rc))
			setRestaurantClone(rc)
			setRequest(thereq)
			setSteps(steps)
			// if ((!params.req || params.req == 'new') && thereq._id) {
			// 	history.replace(`/restaurantEnrollment/?req=${thereq._id}`)
			// }
		}
		fetchRequest()

		async function fetchdata() {
			let data = await getDropdownListApi();
			if (data) {
				setPosTypes(data.posTypes.sort((a, b) => (a.posType.toLowerCase() > b.posType.toLowerCase()) ? 1 : -1));
			}
			setLoadingAsync(false)
		}
		fetchdata()
		global.set({ headerTitle: 'RESTAURANT ONBOARDING' })
	}, [])

	const sm = useMediaQuery(theme().breakpoints.down('sm'));
	const xs = useMediaQuery(theme().breakpoints.down('xs'));

	return (
		<div className={classes.root}>
			<Box className={classes.header} bgcolor="#8B6BD9">
				<Container className={classes.headerContainer} maxWidth={sm ? 'sm': xs ? 'xs' : 'lg'} style={{paddingTop: 16, paddingLeft: 50, paddingRight: 50}}>
					<Grid container justify="space-between">
						<Grid item xs={9} sm={4} md={4} lg={4}>
							<DashNowLogo width="188px" height="50px" onClick={(e) => { document.location = 'https://dashnow.com'}} />
						</Grid>
						<Grid item xs={3} sm={8} md={8} lg={8}> 
							<Typography variant="h5" align="right" style={{color: '#FFF', marginTop: 10}}>
								Restaurant Enrollment
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Container className={classes.content} maxWidth={sm ? 'sm': xs ? 'xs' : 'lg'}>
				<Grid container spacing={0}>
					{!showStepper &&
						<SelectPackagePage 
							request={request} 
							setRequest={smartSetRequest} 
							setShowStepper={setShowStepper} 
						/>
					}
					{showStepper && 
					<>
						{activeStep != steps.length &&
							<Stepper className={classes.stepper} alternativeLabel nonLinear activeStep={activeStep}>
								{steps.map((label, index) => {
									const stepProps = {};
									const buttonProps = {};
									return (
										<Step key={label} {...stepProps}>
										<StepButton
											completed={isStepComplete(index)}
											{...buttonProps}
										>
											{label}
										</StepButton>
										</Step>
									);
								})}
							</Stepper>
						}
						<Grid className={classes.lowerPage}>
							<Container>
								{loadingAsync && <Grid container justify="center"><CircularProgress /></Grid>}
								{!loadingAsync && stepName == "Restaurant Info"  && 
									(<RestaurantInfo 
										record={request.data}
										restaurantClone={restaurantClone}
										dropDownOptions={{posTypes}} 
									/>
								)}
								{!loadingAsync && stepName == "Locations"  && 
									(<RestaurantLocations 
										record={request.data}
										dropDownOptions={{posTypes}} 
									/>
								)}
								{!loadingAsync && stepName == "Billing Info"  && 
									(<BillingInfo 
										record={request.data}
										dropDownOptions={{}} 
									/>
								)}
								{!loadingAsync && stepName == "Review"  && 
									(<Summary 
										record={request.data}
										dropDownOptions={{}} 
									/>
								)}
								{!loadingAsync && activeStep == (steps.length) && 
									(<RequestComplete 
										request={request}
									/>
								)}
							</Container>
						</Grid>
						</>
					}
				</Grid>
			</Container>
			{ showStepper && showButtons && 
				<Box className={classes.actionButtonBar} bgcolor="#F8F8F8" style={{borderTop: 'solid 1px #EEE', boxShadow: '0 0 10px 5px rgba(231,231,231,0.50)'}}>
					<Container maxWidth={sm ? 'sm': xs ? 'xs' : ''}>
						<Grid container justify="space-between">
							<Grid item>
								{showBackButton && <Button variant={'contained'} color="primary" onClick={() => backStep(activeStep)} style={{marginLeft: 1}}>Back</Button>}
							</Grid>
							<Grid item>
								{showNextButton && <Button variant={'contained'} color="primary" onClick={() => nextStep(activeStep)} type='submit'>Next</Button>}
							</Grid>
						</Grid>
					</Container>
				</Box>
			}
		</div>
	);
};

Dash.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withStyles(styles, { withTheme: true })(Dash);
