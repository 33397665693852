import log from 'loglevel';
import dashFetchUpload from '../utils/dashFetchUpload';


export default async function (file, debug) {
	return new Promise( async(resolve, reject) => {
		log.info('uploadBulkRestaurantAdd api');
		const url= `${global.dashServerUrl}/api/v1/restaurant/bulkAddRestaurants`; 
		const formData = new FormData();
		formData.append('file', file);
		console.log(formData)
		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}
		let response = await dashFetchUpload(url, formData)
		if (response.data.success) {
			log.info('bulkAddRestaurants  response.data.success exists');
			// log.info("response.data: " + JSON.stringify(response.data));
			log.info("insertedRowCount: " +response.data.data.insertedRowCount);
			log.info("skippedRowCount: " +response.data.data.skippedRowCount);
			log.info("rowsNotInserted: " + JSON.stringify(response.data.data.rowsNotInserted));
			log.info("rowsInserted: " + JSON.stringify(response.data.data.rowsInserted));
			resolve(true);
		} else {
			log.info('bulkAddRestaurants   FAILURE')
			resolve(false)
		}
	})
}