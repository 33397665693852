import React from 'react';

class EmptyStar extends React.Component {
    render() {
        return (
            <svg width="19px" height="18px" viewBox="0 0 19 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                    <g id="Home" transform="translate(-1248.000000, -726.000000)" stroke="#BABABA" strokeWidth="0.85">
                        <g id="Recent-Activity" transform="translate(1072.000000, 202.000000)">
                            <g id="table" transform="translate(12.000000, 42.000000)">
                                <g id="data1">
                                    <g id="recent1" transform="translate(0.000000, 380.000000)">
                                        <g id="Group-5" transform="translate(110.550000, 103.000000)">
                                            <path d="M63.9773335,0.632897061 L66.2647575,5.94615928 L70.7162423,5.94615928 C71.1766931,5.92873108 71.5985595,6.20239577 71.7707795,6.63023737 C71.9429995,7.05807896 71.8285386,7.5480938 71.4846622,7.85512468 L67.6202681,11.2995946 L69.7605479,16.2304686 C69.9531448,16.6927676 69.8298441,17.2265333 69.4540582,17.5572545 C69.0782724,17.8879757 68.5336737,17.9420142 68.1003447,17.6915785 L62.9280018,14.7723344 L57.7556589,17.6900906 C57.3221267,17.9440362 56.774899,17.8917201 56.3972173,17.5602207 C56.0195357,17.2287214 55.8961793,16.6924513 56.0909968,16.2289807 L58.2357355,11.3025704 L54.3713414,7.85810046 C54.023681,7.55262594 53.9062632,7.0608702 54.0783504,6.6310291 C54.2504377,6.20118799 54.6746128,5.92672257 55.1367887,5.94615928 L59.595705,5.94615928 L61.8861017,0.634384953 C62.089441,0.244718316 62.4921058,0.000312759581 62.9312677,2.99943186e-07 C63.3704296,-0.000312159695 63.7734411,0.243520165 63.9773335,0.632897061 Z" id="Shape"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}

export default EmptyStar;