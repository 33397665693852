import log from 'loglevel';

export default class accessUtils {

	static login = () => {
		localStorage.signedIn = true
		global.set({signedIn: true})
	}

	static logout = (redirect = true) => {
        log.info("LOGGING OUT")
        localStorage.removeItem("accountId"); 
		localStorage.removeItem("token"); 
		localStorage.removeItem("tokenTimeout");  
		localStorage.removeItem("accountFirstName"); 
		localStorage.removeItem("accountLastName"); 
		localStorage.removeItem("refreshToken"); 
		localStorage.removeItem("tosAcceptance"); 
		localStorage.removeItem("refreshTimeout"); 
		localStorage.removeItem("permissions"); 
		localStorage.removeItem('signedIn')
		localStorage.removeItem('restaurant')
		localStorage.removeItem('checkTypeFilter')
		global.set({restaurants: [], restaurant: null, signedIn: false})
		if (redirect) {
			document.location = '/'
		}
	}

	static isLoggedIn = () => {
		let [loggedIn] = global.get("signedIn")
		return (!!loggedIn || localStorage.signedIn)
	}

}
