import React, { useState, useEffect } from 'react';

import TodayDashNow from './todayDashNow';
import TodayTextPay from './todayTextPay';
import TodayCurbly from './todayCurbly';
import history from '../history'
import accessUtils from '../utils/accessUtils';

export default (props) => {

    let [restaurant] = global.get('restaurant')
    let [restaurantId] = global.get('restaurantId')
    let loggedIn = accessUtils.isLoggedIn()

    useEffect(() => {
        if (!loggedIn && !restaurantId) {
            history.push('/login')
        }
	}, [])

    useEffect(() => {
        if (restaurant && restaurant.driveThruOnly) {
            history.push('/payments')
        }
	}, [restaurant])

	return (
        <>
            {global.isDashNow() &&
                <TodayDashNow/>
            }
            {global.isTextPay() &&
                <TodayTextPay/>
            }
            {global.isCurbly() &&
                <TodayCurbly/>
            }
        </>
	);
};