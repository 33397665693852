import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import DFlag from '../components/dash/DFlag';
import log from 'loglevel'

export default (props) => {

    const [results, setResults] = useState({})

    const endpoints = {
        "dashnow.com": "Dashboard for business owners",
        "textpay.app": "Main site for TextPay customers",
        "dnow.app": "Main site for DashNow customers",
        "dash-files-s3.s3-us-west-1.amazonaws.com": "Cloud storage for images, file assets",
		"prod-pos-server.dashnow.com": "QuickBooks connector services",
        "api2.heartlandportico.com": "Heartland payment processing API",
        "core.spreedly.com": "Spreedly payment processing API",
        "js.verygoodvault.com": "Secure storage service",
        "token-cert.dcap.com": "DataCap payment processing API",
        "www.google-analytics.com": "Google analytic service",
        "pay.google.com": "Google Pay",
        "play.google.com": "Google Play service (used by Google Pay)",
        "fonts.gstatic.com": "Google Fonts",
        "fonts.googleapis.com": "Google Fonts",
        "this.should-not.work": "This shouldn't work"
    }

    const handleResponse = (key, ok) => {
        if (ok || results[key] !== true) {
            results[key] = ok
            setResults({ ...results })
        }
    }

    const pingEndpoints = () => {
        Object.keys(endpoints).forEach((url) => {
            fetch("https://" + url, {
                method: 'HEAD',
                mode: 'no-cors'
            }).then(_ => {
                handleResponse(url, true)
            }).catch((err) => {
                log.info(err)
                handleResponse(url, false)
            })
        })
    }

    useEffect(() => {
        pingEndpoints()
    }, [])

    return (
        <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <table>
                    <thead>
                        <tr>
                            <th colSpan={3} style={{ padding: "32px 16px" }}><span style={{ fontSize: 18 }}>DashNow/TextPay service status</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(endpoints).map(url => <>
                            <tr>
                                <td style={{ paddingRight: 16 }}>{url}</td>
                                <td style={{ paddingRight: 16 }}>{endpoints[url]}</td>
                                <td style={{ textAlign: 'right' }}>
                                    <div style={{ padding: 8, width: 40, height: 40 }}>
                                        {results[url] === undefined ?
                                            <CircularProgress size={"100%"}></CircularProgress> :
                                            <DFlag checked={!!results[url]} style={{ height: "100%", width: "100%" }}></DFlag>
                                        }
                                    </div>
                                </td>
                            </tr>
                        </>)}
                    </tbody>
                </table>
            </div>
        </div>
    );
};