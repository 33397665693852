import React, { useEffect, useState } from 'react';
import { Grid, TextField, Button, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/HighlightOff';
import Typography from '@material-ui/core/Typography';
import DRecord from '../../components/dash/DRecord';
import DInput from '../../components/dash/DInput';
import log from 'loglevel'


export default (props) => {
    const header = props.header
    const helperText = props.helperText
    const addButtonLabel = props.addButtonLabel
    const path = props.path
    const [model] = global.get(props.globalModelName)

    let values = model.getVal(path, [])

    const removeItem = (x) => {
        let newModel = { ...model }
        values.splice(x, 1)
        newModel.setVal(path, values)
        global.set({ [props.globalModelName]: newModel })
    }

    const addItem = (x) => {
        let newModel = { ...model }
        values.push('')
        newModel.setVal(path, values)
        global.set({ [props.globalModelName]: newModel })
    }

    return <div style={{ border: 'solid 1px #e2e8f0', borderRadius: 5, width: '100%', padding: 20, paddingTop: 0, marginTop: 20, marginBottom: 20 }}>
        <div style={{ top: -12, backgroundColor: 'var(--white)', paddingLeft: 10, paddingRight: 10, width: 'auto', display: 'inline-block', position: 'relative' }}>
            <b>{header}</b>
            {/* <Typography variant="subtitle1">
            Pick Up Locations
        </Typography> */}
        </div>
        <Grid container xs={12} alignItems="center">
            <Typography variant="body" gutterBottom style={{ color: '#777', marginBottom: 10 }}>
                {helperText}
            </Typography>
            <DRecord record={values}>
                {values && values.length > 0 && values.map((val, x) =>
                    <Grid item xs={12} container>
                        <Grid item style={{ width: 10, paddingTop: 6, marginRight: 12, color: '#777' }}>
                            {x + 1}.
                        </Grid>
                        <Grid item xs>
                            <DInput name={`${x}`} required marginBottom={10} fullWidth />
                        </Grid>
                        <Grid item style={{ width: 16, marginLeft: 6 }}>
                            <IconButton aria-label="delete" style={{ padding: 5 }}>
                                {/* {x > 0 && */}
                                <DeleteIcon onClick={() => { removeItem(x) }} />
                                {/* } */}
                            </IconButton>
                        </Grid>
                    </Grid>
                )}
            </DRecord>

            <Grid item xs={12}>
                <Button
                    variant={'contained'}
                    color="secondary"
                    onClick={() => addItem()}
                    style={{ marginTop: 10, marginLeft: 20 }}>
                    {addButtonLabel}
                </Button>
            </Grid>
        </Grid>
    </div>
}