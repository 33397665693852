import React, { useEffect } from 'react';
import {TextField} from '@material-ui/core';

export default function DTextField(props) {
	
	let [dform2, setDform2] = global.get('dform2');
	let [request, setRequest] = global.get('request');

	useEffect(() => {
		if (props.required && dform2 && !dform2.required[props.dfieldname]) {
			dform2.required[props.dfieldname] = true
			setDform2(dform2)
		} else if (!props.required && dform2 && dform2.required[props.dfieldname]) {
			delete dform2.required[props.dfieldname]
			setDform2(dform2)
		}
	}, []);

	const onChange2 = (e) => {
		if (!validateFormat(e)) {
			return false;
		}
		let fieldError = dform2 && dform2.errors[props.dfieldname]
		if (fieldError && e.target.value) {
			delete dform2.errors[props.dfieldname]
			setDform2(dform2)
		}
		props.onChange(e);
	}

	const validateFormat = (e) => {
		if (props.format && (props.format.toUpperCase() == "NUMBER" || props.format.toUpperCase() == "NUMERIC" || props.format.toUpperCase() == "NUMBERSONLY")) {
			const re = /^[-+]?[0-9]+$/;
			if (e.target.value === '' || re.test(e.target.value)) {
				return true;
			} else {
				return false;
			}
		}
		return true;
    }

	return (
		<TextField
			inputProps={{
				record: props.record,
				dfieldname: props.dfieldname,
				index: props.index,
				min: props.min,
				max: props.max,
				maxLength: props.maxLength,
				...props.inputProps
			}}
			onChange={(e) => onChange2(e)}
			value={props.value}
			className={props.className}
			label={props.label}
			multiline={props.multiline}
			rowsMax={props.rowsMax}
			disabled={props.disabled}
			style={{
				marginBottom: '15px',
				// marginTop: '15px',
				width: '100%', 
				...props.style
			}}
			id={props.dfieldname}
			error={dform2 ? dform2.errors[props.dfieldname] : ""}
			required={props.required}
			helperText={
				(dform2 && dform2.errors[props.dfieldname]) ? ( typeof(dform2.errors[props.dfieldname]) === 'string' || dform2.errors[props.dfieldname] instanceof String) ? dform2.errors[props.dfieldname] : 'This field is required' : props.helperText
			}
			type={props.type}
			variant={props.variant}
			InputLabelProps={{shrink:true}}
		/>
	);
}
