import log from 'loglevel';
import dashFetch from '../utils/dashFetch';
import history from '../history.js'
const uuidv4 = require('uuid/v4');

export default async function (email, password, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('login api');
		const url = `${global.dashServerUrl}/api/v1/account/login`;
		let deviceId = localStorage.deviceId;
		
		const body = {
			password,
			email: email ? email.toLowerCase() : "",
			deviceId,
			fromDashboard: true,
		};

		let options = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		}

		fetch(url, options, debug).then((res) => {
			if (res.ok) {
				res.json().then((json) => {
					if (json.success) {
						let data = json.data
						if (debug) log.info('success');
						log.info('success');
						log.info(data);
						/* SAVE ACCOUNT DATA LOCALLY */
						if (data.token) {
							localStorage.token = data.token
							localStorage.accountId = data.accountId
							localStorage.refreshToken = data.refreshToken
							localStorage.tokenTimeout = data.tokenTimeout
							localStorage.refreshTimeout = data.refreshTimeout
							localStorage.tosAcceptance = data.tosAcceptance
							localStorage.permissions = JSON.stringify(data.permissions)
							localStorage.accountFirstName = data.firstName
							localStorage.accountLastName = data.lastName
							if (data.affiliate && data.affiliateCode) {
								localStorage.affiliateCode = data.affiliateCode
							} else {
								delete localStorage.affiliateCode
							}
						}
			
						resolve(true);
					} else {
						log.error(json.errors)
						resolve(false);
					}
				}).catch((err) => {
					reject(err.message);
				})
			} else {
				reject(res);
			}
		}).catch((err) => { 
			log.error(err)
			reject(err.message);
		 })
	})
}