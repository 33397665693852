import React, { useState, useEffect } from 'react';
import {TextField} from '@material-ui/core';
import {ToggleButtonGroup} from '@material-ui/lab'
import log from 'loglevel';
const uuidv4 = require('uuid/v4');

export default function (props) {
    let [dform] = global.get('dform');
	let [record, setRecord] = useState()
	// log.info("RENDERING DTOGGLE: " +  props.name)

	useEffect(() => {
		// log.info("USE EFFECT DTOGGLE: " +  props.name) 
	}, [])

	if (!record && dform) {
		// log.info("IN DINPUT BEFORE PEEK")
		record = dform.lastRecord; 
		// log.info("last record: ")
		// log.info(JSON.stringify(record))
		if (props.required && record) {
			record.dform.requireds[props.name] = props.required
		}
		setRecord(record)
	}

	const handleChange = (value, e) => {
		if (props.beforeChange) {
			props.beforeChange(record, props.name, value);
		}
		if (record) {
			let fieldError = record.dform.errors[props.name]
			if (fieldError && value) {
				delete record.dform.errors[props.name]
			}
			record.dform.setVal(setRecord, props.name, value, props.rerender)
		}

		if (props.onChange) {
			props.onChange(record, props.name, value); 
        }
        if (props.afterChange) {
			props.afterChange(record, props.name, value); 
        }
	}

	const validateFormat = (value) => {
		if (props.format && (props.format.toUpperCase() == "NUMBER" || props.format.toUpperCase() == "NUMERIC" || props.format.toUpperCase() == "NUMBERSONLY")) {
			const re = /^[-+]?[0-9]+$/;
			if (value === '' || re.test(value)) {
				return true;
			} else {
				return false;
			}
		}
		return true;
    }

    let props2 = {
        ...props, 
        id: record ? record.dform.drecordId + "-" + props.name : uuidv4(),
        recordId: record ? record.dform.drecordId : '',
        onChange: (e, tabIdx) => handleChange(tabIdx, e),
        helperText: record && record.dform.errors ? (record.dform.errors[props.name]) ? ( typeof(record.dform.errors[props.name]) === 'string' || record.dform.errors[props.name] instanceof String) ? record.dform.errors[props.name] : 'This field is required' : props.helperText : '',
        error: record && record.dform.errors ? record.dform.errors[props.name] : false,
        value: props.value ? props.value : record ? record[props.name] : '',
        style: {
            marginBottom: 20,
            width: '100%', 
            ...props.style
        }
    }

	return (
		<ToggleButtonGroup {...props2}>
            {props.children}
        </ToggleButtonGroup>
	)
}
