import React, { useState, useEffect } from 'react';
import { Grid, Dialog, DialogActions, Button, Typography, Checkbox, Table, TableHead, TableRow, TableCell, TableBody, DialogContent, Paper, Box, AppBar, Toolbar, Tabs, Tab, IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import flagCheckAsCompletedApi from '../../actions/flagCheckAsCompletedApi';
import log from 'loglevel'
import moment from 'moment'
import theme from '../../theme'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import alertCustomerForOrderUpApi from '../../actions/alertCustomerForOrderUpApi';
import undoFlagCheckAsCompletedApi from '../../actions/undoFlagCheckAsCompletedApi';
import reprintPaymentReceiptApi from '../../actions/reprintPaymentReceiptApi';
import RefundRequestWindow from './RefundRequestWindow';
import accessUtils from '../../utils/accessUtils';
import CloseIcon from '@material-ui/icons/Close';
import OrderDetailTable from './OrderDetailTable';
import PickupDetails from './PickupDetails';
import { makeStyles } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import getFeedbackDataApi from '../../actions/getFeedbackDataApi';
import cancelCheckApi from '../../actions/cancelCheckApi';
import _ from 'lodash';
import PaidStamp from '../../assets/svgs/PaidStamp';

const useStyles = makeStyles((theme) => ({
    root: {
        color: 'var(--dark-gray)'
    },
    tableHeader: {
        fontSize: '12px'
    },
    sectionHeader: {
        fontSize: '16px',
        padding: '6px 8px 8px 8px'
    },
    typography: {
        fontSize: '14px'
    },
    pickupTableCell: {
        borderBottom: '0px solid white'
    },
    overlay: {
        position:'absolute',
        height: '100%',
        width: '100%',
        zIndex: 10,
        // alignSelf: 'center',
        // marginLeft: '15%',
        backgroundColor: 'rgba(0,0,0,0)',
    },
}));

export default function (props) {
    const classes = useStyles()
    // alert(JSON.stringify(props.permissions))
    const [check, setCheck] = useState(props.check)
    const [orderUpEnabled] = global.get('orderUpEnabled')
    const [restaurant] = global.get('restaurant')
    const [showPaymentDetail, setShowPaymentDetail] = useState(false)
    const [windowContent, setOverlayWindowContent] = useState()
    let signedIn = accessUtils.isLoggedIn()
    let [fsa, setFSA] = useState()
    let [comment, setComment] = useState()

    const isCallAheadCheck = (check.pickupDetails && check.pickupDetails.phoneNumber)
    const hasCustomerPhone = (check.customer && check.customer.phone)
    const isComplete = (check.status == 'completed')

    let tmpPayment
    let tipTotal = 0
    let loyaltyTotal = 0
    let taxesTotal = 0
    let dashTotal = 0
    let lastPaymentTime
    if (check.payments) {
        check.payments.filter(p => (p.fromDash && p.successful)).forEach(payment => {
            loyaltyTotal += payment.loyaltyRewardsAmount
            taxesTotal += payment.tax
            tipTotal += payment.tipAmount
            dashTotal += payment.paymentAmount
        })
        tmpPayment = check.payments.sort((a, b) => moment(a.createdAt) - moment(b.createdAt))[0]
        if (tmpPayment) {
            lastPaymentTime = tmpPayment.createdAt
        }
        loyaltyTotal = Number(loyaltyTotal / 100).toFixed(2)
        taxesTotal = Number(taxesTotal / 100).toFixed(2)
        tipTotal = Number(tipTotal / 100).toFixed(2)
        dashTotal = Number(dashTotal / 100).toFixed(2)
    }

    let paidAt = lastPaymentTime ? moment(lastPaymentTime).fromNow() : 'N/A'
    let paidTime = lastPaymentTime ? moment(lastPaymentTime).format('M/DD @ h:mma') : 'N/A'

    const smallScreen = useMediaQuery(theme().breakpoints.down('sm'));
    const xsSmallScreen = useMediaQuery(theme().breakpoints.down('xs'));
    console.log("smallScreen: " + smallScreen)

    const sendPickedUp = async (check) => {
        let data = await flagCheckAsCompletedApi(check._id)
        if (data) {
            props.close()
        }
    }

    const sendOrderUp = async (check) => {
        let data = await alertCustomerForOrderUpApi(check._id)
        if (data) {
            props.close()
        }
    }

    const sendUndoComplete = async (check) => {
        let data = await undoFlagCheckAsCompletedApi(check._id)
        if (data) {
            props.close()
        }
    }
    
    const cancelCheck = async (check) => {
        let data = await cancelCheckApi(check._id)
        if (data) {
            props.close()
        }
    }

    const reprintPaymentReceipt = async (check) => {
        let data = await reprintPaymentReceiptApi(check)
        if (data) {
            props.close()
        }
    }

    const showRefundWindow = async (check, payment) => {
        setOverlayWindowContent(
            <RefundRequestWindow check={check} close={closeOverlayWindow} payment={payment} />
        );
    }

    const closeOverlayWindow = () => {
        setOverlayWindowContent(null);
    };


    useEffect(() => {
    }, [])

    const fetchData = async () => {
        if (restaurant && feedbackEnabled && !check.isToGo) {
            const allFeedback = await getFeedbackDataApi(restaurant._id)
            const checkFeedback = allFeedback.filter(data => data.check.id.includes(check.id))
            if (checkFeedback.length) {
                setComment(checkFeedback[0].comment)
                setFSA(checkFeedback[0].ratings)
            }
        }
    }

    useEffect(() => {
        fetchData()
    }, [restaurant]);

    const feedbackEnabled = global.f(restaurant, 'config.features.feedback.enabled', false) && signedIn && (!paidTime || (paidTime && dashTotal > 0))

    let color = 'gray'
    if (check.status == 'ordered') {
        color = '#FFAE1B'
    } else if (check.status != 'completed' && check.totals && check.totals.paidDashNow > 0) {
        color = 'var(--dash-purple)'
    }
    if (check.cancelled) {
        color = '#D25757'
    }

    console.log('CHECK', check)
    console.log('dashTotal', dashTotal)
    return (
        <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={true}
            onClose={props.close}
            PaperProps={xsSmallScreen ? { style: { margin: 0, width: '96%', height: '85%', borderRadius: '0px' } } : { style: { minWidth: 500, borderRadius: '0px', height:'75%'} }}
        >
            <MuiDialogTitle
                id="customized-dialog-title"
                style={{ backgroundColor: color, width: '100%', color: 'white', margin: 0, borderRadius: '0px' }}>
                <div>
                    <Typography variant="h6" style={{ display: 'inline-block' }}>{check.checkNumberDisplay ? check.checkNumberDisplay : check.checkNumber}</Typography>
                </div>

                <IconButton style={{ fontSize: '1.2em', marginBottom: 4, position: 'absolute', top: 8, right: 8 }} onClick={props.close}>
                    <CloseIcon style={{ color: 'white' }} />
                </IconButton>

            </MuiDialogTitle>

            <DialogContent style={{ backgroundColor: '#F5F5F5', overflowX: 'hidden', overflowY: 'auto', height: '100%', width: '100%', padding: 0 }}>
                <Grid container direction="row" spacing={0} justify="space-around">

                    {/* TOP STATUS BAR */}
                    <Grid container direction="row" item spacing={1} justify="center" alignItems="center" xs={12}
                        style={{ backgroundColor: 'white', minHeight: '100px', width: '100%', padding: '14px 10px 10px 10px', borderBottom: '1px solid rgb(220,220,220' }}
                    >

                        <Grid item xs={6} sm={6}>
                            Customer: <span style={{ color: 'var(--dash-purple)' }}>{check.pickupDetails.guestName ? check.pickupDetails.guestName : 'N/A'}</span>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            Opened: <span style={{ color: 'var(--dash-purple)' }}>{check.createdAt ? moment(check.createdAt).format('M/DD @ h:mma') : 'N/A'}</span>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            Paid: <span style={{ color: 'var(--dash-purple)' }}>{paidTime}</span>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            Arrived: <span style={{ color: 'var(--dash-purple)' }}>{global.f(check, 'pickupDetails.arrivedAt', false) ? moment(check.pickupDetails.arrivedAt).format('M/DD @ h:mma') : 'N/A'}</span>
                        </Grid>
                    </Grid>


                    <Grid container direction="row" justify="space-between" alignContent="center" alignItems="stretch" style={{ width: '100%', height: '100%', padding: '8px' }}>

                        {/* ORDER DETAIL */}
                        {dashTotal > 0 &&
                            <Grid style={{position:'fixed', width: xsSmallScreen? "96%" : 600, alignSelf:'center'}}>
                                <Grid container style={{height:'100%', marginTop:12}} align="center" justify="center" className={classes.overlay}>
                                    <PaidStamp width={90} height={66} opacity={0.5} fill={'var(--dash-purple)'}/>
                                </Grid>
                            </Grid>
                        }
                        <Grid item xs={12} sm={12} md={12} style={{ height: '100%', padding: '8px' }}>
                            <Paper style={{ padding: 0 }}>
                                <Typography className={classes.sectionHeader}>
                                    Order Detail
                                </Typography>
                                <OrderDetailTable data={check} small={true}/>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} style={{ height: '100%', padding: '8px' }}>
                            <Paper style={{ padding: 0 }}>
                                <PickupDetails data={check}/>
                            </Paper>
                        </Grid>

                    </Grid>
                </Grid>

            </DialogContent>

            
            <DialogActions style={{ borderTop: '1px solid var(--divider)', padding: '10px 8px', minHeight: '60px' }}>
                <Grid container height="100%">
                    <Grid style={{ padding: 5 }} item xs={4} sm={6}>
                    </Grid>
                    <Grid style={{ padding: 5, textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }} item xs={8} sm={6}>
                        {!isComplete && orderUpEnabled && hasCustomerPhone &&
                            <Button style={{ height: '36px', marginRight: 12, fontSize: smallScreen ? '12px' : '14px' }} color="primary" variant="contained" onClick={() => { sendOrderUp(check); }}>
                                {!smallScreen ?
                                    <span>Order Ready ({check.pickupDetails.orderUpAt ? "Re-notify":"Notify"} Customer)</span>
                                    :
                                    <span>Order Ready {check.pickupDetails.orderUpAt ? "(Again)":""}</span>
                                }
                            </Button>
                        }
                        {!isComplete && (isCallAheadCheck || !restaurant.backOffice) &&
							<Button style={{ height: '36px' }} color="primary" variant="contained" onClick={() => { sendPickedUp(check); }}>
                                Picked Up
							</Button>
						}
						{isComplete && (isCallAheadCheck || !restaurant.backOffice) && !check.cancelled &&
							<Button style={{ height: '36px' }} color="primary" variant="contained" onClick={() => { sendUndoComplete(check); }}>
								Undo Picked Up
							</Button>
						}
                    </Grid>
                </Grid>
            </DialogActions>
            {windowContent}
        </Dialog>
    )

}
