import log from 'loglevel';
import dashFetch from '../utils/dashFetch';


export default async function (restaurantId, debug) {
	return new Promise((resolve, reject) => {
        if (debug) log.info('verifyGoLive api');
        

		const url = `${global.dashServerUrl}/api/v1/restaurant/verifyGoLive`;

		const body = {
			restaurantId,
		};

		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info("verifyGoLiveApi json")
			log.info(json)
			resolve(json)
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}