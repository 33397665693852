import log from 'loglevel';
import dashFetch from '../utils/dashFetch';


export default async function (record, debug) {
	return new Promise((resolve, reject) => {
		const url = `${global.dashServerUrl}/api/v1/support/emailSupport`;

		const body = {
            ...record
        }
        console.log(body && body.email)
		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info(json)
			if (json.success) {
                global.showNotification('Message has been sent', "success")
				resolve(true);
			} else {
				global.showNotification('An error occurred, please try again', "error")
				resolve(false)
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}