import log from 'loglevel';
import getToken from '../actions/getToken';
import { NotificationManager } from 'react-notifications';
import accessUtils from './accessUtils';
import axios from 'axios';


export default async function(url, body, debug = true, allowWithoutToken = false) {
	return new Promise(async (resolve, reject) => {
		let token = localStorage.token;
		let res = {};
		try {
			if (debug) log.info(`FETCHING FROM: ${url}`);
			if (debug) log.info('BODY');
			if (debug) log.info(body);
			const refreshToken = localStorage.refreshToken;
			const currentTime = Math.floor((new Date()).getTime() / 1000);
			const tokenTimeout = localStorage.tokenTimeout;
			const refreshTimeout = localStorage.refreshTimeout;
			if (refreshToken) {
				if (tokenTimeout < currentTime) {
					if (refreshTimeout < currentTime) {
						if (!token) {
							accessUtils.logout()
							let msg = "Please sign in again"
							res = { json: { success: false, errors: [msg] } }
							global.showNotification(msg, "error")
							reject()
						} else {
							token = await getToken(debug);
						}
					} else {
						token = await getToken(debug);
					}
				} else {
					token = await getToken(debug);
				}
			}
			if (!token && !allowWithoutToken) {
				let msg = "Please sign in"
				accessUtils.logout()
				reject(msg)
			}
			if (token || allowWithoutToken) {
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'x-access-token': token
                    }
                }
                axios.post(url, body, config).then((response) => {
                    if (debug) log.info("JSON RESPONSE");
                    if (debug) log.info(response)
                    resolve(response)
                }, (error) => {
                    log.error("POST FAILED")
                    let data = error.response ? error.response.data : ''
                    let status = error.response ? error.response.status : '';
                    if (data) {
                        if (debug) log.info("JSON RESPONSE");
                        if (debug) log.info(data)
                        if (debug) log.info("JSON STATUS");
                        if (debug) log.info(status)
                        if (data.userMessage) {
                            global.showNotification(data.userMessage, "error")
                        }
                        if (status === 401) {
                            accessUtils.logout()
                        }
                        reject(data.userMessage);
                    }
                    log.info(status)
                })
			}
		} catch (err) {
			if (debug) log.error(err);
			reject(err);
		}
	})
}
