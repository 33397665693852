import React, { useState, useEffect, useRef, useCallback } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import DTableHead from '../components/dash/DTableHead'
import PeopleIcon from '@material-ui/icons/People';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import moment from 'moment'
import { Slide, Grid, Typography, TextField, Paper, Button, TableContainer, Table, TableCell, TableRow, IconButton, ButtonGroup, FormLabel, FormControl, Box, Menu, MenuItem, Fade, LinearProgress, CircularProgress } from '@material-ui/core';
import styled from "styled-components";
import checkInGuestApi from '../actions/checkInGuestApi';
import pageGuestApi from '../actions/pageGuestApi';
import seatGuestApi from '../actions/seatGuestApi';
import cancelCheckInApi from '../actions/cancelCheckInApi';
import undoSeatGuestApi from '../actions/undoSeatGuestApi';
import fetchRestaurantCheckIns from '../actions/fetchRestaurantCheckIns';
import CheckInSearch from './components/CheckInSearch';
import OptionSwiper from './components/OptionSwiper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Icon from '@mdi/react'
import { mdiAccountClockOutline, mdiTableChair } from '@mdi/js';
import RestoreIcon from '@material-ui/icons/Restore';
import DInput from '../components/dash/DInput';
import DConfirm from '../components/dash/DConfirm';
import DFlag from '../components/dash/DFlag';
import DForm2 from '../components/dash/DForm2';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import log from 'loglevel'
import smartFilters from './utils/SmartFilters';
import { motion } from "framer-motion";

let smartFilter = smartFilters.init('checkIn', {filters: {'status': 'waiting'}, sorts: {'estimated': 'desc'}})

const useStyles = makeStyles(theme => ({
	root: {
		height: 50, 
		borderRadius: 0, 
		width:'100%', 
		'&$selected': {
			backgroundColor: 'inherit !important',
			borderColor: 'var(--purple) !important',
			color: 'var(--purple) !important',
			fontWeight: '600 !important',
			fontSize: '1.2em !important',
			webkitBoxShadow: "0px 0px 5px #AAA",
			mozBoxShadow: "0px 0px 5px #AAA",
			boxShadow: "0px 0px 5px #AAA",
			borderBottomLeftRadius: 5,
			borderBottomRightRadius: 5,
			borderTopLeftRadius: 5,
			borderTopRightRadius: 5,
		},
	},
	selected: {},

	root2: {
		height: 50, 
		width:'100%', 
		'&$selected2': {
			backgroundColor: 'inherit !important',
			borderColor: 'var(--purple) !important',
			color: 'var(--purple) !important',
			fontWeight: '600 !important',
			webkitBoxShadow: "0px 0px 5px #AAA",
			mozBoxShadow: "0px 0px 5px #AAA",
			boxShadow: "0px 0px 5px #AAA",
		},
	},
	selected2: {},

	statusBar: {
		border: 'solid 1px #FFF', 
		borderRadius: 14, 
		backgroundColor: 'var(--white)', 
		width: 100, 
		height: 24, 
		boxShadow: '0 0 6px rgba(0, 0, 0, .2)',
		'& > *': {
			borderRadius: 14, 
			height: '100%', 
			fontSize: '.9em', 
			fontWeight: 600, 
			color: 'var(--white)', 
			textAlign: 'right', 
			paddingTop: 2, 
			paddingLeft: 5, 
			paddingRight: 5,
			minWidth: 30,
		}
	},
	green: {
		backgroundColor: '#3EB637',
	},
	orange: {
		backgroundColor: '#FF9D00',
	},
	red: {
		backgroundColor: '#E21E1E',
	},
}));


let styles = {
	checkIn: {
		height: '100%',
	},
	red: {
		border: 'solid 2px red',
	},
	blue: {
		border: 'solid 2px blue',
	},
	border: {
		border: 'solid 1px #0000001f'
	},
	paper: {
        width:'100%',
        height:'100%'
	},
	toggleButton: {
		height: 50, 
		borderRadius: 0, 
		width:'100%', 
		borderTopColor: 'var(--white)',
	},
}

export default (props) => {
	const classes = useStyles();
	let [restaurant] = global.get('restaurant');
	let [smartFilterUpdated] = global.get('smartFilterUpdated');
	var [restaurantCheckIns, setRestaurantCheckIns] = global.get('restaurantCheckIns');
	var [addUpdatedRestaurantCheckIns] = global.get('addUpdatedRestaurantCheckIns');
	var [records, setRecords] = useState([])
	let [loading, setLoading] = React.useState(true);
	
	let [menu, setMenu] = useState(null)
	let [loadingAsync, setLoadingAsync] = useState(false)
	let [confirmOpen, setConfirmOpen] = useState(false)
	let [selectedCheckIn, setSelectedCheckIn] = useState({})
	let [record, setRecord] = useState({})

	let maxGuestWaitTimeGroups = [4, 8, 100]
	let [currentWaitTimes, setCurrentWaitTimes] = useState([0, 0, 0, 0])

	if (!localStorage.lastPartySize) {
		localStorage.lastPartySize = 2
	}

	moment.updateLocale('en', {
		relativeTime : {
			past: function(input) {
			  return input === 'just now'
				? input
				: input + ' ago'
			},
			s  : 'just now',
			future: "in %s",
			ss : '%d seconds',
			m:  "a min",
			mm: "%d min",
			h:  "an hour",
			hh: "%d hours",
			d:  "a day",
			dd: "%d days",
			M:  "a month",
			MM: "%d months",
			y:  "a year",
			yy: "%d years"
		}
	});

	const isMobile = useMediaQuery('(max-width:600px)')
	const isSmallTablet = useMediaQuery('(max-width:1024px)')

	const [anchorEl, setAnchorEl] = React.useState(null)

	const showPartySizeMenu = (event) => {
		setMenu('partySize')
    	setAnchorEl(event.currentTarget);
	  }
	  
	const showCheckOptionsMenu = (event, selected) => {
		setSelectedCheckIn(selected)
		setMenu('checkOptions')
    	setAnchorEl(event.currentTarget);
  	}
	
	  const showHistoryMenu = (event, selected) => {
		setSelectedCheckIn(selected)
		setMenu('history')
    	setAnchorEl(event.currentTarget);
  	}

  	const handleClose = () => {
		setSelectedCheckIn(null)
		setMenu(null)
    	setAnchorEl(null)
  	}


	let StyledCell = styled(({ color, ...otherProps }) => (
		<TableCell {...otherProps} />
	))`
		height: 70px;
	`;

	// const toggleSort = (id) => {
    //     const isAsc = orderBy === id && order === 'asc';
    //     setOrder(isAsc ? 'desc' : 'asc');
    //     setOrderBy(id);
    // };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
    
    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
	}
	
	const getUnitOfTimeSince = (checkInTime, unit = 'minutes') => {
		 return moment().diff(moment(checkInTime), unit) 
	}


	const filterRecords = async () => { 
		var data = restaurantCheckIns
		if (data && smartFilter) {

			//CALCULATE CURRENT WAIT TIMES
			let currentWaitTimes = [0, 0, 0, 0]
			if (data.length > 0) {
				data.map(rec => {
					rec.stopAt = rec.tableReadySmsSentAt ? moment(rec.tableReadySmsSentAt) : moment()
					rec.elapsed = Math.floor(moment.duration(rec.stopAt.diff(rec.checkedInAt)).asMinutes())
					rec.remaining = rec.waitTimeInMinutes - rec.elapsed
					rec.elapsed = rec.elapsed < 1 ? 1 : rec.elapsed
					rec.percent = Math.round(rec.elapsed / rec.waitTimeInMinutes * 100)
					rec.percent = rec.percent < 100 ? rec.percent : 100
					rec.color = rec.percent < 75 ? 'green' : rec.percent < 99 ? 'orange' : 'red'
					let idx = maxGuestWaitTimeGroups.findIndex(g => {
						return rec.numberOfGuests <= g
					})
					if (idx > -1) {
						let currentWaitTime = currentWaitTimes[idx]
						if (rec.remaining > 0 && rec.remaining > currentWaitTime && rec.status == 'checkedIn') {
							currentWaitTimes[idx] = Math.ceil(rec.remaining/5)*5
						}
					}
				})
			}
			setCurrentWaitTimes(currentWaitTimes)

			let filterSearch = smartFilter.getVal('filters.search')
			let filterStatus = smartFilter.getVal('filters.status')

			if (filterSearch) {
				let checkInSearchFilterUpperCase = filterSearch.toUpperCase()
				checkInSearchFilterUpperCase = checkInSearchFilterUpperCase.replace(/-/g, "").replace(/\./g, "")
				data = data.filter(rec => {
					return (
						(rec.name && rec.name.toUpperCase().indexOf(checkInSearchFilterUpperCase) > -1)
						|| (rec.phone && rec.phone.replace(/-/g, "").replace(/\./g, "").toUpperCase().indexOf(checkInSearchFilterUpperCase) > -1)
						|| (rec.numberOfGuests && rec.numberOfGuests.toString().toUpperCase().indexOf(checkInSearchFilterUpperCase) > -1)
					)
				})
			} else if (filterStatus) {
				if (filterStatus == 'waiting') {
					data = data.filter(rec => rec.status != 'seated')
				} else if (filterStatus == 'seated') {
					data = data.filter(rec => rec.status == 'seated')
				}
			}

			let sortByKeys = Object.keys(smartFilter.sorts)
			if (sortByKeys.length > 0) {
				data = stableSort(data, getComparator(smartFilter.sorts[sortByKeys[0]], sortByKeys[0]))
			}
		}
		setRecords(data)
	}

	useEffect(() => {
		getRestaurantCheckIns()
	}, [restaurant])

	const getRestaurantCheckIns = async () => {
		if (restaurant) {
			log.info(' Getting checkin list')
			restaurantCheckIns = await fetchRestaurantCheckIns(restaurant._id)
			global.set({restaurantCheckIns})
		}
	}

	useEffect(() => {
		if (restaurantCheckIns) {
			let newRestaurantCheckIns = restaurantCheckIns.filter(rec => rec.status != 'cancelled' && moment(rec.createdAt) > moment().subtract(6, 'hours'))
			if (restaurantCheckIns.length != newRestaurantCheckIns.length) {
				setRestaurantCheckIns(newRestaurantCheckIns)
				return
			}
			filterRecords()
			setLoading(false)
		}
	}, [restaurantCheckIns])

	useEffect(() => {
		filterRecords()
	}, [smartFilterUpdated])




	//LISTEN FOR UPDATES RECEIVED FROM THE RESTAURANT WEB SOCKET
	useEffect(() => {
		if (addUpdatedRestaurantCheckIns) {
			let newRestaurantCheckIns = [...restaurantCheckIns]
			for (let incomingCheckIn of addUpdatedRestaurantCheckIns) {
				let index = _.findIndex(newRestaurantCheckIns, { _id: incomingCheckIn._id })
				if (index > -1) {
					console.log("UPDATING CHECK: " + incomingCheckIn._id)
					let existingCheck = newRestaurantCheckIns[index]
					newRestaurantCheckIns.splice(index, 1, incomingCheckIn);
				} else {
					console.log("ADDING CHECK: " + incomingCheckIn._id)
					newRestaurantCheckIns.push(incomingCheckIn) 
				}
			}
			global.set({restaurantCheckIns: newRestaurantCheckIns})
		}
	}, [addUpdatedRestaurantCheckIns])



	

	//USED TO UPDATE THE TIMERS ON THE CHECK IN DATA
	const timerToClearSomewhere = useRef(false)
	useEffect(() => {
		if (records) {


			// filteredCheckIns = stableSort(restaurantCheckIns, getComparator(order, orderBy))


			// setWaitlistCheckIns(filteredCheckIns.filter(rec => rec.status != 'seated'))
			// setSeatedCheckIns(filteredCheckIns.filter(rec => rec.status == 'seated'))


			// timerToClearSomewhere.current = setInterval(() => {
			// 	setFilteredCheckIns([...filteredCheckIns])
			// }, 30000)

			// return () => {
			// 	clearInterval(timerToClearSomewhere.current)
			// }

		}
	}, [records])

	

	useEffect(() => {
		if (!record.numberOfGuests) {
			// setRecordByField('numberOfGuests', localStorage.lastPartySize) // TODO toggleButtonGroup sucks and isnt responding to the value i am passing
		} else if (restaurantCheckIns && restaurantCheckIns.length > 0) {
			let idx = maxGuestWaitTimeGroups.findIndex(g => {
				return record.numberOfGuests <= g
			})
			if (idx > -1) {
				let waitTime = currentWaitTimes[idx] ? currentWaitTimes[idx] : 0
				setRecordByField('waitTimeInMinutes', waitTime)
			}
		}
	}, [record.numberOfGuests])

    const checkInGuest = async (record) => {
        let onValid = async () => {
			setLoadingAsync(true)

			smartFilter.filter('status', record.waitTimeInMinutes > 0 ? 'waiting' : 'seated')
			let success = await checkInGuestApi(record)
			if (success) {
				setRecord({...record, name:'', phone:'', numberOfGuests: null, waitTimeInMinutes: 0})
			}
			setTimeout(() => {
				setLoadingAsync(false)
			}, 500)
		}
		global.set({onValid: (record) => onValid(record), validateNow: true})
	}
	
	const setRecordByField = (field, value) => {
		setRecord({...record, [field]:value})
	}

	const cancelCheckIn = async (checkIn) => {
		await cancelCheckInApi(checkIn._id)
		handleClose(); 
	}

	const columns = [
		{ id: 'name', align: 'left', label: 'Guest'},
		{ id: 'phone', align: 'left', label: 'Phone', width: isSmallTablet ? '120px' : '100px'},
		{ id: 'numberOfGuests', align: 'center', label: 'Party', width: isSmallTablet ? '80px' : '100px' },
		{ id: 'waitTimeInMinutes', align: 'left', label: 'Est.', width: isSmallTablet ? '65px' : '100px' },
		{ id: 'remaining', align: 'left', label: 'Status', width: isSmallTablet ? '135px' : '200px' },
		{ id: 'Action', align: 'left', label: '', width: isSmallTablet ? '170px' : '200px' },
	];


	return (
		<DForm2 name="test" record={record} setRecord={setRecord} style={{}}>
			<Grid container style={styles.checkIn} alignItems="stretch">
				{/* <motion.div style={{width: 700, height: '70px', border: 'solid 1px #CCC', position: 'absolute', zIndex: 10}} initial={{left: '0px', top: '0px'}} animate={{top: '100%', left: "400px"}} transition={{ duration: 0.8 }}>
					Test
				</motion.div> */}
				<Grid container item style={{width: '350px', padding: 15}} direction="column" alignItems="stretch">
					<Grid item xs>
						<Paper style={{marginRight: 0, width: '100%', height: '100%', padding: 15}} elevation={3}>
							<Grid container direction="column" style={{height: '100%'}}>
								<Grid item xs style={{opacity: loadingAsync ? 0.3 : 1}}>

									<FormControl component="fieldset" fullWidth style={{marginBottom: 20}}>
										<FormLabel component="legend">Party Size</FormLabel> 
										
										<Box style={{borderTop: 'solid 1px #0000001f', borderLeft: 'solid 1px #0000001f', marginBottom: 15, borderRadius: 0, margin: 0, padding: 0}}>
											<ToggleButtonGroup aria-label="outlined primary button group" exclusive value={record.numberOfGuests} onChange={(e, value) => setRecordByField('numberOfGuests', value)} style={{border: 'none', width:'100%', margin: 0, padding: 0}} >
												<ToggleButton classes={{root: classes.root, selected: classes.selected}} size="large" value={1} style={{borderTopColor: 'transparent', borderBottomColor: 'transparent', borderLeftColor: 'transparent'}}>1</ToggleButton>
												<ToggleButton classes={{root: classes.root, selected: classes.selected}} size="large" value={2} style={{borderTopColor: 'transparent', borderBottomColor: 'transparent'}}>2</ToggleButton>
												<ToggleButton classes={{root: classes.root, selected: classes.selected}} size="large" value={3} style={{borderTopColor: 'transparent', borderBottomColor: 'transparent'}}>3</ToggleButton>
												<ToggleButton classes={{root: classes.root, selected: classes.selected}} size="large" value={4} style={{borderTopColor: 'transparent', borderBottomColor: 'transparent'}}>4</ToggleButton> 
											</ToggleButtonGroup>
											<ToggleButtonGroup aria-label="outlined primary button group" exclusive value={record.numberOfGuests} fullWidth onChange={(e, value) => setRecordByField('numberOfGuests', value)} style={{border: 'none', height: 50, borderRadius: 0, width:'100%', margin: 0, padding: 0}} >
												<ToggleButton classes={{root: classes.root, selected: classes.selected}} size="large" value={5} style={{borderLeftColor: 'transparent'}}>5</ToggleButton>
												<ToggleButton classes={{root: classes.root, selected: classes.selected}} size="large" value={6} style={{}}>6</ToggleButton>
												<ToggleButton classes={{root: classes.root, selected: classes.selected}} size="large" value={7} style={{}}>7</ToggleButton>
												<Button style={{borderColor: '#0000001f', borderLeftColor: 'rgba(131, 131, 131, 0.1)'}} classes={{root: classes.root, outlinedPrimary: classes.selected}} color={record.numberOfGuests > 7 ? 'primary' : 'secondary'} size="large" variant="outlined" onClick={showPartySizeMenu} aria-controls="party-size-menu" aria-haspopup="true">
													{record.numberOfGuests > 7 && <div style={{marginLeft: 18}}>{record.numberOfGuests}</div>}
													<ArrowDropDownIcon color={record.numberOfGuests > 7 ? 'primary' : 'secondary'} />
												</Button>
											</ToggleButtonGroup>
										</Box>
									</FormControl>

									<FormControl component="fieldset" fullWidth style={{marginBottom: 20}}>
										<FormLabel component="legend">Estimated Wait Time</FormLabel>
										<OptionSwiper width={57} value={record.waitTimeInMinutes} setValue={(value) => setRecord({...record, waitTimeInMinutes: value})} onChange={(v) => setRecord({...record, waitTimeInMinutes: v})} unit="minute" options={[{0: 'No<br/>Wait'}, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80]} />
									</FormControl>


									<FormControl component="fieldset" fullWidth style={{marginBottom: 20}}>
										<FormLabel component="legend">Guest Name</FormLabel>
										{/* onChange={(e) => setRecordByField('name', e.target.value)} */}
										<DInput name="name" required autoComplete='none' />
										{restaurant && <DInput name="restaurant" overrideValue={restaurant._id} style={{display:'none'}}/>}
									</FormControl>

									<FormControl component="fieldset" fullWidth style={{marginBottom: 20}}>
										<FormLabel component="legend">Guest Phone</FormLabel>
										<DInput
											required
											format="(###) ###-####"
											type="tel"
											name="phone"
											pattern="[0-9]*"
											autoComplete='none'
											// InputLabelProps={{ shrink: true }}
											// fullWidth={true}
										/>
									</FormControl>
								</Grid>
								<Grid item>
									{loadingAsync && 
										<LinearProgress style={{marginBottom: 15}} />
									}
									{!loadingAsync && 
										<Button size="large" variant="contained" color="primary" disabled={!(record.numberOfGuests && record.name && record.phone && record.phone.replace(/\D/g,'').length >= 10)} fullWidth onClick={() => checkInGuest(record)}>
											{record.waitTimeInMinutes <= 0 && 'Seat Guest Now'}
											{record.waitTimeInMinutes > 0 && 'Add Guest To Wait List'}
										</Button>
									}
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
				

				<Grid xs container item direction="column" alignItems="stretch" style={{padding: 15, paddingLeft: 0}}>
					<Grid item style={{height: 90, paddingBottom: 15}}>
						<Paper style={styles.paper}>
							<Grid container alignItems="stretch" style={{height: '100%'}}>
								<Grid xs item container alignItems="center" height="100%" justify="center" wrap="nowrap" style={{paddingLeft: 10, paddingRight: 10, fontSize: '.9em', fontWeight: 500}}>
									CURRENT WAIT TIMES
								</Grid>
								{maxGuestWaitTimeGroups.map((rec, index) => {
									let last = index+1 == maxGuestWaitTimeGroups.length
									let min = index == 0 ? '1' : maxGuestWaitTimeGroups[index-1]+1
									let max = last ? '+' : '-' + rec
									let currentWaitTime = currentWaitTimes[index]
									return (
										<Grid xs item container alignItems="center" height="100%" justify="center" wrap="nowrap" style={{paddingLeft: 10, paddingRight: 10, marginTop: 12, marginBottom: 12, borderRight: !last ? 'solid 1px #DDD' : 'inherit'}}>
											<Grid item style={{fontSize: '1.2em', color: '#888', width: last ? 22 : 30}}>
												{min}{max}
											</Grid>
											{currentWaitTime != undefined && currentWaitTime > 0 && <>
												<Grid item style={{marginLeft: 10, fontSize: '2.5em', letterSpacing: '-1px', color: 'var(--purple)'}}>{currentWaitTime}</Grid>
												<Grid item style={{marginLeft: 4, color: 'var(--purple)', fontWeight: 500, fontSize: '1.1em', marginTop: 10}}>min</Grid>
											</>}
											{(!currentWaitTime || currentWaitTime == 0) &&
												<Grid item style={{marginLeft: 10, fontSize: '1.5em', color: '#DDD'}}><u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></Grid>
											}
										</Grid>
									)
								})}
							</Grid>
						</Paper>
					</Grid>

					<Grid xs item style={{}}>

						<Grid container style={styles.checkIn} alignItems="stretch">

							<Grid container item style={{ width: '100%'}}>
								<Paper style={styles.paper} >

									<Grid container item direction="column" alignItems="stretch" style={{height: '100%'}}>

										<Grid item container style={{padding: 10, paddingLeft: 0, paddingBottom: 5}}>
											<Grid item xs>
												<CheckInSearch value={smartFilter.getVal('filters.search', '')} onChange={(e, value) => { smartFilter.filter('search', value) } }  />
											</Grid>
											{!smartFilter.hasFilter('search') &&
												<Grid item>
													<Paper>
														<ToggleButtonGroup exclusive size="small" value={smartFilter.hasFilter('status', 'waiting')} style={{height: 44}} onChange={(e, value) => { smartFilter.filter('status', value ? 'waiting' : 'seated') } }>
															<ToggleButton classes={{root: classes.root2, selected: classes.selected2}} key={0} value={true} style={{height: '100%', paddingLeft: 14, paddingRight: 14}}>
																{smartFilter.hasFilter('status', 'waiting') && 
																	<Icon path={mdiAccountClockOutline} title="Guests Waiting" size={0.9} color="var(--purple)" style={{marginRight: 6}}  />
																}
																WAITING
															</ToggleButton>
															<ToggleButton classes={{root: classes.root2, selected: classes.selected2}} key={1} value={false} style={{height: '100%', paddingLeft: 14, paddingRight: 14}}>
																{smartFilter.hasFilter('status', 'seating') && 
																	<Icon path={mdiTableChair} title="Guests Seated" size={0.9} color="var(--purple)" style={{marginRight: 6}}  />
																}
																SEATED
															</ToggleButton>
														</ToggleButtonGroup>
													</Paper> 
												</Grid>

											}
										</Grid>
										

										{/* table */}
										<Grid item style={{overflow:'hidden'}}>
											<table class="h-full w-full overflow-hidden" style={{fontSize: isSmallTablet ? '.95em' : '1em'}}>
												<thead class="w-full font-medium">
													<tr>
														{columns.filter(c => c.show != false).map(col => 
															<td class="p-2 pl-4 whitespace-no-wrap" style={{borderBottom: 'solid 1px #CCC', width: col.width ? col.width : 'auto', textAlign: col.align ? col.align : 'left'}} onClick={() => smartFilter.sortBy(col.id)} colspan={col.colspan ? col.colspan : 1}>
																{col.label}
																{smartFilter.isFieldSorted(col.id, 'asc') && <KeyboardArrowDownIcon style={{marginBottom: 2, marginLeft: 2}} />}
																{smartFilter.isFieldSorted(col.id, 'desc') && <KeyboardArrowUpIcon style={{marginBottom: 2, marginLeft: 2}} />}
															</td>
														)}
													</tr>
												</thead>
											</table>
										</Grid>
										<Grid xs item style={{overflow:'hidden', overflowY: 'auto'}}>
											<table class="w-full" style={{fontSize: isSmallTablet ? '.95em' : '1em'}}>
												<tbody>
													{loading && 
														<tr>
															<td style={{width: '100%'}}>
																<LinearProgress />
															</td>
														</tr>
													}
													{records && records.map((checkIn, index) => {
														return (
															<Slide in direction={'right'} key={checkIn._id.toString()} >
																<tr style={{borderBottom: 'solid 1px #e5e5e5'}}>
																	<td class="p-2 pl-4" style={{width: columns[0].width ? columns[0].width : 'auto'}}>
																		<span style={{}}>{checkIn.name}</span>
																	</td>

																	<td class="p-2 pl-4" style={{width: columns[1].width ? columns[1].width : 'auto'}}>
																		<span style={{color:"gray", whiteSpace: 'nowrap'}}>{checkIn.phone}</span>
																	</td>
																
																	<td class="p-2 pl-4" style={{width: columns[2].width ? columns[2].width : 'auto', textAlign: 'center'}}>
																		{checkIn.numberOfGuests}
																	</td>

																	<td class="p-2 pl-4" style={{width: columns[3].width ? columns[3].width : 'auto'}}>
																		{global.formatUnitOfTime(checkIn.waitTimeInMinutes)}
																	</td>

																	<td class="p-2 pl-4" style={{width: columns[4].width ? columns[4].width : 'auto'}} onClick={e => showHistoryMenu(e, checkIn)}>
																		{checkIn.status == 'checkedIn' && 
																			<div className={classes.statusBar} style={{height: isSmallTablet ? 21 : 24}}>
																				<div className={classes[checkIn.color]} style={{width: checkIn.percent + '%'}}>{checkIn.elapsed}m</div>
																			</div>
																		}
																		{checkIn.status == 'tableReady' && <>
																			<NotificationsActiveIcon size="small" color="secondary" />
																			{checkIn.tableReadySmsSentAt &&  
																				<span style={{marginLeft: 6}}>{moment(checkIn.tableReadySmsSentAt).fromNow()}</span>
																			}
																		</>}

																		{checkIn.status == 'seated' && <>
																			{checkIn.seatedAt &&  
																				<span>Seated<br/> {moment(checkIn.seatedAt).fromNow()}</span>
																			}
																		</>}
																	</td>
																	
																	<td class="p-2 pl-4" style={{width: columns[5].width ? columns[5].width : 'auto'}}>
																		<FormControl component="fieldset" fullWidth style={{width: isSmallTablet ? 145 : 160}}>
																			<ToggleButtonGroup aria-label="outlined primary button group" exclusive fullWidth>
																				{checkIn.status == 'checkedIn' && 
																					<Button variant="outlined" fullWidth style={{border: 'solid 1px #DDD', fontSize: isSmallTablet ? '.95em' : '1em'}} onClick={() => pageGuestApi(checkIn._id)}>
																						TEXT GUEST
																					</Button>
																				}
																				{checkIn.status == 'tableReady' && 
																					<Button variant="outlined" fullWidth style={{border: 'solid 1px #DDD', fontSize: isSmallTablet ? '.95em' : '1em'}} onClick={() => seatGuestApi(checkIn._id)}>
																						SEAT GUEST
																					</Button>
																				}
																				{checkIn.status == 'seated' && 
																					<Button variant="outlined" fullWidth style={{border: 'solid 1px #DDD', fontSize: isSmallTablet ? '.95em' : '1em'}} onClick={() => undoSeatGuestApi(checkIn._id)}>
																						UNSEAT
																					</Button>
																				}
																				<Button variant="outlined" onClick={e => showCheckOptionsMenu(e, checkIn)} style={{width: 40, minWidth: 40, minHeight: 40, border: 'solid 1px #DDD'}} aria-controls="check-options-menu" aria-haspopup="true">
																					<ArrowDropDownIcon style={{color: '#888'}} />
																				</Button>
																			</ToggleButtonGroup>
																		</FormControl>
																		
																	</td>
																</tr>
															</Slide>
														)
													})}
												</tbody>
											</table>
										</Grid>
										
									</Grid>
								</Paper>

							</Grid>

						</Grid>


					</Grid>
					
				</Grid>
			</Grid>




			<Menu id="party-size-menu" getContentAnchorEl={null} anchorEl={anchorEl} open={menu == 'partySize'} onClose={handleClose} TransitionComponent={Fade} 
				style={{maxHeight: 300, top: -5, marginLeft: 0}}
				anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
				transformOrigin={{ vertical: "top", horizontal: "right"}}
				PaperProps={{
					style: {border: 'solid 1px #0000001f', borderTop: 'none', boxShadow: 'none', maxHeight: 300, width: 73},
				}}
			>
				{_.times(100, num => {
					if (num > 7) {
						return (
							<MenuItem key={num} onClick={(e) => { setRecordByField('numberOfGuests', num); handleClose(e); }} style={{color: '#888'}} selected={record.numberOfGuests == num}>{num}</MenuItem>
						)
					}
				})}
			</Menu>


			<Menu id="check-options-menu" getContentAnchorEl={null} anchorEl={anchorEl} open={menu == 'history'} onClose={handleClose} TransitionComponent={Fade} 
				style={{maxHeight: 400, top: 0, marginLeft: 0}}
				anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
				transformOrigin={{ vertical: "top", horizontal: "right"}}
				PaperProps={{
					style: {border: 'solid 1px #0000001f', maxHeight: 350, width: 300},
				}}
			>
				<Grid container style={{paddingLeft: 10}}>
					<Grid item xs container direction="column" style={{padding: 10}}>
						<Grid item xs style={{marginBottom: 5}}>
							<RestoreIcon /> History
						</Grid>
						{selectedCheckIn && <>		
							{selectedCheckIn.checkedInAt &&
								<Grid item xs container justify="space-between">
									<span style={{color: 'var(--purple)', marginLeft: 10, marginRight: 20}}>{moment(selectedCheckIn.checkedInAt).fromNow()}</span><span style={{ marginRight: 20}} >Checked In</span>
								</Grid>
							}
							{selectedCheckIn.tableReadySmsSentAt &&
								<Grid item xs container justify="space-between">
									<span style={{color: 'var(--purple)', marginLeft: 10, marginRight: 20}}>{moment(selectedCheckIn.tableReadySmsSentAt).fromNow()}</span><span style={{ marginRight: 20}} >Texted Guest</span>
								</Grid>
							}
							{selectedCheckIn.seatedAt &&
								<Grid item xs container justify="space-between">
									<span style={{color: 'var(--purple)', marginLeft: 10, marginRight: 20}}>{moment(selectedCheckIn.seatedAt).fromNow()}</span><span style={{ marginRight: 20}} >Seated guest</span>
								</Grid>
							}
							{selectedCheckIn.cancelledAt &&
								<Grid item xs container justify="space-between">
									<span style={{color: 'var(--purple)', marginLeft: 10, marginRight: 20}}>{moment(selectedCheckIn.cancelledAt).fromNow()}</span><span style={{ marginRight: 20}} >Cancelled</span>
								</Grid>
							}
						</>}
					</Grid>
				</Grid>
			</Menu>



			<Menu id="check-options-menu" getContentAnchorEl={null} anchorEl={anchorEl} open={menu == 'checkOptions'} onClose={handleClose} TransitionComponent={Fade} 
				style={{maxHeight: 400, top: 0, marginLeft: 0}}
				anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
				transformOrigin={{ vertical: "top", horizontal: "right"}}
				PaperProps={{
					style: {border: 'solid 1px #0000001f', maxHeight: 350, width: isSmallTablet ? 145 : 160},
				}}
			>
				{selectedCheckIn &&
					<Grid item style={{width: isSmallTablet ? 145 : 160}}>
						{selectedCheckIn.status == 'tableReady' &&
							<MenuItem key={1} onClick={(e) => { handleClose(e); pageGuestApi(selectedCheckIn._id)} } style={{fontWeight: 500, fontSize: '1em', color: 'var(--text)', marginTop: 5, marginBottom: 5}}>
								TEXT AGAIN
							</MenuItem>
						}
						{selectedCheckIn.status != 'seated' && selectedCheckIn.status != 'tableReady' &&
							<MenuItem key={2} onClick={(e) => { handleClose(e); seatGuestApi(selectedCheckIn._id)} } style={{fontWeight: 500, fontSize: '1em', color: 'var(--text)', marginTop: 5, marginBottom: 5}}>
								SEAT GUEST
							</MenuItem>
						}
						{selectedCheckIn.status != 'cancelled' && <>
							<MenuItem key={3} onClick={(e) => { setConfirmOpen(true);} } style={{fontWeight: 500, fontSize: '1em', color: 'var(--text)', marginTop: 5, marginBottom: 5}}>
								CANCEL 
							</MenuItem>
							<DConfirm
								title="Cancel Request?"
								open={confirmOpen}
								setOpen={setConfirmOpen}
								onConfirm={() => {cancelCheckIn(selectedCheckIn);} }
							>
								Are you sure you want to cancel this request?
							</DConfirm>
						</>}
					</Grid>
				}
			</Menu>








		</DForm2>
	);
};