import React from 'react';

export default (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 204 186" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title>Shape</title>
            <desc>Created with Sketch.</desc>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Dash-Now-logo" fill={props.fill ? props.fill : "#8B6BD9"}>
                    <g id="dash-logo-black-vertical">
                        <g id="Group">
                            <g id="d">
                                <path d="M98.1471699,107.68388 C107.582321,107.68388 117.017471,111.545364 124.835168,119.268333 L124.835168,174.358847 C117.017471,182.081817 107.582321,185.943301 98.1471699,185.943301 C78.4681412,185.943301 64.9893544,169.982498 64.9893544,146.81359 C64.9893544,123.644683 78.4681412,107.68388 98.1471699,107.68388 Z M203.030434,0.0488990253 L203.030434,97.6269041 C203.030434,130.748648 185.862048,160.016022 159.612593,177.655373 L159.610438,26.0778378 L124.835168,26.0778378 L124.835168,91.2082118 C114.321714,83.4852425 102.460382,79.3663256 90.5990493,79.3663256 C54.7454765,79.3663256 29.6749331,107.169015 29.6749331,146.81359 C29.6749331,154.841901 30.7030549,162.384587 32.6406458,169.309097 C12.568468,151.483902 2.84217094e-14,125.966238 2.84217094e-14,97.6269041 C2.84217094e-14,44.2749684 44.5454575,0.923662613 99.8364771,0.0619716901 L101.515217,0.0488990253 L203.030434,0.0488990253 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}