import log from "loglevel";
import dashFetch from "../utils/dashFetch"

export default function(restaurantId, gatewayId, debug) {
    return new Promise((resolve, reject) => {
		if (debug) log.info('assignRestaurantToGateway api');
        const url = `${global.dashServerUrl}/dashboard/api/v1/dashboard/assignRestaurantToGateway`
        
        const body = {
            restaurantId,
            gatewayId,
        }

        dashFetch(url, body, debug).then(({ res, json }) => {
			log.info('>>> assignRestaurantToGatewayApi JSON');
			log.info(json)
			if (json.success) {
				resolve(true);
			} else {
				resolve(false)
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
    })
}