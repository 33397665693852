import React, { useState } from 'react';
import SelectMui from '@material-ui/core/Select';
import { Grid, Paper, Button, Input, Typography, Divider, CircularProgress, FormControl, InputLabel, TextField } from '@material-ui/core';
import generateQrCodePageApi from '../../actions/generateQrCodePageApi';
import { makeStyles } from '@material-ui/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import log from 'loglevel'
import DForm2 from '../../components/dash/DForm2';
import DInput from '../../components/dash/DInput';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { useEffect } from 'reactn';
import emailSupport from '../../actions/emailSupport';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    scrolling: {
        overflow: 'auto',
        height: 'auto'
    },
    paper: {
        width:'100%',
        height: '100%',
        padding: '15px',
        textAlign: 'center'
    },
    section: {
        padding:'8px 0px'
    }
    
}))

export default function (props) {
    const c = useStyles();
    const [restaurant] = global.get('restaurant')
    const [record, setRecord] = useState({checkedSupport: true, email: localStorage.emailAddress, name: localStorage.fullName,})

    useEffect(()=>{
        if (restaurant) {
            setRecord({
                ...record, 
                restaurantName: restaurant.name,
                appName: restaurant.appName,
            })
        }
    },[restaurant])

    const handleSubmit = async () => {
        await emailSupport(record)
        setRecord({...record, message:''})

    }

    const handleChange = (fieldName, value) => {
        setRecord({...record, [fieldName]: value})
    }
    console.log({record})
	return (
        <div style={{display: 'flex', justifyContent:'flex-start', alignItems:'center', maxWidth:1120}}>
            <div style={{width:'calc(100% - 30px)'}}>
                    <div className={c.section}>
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Please select one of the following:</FormLabel>
                                <RadioGroup aria-label="Support request or Suggestion" name="isSupport" value={record.checkedSupport} color="primary" 
                                    onChange={(e) => { setRecord({ ...record, checkedSupport: e.target.value ==='true' ? true : false }) }}>
                                    <FormControlLabel value={true} control={<Radio />} label="Support request" />
                                    <FormControlLabel value={false} control={<Radio />} label="Suggestion" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>

                    <div className={c.section}>
                        <TextField value={record.name} onChange={(e) => handleChange('name', e.target.value)} label="Your name" name="name" style={{width:'100%'}} variant="outlined" InputLabelProps={{shrink:true}}></TextField>    
                    </div>

                    <div className={c.section}>
                        <TextField value={record.email} onChange={(e) => handleChange('email', e.target.value)} label="Email" name="email" style={{width:'100%'}} variant="outlined" InputLabelProps={{shrink:true}}></TextField>    
                    </div>

                    <div className={c.section}>
                        <TextField value={record.message} onChange={(e) => handleChange('message', e.target.value)}
                            label={`Additional details regarding your ${record.checkedSupport ? 'issue' : 'suggestion'}`} name="message" 
                            style={{width:'100%'}} variant="outlined" multiline rows={4} InputLabelProps={{shrink:true}}>
                        </TextField>
                    </div>

                    <div className={c.section} style={{display:'flex', justifyContent:'center'}}>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                    </div>
            </div>
        </div>
	)
}
