import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, Paper, CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
	AddCircleOutline,
} from '@material-ui/icons';
import { Button, TextField, Checkbox } from '@material-ui/core';
import dashFetch from '../../utils/dashFetch';
import { withRouter } from 'react-router-dom';

import ReactTable from "react-table";
import "react-table/react-table.css";

const styles = (theme) => ({
    root: {
    },
	addRestaurantIcon: {
		float: 'left',
		marginTop: 5,
		marginRight: 5,
		width: '20px',
		height: '20px'
	},
	button: {
        marginLeft: 20,
		marginRight: 20,
		marginTop: 10,
		marginBottom: 10,	
    },
    
})

const RestaurantListing = (props) => {
	const { classes } = props;


	const placeholderData = [
		{ name: 'Sombreros', address: '123 shawnee', installCode: '1234asdf', lastUpdated: '01/04/19' },
		{ name: 'Saints', address: '4565 Lenexa', installCode: '543cv245', lastUpdated: '05/06/19' },
		{ name: 'Joe & HAsh', address: '123 shawnee', installCode: '6g4cfdg46', lastUpdated: '07/24/19' },
    ]
    const [restaurants] = global.get('restaurants')
    const [formattedRestaurants, setFormattedRestaurants] = useState([])
	const [restaurant] = global.get('restaurant')

	useEffect(() => {
        setFormattedRestaurants(formatRestaurants(restaurants))
        global.set({ headerTitle: 'RESTAURANT LISTING' })
    }, [restaurants]);


	const formatRestaurants = (restaurants) => {
        if (restaurants) {
            console.log({restaurants})
            return restaurants.map((restaurant) => {
                let enabled = <center key={restaurant?.restaurantEnabled}><Checkbox checked={restaurant?.restaurantEnabled} style={{padding: 0}} color="primary" /></center> 
                return {
                    name: restaurant?.name,
                    id: restaurant?._id,
                    installCode: restaurant?.installCode,
                    status: global.capitalize(restaurant?.status ?? ""),
                    appName: restaurant?.appName,
                    restaurantEnabled: enabled,
                    address: (restaurant?.locationInfo?.addressLine1) ? (restaurant?.locationInfo?.addressLine1 + ', ' + restaurant?.locationInfo?.city) : 'N/A',
                    restaurantGroup: restaurant?.restaurantGroup && restaurant?.restaurantGroup?.name ? restaurant?.restaurantGroup?.name : '',
                    posType: restaurant?.posType && restaurant?.posType?.posType ? restaurant?.posType?.posType : ''
                };
            });
        }
    };

	const addRestaurant = async () => {
		navigateToListing(null) // pass null to creat new restaurant
	};

	const navigateToListing = async (id) => {
		console.log("NAVIGATE TO LISTING " + id)
		// global.selectedRestaurantId = id
        let path = (id) ? id : 'new'
        let rest = global._.find(restaurants, { _id: id })
        global.set({ 'restaurant': rest})
        props.history.push(`/admin/restaurants/addEditRestaurant/${path}`)
    }

	return (
		<div className={styles.root} style={{ width: '100%' }}>
			<Grid container style={{width: '100%'}}>
                <Paper style={{ width: '100%', height: '100%' }} className={classes.rootTable}>
                    <Grid container>
                        <Grid item xs={9}>
                            {restaurant &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={()=> navigateToListing(restaurant._id)}
                                >
                                    <Typography variant="subtitle1" style={{ color: 'white', width: 190 }}>
                                        View {restaurant.name}
                                    </Typography>
                                </Button>
                            }
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                style={{
                                    float: 'right',
                                    height: 45,
                                    width: 200,
                                }}
                                onClick={addRestaurant}
                            >
                                <div>
                                    <AddCircleOutline className={classes.addRestaurantIcon} />
                                    <Typography variant="subtitle1" style={{ color: 'white', width: 190 }}>
                                        Add Site
                                    </Typography>
                                </div>
                            </Button>
                        </Grid>
                    </Grid>
                    <ReactTable
                        data={formattedRestaurants}
                        filterable
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
                        }
                        defaultSorted={[
                            {
                                id: "id",
                                desc: true
                            }
                        ]}
                        columns={[
                            {
                                columns: [
                                    {
                                        Header: "Site Name",
                                        accessor: "name"
                                    },
                                    {
                                        Header: "ID",
                                        accessor: "id"
                                    },
                                    {
                                        Header: "POS Type",
                                        accessor: "posType"
                                    },
                                    {
                                        Header: "Address",
                                        accessor: "address"
                                    },
                                    {
                                        Header: "Install Code",
                                        accessor: "installCode"
                                    },
                                    {
                                        Header: "Site Group",
                                        accessor: "restaurantGroup"
                                    },
                                    {
                                        Header: "Status",
                                        accessor: "status"
                                    },
                                    {
                                        Header: "App Name",
                                        accessor: "appName"
                                    },
                                    {
                                        Header: "Enabled",
                                        accessor: "restaurantEnabled",
                                        width: 100,
                                            sortMethod: (a,b) => {
                                                if (a.key == b.key) {
                                                    return 0
                                                }
                                                return (a.key > b.key) ? 1 : -1
                                            },
                                    },
                                ]
                            },
                        ]}
                        getTrProps={(state, rowInfo) => {
                            if (rowInfo && rowInfo.row) {
                                // console.log('TYLER ' + JSON.stringify(rowInfo))
                                // console.log('TYLER 1 ' + JSON.stringify(rowInfo.row))
                                return {
                                    onClick: (e) => {
                                        navigateToListing(rowInfo.row.id)
                                    },
                                    // style: {
                                    //     background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                                    //     color: rowInfo.index === this.state.selected ? 'white' : 'black'
                                    // }
                                }
                            } else {
                                return {}
                            }
                        }}
                        defaultPageSize={15}
                        className="-striped -highlight"
                    />
                    <br />
                </Paper>
                <div style={{ textAlign: "center" }}>
                    <em>Tip: Hold shift when sorting to multi-sort!</em>
                </div>
            </Grid>
        </div>
	);
};

RestaurantListing.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withRouter(withStyles(styles, { withTheme: true })(RestaurantListing));