import React, { useState } from 'react';
import {
	Paper,
	ListItem,
	List,
	Typography,
	ListItemText,
	Tabs,
	Badge,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TablePagination,
	TableFooter,
	CardContent,
	Grid
} from '@material-ui/core';
import moment from 'moment'

const tabs = [
	{
		title: 'F / S / O',
		alertCount: 3
	},
	{
		title: 'Comments',
		alertCount: 1
	}
];

const styles = {
	infoBanner: {
		width: '100%',
		padding: 15
	},
	paper: {
		width: '100%',
		padding: '1em'
	}
};

export default () => {
	let [ page, setPage ] = useState(0);
	let [ rowsPerPage, setRowsPerPage ] = useState(5);
	const [dashboardPaidItemsData] = global.get('dashboardPaidItemsData')


	const initialFilter = () => Number(localStorage.filterDays) ||  7
	const [activeDateRange, setActiveDateRange] = useState(initialFilter)
	let itemData = []
	if (dashboardPaidItemsData && (dashboardPaidItemsData.length > 0)) {
		dashboardPaidItemsData.forEach((itmEntry) => {
			if (withinDateRange(itmEntry.aggregateDate, activeDateRange)) {
				let keyString = itmEntry.name + itmEntry.price.toString().replace('.', '')
				if (itemData[keyString]) {
					itemData[keyString].qty += itmEntry.qty
				} else {
					itemData[keyString] = {
						name: itmEntry.name,
						qty: itmEntry.qty,
						price: itmEntry.price,
					}
				}
			}
		})
		Object.keys(itemData).forEach((key) => {
			itemData[key].dailyAverage = itemData[key].qty / activeDateRange
		})
	}
	return (
		<Grid container style={styles.infoBanner}>
			<Paper style={styles.paper}>
				<CardContent color="primary">
					<Typography variant="h5" align="center">
						Top Items Sold
					</Typography>
				</CardContent>
				<Table style={{ tableLayout: 'auto', width: '100%' }}>
					<TableHead>
						<TableRow>
							<TableCell style={{ fontWeight: 'bold' }}>Item</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}>Price</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}>Total Sold</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}>Avg/Day</TableCell>
						</TableRow>
					</TableHead>
					<TableBody style={{ width: '100%' }}>
						{dashboardPaidItemsData && Object.keys(itemData).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n) => {
								let itmEntry = itemData[n]
								return (
									<TableRow key={itmEntry.user}>
										<TableCell>{itmEntry.name}</TableCell>
										<TableCell>{itmEntry.price}</TableCell>
										<TableCell>{itmEntry.qty}</TableCell>
										<TableCell>{itmEntry.dailyAverage.toFixed(2)}</TableCell>
									</TableRow>
								)
							})
						}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								colSpan={4}
								count={dashboardPaidItemsData ? dashboardPaidItemsData.length : 0}
								rowsPerPage={rowsPerPage}
								rowsPerPageOptions={[ 5, 10, 15 ]}
								page={page}
								backIconButtonProps={{
									'aria-label': 'Previous Page'
								}}
								onChangeRowsPerPage={(e) => setRowsPerPage(e.target.value)}
								nextIconButtonProps={{
									'aria-label': 'Next Page'
								}}
								onChangePage={(e, p) => setPage(p)}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</Paper>
		</Grid>
	);
};


const withinDateRange = (itmDate, daysBack) => {
	return (moment(itmDate) > moment().subtract(daysBack, 'd'))
}