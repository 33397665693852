import React, { useState, useEffect, useRef } from "react";
import { Grid, useMediaQuery } from "@material-ui/core";
import moment from "moment";

let styles = {
	item: {
		borderTop: "solid 1px #CCCCCC",
		borderBottom: "solid 1px #CCCCCC",
		padding: 0,
		marginTop: 4,
		color: "#888",
		borderRight: "solid 1px #e9e9e9",
	},
	active: {
		margin: 0,
		paddingTop: 4,
		border: "none",
		border: 'solid 1px var(--purple)',
		webkitBoxShadow: "0px 0px 5px #AAA",
		mozBoxShadow: "0px 0px 5px #AAA",
		boxShadow: "0px 0px 5px #AAA",
		fontSize: "1.1em",
		fontWeight: 500,
		color: "var(--purple)",
		backgroundColor: "white",
		whiteSpace: "nowrap",
		borderBottomLeftRadius: 5,
		borderBottomRightRadius: 5,
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5,
	},
};

export default (props) => {
	let itemWidth = props.width ? props.width : 50;
	let itemHeight = itemWidth - 10;

	const [scrolling, setScrolling] = React.useState(false);
	let [random] = React.useState(Math.floor(10000 + Math.random() * 900000).toString());
	let [selectedOption, setSelectedOption] = React.useState(null);
	let [selectedOptionDisplayOnly, setSelectedOptionDisplayOnly] = React.useState(null);
	let setValue = props.setValue;

	let optionPrefix = props.optionPrefix ? props.optionPrefix : "";
	let optionSuffix = props.optionSuffix ? props.optionSuffix : "";
	let unit = props.unit ? props.unit : "";

	const formatOption = (option) => {
		let optionFormatted = "";
		if (unit == "minute" || unit == "second" || unit == "hour") {
			optionFormatted = global.formatUnitOfTime(option, unit);
		} else {
			optionFormatted =
				(optionPrefix ? optionPrefix : "") +
				option +
				(optionSuffix ? optionSuffix : "");
		}
		return optionFormatted;
	};

	let optionsIn = props.options ? props.options : {};
	let options = []
	optionsIn.forEach((o, index) => {
		if (index == 0) {
			options.push({id: '', name: ''})
			options.push({id: '', name: ''})
		}
		if (typeof o === 'object') {
			options.push({id: Object.keys(o)[0], name: Object.values(o)[0]})
		} else {
			options.push({id: o, name: formatOption(o)})
		}
		if (index + 1 == optionsIn.length) {
			options.push({id: '', name: ''})
			options.push({id: '', name: ''})
		}
	})

	const isMobile = useMediaQuery("(max-width:600px)");

	if (props.color) {
		styles.active.backgroundColor = props.color;
		styles.item.borderTop = "solid 1px " + props.color;
		styles.item.borderBottom = "solid 1px " + props.color;
	}

	let scrollContainer;

	const setValueAndScroll = (val) => {
		let selectedOptionIndex = options.findIndex(rec => rec.id == val);
		scrollContainer = document.getElementById(random);
		if (selectedOptionIndex > -1 && scrollContainer) {
			setSelectedOption(options[selectedOptionIndex].id)
			setSelectedOptionDisplayOnly(options[selectedOptionIndex].name)
			scrollContainer.scrollTo(
				((selectedOptionIndex - 2) * itemWidth),
				scrollContainer.scrollTop
			);
		}
	};

	useEffect(() => {
		if (props.value != undefined && props.value != selectedOption) {
			setValueAndScroll(props.value);
		}
	}, [props.value]);

	useEffect(() => {
		let defaultValue = options ? options[2].id : "";
		scrollContainer = document.getElementById(random);
		if (!selectedOption && defaultValue) {
			setValueAndScroll(defaultValue);
		}
	}, []);

	const timer = useRef(false)

	let handleScroll = function (event) {
		let el = event.target;
		let last = el.scrollLeft;
		if (timer.current) {
			clearTimeout(timer.current);
		}
		timer.current = setTimeout(() => {
			last = last + itemWidth;
			let optionIndex = Math.round(last / itemWidth)
			// console.log('optionIndex: ', optionIndex)
			let newOption = options[optionIndex];
			// console.log('newOption: ', newOption)
			if (newOption.id && selectedOption != newOption.id) {
				setSelectedOption(newOption.id);
			}
			if (newOption.name && selectedOptionDisplayOnly != newOption.name) {
				setSelectedOptionDisplayOnly(newOption.name);
			}
			setScrolling(false);
		}, 50);

		if (!scrolling) {
			setScrolling(true);
		} else {
			last = last + itemWidth;
			let optionIndex = Math.round(last / itemWidth)
			let newOption = options[optionIndex];
			if (newOption.name && selectedOptionDisplayOnly != newOption.name) {
				setSelectedOptionDisplayOnly(newOption.name);
			}
		}
	};

	function scrollToClickedOption(event) {
		let scrollContainer = document.getElementById(random);
		var bounds = scrollContainer.getBoundingClientRect();
		var x = event.clientX - bounds.left;
		let scrollDiff = (2 - Math.floor(x / itemWidth)) * itemWidth * -1;
		scrollContainer.scrollTo(
			scrollContainer.scrollLeft + scrollDiff,
			scrollContainer.scrollTop
		);
	}

	useEffect(() => {
		if (selectedOption && setValue) {
			setValue(selectedOption);
			// console.log('selectedOption: ', selectedOption) 
		}
	}, [selectedOption]);

	return (
		<div style={{...props.style, overflow: "hidden", position: "relative", height: itemHeight + 20, width: itemWidth * 5 + "px", marginTop: 10}}>
			<div style={{...styles.item, ...styles.active, height: itemHeight + 10, width: itemWidth + 6, position: "absolute", top: 2, right: 0, bottom: 0, left: 0, zIndex: 2, pointerEvents: "none", marginLeft: "auto", marginRight: "auto"}}>
				<div style={{height: itemHeight, width: itemWidth + 6, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
					<span style={{textAlign: 'center'}} dangerouslySetInnerHTML={{__html: selectedOptionDisplayOnly}} />
				</div>
			</div>
			<div id={random} className="smart-scroll-container hide-scrollbar" style={{overflow: "auto", scrollSnapType: "x mandatory", scrollBehavior: "smooth", position: "absolute", top: 2, right: 0, bottom: 0, left: 0, pointerEvents: "auto"}} onScroll={handleScroll} onClick={(e) => scrollToClickedOption(e)}>
				<Grid container direction="row" wrap="nowrap" justify="space-between">
					{options.map((option, index) => {
						let style = {...styles.item, height: itemHeight, scrollSnapAlign: "center"}
						if (index == 0 || index > options.length - 3) {
							style.borderRight = "none";
						}
						if (index < 2 || index > options.length - 3) {
							style.borderTop = "solid 1px #e5e5e5";
							style.borderBottom = "solid 1px #e5e5e5";
							style.backgroundColor = "#f5f5f5";
						}
						if (index == 2) {
							style.borderLeft = props.color ? "solid 1px " + props.color : "solid 1px #CCCCCC";
							style.borderBottomLeftRadius = 5;
							style.borderTopLeftRadius = 5;
						}

						if (index == options.length - 3) {
							style.borderRight = props.color ? "solid 1px " + props.color : "solid 1px #CCCCCC";
							style.borderBottomRightRadius = 5;
							style.borderTopRightRadius = 5;
						}

						return (
							<Grid xs item container style={style} alignItems="center" justify="center">
								<div style={{width: itemWidth, textAlign: "center"}}>
									<span dangerouslySetInnerHTML={{__html: option.name}} />
								</div>
							</Grid>
						)
					})}
				</Grid>
			</div>
		</div>
	);
};
