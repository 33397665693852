import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, Paper, Tab, Tabs, Dialog } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
	AddCircleOutline,
} from '@material-ui/icons';
import { Button, TextField, Checkbox } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import AddEditAgency from "../components/AddEditAgency";
import moment from 'moment'


import ReactTable from "react-table";
import "react-table/react-table.css";
import getAgencyListApi from '../../actions/getAgencyListApi';
import getAgentListApi from '../../actions/getAgentListApi';

const styles = (theme) => ({
    root: {
    },
	addIcon: {
		float: 'left',
		marginTop: 5,
		marginRight: 5,
		width: '20px',
		height: '20px'
	},
	addButton: {
		float: 'right',
		marginLeft: 20,
		marginRight: 20,
		marginTop: 10,
		marginBottom: 10,
		width: 200,
		height: 45
    },
    tabs: {
        width: '100%'
    }
    
})

const AgencyListing = (props) => {
	const { classes } = props;

    const [dialogContent, setDialogContent] = useState()
    const [agencies, setAgencies] = useState([])
    const [agents, setAgents] = useState([])
    const [refresh, setRefresh] = useState(true)
    
    async function fetchData() {
        let data = await getAgencyListApi()
        if (data) setAgencies(data)
        data = await getAgentListApi()
        if (data) setAgents(data)
    }

    useEffect(() => {
        if(refresh){
            fetchData();
            setRefresh(false)
        }

    }, [refresh]);

	const upsertRecord = (record) => {
        setDialogContent(
            <AddEditAgency record={record} agents={agents} refresh={setRefresh} close={closeContent} />
        )
    }


    const closeContent = () => {
        setDialogContent(null)
    }

    const showRecords = () => {
        return (
            <div  className={classes.listing}>
                <Grid container>
                    <Paper style={{ width: '100%' }} className={classes.rootTable}>
                        <div>
                            <Grid container>
                                <Grid item xs={12} sm={9} style={{ padding: 14, textAlign:'left', marginTop:'-8px'}}>
                                    <Typography variant="h6" color="primary">
                                        Agencies
                                    </Typography>
                                </Grid>
                                <Grid item lg={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.addButton}
                                        onClick={() =>upsertRecord(null)}
                                    >
                                        <div>
                                            <AddCircleOutline className={classes.addIcon} />
                                            <Typography variant="subtitle1" style={{ color: 'white', width: 200 }}>
                                                Add Agency
                                            </Typography>
                                        </div>
                                    </Button>
                                </Grid>
                            </Grid>
                            {agencies && agencies.length > 0 && 
                                <ReactTable
                                    data={agencies}
                                    filterable
                                    defaultFilterMethod={(filter, row) =>
                                        String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
                                    }
                                    defaultSorted={[
                                        {
                                            id: "name",
                                            desc: true
                                        }
                                    ]}
                                    columns={[
                                        {
                                            Header: "",
                                            columns: [
                                                {
                                                    Header: "Name",
                                                    accessor: "name",
                                                },
                                                {
                                                    Header: "Code",
                                                    accessor: "code",
                                                },
                                                
                                            ]
                                        },
                                    ]}
                                    getTrProps={(state, rowInfo) => {
                                        if (rowInfo && rowInfo.row) {
                                            // console.log('TYLER ' + JSON.stringify(rowInfo))
                                            // console.log('TYLER 1 ' + JSON.stringify(rowInfo.row))
                                            return {
                                                onClick: (e) => {
                                                    upsertRecord(rowInfo.row._original)
                                                },
                                                // style: {
                                                //     background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                                                //     color: rowInfo.index === this.state.selected ? 'white' : 'black'
                                                // }
                                            }
                                        } else {
                                            return {}
                                        }
                                    }}
                                    defaultPageSize={15}
                                    className="-striped -highlight"
                                />
                            }
                            <br />
                        </div>
                    </Paper>
                    <div style={{ textAlign: "center" }}>
                        <em>Tip: Hold shift when sorting to multi-sort!</em>
                    </div>
                </Grid>
            </div>
        )
    }

    return (
        <Grid container >
            <div className={styles.root} style={{ width: '100%' }}>

                <Grid item lg={12} >
                    {showRecords()}
                </Grid>
                <Grid item lg={12} >
                    {dialogContent}
                </Grid>
            </div>
        </Grid>
    );
};
export default withRouter(withStyles(styles, { withTheme: true })(AgencyListing));