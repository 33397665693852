import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import {
	Grid,
	Button,
    Paper,
    Container,
    Chip,
    Typography,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import fetchProductPackageGroupApi from '../../../actions/fetchProductPackageGroupApi'
import theme from '../../../theme'


const styles = (theme) => ({
	root: {
        marginTop: 20,
    },
    box: {
		backgroundColor: 'var(--white)',
        border: '1px solid #EAEAEA',
        boxShadow: '0 0 10px 5px rgba(231,231,231,0.50)',
        paddingTop: 30,
        paddingBottom: '40px !important;',
		height: '100%',
        minWidth: 300,
        borderRadius: 6,
    },
});

const Dash = (props) => {
    const [ppg, setPpg] = useState()
	const { classes, request, setRequest, setShowStepper } = props;
	const sm = useMediaQuery(theme().breakpoints.down('sm'));
    const xs = useMediaQuery(theme().breakpoints.down('xs'));
    

    useEffect(() => {
        fetchData()
    }, [])


    const fetchData = async () => {
        
        let ppg = await fetchProductPackageGroupApi()
        if (ppg) {
            setPpg(ppg)
        }
    }
    //TODO - Remove hard coded package ids from this page
    const selectPackage = (packageId) => {
        request.data.selectedPackageId = packageId
        setShowStepper(true)
    }
    const renderProductPackage = (pp) => {
        let transactionFeeProduct = pp.products.find(p => p.type == 'software' && p.unitType == "transaction")
        let setupFeeProduct = pp.products.find(p => p.type == 'software' && p.unitType == "location")
        // console.log("")
        // console.log("transactionFeeProduct")
        // console.log(JSON.stringify(transactionFeeProduct))
        // console.log("")
        // console.log("setupFeeProduct")
        // console.log(JSON.stringify(setupFeeProduct))
        return (
            <Grid container item xs={12} sm={12} md={4} lg={4}>
                <Grid className={classes.box} item container direction="row" justify="center" >
                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center">
                        <Typography gutterBottom variant="h5" color="primary">{pp.name}</Typography>
                    </Grid>
                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center">
                        <Typography variant="h4" style={{ paddingRight: 3 }}>$</Typography>
                        <Typography variant="h3">{global.formatMoney(transactionFeeProduct.unitPrice)}</Typography>
                    </Grid>
                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center">
                        <Typography gutterBottom variant="body2" style={{ fontWeight: 300, paddingBottom: 25 }}>/ per {transactionFeeProduct.unitType.toLowerCase()}</Typography>
                    </Grid>
                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center">
                        <Typography gutterBottom style={{ fontWeight: 500, paddingBottom: 10 }}>Contactless Payment Benefits</Typography>
                    </Grid>
                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center">
                        <Typography gutterBottom style={{ fontWeight: 300, paddingBottom: 8 }}>Pay-at-table</Typography>
                    </Grid>

                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center">
                        <Typography gutterBottom style={{ fontWeight: 300, paddingBottom: 8 }}>Text-to-pay</Typography>
                    </Grid>
                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center">
                        <Typography gutterBottom style={{ fontWeight: 300, paddingBottom: 8 }}>Real-time curbside dashboard</Typography>
                    </Grid>
                    {transactionFeeProduct.mininumPrice && transactionFeeProduct.mininumPrice > 0 && transactionFeeProduct.billingFrequency &&
                        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center">
                            <Typography gutterBottom style={{ fontWeight: 300, paddingBottom: 8 }}>${transactionFeeProduct.mininumPrice/100} {transactionFeeProduct.billingFrequency} minimum</Typography>
                        </Grid>
                    }
                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" style={{ flexGrow: 1 }}>
                        <Typography gutterBottom style={{ fontWeight: 300, paddingBottom: 8, textDecoration: setupFeeProduct.waived ? 'line-through' : '', textAlign: 'right' }}>${setupFeeProduct.unitPrice/100} {setupFeeProduct.name}</Typography>
                        {setupFeeProduct.waived &&
                            <Chip label="Waived" size="small" style={{ marginLeft: 10, backgroundColor: '#FFC64D', borderRadius: 3 }} />
                        }
                    </Grid>
                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center">
                        <Button variant="contained" color="primary" size="large" style={{ marginTop: 30 }} onClick={() => selectPackage(pp._id)}>Get Started</Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

	return (
        // <Container className={classes.root}>
            <Grid container spacing={3} style={{padding: 25, paddingBottom: 0}}> 
                {ppg &&
                    <>
                    <Grid container item xs={12} sm={12} md={12} lg={12}>
                        <Grid item container direction="row" justify="center" >
                            <Typography variant="h4" style={{fontWeight: 300}}>
                                Select a package to begin
                            </Typography>
                        </Grid>
                    </Grid>
                    {ppg.productPackages.map(pp => {
                        return renderProductPackage(pp)
                    })}
                    </>
                }
            </Grid>
        // </Container>
	);
};

export default withStyles(styles, { withTheme: true })(Dash);
