import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function CustomizedInputBase() {
  const classes = useStyles();
  const [paymentSearchFilter, setPaymentSearchFilter] = global.get('paymentSearchFilter', '')
  const [orderTerminology] = global.get('orderTerminology')

  let orderTerm = global.isTextPay() && orderTerminology ? orderTerminology : "Check"
  return (
    <Paper className={classes.root}>
      <IconButton className={classes.iconButton} aria-label="menu">
        <SearchIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder={`Search by ${orderTerm} Number, Table Number, Guest Name, Amount, etc.`}
        inputProps={{ 'aria-label': `Search by ${orderTerm} Number, Table Number, Guest Name, Amount, etc.` }}
        value={paymentSearchFilter ? paymentSearchFilter : ''}
        onChange={e => setPaymentSearchFilter(e.target.value) }
      />
      {paymentSearchFilter && 
        <>
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton color="primary" className={classes.iconButton} aria-label="directions">
              <ClearIcon onClick={() => { setPaymentSearchFilter('') }} />
          </IconButton>
        </>
      }
    </Paper>
  );
}
