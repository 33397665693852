import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import { Grid, Paper, Drawer, Zoom, Badge, Tab, Tabs } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Button, TextField, Checkbox, Box, CircularProgress } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import theme from '../theme'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import log from 'loglevel'
import history from '../history.js'
import _ from 'lodash';


import DashCard from './components/DashCard'
import CheckDetails from "./components/CheckDetails";

import TransactionListing from './transactionListing';

import StopIcon from '@material-ui/icons/Stop';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DLoader from '../components/dash/DLoader';

import TodaySearch from './components/TodaySearch';
import accessUtils from '../utils/accessUtils';
import ManualCheckEntry from '../dashboard/components/ManualCheckEntry';

import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {
    },
    scrolling: {
        overflow: 'auto',
        height: 'auto'
    },
    checks: {
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 5,
            paddingRight: 5,
            maxHeight: 'calc(100% - 4.3em)',
        },
        [theme.breakpoints.up("sm")]: {
            paddingLeft: 10,
            paddingRight: 10,
            maxHeight: 'calc(100% - 3em)',
        },
    },
    paper: {
        // maxHeight: '100%',
        width:'100%',
        height:'100%'
    },
    badge: {
        [theme.breakpoints.down("xs")]: {
            marginRight:-3,
        },
        [theme.breakpoints.up("sm")]: {
            marginRight:-8,
        },
    },
    arrivedbadge: {
        [theme.breakpoints.down("xs")]: {
            marginRight:-3,
        },
        [theme.breakpoints.up("sm")]: {
            marginRight:-8,
        },
        backgroundColor: "#9fa8da",
    },
    iconWrapper: {
        height: '2.7em', 
        display: 'flex',
        alignItems: 'center' 
    }
}))


export default (props) => {
    const classes = useStyles();
    let [restaurant] = global.get('restaurant')
    let [checks, setChecks] = global.get('checks')
    let [filteredChecks, setFilteredChecks] = React.useState([])
    let [orderedChecks, setOrderedChecks] = React.useState([])
    let [paidChecks, setPaidChecks] = React.useState([])
    let [openChecks, setOpenChecks] = React.useState([])
    let [orderReadyChecks, setOrderReadyChecks] = React.useState([])
    let [arrivedChecks, setArrivedChecks] = React.useState([])
    let [completedChecks, setCompletedChecks] = React.useState([])
    let [drawerOpen, setDrawerOpen] = React.useState(false)
    let [dialogContent, setDialogContent] = useState()

    let smallScreen = useMediaQuery(theme().breakpoints.down('sm'));
    const isMobile = useMediaQuery('(max-width:600px)')
    let [textToPayEnabled] = global.get('textToPayEnabled')
    let [qrCodeEnabled] = global.get('qrCodeEnabled')
    let [imHereEnabled] = global.get('imHereEnabled')
    let [orderUpEnabled] = global.get('orderUpEnabled')
    let [orderUpShowOrderReady] = global.get('orderUpShowOrderReady')
    let [hasPosIntegration] = global.get('hasPosIntegration')
    let [newCustomerArrival] = global.get('newCustomerArrival', false)
    let [todaySearchFilter] = global.get('todaySearchFilter', '')
    let [checkTypeFilter] = global.get('checkTypeFilter', 'all')
    let signedIn = accessUtils.isLoggedIn()
    let [showAll, setShowAll] = global.get('showAll', false)
    
    let [showOrderedColumn, setShowOrderedColumn] = React.useState(true)
    let [showPaidColumn, setShowPaidColumn] = React.useState(true)
    let [showOpenColumn, setShowOpenColumn] = React.useState(true)
    let [showArrivedColumn, setShowArrivedColumn] = React.useState(true)
    let [showClosedColumn, setShowClosedColumn] = React.useState(false)
    let [showOrderReadyColumn, setShowOrderReadyColumn] = React.useState(false)
    let [isLoading, setIsLoading] = React.useState(false)
    
    let [numberOfColumns, setNumberOfColumns] = React.useState(3)

    let showSearch = true

    if (!localStorage.tabSelected) localStorage.tabSelected = 'ordered'

    let [selectedTab, setSelectedTab] = React.useState(localStorage.tabSelected)

    let showTabs = smallScreen

    useEffect(() => {
        showTabs = smallScreen
    }, [smallScreen]);



    const closeContent = () => {
        setDialogContent(null)
    }

    const showCheckDetails = (check) => {
        setDialogContent(
            <CheckDetails check={check} close={closeContent} />
        )
    }

    useEffect(() => {
        if (checks) {

            // REMOVE CHECKS OLDER THAN 24 HOURS FROM THE CHECKS ARRAY TO KEEP MEMORY USAGE DOWN
            let origChecksLength = checks.length
            checks = checks.filter(rec => moment(rec.createdAt) > moment().subtract(24, 'hours') && !rec.deleted)
            if (origChecksLength != checks.length) {
                setChecks(checks)
            }
            filteredChecks = []

            if (todaySearchFilter) {
                setShowAll(true)
                setSelectedTab("")
                let todaySearchFilterUpperCase = todaySearchFilter.toUpperCase()
                todaySearchFilterUpperCase = todaySearchFilterUpperCase.replace(/-/g, "").replace(/\./g, "")
                filteredChecks = checks.filter(rec => {
                    return (
                        (rec.checkNumber && rec.checkNumber.toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                        || (rec.orderNumber && rec.orderNumber.toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                        || (rec.tableNumber && rec.tableNumber.toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                        || (rec.totals.total && rec.totals.total.toString().toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                        || (rec.pickupDetails && rec.pickupDetails.guestName && rec.pickupDetails.guestName.toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                        || (rec.pickupDetails && rec.pickupDetails.phoneNumber && rec.pickupDetails.phoneNumber.replace(/-/g, "").replace(/\./g, "").toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                    )
                })
            } else if (checkTypeFilter != "all") {
                setShowAll(false)
                if (checkTypeFilter == 'toGo') {
                    filteredChecks = checks.filter(rec => rec.isToGo)
                } else if (checkTypeFilter == 'dineIn') {
                    filteredChecks = checks.filter(rec => !rec.isToGo)
                }
                if (selectedTab == "") {
                    setSelectedTab(localStorage.tabSelected) // sets it back to pre cleared value
                } else if (checkTypeFilter != localStorage.checkTypeFilter) {
                    setSelectedTab('ordered')
                }
            } else {
                setShowAll(true)
                filteredChecks = checks
            }


            setupColumns()
            //REMOVE CHECKS OLDER THAN 12 HOURS FROM THE UI
            filteredChecks = filteredChecks.filter(rec => moment(rec.createdAt) > moment().subtract(12, 'hours'))
            // filteredChecks = filteredChecks.map(rec => { 
            //     rec.payments = rec.payments ? rec.payments.filter(pmt => pmt.status == 'captured') : []
            //     return rec
            // })

            setFilteredChecks(filteredChecks)
            localStorage.setItem('checkTypeFilter', checkTypeFilter)
        }
    }, [checks, checkTypeFilter, todaySearchFilter, selectedTab, showTabs, showOrderedColumn, showOrderReadyColumn]);


    const setupColumns = () => {
        let orderedColShow = (textToPayEnabled && checkTypeFilter == "toGo") || (restaurant && restaurant.backOffice && qrCodeEnabled)
        let paidColShow = !orderUpShowOrderReady
        setShowOpenColumn(!paidColShow)
        setShowOrderedColumn(orderedColShow && paidColShow)
        setShowPaidColumn(paidColShow)
        setShowOrderReadyColumn(orderUpShowOrderReady)
        setShowArrivedColumn(checkTypeFilter == "toGo" && imHereEnabled)
        setShowClosedColumn((checkTypeFilter == "dineIn" && (qrCodeEnabled || (smallScreen && !textToPayEnabled && orderUpEnabled))) || (checkTypeFilter == "toGo" && smallScreen))

        // setShowOrderedColumn( ((textToPayEnabled && checkTypeFilter == "toGo") || (restaurant && restaurant.backOffice) && (!showTabs || selectedTab == "ordered")))
        // setShowPaidColumn((!showTabs || selectedTab == "paid"))
        // setShowArrivedColumn(checkTypeFilter == "toGo" && imHereEnabled && (!showTabs || selectedTab == "arrived"))
        // setShowClosedColumn(checkTypeFilter == "dineIn" && (!showTabs || selectedTab == "completed"))
    }

    const setDefaultCheckTypeFilter = () => {
        let checkTypeFilterTmp = ''
        let availableFilters = []
        if (textToPayEnabled) {
            if (!hasPosIntegration) {
                availableFilters = ['toGo']
            } else {
                availableFilters.push('toGo', 'all')
            }
        }
        if ((qrCodeEnabled || orderUpEnabled) && hasPosIntegration) { //TODO i dont think this works for print only
            availableFilters.push('dineIn')
            if (availableFilters.indexOf('all') == -1) availableFilters.push('all')
        }
        checkTypeFilterTmp = (localStorage.checkTypeFilter != undefined && localStorage.checkTypeFilter != 'undefined') ? localStorage.checkTypeFilter :'toGo'
        if (availableFilters.indexOf(checkTypeFilterTmp) == -1) {
            checkTypeFilterTmp = availableFilters[0]
        }
        if (checkTypeFilterTmp == 'all' && !todaySearchFilter) checkTypeFilterTmp = availableFilters.find(f=> f != 'all')
        global.set({ checkTypeFilter: checkTypeFilterTmp })
    }


    useEffect(() => {
        setOrderedChecks(filteredChecks.filter((rec) => { return rec.status == 'ordered' && (!showOrderReadyColumn || (showOrderReadyColumn && !rec.pickupDetails.orderUpAt))}))
        setPaidChecks(filteredChecks.filter((rec) => { return rec.status == 'paid' && (!showOrderReadyColumn || (showOrderReadyColumn && !rec.pickupDetails.orderUpAt))}))
        if (showOrderReadyColumn) setOrderReadyChecks(filteredChecks.filter(c => c.pickupDetails.orderUpAt && !c.pickupDetails.pickedUpAt))
        setArrivedChecks(filteredChecks.filter((rec) => { return rec.status == 'arrived' }))
        setCompletedChecks(filteredChecks.filter((rec) => { return rec.status == 'completed'}))
    }, [filteredChecks])

    useEffect(() => {
        setOpenChecks(paidChecks.concat(orderedChecks))
    }, [paidChecks, orderedChecks])

    useEffect(() => {
        setDefaultCheckTypeFilter()
    }, [restaurant, textToPayEnabled, hasPosIntegration, qrCodeEnabled])

    useEffect(() => {
        if (selectedTab != "") {
            localStorage.setItem('tabSelected', selectedTab)
        }
    }, [selectedTab]);

    useEffect(() => {
        let numOfCols = 0
        if (showOrderedColumn) numOfCols++
        if (showPaidColumn) numOfCols++
        if (showOrderReadyColumn) numOfCols++
        if (showArrivedColumn) numOfCols++
        if (showOpenColumn) numOfCols++
        if (showClosedColumn) numOfCols++
        setNumberOfColumns(numOfCols)
	}, [showArrivedColumn, showClosedColumn, showOrderedColumn, showPaidColumn, showOrderReadyColumn, showOpenColumn])

    useEffect(()=>{
        if(restaurant && checks){
            setIsLoading(false)
        } else {
            setIsLoading(true)
        }
        console.log('in useEffect - restaurant', restaurant)
        console.log('in useEffect - checks', checks)
        console.log('is loading', isLoading)
    },[restaurant, checks])


	const setcheckTypeFilter = (newValue) => {
        if (newValue) global.set({ checkTypeFilter: newValue }); 
    }

    const showLegend = restaurant && restaurant.backOffice && (textToPayEnabled || qrCodeEnabled)
    const legendHeight = !showLegend ? '0px' : '50px'

    
    if(isLoading){
        return <div style={{width:'100%', height:'100%'}}><CircularProgress style={{position: 'absolute', top:'50%', left:'50%', height:'100px', width:'100px', marginTop:'-50px', marginLeft:'-50px', opacity:'20%'}}/></div>
    }

    const showCheckTypeToggles = (!todaySearchFilter) && (textToPayEnabled && qrCodeEnabled)
    const showManualCheckEntry = textToPayEnabled && !hasPosIntegration && checkTypeFilter == 'toGo'
    const showPickedUp = checkTypeFilter == "toGo" || (orderUpEnabled && checkTypeFilter == "dineIn" && !showClosedColumn)
    const pickedUpHeight = showPickedUp ? "50px" : '0px'


	return (
        <Grid container style={{ height:"100%", padding: isMobile ? 5 : 10, paddingTop:5}}>
            {restaurant && checks && <>
                <Grid item xs={12} container style={{ width:'100%', overflow: 'none', padding: 5}}>
                    {showSearch && !showManualCheckEntry && 
                        <Grid item xs style={{paddingRight:!showCheckTypeToggles? 0 :isMobile ? 5 : 10}}>
                            <TodaySearch />
                        </Grid>
                    }
                    { showManualCheckEntry && 
                        <Grid item xs style={{ paddingRight:!showCheckTypeToggles? 0 :isMobile ? 5 : 10}}>
                            <ManualCheckEntry/>
                        </Grid>
                    }
                    {showCheckTypeToggles &&
                        <Grid item>
                            <Paper>
                                <ToggleButtonGroup exclusive size="small" value={checkTypeFilter} onChange={(e, value) => setcheckTypeFilter(value)} style={{height: showManualCheckEntry? 64 : 44}}>
                                    <ToggleButton key={0} value={'all'} style={{height: '100%', paddingLeft: isMobile ? 10 : 20, paddingRight: isMobile ? 10 : 20, borderTop: 'none', borderBottom: 'none', borderLeft: 'none'}}>All</ToggleButton>
                                    {qrCodeEnabled && <ToggleButton key={1} value={'dineIn'} style={{height: '100%', paddingLeft: isMobile ? 10 : 20, paddingRight: isMobile ? 10 : 20, borderTop: 'none', borderBottom: 'none'}}>Dine-In</ToggleButton>}
                                    {textToPayEnabled && <ToggleButton key={2} value={'toGo'} style={{height: '100%', paddingLeft: isMobile ? 10 : 20, paddingRight: isMobile ? 10 : 20, borderTop: 'none', borderBottom: 'none', borderRight: 'none'}}>To-Go</ToggleButton>}
                                </ToggleButtonGroup>
                            </Paper>
                        </Grid>
                    }
                </Grid>


                

                <Grid container style={{ width:'100%', maxHeight:'100%', height: `calc(100% - 50px ${!showLegend ? " - 20px":""})`, overflow: 'none', }} >

                    {(smallScreen || showAll) && <>
                        <Grid item xs={12} container direction='row' style={{ width: '100%', height: smallScreen ? 'calc(100% - 70px)' : 'calc(100% - 40px)', minHeight: smallScreen ? 'calc(100% - 40px)' : '0', margin: 0 }}>
                            <Grid item xs={12} style={{ height: '100%', width: '100%', padding: 5}} >
                                <Paper className={classes.paper}>
                                    {showTabs && !todaySearchFilter && checkTypeFilter != "all" &&
                                        <Tabs style={{width: '100%', borderBottom: 'solid 1px #DDD'}}
                                            // style={{ maxHeight: '3.5em', minHeight: '2.7em'}}
                                            value={selectedTab}
                                            variant="fullWidth"
                                            indicatorColor="primary"
                                            TabIndicatorProps={{
                                                style: {
                                                    backgroundColor: selectedTab == 'ordered' ? 'var(--dash-orange)' : selectedTab == 'arrived' ? '#9fa8da' : selectedTab == 'paid' ? 'var(--dash-purple)' : 'var(--dash-gray)',
                                                    height: 3,
                                                }
                                            }}
                                            onChange={(e, value) =>{
                                                setSelectedTab(value)
                                            }}
                                        >
                                            {showOrderedColumn && <Tab label="Ordered" value="ordered" />}
                                            {showPaidColumn && <Tab label={!showArrivedColumn ? <Badge classes={{badge:classes.badge}} color="primary" badgeContent={paidChecks.length > 0 ? paidChecks.length : null}>Paid</Badge>: "Paid"} value={"paid"} />}
                                            {showOpenColumn && <Tab label={"Open"} value={"open"} />}
                                            {showOrderReadyColumn && <Tab label={"Notified"} value={"orderReady"} />}
                                            {showArrivedColumn && <Tab label={<Badge classes={{badge:classes.arrivedbadge}} color="primary" badgeContent={arrivedChecks.length > 0 ? arrivedChecks.length : null}>Arrived</Badge>} value="arrived" />}
                                            {showClosedColumn && <Tab label={checkTypeFilter == 'toGo' || showOrderReadyColumn ? "Picked Up" : "Closed"} value="completed" />}
                                        </Tabs>
                                    }
                                    <Grid container className={`${classes.scrolling} ${classes.checks}`} style={{paddingTop: 10}}>
                                        {filteredChecks && filteredChecks.filter((rec) => { return selectedTab == "" || checkTypeFilter == "all" || rec.status == selectedTab || (selectedTab == "open" && !rec.closed && (!showOrderReadyColumn || (showOrderReadyColumn && !rec.pickupDetails.orderUpAt))) || (selectedTab == "orderReady" && rec.getVal('pickupDetails.orderUpAt') && !rec.getVal('pickupDetails.pickedUpAt'))}).map((data, idx) => 
                                            <Grid item xs={12} md={6} lg={4} style={{paddingLeft: !isMobile && 5 , paddingRight: !isMobile && 5 }} onClick={() => showCheckDetails(data)}>
                                                <DashCard data={data} hideTopBorder={checkTypeFilter == "all" || idx > 0 || smallScreen && idx == 0}/>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </>}


                    {!showAll && !smallScreen && <>
                        <Grid item lg={12} md={12} container direction='row' style={{ width: '100%', height: smallScreen ? 'auto' : `calc(100% - ${pickedUpHeight} - ${legendHeight})`, minHeight: smallScreen ? `calc(100% - ${pickedUpHeight} - ${legendHeight})` : '0', margin: 0 }}>
                            {showOrderedColumn && 
                                <Grid item md={12/numberOfColumns} lg={12/numberOfColumns} style={{ height: smallScreen ? 'auto' : '100%', width: '100%', padding: 5, paddingRight: !smallScreen? 10 : 5}} >
                                    <Paper className={classes.paper} style={{borderTop: 'solid 4px var(--dash-orange)',}}>
                                        <Grid item lg={12} className={classes.iconWrapper} justify="center">
                                            <Typography style={{ paddingTop: 2, paddingLeft:3 }} display="inline" align="center" variant="h5">Ordered</Typography>
                                        </Grid>
                                        <Grid container className={`${smallScreen ? '' : classes.scrolling} ${classes.checks}`}>
                                            {orderedChecks && orderedChecks.map((data, idx) => 
                                                <Grid item lg={12} style={{ width:'100%' }} onClick={() => showCheckDetails(data)}>
                                                    <DashCard data={data} hideTopBorder={idx > 0 || smallScreen && idx == 0}/>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Paper>
                                </Grid>
                            }{showPaidColumn &&
                                <Grid item md={12/numberOfColumns} lg={12/numberOfColumns} style={{ width: '100%', maxHeight: '100%', padding: 5 }} >
                                    <Paper className={classes.paper} style={{borderTop: 'solid 4px var(--dash-purple)'}}>
                                        <Grid item lg={12} className={classes.iconWrapper} justify="center" >
                                            <Typography style={{ paddingTop: 2, paddingLeft:3 }} display="inline" align="center" variant="h5">{"Paid"}</Typography>
                                        </Grid>
                                        <Grid container className={`${smallScreen ? '' : classes.scrolling} ${classes.checks}`} >
                                            {paidChecks && paidChecks.map((data, idx) => 
                                                <Grid item lg={12} style={{ width:'100%' }} onClick={() => showCheckDetails(data)}>
                                                    <DashCard data={data} hideTopBorder={idx > 0 || smallScreen && idx == 0}/>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Paper>
                                </Grid>
                            }{showOpenColumn &&
                                <Grid item md={12/numberOfColumns} lg={12/numberOfColumns} style={{ width: '100%', maxHeight: '100%', padding: 5 }} >
                                    <Paper className={classes.paper} style={{borderTop: 'solid 4px var(--dash-purple)'}}>
                                        <Grid item lg={12} className={classes.iconWrapper} justify="center" >
                                            <Typography style={{ paddingTop: 2, paddingLeft:3 }} display="inline" align="center" variant="h5">Open</Typography>
                                        </Grid>
                                        <Grid container className={`${smallScreen ? '' : classes.scrolling} ${classes.checks}`} >
                                            {openChecks && openChecks.map((data, idx) => 
                                                <Grid item lg={12} style={{ width:'100%' }} onClick={() => showCheckDetails(data)}>
                                                    <DashCard data={data} hideTopBorder={idx > 0 || smallScreen && idx == 0}/>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Paper>
                                </Grid>
                            }{showOrderReadyColumn &&
                                <Grid item md={12/numberOfColumns} lg={12/numberOfColumns} style={{ width: '100%', maxHeight: '100%', padding: 5 }} >
                                    <Paper className={classes.paper} style={{borderTop: 'solid 4px #9fa8da'}}>
                                        <Grid item lg={12} className={classes.iconWrapper} justify="center" >
                                            <Typography style={{ paddingTop: 2, paddingLeft:3 }} display="inline" align="center" variant="h5">Notified</Typography>
                                        </Grid>
                                        <Grid container className={`${smallScreen ? '' : classes.scrolling} ${classes.checks}`} >
                                            {orderReadyChecks && orderReadyChecks.map((data, idx) => 
                                                <Grid item lg={12} style={{ width:'100%' }} onClick={() => showCheckDetails(data)}>
                                                    <DashCard data={data} hideTopBorder={idx > 0 || smallScreen && idx == 0}/>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Paper>
                                </Grid>
                            }
                            {showArrivedColumn &&
                                <Grid item md={12/numberOfColumns} lg={12/numberOfColumns} style={{ width: '100%', maxHeight: '100%', padding: 5, paddingLeft: 10 }} >
                                    <Paper className={classes.paper} style={{borderTop: 'solid 4px #9fa8da'}}>
                                        <Grid item lg={12} className={classes.iconWrapper} justify="center" >
                                            <Typography style={{ paddingTop: 2, paddingLeft:3 }} display="inline" variant="h5">Arrived</Typography>
                                        </Grid>
                                        <Grid container className={`${smallScreen ? '' : classes.scrolling} ${classes.checks}`} >
                                            {arrivedChecks && arrivedChecks.map((data, idx) =>
                                                <Grid item lg={12} style={{ width:'100%' }} onClick={() => showCheckDetails(data)}>
                                                    <DashCard data={data} hideTopBorder={idx > 0 || smallScreen && idx == 0}/>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Paper>
                                </Grid>
                            }

                            {showClosedColumn &&
                                <Grid item md={12/numberOfColumns} lg={12/numberOfColumns} style={{ width: '100%', maxHeight: '100%', padding: 5, paddingLeft: 10 }} >
                                    <Paper className={classes.paper} style={{borderTop: 'solid 4px var(--dash-gray)'}}>
                                        <Grid item lg={12} className={classes.iconWrapper} justify="center" >
                                            <Typography style={{ paddingTop: 2, paddingLeft:3 }} display="inline" variant="h5">Closed</Typography>
                                        </Grid>
                                        <Grid container className={`${smallScreen ? '' : classes.scrolling} ${classes.checks}`} >
                                            {completedChecks && completedChecks.map((data, idx) =>
                                                <Grid item lg={12} style={{ width:'100%' }} onClick={() => showCheckDetails(data)}>
                                                    <DashCard data={data} hideTopBorder={idx > 0 || smallScreen && idx == 0}/>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Paper>
                                </Grid>
                            }

                        </Grid>
                        
                        {showPickedUp && 
                            <Grid container item md={12} lg={12} style={{ width: '100%', padding: 5, paddingBottom:3}} alignItems='center'  >
                                <Paper style={{ width:'100%'}}>
                                    <Grid container direction="row" align="center" style={{ padding: 10, cursor: 'pointer' }} justify="center" onClick={() => { setDrawerOpen(!drawerOpen) }}>
                                        <Typography style={{ clear: 'both' }} display="inline" align="center" variant="h5">Picked Up</Typography>
                                        <ExpandMoreIcon style={{ marginTop: 3 }} />
                                    </Grid>
                                    <Drawer anchor={'bottom'} open={drawerOpen} onClose={() => { setDrawerOpen(!drawerOpen) }}>
                                        <Grid container direction="row" align="center" onClick={() => { setDrawerOpen(!drawerOpen) }} style={{ padding: 10 }} justify="center">
                                            <CheckCircleIcon />
                                            <Typography style={{ clear: 'both' }} display="inline" align="center" variant="h5">Picked Up</Typography>
                                            <ExpandLessIcon style={{ marginTop: 3 }} />
                                        </Grid>
                                        <TransactionListing callAhead={checkTypeFilter == "toGo"} simple={true} />
                                    </Drawer>
                                </Paper>
                            </Grid>
                        }
                    </>}
                    {showLegend && 
                        <Grid container item md={12} lg={12} style={{ width: '100%', padding: !smallScreen? 5 : 0, paddingBottom: 0, paddingTop:0}} alignContent="flex-start">
                            <Grid container item lg={12} direction="row" style={{marginTop: 5}} alignItems='center' >
                                <div style={{borderRadius: '9999px', width: '12px', height: '12px', margin:5, border: `2px solid #a3a3a3`}}/>
                                <Typography variant="subtitle1">Not Paid</Typography>
                                <div style={{borderRadius: '9999px', width: '12px', height: '12px', margin:5, marginLeft: 15, backgroundColor:'#a3a3a3'}}/>
                                <Typography variant="subtitle1">Paid {!smallScreen && '(Direct)'}</Typography>
                                <div style={{borderRadius: '9999px', width: '12px', height: '12px', margin:5, marginLeft: 15, backgroundColor:'var(--dash-purple)'}}/>
                                <Typography variant="subtitle1">Paid (Dash Now)</Typography>
                            </Grid>
                        </Grid>
                    }

                    <Grid item lg={12} >
                        {dialogContent}
                    </Grid>
                </Grid>
            </>
            }
        </Grid>
	);
};