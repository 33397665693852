import React, { useState, useEffect, useRef } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Select, MenuItem, TextField, Popper} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import getRestaurantList from "../../actions/getRestaurantListApi";
import history from '../../history';
// import openRestaurantWebSocket from '../../actions/openRestaurantWebSocket';
import _ from 'lodash'
import accessUtils from '../../utils/accessUtils';
import log from 'loglevel';

const useStyles = makeStyles((theme) => ({
    underline: {
        paddingTop: 5,
        "&&&:before": {
          borderBottom: "none"
        },
        "&&:after": {
          borderBottom: "none"
        }
    },
    input: {
        fontFamily: 'Roboto',
        color: theme.palette.text.primary
    }
}))

export default function RestaurantSelector(props) {

    const classes = useStyles();
    let [restaurant] = global.get('restaurant');
    let [restaurants] = global.get('restaurants', []);
    let [restaurantId] = global.get('restaurantId')
    let [checks] = global.get('checks')
    let [refreshRestaurants] = global.get('refreshRestaurants')
    let [headerTitle] = global.get('headerTitle')
    let [loading, setLoading] = React.useState(true);
    let [navBar] = global.get('navBar')
    let isLoggedIn = accessUtils.isLoggedIn()

    const timerToClearSomewhere = useRef(false)

    const getCheckLookback = (restaurant) => {
        if (restaurant?.appName == 'TextPay') {
            let checkLookback = parseInt(localStorage.getItem('checkLookback'))
            return checkLookback ? checkLookback : 7
        } else {
            return 1
        }
    }

    const fetchData = async (forceRefresh = false) => {
        log.info("restaurantSelector restaurantId: " + (restaurantId ?? localStorage.lastSelectedRestaurantId))
        if (forceRefresh || (!restaurants || !restaurants.length)) {
            restaurants = await getRestaurantList(restaurantId ?? localStorage.lastSelectedRestaurantId, navBar == 'admin')
            global.set({restaurants})
            
            //IF THE USER IS LOGGED IN AND THERE IS NO RESTAURANT SELECTED, FORCE TO THE SETUP SCREENS
            if (isLoggedIn && (!restaurants || restaurants.length == 0)) {
                history.push('/setup')
            }
        }
        if ((forceRefresh || !restaurant)) {
            let selectedRestaurant
            let restaurantIdParam = history.location.pathname && history.location.pathname.split('/') && history.location.pathname.split('/').length >= 4 ? history.location.pathname.split('/')[4] : '' 
            if (restaurants && restaurants.length > 0) {
                selectedRestaurant = restaurants[0] 
                let selectedRestaurant2
                if (restaurantIdParam == 'new') {
                    selectedRestaurant = null
                } else if (restaurantId) {
                    selectedRestaurant2 = _.find(restaurants, {_id: restaurantId})
                    if (selectedRestaurant2 == undefined) {
                        selectedRestaurant2 = null
                    }
                } else if (localStorage.lastSelectedRestaurantId) {
                    selectedRestaurant2 = _.find(restaurants, {_id: localStorage.lastSelectedRestaurantId})
                }
                if (selectedRestaurant2 != undefined) {
                    selectedRestaurant = selectedRestaurant2
                }
            } else {
                selectedRestaurant = null
            }
            global.set({checkLookback: getCheckLookback(selectedRestaurant), restaurant: selectedRestaurant, restaurantId: selectedRestaurant?._id})
        }
        setLoading(false)
	}

	const setupSelectedRestaurant = async () => {
        if (restaurant) {
            log.info("selectedRestaurant code " + restaurant?.code + " id: " + restaurant?._id)
            if(restaurant?._id && restaurantId && (restaurant?.code == null || restaurant?.code == undefined)) {
                await fetchData(true)
            }
            localStorage.lastSelectedRestaurantId = restaurant ? restaurant._id : null

            const textToPayEnabled = restaurant.getVal('config.features.textToPay.enabled', false)
            const textOnHoldEnabled = restaurant.getVal('config.features.textToPay.textOnHold', false)
            const emailToPayEnabled = restaurant.getVal('config.features.textToPay.emailToPay', false)
            const payAtTableEnabled = restaurant.getVal('config.features.payAtTable.enabled', false)
            const feedbackEnabled = restaurant.getVal('config.features.feedback.enabled', false)
            const imHereEnabled = restaurant.getVal('config.features.imHere.enabled', false)
            const orderUpEnabled = restaurant.getVal('config.features.orderUp.enabled', false)
            const orderUpShowOrderReady = orderUpEnabled && restaurant.getVal('config.features.orderUp.showOrderReadyColumn', false)
            const orderTerminology = restaurant.getVal('config.features.textToPay.orderTerminology', "Order")
            const showCustomerAccountName = restaurant.getVal('config.features.textToPay.showCustomerAccountName', false)
            const checkInEnabled = restaurant.getVal('config.features.paging.enabled', false)
            const audibleAlert = restaurant.getVal('config.features.imHere.audibleAlert', false)
            const audibleAlertInterval = restaurant.getVal('config.features.imHere.audibleAlertInterval', 0)
            const qrCodeEnabled = restaurant.getVal('qrCodeEnabled', false)
            const hasPosIntegration = restaurant.getVal('posType.supported', false)
            const allowPartialPayments = restaurant.getVal('config.mobile.allowPartialPayments', false)
            const showNonTextpayChecks = restaurant.getVal('config.features.textToPay.showNonTextpayChecks', false)
            const showPendingChecks = restaurant.getVal('config.features.textToPay.alwaysHold', false)
            const paymentLinksEnabled = restaurant.getVal('config.features.textToPay.paymentLinks', false)
            const displayItemName = restaurant.getVal('config.mobile.displayItemName', false)
            const displayItemDescription = restaurant.getVal('config.mobile.displayItemDescription', false)
            const displayItemModifiers = restaurant.getVal('config.mobile.displayItemModifiers', false)

            global.set({
                textToPayEnabled,
                payAtTableEnabled,
                feedbackEnabled,
                imHereEnabled,
                orderUpEnabled,
                checkInEnabled,
                emailToPayEnabled,
                showCustomerAccountName,
                audibleAlert,
                audibleAlertInterval,
                appName: restaurant.appName,
                paymentLinksEnabled,
                orderTerminology,
                hasPosIntegration,
                qrCodeEnabled,
                textOnHoldEnabled,
                allowPartialPayments,
                orderUpShowOrderReady,
                showNonTextpayChecks,
                showPendingChecks,
                displayItemName,
                displayItemDescription,
                displayItemModifiers
            })

            //IF THE RESTAURANT HASN'T BEEN INSTALLED, FORCE IT TO THE SETUP SCREENS
            if (restaurant.status == 'hyfin') {
                history.push('/payments')
            } else if (restaurant.status != 'live' && restaurant.status != 'cancelled') {
                if (navBar != 'admin' && (restaurant.appName != "DashNow") && window.location.pathname.indexOf("/siteListing") != 0) {
                    history.push('/setup')
                }
            } else {
                if (window.location.pathname.indexOf("/setup") == 0) {
                    history.push('/today')
                }
            }
        } else if (restaurants != undefined) {
            if (navBar != 'admin') {
				delete localStorage.lastSelectedRestaurantId
                history.push('/setup')
            }
        }
    }
    
    useEffect(() => {
        setupSelectedRestaurant()
    }, [restaurant]);
    
    useEffect(() => {
        setLoading(true)
        fetchData()

        return () => {
			setTimeout(() => clearInterval(timerToClearSomewhere.current), 15000)
		}
    }, []);

    useEffect(() => {
        if (refreshRestaurants) {
            fetchData(true)
            global.set({'refreshRestaurants': false})
        }
    }, [refreshRestaurants]);

    useEffect(() => {
		timerToClearSomewhere.current = setInterval(() => {
			global.set({ 'checks': checks })
		}, 60000); //Reload checks into state to receive updates
		return () => {
			clearInterval(timerToClearSomewhere.current)
		}	
    },[checks])


	if (global.hasPermission('admin.*')) {
		document.addEventListener("keydown", function(e) {
			if ((e.key == "p" || e.key == "o") && (window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
              e.preventDefault();
              document.getElementById("select-site").value = '';
              document.getElementById("select-site").focus();
			}
		}, false);
    }

    return (
        <Autocomplete
            id="select-site"
            size="small"
            disableClearable
            blurOnSelect
            openOnFocus
            disablePortal
            fullWidth
            autoComplete={false}
            clearText="Select a Site"
            style={{marginTop: 6}}
            // className={classes.restaurantSelect}
            options={restaurants ? restaurants.sort((a, b) => -b.friendlyName.localeCompare(a.friendlyName)) : []}
            // groupBy={(option) => option.restaurantGroup ? option.restaurantGroup.name : option.friendlyName}
            getOptionLabel={(option) => option.friendlyName}
            getOptionSelected = {(optionToEval, valueToEval) => optionToEval === valueToEval}
            value={restaurant ?? ''}
            onChange={(e, v) => { global.set({checkLookback: getCheckLookback(v), checks: null, restaurant: v, restaurantId: v._id}) }}
            renderInput={(params) => <TextField {...params} InputProps={{...params.InputProps, classes}}/>}
            inputProps={{
                onfocus: "this.type='password'",
                autocomplete: "new-password",
                role: "presentation",
                form: {
                    autocomplete: 'off',
                },
                className: classes.input
            }}
        />
    )
}
