import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, Paper, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';
import theme from '../../theme'

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 370,
    },
    tableHeader: {
        fontSize: '12px',
        lineHeight:1.1
    },
    cell: {
        border:0,
        textAlign:'right',
        padding:0,
        paddingTop:6,
        paddingBottom:6
    },
    cellLeft: {
        paddingTop:6,
        paddingBottom:6,
        paddingLeft:16,
    },
    sectionHeader: {
        fontSize: '16px',
        padding: '6px 8px 8px 8px'
    },
});

export default function PaymentsTable(props) {
    const classes = useStyles();
    let check = props.data
    let payments = check.payments ? check.payments : []
    const [showFailedPayments, setShowFailedPayments] = React.useState(false)
    const [restaurant] = global.get('restaurant')
    const allowTips = global.f(restaurant, 'config.mobile.allowTips')

    const xsSmallScreen = useMediaQuery(theme().breakpoints.down('xs'));

    const showLoyalty = props.showLoyalty

    const col1Width = '30%'
    let numColumns = 3
    if (showLoyalty) numColumns++
    if (allowTips) numColumns = numColumns+2
    const equalColWidth = `${70 / numColumns}%`


    let applyToCheckTotal = 0
    let paymentTotal = 0
    let tipTotal = 0
    let loyaltyTotal = 0
    let refundTotal = 0

    const productName = global.isTextPay() ? "TextPay" : "DashNow"


    return (
        <Paper style={{height:'100%'}}>
            <div  style={{display:'flex', flexDirection:'column', justifyContent:'space-between', height:'100%'}}>
                <Typography className={classes.sectionHeader}>
                    Payments
                </Typography>

                <div style={{height:'100%'}}>
                    <div style={{color:'var(--dark-gray)'}}>
                        <Table size="small"> 
                            <TableHead>
                                <TableRow>
                                    <TableCell classes={{root: classes.tableHeader}} style={{paddingRight:0, width:col1Width}}>
                                        Type
                                    </TableCell>
                                    
                                    {allowTips &&
                                        <>
                                            <TableCell align="right" classes={{root: classes.tableHeader}} style={{paddingRight:0, width: equalColWidth}}>
                                                Apply To Check
                                            </TableCell>

                                            <TableCell align="right" classes={{root: classes.tableHeader}} style={{paddingRight:0, width: equalColWidth}}>
                                                Tip Amt
                                            </TableCell>
                                        </>
                                    }

                                    {showLoyalty &&
                                        <TableCell align="right" classes={{root: classes.tableHeader}} style={{paddingRight:0, width: equalColWidth}}>
                                            Loyalty
                                        </TableCell>
                                    }

                                    <TableCell align="right" classes={{root: classes.tableHeader}} style={{paddingRight:16, width: equalColWidth}}>
                                        Total Paid
                                    </TableCell>
                                    {/* <TableCell align="right" classes={{root: classes.tableHeader}} style={{paddingRight:0, width: equalColWidth}}>
                                    </TableCell> */}
                                </TableRow>
                            </TableHead> 
                        </Table>

                        <div style={{height: xsSmallScreen ? 'auto' : props.fullHeight? '350px' : '100px', overflow:'auto'}}>
                        {/* <div style={{height: xsSmallScreen ? 'auto' : props.fullHeight? '374px' : '170px', overflow:'auto'}}> */}
                            <Table style={{margin:0}}>
                                <TableBody style={{ width: '100%'}}>
                                    {payments.sort(p => p.successful).map((payment, index) => {
                                        let applyToCheck = (payment.paymentAmount - payment.tipAmount)
                                        if (payment.successful) {
                                            applyToCheckTotal += applyToCheck
                                            tipTotal += payment.tipAmount
                                            tipTotal += payment.autoTipAmount ? payment.autoTipAmount : 0
                                            paymentTotal += payment.paymentAmount
                                            loyaltyTotal += payment.loyaltyRewardsAmount
                                            refundTotal += payment.refundAmount
                                            paymentTotal -= payment.refundAmount
                                        } 
                                        if (index === payments.length -1) {
                                            if (applyToCheckTotal - refundTotal <= 0) {
                                                applyToCheckTotal = 0;
                                                loyaltyTotal += 0;
                                                tipTotal = 0;
                                            } else {
                                                applyToCheckTotal -= refundTotal
                                            }
                                        }
                                        const loyaltyAmount = payment.loyaltyRewardsAmount ? payment.loyaltyRewardsAmount : ''
                                        const isRefund = payment.refundAmount > 0 || (payment.status && payment.status.toLowerCase().indexOf('refund') > -1)
                                        if (!payment.successful || payment.paymentAmount > 0 || isRefund) {
                                            return (
                                                <TableRow key={payment.name} style={{borderBottom: payments.length > 1 && '1px solid var(--divider)', backgroundColor:payment.successful && !isRefund ? 'none' : 'var(--background)'}}>
                                                    <TableCell align="left" style={{paddingTop:6, paddingBottom:6, border:0, width:col1Width, paddingRight:0}}>

                                                        {payment.fromDash ? payment.successful ? `${productName} ${isRefund ? 'Refund' : ''}` : 'FAILED' : global.isTextPay() ? 'External' : global.f(payment, 'card.type', 'Card')}
                                                        <span style={{fontSize:'12px', color:'gray'}}> {moment(payment.createdAt).format('MM/DD hh:mm a') + ' '}</span>
                                                        
                                                        {payment.fromDash && payment.status == "captured" && global.hasPermission('restaurant.refund') &&
                                                                <Button variant="contained" color="primary" size="small" style={{flexDirection:'column', justifyContent:'center', alignItems:'center'}}
                                                                    style={{height:'20px', fontSize:'10px'}}
                                                                    onClick={() => props.showRefundWindow(check, payment)}
                                                                >
                                                                    <span style={{justifyContent:"center"}}>Refund</span>
                                                                </Button>
                                                        }

                                                    </TableCell>

                                                    {!payment.successful &&<>
                                                        <TableCell colSpan={numColumns} align="left" className={classes.cellLeft} style={{textAlign:'left', width: `calc(${equalColWidth} * ${numColumns})`}}>REASON: {payment.userMessage ? payment.userMessage : "N/A" }</TableCell>
                                                    </>}
                                                    {payment.successful && isRefund && <>
                                                        <TableCell colSpan={numColumns - 1} align="left" className={classes.cellLeft} style={{textAlign:'left', borderBottom:'none', width: `calc(${equalColWidth} * ${numColumns-1})`}}>{payment.status.toUpperCase()}</TableCell>
                                                        <TableCell align="right" style={{width: equalColWidth, paddingRight:16}} className={classes.cell} >{isRefund && payment.refundAmount > 0? `-$${global.formatMoney(payment.refundAmount)}` : payment.paymentAmount ? `$${global.formatMoney(payment.paymentAmount)}` : 'N/A'}</TableCell>
                                                    </>}

                                                    {payment.successful && !isRefund &&<>
                                                        {allowTips &&
                                                            <>
                                                                <TableCell align="right" className={classes.cell} style={{paddingRight:0, width: equalColWidth}}>{isRefund ? '' : applyToCheck ? `$${global.formatMoney(applyToCheck)}` : 'N/A'}</TableCell>
                                                                <TableCell align="right" style={{width: equalColWidth}} className={classes.cell} >{isRefund ? '' : payment.tipAmount ?  `$${global.formatMoney(payment.tipAmount)}` : 'N/A'}</TableCell>
                                                            </>
                                                        }
                                                        {showLoyalty &&
                                                            <TableCell align="right" style={{width: equalColWidth, paddingRight:16}} className={classes.cell} >{isRefund ? '' : loyaltyAmount ?  `($${global.formatMoney(loyaltyAmount)})` : 'N/A'}</TableCell>
                                                        }

                                                        <TableCell align="right" style={{width: equalColWidth, paddingRight:16}} className={classes.cell} >{isRefund ? `-$${global.formatMoney(payment.refundAmount)}` : payment.paymentAmount ? `$${global.formatMoney(payment.paymentAmount)}` : 'N/A'}</TableCell>
                                                        {/* <TableCell align="left" style={{width: equalColWidth}} className={classes.cell} style={{ textAlign:'center'}}>
                                                            {!xsSmallScreen && payment.fromDash && payment.status == "captured" && global.hasPermission('restaurant.refund') &&
                                                                <Button variant="contained" color="primary" size="small" 
                                                                    style={{fontSize:'10px'}}
                                                                    onClick={() => props.showRefundWindow(check, payment)}
                                                                >
                                                                    Refund
                                                                </Button>
                                                            }
                                                        </TableCell> */}
                                                    </>}
                                                    
                                                </TableRow>
                                            );
                                        }
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </div>

                {/* TOTALS */}
                <div>
                    <Table style={{marginTop: 5, height:40}} size='small'>
                        <TableBody style={{height:'100%'}}>
                            <TableRow style={{borderTop: '1px solid var(--divider)', color: 'green'}}>
                                <TableCell style={{border:'0px', fontWeight:'800', width:col1Width}}>Total</TableCell>

                                {allowTips &&
                                    <>
                                        <TableCell align="right" style={{border:'0px', fontWeight:'500', paddingRight:0, width:equalColWidth}}>${global.formatMoney(applyToCheckTotal)}</TableCell>
                                        <TableCell align="right" style={{border:'0px', fontWeight:'500', paddingRight:0, width:equalColWidth}}>${global.formatMoney(tipTotal)}</TableCell>
                                    </>
                                }

                                {showLoyalty &&
                                    <TableCell align="right" style={{border:'0px', fontWeight:'500', paddingRight:0, width:equalColWidth}}>(${global.formatMoney(loyaltyTotal)})</TableCell>
                                }
                                
                                <TableCell align="right" style={{border:'0px', fontWeight:'500', paddingRight:16, paddingLeft:0, width:equalColWidth}}>${global.formatMoney(paymentTotal)}</TableCell>
                                {/* <TableCell align="right" style={{border:'0px', fontWeight:'500', paddingRight:0, width:equalColWidth}}></TableCell> */}
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </div>

        </Paper>
  );
}