import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, Paper, Tabs, Tab, Radio, RadioGroup, FormControlLabel, Checkbox, Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Input, MenuItem, TextField, Button} from '@material-ui/core';
import dashFetch from '../../utils/dashFetch';
import RestaurantListing from '../../admin/pages/restaurantListing'
import Support from '../../admin/pages/support'
import Billing from '../../admin/billing/billing'
import RolePermissionManager from '../../admin/pages/rolePermissionManager'
import Config from './configPage'
import ProductListing from '../../admin/pages/productListing'
import ProductPackageListing from '../../admin/pages/productPackageListing'
import ProductPackageGroupListing from '../../admin/pages/productPackageGroupListing'
import MerchantPricingListing from '../../admin/pages/merchantPricingListing'
import AddEditRestaurant from '../../admin/pages/addEditRestaurant';
import RestaurantGroups from '../../admin/pages/restaurantGroups';

import getProductListApi from "../../actions/getProductListApi";
import getProductPackageTypesApi from "../../actions/getProductPackageTypesApi";
import getSmsTemplateTypesApi from "../../actions/getSmsTemplateTypesApi";
import getProductPackageListApi from "../../actions/getProductPackageListApi";
import getProductPackageGroupListApi from "../../actions/getProductPackageGroupListApi";
import LocalActivityIcon from '@material-ui/icons/LocalActivity';

import AppsIcon from '@material-ui/icons/Apps';
import DashboardIcon from '@material-ui/icons/Dashboard';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import AccountTreeIcon from '@material-ui/icons/AccountTree';


import log from 'loglevel'
import { withRouter } from 'react-router-dom';
import SiteSignupList from './SiteSignupList';
import GatewayList from './GatewayList';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },
     
	addRestaurantIcon: {
		float: 'left',
		marginTop: 5,
		marginRight: 5,
		width: '20px',
		height: '20px'
	},
	addRestaurantButton: {
		float: 'right',
		marginLeft: 20,
		marginRight: 20,
		marginTop: 10,
		marginBottom: 10,
		width: 200,
		height: 45
    },
    paper: { width: '90%', margin: 20, padding: 20 },
    tabs: {
        width: '100%',
        background: 'var(--background)',
		width: 60,
		borderRight: 'solid 1px #CCC',
		height: '100%',
    }
})

const Admin = (props) => {
	const { classes } = props;
    // alert(JSON.stringify(props))
    const [navBar, setNavBar] = global.get('navBar')
    const [accountRoles, setAccountRoles] = useState([])
    const [refundReason, setRefundReason] = useState()
    const [activeSubTab, setActiveSubTab] = useState('PRODUCTS')
    const [refundPaymentId, setRefundPaymentId] = useState('')
    const [refundAmount, setRefundAmount] = useState('')
    const [voidPaymentId, setVoidPaymentId] = useState('')
    const [adjustPaymentId, setAdjustPaymentId] = useState('')
    const [adjustedAmount, setAdjustedAmount] = useState('')
    const [statusPaymentId, setStatusPaymentId] = useState('')
    let [activePages] = global.get('activePages')
    let activePage = activePages ? activePages.admin.replace('admin/', '') : ''

    console.log("activeSubTab.name: " + activeSubTab.name)

    let { page, subPage } = (props.match && props.match.params) ? props.match.params : props.props.match.params

    async function fetchdata() {
        await Promise.all([
            getProductListApi(), 
            getProductPackageListApi(), 
            getProductPackageGroupListApi(), 
            getProductPackageTypesApi(), 
            getSmsTemplateTypesApi()
        ]).then(results => {
            global.set({ 
                products: results[0],
                productPackages: results[1],
                productPackageGroups: results[2],
                productPackageTypes: results[3],
                smsTemplateTypes: results[4],
            })
        })
    }


	useEffect(() => {
        if (page == 'products') {
            fetchdata()
        }
        if (page == 'billing') {
            global.set({refreshRestaurants: true})
        }
        if (navBar != 'admin') setNavBar('admin')
        global.set({ headerTitle: 'ADMIN' })
    }, [page]);
    
    const refundPayment = async () => {
        let confirmation = 'Are you sure you want to refund this payment? ' + refundPaymentId
        if(refundAmount){
            confirmation += ' a partial payment of ' + refundAmount
        } else {
            confirmation += ' the full amount'
        }
        if (window.confirm(confirmation)) {
            let data = await dashFetch(`${global.dashServerUrl}/api/v1/payment/refund`, { paymentId: refundPaymentId, refundAmount, refundReason}, true);
            log.info('data ' + JSON.stringify(data))
            if(data && data.json){
                if(data.json.success && data.json.success == true && !data.json.errors){
                    alert("SUCCESSFULLY REFUNDED PAYMENT")
                } else if(data.json.errors) {
                    alert("ERROR " + JSON.stringify(data.json.errors))
                } else {
                    alert("ERROR")
                }
            } else {
                alert("ERROR NO DATA RETURNED: " + JSON.stringify(data))
            }
        }    
    };

    const voidPayment = async () => {
        if (window.confirm('Are you sure you want to void this payment? ' + voidPaymentId)) {
            let data = await dashFetch(`${global.dashServerUrl}/api/v1/payment/void`, { paymentId: voidPaymentId, refundReason}, true);
            log.info('data ' + JSON.stringify(data))
            if(data && data.json){
                if(data.json.success && data.json.success == true && !data.json.errors){
                    alert("SUCCESSFULLY VOIDED PAYMENT")
                } else if(data.json.errors) {
                    alert("ERROR " + JSON.stringify(data.json.errors))
                } else {
                    alert("ERROR")
                }
            } else {
                alert("ERROR NO DATA RETURNED: " + JSON.stringify(data))
            }
        }    
    };

    const adjustPayment = async () => {
        if(adjustedAmount){
            let confirmation = 'Are you sure you want to make a new payment for paymentId ' + adjustPaymentId + ' with an adjusted payment amount of ' + adjustedAmount
            if (window.confirm(confirmation)) {
                let data = await dashFetch(`${global.dashServerUrl}/api/v1/payment/makeAdjustedPayment`, { paymentId: adjustPaymentId, adjustedAmount, refundReason }, true);
                log.info('data ' + JSON.stringify(data))
                if(data && data.json){
                    if(data.json.success && data.json.success == true && !data.json.errors){
                        alert("SUCCESSFULLY ADJUSTED PAYMENT")
                    } else if(data.json.errors) {
                        alert("ERROR " + JSON.stringify(data.json.errors))
                    } else {
                        alert("ERROR")
                    }
                } else {
                    alert("ERROR NO DATA RETURNED: " + JSON.stringify(data))
                }
            }  
        } else {
            window.confirm('ERROR no adjustedAmount entered')
        }
          
    };

    const getPaymentStatus = async () => {
        if (statusPaymentId) {
            let data = await dashFetch(`${global.dashServerUrl}/api/v1/payment/getPaymentStatus`, { paymentId: statusPaymentId }, true)
            if(data && data.json){
                if(data.json.success && data.json.resp){
                    global.showNotification(JSON.stringify(data.json.resp.gatewayApiResponse))
                    // alert(JSON.stringify(data.json.resp.gatewayApiResponse))
                } else if(data.json.errors) {
                    alert("ERROR " + JSON.stringify(data.json.errors))
                } else {
                    alert("ERROR")
                }
            } else {
                alert("ERROR NO DATA RETURNED: " + JSON.stringify(data))
            }
        } else {
            window.confirm('ERROR no payment ID entered')
        }
    }

    const showRefund = () => {
        return (
            <Grid>
                <Paper className={classes.paper}>
                    <Grid container>
                        <Grid item lg={12} style={{ paddingBottom: 12 }}>
                            <Typography variant="h5">REFUND REASON:</Typography><Typography> notes about why this refund/void took place</Typography>
                        </Grid>
                        <Grid item lg={6} style={{ padding: "0 20px 10px 0" }}>
                            <Input
                                style={{ width: 300 }}
                                value={refundReason}
                                onChange={(event) => setRefundReason(event.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper className={classes.paper}>
                    <Grid container>
                        <Grid item lg={12} style={{paddingBottom: 12}}>
                            <Typography>REFUND: to be used AFTER the payment has processed</Typography>
                        </Grid>
                        <Grid container lg={12}>
                            <Grid item lg={6} style={{padding: "0 20px 10px 0"}}>
                                <Typography>Payment ID:</Typography>
                                <Input 
                                    style={{width: 300}}
                                    value={refundPaymentId}
                                    onChange={(event) => setRefundPaymentId(event.target.value)}
                                />
                            </Grid>
                            <Grid item lg={6} style={{padding: "0 20px 10px 0"}}>
                                <Typography>Refund Amount: No decimal - (Leave blank for full amount)</Typography>
                                <Input 
                                    style={{width: 300}}
                                    value={refundAmount}
                                    onChange={(event) => setRefundAmount(event.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item lg={12}>
                            <Button style={{ float: 'right' }} variant="contained" color="primary" disabled={!refundReason} onClick={refundPayment}>
                                Request Refund
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper className={classes.paper}>
                    <Grid container>
                        <Grid item lg={12} style={{paddingBottom: 12}}>
                            <Typography>VOID: to be used BEFORE the payment has processed</Typography>
                        </Grid>
                        <Grid container lg={12}>
                            <Grid item lg={12} style={{padding: "0 20px 10px 0"}}>
                                <Typography>Payment ID:</Typography>
                                <Input 
                                    style={{width: 300}}
                                    value={voidPaymentId}
                                    onChange={(event) => setVoidPaymentId(event.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item lg={12}>
                            <Button style={{ float: 'right' }} variant="contained" color="primary" disabled={!refundReason} onClick={voidPayment}>
                                Request Void
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper className={classes.paper}>
                    <Grid container>
                        <Grid item lg={12} style={{paddingBottom: 12}}>
                            <Typography>ADJUSTMENT: to be used AFTER the payment has been VOIDED to charge a different amount<br></br>(can not be used for mobile wallet payments)</Typography>
                        </Grid>
                        <Grid container lg={12}>
                            <Grid item lg={6} style={{padding: "0 20px 10px 0"}}>
                                <Typography>Payment ID:</Typography>
                                <Input 
                                    style={{width: 300}}
                                    value={adjustPaymentId}
                                    onChange={(event) => setAdjustPaymentId(event.target.value)}
                                />
                            </Grid>
                            <Grid item lg={6} style={{padding: "0 20px 10px 0"}}>
                                <Typography>Adjusted Amount: (No decimal)</Typography>
                                <Input 
                                    style={{width: 300}}
                                    value={adjustedAmount}
                                    onChange={(event) => setAdjustedAmount(event.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item lg={12}>
                            <Button style={{ float: 'right' }} variant="contained" color="primary" disabled={!refundReason} onClick={adjustPayment}>
                                Make Adjusted Payment
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper className={classes.paper}>
                    <Grid container>
                        <Grid item lg={12} style={{paddingBottom: 12}}>
                            <Typography>PAYMENT STATUS</Typography>
                        </Grid>
                        <Grid container lg={12}>
                            <Grid item lg={6} style={{padding: "0 20px 10px 0"}}>
                                <Typography>Payment ID:</Typography>
                                <Input 
                                    style={{width: 300}}
                                    value={statusPaymentId}
                                    onChange={(event) => setStatusPaymentId(event.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item lg={12}>
                            <Button style={{ float: 'right' }} variant="contained" color="primary" disabled={!statusPaymentId} onClick={getPaymentStatus}>
                                Get Payment Status
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }
    
    return (

            <div className={classes.root} style={{width: '100%', height: '100%', overflow: 'hidden'}}>
                {activePage && 
                    <Grid container style={{height: '100%', margin:'auto', overflow: 'hidden'}}>
                        <Grid item xs={12} container direction="column" alignItems="stretch" style={{height: '100%', overflow: 'hidden'}}>
                            {page == 'products' && 
                                
                                <div style={{width:'100%', height:'100%', textAlign:'center'}}>

                                    <Grid container spacing={0} style={{width: '100%', height: '100%'}}>
                                        <Grid item style={{width: 60}}>
                                            <Tabs
                                                orientation="vertical"
                                                value={activeSubTab}
                                                onChange={(e, n) => {
                                                    console.log('yay', n)
                                                    setActiveSubTab(n);
                                                }}
                                                className={classes.tabs}
                                                indicatorColor='primary'
                                            >	
                        
                                                {global.hasPermission('admin.*') &&
                                                    <Tooltip title="Products">
                                                        <Tab value="PRODUCTS" icon={<LocalActivityIcon color={(activeSubTab == "" || activeSubTab == "PRODUCTS") ? 'primary' : ''} />} style={{minWidth: 0}} />
                                                    </Tooltip>
                                                }
                        
                                                {global.hasPermission('admin.*') &&
                                                    <Tooltip title="Product Packages">
                                                        <Tab value="PACKAGES" icon={<LocalMallIcon color={activeSubTab == "PACKAGES" ? 'primary' : ''} />} style={{minWidth: 0}} />
                                                    </Tooltip>
                                                }
                        
                                                {global.hasPermission('admin.*') &&
                                                    <Tooltip title="Package Groups">
                                                        <Tab value="PACKAGE GROUPS" icon={<AccountTreeIcon color={activeSubTab == "PACKAGE GROUPS" ? 'primary' : ''} />} style={{minWidth: 0}} />
                                                    </Tooltip>
                                                }
                                                {global.hasPermission('admin.pricing.*') &&
                                                    <Tooltip title="Merchant Pricing">
                                                        <Tab value="MERCHANT PRICING" icon={<MonetizationOnIcon color={activeSubTab == "MERCHANT PRICING" ? 'primary' : ''} />} style={{minWidth: 0}} />
                                                    </Tooltip>
                                                }
                                            
                                            </Tabs>
                                        </Grid>
                                        <Grid container xs item style={{padding:14, height: '100%', overflowX: 'hidden', overflowY: 'auto'}}>
                                            {(activeSubTab == "" || activeSubTab == "PRODUCTS") && <ProductListing/>}
                                            {activeSubTab == "PACKAGES" && <ProductPackageListing/>}
                                            {activeSubTab == "PACKAGE GROUPS" && <ProductPackageGroupListing/>}
                                            {activeSubTab == "MERCHANT PRICING" && <MerchantPricingListing/>}
                                        </Grid>
                                    </Grid>
                                </div>
                            }

                            <Grid item container xs style={{height: '100%'}} >
                                {page == 'restaurants' && subPage == 'restaurantListing' && global.hasPermission('admin.restaurant.view*') && <RestaurantListing />}
                                {page == 'restaurants' && subPage == 'signupListing' && global.hasPermission('admin.restaurant.view*') && <SiteSignupList/>}
                                {page == 'restaurants' && subPage.indexOf("addEditRestaurant") > -1 && global.hasPermission('admin.restaurant.view*') && <AddEditRestaurant />}
                                {page == 'accounts' && global.hasPermission('admin.user') && <RolePermissionManager/>}
                                {page == 'config' && global.hasPermission('admin.*') && <Config/>}
                                {page == 'support' && <Support />}
                                {page == 'billing' && <Billing />}
                                {page == 'refund' && global.isAdmin() && showRefund()}
                                {page == 'restaurants' && subPage.indexOf("restaurantGroups") > -1 && global.hasPermission('admin.restaurant.view*') && <RestaurantGroups />}
                                {page == 'gateways' && global.hasPermission('admin.restaurant.gateway.*') && <GatewayList />}
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </div>

    );
};

Admin.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withRouter(withStyles(styles, { withTheme: true })(Admin));