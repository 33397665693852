import React, { useState, useEffect } from 'react';

import HeartlandOnboarding from './HeartlandOnboarding';

const heartland = true//(window.location.href.indexOf('heartland') > -1)
export default (props) => {
	return (
        <>
            {heartland &&
                <HeartlandOnboarding/>
            }
        </>
	);
};