import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import log from 'loglevel';
import _ from 'lodash';
import {
    Grid,
    Typography,
    Paper,
    Button,
    makeStyles,
} from '@material-ui/core';
import DForm2 from '../../../components/dash/DForm2';
import DInput from '../../../components/dash/DInput';
// import QRCode from 'qrcode';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PhotoIcon from '@material-ui/icons/Photo';
import Grow from '@material-ui/core/Grow';
import QRCode from 'qrcode.react';
import imageCompression from 'browser-image-compression';

const styles = makeStyles(theme => ({
    root: {},
    billingInfoWrapper: {
        paddingLeft: '4%',
        paddingRight: '4%',
        paddingBottom: '4%',
        paddingTop: '1%',
        marginBottom: '3%',
        marginRight: '3%'
    },
    subheadingText: {
        paddingTop: '1%'
    },
    box: {
        backgroundColor: 'var(--white)',
        border: '1px solid #EAEAEA',
        boxShadow: '0 0 10px 5px rgba(231,231,231,0.50)',
        padding: 30,
        marginBottom: 20,
        borderRadius: 6,
    },
    toggleSelected: {
        backgroundColor: "#8B6BD9 !important;",
        color: "var(--white) !important;",
        '& p': {
            color: "var(--white) !important;",
            fontWeight: '500 !important',
        }
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const isMobile = !!navigator.userAgent.match(/iphone|android|blackberry/ig) || false;

const Dash = (props) => {
    const [image, setImage] = useState(global.f(props.record, 'bankInfo.voidedCheckDataURL', false))
    const { classes } = props;

    async function handleFileUpload(file) {
        if (file) {
            try {
                const imageFile = file;
                if (imageFile.size > 300000 ) { // conditionally resize/compress image
                    const options = {
                        maxSizeMB: 3,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true
                    }
                    const compressedFile = await imageCompression(imageFile, options);
                    const compressedDataURL = await imageCompression.getDataUrlFromFile(compressedFile)
                    setImage(compressedDataURL)
                } else {
                    let reader = new FileReader()
                    reader.readAsDataURL(imageFile)
                    reader.onload = function () {
                        const img = reader.result;
                        setImage(img)
                    }
                }
            } catch (error) {
                global.showNotification('An error occurred uploading your image, please try again.', "error")
            }
        }
    }

    useEffect(()=>{
        //rotate check if not landscape
        const preview = document.getElementById('upload-preview')
        const imgContainer = document.getElementById("check-preview-container")
        if(image && preview){
            if (preview.clientHeight > preview.clientWidth) {
                imgContainer.style.transform = 'rotate(90deg)'
            }
        }
        if(!image){
            imgContainer.style.transform = null
        }
    },[image])

    return (
        <DForm2 record={props.record.bankInfo}>
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="subtitle1" color="primary" gutterBottom>
                        Please provide details of your business
					</Typography>
                </Grid>
                
                <Paper className={classes.box} style={{padding:30}}>
                    <Typography variant="h6" color="primary" className={styles.subheadingText} gutterBottom>
                        Bank Info
                    </Typography>

                    <Grid container justify="center" spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <DInput type="text" name="bankName" label="Bank Name" required/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DInput type="text" name="nameOnCheck" label="Name on Check" required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DInput type="tel" name="routingNumber" label="Routing Number" format='#########' required errorIf={(value)=> {return !(/^\d{9}$/.test(value))}} helperText="Routing number contains 9 digits" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DInput type="tel" name="accountNumber" label="Account Number" 
                                onBlur={()=>props.goToPageApi(props.request, 3, 'MerchantSetupRequest')}
                                errorIf={(value)=> {return !(/\d{3,30}/.test(value))}} required
                                helperText="Account number contains 10-17 digits"
                            />
                        </Grid>
                        <DInput name="voidedCheckDataURL" overrideValue={image} required style={{ display: 'none' }}></DInput>
                        <Grid item xs={12}>
                            <Typography variant="h6" color="primary" className={styles.subheadingText} style={{marginBottom:'-20px'}}>
                                Voided Check
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            {!isMobile &&
                                <Typography variant="body">
                                    You may either upload a photo file of a check, or use a mobile phone by scanning this QR code.
                                </Typography>        
                            }
                        </Grid>

                        <Grid item container justify="space-evenly" alignItems="center" xs={12} md={6}>
                            {!isMobile &&
                                <>
                                    <QRCode value={window.location.href} size={100}></QRCode>
                                    <Typography>
                                        or
                                    </Typography>
                                </>
                            }
                            <Button variant="contained" style={{ margin: '4px' }} onClick={() => document.querySelector('#check-upload').click()}>Upload photo of check</Button>
                        </Grid>
                        <Grid container item xs={12} justify="center">
                            <Grid item>
                                <Card style={{ margin: 'auto', padding: '4px', textAlign: 'center'}} id="check-preview-container">
                                    <CardContent style={{ padding: 4 }}>
                                        {!image ?
                                            <div style={{ width: '280px', height: '150px', border: '2px dashed var(--purple)', overflow: 'auto', textAlign: 'center', verticalAlign: 'center', padding: '6px' }}>
                                                <PhotoIcon style={{ height: '60px', width: '60px', opacity: '.1', position: 'relative', top: '50%', transform: 'translate(0%,-50%)' }} />
                                            </div>
                                            :
                                            <>  
                                                <Grow in={image} timeout={{ appear:500, enter:1000, exit:500}}>
                                                    <img id="upload-preview" src={image} style={{ maxHeight: '300px', maxWidth: '95%'}} id="check-image"/>
                                                </Grow>
                                            </>
                                        }
                                    </CardContent>
                                </Card>
                                {image &&
                                    <Button style={{ margin: 'auto', display: 'block', marginTop: '8px' }} variant="contained" color="secondary" size="small" onClick={() => setImage(null)}>
                                        Retake Photo
                                    </Button>
                                }
                            </Grid>
                        </Grid>

                        <Grid item xs={6} alignItems="stretch" alignContent="stretch">
                            <form encType="discrete/image">
                                <input type='file' id="check-upload" accept={'image/*'} style={{ display: 'none' }} 
                                    onChange={(e) => {
                                        if(e.target.files && e.target.files.length > 0){
                                            handleFileUpload((e.target.files[0]))
                                        }
                                    }}
                                />
                            </form>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </DForm2>
    )
};

export default withStyles(styles, { withTheme: true })(Dash);
