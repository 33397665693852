import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import log from 'loglevel';
import _ from 'lodash';
import {
	Grid,
	Button,
	Typography,
	FormControl,
	MenuItem,
	Modal,
	Paper,
	InputLabel,
} from '@material-ui/core';
import SelectMui from '@material-ui/core/Select';
import DForm2 from '../../../components/dash/DForm2';
import DRecord from '../../../components/dash/DRecord';
import DInput from '../../../components/dash/DInput';
import DSwitch from '../../../components/dash/DSwitch';
import DSubmitButton from '../../../components/dash/DSubmitButton';
import dashFetch from '../../../utils/dashFetch'

const styles = (theme) => ({
	root: {
		height: '100%',
        width: '100%',
	},
	restaurantFieldInputItem: {
		width: '100%',
		// marginTop: '15px'
	},
	muiSelect: {
		marginBottom: '15px',
	},
	subheadingText: {
		fontSize: '16px',
	},
	box: {
		backgroundColor: 'var(--white)',
        border: '1px solid #EAEAEA',
        boxShadow: '0 0 10px 5px rgba(231,231,231,0.50)',
        padding: 30,
        marginBottom: 20,
        borderRadius: 6,
	},
});

const Dash = (props) => {
	const { classes, incrementLocationCount } = props;
	const restaurants = props.record ? props.record.restaurants: ''
	const restaurantGroup = props.record ? props.record.restaurantGroup: ''
	const [posTypes, setPosTypes] = useState(props.dropDownOptions ? props.dropDownOptions.posTypes : {})

	return (
		<DForm2 name="locationInfo" record={props.record}  validate={false}>
            <Grid container>
				<Grid item xs={12} sm={12} md={12}>
					<Typography variant="subtitle1" color="primary" gutterBottom>
						Please provide details of each restaurant location
					</Typography>
				</Grid>
                {/* Location Infos */}
                {_.times(restaurantGroup.locationCount, (num) => {
                return (
                    <DRecord record={props.record.restaurants[num]}>
                        <Paper className={classes.box}>
                            <Typography variant="h6" color="primary" className={styles.subheadingText} gutterBottom>
                                Location {num + 1}
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <DInput name="name" label="Site Name" required />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <DInput name="locationName" label="Location Alias  (Ex: East Collins Ave)" />
                                </Grid>
                                <Grid container spacing={1} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <DInput name="locationInfo.addressLine1" label="Address Line 1" required />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <DInput name="locationInfo.addressLine2" label="Address Line 2" />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <DInput name="locationInfo.city" label="City" required />
                                </Grid>
                                <Grid item xs={8} sm={8} md={4} lg={4} xl={4}>
									<FormControl style={{ width: '100%' }} className={classes.restaurantFieldInputItem}>
										<InputLabel shrink htmlFor="state">State</InputLabel>
										<DInput
											InputLabelProps={{ shrink: true }}
											value={restaurants[num] ? restaurants[num].locationInfo.state : ''}
											name="locationInfo.state"
											fieldType="Select"
											required
											// onChange={(e) => setRequest(e, `data.record.state`)}  //todo fix
										>
											<MenuItem disabled value=""><em>State</em></MenuItem>
											{states.map((pt) => <MenuItem value={pt.value}>{pt.label}</MenuItem>)}
										</DInput>
									</FormControl>
                                </Grid>
                                <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                                    <DInput name="locationInfo.zipCode" label="Zip Code" required />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <div>
                                        <DInput name="locationInfo.phone" label="Business Phone Number" required />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <div>
                                        <FormControl className={classes.restaurantFieldInputItem} >
                                            <InputLabel shrink htmlFor="posType">POS Type</InputLabel>
                                            <DInput
                                                className={classes.muiSelect}
                                                value={restaurants[num] ? restaurants[num].posType : ''}
												name="posType"
												fieldType="Select"
                                                required
                                            >
                                                {posTypes.map((pt) => <MenuItem value={pt._id}>{pt.posType}</MenuItem>)}
                                            </DInput>
                                        </FormControl>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </DRecord>
                )})}
            </Grid>
		</DForm2>
	);
};

export default withStyles(styles, { withTheme: true })(Dash);

const states = [
	{ value: 'AL', label: 'Alabama' },
	{ value: 'AK', label: 'Alaska' },
	{ value: 'AZ', label: 'Arizona' },
	{ value: 'AR', label: 'Arkansas' },
	{ value: 'CA', label: 'California' },
	{ value: 'CO', label: 'Colorado' },
	{ value: 'CT', label: 'Connecticut' },
	{ value: 'DE', label: 'Delaware' },
	{ value: 'FL', label: 'Florida' },
	{ value: 'GA', label: 'Georgia' },
	{ value: 'HI', label: 'Hawaii' },
	{ value: 'ID', label: 'Idaho' },
	{ value: 'IL', label: 'Illinois' },
	{ value: 'IN', label: 'Indiana' },
	{ value: 'IA', label: 'Iowa' },
	{ value: 'KS', label: 'Kansas' },
	{ value: 'KY', label: 'Kentucky' },
	{ value: 'LA', label: 'Louisiana' },
	{ value: 'ME', label: 'Maine' },
	{ value: 'MD', label: 'Maryland' },
	{ value: 'MA', label: 'Massachusetts' },
	{ value: 'MI', label: 'Michigan' },
	{ value: 'MN', label: 'Minnesota' },
	{ value: 'MS', label: 'Mississippi' },
	{ value: 'MO', label: 'Missouri' },
	{ value: 'MT', label: 'Montana' },
	{ value: 'NE', label: 'Nebraska' },
	{ value: 'NV', label: 'Nevada' },
	{ value: 'NH', label: 'New Hampshire' },
	{ value: 'NJ', label: 'New Jersey' },
	{ value: 'NM', label: 'New Mexico' },
	{ value: 'NY', label: 'New York' },
	{ value: 'NC', label: 'North Carolina' },
	{ value: 'ND', label: 'North Dakota' },
	{ value: 'OH', label: 'Ohio' },
	{ value: 'OK', label: 'Oklahoma' },
	{ value: 'OR', label: 'Oregon' },
	{ value: 'PA', label: 'Pennsylvania' },
	{ value: 'RI', label: 'Rhode Island' },
	{ value: 'SC', label: 'South Carolina' },
	{ value: 'SD', label: 'South Dakota' },
	{ value: 'TN', label: 'Tennessee' },
	{ value: 'TX', label: 'Texas' },
	{ value: 'UT', label: 'Utah' },
	{ value: 'VT', label: 'Vermont' },
	{ value: 'VA', label: 'Virginia' },
	{ value: 'WA', label: 'Washington' },
	{ value: 'WV', label: 'West Virginia' },
	{ value: 'WI', label: 'Wisconsin' },
	{ value: 'WY', label: 'Wyoming' }
];