import log from 'loglevel';
import dashFetch from '../utils/dashFetch';


export default async function (restaurantId, allBilling, debug) {
	return new Promise((resolve, reject) => {
		if (true) log.info('getRestaurantListApi api ' + restaurantId);
		const url = `${global.dashServerUrl}/dashboard/api/v1/dashboard/getRestaurantList`;

		const body = {
			restaurantId, allBilling, debug
		};

		let allowWithoutToken = restaurantId ? true : false

		dashFetch(url, body, debug, allowWithoutToken).then(({ res, json }) => {
			log.info('>>> getRestaurantListApi JSON');
			// log.info(json)
			if (json.success) {
				resolve(json.data);
			} else {
				resolve(false)
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}