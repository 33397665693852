import log from 'loglevel';
import dashFetch from '../utils/dashFetch';


export default async function (debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('acceptTermsOfService api');
		const url = `${global.dashServerUrl}/api/v1/account/acceptTermsOfService`;

		const body = {};

		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info('>>> acceptTermsOfService JSON');
			log.info(json)
			if (json.success) {
				resolve(json.success);
				localStorage.tosAcceptance = new Date()
			} else {
				resolve(false)
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}