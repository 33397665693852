import React, { useState, useEffect, useRef } from 'react';
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { ObjectID } from 'bson';
import { Grid } from '@material-ui/core';

export default function DChart(props) {

	const [restaurant, setRestaurant] = global.get('restaurant')
	let restaurantId = new ObjectID(restaurant._id)
	let random = Math.floor(10000 + Math.random() * 900000).toString()

	let chart

	if (restaurant) {
		chart = props.chartSdk.createChart({
			chartId: props.chartId,
			filter: {"_id.site": restaurantId},
			// filter: {"_id.site": new ObjectID('5f6a06531785a651c269baee')},
			height: props.height ? props.height : "300px"
		})
	}

	
	useEffect(() => {
		if (restaurant) {
			chart.render(document.getElementById(random));
		}
	}, [restaurant])


	return (
		<Grid id={random} container />
	)
}
