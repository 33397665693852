import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { TextField, Grid, Paper, Drawer, Zoom, TableHead, TableBody, TableRow, TableCell, Table, TableContainer, TablePagination, CircularProgress, LinearProgress, Popper, Button, ClickAwayListener, Menu, Slide, DialogActions, DialogContent, Dialog } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import theme from '../../theme'
import DTableHead from '../../components/dash/DTableHead'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import log from 'loglevel'
import _ from 'lodash';
import GlobalSearchFilter from '../../dashboard/components/GlobalSearchFilter';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import dashFetch from '../../utils/dashFetch';
import { Autocomplete } from '@material-ui/lab';


import moment from 'moment'
import DialogTitle from '../../dashboard/components/DialogTitle';
import getUnassociatedGatewayListApi from '../../actions/getUnassociatedGatewayListApi';
import getRestaurantListApi from '../../actions/getRestaurantListApi';
import retestAutoGeneratedGatewayApi from '../../actions/retestAutoGeneratedGatewayApi';
import DForm2 from '../../components/dash/DForm2';
import DInput from '../../components/dash/DInput';
import assignRestaurantToGatewayApi from '../../actions/assignRestaurantToGatewayApi';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    scrolling: {
        overflow: 'auto',
        height: 'auto'
    },
    payments: {
        paddingLeft: 5,
        paddingRight: 5,
        maxHeight: 'calc(100% - 3em)',
    },
    paper: {
        // paddingRight:8,
        // paddingleft:8,
        // margin: 8,
        // minHeight: '100%',
        // maxHeight: '100%',
        width:'100%',
        height:'100%'
    },
    iconWrapper: {
        height: '2.7em', 
        display: 'flex',
        alignItems: 'center' 
    }
}))


export default (props) => {
    const classes = useStyles();
    let [restaurants, setRestaurants] = useState([])
    let [records, setRecords] = useState([])
    let [filteredRecords, setFilteredRecords] = useState([])
    let [dialogContent, setDialogContent] = useState()
    let [refreshData, setRefreshData] = useState(true)
    let [selectedRecord, setSelectedRecord] = useState()
    let [loading, setLoading] = useState(true)
    let [order, setOrder] = useState('asc');
    let [orderBy, setOrderBy] = useState('name');


    let smallScreen = useMediaQuery(theme().breakpoints.down('sm'));
    let [gwSearchFilter] = global.get('gwSearchFilter', '')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    
    const closeContent = (reload = false) => {
        setDialogContent(null)
        if (reload == true) {
            fetchData()
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
    
    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    
    const headCells = [
        { id: 'id', align: 'left', label: 'ID' },
        { id: 'merchantId', align: 'left', label: 'MID' },
        { id: 'gatewayTypeName', align: 'left', label: 'Type' },
        { id: 'testTransactionProcessedAmount', align: 'left', label: 'Test Transaction Amount' },
        { id: 'testTransactionDate', align: 'left', label: 'Test Transaction On' },
        { id: 'createdAt', align: 'left', label: 'Created On' },
    ];

    

    const fetchData = async () => {
        setPage(0)
        setLoading(true)
        //refreshrestaurants
        let gw = await getUnassociatedGatewayListApi()
        let restaurants = await getRestaurantListApi()
        setRecords(gw)
        setRestaurants(restaurants)
        setLoading(false)
    }
    


    const filterData = (searchFilter) => {
        let recsTmp
        if (!searchFilter) {
            searchFilter = ''
            recsTmp = records
            // setFilteredrecords(records)
            setPage(0)
        } else {
            setLoading(true)

            const seartchFilterTUC = searchFilter.toUpperCase().replace('.', '')
            if (records) {
                recsTmp = records.filter(rec => {
                    return (
                        (rec._id && rec._id.toString().toUpperCase().includes(seartchFilterTUC)) 
                        ||  (rec.getVal('merchantId') && rec.merchantId.toString().toUpperCase().includes(seartchFilterTUC)) 
                        ||  (rec.getVal('gatewayType.name') && rec.gatewayType.name.toString().toUpperCase().includes(seartchFilterTUC)) 
                        ||  (rec.getVal('testTransactionProcessedAmount') && rec.testTransactionProcessedAmount.toString().toUpperCase().includes(seartchFilterTUC)) 
                        ||  (rec.getVal('testTransactionDate') && rec.testTransactionDate.toString().toUpperCase().includes(seartchFilterTUC)) 
                        ||  (rec.getVal('createdAt') && rec.createdAt.toString().toUpperCase().includes(seartchFilterTUC))
                    )
                })
                
            }
            setLoading(false)
        }
        recsTmp = recsTmp && recsTmp.map(rec=> {
            let res = {
                ...rec,
                gatewayTypeName: rec.getVal('gatewayType.name', ''),
            }
            return res
        })
        setFilteredRecords(recsTmp)
    }



    useEffect(() => {
        if (refreshData) {
            fetchData()
            setRefreshData(false)
        }
    }, [refreshData])

    
    useEffect(() => {
        filterData(gwSearchFilter)
    }, [gwSearchFilter, records])
    

    const retest = async (gateway) => {
        gateway = await retestAutoGeneratedGatewayApi(gateway)
        let recordsTmp = [...records]
        recordsTmp.replaceItem(gateway)
        setRecords(recordsTmp)
        let success = !!gateway.testTransactionProcessedAmount
        global.showNotification('GW TESTED', success ? "success" : 'error')
        setDialogContent(null)
    }

    

    const renderGW = (gateway) => {

        const handleSaveGateway = async () => {
            let restaurantId = gateway.restaurant
            if (!restaurantId) {
                log.info('invalid restaurant id: ' + restaurantId)
            } else {
                await assignRestaurantToGatewayApi(restaurantId, gateway._id)
                setRefreshData(true)
                setDialogContent(null)
            }
        }
        
        return (
            <Dialog
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                maxWidth={'md'}
                open={true}
                style={{ margin: 0, height: '100%', width: '100%'}}
                onClose={ props.close }
            >
                {gateway && 
                <>
                    <DialogTitle onClose={setDialogContent(null)}>
                        Gateway
                    </DialogTitle>
                    <DialogContent dividers style={{backgroundColor:'var(--background)'}}>
                        <DForm2 name="gateway" record={gateway} margin="10px">
                            <Grid container>
                                <Grid item lg={12} container spacing={2}>
                                    <Grid item lg={9}>
                                        <DInput
                                            disabled
                                            label="Id"
                                            name="_id"
                                        />
                                    </Grid>
                                    <Grid item lg={9}>
                                        <DInput
                                            disabled
                                            label="MID"
                                            name="merchantId"
                                        />
                                    </Grid>
                                    <Grid item lg={9}>
                                        <DInput
                                            disabled
                                            label="GW Type Name"
                                            name="gatewayTypeName"
                                        />
                                    </Grid>
                                    <Grid item lg={9}>
                                        <DInput
                                            disabled
                                            label="Test Transaction Amount"
                                            name="testTransactionProcessedAmount"
                                        />
                                    </Grid>
                                    <Grid item lg={9}>
                                        <DInput
                                            disabled
                                            label="Test Transaction On"
                                            name="testTransactionDate"
                                        />
                                    </Grid>
                                    <Grid item lg={9}>
                                        <DInput
                                            disabled
                                            label="Created At"
                                            name="createdAt"
                                        />
                                    </Grid>
                                    <Grid item lg={9}>
                                        {/* <DInput label="Restaurant" name="restaurant" fieldType="select" id="restaurant-selector">
                                            {restaurants && restaurants.map((r) => <MenuItem value={r._id}>{r.name}</MenuItem>)}
                                        </DInput> */}
                                        <Autocomplete
                                            options={restaurants}
                                            getOptionLabel={(option) => option.name}
                                            style={{ width: '100%' }}
                                            onChange={(e, v) => gateway.restaurant = v._id}
                                            renderInput={(params) => <TextField {...params} name="restaurant" label="Restaurant" />}
                                            />
                                    </Grid>                                    
                                </Grid>
                            </Grid>
                        </DForm2>
                    </DialogContent>
                </>}
                <DialogActions>
                    <div style={{display:'flex', justifyContent:'justify-start', width:'100%'}}>
                        {/* <div>
                            {gateway &&
                                <Button style={{ float: 'left'}} variant="outlined" color="primary" onClick={ ()=> copyToClipboard() }><AssignmentIcon style={{paddingRight: '10px'}}></AssignmentIcon>Copy Demo Param</Button>
                            }
                        </div> */}
                        <Button onClick={()=> setDialogContent(null)}>
                            Close
                        </Button>
                        <div style={{flexGrow: 1}}></div>
                        <div>
                            {gateway && !gateway.createdFromDashboard}
                            <Button variant="outlined" color="primary" onClick={() => {retest(gateway)}}>
                                Re-test
                            </Button>
                        </div>
                        <div style={{paddingLeft: 16}}>
                            <Button variant="contained" color="primary" onClick={handleSaveGateway}>
                                Save
                            </Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        )
    }




	return (
        <Grid container style={{ height:"100%", padding: 10}}>
            {!selectedRecord && 
                <>
                    <Box display="flex" style={{ width:'100%', height: '60px', overflow: 'none', padding: 5}}>
                        <Box display="flex" style={{ flexGrow: 1}}>
                            <Box style={{ flexGrow: 1}}>
                                <GlobalSearchFilter var="gwSearchFilter" searchText="Search by stuff, etc."/>
                            </Box>
                        </Box>
                    </Box>
                    <Grid container style={{ width:'100%', maxHeight:'100%', height: 'calc(100% - 50px)', overflow: 'none'}} >


                        <Grid item xs={12} container direction='row' style={{ width: '100%', height: smallScreen ? 'auto' : 'calc(100% - 50px)', minHeight: smallScreen ? 'calc(100% - 50px)' : '0', margin: 0 }}>
                            <Grid item xs={12} style={{ height: smallScreen ? 'auto' : '100%', width: '100%', padding: 5}} >
                                <Slide in={!selectedRecord} direction="right">
                                    <Paper className={classes.paper}>
                                        {loading && <LinearProgress />}
                                        {!loading && records &&
                                            <TableContainer style={{height: '100%' }}>
                                                <Table id="payments-table" size="small" aria-label="a dense sticky table" stickyHeader style={{position: 'sticky'}}>
                                                    <DTableHead
                                                        // classes={classes}
                                                        order={order}
                                                        headCells={headCells}
                                                        style={{backgroundColor:'white'}}
                                                        orderBy={orderBy}
                                                        onRequestSort={handleRequestSort}
                                                    />
                                                    <TableBody id="payments-table-body">
                                                        {filteredRecords && stableSort(filteredRecords, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(rest => {

                                                            return (
                                                                <>
                                                                    <TableRow id={rest._id} selected={selectedRecord && rest._id == selectedRecord._id} onClick={() => {setDialogContent(renderGW(rest))}} style={{ cursor: 'pointer' }}>
                                                                        <TableCell>{rest._id}</TableCell>
                                                                        <TableCell>{rest.merchantId.toUpperCase()}</TableCell>
                                                                        <TableCell>{rest.gatewayTypeName}</TableCell>
                                                                        <TableCell>{rest.testTransactionProcessedAmount}</TableCell>
                                                                        <TableCell>{rest.testTransactionDate ? moment(rest.testTransactionDate).format("M/D/YY h:mm a") : ''}</TableCell>
                                                                        <TableCell style={{minWidth: '185px'}}>{rest.createdAt ? moment(rest.createdAt).format("M/D/YY h:mm a") : ''}</TableCell>
                                                                    </TableRow>
                                                                </>
                                                            )
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Paper>
                                </Slide>
                            </Grid>
                        </Grid>

                        {records && records.length > rowsPerPage && 
                            <Grid container item md={12} style={{ width: '100%', padding: 0, marginTop: -5}} justify='flex-end'>
                                <TablePagination
                                    rowsPerPageOptions={[25, 50, 100]}
                                    align='right'
                                    component="div"
                                    count={records ? records.length : 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Grid>
                        }

                        <Grid item lg={12} >
                            {dialogContent}
                        </Grid>
                    </Grid>
                </>
            }
            {/* {selectedRecord &&
                <Paper style={{width:'100%'}}>
                    <div style={{display:'flex', justifyContent:'flex-start', flexDirection:'column'}}>
                        <div id="top" style={{borderBottom:'1px solid var(--divider)'}}>
                            <IconButton color="primary" className={classes.iconButton}  onClick={() => { setSelectedRecord(null) }} aria-label="directions">
                                <ArrowBackIosIcon/> <span style={{fontSize:14, color:'var(--text)'}}>BACK</span>
                            </IconButton>
                        </div>
                        <Slide in={selectedRecord} direction="left" mountOnEnter unmountOnExit>
                            <div>
                                <SiteAdmin site={selectedRecord}/>
                            </div>
                        </Slide>

                    </div>
                </Paper>
            } */}
        </Grid>
	);
};
