import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
	AddCircleOutline, History,
} from '@material-ui/icons';
import { Button, TextField, Checkbox } from '@material-ui/core';
import dashFetch from '../utils/dashFetch';
import history from '../history';
import { withRouter } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import ReactTable from "react-table";
import "react-table/react-table.css";

const styles = (theme) => ({
    root: {
    },
})

const RequestComplete = (props) => {
	const { classes, request } = props;

	useEffect(() => {
	
	}, []);


	const navigateToRequest = async () => {
		console.log("NAVIGATE TO LISDTING ")
        history.push(`/requestListing/`)
	}

	return (
		<div className={classes.listing}>
			<Grid container justify="center" alignItems="center" style={{height:'100%'}}>
                <Grid item xs={12}>
                    {request &&
                        <Paper style={{ width: '100%' }} className={classes.rootTable} style={{border: 'none', boxShadow: 'none'}}>
                            <Grid container justify="center" alignItems="center" style={{height:'100%', width:'100%'}}>
                                <Grid item lg={12} style={{ textAlign: "center", padding: '8px', paddingTop: '12px' }}>
                                    <Typography variant="h5" style={{paddingBottom: 25}}>
                                        SUCCESS!
                                    </Typography>
                                    <br/>
                                    <CheckCircleIcon color="primary" style={{ fontSize: 50 }} />
                                        {request.status == 'New' &&
                                            <Typography>
                                                Your request has been submitted to {global.f(request, 'data.restaurantGroup.primaryContactInfo.email', 'the client')}
                                            </Typography>
                                        }
                                        {request.status == 'Requested' &&
                                            <Typography>
                                                You will receive an email when your account has been created and you can begin taking payments for your business.
                                            </Typography>
                                        }
                                        {request.status == 'Submitted' &&
                                            <Typography>
                                                Your request has been submitted to Dash Now For final review
                                            </Typography>
                                        }
                                        {request.status == 'Final Review' &&
                                            <Typography>
                                                Your request has been published
                                            </Typography>
                                        }
                                </Grid>
                                {/* <Grid item xs={12} style={{textAlign: 'center', padding: '3%'}}>
                                    <Button variant="contained" color="primary" onClick={() => history.push('/requestListing')}>
                                        Take me back
                                    </Button>
                                </Grid> */}
                            </Grid>
                        </Paper>
				    }
                </Grid>
            </Grid>
        </div>
	);
};

RequestComplete.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withRouter(withStyles(styles, { withTheme: true })(RequestComplete));