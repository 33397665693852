import React from 'react';

class GoldStar extends React.Component {
    render () {
        return (
            <svg width="19px" height="18px" viewBox="0 0 19 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Home" transform="translate(-1212.000000, -727.000000)" fill="#DFBF4F">
                        <g id="Recent-Activity" transform="translate(1072.000000, 202.000000)">
                            <g id="table" transform="translate(12.000000, 42.000000)">
                                <g id="data1">
                                    <g id="recent1" transform="translate(0.000000, 380.000000)">
                                        <g id="Group-5" transform="translate(110.550000, 103.000000)">
                                            <path d="M27.9773335,0.632897061 L30.2647575,5.94615928 L34.7162423,5.94615928 C35.1766931,5.92873108 35.5985595,6.20239577 35.7707795,6.63023737 C35.9429995,7.05807896 35.8285386,7.5480938 35.4846622,7.85512468 L31.6202681,11.2995946 L33.7605479,16.2304686 C33.9531448,16.6927676 33.8298441,17.2265333 33.4540582,17.5572545 C33.0782724,17.8879757 32.5336737,17.9420142 32.1003447,17.6915785 L26.9280018,14.7723344 L21.7556589,17.6900906 C21.3221267,17.9440362 20.774899,17.8917201 20.3972173,17.5602207 C20.0195357,17.2287214 19.8961793,16.6924513 20.0909968,16.2289807 L22.2357355,11.3025704 L18.3713414,7.85810046 C18.023681,7.55262594 17.9062632,7.0608702 18.0783504,6.6310291 C18.2504377,6.20118799 18.6746128,5.92672257 19.1367887,5.94615928 L23.595705,5.94615928 L25.8861017,0.634384953 C26.089441,0.244718316 26.4921058,0.000312759581 26.9312677,2.99943186e-07 C27.3704296,-0.000312159695 27.7734411,0.243520165 27.9773335,0.632897061 Z" id="Shape"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}

export default GoldStar;
