import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, Paper, MenuItem, Select, Checkbox, ListItemText, FormControl, FormControlLabel, Switch, FormGroup, CircularProgress} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
	AddCircleOutline,
} from '@material-ui/icons';
import dashFetch from '../../utils/dashFetch';
import { Button, Input } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import getAccountListApi from '../../actions/getAccountListApi'
import AddEditPosType from '../components/AddEditPosType'
import log from 'loglevel'
import ReactTable from "react-table";
import getPosTypes from '../../actions/getPosTypesApi';


import "react-table/react-table.css";

const styles = (theme) => ({
    root: {
    },
	addIcon: {
		float: 'left',
		marginTop: 5,
		marginRight: 5,
		width: '20px',
		height: '20px'
	},
	addButton: {
		float: 'right',
		marginLeft: 20,
		marginRight: 20,
		marginTop: 10,
		marginBottom: 10,
		width: 200,
		height: 45
    },
    tabs: {
        width: '100%'
    }
    
})

const PosListing = (props) => {
    const { classes } = props;
    const [posTypes, setPosTypes] = useState([])
    const [dialogContent, setDialogContent] = useState()
    const [refreshPosTypes, setRefreshPosTypes] = useState(true)

    async function fetchdata() {
        let data = await getPosTypes()
        if (data) {
            setPosTypes(data)
        }
    }

    useEffect(() => {
        if(refreshPosTypes){
            fetchdata();
            setRefreshPosTypes(false)
        }
    }, [refreshPosTypes]);

    const openAddEditPosWindow = (pos, newPos = true) => {
        setDialogContent(
            <AddEditPosType refresh={setRefreshPosTypes} posType={pos} create={newPos} close={closeContent} />
        )
    }

    const closeContent = () => {
        setDialogContent(null)
    }


    const showList = () => {
        return (
            <div className={classes.listing}>
                <Grid container>
                    <Paper style={{ width: '100%' }} className={classes.rootTable}>
                        <div>
                            <Grid container>
                                <Grid item xs={12} sm={8} style={{ padding: '14px 14px 0px 14px', textAlign:'left', marginTop:'-8px'}}>
                                    <Typography variant="h6" color="primary">
                                        POS Types
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.addButton}
                                        onClick={openAddEditPosWindow}
                                    >
                                        <div>
                                            <AddCircleOutline className={classes.addIcon} />
                                            <Typography variant="subtitle1" style={{ color: 'white', width: 190 }}>
                                                Add Pos Type
                                            </Typography>
                                        </div>
                                    </Button>
                                </Grid>
                            </Grid>
                            <ReactTable
                                data={posTypes}
                                filterable
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
                                }
                                defaultSorted={[
                                    {
                                        id: "name",
                                        desc: true
                                    }
                                ]}
                                columns={[
                                    {
                                        Header: "POS Types",
                                        columns: [
                                            {
                                                Header: "Type",
                                                accessor: "posType",
                                            },
                                            {
                                                Header: "code",
                                                accessor: "code",
                                            },
                                            {
                                                Header: "_id",
                                                accessor: "_id",
                                            },
                                            {
                                                Header: "Supported",
                                                accessor: (original)=> {
                                                    return original.supported.toString()
                                                },
                                                id:'sup'
                                            },
                                            {
                                                Header: "Cloud-based",
                                                accessor: (original)=> {
                                                    return original.config.cloudBased.toString()
                                                },
                                                id:'conf1'
                                            },
                                            {
                                                Header: "sendPaymentDataToBackOffice",
                                                accessor: (original)=> {
                                                    return original.config.sendPaymentDataToBackOffice.toString()
                                                },
                                                id:'conf2'
                                            },
                                            {
                                                Header: "Cloud-based config fields",
                                                accessor: (original)=> {
                                                    return original.config.cloudBasedConfigFields.toString()
                                                },
                                                id:'conf3'
                                            },
                                        ]
                                    },
                                ]}
                                getTrProps={(state, rowInfo) => {
                                    if (rowInfo && rowInfo.row) {
                                        // console.log('TYLER ' + JSON.stringify(rowInfo))
                                        // console.log('TYLER 1 ' + JSON.stringify(rowInfo.row))
                                        return {
                                            onClick: (e) => {
                                                openAddEditPosWindow(rowInfo.row._original, false)
                                            }
                                        }
                                    } else {
                                        return {}
                                    }
                                }}
                                defaultPageSize={15}
                                className="-striped -highlight"
                            />
                            <br />
                        </div>
                    </Paper>
                    <div style={{ textAlign: "center" }}>
                        <em>Tip: Hold shift when sorting to multi-sort!</em>
                    </div>
                </Grid>
            </div>
        )
    }

    return (
        <Grid container >
            <div className={styles.root} style={{ width: '100%' }}>
                <Grid container>

                    <Grid item xs={12} >
                        {showList()}
                    </Grid>
                    <Grid item lg={12} >
                        {dialogContent}
                    </Grid>
                </Grid>
            </div>
        </Grid>
    );
};

PosListing.propTypes = {
    classes: PropTypes.shape({}).isRequired
};

export default withRouter(withStyles(styles, { withTheme: true })(PosListing));
