import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import PropTypes from 'prop-types';
import SelectMui from '@material-ui/core/Select';
import { Grid, TextField, Typography, Paper, FormControl, InputLabel, Button, Box, TableHead, TableRow, TableCell, Table, TablePagination, Slide, CircularProgress,
     LinearProgress, Menu, MenuItem, Tabs, Tab, Dialog, DialogContent, DialogActions, IconButton, Divider, Accordion, AccordionSummary, AccordionDetails, InputAdornment,
      FormLabel, Radio, RadioGroup, FormControlLabel, Modal, Switch} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/core/styles';
import getRestaurantList from "../../../actions/getRestaurantListApi";
import getProductPackageList from "../../../actions/getProductPackageListApi";
import generateInvoiceForRestaurantApi from "../../../actions/generateInvoiceForRestaurantApi";
import getRestaurantOrdersAndInvoices from "../../../actions/getRestaurantOrdersAndInvoicesApi";
import addTestPaymentAPI from "../../../actions/addTestPaymentApi";
import payInvoiceApi from "../../../actions/payInvoiceApi";
import termOrderApi from "../../../actions/termOrderApi";
import changeOrderStartDateApi from "../../../actions/changeOrderStartDateApi";
import addOrderToRestaurantApi from "../../../actions/addOrderToRestaurantApi";
import updateRestaurantBillingInfoApi from "../../../actions/updateRestaurantBillingInfoApi";
import updateRestaurantPrimaryContactApi from "../../../actions/updateRestaurantPrimaryContactApi";
import cancelOrderApi from "../../../actions/cancelOrderApi";
import deleteInvoiceApi from "../../../actions/deleteInvoiceApi";
import removeDiscountFromInvoiceApi from "../../../actions/removeDiscountFromInvoiceApi";
import log from 'loglevel'
import { withRouter } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'
import DFlag from '../../../components/dash/DFlag'
import InvoicePendingIcon from '@material-ui/icons/Autorenew';
import InvoiceOpenIcon from '@material-ui/icons/FilterNone';
import InvoiceClosedIcon from '@material-ui/icons/AttachMoney';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MasterInvoice from "../../../components/billing/MasterInvoice";
import Invoice from "../../../components/billing/Invoice";
import sendEmailUpdatePaymentInfoApi from "../../../actions/sendEmailUpdatePaymentInfoApi";
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '../../../dashboard/components/DialogTitle';
import FormAddDiscountToInvoice from './FormAddDiscountToInvoice';
import getInvoiceList from "../../../actions/getInvoiceListApi";
import getInvoice from "../../../actions/getInvoiceApi";
import uploadInvoicesApi from '../../../actions/uploadInvoicesApi'
import downloadPartnerResidualsApi from '../../../actions/downloadPartnerResidualsApi'

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import DInput from '../../../components/dash/DInput';
import theme from '../../../theme';
  

const styles = (theme) => ({
    root: {
        height: '100%',
        width:'100%'
        // flexGrow: 1,
        // paddingTop: 25,
        // padding: 15,
    },
    restaurantFieldInputItem: {
        width: '100%',
    },
	muiSelect: {
		width: '100%',
    },
    deletedRow: {
        color: '#DDD'
    }
})

const Admin = (props) => {

    const {classes, showDetails, setShowDetails} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedTab, setSelectedTab] = React.useState('invoices');
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [showDiscountForm, setShowDiscountForm] = React.useState(false);
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    let [restaurants] = global.get('restaurants', []);
    let [restaurant] = global.get('restaurant', null);
    const [productPackages, setProductPackages] = useState([])
    const [selectedProductPackage, setSelectedProductPackage] = useState(null)
    const [selectedRecordRow, setSelectedRecordRow] = useState("")
    const [paymentAmount, setPaymentAmount] = useState(1)
    const [asOfDate, setAsOfDate] = useState(moment())
    const [paymentDate, setPaymentDate] = useState(moment())
    const [orderDate, setOrderDate] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [goLiveDate, setGoLiveDate] = useState(null)
    const [billingDayOfMonth, setBillingDayOfMonth] = useState(null)
    const [orders, setOrders] = useState(null) 
    const [payments, setPayments] = useState(null) 
    const [invoices, setInvoices] = useState(null)
    const [selectedOrder, setSelectedOrder] = useState(null)
    const [selectedInvoice, setSelectedInvoice] = useState(null)
    const [generatingFlag, setGeneratingFlag] = useState(false)
    const [payingFlag, setPayingFlag] = useState(false)
    const [hasInProgressInvoice, setHasInProgressInvoice] = useState(true)
    const [paymentEmailJustSent, setPaymentEmailJustSent] = useState(false)
    const [showAddMainContactWindow, setShowAddMainContactWindow] = React.useState(false);
    const [primaryContactInfo, setPrimaryContactInfo] = useState({})
    const [expanded, setExpanded] = React.useState(false);
    let [openModal, setOpenModal] = useState(false)
    let [checkedByRestaurant, setCheckedByRestaurant] = global.get('checkedByRestaurant')
    let [file, setFile] = useState(null)
    let defaultFilters = { invoiceStatus: 'all', minAmount: "0.01", paymentStatus: 'all'} //type: 'visible', defaulted
    if (checkedByRestaurant && restaurant) defaultFilters.restaurantId = restaurant._id
    let [filters, setFilters] = useState(defaultFilters)
    

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(6)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    
    const sendEmailUpdatePaymentInfo = async () => {
        if (restaurant) {
            let success = await sendEmailUpdatePaymentInfoApi(restaurant._id)
            if (success) {
                restaurant = _.find(restaurants, {id: restaurant._id})
                restaurant.billingInfo.encryptedCardLastEmailNotificationSent = new Date()
                setPaymentEmailJustSent(true)
                global.set({restaurant})
                // selectRestaurant({...rest})
            }
        }
    }

    const upload = async () => {
        let invoiceDate = await uploadInvoicesApi(file)
        if (invoiceDate) {
            filters = {...filters, type: 'internal', invoiceStatus: 'all', paymentStatus: 'all', paymentVerification: 'all', hasPrimaryContact: 'all', hasPaymentMethod: 'all', invoiceDate: invoiceDate}
            setFilters(filters)
            fetchData()
        }
        downloadPartnerResidualsApi()
    }
    

	useEffect(() => {
        if (localStorage.billingTestDate) {
            setPaymentDate(localStorage.billingTestDate)
        }
        if (localStorage.billingTestAsOfDate) {
            setAsOfDate(localStorage.billingTestAsOfDate)
        }
        
        if (localStorage.billingTestAmount) {
            setPaymentAmount(localStorage.billingTestAmount)
        }
        fetchData()
    }, []);

    useEffect(() => {
        updateSelectedRestaurant(restaurant)
        setPaymentEmailJustSent(false)
    }, [restaurant, checkedByRestaurant]);
    
    useEffect(() => {
        if (checkedByRestaurant) {
            setHasInProgressInvoice(_.find(invoices, {status: "inProgress", deleted: false}))
        }
    }, [invoices, checkedByRestaurant]);
    
    useEffect(() => {
        if (checkedByRestaurant == undefined) {
            setCheckedByRestaurant(localStorage.getItem('searchByRestaurant') != null ? localStorage.getItem('searchByRestaurant') == 'true' : false)
        }
    }, [checkedByRestaurant]);

    useEffect(() => {
        if (checkedByRestaurant && restaurant) {
            setFilters({...filters, restaurantId: restaurant._id})
        } else {
            const noId = new Object(filters)
            if (noId.restaurantId) {
                delete noId.restaurantId
                setFilters({...noId})
            }
        }
        if (selectedTab != "invoices" && !checkedByRestaurant) setSelectedTab('invoices')
    }, [checkedByRestaurant, restaurant])
    
    useEffect(() => {
        if (checkedByRestaurant == true || checkedByRestaurant == false) {
            localStorage.setItem('searchByRestaurant', checkedByRestaurant)
        }
        setSelectedInvoice(null)
    }, [checkedByRestaurant])


    const addTestPayment = async () => {
        if (restaurant && paymentAmount && paymentDate) {
            let payment = await addTestPaymentAPI(restaurant, paymentAmount, paymentDate)
            if (payment) {
                payments.removeItem(payment)
                
                let p = [...payments, payment]
                p = _.sortBy(p, ['_id'])
                setPayments(p)
                setPaymentAmount(parseInt(paymentAmount)+1)
            }
        } else {
            global.showNotification("Missing required input", "error")
        }
    }

    const addOrderToRestaurant = async () => {
        if (restaurant && selectedProductPackage && startDate) {
            let res = await addOrderToRestaurantApi(restaurant, selectedProductPackage, orderDate, startDate)
            if (res) {
                orders.removeItem(res.order)
                if (res.cancelledOrder) {
                    orders.removeItem(res.cancelledOrder)
                }

                let recs = [...orders, res.order]
                if (res.cancelledOrder) {
                    recs = [...recs, res.cancelledOrder]
                }
                
                recs = _.sortBy(recs, ['_id']).reverse()
                setOrders(recs)

                if (res.restaurantBillingType) {
                    let rest = _.find(restaurants, {id: restaurant._id})
                    rest.billingInfo.enabled = true
                    rest.billingInfo.billingType = res.restaurantBillingType
                    global.set({restaurant: rest})
                    global.set({restaurants})
                }
            }
        } else {
            global.showNotification("Missing required input", "error")
        }
        setOrderDate(null)
        setSelectedProductPackage(null)
    }

    const updateRestaurantBillingInfo = async () => {
        if (restaurant && (goLiveDate || billingDayOfMonth)) {
            let successful = await updateRestaurantBillingInfoApi({restaurant, goLiveDate, billingDayOfMonth})
            if (successful) {
                let rest = _.find(restaurants, {id: restaurant._id})
                rest.goLiveDate = goLiveDate
                setGoLiveDate(moment(rest.goLiveDate).utc())
                rest.billingInfo.billingDayOfMonth = billingDayOfMonth
                global.set({restaurant: rest})
                global.set({restaurants})

                global.showNotification("Restaurant info updated successfully", "info")
            } else {
                global.showNotification("An error occurred, please try again", "error")
            }
        } else {
            global.showNotification("Missing required input", "error")
        }
    }

    const cancelOrder = async (event) => {
        setAnchorEl(null)
        if (selectedRecordRow) {
            let orderResp = await cancelOrderApi(selectedRecordRow)
            if (orderResp) {
                orders.removeItem(orderResp)
                let recs = [...orders, orderResp]
                recs = _.sortBy(recs, ['_id']).reverse()
                setOrders(recs)
            }
        }
        hideMenu()
    }

    const deleteInvoice = async (event) => {
        setAnchorEl2(null)
        hideMenu()
        if (selectedRecordRow) {
            let resp = await deleteInvoiceApi(selectedRecordRow._id)
            if (resp && invoices) {
                invoices.removeItem(selectedRecordRow) //
                let recs = [...invoices]
                recs = _.sortBy(recs, ['_id']).reverse()
                setInvoices(recs)
                // getOrders()
            }
        }
    }

    const downloadInvoice = async (event) => {
        setAnchorEl2(null)
        hideMenu()
        if (selectedRecordRow) {
            const url = `${global.dashServerUrl}/pdf/invoice/${selectedRecordRow._id}`;
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                }
            }
            fetch(url, options, false)
            .then((res => res.blob()))
            .then(blob => {
                if (blob) {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = (restaurant.name + "-invoice-" + moment(selectedRecordRow.invoiceDate).utc().format('L') + ".pdf").replace(/[&']/g, "").replace(/[\s\"/]/g, "-");
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                } else {
                    //error
                }
            }).catch((err) => {
                log.error(err)
            })
        } else {
            log.info("PRINT INVOICE ERROR...InvoiceId not found");
        }
    }

    const removeAllDiscountsFromInvoice = async (event) => {
        setAnchorEl2(null)
        hideMenu()
        if (selectedRecordRow) {
            removeDiscountFromInvoice(selectedRecordRow._id, null)
        }
    }

    const removeDiscountFromInvoice = async (invoiceId, discountId) => {
        if (invoiceId) {
            let resp = await removeDiscountFromInvoiceApi(invoiceId, discountId)
            if (resp) {
                let invoicesNew = invoices.replaceItem(resp)
                invoicesNew = _.sortBy(invoicesNew, ['_id']).reverse()
                setInvoices(invoicesNew)
                setSelectedInvoice(resp)
                global.showNotification('Successfully removed discount', 'success')
            }
        }
    }
    
    const generateAllInvoicesForRestaurant = async () => {
        await generateInvoiceForRestaurant(true)
    }
    
    const setOrderTermDate = async (order, termDate) => {
        if (order) {
            let orderResp = await termOrderApi(order, termDate)
            if (orderResp) {
                orders.removeItem(orderResp)
                let recs = [...orders, orderResp]
                recs = _.sortBy(recs, ['_id']).reverse()
                setOrders(recs)
            }
        }
    }

    const setOrderStartDate = async (order, startDate) => {
        if (order) {
            let orderResp = await changeOrderStartDateApi(order, startDate)
            if (orderResp) {
                orders.removeItem(orderResp)
                let recs = [...orders, orderResp]
                recs = _.sortBy(recs, ['_id']).reverse()
                setOrders(recs)
            }
        }
    }

    const generateInvoiceForRestaurant = async (generateAll) => {
        if (restaurant) {
            setGeneratingFlag(true)
            if (!generateAll) {
                generateAll = false
            }
            let data = await generateInvoiceForRestaurantApi(restaurant, generateAll, asOfDate)
            if (data) {
                if (generateAll) {
                    document.location = document.location
                } else {
                    let invoiceList = data.invoices ? data.invoices : [] 
                    if (data.invoice) {
                        invoiceList.push(data.invoice)
                    }
                    setHasInProgressInvoice(false)
                    let lastInvoice
                    for (const invoice of invoiceList) {
                        lastInvoice = invoice
                        // if (invoice.usageDetails && invoice.usageDetails.transactions) {
                            // for (let payment of invoice.usageDetails.transactions) { 
                            //     _.remove(payments, {_id: payment})
                            // }
                            // setPayments([...payments])
                        // }
                        invoices.removeItem(invoice)
                        if (invoice.status == "inProgress") {
                            setHasInProgressInvoice(true)
                        }
                        let inv = _.sortBy([...invoices, invoice], ['_id']).reverse()
                        setInvoices(inv)
                    }
                    setSelectedInvoice(lastInvoice)
                }
            }
            setGeneratingFlag(false)
        } else {
            global.showNotification("Please select a restaurant first", "info")
        }
    }

    const payInvoice = async () => {
        if (selectedInvoice) {
            setPayingFlag(true)
            try {
                let data = await payInvoiceApi(selectedInvoice._id)
                console.log("payInvoice RESPONSE: " + JSON.stringify(data));
                if (data && data.invoice) {
                    invoices.removeItem(data.invoice)
                    let inv = _.sortBy([...invoices, data.invoice], ['_id']).reverse()
                    setInvoices(inv)
                    setSelectedInvoice(data.invoice)
                }
            } catch (err) {
                global.showNotification(err, "error")
            }
            setPayingFlag(false)
        } else {
            global.showNotification("Please select an invoice first", "info")
        }
    }

    const fetchData = async () => {
        let productPackageList = await getProductPackageList()
        if (productPackageList) {
            setProductPackages(productPackageList)
        }

    }

    const updateSelectedRestaurant = async (rest) => {
        if (restaurant && checkedByRestaurant) {
            setOrders(null)
            // setPayments(null)
            setInvoices(null)
            setSelectedInvoice(null)
            setPrimaryContactInfo({...rest.primaryContactInfo})
            if (rest) {
                setPage(0)
                // setSelectedRestaurant(restaurant)
                setGoLiveDate(moment(rest.goLiveDate).utc())
                setBillingDayOfMonth(rest.billingInfo.billingDayOfMonth ? rest.billingInfo.billingDayOfMonth : 1)
                getOrders()
            }
        }
    }

    const getOrders = async () => {
        if (restaurant) {
            let res = await getRestaurantOrdersAndInvoices(restaurant, false, true)
            let orders = res.orders ? _.sortBy(res.orders, ['_id']).reverse() : []

            setOrders(orders)

            if (orders) {
                setSelectedOrder(orders[0])
            }
            setPage(0)
        }
    }

    const getFilteredInvoiceList = async () => {
        setGeneratingFlag(true)
        let filtersCopy = {...filters, type:'visible'}
        let invoices = await getInvoiceList(filtersCopy, true)
        return invoices
    }

    useEffect(()=> {
        let isSubscribed = true
        getFilteredInvoiceList().then(invoices=> {
            if (isSubscribed) {
                setGeneratingFlag(false)
                setInvoices(invoices)
            }
        })
        return () => isSubscribed = false // this is to only track the resp of the latest filter
    }, [filters])

    const showOrdersMenu = (event, order) => {
        setSelectedRecordRow(order)
        console.log("order")
        console.log(order)
        setAnchorEl(event.currentTarget);
    }

    const showInvoicesMenu = (event, invoice) => {
        setSelectedRecordRow(invoice)
        console.log("invoice")
        console.log(invoice)
        setAnchorEl2(event.currentTarget);
    }

    const showWindowPrimaryContact = () => {
        setShowAddMainContactWindow(true)
    }

    const closeAllWindows = () => {
        setPrimaryContactInfo({...restaurant.primaryContactInfo})
        setShowAddMainContactWindow(false)
    }

    const updatePrimaryContact = async () => {
        if (restaurant && primaryContactInfo) {
            let restIn = await updateRestaurantPrimaryContactApi({restaurant, primaryContactInfo})
            if (restIn) {
                let rest = _.find(restaurants, {id: restIn._id})
                if (rest) {
                    rest.primaryContactAccount = restIn.primaryContactAccount
                    rest.primaryContactInfo = restIn.primaryContactInfo
                    setPrimaryContactInfo(restIn.primaryContactInfo)
                    global.set({restaurant: rest})
                    global.set({restaurants})
                    closeAllWindows()
                }
                global.showNotification("Restaurant primary contact updated successfully", "info")
            } else {
                global.showNotification("An error occurred, please try again", "error")
            }
        } else {
            global.showNotification("Missing required input", "error")
        }
    }

    const hideMenu = (event) => {
        setAnchorEl(null);
        setAnchorEl2(null)
    }
    
    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    useState(()=> {
        if (selectedTab !== 'invoices') setExpanded(null)
    }, [selectedTab])

   
    // console.log({invoices})

    const showFilterList = () => {
        let filterList = filters
        delete filterList.dform
        let formattedFilterList = []
        for (const [key, value] of Object.entries(filterList)) {
            formattedFilterList.push(` ${key}: ${value} | `)
        }
        return formattedFilterList;
    }

    const handleOpen = async (i) => {
        if (i.isMasterInvoice) {
            let masterInvoice = await getInvoice(i, true)
            setSelectedInvoice(masterInvoice);
        } else {
            setSelectedInvoice(i);
        } 
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    if (!restaurant) {
        return (
            <div style={{height:'100%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <div>
                    Select a restaurant to view more information
                </div>
            </div>
        )
    }

    const clearFilters = () => {
        if (checkedByRestaurant) {
            setFilters({restaurantId: restaurant._id})
        } else {
            setFilters({})
        }
    }



    return (
        <div className={classes.root}>
            {restaurant && 
                <div style={{display:'flex', justifyContent:'flex-start', flexDirection:'column', height: '100%', width:'100%', overflow:'hidden'}}>
                    <Grid item>
                        <div style={{display:'flex', justifyContent: 'space-between', alignItems:'center', width:'100%', paddingLeft:15}}>
                            <div style={{width:'20%', display:'flex', flexDirection:'row', alignItems:'center'}}>
                                <Typography variant="h6">Invoices</Typography>
                                <FormControl component="fieldset">
                                    {/* <FormLabel style={{fontSize:12, paddingTop:'7px'}} component="legend">Browse by</FormLabel> */}
                                    <ToggleButtonGroup value={checkedByRestaurant} onChange={(e,v)=> setCheckedByRestaurant(v)} style={{width: '100%', paddingLeft:5}} exclusive> 
                                        <ToggleButton value={false} size="small">
                                            <Typography color="primary">All</Typography>
                                        </ToggleButton>
                                        <ToggleButton value={true} size="small">
                                            <Typography color="primary">Site</Typography>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </FormControl>
                            </div>
                            {/* <FormControlLabel
                                style={{padding:'0px 4px'}}
                                control={
                                    <Switch checked={checkedByRestaurant} 
                                        color="primary"
                                        onChange={(e)=> {
                                            e.stopPropagation()
                                            setCheckedByRestaurant(!checkedByRestaurant)
                                        }} name="by restaurant"/>}
                                label="By Restaurant"
                            /> */}
                            <div style={{color:'var(--light-gray', maxWidth:"50%", overflow: 'hidden', textOverflow: 'ellipsis'}} >
                                {showFilterList()}
                            </div>
                            <div style={{float:'right'}}>
                                <Button size="small" style={{color:'var(--light-gray', height:24}} 
                                    onClick={(e)=> {
                                        e.stopPropagation()
                                        clearFilters()
                                    }}>Clear</Button>
                                    {selectedTab === 'invoices' &&
                                        <Button size="small"  onClick={()=> setExpanded(expanded === 'panel1' ? null : 'panel1')}>Filters</Button>
                                    }
                                    <IconButton color="primary"
                                        onClick={(e) => { 
                                            e.stopPropagation()
                                            props.resize('bottom') 
                                        }} >
                                        <ZoomOutMapIcon/>
                                    </IconButton>
                            </div>
                        </div>

                        <Accordion elevation={0} style={{padding:0, height:expanded === 'panel1' ? undefined : 0}} expanded={expanded === 'panel1'} onChange={()=>handleChange('panel1')}>
                            <AccordionSummary
                                style={{height: 0, display:'none'}}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                            </AccordionSummary>
                            <AccordionDetails style={{ backgroundColor: 'var(--background)', borderBottom:'1px solid #ccc', borderTop:'1px solid #ccc' }}>
                                <div style={{display:'flex', justifyContent:'flex-start', alignItems:'flex-start', alignContent:'center', width:'100%', flexWrap:'wrap', fontSize:10, padding:4,}}>
                                {/* FILTERS  */}
                                    <div style={{display:'flex', flexDirection:'column'}}>

                                        <FormControl fullWidth>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    autoOk
                                                    initialFocusedDate=""
                                                    showTodayButton
                                                    format="MM/dd/yyyy"
                                                    id="date-picker-inline"
                                                    label="Invoice Date"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}    
                                                    // style={{width: 150}}
                                                    value={filters.invoiceDate ? filters.invoiceDate : null}
                                                    onChange={date => {setFilters({...filters, invoiceDate: date})}} 
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </FormControl>

                                        <FormControl fullWidth>
                                            <TextField 
                                                style={{marginTop:8}}
                                                name="minAmount" 
                                                label="Min Amount" 
                                                autoComplete='off' 
                                                type="number" 
                                                InputLabelProps={{shrink: true}} 
                                                InputProps={{margin:'dense', startAdornment: <InputAdornment position="start">$</InputAdornment>}}
                                                autoFocus 
                                                defaultValue={filters.minAmount}
                                                onChange={e => {setFilters({...filters, minAmount: e.target.value})}} 
                                            />
                                        </FormControl>
                                    </div>
                                    {/* <div style={{padding:4}}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Type</FormLabel>
                                            <RadioGroup value={filters.type ? filters.type : 'all'} aria-label="type" name="customized-radios" onClick={e => {setFilters({...filters, type: e.target.value})}} style={{paddingLeft: 10}}>
                                                <FormControlLabel value="all" control={<Radio size="small" />} label="All" />
                                                <FormControlLabel value="visible" control={<Radio size="small" />} label="Client Visible" />
                                                <FormControlLabel value="internal" control={<Radio size="small" />} label="Internal" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div> */}
                                    <div style={{padding:4}}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Invoice Status:</FormLabel>
                                            <RadioGroup value={filters.invoiceStatus ? filters.invoiceStatus : 'all'} aria-label="invoiceStatus" name="customized-radios" onClick={e => {setFilters({...filters, invoiceStatus: e.target.value})}} style={{paddingLeft: 10}}>
                                                <FormControlLabel value="all" control={<Radio size="small" />} label="All" />
                                                <FormControlLabel value="inProgress" control={<Radio size="small" />} label="In-Progress" />
                                                <FormControlLabel value="open" control={<Radio size="small" />} label="Open" />
                                                <FormControlLabel value="closed" control={<Radio size="small" />} label="Closed" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    {!checkedByRestaurant &&
                                        <div style={{padding:4}}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">Invoice Applies To:</FormLabel>
                                                <RadioGroup value={filters.invoiceAppliesTo ? filters.invoiceAppliesTo : 'all'} aria-label="invoiceAppliesTo" name="customized-radios" onClick={e => {setFilters({...filters, invoiceAppliesTo: e.target.value})}} style={{paddingLeft: 10}}>
                                                    <FormControlLabel value="all" control={<Radio size="small" />} label="All" />
                                                    <FormControlLabel value="site" control={<Radio size="small" />} label="Site" />
                                                    <FormControlLabel value="siteGroup" control={<Radio size="small" />} label="Site Group" />
                                                    <FormControlLabel value="agency" control={<Radio size="small" />} label="Agency" />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    }
                                    <div style={{padding:4}}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Payment Status:</FormLabel>
                                            <RadioGroup value={filters.paymentStatus ? filters.paymentStatus : 'all'} aria-label="paymentStatus" name="customized-radios" onClick={e => {setFilters({...filters, paymentStatus: e.target.value})}} style={{paddingLeft: 10}}>
                                                <FormControlLabel value="all" control={<Radio size="small" />} label="All" />
                                                <FormControlLabel value="outstanding" control={<Radio size="small" />} label="Outstanding" />
                                                <FormControlLabel value="paid" control={<Radio size="small" />} label="Paid" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div style={{padding:4}}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Payment Verification:</FormLabel>
                                            <RadioGroup value={filters.paymentVerification ? filters.paymentVerification : 'all'} aria-label="paymentVerification" name="customized-radios" onClick={e => {setFilters({...filters, paymentVerification: e.target.value})}} style={{paddingLeft: 10}}>
                                                <FormControlLabel value="all" control={<Radio size="small" />} label="All" />
                                                <FormControlLabel value="verified" control={<Radio size="small" />} label="Verified" />
                                                <FormControlLabel value="failed" control={<Radio size="small" />} label="Failed" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div style={{padding:4}}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Primary Contact:</FormLabel>
                                            <RadioGroup value={filters.hasPrimaryContact ? filters.hasPrimaryContact : 'all'} aria-label="hasPrimaryContact" name="customized-radios" onClick={e => {setFilters({...filters, hasPrimaryContact: e.target.value})}} style={{paddingLeft: 10}}>
                                                <FormControlLabel value="all" control={<Radio size="small" />} label="All" />
                                                <FormControlLabel value="exists" control={<Radio size="small" />} label="Exists" />
                                                <FormControlLabel value="missing" control={<Radio size="small" />} label="Missing" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div style={{padding:4}}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Payment Method:</FormLabel>
                                            <RadioGroup value={filters.hasPaymentMethod ? filters.hasPaymentMethod : 'all'} aria-label="hasPaymentMethod" name="customized-radios" onClick={e => {setFilters({...filters, hasPaymentMethod: e.target.value})}} style={{paddingLeft: 10}}>
                                                <FormControlLabel value="all" control={<Radio size="small" />} label="All" />
                                                <FormControlLabel value="exists" control={<Radio size="small" />} label="Exists" />
                                                <FormControlLabel value="missing" control={<Radio size="small" />} label="Missing" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>                                
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid container item direction="column" style={{height: '100%', width:'100%', overflow:'hidden'}}>
                        {checkedByRestaurant &&
                            <Tabs
                                value={selectedTab}
                                onChange={(e, value) => {setSelectedTab(value)}}
                                indicatorColor="primary"
                                textColor="primary"
                                style={{marginBottom: 1, height:50, borderBottom:'solid 1px #ccc'}}
                            >   
                                {checkedByRestaurant && <Tab value="invoices" label="invoices" {...a11yProps(2)}/>}
                                {checkedByRestaurant && <Tab value="general" label="General" {...a11yProps(0)} onClick={()=>setExpanded(null)} disabled={!checkedByRestaurant}/>}
                                {checkedByRestaurant && <Tab value="orders" label="Orders" {...a11yProps(1)} onClick={()=>setExpanded(null)} disabled={!checkedByRestaurant}/>}
                            </Tabs>
                        }
                        <Grid xs item container style={{paddingTop: 5, overflowY: 'auto', overflowX: 'hidden', width: '100%', display:props.collapse? 'none' : 'inherit'}}>
                            {/* <Grid item style={{height:60}}> */}
                                
                            {/* </Grid> */}
                            {selectedTab == "general" &&
                                <Grid item xs={12} container>
                                    <Grid item xs>
                                        <Grid xs={12} container spacing={1} style={{padding: 25}}>
                                            <Grid item style={{width: 100}}></Grid>
                                            <Grid xs item>
                                                <Typography align="center" variant="h5" style={{paddingTop: 15, paddingBottom: 5}}>{restaurant.name}</Typography>
                                                <Typography align="center" variant="subtitle1" style={{paddingBottom: 15}}>
                                                    {restaurant.locationInfo && 
                                                        <>
                                                            {restaurant.locationInfo.addressLine1} {restaurant.locationInfo.addressLine2}<br/>
                                                            {restaurant.locationInfo.city}, {restaurant.locationInfo.state} {restaurant.locationInfo.zipCode}
                                                        </>
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid xs={12} container spacing={3} style={{padding: 25}} justify="flex-start" alignItems="flex-start">
                                            <Grid xs={4} item container spacing={1}>
                                                <Grid xs={12} sm={6} item>Status</Grid>
                                                <Grid xs={12} sm={6} item>{restaurant.status ? restaurant.status.toProperCase() : ''}</Grid>

                                                <Grid xs={12} sm={6} item>Enrolled On</Grid>
                                                <Grid xs={12} sm={6} item>{restaurant.dateEnrolled ? moment(restaurant.dateEnrolled).utc().format("MM/DD/YYYY") : ''}</Grid>

                                                <Grid xs={12} sm={6} item>Installed On</Grid>
                                                <Grid xs={12} sm={6} item>{restaurant.dateInstalled ? moment(restaurant.dateInstalled).utc().format("MM/DD/YYYY") : ''}</Grid>

                                                <Grid xs={12} sm={6} item>Live On:</Grid>
                                                <Grid xs={12} sm={6} item>{restaurant.goLiveDate ? moment(restaurant.goLiveDate).utc().format("MM/DD/YYYY") : ''}</Grid>

                                                <Grid xs={12} sm={6} item>Billing Type</Grid>
                                                <Grid xs={12} sm={6} item>{restaurant.billingInfo && restaurant.billingInfo.billingType ? restaurant.billingInfo.billingType.toProperCase() : ''}</Grid>

                                            </Grid>

                                            <Grid xs={3} item container spacing={1}>
                                                <Grid xs={12} item>
                                                    <DFlag checked={restaurant.billingInfo.enabled} />
                                                    <Typography variant="inline">
                                                        Billing Enabled
                                                    </Typography>
                                                </Grid>

                                                <Grid xs={12} item>
                                                    <DFlag checked={restaurant.primaryContactAccount} />
                                                    <Typography variant="inline">
                                                        Has Primary Contact
                                                    </Typography>
                                                </Grid>

                                                <Grid xs={12} item>
                                                    <DFlag checked={restaurant.billingInfo.encryptedCard} />
                                                    <Typography variant="inline">
                                                        Has Payment Info
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid xs item>

                                                    <Paper square elevation={2} style={{padding: 16}}>
                                                        <Typography variant="h6" style={{fontWeight: 400}}>Primary Contact</Typography>

                                                        {restaurant.primaryContactAccount && 
                                                            <>
                                                                <Grid xs={12} item style={{paddingTop: 5, paddingLeft: 10}}>
                                                                    {restaurant.primaryContactAccount.fullName}
                                                                </Grid>
                                                                {restaurant.primaryContactInfo.title && 
                                                                    <Grid xs={12} item style={{paddingTop: 5, paddingLeft: 10}}>
                                                                        {restaurant.primaryContactInfo.title}
                                                                    </Grid>
                                                                }
                                                                {restaurant.primaryContactAccount.phoneNumber && 
                                                                    <Grid xs={12} item style={{paddingTop: 5, paddingLeft: 10}}>
                                                                        {restaurant.primaryContactAccount.phoneNumber}
                                                                    </Grid>
                                                                }
                                                                {restaurant.primaryContactAccount.email && 
                                                                    <Grid xs={12} item style={{paddingTop: 5, paddingLeft: 10}}>
                                                                        {restaurant.primaryContactAccount.email}
                                                                    </Grid>
                                                                }
                                                            </>
                                                        }

                                                        {!restaurant.primaryContactAccount && 
                                                            <Grid xs={12} item style={{paddingTop: 15, color: 'red'}}>
                                                                PRIMARY CONTACT INFO IS MISSING
                                                            </Grid>
                                                        }
                                                        {/* {global.hasPermission('admin.billing.edit') &&
                                                            <Grid xs={12} item style={{paddingTop: 5}}>
                                                                <Button variant="contained" onClick={() => showWindowPrimaryContact() } color="primary">Update Primary Contact</Button>
                                                            </Grid>
                                                        } */}
                                                    </Paper>


                                                    <Paper square elevation={2} style={{padding: 16, marginTop: 25}}>
                                                        <Typography variant="h6" style={{fontWeight: 400}}>Payment Info</Typography>
                                                        {!restaurant.billingInfo.enabled && 
                                                            <Grid xs={12} item style={{paddingTop: 15}}>
                                                                BILLING IS NOT ENABLED
                                                            </Grid>
                                                        }
                                                        {restaurant.billingInfo.enabled && restaurant.billingInfo.billingType.toUpperCase() != "DIRECT" && 
                                                            <Grid xs={12} item style={{paddingTop: 15}}>
                                                                BILLING IS NOT DIRECT
                                                            </Grid>
                                                        }
                                                        {restaurant.billingInfo.enabled && restaurant.billingInfo.billingType.toUpperCase() == "DIRECT" && restaurant.billingInfo.encryptedCardStatus && 
                                                            <>
                                                                <Grid xs={12} item style={{paddingTop: 15, color: 'red'}}>
                                                                    PAYMENT INFO IS {restaurant.billingInfo.encryptedCardStatus}                                                    
                                                                </Grid>
                                                                {global.hasPermission('admin.billing.edit') &&
                                                                    <Grid xs={12} item style={{paddingTop: 5}}>
                                                                        <Button variant="contained" disabled={!restaurant.primaryContactAccount || !restaurant.primaryContactAccount.email || paymentEmailJustSent} onClick={() => sendEmailUpdatePaymentInfo() } color="primary">Send Email - Update Payment Info</Button>
                                                                    </Grid>
                                                                }

                                                                {global.hasPermission('admin.billing.edit') && restaurant.billingInfo.encryptedCardLastEmailNotificationSent &&
                                                                    <Grid xs={12} item style={{paddingTop: 5}}>
                                                                        Last sent: {moment(restaurant.billingInfo.encryptedCardLastEmailNotificationSent).fromNow()}
                                                                    </Grid>
                                                                }
                                                            </>
                                                        }

                                                    </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }

                            {selectedTab == "invoices" &&
                                <Grid item xs={12} container alignItems="flex-start">
                                    <Grid item xs={12}>
                                        {global.hasPermission('admin.billing.edit') && ((orders && _.find(orders, {cancelled: false})) || hasInProgressInvoice) && 
                                            <Grid item xs={12} container justify="flex-start" alignItems="flex-end" style={{ marginBottom: 15, marginLeft:15}}>
                                                {checkedByRestaurant && <>
                                                    <Grid item style={{marginRight: 15}}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                autoOk
                                                                initialFocusedDate=""
                                                                showTodayButton
                                                                format="MM/dd/yyyy"
                                                                id="date-picker-inline"
                                                                label="Run Date"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}    
                                                                style={{width: 150}}
                                                                value={asOfDate}
                                                                disabled={generatingFlag || (!invoices || invoices.length < 1) || (!orders || orders.length < 1)}
                                                                onChange={date => { setAsOfDate(date) }}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Grid>

                                                    <Grid item style={{marginRight: 15}}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={generatingFlag || (!invoices || invoices.length < 1) || (!orders || orders.length < 1)}
                                                            onClick={() => {generateInvoiceForRestaurant(false)}}
                                                        >
                                                            {hasInProgressInvoice ? "Update Invoice" : "Generate Next Invoice"}
                                                        </Button>
                                                    </Grid>

                                                    <Grid item style={{marginRight: 15}}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={generatingFlag || (!orders || orders.length < 1)}
                                                            onClick={generateAllInvoicesForRestaurant}
                                                        >
                                                            {"Generate All Invoices"}
                                                        </Button>
                                                    </Grid>
                                                </>}

                                                {selectedInvoice && 
                                                    <Grid item style={{marginRight: 15}}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={!((selectedInvoice && selectedInvoice.status == 'open' && (!selectedInvoice.payment.paid || selectedInvoice.payment.verified == false)) && !payingFlag && selectedInvoice.totals.total > 0)}
                                                            onClick={payInvoice}
                                                        >
                                                            {"Pay Invoice"}
                                                        </Button>
                                                    </Grid>
                                                }

                                                <Grid item style={{ border:'1px solid var(--dash-purple)', borderRadius:4, padding:2, display:'flex', justifyContent:'center', alignItems:'center', marginRight: 10}}>
                                                
                                                    <FormControl component="fieldset">
                                                        <input type='file' accept={'.csv'}
                                                            onChange={(e) => {
                                                                if(e.target.files && e.target.files.length > 0){
                                                                    if(e.target.files[0].size <= (1024*1024*10) ){ //10MB
                                                                        setFile(e.target.files[0])
                                                                    } else {
                                                                        global.showNotification('The file size is too large, 10MB limit', 'error')
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <Button
                                                        align="right"
                                                        disabled={!file}
                                                        size="small"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={upload}
                                                    >
                                                        Upload
                                                    </Button>
                                        
                                                </Grid>

                                                <FormAddDiscountToInvoice invoice={selectedInvoice} open={showDiscountForm} close={(invoice) => { 
                                                    if (invoice) {
                                                        invoices.removeItem(invoice)
                                                        let recs = [...invoices, invoice]
                                                        recs = _.sortBy(recs, ['_id']).reverse()
                                                        setInvoices(recs)
                                                        setSelectedInvoice(invoice)
                                                    }; setShowDiscountForm(false) }} />

                                            </Grid>
                                        }
                                        

                                        <Table size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell />
                                                    <TableCell>
                                                        Invoice #
                                                    </TableCell>
                                                    <TableCell>
                                                        From Date
                                                    </TableCell>
                                                    <TableCell>
                                                        Thru Date
                                                    </TableCell>
                                                    <TableCell>
                                                        Invoice Date
                                                    </TableCell>
                                                    {!checkedByRestaurant &&
                                                        <TableCell>
                                                            Restaurant
                                                        </TableCell>
                                                    }
                                                    <TableCell>
                                                        Applies To
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Primary Contact
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Payment Method
                                                    </TableCell>
                                                    <TableCell>
                                                        Amount
                                                    </TableCell>
                                                    <TableCell>
                                                        Status
                                                    </TableCell>
                                                    <TableCell>
                                                        Paid
                                                    </TableCell>
                                                    <TableCell>
                                                        Verified
                                                    </TableCell>
                                                    <TableCell>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            {(!invoices || (generatingFlag)) &&  
                                                <TableRow><TableCell align="center" colSpan={13}><LinearProgress style={{marginTop: 10, marginBottom: 10, paddingTop: 2, paddingBottom: 2}} /></TableCell></TableRow>
                                            }
                                            {invoices && invoices.map(i => {
                                                return (
                                                    <TableRow id={i._id} selected={selectedInvoice && selectedInvoice._id == i._id} onClick={() => setSelectedInvoice(i)} onDoubleClick={()=>handleOpen(i)} style={{cursor: 'pointer'}} className={i.deleted && classes.deletedRow}>
                                                    {/* <TableRow id={i._id} selected={selectedInvoice && selectedInvoice._id == i._id} onClick={(event) => setSelectedInvoice(i)} style={{cursor: 'pointer'}} className={i.deleted && classes.deletedRow}> */}
                                                        <TableCell style={{paddingRight: 0}} align="right">
                                                            {!i.deleted && generatingFlag && i.status.toUpperCase() == "INPROGRESS" && <LinearProgress style={{paddingTop: 1, paddingBottom: 1, marginTop: 10, marginBottom: 12}} />}
                                                            {!i.deleted && !generatingFlag && i.status.toUpperCase() == "INPROGRESS" && <InvoicePendingIcon fontSize="medium" />}
                                                            {!i.deleted && i.status.toUpperCase() == "OPEN" && <InvoiceOpenIcon fontSize="medium" />}
                                                            {!i.deleted && i.status.toUpperCase() == "CLOSED" && <InvoiceClosedIcon fontSize="medium" />}
                                                        </TableCell>
                                                        <TableCell>
                                                            {i.invoiceNumber}
                                                        </TableCell>
                                                        <TableCell>
                                                            {moment(i.fromDate).utc().format('L')}
                                                        </TableCell>
                                                        <TableCell>
                                                            {moment(i.thruDate).utc().format('L')}
                                                        </TableCell>
                                                        <TableCell>
                                                            {moment(i.invoiceDate).utc().format('L')}
                                                        </TableCell>
                                                        {!checkedByRestaurant &&
                                                            <TableCell>
                                                                {i.restaurant && i.restaurant.name}
                                                                {i.isMasterInvoice && i.restaurantGroup && i.restaurantGroup.name + " (Group)"}
                                                                {i.isMasterInvoice && i.agency && "(Agency)"}
                                                            </TableCell>
                                                        }
                                                        <TableCell>
                                                            {i.restaurantGroup && i.isMasterInvoice && "Site Group"}
                                                            {i.restaurant && !i.isMasterInvoice && "Site"}
                                                            {i.agency && i.isMasterInvoice && "Agency"}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {i.primaryContactAccountId ? <CheckCircleOutlineIcon /> : ''}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {i.paymentMethodId ? <CheckCircleOutlineIcon /> : ''}
                                                        </TableCell>
                                                        <TableCell>
                                                            ${(i.totals.total/100).toFixed(2)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {i.status}
                                                        </TableCell>
                                                        <TableCell>
                                                            {i.payment.paid ? <CheckCircleOutlineIcon /> : ''}
                                                        </TableCell>
                                                        <TableCell>
                                                            {i.payment.verified ? <CheckCircleOutlineIcon /> : ''}
                                                        </TableCell>
                                                        <TableCell>
                                                            {!i.deleted && global.hasPermission('admin.billing.edit') && 
                                                                <IconButton aria-label="more" aria-controls="invoices-menu" aria-haspopup="true" size="small" onClick={(e) => showInvoicesMenu(e, i)}>
                                                                    <MoreVertIcon />
                                                                </IconButton>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </Table>
                                    </Grid>
                                </Grid>
                            }

                            {selectedTab == "orders" &&
                                <Grid item xs={12} container direction="column" justify="flex-start" alignItems="flex-start">
                                    {global.hasPermission('admin.billing.edit') && 
                                        <Grid item container>
                                            <div style={{width: '100%'}}>
                                                <Grid container justify="space-around">

                                                    { false && 
                                                        <Grid item xs container justify="flex-start" spacing={3} alignItems="flex-end" style={{paddingBottom: 15}}>
                                                            <Grid item>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                    <KeyboardDatePicker
                                                                        autoOk
                                                                        initialFocusedDate=""
                                                                        showTodayButton
                                                                        format="MM/dd/yyyy"
                                                                        id="goLiveDateInput"
                                                                        placeholder=""
                                                                        label="Go Live Date"
                                                                        value={goLiveDate}
                                                                        onChange={val => { setGoLiveDate(val) }}
                                                                        style={{width: 150}}
                                                                        KeyboardButtonProps={{
                                                                            'aria-label': 'Go Live Date',
                                                                        }}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </Grid>
                                                            <Grid item>
                                                                <TextField
                                                                    id="billingDayOfMonthInput"
                                                                    label="Billing Day of Month"
                                                                    value={billingDayOfMonth}
                                                                    onChange={(e) => setBillingDayOfMonth(e.target.value)}
                                                                    type="number"
                                                                    style={{width: 150}}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <Button variant="contained" color="primary" disabled={false} onClick={() => updateRestaurantBillingInfo()}>
                                                                    Update
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    }

                                                    <Grid item xs container justify="flex-start" spacing={3} alignItems="flex-end" style={{paddingLeft: 15, paddingBottom: 15}}>
                                                        <Grid item>
                                                            <InputLabel shrink>Select Product Package</InputLabel>
                                                            <SelectMui
                                                                label="Product Package"
                                                                disabled={false}
                                                                className={classes.muiSelect}
                                                                value={selectedProductPackage}
                                                                onChange={(e) => setSelectedProductPackage(e.target.value)}>
                                                                {productPackages.map(r => {
                                                                    return (
                                                                        <MenuItem value={r}>{r.name}{r.description ? ` (${r.description})` : ''}</MenuItem>
                                                                    )
                                                                })}
                                                            </SelectMui>
                                                        </Grid>
                                                        <Grid item>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                <KeyboardDatePicker
                                                                    autoOk
                                                                    initialFocusedDate=""
                                                                    showTodayButton
                                                                    disableToolbar
                                                                    // variant="inline"
                                                                    format="MM/dd/yyyy"
                                                                    disabled={false}
                                                                    // margin="normal"
                                                                    id="startDateInput"
                                                                    label="Start Date"
                                                                    value={startDate}
                                                                    onChange={date => { setStartDate(date) }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}    
                                                                    style={{width: 150}}
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'change date',
                                                                    }}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </Grid>
                                                        <Grid item>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                <KeyboardDatePicker
                                                                    autoOk
                                                                    initialFocusedDate=""
                                                                    showTodayButton
                                                                    disableToolbar
                                                                    // variant="inline"
                                                                    format="MM/dd/yyyy"
                                                                    disabled={false}
                                                                    // margin="normal"
                                                                    id="orderDateInput"
                                                                    label="Order Date"
                                                                    value={orderDate}
                                                                    onChange={date => { setOrderDate(date) }}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}    
                                                                    style={{width: 150}}
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'change date',
                                                                    }}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={!startDate || !selectedProductPackage}
                                                                onClick={addOrderToRestaurant}
                                                            >
                                                                Add
                                                            </Button>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </div>
                                        </Grid>
                                    }

                                    {orders &&
                                        <Grid item container alignItems="flex-start">
                                            <Table size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center">
                                                            Status
                                                        </TableCell>
                                                        <TableCell>
                                                            Order #
                                                        </TableCell>
                                                        {/* <TableCell>
                                                            Eff Date
                                                        </TableCell> */}
                                                        <TableCell>
                                                            Start Date
                                                        </TableCell>
                                                        <TableCell>
                                                            Term Date
                                                        </TableCell>
                                                        <TableCell>
                                                            Package Name
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {!orders && 
                                                    <TableRow><TableCell align="center" colSpan={3}><LinearProgress style={{marginTop: 10, marginBottom: 10, paddingTop: 2, paddingBottom: 2}} /></TableCell></TableRow>
                                                }
                                                {orders && orders.map((o) => {
                                                    return (
                                                        <>
                                                            {o.orderProductPackages && o.orderProductPackages.map((opp, index) => {
                                                                console.log('twat', JSON.stringify(opp))
                                                                let invoicesForOpp = []
                                                                if (opp.orderProducts) {
                                                                    opp.orderProducts.forEach(op => {
                                                                        invoicesForOpp = invoicesForOpp.concat(op.addedToInvoices)
                                                                    })
                                                                }
                                                                let hasPaidInvoices = invoices && invoices.find(i => invoicesForOpp.indexOf(i._id) >= 0 && i.payment && i.payment.paid)
                                                                return (
                                                                    <TableRow className={(o.cancelled || opp.terminationDate) && classes.deletedRow}>
                                                                        {index == 0 &&
                                                                            <TableCell align="center" rowSpan={o.orderProductPackages.length}>
                                                                                {!o.cancelled && <CheckCircleOutlineIcon color="primary" />}
                                                                            </TableCell>
                                                                        }
                                                                        {index == 0 &&
                                                                            <TableCell rowSpan={o.orderProductPackages.length}>
                                                                                {o.orderNumber}
                                                                            </TableCell>
                                                                        }
                                                                        {/* <TableCell>
                                                                            {moment(opp.effectiveDate ? opp.effectiveDate : opp.createdAt).utc().format('L')}
                                                                        </TableCell> */}
                                                                        <TableCell>
                                                                            {/* {o.startDate ? moment(o.startDate).utc().format('L') : ''} */}
                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                <KeyboardDatePicker
                                                                                    autoOk
                                                                                    initialFocusedDate=""
                                                                                    showTodayButton
                                                                                    disablePast
                                                                                    disabled={!!hasPaidInvoices}
                                                                                    minDateMessage=""
                                                                                    disableToolbar
                                                                                    // variant="inline"
                                                                                    format="MM/dd/yyyy"
                                                                                    // margin="normal"
                                                                                    id="startDateInput"
                                                                                    value={o.startDate}
                                                                                    onChange={date => { setOrderStartDate(o, date) }}
                                                                                    InputLabelProps={{
                                                                                        shrink: true,
                                                                                    }}    
                                                                                    style={{width: 150}}
                                                                                    KeyboardButtonProps={{
                                                                                        'aria-label': 'change date',
                                                                                    }}
                                                                                />
                                                                            </MuiPickersUtilsProvider>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                <KeyboardDatePicker
                                                                                    autoOk
                                                                                    initialFocusedDate=""
                                                                                    showTodayButton
                                                                                    disablePast
                                                                                    minDateMessage=""
                                                                                    // variant="inline"
                                                                                    format="MM/dd/yyyy"
                                                                                    // margin="normal"
                                                                                    id="paymentDateInput"
                                                                                    value={o.terminationDate}
                                                                                    onChange={date => { setOrderTermDate(o, date) }}
                                                                                    InputLabelProps={{
                                                                                        shrink: true,
                                                                                    }}    
                                                                                    style={{width: 150}}
                                                                                    KeyboardButtonProps={{
                                                                                        'aria-label': 'change date',
                                                                                    }}
                                                                                />
                                                                            </MuiPickersUtilsProvider>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {opp.name} ({opp.description})
                                                                        </TableCell>
                                                                        {index == 0 &&
                                                                            <TableCell rowSpan={o.orderProductPackages.length}>
                                                                                {!o.cancelled && global.hasPermission('admin.billing.edit') && 
                                                                                    <IconButton aria-label="more" aria-controls="orders-menu" aria-haspopup="true" size="small" onClick={(e) => showOrdersMenu(e, o)}>
                                                                                        <MoreVertIcon />
                                                                                    </IconButton>
                                                                                }
                                                                            </TableCell>
                                                                        }
                                                                    </TableRow>
                                                                )
                                                            })}

                                                        </>
                                                    )
                                                })}
                                            </Table>
                                        </Grid>
                                    }
                                </Grid>
                            }

                            {selectedTab == "unassigned" &&
                                <Grid item xs={12} container>
                                    <Paper elevation={1} style={{width: '100%'}}>
                                        <Typography align="center" variant="h5" style={{paddingTop: 10, paddingBottom: 20}}>Unassigned Transactions</Typography>



                                        {restaurant.demo && 
                                            <Grid item xs={12} container justify="space-around" alignItems="flex-end" style={{marginBottom: 15}}>
                                                <Grid item>
                                                    <TextField
                                                        label="Amount"
                                                        type="numeric"
                                                        disabled={restaurant ? !restaurant.demo : false}
                                                        onChange={(e) => { setPaymentAmount(e.target.value); localStorage.billingTestDate = e.target.value }}
                                                        value={paymentAmount}
                                                        style={{width: 100}}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            autoOk
                                                            initialFocusedDate=""
                                                            showTodayButton
                                                            // variant="inline"
                                                            format="MM/dd/yyyy"
                                                            disabled={restaurant ? !restaurant.demo : false}
                                                            // margin="normal"
                                                            id="paymentDateInput"
                                                            label="Payment Date"
                                                            value={paymentDate}
                                                            onChange={date => { setPaymentDate(date) }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}    
                                                            style={{width: 150}}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={restaurant ? !restaurant.demo : false}
                                                        onClick={addTestPayment}
                                                    >
                                                        Add
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        }

                                        <Table size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Date
                                                    </TableCell>
                                                    <TableCell>
                                                        Amount
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {!payments && 
                                                <TableRow><TableCell align="center" colSpan={3}><LinearProgress style={{marginTop: 10, marginBottom: 10, paddingTop: 2, paddingBottom: 2}} /></TableCell></TableRow>
                                            }
                                            {payments && payments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((p) => {
                                                return (
                                                    <Slide direction="right" in={!(p.invoice && p.invoice.includedOnInvoice && p.invoice.includedOnInvoice.invoiceNumber)} mountOnEnter unmountOnExit>
                                                        <TableRow>
                                                            <TableCell>
                                                                {moment(p.createdAt).utc().format('L')}
                                                            </TableCell>
                                                            <TableCell>
                                                                ${(p.paymentAmount/100).toFixed(2)}
                                                            </TableCell>
                                                        </TableRow>
                                                    </Slide>
                                                )
                                            })}
                                        </Table>
                                        {payments && 
                                            <TablePagination
                                                rowsPerPageOptions={[10, 15, 20]}
                                                component="div"
                                                count={payments ? payments.length : 0}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                            />
                                        }
                                    </Paper>
                                </Grid>
                            }

                                    {/* { selectedInvoice && !selectedInvoice.isMasterInvoice &&
                                        <Grid item xs={12} style={{margin: 25}}>
                                            <Invoice invoice={selectedInvoice} elevation={3} showDetails={showDetails} removeDiscount={removeDiscountFromInvoice}/>
                                        </Grid>
                                    }

                                    { selectedInvoice && selectedInvoice.isMasterInvoice &&
                                        <Grid item xs={12} style={{margin: 25}}>
                                            <MasterInvoice invoice={selectedInvoice} elevation={3} showDetails={showDetails} removeDiscount={removeDiscountFromInvoice}/>
                                        </Grid>
                                    } */}

                        </Grid>
                    </Grid>

                </div>
            }
                
            <Menu id="orders-menu"
                open={open}
                anchorEl={anchorEl}
                // keepMounted
                onClose={hideMenu}
                getContentAnchorEl={null}
                // anchorOrigin={{horizontal: 'right'}}
                // transformOrigin={{horizontal: 'left'}}
                onChange={(e) => cancelOrder(e)}
            >
                <MenuItem onClick={e => cancelOrder(e)}>Cancel Order</MenuItem>
            </Menu>

            <Menu id="invoices-menu"
                open={open2}
                anchorEl={anchorEl2}
                onClose={hideMenu}
                getContentAnchorEl={null}
            >
                {open2 && !selectedRecordRow.getVal('payment.paid') && selectedRecordRow.getVal('totals.total', 0) > 0 && <MenuItem onClick={() => { setShowDiscountForm(true); hideMenu() }}>Add Discount</MenuItem>}
                {open2 && !selectedRecordRow.getVal('payment.paid') && selectedRecordRow && _.filter(selectedRecordRow.discounts, o => !o.deleted).length > 0 && <MenuItem onClick={removeAllDiscountsFromInvoice}>Remove All Discounts</MenuItem>}
                {open2 && selectedRecordRow && <MenuItem onClick={downloadInvoice}>Download Invoice</MenuItem>}
                {open2 && selectedRecordRow && <MenuItem onClick={() =>handleOpen(selectedRecordRow)}>View Invoice</MenuItem>}
                {open2 && !selectedRecordRow.getVal('payment.paid') && <MenuItem onClick={deleteInvoice}>Delete Invoice</MenuItem>}
            </Menu>

            <Dialog open={showAddMainContactWindow} onClose={closeAllWindows} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Update Primary Contact</DialogTitle>
                <DialogContent dividers>
                <TextField
                    autoFocus
                    margin="dense"
                    id="firstName"
                    value={restaurant && primaryContactInfo ? primaryContactInfo.firstName : restaurant && primaryContactInfo ? primaryContactInfo.firstName : ''}
                    label="First name"
                    onChange={(e) => {setPrimaryContactInfo({...primaryContactInfo, firstName: e.target.value})}}
                    fullWidth
                />
                <TextField
                    margin="dense"
                    id="lastName"
                    value={restaurant && primaryContactInfo ? primaryContactInfo.lastName : restaurant && primaryContactInfo ? primaryContactInfo.lastName : ''}
                    label="Last Name"
                    onChange={(e) => {setPrimaryContactInfo({...primaryContactInfo, lastName: e.target.value})}}
                    fullWidth
                />
                <TextField
                    margin="dense"
                    id="email"
                    value={restaurant && primaryContactInfo ? primaryContactInfo.email : restaurant && primaryContactInfo ? primaryContactInfo.email : ''}
                    label="Email"
                    type="email"
                    onChange={(e) => {setPrimaryContactInfo({...primaryContactInfo, email: e.target.value})}}
                    fullWidth
                />
                <TextField
                    margin="dense"
                    id="phone"
                    value={restaurant && primaryContactInfo ? primaryContactInfo.phone : restaurant && primaryContactInfo ? primaryContactInfo.phone : ''}
                    label="Phone"
                    type="phone"
                    onChange={(e) => {setPrimaryContactInfo({...primaryContactInfo, phone: e.target.value})}}
                    fullWidth
                />
                <TextField
                    margin="dense"
                    id="title"
                    value={restaurant && primaryContactInfo ? primaryContactInfo.title : restaurant && primaryContactInfo ? primaryContactInfo.title : ''}
                    label="Title"
                    onChange={(e) => {setPrimaryContactInfo({...primaryContactInfo, title: e.target.value})}}
                    fullWidth
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={closeAllWindows} color="primary">
                    Cancel
                </Button>
                <Button onClick={updatePrimaryContact} color="primary">
                    Save
                </Button>
                </DialogActions>
            </Dialog>

            { selectedInvoice && !selectedInvoice.isMasterInvoice &&
                <Modal
                    style={{maxWidth: 1300, margin: "auto"}}
                    open={openModal}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Grid item xs={12} container style={{overflowY: 'auto', overflowX: 'hidden', height: '90%', margin: 45}}>
                        <Invoice invoice={selectedInvoice} elevation={3} showDetails={true} removeDiscount={removeDiscountFromInvoice} />
                    </Grid>
                </Modal>
            }
            { selectedInvoice && selectedInvoice.isMasterInvoice &&
                <Modal
                    style={{maxWidth: 1300, margin: "auto"}}
                    open={openModal}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Grid item xs={12} container style={{overflowY: 'auto', overflowX: 'hidden', height: '90%', margin: 45}}>
                        <MasterInvoice invoice={selectedInvoice} elevation={3} showDetails={true} removeDiscount={removeDiscountFromInvoice} />
                    </Grid>
                </Modal>
            }

        </div>
    );
};

Admin.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withRouter(withStyles(styles, { withTheme: true })(Admin));