import log from 'loglevel';
import { NotificationManager } from 'react-notifications';
import history from '../history.js'
const uuidv4 = require('uuid/v4');

export default async function(debug = true) {
	return new Promise(async (resolve, reject) => {
		// if (debug) log.info('register api');

		const url = `${global.dashServerUrl}/api/v1/account/getToken`;
		if (debug) log.info(`url: ${url}`);

		const body = {
			//mobileDeviceInfo: {
			//},
			deviceId: localStorage.deviceId,
		};

		// if (debug) log.info('getToken body');
		// if (debug) log.info(body);

		let headers = {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		}
		if (localStorage.refreshToken) {
			headers['x-access-refresh-token'] = localStorage.refreshToken
		}
		if (debug) log.info('getToken headers')
		if (debug) log.info(headers)

		fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(body) }, debug).then((res) => {
			log.info(`status: ${res.status}`);
			if (res.ok) {
				res.json().then((json) => {
					if (json.success) {
						let data = json.data
						if (debug) log.info('getToken success');
						if (debug) log.info(data)
						localStorage.token = data.token
						localStorage.refreshToken = data.refreshToken
						localStorage.tokenTimeout = data.tokenTimeout
						localStorage.refreshTimeout = data.refreshTimeout
						resolve(data.token);
					} else {
						console.error(json.errors)
						reject(json.errors);
					}
				}).catch((err) => {
					reject(err.message);
				})
			} else {
				localStorage.setItem("token", "")
				localStorage.setItem("permissions", "")
				window.location.reload()
				// NotificationManager.warning('', "Your Session Timed Out", 3000);
				reject(res);
			}
		}).catch((err) => {
			localStorage.token = null
			reject(err.message);
		})
	})

}
