let instances = {}

let SmartFilters = function() {
	this.init = function(instanceName, props) {
		let instance = instances[instanceName]
		if (!instance) {
			instance = new SmartFilterOne(props)
			instances[instanceName] = instance
		}
		// console.log('instance')
		// console.log(instance)
		return instance
	}
}


let SmartFilterOne = function(props) {

	let initFilters = {}
	let filters = {}
	let sorts = {}

	if (props.filters) {
		this.initFilters = props.filters
		this.filters = props.filters
	}
	if (props.sorts) this.sorts = props.sorts
	  
	this.sortBy = (field, dir = 'asc', reset = true) => {
		if (this.sorts[field] == 'asc') {
			dir = 'desc'
		}
		if (reset) {
			this.sorts = {}
		}
		// alert('sorting by: ' + field)
		this.sorts[field] = dir
		this.updated()
	}

	this.filter = (field, value) => {
		if (value == undefined || value == null || value == '') {
			if (this.filters[field]) {
				delete this.filters[field]
				this.updated()
				return
			}
		} else if (this.filters[field] != value) {
			this.filters[field] = value
			this.updated()
		}
	}

	this.hasFilter = (field, value = null) => {
		if (field && value != null) {
			return this.filters && this.filters[field] == value
		} else if (field) {
			return this.filters && this.filters.hasOwnProperty(field)
		}
	}

	this.getFieldSortedDir = (field) => {
		if (this.sorts && this.sorts.hasOwnProperty(field)) {
			return this.sorts[field]
		} else {
			return false
		}
	}

	this.isFieldSorted = (field, dir = null) => {
		if (field && dir != null) {
			return this.sorts && this.sorts[field] == dir
		} else if (field) {
			return this.sorts && this.sorts[field]
		}
	}

	this.clearAllFilters = () => {
		this.filters = initFilters
		this.updated()
	}

	this.updated = () => {
		global.set({smartFilterUpdated: Math.random()})
	}
}

export default new SmartFilters();

