import log from 'loglevel';
import dashFetch from '../utils/dashFetch';


export default async function (dateString, restaurantId, restaurantStations, debug) {
	return new Promise((resolve, reject) => {
        if (debug) log.info('fetchDBFiles api');
        

		const url = `${global.dashServerUrl}/dashboard/api/v1/dashboard-restaurant-station/fetchDBFiles`;

		const body = {
			restaurantId,
			restaurantStations,
			dateString
		};

		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info("json")
			log.info(json)
			if (json.success) {
				resolve(true);
			} else {
				resolve(false)
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}