import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import DashCarIcon from '../../dashboard/components/DashCarIcon';
import TableRow from '@material-ui/core/TableRow';
import { Typography, Divider, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment'
import theme from '../../theme'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  sectionHeader: {
        fontSize: '16px'
    },
  container: {
    maxHeight: 370,
  },
  tableHeader: {
      fontSize: '12px'
  }
});

export default function StickyHeadTable(props) {
    const classes = useStyles();
    let check = props.data
    const xsSmallScreen = useMediaQuery(theme().breakpoints.down('xs'));

    const [imHereEnabled] = global.get('imHereEnabled')
    const customerPhone = (check.customer && check.customer.phone) ? check.customer.phone : (check.pickupDetails && check.pickupDetails.phoneNumber) ? check.pickupDetails.phoneNumber : ''
    const customerEmail = (check.customer && check.customer.email) ? check.customer.email : ''

    return (
        <>
            {xsSmallScreen ?
                <>
                    <Typography className={classes.sectionHeader}>{!imHereEnabled ? 'Customer Detail' : 'Pickup Detail'}</Typography>
                    <Grid container justify="center" alignItems="center" style={{padding:8}} spacing={1}>

                        <Grid item xs>
                            Customer: <span style={{ color: 'var(--dash-purple)' }}>{global.f(check, 'pickupDetails.guestName', false) ? check.pickupDetails.guestName : global.f(check, 'customer.firstName', false) ? check.customer.firstName : 'Guest'}</span>
                        </Grid>
                        {(!global.isTextPay() || customerPhone) &&
                            <Grid item xs>
                                Phone: <span style={{ color: 'var(--dash-purple)' }}>{customerPhone ? global.formatPhoneNumber(customerPhone) : 'N/A'}</span>
                            </Grid>
                        }
                        {customerEmail &&
                            <Grid item xs={12}>
                                Email: <span style={{ color: 'var(--dash-purple)', wordBreak: 'break-all' }}>{customerEmail}</span>
                            </Grid>
                        }
                        {imHereEnabled &&
                            <Grid item xs={12} container direction="column" alignItems="center">
                                <Grid item xs={6} style={{paddingTop:6}}>
                                    <DashCarIcon data={check.pickupDetails}></DashCarIcon>
                                </Grid>
                                {global.f(check, 'pickupDetails.pickupLocation', false) ? check.pickupDetails.pickupLocation : 'N/A'}
                            </Grid>
                        }
                    </Grid>
                </>
                :
                <>
                    <Typography className={classes.sectionHeader}>{!imHereEnabled ? 'Customer Detail' : 'Pickup Detail'}</Typography>
                    <Table size="small" className={classes.root}>
                        <TableHead >
                            <TableRow>
                                <TableCell align="left" classes={{ root: classes.tableHeader }}>Customer</TableCell>
                                {(!global.isTextPay() || customerPhone) && <TableCell align="center" classes={{ root: classes.tableHeader }} style={{minWidth:'50%'}}>Phone</TableCell>}
                                {customerEmail && <TableCell align="left" classes={{ root: classes.tableHeader }} style={{minWidth:'50%'}}>Email</TableCell>}
                                {imHereEnabled && <>
                                    <TableCell align="center" classes={{ root: classes.tableHeader }}>Arrived</TableCell>
                                    <TableCell align="center" classes={{ root: classes.tableHeader }}>Location</TableCell>
                                    <TableCell align="center" classes={{ root: classes.tableHeader }}>Vehicle</TableCell>
                                </>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="left" className={classes.pickupTableCell}>{global.f(check, 'pickupDetails.guestName', false) ? check.pickupDetails.guestName : global.f(check, 'customer.firstName', false) ? check.customer.firstName : 'Guest'}</TableCell>
                                {(!global.isTextPay() || customerPhone) && <TableCell align="center" className={classes.pickupTableCell} style={{width:'25%'}}><span style={{whiteSpace:'nowrap', minWidth:'100px'}}>{global.formatPhoneNumber(customerPhone)}</span></TableCell>}
                                {customerEmail && <TableCell align="left" className={classes.pickupTableCell} style={{ wordBreak: 'break-all' }}>{customerEmail}</TableCell>}
                                {imHereEnabled && <>
                                    <TableCell align="center" className={classes.pickupTableCell}>{global.f(check, 'pickupDetails.arrivedAt', false) ? moment(check.pickupDetails.arrivedAt).format('h:mma') : 'N/A'}</TableCell>
                                    <TableCell align="center" className={classes.pickupTableCell}>{global.f(check, 'pickupDetails.pickupLocation', false) ? check.pickupDetails.pickupLocation : 'N/A'}</TableCell>
                                    <TableCell align="center" className={classes.pickupTableCell}><DashCarIcon data={check.pickupDetails}></DashCarIcon></TableCell>
                                </>}
                            </TableRow>
                        </TableBody>
                    </Table>
                </>
            }
        </>
    );
}