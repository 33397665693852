import log from 'loglevel';
import dashFetch from '../utils/dashFetch';


export default async function (restaurantId, paymentAmt, type, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('testTransactionOnGatewayApi api');
		const url = `${global.dashServerUrl}/api/v1/payment/testTransactionOnGateway`;

		const body = {
			restaurantId,
			paymentAmt,
			type
		};

		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info("testTransactionOnGatewayApi json" + JSON.stringify(json))
			if (json.success) {

				if(json.data.createIFrame && json.data.ticketId){
					resolve({createIFrame: json.data.createIFrame, ticketId: json.data.ticketId})
				} else {
					alert('PAYMENT SUCCESSFUL FOR AMOUNT: ' + json.data.amountProcessed)
					resolve(true)
				}
			} else {
				alert('PAYMENT NOT SUCCESSFUL: ' + JSON.stringify(json.data))
				resolve(false )
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}