import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, CircularProgress, Button, IconButton, Dialog } from '@material-ui/core';
import log from 'loglevel'
import _ from 'lodash';
import getRestaurantGroupMenusApi from '../../actions/getRestaurantGroupMenusApi';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import history from '../../history';
import theme from '../../theme'
import downloadRestaurantGroupExport from '../../actions/downloadRestaurantGroupExportApi'
import requestLogsApi from '../../actions/requestLogsApi'
import forceUpdateApi from '../../actions/forceUpdateApi'
import stationRestartApi from '../../actions/stationRestartApi';
import updateRemoteDesktopApi from '../../actions/updateRemoteDesktopApi';

export default function RestaruantGroupConfig (props) {
    const isMobile = useMediaQuery(theme().breakpoints.down('xs'));
    const isTablet = useMediaQuery('@media only screen and (max-device-height : 768px) and (max-device-width : 1024px)');

    let [restaurant] = global.get('restaurant')
    const [group, setGroup] = useState(props.group ? props.group : null);
    let [isLoading, setLoading] = useState(false);
    
    const fetchData = async () => {
        setLoading(true)
        // let data;
        // if (group) {
        //     data = await getRestaurantGroupMenusApi(entityId)
        // } else { //restaurant
        //     data = await getRestaurantMenusApi(entityId);
        // }
        // if (!data) {
        //     setLoading(false)
        //     return
        // }
        // setMenus(data)
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [restaurant]);

    const requestLogs = async () => {
		let success = await requestLogsApi(null, null, group._id)
		setLoading(true)
		if (success) {
			global.showNotification('Logs requested', 'success')
		}
		setLoading(false)
	}
    
	const forceStationUpdate = async () => {
        let success = await forceUpdateApi(null, null, group._id)
		setLoading(true)
		if (success) {
            global.showNotification(`All Stations Updated`, 'success')
		}
		setLoading(false)
	}
    
	const stationRestart = async () => {
        setLoading(true)
		let success = await stationRestartApi(null, null, group._id)
		if (success) {
            global.showNotification(`All Stations Restarted`, 'success')
		}
		setLoading(false)
	}
    
    const updateRemoteDesktop = async () => {
        let success = await updateRemoteDesktopApi(null, null, group._id)
        setLoading(true)
        if (success) {
            global.showNotification('Remote Desktop Installed', 'success')
        }
        setLoading(false)
    }

    const showPage = () => {
        return (
            <>
                <Grid container alignContent='flex-start' justify="center" style={{marginTop:16}}>
                    {group._id && global.hasPermission('admin.restaurant.gateway.edit') && <>
                        <Grid container alignContent='flex-start' justify="center" style={{marginTop:16}}>
                            Restaurant Group Id: {group._id}
                        </Grid> 

                        <Grid container alignContent='flex-start' justify="center" style={{marginTop:16}}>
                            <Button style={{height: '50px', margin: '10px', width: '100%',}} variant="contained" color="primary" 
                                onClick={ ()=> requestLogs() }>
                                Request Setup Logs For All Restaurants in Group
                            </Button>
                        
                            <Button style={{height: '50px', margin: '10px', width: '100%',}} variant="contained" color="primary" 
                                onClick={ ()=> forceStationUpdate() }>
                                Update Setup For All Restaurants in Group
                            </Button>
                        
                            <Button style={{height: '50px', margin: '10px', width: '100%',}} variant="contained" color="primary" 
                                onClick={ ()=> stationRestart() }>
                                Restart Setup For All Restaurants in Group
                            </Button>
                        
                            <Button style={{height: '50px', margin: '10px', width: '100%',}} variant="contained" color="primary" 
                                onClick={ ()=> updateRemoteDesktop() }>
                                Install Remote Desktop For All Restaurants in Group
                            </Button>
                            
                            <Button style={{height: '50px', margin: '10px', width: '100%',}} variant="contained" color="primary" 
                                onClick={ () => downloadRestaurantGroupExport(null, group._id) }>
                                Restaurant Group Export
                            </Button>
                        </Grid>

                        <Grid container alignContent='flex-start' justify="center" style={{marginTop:16}}>
                        </Grid>
                    </>}
				</Grid>
            </>
        )    
    }


    const showLoader = () => {
        return (
            <Grid container
                style={{height:'100%', width:'100%'}}
                justify="center"
                alignItems="center"
                direction="row"><Grid item ><CircularProgress/></Grid>
            </Grid>
        )
    }

    if (props.hidePaper) {
        // AddEditRestaurantGroup
        return (
            <div style={{display:'flex', justifyContent:'center', alignItems:'flex-start'}}>
                {isLoading ? showLoader() : showPage()}
            </div>
        )
    } else {
        return (
            <div style={{display:'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection:'column', height:'100%', width:'100%', padding:4}}>
                <div style={{ width:'100%'}}>
                    {isLoading ? showLoader() : showPage()}
                </div>
            </div>
        )
    }
};
