import log from 'loglevel';
import dashFetch from '../utils/dashFetch';

export default async function (accountId) {
	return new Promise((resolve, reject) => {
		const url = `${global.dashServerUrl}/api/v1/account/deactivateAccount`;

		const body = {
			accountId
		};


		dashFetch(url, body, false).then(({ res, json }) => {
			log.info("json")
			log.info(json)
			if (json.success) {
				global.showNotification('Account deactivated', 'success')
				resolve(true);
			} else {
				log.error(json.errors)
				global.showNotification(json.errors, 'error')
				reject(json.errors);
			}
		}).catch((err) => { 
			log.error(err)
			reject(err.message);
		 })
	})
}