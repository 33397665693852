import log from 'loglevel';
import moment from 'moment';

export default async function (restaurant, debug, startDate, endDate) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('exportTransactionList ');
		const url = `${global.dashServerUrl}/dashboard/api/v1/export/exportTransactionList`;

		const body = {
			restaurantId: restaurant._id, 
            debug,
            startDate: moment(startDate).format("MM/DD/YYYY"),
			endDate: moment(endDate).format("MM/DD/YYYY")
		};

		let options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		}

		fetch(url, options, debug)
		.then((res => res.ok ? res.blob() : undefined))
		.then(blob => {
			log.info('>>> alertCustomerForOrderUpApi JSON');
			if (blob) {
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement('a');
				a.href = url;
				a.download = `${restaurant.name.replace("&", "").replace("'", "").replace("\"", "")}-weekly-${moment().format('DD-MM-YYYY')}.xlsx`;
				document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
				a.click();
				a.remove();  //afterwards we remove the element again         
				resolve(true)
			} else {
				reject(false)
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}