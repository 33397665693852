import React, { useState, useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Dialog, DialogActions, Button, Typography, Checkbox, Table, TableRow, TableCell, TableBody, Menu, Paper, IconButton, Badge, Divider, MenuItem, Tooltip } from '@material-ui/core';
import log from 'loglevel'
import NotificationsIcon from '@material-ui/icons/Notifications';
import moment from 'moment'
import DashCard from './DashCard';
import CheckDetails from './CheckDetails';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Grow from '@material-ui/core/Grow';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Sound from 'react-sound';

const styles = {
	infoBanner: {
		padding: 5,
		paddingTop: 15
	},
	paper: {
		padding: 10,
		width: '100%',
		height: '100%'
	},
	table: {
	}
}

const StyledMenu = withStyles({
	paper: {
		marginTop:-5,
	},
	marginBottom: -5
})((props) => (
	<Menu
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center',
		}}
		{...props}
	/>
));

export default function (props) {
	// alert(JSON.stringify(props.permissions))
	const [checks] = global.get('checks')
	const [checksWithAlerts, setChecksWithAlerts] = useState([])
    const [dialogContent, setDialogContent] = useState()
    const [annoyUser, setAnnoyUser] = useState(false)
	const isMobile = useMediaQuery('(max-width:500px)')
	let [audibleAlert] = global.get('audibleAlert')
	let [newCustomerArrival] = global.get('newCustomerArrival')
	let [audibleAlertInterval] = global.get('audibleAlertInterval')

	const timer = useRef(false)

	const closeContent = () => {
		setDialogContent(null)
	}

	const showCheckDetails = (check) => {
		// alert('role ' + JSON.stringify(role))
		setDialogContent(
			<CheckDetails check={check} close={closeContent} />
		)
	}

	useEffect(() => {
		if (audibleAlertInterval) {
			return () => {
				setTimeout(() => clearInterval(timer.current), 15000)
			}
		}
	}, [])




	useEffect(() => {
		if (checks) {
			setChecksWithAlerts(checks.filter(rec => (rec.status == 'arrived' && !rec.pickupDetails.pickedUpAt) || (rec.paidInFull && !rec.closed && rec.totals.paidDashNow > 0)))
		}
	}, [checks]);

	useEffect(() => {
		let imHereChecks = checksWithAlerts.filter(rec => (rec.status == 'arrived' && !rec.pickupDetails.pickedUpAt))
		if (audibleAlertInterval && imHereChecks && imHereChecks.length > 0) {
			timer.current = setInterval(() => {
				setAnnoyUser(true)
			}, audibleAlertInterval*1000); //Reload checks into state to receive updates
			return () => {
				clearInterval(timer.current)
			}
		}
	}, [checksWithAlerts])
	const [anchorCheckList, setAnchorCheckList] = React.useState(null);

	return (
		<>	
			{(global.isDashNow() || global.isTextPay()) && 
				<Tooltip title="New Alerts">
					<IconButton focusRipple={true} style={{}} aria-controls="simple-menu" aria-haspopup="true" edge="end" color="inherit" onClick={(e) => { setAnchorCheckList(e.currentTarget); }}>
						<Badge color="primary" badgeContent={checksWithAlerts ? checksWithAlerts.length : null}>
							<NotificationsIcon style={{color:'var(--text)'}} color={Boolean(anchorCheckList) ? 'primary' : ''} />
						</Badge>
					</IconButton>
				</Tooltip>
			}
			<StyledMenu
				id="menu-appbar"
				anchorEl={anchorCheckList}
				keepMounted
				open={Boolean(anchorCheckList)}
				onClose={() => setAnchorCheckList(null)}
			>
				<Grid container style={{minWidth: isMobile ? 280 : 400}} justify="space-evenly" alignItems="center" direction="column">
					{audibleAlert && <>
						<Sound url='/Classic-doorbell-sound.mp3' ignoreMobileRestrictions={true} playStatus={audibleAlert && newCustomerArrival ? Sound.status.PLAYING : Sound.status.STOPPED} onFinishedPlaying={() => { global.set({newCustomerArrival: false}) }} />
						{audibleAlertInterval > 0 && annoyUser &&
							<Sound url='/Notification-bell.mp3' ignoreMobileRestrictions={true} playStatus={audibleAlert ? Sound.status.PLAYING : Sound.status.STOPPED} onFinishedPlaying={() => { setAnnoyUser(false) }} />
						}
					</>}
					{(checksWithAlerts && checksWithAlerts.length) ?
					    <>
                            <Typography variant="h6" style={{margin:'auto'}}>New Alerts</Typography>
                            {checksWithAlerts.map((check, idx) => {
                                return(
                                    <div style={{ width: '96%' }} onClick={()=>showCheckDetails(check)}>
                                        <DashCard data={check} open={showCheckDetails} showTotalPaid={check.paidInFull && !check.closed && check.totals.paidDashNow > 0} hideTopBorder={idx > 0} />
                                    </div>
                                )
                            })}
                        </>
                    :
                        <Grow in={anchorCheckList} timeout={{ appear:100,enter:200,exit:100}} >
                            <div {...props} style={{color: 'rgb(48, 48, 48,.2)', width:'100%', height:'100%', textAlign:'center'}}>
                                <ReceiptIcon style={{fontSize:'100px'}}/>
                                <Typography variant="h6">No new Alerts</Typography>
                            </div>
                        </Grow>
					}
				</Grid>
			</StyledMenu>
			<Grid item lg={12} >
				{dialogContent}
			</Grid>
		</>
	)

}
