import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function DLoader () {
    return (
        <div style={{width:'100%', height:'100%'}}>
            <CircularProgress 
                style={{
                    position: 'absolute', 
                    top:'50%', 
                    left:'50%', 
                    height:'100px', 
                    width:'100px', 
                    marginTop:'-50px', 
                    marginLeft:'-50px', 
                    opacity:'20%'}}/>
        </div>
    )
}