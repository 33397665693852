import React, { useEffect, useState } from 'react';
import log from 'loglevel';
import { makeStyles } from '@material-ui/core/styles';
//import InboxIcon from '@material-ui/icons/MoveToInbox';
import LockIcon from '@material-ui/icons/Lock';
import { NotificationManager } from 'react-notifications';
// import '../../util/modalCSS.css'
// import DashLoad from '../../assets/Dashnow-Loading.gif';
import DInput from '../../components/dash/DInput';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DRecord from '../../components/dash/DRecord';
import { Typography, Button, Grid, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import saveCardTokenApi from "../../actions/saveCardTokenApi";

export default (props) => {
    const record = props.record
	const [awaitingPaymentResponse, setAwaitingPaymentResponse] = useState(false);
	const [saveCardBtn, setSaveCardBtn] = useState(false);
	const [ openDialog, setOpenDialog ] = useState(false);
	const [ successfullyCreatedToken, setSuccessfullyCreatedToken ] = useState(false)
	const [ fullName, setFullName ] = useState('');
	const GA_CATEGORY_PAYMENT_ADD_CARD = "Payment add card"
	let [restaurant] = global.get('restaurant', null);

	let [check] = global.get("check")
	let spreedlyEnvironmentKey = global.spreedlyEnvironmentKey ? global.spreedlyEnvironmentKey : ""
    
    log.info('spreedlyEnvironmentKey: ' + spreedlyEnvironmentKey)

	const c = makeStyles((theme) => ({
		separator: {
			height: 1,
			width: '100%',
			backgroundColor: '#ccc',
		},
		form: {
			width: '100%',
			// height: 240,
			backgroundColor: 'none',
			paddingTop: 16,
		},
		label: {
			fontSize: 14,
			fontWeight: 400,
			color: 'rgba(0, 0, 0, 0.6)',
			textAlign: 'center',
			marginBottom: 8,
		},
		cardNumIconWrapper: {
			width: '100%',
            height: 44,
            padding: 6,
		},
		nameInput: {
			width: 212,
			height: 34,
            borderStyle: 'none',
			marginRight: '188',
			borderBottom: '1px solid #999999',
			borderRadius: 0,
			padding: 6,
			paddingBottom: 0,
			fontSize: 16,
			fontWeight: '500',
			color: 'rgb(97,97,97)',
            marginBottom: 8,
            marginRight: 18,
			appearance: 'none'
		},
		securityFAQ: {
			marginTop: '.37em',
			color: 'var(--light-gray)'
        },
		cardNameWrapper: {
            height: 44,
			display: 'inline-flex',
			position: 'relative',
			marginRight: 'auto',
			marginLeft: 'auto',
			marginBottom: 16,
			padding: 0,
		},
		expCvvWrapper: {
            height: 44,
			display: 'inline-flex',
			position: 'relative',
			marginRight: 'auto',
			marginLeft: 'auto',
			marginBottom: 16,
			padding: 0,
		},
		expWrapper: {
            height: 30,
            width: 212,
            borderBottom: '1px solid #999999',
			display: 'flex',
			// justifyContent: 'center',
			padding: 6,
			margin: 0,
			marginRight: 18,
			marginTop: 14,
			backgroundColor: 'var(--white)'
		},
		expSlash: {
			color: '#999999',
			alignSelf: 'center',
			// marginLeft: 5,
		},
		inputExpMonth: {
			width: 48,
			color: '#616161',
			backgroundColor: 'var(--white)',
            fontSize: 16,
            fontWeight: 500,
			fontPadding: 'none',
			border: 'none',
			marginRight: 0,
			outline: 'none',
			'-webkit-border-radius': 0,
			'-moz-border-radius': 0,
			'border-radius': 0,
			'-khtml-border-radius': 0,
		},
		inputExpYear: {
			width: 48,
			color: '#616161',
			backgroundColor: 'var(--white)',
			fontSize: 16,
            fontPadding: 'none',
            fontWeight: 500,
			border: 'none',
			padding: 6,
			paddingLeft: 12,
			marginLeft: 0,
			outline: 'none',
			'-webkit-border-radius': 0,
			'-moz-border-radius': 0,
			'border-radius': 0,
			'-khtml-border-radius': 0,
		},
	}))();

	let lastToken = null;

	const initSpreedly = () => {
		log.info('INSIDE SPREEDLY!!!');

		lastToken = null;
		window.Spreedly.init(`${spreedlyEnvironmentKey}`, { "numberEl": "spreedly-number", "cvvEl": "spreedly-cvv" });

		window.Spreedly.on("ready", function () {
			
			window.Spreedly.setPlaceholder('number', 'Card Number');
			window.Spreedly.setFieldType('number', 'tel');
			window.Spreedly.setNumberFormat("prettyFormat");
			window.Spreedly.setStyle('number', 'font-family: "Roboto", "Helvetica", "Arial", sans-serif; width: 200px; height: 44px; color: #616161; background-color: #FFF; font-size: 16px; font-padding: none; padding: 6px; margin-bottom: 8; margin-right: auto; margin-left: 30; -webkit-border-radius: 0px; -moz-border-radius: 0px; border-radius: 0px; -khtml-border-radius: 0px; ');


			window.Spreedly.setPlaceholder('cvv', 'CVV');
			window.Spreedly.setFieldType('cvv', 'tel');
			window.Spreedly.setStyle('cvv', 'font-family: "Roboto", "Helvetica", "Arial", sans-serif; width: 70px; height: 44px; color: #616161; background-color: #FFF; font-size: 16px; font-padding: none; padding: 6px; left: 0; right: 0; margin-right: auto; margin-left: auto; -webkit-border-radius: 0px; -moz-border-radius: 0px; border-radius: 0px; -khtml-border-radius: 0px; ');

			document.getElementById("spreedly-number");
		});

		window.submitPaymentForm = () => {
			var requiredFields = {};

			// Get required, non-sensitive, values from host page
			let fullName = document.getElementById("full_name").value;
			requiredFields["full_name"] = fullName
			requiredFields["month"] = document.getElementById("cardExpirationMonth").value;
            requiredFields["year"] = "20" + document.getElementById("cardExpirationYear").value;
            log.info('requiredFields');
			log.info(requiredFields);
			window.Spreedly.tokenizeCreditCard(requiredFields);
		}

		window.Spreedly.on('errors', function (errors) {
            for (var i = 0; i < errors.length; i++) {
				var error = errors[i];
				NotificationManager.warning('', error.message, 3000);
				log.info(error);
			};
		});

		window.Spreedly.on("paymentMethod", async function (token, paymentMethod) {
            if (token !== lastToken) {
				lastToken = token
				log.info("RECEIVED TOKEN FROM SPREEDLY")
				log.info("TOKEN: " + token)
				var type = paymentMethod.card_type;
				var lastFour = paymentMethod.last_four_digits;
				if (type != null && token != null && lastFour != null) {
					window.Spreedly.removeHandlers();
					if (token) {
						setSuccessfullyCreatedToken(true)
						setSaveCardBtn(false)
                        props.next(token, paymentMethod)
					}
				}
			}
		});

		window.Spreedly.on('fieldEvent', function(name, type, activeEl, inputProperties) {
            if(name === "number") {
				let numberParent = document.getElementById("spreedly-number");

				if(type === "focus") { numberParent.className = "highlighted"; }

				if(type === "input") {
					console.log("validNumber: " + inputProperties["validNumber"]);
					if(!inputProperties["validNumber"]) {
						if(inputProperties["numberLength"] > 14) {
							numberParent.className = "error";
							window.Spreedly.setStyle('number', 'border-color: #f00;');
						} else if(inputProperties["numberLength"] <= 14) {
							window.Spreedly.setStyle('number', 'border-color: #999999;');
						}
					} else {
						numberParent.className = "";
						window.Spreedly.setStyle('number', 'border-color: #999999;');
						console.log("cardType: " + inputProperties["cardType"]);
						if(["visa", "master", "discover", "american_express"].includes(inputProperties["cardType"])) {
						}
					}
				}
			}

			if(name === "cvv") {

				var expYear = document.getElementById('year');
				if (expYear && (expYear.value.length > 0) && expYear.value.length < 2) {
					document.getElementById('expWrapper').style.borderColor = '#f00';
				}

				let cvvParent = document.getElementById("spreedly-cvv");

				if(type === "input") {
					if(!inputProperties["validCvv"]){
						cvvParent.className = "error";
						window.Spreedly.setStyle('cvv', 'border-color: #f00;');
					} else {
						cvvParent.className = "";
						window.Spreedly.setStyle('cvv', 'border-color: #999999;');
					}

				}

				if(type === "focus") { cvvParent.className = "highlighted"; }
			}

			if(type === "input"){
                if(inputProperties["validNumber"] && inputProperties["validCvv"]) {
					setSaveCardBtn(true);
				} else {
					setSaveCardBtn(false);
				}
			}
		});

		window.Spreedly.on('validation', function(inputProperties) {
			log.info('validation INPUT PROPS: ' + JSON.stringify(inputProperties));
		});

	}
	

	useEffect(() => {
		window.Spreedly.on("paymentMethod", async function (token, paymentMethod) {
			if (token !== lastToken) {
				lastToken = token
				log.info("RECEIVED TOKEN FROM SPREEDLY")
				log.info("TOKEN: " + token)
				log.info("paymentMethod: " + paymentMethod)
				log.info("paymentMethodJson: " + JSON.stringify(paymentMethod))
				var type = paymentMethod.card_type;
				var lastFour = paymentMethod.last_four_digits;

				var fullName = paymentMethod.full_name;
				var firstName = paymentMethod.first_name;
				var lastName = paymentMethod.last_name;
				var expMonth = paymentMethod.month;
				var expYear = paymentMethod.year;

				if (type != null && token != null && lastFour != null) {
					let data = {restaurantId: restaurant._id, type, token, lastFour, fullName, firstName, lastName, expMonth, expYear}
					let resp = await saveCardTokenApi(data, true);
					// window.closeAllPanels();
					window.Spreedly.removeHandlers();
					let encryptedCard = (resp && resp.encryptedCard) ? resp.encryptedCard : ''
					if (encryptedCard) {
						let rest = {...restaurant}

						//THIS CODE IS DUPLICATED IN PaymentMethod.js FYI
						if (rest.billingInfo.billingAppliesTo === "restaurant") {
							rest.billingInfo.encryptedCard = encryptedCard
							rest.billingInfo.encryptedCardStatus = ''
						} else if (rest.billingInfo.billingAppliesTo === "restaurantGroup") {
							rest.restaurantGroup.billingInfo.encryptedCard = encryptedCard
							rest.restaurantGroup.billingInfo.encryptedCardStatus = ''
						} else if (rest.billingInfo.billingAppliesTo === "agency") {
							rest.agent.info.agency.billingInfo.encryptedCard = encryptedCard
							rest.agent.info.agency.billingInfo.encryptedCardStatus = ''
						}
                		global.set({restaurant: rest})
					}
				}
			}
		});
		// window.saveCardToken = saveCardToken;
		window.NotificationManager = NotificationManager;
		window.log = log;
		window.closeAllPanels = props.closeAllPanels;

		if (window.Spreedly && !window.Spreedly.isLoaded()) { 
			initSpreedly();
		}
	}, []);

	const theOnSubmit = async (e) => {
		setAwaitingPaymentResponse(true)
		await window.submitPaymentForm();
		setAwaitingPaymentResponse(false)
		return false;
	}

	const thisYear = new Date().getFullYear() - 2000;
	let invalidExpMonth = false
	let invalidExpYear = false

	const handleSetFullName = (name) => {
		setFullName(name.replace(/[^\w\s]/gi, ''))
	}

	const onInput = (e) => {
		if (e.target.id === 'cardExpirationMonth') {
			invalidExpMonth = false
			if (e.target.value.length === 2 && (e.target.value > 12 || e.target.value < 1)) {
				invalidExpMonth = true
			}
		}

		if (e.target.id === 'cardExpirationYear') {
			invalidExpYear = false
			if (e.target.value.length === 2 && (e.target.value > thisYear + 7 || e.target.value < thisYear)) {
				invalidExpYear = true
			}
		}
		if (invalidExpMonth || invalidExpYear) {
			document.getElementById('expWrapper').style.borderColor = '#f00';
		} else {
			document.getElementById('expWrapper').style.borderColor = '#999999';
		}
	};

	// const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

	return (
		<div id="modalContainer" className={c.modalContainer}>
			<div className={c.modalWrapper}>
				<form id="payment-form" className={c.form} onSubmit={(e) => { e.preventDefault() }}>
					<input type="hidden" name="payment_method_token" id="payment_method_token" />

                    <div className={c.cardNameWrapper}>
						<div style={{marginTop: 10}}>
							<input type='text' id="full_name" name="full_name" placeholder='Name On Card' 
								value={fullName} onInput={(e) => handleSetFullName(e.target.value)} className={c.nameInput}
							/>
						</div>
                        
                        <div
                            id="spreedly-number"
                            autoComplete="cc-number"
                            style={{appearance: 'none', height: '44px', borderBottom: '1px solid #999'}}
                            type="tel"
                        />
                    </div>
                    <br/>
					<div className={c.expCvvWrapper}>
						<div id="expWrapper" className={c.expWrapper}>
							<input
								id="cardExpirationMonth"
								autoComplete="cc-exp-month"
								placeholder="MM"
								maxLength={2}
								className={c.inputExpMonth}
								onInput={(e) => onInput(e)}
								type="tel"
							/>
							<div className={c.expSlash}>/</div>
							<input
								id="cardExpirationYear"
								autoComplete="cc-exp-year"
								placeholder="YY"
								maxLength={2}
								className={c.inputExpYear}
								onInput={(e) => onInput(e)}
								type="tel"
							/>
						</div>
                        <br/>
						<div
							id="spreedly-cvv"
							autoComplete="off"
							type="tel"
							style={{appearance: 'none', height: '44px', borderBottom: '1px solid #999'}}
						/>
					</div>
                    <br/>
					<Grid container spacing={3}>
						<Grid item style={{marginTop: 15}}>
							<Button id="submit-button" variant='contained' color='primary' onClick={(e) => { theOnSubmit(e); e.preventDefault(); }} disabled={!saveCardBtn} style={{marginBottom: 15}}>
								<Typography>Save</Typography>
							</Button>
							{props.hideCancelButton != true && 
								<Button id="submit-button" variant='contained' color='secondary' onClick={props.cancelForm} style={{marginLeft: 10, marginBottom: 15}}>
									<Typography>Cancel</Typography>
								</Button>
							}
						</Grid>

						{successfullyCreatedToken && 
							<Grid item>
								<CheckCircleIcon style={{color: 'green', paddingTop: 8}} />
							</Grid>
						}
					</Grid>
					{awaitingPaymentResponse && 
						<Grid item className={c.payButton} style={{ bottom: 0 }} xs={12}>
							<CircularProgress color="primary" />
						</Grid>
					}
				</form>
			</div>
		</div>
    );
};