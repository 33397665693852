import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { useMediaQuery } from '@material-ui/core';
import theme from '../../theme';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function CustomizedInputBase(props) {
  let smallScreen = useMediaQuery(theme().breakpoints.down('sm'));
  const classes = useStyles();
  const [todaySearchFilter, setTodaySearchFilter] = global.get('todaySearchFilter', '')
  const [orderTerminology] = global.get('orderTerminology')

  const overrideText = props.overrideText

  return (
    <Paper className={classes.root}>
      <IconButton className={classes.iconButton} aria-label="menu">
        <SearchIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder={smallScreen ? 'Search' : overrideText ? overrideText: global.isTextPay() || global.isCurbly() ? `Search by ${orderTerminology} number or amount` : 'Search by check number, table number, amount or phone number'}
        inputProps={{ 'aria-label': overrideText ? overrideText: global.isTextPay() || global.isCurbly() ? `Search by ${orderTerminology} number or amount` : 'Search by check number, table number, amount or phone number' }}
        value={todaySearchFilter ? todaySearchFilter : ''}
        onChange={e => setTodaySearchFilter(e.target.value) }
        pattern="[a-zA-Z0-9\s]*"
      />
      {todaySearchFilter && 
        <>
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton color="primary" className={classes.iconButton} aria-label="directions" onClick={() => { setTodaySearchFilter('') }}>
              <ClearIcon />
          </IconButton>
        </>
      }
    </Paper>
  );
}
