import React, { useState, useEffect } from 'react';
import {TextField, FormControl, InputLabel, Switch, FormGroup, FormControlLabel, Checkbox, FormHelperText, Typography} from '@material-ui/core';
import SelectMui from '@material-ui/core/Select';
import log from 'loglevel';
import _ from 'lodash';
import { NotificationManager } from 'react-notifications';
import CheckBox from '@material-ui/core/Checkbox';
import NumberFormat from 'react-number-format';
import theme from '../../theme';
import { useRef } from 'react';
import { ToggleButtonGroup } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
const uuidv4 = require('uuid/v4');

export default function (props) {
	// log.info("RENDERING DINPUT: " +  props.name)
	
	let [dform] = global.get('dform');
	let [validateNow] = global.get('validateNow');
	let [record, setRecord] = useState()
	let recordId = useRef()
	let fieldType = props.fieldType ? props.fieldType.toLowerCase() : 'textfield'

	useEffect(() => {
        // log.info("USE EFFECT DINPUT: " +  props.name)
		return () => {
            if(record && record.dform && record.dform.errors && Object.keys(record.dform.errors).length > 0){
                delete record.dform.errors[props.name]
            }
            if(record && record.dform && record.dform.errors && Object.keys(record.dform.requireds).length > 0){
                delete record.dform.requireds[props.name]
            }
		}
    }, [])
    
	if (dform) {
		if (!record) {
			record = dform.lastRecord
			recordId.current = record.dform.drecordId
			// log.info("  *** " + props.name + " >> " + record.dform.drecordId)
		} else {
			record = dform.records[record.dform.dformId][recordId.current]
		}
		// log.info("SET RECORD ON ... DINPUT " + props.name + " > RECORD ID: " + recordId.current)
	}

	if (props.required && record) {
        if (!record.dform.requireds) {
            record.dform.requireds = {}
        }

        record.dform.requireds[props.name] =  props.required
        if (props.errorIf) {
            if (!record.dform.errorIfs) {
                record.dform.errorIfs = {}
            }
            record.dform.errorIfs[props.name] =  props.errorIf

        }
	}

	if (record) {
		// log.info("  DINPUT drecordId: " + recordId.current)
	}

	const handleChange = (e) => {
		let value = e.target ? e.target.value : ''
		let name =  e.target ?  e.target.name : props.name
		if (fieldType == 'checkbox' || fieldType == 'switch' || fieldType == 'toggle') {
			value = !(value == 'true');
        }
		if (props.maxLength && value != '' && value.length > parseInt(props.maxLength)) {
			return false
		}
		if (props.max && value != '' && parseInt(value) > parseInt(props.max)) {
            const msgMax = 'The maximum number you can input is ' + props.max
            global.showNotification(msgMax, "warning", 2000)
			return false
		}
		if (props.min && value != '' && parseInt(value) < parseInt(props.min)) {
            const msgMin = 'The minimum number you can input is ' + props.min
            global.showNotification(msgMin, 'warning', 2000) 
			return false
		}
		if (value && props.type && props.type.toUpperCase() == "PHONE") {
			let newValue = value.replace(/\D/g, '');
			if (newValue) {
				newValue = formatPhoneNumber(newValue);
				if (!newValue) {
					return false
				} else {
					if (newValue && newValue != value) {
						value = newValue
						e.target.value = value
					}
				}
			} else {
				return false
			}
		}

		if (props.beforeChange) {
			props.beforeChange(record, props.name, value);
		}
		if (!validateFormat(e)) {
			return false;
		}
		if (record && record.dform) {
			let fieldError = record.dform.errors && record.dform.errors[props.name]
			if (fieldError && value) {
				delete record.dform.errors[props.name]
            }
			// console.log('value val: '+ value)
			// console.log('value type: '+ typeof value)

            if (props.type == 'number'){
                const hasDecimal = value ? value.split('.').length > 1 : false
                value = hasDecimal ? parseFloat(value) : parseInt(value)
            }

			// console.log('value val: '+ value)
			// console.log('value type: '+ typeof value)
            record.dform.setDVal(setRecord, name, value, props.rerender) 
			if (record.dform.errors && Object.keys(record.dform.errors).length > 0) {
                if (typeof record.dform.validate=='function') {
                    record.dform.validate(true)
				}
			}
		}
		if (props.onChange) {
			props.onChange(record, props.name, value); 
        }
		if (props.afterChange) {
			props.afterChange(record, props.name, value); 
        }
    }

    const conditonallyHandleChange = (e, val = null) => {
        if (props.overrideValue || (typeof props.overrideValue=='boolean')) {
            e.target.value = props.overrideValue
            e.target.name = props.name
        }
        if (val != null) {
            e.target.value = val
            e.target.name = props.name
        }
        handleChange(e)
	}
	
	const mockEvent = (value = null, name = null) => {
		const mockEvent = {target:{}}
        if (value != null) mockEvent.target.value = value
        if (name) mockEvent.target.name = name
        conditonallyHandleChange(mockEvent)
	}

    useEffect(()=>{
		mockEvent(props.overrideValue)
    },[props.overrideValue])

	const getVal = () => {
		if (record) {
			let val = _.get(record, props.name) 
            return val != undefined ? val : (props.fieldType == 'date') ? null : '';
		// } else if (record) {
		// 	return record[props.name] || ''
		} else {
			return ''
		}
	}

	const validateFormat = (e) => {
		let value = e.target.value
		if (value) {
			if (props.format) {
				if (props.format.toUpperCase() == "NUMBER" || props.format.toUpperCase() == "NUMERIC" || props.format.toUpperCase() == "NUMBERSONLY") {
					const re = /^[-+]?[0-9]+$/;
					if (value === '' || re.test(value)) {
						return true;
					} else {
						return false;
					}
				}
			}
		}
		return true;
	}

	const formatPhoneNumber = (phoneNumberStr) => {
		if (phoneNumberStr.length < 4) {
			phoneNumberStr = phoneNumberStr.replace(/(\d{1,3})/g, "($1");
		} else if (phoneNumberStr.length < 7) {
			phoneNumberStr = phoneNumberStr.replace(/(\d{3})(\d{1,3})/g, "($1) $2");
		} else if (phoneNumberStr.length <= 10) {
			phoneNumberStr = phoneNumberStr.replace(/(\d{3})(\d{3})(\d{1,4})/g, "($1) $2-$3");
		} else {
			return false
		}
		return phoneNumberStr;
	}

	const hasError = () => {
        let error = false
		if (record && record.dform) {
			if (record.dform.errors && record.dform.errors[props.name]) {
				if (props.validateId && document.getElementById(props.validateId)) {
					document.getElementById(props.validateId).error = true
				}
				error = true
            }
        }
		return error
    } 

	let props2 = {
		...props, 
		inputProps: {
			record: record,
			recordId: record ? record.dform.drecordId : '',
			name: props.name,
			index: props.index,
			type: props.type ? props.type : "text",
			min: props.min,
			max: props.max,
			maxLength: props.maxLength,
            ...props.inputProps,
		},
		
		disabled: dform.readOnly || props.disabled,
		id: record ? record.dform.drecordId + "-" + props.name : uuidv4(),
        recordId: record ? record.dform.drecordId : '',
        onChange: (e) => conditonallyHandleChange(e),
		helperText: hasError() ? (props.helperText && getVal() != null) ? props.helperText : props.noHelperText ? "" : 'This field is required' : '',
		type: props.type ? props.type.toUpperCase() == 'PHONE' ? 'tel' : props.type : "text",
        error: props.error ? props.error : hasError(),
        errorIf: props.errorIf ? props.errorIf : null,
		value: (props.value != undefined) ? props.value : getVal(),
		InputLabelProps: {shrink:true},
		style: props.style ? props.style : {},
		format: props.format,
		marginBottom: props.marginBottom ? props.marginBottom : (dform.margin || props.margin) ? props.margin ? props.margin : dform.margin : '15px'
	}
	
	switch (fieldType) {
		case 'checkbox':
			props2.type = 'checkbox' // WE BLED FOR THIS FIX Z&T
			props2.inputProps['type'] = 'checkbox'
			props2.checked = props2.value
			props2.labelPlacement = props2.labelPlacement ? props2.labelPlacement : 'end'
			props2.color = props2.color ? props2.color : 'primary'
			props2.style = {...props2.style, marginLeft: props2.style && props2.style.marginLeft ? props2.style.marginLeft : 0}
			props2.style = {...props2.style, marginBottom: props2.style && props2.style.marginBottom ? props2.style.marginBottom : 0}
			return (
				<FormControl component="fieldset">
					<FormGroup>
						<FormControlLabel
							value="top"
							control={<Checkbox {...props2} />}
							label={<Typography style={{color: hasError() ? theme().palette.error.main : 'inherit'}}>{props2.label}</Typography>}
							labelPlacement={props2.labelPlacement}
							required={props.required}
							error={hasError()}
							labelStyle={{color: 'red'}}
							classes={{label: {color: 'red'}}}
						/>
					</FormGroup>
				</FormControl>
			);
		case 'toggle':
		case 'switch':
			props2.type = 'checkbox' // WE BLED FOR THIS FIX Z&T
			props2.inputProps['type'] = 'checkbox'
			props2.checked = !!props2.value
			props2.labelPlacement = props2.labelPlacement ? props2.labelPlacement : 'end'
			props2.color = props2.color ? props2.color : 'primary'
			return (
				<FormControl component="fieldset">
					<FormGroup>
						<FormControlLabel
							value="top"
							control={<Switch {...props2} />}
							label={<Typography style={{color: hasError() ? theme().palette.error.main : 'inherit'}}>{props2.label}</Typography>}
							labelPlacement={props2.labelPlacement}
							required={props.required}
							error={hasError()}
							labelStyle={{color: 'red'}}
							classes={{label: {color: 'red'}}}
						/>
					</FormGroup>
				</FormControl>
			);
		case "select": 
			props2.style = {...props2.style, width: props2.style && props2.style.width ? props2.style.width : '100%'}
			props2.style = {...props2.style, marginBottom: props2.style && props2.style.marginBottom ? props2.style.marginBottom : props2.marginBottom}
			return (
				<FormControl style={{ width: '100%' }}>
					<InputLabel shrink error={hasError()} required={props.required}>{props2.label}</InputLabel>
					<SelectMui
						MenuProps={{style: {zIndex: 999999999999}}}
						{...props2}
					>
						{props.children}
					</SelectMui>
				</FormControl>
            );
		case "togglegroup": 
			props2.style = {...props2.style, width: props2.style && props2.style.width ? props2.style.width : '100%'}
			props2.style = {...props2.style, marginBottom: props2.style && props2.style.marginBottom ? props2.style.marginBottom : props2.marginBottom}
			props2.onChange = (e, val) => mockEvent(val, props.name)
			return (
				<ToggleButtonGroup {...props2}>
					{props.children}
				</ToggleButtonGroup>
            );
		case "date": 
			props2.style = {...props2.style, width: props2.style && props2.style.width ? props2.style.width : '100%'}
			props2.style = {...props2.style, marginBottom: props2.style && props2.style.marginBottom ? props2.style.marginBottom : props2.marginBottom}
			props2.onChange = (date, dateStr) => mockEvent(dateStr, props.name)
			return (
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDatePicker
						{...props2}
						autoOk
						// initialFocusedDate=""
						showTodayButton
						format="MM/dd/yyyy"
						id="date-picker-inline"
						InputLabelProps={{
							shrink: true,
						}}
						KeyboardButtonProps={{
							'aria-label': 'change date',
						}}
					>{props.children}
					</KeyboardDatePicker>
				</MuiPickersUtilsProvider>
            );
		case 'textfield':
		default: 
			props2.style = {...props2.style, width: props2.style.width ? props2.style.width : '100%'}
			props2.style = {...props2.style, marginBottom: props2.style.marginBottom ? props2.style.marginBottom : props2.marginBottom}
			return (<>
				{ (!props.format ) ? <TextField {...props2}>{props.children}</TextField> : <NumberFormat {...props2} customInput={TextField}>{props.children}</NumberFormat> }
			</>)
	} 
}