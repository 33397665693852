import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, CircularProgress, Button, Checkbox, FormControlLabel, Divider, InputLabel, IconButton, DialogContent, FormControl, FormLabel, RadioGroup, Radio, Typography, TextField } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import log from 'loglevel'
import _ from 'lodash';
import updateMenuSchedule from '../../actions/updateMenuScheduleApi';
import TimePicker from '../../components/TimePicker';
import DialogTitle from '../components/DialogTitle';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import uploadMenuRestaurantOrGroupApi from '../../actions/uploadMenuRestaurantOrGroupApi.js';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

export default function Menus (props) {
    const restaurant = props.restaurant
    const restaurantWebsite = restaurant ? restaurant.website : '';
    const [group, setGroup] = useState(props.group ? props.group : null);
    const entity = group ? group : restaurant;
    
    let [menu, setMenu] = useState(props.menu)
    let [menuUrl, setMenuUrl] = useState(props.menu.menuUrl ? props.menu.menuUrl : '')
    let [menuFile, setMenuFile] = useState('')
    let [menuSource, setMenuSource] = useState('')

    let [startTime, setStart] = useState(props.menu.startTime ? props.menu.startTime : '00:00')
    let [endTime, setEnd] = useState(props.menu.endTime ? props.menu.endTime : '23:59');
    let [daysPicked, setDays] = useState(props.menu.daysPicked ? props.menu.daysPicked : []);
    let [fromDate, setFromDate] = useState(props.menu.fromDate ? props.menu.fromDate : null)
    let [thruDate, setThruDate] = useState(props.menu.thruDate ? props.menu.thruDate : null)
    let [allDayChecked, toggleAllDayChecked] = useState(props.menu.allDayChecked ? props.menu.allDayChecked : null);
    let [scheduleBy, setScheduleBy] = useState(props.menu.fromDate ? 'dates' : 'days') // 'days' or 'date' 
    let [isLoading, setLoading] = useState(false);

    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    
    if(!menu || isLoading){
        return (
            <Grid container 
                style={{width: '100%', height: '100%', minHeight:'300px', minWidth:'300px'}}
                justify="center"
                alignItems="center"
                direction="row">
                    <Grid item ><CircularProgress/></Grid>
            </Grid>
        )
    }
    
    const updateSchedule = async () => {
        const scheduleObject = {
            startTime: allDayChecked ? '00:00' : startTime,
            endTime: allDayChecked ? '23:59' : endTime,
            fromDate,
            thruDate,
            daysPicked,
            allDayChecked,
            scheduleBy
        }

        setLoading(true);
        let response;
        response = await updateMenuSchedule(menu._id, scheduleObject)

        if(response && response.success){
            global.showNotification('Menu updated successfully','success')
        }
        if(response && !response.success){
            global.showNotification('Menu updated unsuccessful')
        }
        setLoading(false)
        props.toggleAddEdit('')
    }

    const handleDayChange = (day) => {
        if (_.includes(daysPicked, day)){
           const updatedDays = _.remove(daysPicked, (d)=>{
               return day != d
           })
           setDays(updatedDays)
            
        } else if (day == 'all') {
            setDays((daysPicked && daysPicked.length == 7) ? [] : days)
        } else {
            setDays([...daysPicked, day])
        }
    }

    const publishMenu = async () => {
        setLoading(true);
        
        let menuObj = {
            isGroup: group ? true : false,
            id: entity._id,
            menuUrl: menuUrl ? menuUrl : null,
            menuFile: menuFile ? menuFile : null,
            schedule: {
                startTime: allDayChecked ? '00:00' : startTime,
                endTime: allDayChecked ? '23:59' : endTime,
                fromDate,
                thruDate,
                daysPicked,
                allDayChecked,
            }
        }

        if (scheduleBy == 'days') {
            delete menuObj.schedule.fromDate
            delete menuObj.schedule.thruDate
        }
        if (scheduleBy == 'dates') {
            delete menuObj.schedule.daysPicked
            delete menuObj.schedule.allDayChecked
        }

        const response = await uploadMenuRestaurantOrGroupApi(menuObj)
        if (response.success) {
            global.showNotification('Menu uploaded successfully','success')
            props.toggleAddEdit(null)
        }
        if(!response.success){
            global.showNotification('Menu upload unsuccessful')
        }
        setLoading(false)
    }
    
	return (
        <>
            <DialogTitle onClose={ ()=>props.toggleAddEdit(null) } id="customized-dialog-title">
                {props.menu == 'newMenu' ? 'ADD MENU' :  'EDIT MENU SCHEDULE'}
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2} style={{width:'80%', margin:'auto'}}>
                    {props.menu == 'newMenu'  &&
                        <>
                            <Grid item xs={12} sm={12} style={{textAlign:'left'}}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Menu source:</FormLabel>
                                    <RadioGroup row aria-label="Menu source" name="useUrl" value={menuSource} 
                                        onChange={(e)=> {
                                            setMenuSource(e.target.value)
                                            setMenuUrl(null)
                                            setMenuFile(null)
                                            }
                                        }
                                    >
                                        <FormControlLabel value={'file'} control={<Radio />} label="Upload file" />
                                        <FormControlLabel value={'url'} control={<Radio />} label="URL" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                {menuSource == 'url' &&
                                    <>
                                        <TextField 
                                            onChange={(e) => setMenuUrl(e.target.value)}
                                            style={{width: '100%'}}
                                            placeholder={'http://www....'}
                                            value={menuUrl}
                                        >
                                        </TextField>
                                    </>
                                }
                                {menuSource == 'file' &&
                                    <>  
                                        <form encType="multipart/form-data">
                                            <input 
                                                type='file' 
                                                accept={'image/*,application/pdf'}
                                                onChange={(e) => {
                                                    if(e.target.files && e.target.files.length > 0){
                                                        if(e.target.files[0].size <= (1024*1024*10) ){ //10MB
                                                            setMenuFile(e.target.files[0]) 
                                                        } else {
                                                            global.showNotification('The file size is too large, 10MB limit', 'error')
                                                        }
                                                    }
                                                }}
                                                disabled={menuUrl && menuUrl.length}
                                            />
                                            {restaurantWebsite && restaurantWebsite.menuUrl &&
                                                <a href={restaurantWebsite.menuUrl}>{restaurantWebsite.menuUrl}</a>
                                            }
                                        </form>
                                    </>
                                }
                            </Grid>
                        </>
                    }
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Schedule by:</FormLabel>
                            <RadioGroup row aria-label="Schedule by" name="scheduleBy" value={scheduleBy} 
                                onChange={(e)=> {
                                    setScheduleBy(e.target.value)
                                    if (e.target.value == 'days') {
                                        setFromDate(null)
                                        setThruDate(null)
                                    }
                                    if (e.target.value == 'dates') {
                                        setDays([])
                                    }
                                }}>
                                <FormControlLabel value={'days'} control={<Radio />} label="Days of the week" />
                                <FormControlLabel value={'dates'} control={<Radio />} label="Dates" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item container xs={12}>
                            {scheduleBy == 'days' &&
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            style={{display:'block'}}
                                            label={'All week'}
                                            control={
                                                <Checkbox
                                                    checked={daysPicked && daysPicked.length == 7}
                                                    onChange={()=>handleDayChange('all')}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                            }
                                        />
                                        {days.map((day) => {
                                            return (
                                                    <FormControlLabel
                                                        style={{display:'block'}}
                                                        label={day}
                                                        control={
                                                            <Checkbox
                                                                checked={_.includes(daysPicked, day)}
                                                                onChange={()=>handleDayChange(day)}
                                                                name="checkedB"
                                                                color="primary"
                                                            />
                                                        }
                                                    />
                                                )
                                            }
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <> 
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={allDayChecked}
                                                        onChange={()=>toggleAllDayChecked(!allDayChecked)}
                                                        name="checkedB"
                                                        color="primary"
                                                    />
                                                }
                                                label="All day"
                                            /> 
                                            <br/>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardTimePicker
                                                        label="Start time"
                                                        onChange={date => {setStart(moment(date).format('HH:mm'))}} 
                                                        disabled={allDayChecked}
                                                        invalidDateMessage={null}
                                                        id="time-picker-start-day"
                                                        InputLabelProps={{ shrink: true }}
                                                        value={moment(startTime, "HH:mm")}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change time',
                                                        }}
                                                />
                                                <br/>
                                                <KeyboardTimePicker
                                                        label="End time"
                                                        onChange={date => {setEnd(moment(date).format('HH:mm'))}} 
                                                        invalidDateMessage={null}
                                                        id="time-picker-end-day"
                                                        disabled={allDayChecked}
                                                        InputLabelProps={{ shrink: true }}
                                                        value={moment(endTime, "HH:mm")}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change time',
                                                        }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </>
                                    </Grid>
                                </>
                            }
                        <Grid item container>
                            {scheduleBy == 'dates' &&
                                <>
                                    <Grid item container xs={12} spacing={1}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid item xs={12} sm={6}>
                                                <KeyboardDatePicker
                                                    label="Start date"
                                                    value={fromDate}
                                                    onChange={date => {
                                                        if (!fromDate) {
                                                            setFromDate(moment(date, 'ddd MMM DD YYYY HH:mm:ss GMTZZ').startOf('day').format()) 
                                                        } else {
                                                            setFromDate(moment(date, 'ddd MMM DD YYYY HH:mm:ss GMTZZ').format()) 
                                                        }
                                                    }} 
                                                    autoOk
                                                    openTo="date"
                                                    initialFocusedDate=""
                                                    showTodayButton
                                                    format="MM/dd/yyyy"
                                                    id="date-picker-start"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}    
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <KeyboardTimePicker
                                                    label="Start time"
                                                    onChange={date => {setFromDate(moment(date, 'ddd MMM DD YYYY HH:mm:ss GMTZZ').format())}} 
                                                    invalidDateMessage={null}
                                                    // margin="normal"
                                                    id="time-picker-start"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={fromDate}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <KeyboardDatePicker
                                                    autoOk
                                                    initialFocusedDate=""
                                                    showTodayButton
                                                    format="MM/dd/yyyy"
                                                    id="date-picker-end"
                                                    label="End date"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}    
                                                    value={thruDate}
                                                    onChange={date => {
                                                        if (!thruDate) {
                                                            setThruDate(moment(date, 'ddd MMM DD YYYY HH:mm:ss GMTZZ').endOf('day').format()) 
                                                        } else {
                                                            setThruDate(moment(date, 'ddd MMM DD YYYY HH:mm:ss GMTZZ').format()) 
                                                        }
                                                    }} 
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <KeyboardTimePicker
                                                    id="time-picker-end"
                                                    label="End time"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={thruDate}
                                                    onChange={date => {setThruDate(moment(date, 'ddd MMM DD YYYY HH:mm:ss GMTZZ').format())}} 
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                    invalidDateMessage={null}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid> 
                                </>
                            }
                        </Grid>
                    </Grid>
                    <Grid item align="center" xs={12}>
                        {props.menu == 'newMenu' ?
                            <Button disabled={!(menuFile || menuUrl) || !(fromDate && thruDate || daysPicked.length > 0)} variant="contained" color="primary" style={{margin: 'auto'}} onClick={()=>publishMenu()}>
                                <PublishIcon style={{marginRight: 10, marginLeft: -2}}/> 
                                Publish Menu
                            </Button>
                        :   
                            <Button disabled={!(menuFile || menuUrl) || !(fromDate && thruDate || daysPicked.length > 0)} variant="contained" color="primary" style={{margin: 'auto'}} onClick={()=>updateSchedule()}>
                                <PublishIcon style={{marginRight: 10, marginLeft: -2}}/> 
                                Update Menu
                            </Button>
                        }
                    </Grid>
                </Grid>  
            </DialogContent>
        </>
    )
};
