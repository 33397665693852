import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import log from 'loglevel';
import _ from 'lodash';
import {
	Grid,
	Stepper,
	Step,
	StepButton,
	Button,
	CircularProgress,
	Container,
	Box,
	Paper,
	Typography,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {ReactComponent as DashNowLogo} from '../../assets/svgs/dash-now-logo-white.svg';
import theme from '../../theme'
import GatewaySetup from './pages/GatewaySetup'
import RestaurantInfo from './pages/RestaurantInfo'
import Install from './pages/Install'
import Summary from '../restaurantEnrollment/pages/Summary'
import RequestComplete from '../requestComplete';
import publishApi from '../../actions/publishApi';
import { urlParse } from '../../utils/urlParser'
import findRestaurantOnboardingRequestApi from '../../actions/findRestaurantOnboardingRequestApi'
import createRestaurantOnboardingRequestApi from '../../actions/createRestaurantOnboardingRequestApi'
import history from '../../history'
import getDropdownListApi from '../../actions/getDropdownListApi';

const headerHeight = 80
const activeButtonBarHeight = 60

const styles = (theme) => ({
	root: {
		backgroundColor: theme().palette.background.default,
		overflow: 'hidden',
	},
	stepper: {
		width: '100%',
		backgroundColor: theme().palette.background.default,
		'&$selected': {
			backgroundColor: '#5220A8'
		}
	},
	lowerPage: {
		height: '100%',
		width: '100%',
		// marginTop: stepperHeight + 50,
		padding: '1%'
	},
	header: {
		position: 'absolute',
		top: 0,
		width: '100%',
		height: `${headerHeight}px`,
		zIndex: 11111,
	},
	content: {
		position: 'absolute',
		top: `${headerHeight}px`,
		bottom: `${activeButtonBarHeight}px`,
		width: '100%',
		overflow: 'auto',
		marginBottom: 20,
	},
	actionButtonBar: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		height: `${activeButtonBarHeight}px`,
		zIndex: 11111,
		paddingTop: 20,
	},
});

function camelize(str) {
	return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
		return index == 0 ? word.toLowerCase() : word.toUpperCase();
	}).replace(/\s+/g, '');
}

const Dash = (props) => {
	const { classes } = props;
	const [steps, setSteps] = useState([])
	const [posTypes, setPosTypes] = useState([]);
	const [activeStep, setActiveStep] = useState(0);
	const [stepName, setStepName] = useState('Restaurant Info');
	const [spreedlyGatewayOptions, setSpreedlyGatewayOptions] = useState([])
	const [gatewayTypes, setGatewayTypes] = useState([])
	const [loadingAsync, setLoadingAsync] = useState(true)
	const [completed, setCompleted] = useState(new Set());
	const [request, setRequest] = useState({})


	const smartSetRequest = (e, fieldName = null, value = null) => {
		const val = (e && e.target && e.target.value) ? e.target.value : value;
		const requestClone = { ...request };
		console.log(val)
		fieldName = fieldName ? fieldName : e.target.getAttribute('dfieldname');
		// console.log(request.data.restaurants[0].gateways[0].spreedlyGatewayType)
		console.log(fieldName)
		_.set(requestClone, fieldName, val);
		setRequest(requestClone);
		console.log(request)
		// addObjectToSave(request);
	}


	const handleStep = step => () => {
		setActiveStep(step);
	};
	const backStep = step => () => {
		if (step > 0) {
			setActiveStep(--step);
			setStepName(steps[step])
		}
	};
	const forwardStep = step => () => {
		log.info('step '+ step)
		setActiveStep(++step);
		setStepName(steps[step])
		if (step > steps.length -1) {
			submit()
		}
	};

	const submit = async () => {
		log.info('submitting request')
		setLoadingAsync(true)
		await publishApi(request)
		// forwardStep(activeStep)
		setLoadingAsync(false)
	}


	function isStepComplete(step) {
		return completed.has(step);
	}


	useEffect(() => {

		let params = urlParse(window.location.href).params
		
		async function fetchRequest() {
			let data
			if (params.req) {
				data = await findRestaurantOnboardingRequestApi(params.req)
			} else {
				data = await createRestaurantOnboardingRequestApi(params.req, params.ag, params.sr, params.packageId)
			}
			let thereq = data.request
			let steps = data.request.pages
			log.info(steps)
			log.info('reqid: ' + thereq._id)
			log.info(thereq)
			setRequest(thereq)
			setSteps(steps)
			log.info('bb plz')
			if ((!params.req || params.req == 'new') && thereq._id) {
				history.replace(`/restaurantOnboarding/?req=${thereq._id}`)
			}
		}
		fetchRequest()

		async function fetchdata() {

			let data = await getDropdownListApi();
			if (data) {
				log.info(data.spreedlyGatewayTypes)
				setSpreedlyGatewayOptions(data.spreedlyGatewayTypes.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1));
				// setSelectedGateway(data.spreedlyGatewayTypes[0]);
				// setRestaurantGroups(data.restaurantGroups ? data.restaurantGroups.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1) : []);
				// setLoyaltyPrograms(data.loyaltyPrograms ? data.loyaltyPrograms.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1) : []);
				setPosTypes(data.posTypes.sort((a, b) => (a.posType.toLowerCase() > b.posType.toLowerCase()) ? 1 : -1));
				let sortedGatewayTypes = data.gatewayTypes.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
				setGatewayTypes(sortedGatewayTypes);
				// setMasterGateways(data.masterGateways.sort((a, b) => (a.gatewayType.name.toLowerCase() > b.gatewayType.name.toLowerCase()) ? 1 : -1));
				// setDefaultPaymentMethods(sortedGatewayTypes)// not working yet
			}
			setLoadingAsync(false)
		}
		fetchdata()
		global.set({ headerTitle: 'RESTAURANT ONBOARDING' })
	}, [])

	const sm = useMediaQuery(theme().breakpoints.down('sm'));
	const xs = useMediaQuery(theme().breakpoints.down('xs'));

	return (
		<div className={classes.root}>
			<Box className={classes.header} bgcolor="#8B6BD9">
				<Container className={classes.headerContainer} maxWidth={sm ? 'sm': xs ? 'xs' : ''} style={{paddingTop: 16, paddingLeft: 50, paddingRight: 50}}>
					<Grid container justify="space-between">
						<Grid item justify="flex-start" xs={4} sm={4} md={4} lg={4}>
							{/* <DashNowLogo width={sm ? 24 : 28} height={sm ? 24 : 28}  /> */}
							<DashNowLogo width="188px" height="50px" onClick={(e) => { document.location = 'https://dashnow.com'}} />
						</Grid>
						<Grid item xs={8} sm={8} md={8} lg={8}>
							<Typography variant="h5" align="right" style={{color: '#FFF', marginTop: 10}}>
								Restaurant Onboarding Form
							</Typography>
						</Grid>
					</Grid>
					
				</Container>
			</Box>
			<Container className={classes.content} maxWidth={sm ? 'sm': xs ? 'xs' : ''}>
				<Grid container spacing={0}>
					{activeStep != steps.length &&
						<Stepper className={classes.stepper} alternativeLabel nonLinear activeStep={activeStep}>
							{steps.map((label, index) => {
								const stepProps = {};
								const buttonProps = {};
								return (
									<Step key={label} {...stepProps}>
									<StepButton
										completed={isStepComplete(index)}
										{...buttonProps}
									>
										{label}
									</StepButton>
									</Step>
								);
							})}
						</Stepper>
					}
					<Grid className={classes.lowerPage}>
						<Container>
							{loadingAsync && <Grid container justify="center"><CircularProgress /></Grid>}
							{!loadingAsync && stepName == "Restaurant Info"  && 
								(<RestaurantInfo
									record={request.data}
									dropDownOptions={{ posTypes }}
								/>
							)}
							{!loadingAsync && stepName == "Gateway Info" && // need to update this component
								(<GatewaySetup
									record={request.data}
									dropDownOptions={{ posTypes, spreedlyGatewayOptions, gatewayTypes }}
								/>
							)}
							{!loadingAsync && stepName == "Install"  && 
								(<Install
									record={request.data}
									dropDownOptions={{}}
								/>
							)}
							{!loadingAsync && stepName == "Review" && 
								(<Summary
									record={request.data}
									dropDownOptions={{}}
								/>
							)}
							{!loadingAsync && activeStep == (steps.length) && 
								(<RequestComplete 
									request={request} 
								/>
							)}
						</Container>
					</Grid>
				</Grid>
			</Container>
			{ (true || (activeStep != steps.length && activeStep != 0)) && 
				<Box className={classes.actionButtonBar} bgcolor="#F8F8F8" style={{borderTop: 'solid 1px #CCC'}}>
					<Container>
						<Button variant={'contained'} color="primary" onClick={backStep(activeStep)}>Back</Button>
						<Button variant={'contained'} color="primary" onClick={forwardStep(activeStep)} type='submit'>Next</Button>
					</Container>
				</Box>
			}
		</div>
	);
};

Dash.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withStyles(styles, { withTheme: true })(Dash);
