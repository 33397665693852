import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

export default function (props){
    let [restaurant] = global.get('restaurant')
    
    if(!restaurant){
        return (
            <Grid container 
                style={{width: '100%', height: '100%'}}
                justify="center"
                alignItems="center"
                direction="row">
                    <Grid item ><CircularProgress/></Grid>
            </Grid>
        )
    }

    return (
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <iframe id='iFramePreview' src={'https://' + (!global.isProd ? 'stage.' : '') + 'dnow.app/?r=' + restaurant.id + '&tb=1&z=' + ((restaurant.branding && restaurant.branding.splashLogo) ? '1-' : '0-' )+ `${restaurant.code}`} height={props.height ? props.height : 600} width={props.width ? props.width : 300}></iframe>
        </div>
    )
}