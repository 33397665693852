import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import log from 'loglevel';
import _ from 'lodash';
import {
	Grid,
	Typography,
	Tabs,
	Tab,
	Paper,
	Checkbox,
	FormControlLabel,
	FormControl,
	InputLabel,
	ClickAwayListener,
	MenuItem,
	Tooltip,
} from '@material-ui/core';
import { 
	ToggleButtonGroup,
	ToggleButton,
} from '@material-ui/lab'
import SelectMui from '@material-ui/core/Select';
import Info from '@material-ui/icons/Info';
import urlencode from 'urlencode'
import DTextField from '../../../components/dash/DTextField';
import tokenizeAchApi from "../../../actions/tokenizeAchApi";
import DForm2 from '../../../components/dash/DForm2';
import DRecord from '../../../components/dash/DRecord';
import DInput from '../../../components/dash/DInput';
import DToggleButtonGroup from '../../../components/dash/DToggleButtonGroup';
import AddCardPanel from '../../../dashboard/components/AddCardPanel'


const styles = (theme) => ({
	root: {},
	billingInfoWrapper: {
		paddingLeft: '4%',
		paddingRight: '4%',
		paddingBottom: '4%',
		paddingTop: '1%',
		marginBottom: '3%',
		marginRight: '3%'
	},
	subheadingText: {
		paddingTop: '1%',
		paddingBottom: '1%',
	},
	box: {
		backgroundColor: 'var(--white)',
        border: '1px solid #EAEAEA',
        boxShadow: '0 0 10px 5px rgba(231,231,231,0.50)',
        padding: 30,
        marginBottom: 20,
        borderRadius: 6,
	},
	toggleSelected: {
		backgroundColor: "#8B6BD9 !important;",
		color: "var(--white) !important;",
		'& p': {
			color: "var(--white) !important;",
			fontWeight: '500 !important',
		}
	}
});

const Dash = (props) => {
	
	

	useEffect(() => {
	}, [])
	

	const { classes } = props;
	let restaurantGroup = props.record ? props.record.restaurantGroup : ''
	let restaurants = props.record.restaurants


	const achInputUpdated = (e) => {
		let fieldName = e.target.getAttribute('dfieldname')
		let value = e.target.value
		// let origValue = _.get(request, fieldName);  //todo fix
		let origValue

		console.log("SETTING ACH INPUT") 
		console.log("  fieldName: " + fieldName)
		console.log("  value: " + value)
		console.log("  origValue: " + origValue) 

		// setRequest(e.target, fieldName, value) //todo fix
		
		let encryptedCard = restaurantGroup.billingInfo.encryptedCard

		let idxLeft = fieldName.indexOf("[")
		if (idxLeft > -1) {
			let restaurantIndex = fieldName.substring(idxLeft+1, idxLeft+4).replace("]", "").replace(".", "")
			console.log("restaurantIndex: " + restaurantIndex)
			encryptedCard = restaurants[restaurantIndex].billingInfo.encryptedCard
		}

		let bankName = encryptedCard.ach.bankName
		let nameOnAccount = encryptedCard.ach.nameOnAccount
		let routingNumber = encryptedCard.ach.routingNumber
		let accountNumber = encryptedCard.ach.accountNumber
		if (value != origValue) {
			encryptedCard.dirty = true
		}
	}


	const tokenizePayments = async () => {
		if (restaurantGroup.billingInfo.enabled) {
			let encryptedCard = restaurantGroup.billingInfo.encryptedCard
			await tokenizePayment(encryptedCard)
		} else {
			for (let x = 0; x < restaurantGroup.locationCount; x++) {
				let encryptedCard = restaurants[x].billingInfo.encryptedCard
				await tokenizePayment(encryptedCard)
			}
		}
		log.info("restaurants")
		log.info(JSON.stringify(restaurants))
		return true;
	}

	const tokenizePayment = async (encryptedCard) => {
		if (encryptedCard) {
			console.log("TOKENIZING PAYMENT DATA")
			console.log("encryptedCard")
			console.log(JSON.stringify(encryptedCard))
			if (encryptedCard.paymentType == "ach" && !encryptedCard.token) {
				console.log("ach")
				if (encryptedCard.ach.bankName && encryptedCard.ach.nameOnAccount && encryptedCard.ach.routingNumber && encryptedCard.ach.accountNumber) {
					console.log("has all data")
					let data = await tokenizeAchApi(encryptedCard, true)
					encryptedCard.token = data
					console.log("token: " + data)
				}
			}
		}
		return encryptedCard;
	}

	const getBillingInfoComponent = (record, restaurantIdx) => {
		return (
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<DRecord record={record.billingInfo.encryptedCard} name="encryptedCard" />
				<Paper className={classes.box}>
					{restaurantIdx != undefined && 
						<Typography variant="body1" color="primary">
							{record.name} {restaurants && restaurants[restaurantIdx] && restaurants[restaurantIdx].locationName && '- ' + restaurants[restaurantIdx].locationName}
						</Typography>
					}

					<DInput fieldType="toggleGroup" name="paymentType" style={{width: '100%', marginBottom: 20}} exclusive rerender>
						<ToggleButton selected={record.billingInfo.encryptedCard.paymentType == "ach"} classes={{selected: classes.toggleSelected}} value="ach">
							<Typography variant="body1" color="primary" className={classes.subheadingText}>ACH</Typography>
						</ToggleButton>
						<ToggleButton selected={record.billingInfo.encryptedCard.paymentType == "creditCard"} classes={{selected: classes.toggleSelected}} value="creditCard">
							<Typography variant="body1" color="primary" className={classes.subheadingText}>Credit Card</Typography>
						</ToggleButton>
						<Tooltip title={<Typography variant="body2">All billing information is securely stored through our partner, CardConnect, with the highest level of PCI compliance.</Typography>} placement="right" color="primary">
							<Info style={{paddingTop: '.5em'}} toolTip />
						</Tooltip>
					</DInput>

					{record.billingInfo.encryptedCard.paymentType == "ach" && 
						<>
							<DRecord record={record.billingInfo.encryptedCard.ach} name="ach" />
							<Grid container spacing={3}>
								{/* https://docs.spreedly.com/guides/echecks/ */}
								{/* <form accept-charset="UTF-8" action="https://core.spreedly.com/v1/payment_methods" method="POST">
									<fieldset>
										<label for="full_name">Name on Account</label>
										<input id="full_name" name="full_name" type="text" />

										<label for="bank_name">Bank Name</label>
										<input id="bank_name" name="bank_name" type="text" />

										<label for="bank_routing_number">Bank Routing Number</label>
										<input id="bank_routing_number" name="bank_routing_number" type="text" />

										<label for="bank_account_number">Account Number</label>
										<input id="bank_account_number" name="bank_account_number" type="text" />

										<button type='submit'>Submit Payment</button>
									</fieldset>
								</form> */}
								<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
									<DInput name="bankName" label="Bank Name" required />
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
									<DInput name="nameOnAccount" label="Name on Account" required />
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
									<DInput name="routingNumber" label="Routing Number" required />
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
									<DInput name="accountNumber" label="Account Number" required />
								</Grid>
							</Grid>
							<DRecord record={record.billingInfo.encryptedCard} name="encryptedCard" />
							<Grid container spacing={3}>
								{restaurantIdx == -1 && <>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography variant="body1" color="primary" className={styles.subheadingText}>
											{record.name} Billing Address
										</Typography>
									</Grid>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<div>
											<DInput name="addressLine1" label="Address Line 1" required />
										</div>
									</Grid>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<div>
											<DInput name="addressLine2" label="Address Line 2" />
										</div>
									</Grid>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<div>
											<DInput name="city" label="City" required />
										</div>
									</Grid>
									<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
										<FormControl style={{ width: '98%', }} InputLabelProps={{ shrink: true }}>
											<InputLabel shrink htmlFor="state">State</InputLabel>
											<DInput
												InputLabelProps={{ shrink: true }}
												
												value={record.state}
												fieldType="Select"
												// onChange={(e) => setRequest(e, `data.record.state`)}  //todo fix
											>
												<MenuItem disabled value=""><em>State</em></MenuItem>
												{states.map((pt) => <MenuItem value={pt.value}>{pt.label}</MenuItem>)}
											</DInput>
										</FormControl>
									</Grid>
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<div style={{  }}>
											<DInput name="zipCode" label="Zip Code" required />
										</div>
									</Grid>
								</>}
							</Grid>
						</>
						
					}
					{record.billingInfo.encryptedCard.paymentType != "ach" &&
						<AddCardPanel record={record} next={(cardToken, paymentMethod) => {
							log.info('restaurantIdx: ' + restaurantIdx)
							restaurants[restaurantIdx].billingInfo.encryptedCard.token = cardToken
							restaurants[restaurantIdx].billingInfo.encryptedCard.cardType = paymentMethod.card_type
							restaurants[restaurantIdx].billingInfo.encryptedCard.lastFour = paymentMethod.last_four_digits
						}} />
					}
				</Paper>
			</Grid>
		)
	}

	return (
		<DForm2 record={props.record} name="billingInfo" validate={false} readOnly={false}>
			<Grid container>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Typography variant="subtitle1" color="primary" gutterBottom>
						Please provide the billing info below
					</Typography>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<DRecord record={props.record.restaurantGroup} name="restaurantGroup" />
					<FormControlLabel
						control={
							<DInput 
								color='primary'
								fieldType='CheckBox'
								name="billingInfo.enabled"
								// value={!!restaurantGroup.billingInfo.enabled}
								style={{ width: '10%', paddingTop:'8.5px', }}
							/>
						}
						label={<Typography>Billing information is the same for all locations</Typography>}
					/>
				</Grid>
				{!restaurantGroup.billingInfo.enabled &&
					_.times(props.record.restaurantGroup.locationCount, (num) => {
						return (
							getBillingInfoComponent(props.record.restaurants[num], num)
						)
					})
				}
				{restaurantGroup.billingInfo.enabled &&
					getBillingInfoComponent(props.record.restaurantGroup, -1)
				}
			</Grid>
		</DForm2>
	);
};

export default withStyles(styles, { withTheme: true })(Dash);



const states = [
	{ value: 'AL', label: 'Alabama' },
	{ value: 'AK', label: 'Alaska' },
	{ value: 'AZ', label: 'Arizona' },
	{ value: 'AR', label: 'Arkansas' },
	{ value: 'CA', label: 'California' },
	{ value: 'CO', label: 'Colorado' },
	{ value: 'CT', label: 'Connecticut' },
	{ value: 'DE', label: 'Delaware' },
	{ value: 'FL', label: 'Florida' },
	{ value: 'GA', label: 'Georgia' },
	{ value: 'HI', label: 'Hawaii' },
	{ value: 'ID', label: 'Idaho' },
	{ value: 'IL', label: 'Illinois' },
	{ value: 'IN', label: 'Indiana' },
	{ value: 'IA', label: 'Iowa' },
	{ value: 'KS', label: 'Kansas' },
	{ value: 'KY', label: 'Kentucky' },
	{ value: 'LA', label: 'Louisiana' },
	{ value: 'ME', label: 'Maine' },
	{ value: 'MD', label: 'Maryland' },
	{ value: 'MA', label: 'Massachusetts' },
	{ value: 'MI', label: 'Michigan' },
	{ value: 'MN', label: 'Minnesota' },
	{ value: 'MS', label: 'Mississippi' },
	{ value: 'MO', label: 'Missouri' },
	{ value: 'MT', label: 'Montana' },
	{ value: 'NE', label: 'Nebraska' },
	{ value: 'NV', label: 'Nevada' },
	{ value: 'NH', label: 'New Hampshire' },
	{ value: 'NJ', label: 'New Jersey' },
	{ value: 'NM', label: 'New Mexico' },
	{ value: 'NY', label: 'New York' },
	{ value: 'NC', label: 'North Carolina' },
	{ value: 'ND', label: 'North Dakota' },
	{ value: 'OH', label: 'Ohio' },
	{ value: 'OK', label: 'Oklahoma' },
	{ value: 'OR', label: 'Oregon' },
	{ value: 'PA', label: 'Pennsylvania' },
	{ value: 'RI', label: 'Rhode Island' },
	{ value: 'SC', label: 'South Carolina' },
	{ value: 'SD', label: 'South Dakota' },
	{ value: 'TN', label: 'Tennessee' },
	{ value: 'TX', label: 'Texas' },
	{ value: 'UT', label: 'Utah' },
	{ value: 'VT', label: 'Vermont' },
	{ value: 'VA', label: 'Virginia' },
	{ value: 'WA', label: 'Washington' },
	{ value: 'WV', label: 'West Virginia' },
	{ value: 'WI', label: 'Wisconsin' },
	{ value: 'WY', label: 'Wyoming' }
];