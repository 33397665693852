import React from 'react';
import TableBody from '@material-ui/core/TableBody';


export default function DForm(props) {
	const components = {
		'div': `div`,
		'TableBody': TableBody
	}
	const Element = (props.element) ? components[props.element] : components['div']
	// console.log("element " + Element)
	return (
		<Element 
			class="DForm" 
			dashkey={props.dashkey} 
			collection={props.collection}
			style={props.style}
			element="div"
			className={props.className}
		>
			{props.children}
		</Element>
	);
}
