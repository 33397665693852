import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import log from 'loglevel';
import _ from 'lodash';
import {
	Grid,
	Button,
	CircularProgress,
	Container,
	Box,
	Paper,
	Typography,
	MenuItem, TextField
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from '../../theme'
import signupApi from '../../actions/signupApi';
import { urlParse } from '../../utils/urlParser'
import history from '../../history'
import getPosTypesApi from '../../actions/getPosTypesApi';
import DForm2 from '../../components/dash/DForm2';
import DRecord from '../../components/dash/DRecord';
import DInput from '../../components/dash/DInput';
import ReactGA from 'react-ga'
import accessUtils from '../../utils/accessUtils';

const headerHeight = 100
const activeButtonBarHeight = 0

const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.background.default,
		overflow: 'hidden',
	},
	header: {
		position: 'absolute',
		top: 0,
		width: '100%',
		height: `${headerHeight}px`,
		zIndex: 11111,
	},
	content: {
		position: 'absolute',
		top: `${headerHeight}px`,
		bottom: `${activeButtonBarHeight}px`,
		width: '100%',
		overflow: 'auto',
	},
	box: {
		backgroundColor: 'var(--white)',
		border: '1px solid #EAEAEA',
		boxShadow: '0 0 10px 5px rgba(231,231,231,0.50)',
		padding: 30,
		marginBottom: 20,
		borderRadius: 6,
	},
	link: {
		textDecoration: 'none', 
		fontFamily: '"Open Sans", Arial, sans-serif',
		marginLeft: '24px',
		fontWeight: 600,
		fontSize: '14px',
		lineHeight: '14px',
		color: 'var(--white)',
	}
});

function camelize(str) {
	return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
		return index == 0 ? word.toLowerCase() : word.toUpperCase();
	}).replace(/\s+/g, '');
}

const Dash = (props) => {
	const GA_SIGNUP = "Curbly Signup";
	const isMobile = useMediaQuery('(max-width:500px)')
	const [phoneNumber, setPhoneNumber] = useState()
	const [phoneNumberFormatted, setPhoneNumberFormatted] = useState("")

	const [agentReferralId, setAgentReferralId] = global.get('agentReferralId')
	const [affiliateCode, setAffiliateCode] = global.get('affiliateCode')
	const [signupSource, setSignupSource] = global.get('signupSource')
	const { classes } = props;
	const [posTypes, setPosTypes] = useState([]);
	const [saving, setSaving] = useState(false)
	const [loadingAsync, setLoadingAsync] = useState(true)

	let params = urlParse(document.referrer).params

	const [record, setRecord] = useState({
		appName: global.isCurbly() ? 'Curbly' : global.isTextPay() ? 'TextPay' : 'DashNow',
		numberOfSites: 1,
		agentId: agentReferralId,
		signupSource: signupSource,
		siteGroupName: '',
		posType: '',
		posTypeOther: '',
		primaryContactInfo: { 
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			title: '',
		},
	})

	const submit = async () => {
		log.info('submitting form')
		setLoadingAsync(true)
		// await publishApi(request)
		setLoadingAsync(false)
	}

	let saveForm = () => {
        setSaving(true)
		let onValid = async () => {
			setSaving(false)
			let recordCopy = {...record}
			delete recordCopy.dform
			delete recordCopy.primaryContactInfo.dform
			let resp = await signupApi(recordCopy)
			if (resp) {
				let newAccount = resp.account
				history.push('/login?a=' + newAccount._id + '&e=' + recordCopy.primaryContactInfo.email)
			}

			// let encryptedCard = await tokenizePayment()
			// setFormData(defaultForm)
		}
		ReactGA.event({
			category: GA_SIGNUP,
			action: "Signup Form Submitted"
		});
		let onError = async () => {
			setSaving(false)
		}
		global.set({onValid, onError, validateNow: true})
	};


	const handleSetPhoneNumber = (phoneNumberStr) => {
		// remove non numeric values from string & set state
		const newPhoneNumber = phoneNumberStr.replace(/\D/g, '');
		if (newPhoneNumber !== phoneNumber) {
            setPhoneNumber(newPhoneNumber);
			setPhoneNumberFormatted(formatPhoneNumber(newPhoneNumber));
		}
    }
    
    const formatPhoneNumber = (phoneNumberStr) => {
		if (phoneNumberStr.length < 4) {
			phoneNumberStr = phoneNumberStr.replace(/(\d{1,3})/g, "($1");
		} else if (phoneNumberStr.length < 7) {
			phoneNumberStr = phoneNumberStr.replace(/(\d{3})(\d{1,3})/g, "($1) $2");
		} else if (phoneNumberStr.length <= 10) {
			phoneNumberStr = phoneNumberStr.replace(/(\d{3})(\d{3})(\d{1,4})/g, "($1) $2-$3");
		}
		return phoneNumberStr;
    }
    
    const errorPhoneNumber = (phoneNumber) => {
		return phoneNumber ? phoneNumber.length < 10 : false
    }

	useEffect(() => {
		accessUtils.logout(false)
		async function fetchdata() {
			let data = await getPosTypesApi();
			if (data) {
				setPosTypes(data.sort((a, b) => (a.posType.toLowerCase() > b.posType.toLowerCase()) ? 1 : -1));
			}
			setLoadingAsync(false)
		}
		fetchdata()
		ReactGA.event({
			category: GA_SIGNUP,
			action: "Signup Initiated"
		});
		global.set({ headerTitle: 'SIGNUP' })

		let updated = false
		if (params.a && agentReferralId != params.a) {
			setAgentReferralId(params.a)
			updated = true
		}

		if (params.ref && affiliateCode != params.a) {
			setAffiliateCode(params.ref)
			updated = true
		}

		if (params.src && agentReferralId != params.src) {
			setSignupSource(params.src)
			updated = true
		}

		if (updated) {
			setRecord({...record, agentId: params.a, affiliateCode: params.ref, signupSource: params.src})
		}

	}, [])

	const sm = useMediaQuery(theme().breakpoints.down('sm'));
	const xs = useMediaQuery(theme().breakpoints.down('xs'));

	return (
		<div className={classes.root}>
			<Box className={classes.header} bgcolor="#8647dd">
				<Container maxWidth={sm ? 'sm': xs ? 'xs' : false} style={{height: '100%', maxWidth: '1100px', paddingLeft: 20}} disableGutters={true}>
					<Grid container justify="space-between" alignItems="center" style={{height: '100%', width: '100%'}}>
						<Grid item xs={6}>
							<a href="https://curbly.app/">
								<img src="https://curbly.app/wp-content/uploads/2020/09/curbly-logo-white@5x.png" alt="Curbly" id="logo" height="43px" />
							</a>
						</Grid>
						{!xs && !sm &&
							<Grid item xs={6} justify="flex-end" container>
								<a href="https://curbly.app" className={classes.link}>Home</a>
								<a href="https://curbly.app#howItWorks" className={classes.link}>How Does It Work?</a>
								<a href="https://curbly.app#features" className={classes.link}>Features</a>
								<a href="https://curbly.app#pricing" className={classes.link}>Pricing</a>
								<a href="https://dashboard.curbly.app/signup" className={classes.link}>Sign Up</a>
								<a href="https://dashboard.curbly.app" className={classes.link}>Login</a>
							</Grid>
						}
					</Grid>
				</Container>
			</Box>
			<Box className={classes.content}>
				<Container maxWidth={sm ? 'sm': xs ? 'xs' : false} style={{maxWidth: '1080px'}} disableGutters={true}>
					<Grid container justify="center" spacing={0}>
						<Grid>
							<Container>
								{loadingAsync && <Grid container justify="center"><CircularProgress /></Grid>}
								{!loadingAsync && 

									<DForm2 record={record} >
										<Grid container>
											<Grid container>
												<Grid item xs={12}>
													<Typography variant="h4" color="primary" style={{marginTop: 25, marginBottom: 15}}>
														Sign Up Today!
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<Paper className={classes.box}>
														{/* <DRecord record={record} /> */}
														<Grid container spacing={3}>
															<Grid item xs={12}>
																<Typography variant="h6" color="primary" style={{marginBottom: 15}}>
																	Company Info
																</Typography>
																<Grid container spacing={3}>
																	<Grid item xs={12} sm={6}>
																		<DInput name="siteGroupName" label="Company Name" required />
																	</Grid>
																	<Grid item xs={12} sm={4} md={2}>
																		<DInput name="numberOfSites" label="# of Locations" type="number" min={1} max={50} required />
																	</Grid>

																	<Grid item xs={12} sm={6}>
																		<DInput fieldType="Select" name="posType" label="POS Type" rerender required>
																			{posTypes.map((p) => <MenuItem value={p._id}>{p.posType}</MenuItem>)}
																			<MenuItem value='-1'>Other</MenuItem>
																		</DInput>
																	</Grid>

																	{record.posType == '-1' &&
																		<Grid item xs={12} sm={6}>
																			<DInput name="posTypeOther" label="POS Type Other" />
																		</Grid>
																	}
																</Grid>
															</Grid>

															<Grid item xs={12}>
																<Typography variant="h6" color="primary" style={{marginBottom: 15}}>
																	Primary Contact
																</Typography>
																<Grid container spacing={3}>
																	<DRecord record={record.primaryContactInfo} name="primaryContactInfo" />
																	<Grid item xs={12} sm={6} md={6} lg={6}>
																		<div>
																			<DInput name="firstName" label="First Name" required />
																		</div>
																	</Grid>
																	<Grid item xs={12} sm={6} md={6} lg={6}>
																		<div>
																			<DInput name="lastName" label="Last Name" required />
																		</div>
																	</Grid>
																	<Grid item xs={12} sm={12} md={6} lg={6}>
																		<div>
																			<DInput name="title" label="Title" required />
																		</div>
																	</Grid>
																	<Grid item xs={12} sm={12} md={6} lg={6}>
																		<div>
																			<TextField
																				onChange={(event) => { handleSetPhoneNumber(event.target.value); }}
																				value={phoneNumberFormatted}
																				required
																				type="tel"
																				pattern="[0-9]*"
																				margin="normal"
																				error={errorPhoneNumber(phoneNumber)}
																				helperText={errorPhoneNumber(phoneNumber) ? "Must be valid phone number" : ""}
																				label="Phone Number"
																				name="phoneNumber"
																				fullWidth={true}
																				InputLabelProps={{ shrink: true }}
																				inputProps={{ maxLength: 14 }}
																				style={{ paddingTop: 0, marginTop: '0px' }}
																			/>
																			<DInput name='phone' label="Phone #" overrideValue={phoneNumber}  required type="tel" style={{display:'none'}}/>
																		</div>
																	</Grid>
																	<Grid item xs={12} sm={12} md={6} lg={6}>
																		<div>
																			<DInput name="email" type="email" label="Email" required />
																		</div>
																	</Grid>
																</Grid>
															</Grid>
															<Grid item xs={12}>
																<Button variant={'contained'} color="primary" onClick={event => { saveForm() } } type='submit'>Create Account</Button>
															</Grid>
														</Grid>
													</Paper>
												</Grid>
											</Grid>
										</Grid>
									</DForm2>

								}
							</Container>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</div>
	);
};

Dash.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withStyles(styles, { withTheme: true })(Dash);
