import log from 'loglevel';
import dashFetch from '../utils/dashFetch';


export default async function (restaurantId, tableNumbers, qrCodesPerTable = 2, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('generateQrCodePageApi ');
		log.info('restaurantId ' + restaurantId);
		const url = `${global.dashServerUrl}/dashboard/api/v1/pdf/generateQrCodePage`;

		const body = {
			restaurantId,
			tableNumbers,
			qrCodesPerTable,
			debug
		};



		let options = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		}

		fetch(url, options, debug).then((res) => {
			log.info('>>> generateQrCodePageApi JSON');
			if (res.ok) {
				res.arrayBuffer().then((data) => {
					if (data) {
						resolve(data);
					} else {
						resolve(false)
					}
				})
			} else {
				resolve(false)
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
		
	})
}