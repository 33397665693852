import React, { useEffect } from 'react';
import Switch from '@material-ui/core/Switch';

export default function DSwitch(props) {
	useEffect(() => {});
	return (
		
		<Switch
			inputProps={{
				dfieldname: props.dfieldname,
				index: props.index,
				'aria-label': 'primary checkbox' 
			}}
			onChange={props.onChange}
			value={props.value}
			checked={props.checked}
			color={"primary"}
			variant="standard"
			disabled={props.disabled}
		/>
	);
}
