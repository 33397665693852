import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Grid, Paper, Drawer, Zoom, MenuItem, Menu, IconButton, Badge } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Button, TextField, Checkbox, Box, CircularProgress, Tabs, Tab, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import theme from '../theme'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import history from '../history.js'
import _ from 'lodash';
import DashCardCurbly from './components/DashCardCurbly'
import TransactionListing from './transactionListing';
import TodaySearch from './components/TodaySearch';
import accessUtils from '../utils/accessUtils';
import moment from 'moment'
import Sound from 'react-sound';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CheckDetailsCurbly from './components/CheckDetailsCurbly';
import TutorialCurbly from './tutorialCurbly';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    scrolling: {
        overflow: 'auto',
        height: 'auto'
    },
    checks: {
        paddingLeft: 5,
        paddingRight: 5,
        maxHeight: 'calc(100% - 3em)',
    },
    addRestaurantIcon: {
		float: 'left',
		// marginTop: 5,
		// marginRight: 5,
		// width: '20px',
		height: '20px'
	},
    paper: {
        // paddingRight:8,
        // paddingleft:8,
        // margin: 8,
        // minHeight: '100%',
        // maxHeight: '100%',
        width:'100%',
        height:'100%'
    },
    iconWrapper: {
        height: '2.7em', 
        display: 'flex',
        alignItems: 'center',
        height: 48,
    },
    columnHeader: {
        height: 48
    },
    fabButtonMobile: {
        position: 'absolute',
        zIndex: 1000,
        left: 0,
        right: 0,
        margin: '0 auto',
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1000,
        // left: 0,
        right: 40,
        margin: '0 auto',
    },
    badge: {
        marginRight:-8,
    },
    dot: {
        height: 8,
        width: 8,
        borderRadius: '50%',
        display: 'inline-block',
        margin: 3,
        backgroundColor: '#DDD',
      }
}))


export default (props) => {
    const classes = useStyles();
    let [restaurant] = global.get('restaurant')
    let [checks, setChecks] = global.get('checks')
    let [filteredChecks, setFilteredChecks] = React.useState([])
    let [orderedChecks, setOrderedChecks] = React.useState([])
    let [arrivedChecks, setArrivedChecks] = React.useState([])
    let [completedChecks, setCompletedChecks] = React.useState([])
    let [drawerOpen, setDrawerOpen] = React.useState(false)
    let [dialogContent, setDialogContent] = useState()

    if (!localStorage.tabSelected) localStorage.tabSelected = 'ordered'

    let [selectedTab, setSelectedTab] = React.useState(localStorage.tabSelected)

    let smallScreen = useMediaQuery(theme().breakpoints.down('sm'));
    let [textToPayEnabled] = global.get('textToPayEnabled')
    let [imHereEnabled] = global.get('imHereEnabled')
    let [orderUpEnabled] = global.get('orderUpEnabled')
    let [newCustomerArrival] = global.get('newCustomerArrival', false)
    let [todaySearchFilter] = global.get('todaySearchFilter', '')
    let [checkTypeFilter] = global.get('checkTypeFilter')
    let signedIn = accessUtils.isLoggedIn()
    let [showAll, setShowAll] = global.get('showAll', false)
    let [showTutorial] = global.get('showTutorial')
    
    
    let [showOrderedColumn, setShowOrderedColumn] = React.useState(true)
    let [showArrivedColumn, setShowArrivedColumn] = React.useState(true)
    let [showCompletedColumn, setshowCompletedColumn] = React.useState(false)
    let [isLoading, setIsLoading] = React.useState(false)
    
    let [numberOfColumns, setNumberOfColumns] = React.useState(3)
    let [selectedDateFilter, setSelectedDateFilter] = React.useState(7)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let setShowTutorial = (val) => {
        delete localStorage.showTutorial
        global.set({showTutorial: val})
    }

    let showSearch = true

    const closeContent = () => {
        setDialogContent(null)
    }

    const showCheckDetails = (check = null) => {
        setDialogContent(
            <CheckDetailsCurbly check={check} close={closeContent}/>
        )
    }

    let showTabs = smallScreen
    useEffect(() => {
        showTabs = smallScreen
    }, [smallScreen]);


    useEffect(() => {
        if (checks) {

            // REMOVE CHECKS OLDER THAN 24 HOURS FROM THE CHECKS ARRAY TO KEEP MEMORY USAGE DOWN
            let origChecksLength = checks.length
            checks = checks.filter(rec => moment(rec.createdAt) > moment().subtract(24, 'hours') && !rec.deleted && rec.pickupDetails && rec.pickupDetails.phoneNumber)
            if (origChecksLength != checks.length) {
                setChecks(checks)
            }

            filteredChecks = checks

            if (todaySearchFilter) {
                setShowAll(true)
                setSelectedTab("")
                let todaySearchFilterUpperCase = todaySearchFilter.toUpperCase()
                todaySearchFilterUpperCase = todaySearchFilterUpperCase.replace(/-/g, "").replace(/\./g, "")
                filteredChecks = filteredChecks.filter(rec => {
                    return (
                        (rec.checkNumber && rec.checkNumber.toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                        || (rec.orderNumber && rec.orderNumber.toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                        || (rec.tableNumber && rec.tableNumber.toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                        || (rec.totals.total && rec.totals.total.toString().toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                        || (rec.pickupDetails && rec.pickupDetails.guestName && rec.pickupDetails.guestName.toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                        || (rec.pickupDetails && rec.pickupDetails.phoneNumber && rec.pickupDetails.phoneNumber.replace(/-/g, "").replace(/\./g, "").toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                    )
                })
            } else {
                // if (showTabs) {
                //     filteredChecks = filteredChecks.filter(rec => {
                //         return rec.status == selectedTab
                //     })
                // }
                if (selectedTab == "") {
                    setSelectedTab(localStorage.tabSelected) // sets it back to pre cleared value
                }
                setShowAll(false)
            }

            setupColumns()
            //REMOVE CHECKS OLDER THAN 12 HOURS FROM THE UI
            // filteredChecks = filteredChecks.filter(rec => moment(rec.createdAt) > moment().subtract(12, 'hours'))
            filteredChecks = filteredChecks.map(rec => { 
                rec.payments = rec.payments ? rec.payments.filter(pmt => pmt.status == 'captured') : []
                return rec
            })

            setFilteredChecks(filteredChecks)
            if (signedIn) {
                localStorage.setItem('checkTypeFilter', checkTypeFilter)
            }
        }
    }, [checks, checkTypeFilter, todaySearchFilter, selectedTab, showTabs]);

    useEffect(() => {
        setOrderedChecks(filteredChecks.filter((rec) => { return rec.status == 'ordered' || rec.status == 'paid' }))
        setArrivedChecks(filteredChecks.filter((rec) => {  return rec.status == 'arrived' }))
        setCompletedChecks(filteredChecks.filter((rec) => { return rec.status == 'completed'}))
    }, [filteredChecks])


    const setupColumns = () => {
        setShowOrderedColumn(!showTabs || selectedTab == 'ordered')
        setShowArrivedColumn(!showTabs || selectedTab == 'arrived')
    }

    const setDefaultCheckTypeFilter = () => {
        let checkTypeFilterTmp = 'dineIn'
        if (textToPayEnabled) {
            checkTypeFilterTmp = (localStorage.checkTypeFilter) ? localStorage.checkTypeFilter :'toGo'
        }
        global.set({ checkTypeFilter: checkTypeFilterTmp })
    }

    useEffect(() => {
        setDefaultCheckTypeFilter()
        if (showTutorial != localStorage.showTutorial) {
            global.set({showTutorial: localStorage.showTutorial})
        }
    }, []);

    useEffect(() => {
        if (selectedTab != "") {
            localStorage.setItem('tabSelected', selectedTab)
        }
    }, [selectedTab]);


    useEffect(() => {
        setDefaultCheckTypeFilter()
    }, [restaurant, textToPayEnabled])

    useEffect(() => {
        let numOfCols = 0
        if (showOrderedColumn) numOfCols++
        if (showArrivedColumn) numOfCols++
        if (showCompletedColumn) numOfCols++
        setNumberOfColumns(numOfCols)
	}, [showCompletedColumn, showOrderedColumn, showArrivedColumn])

    useEffect(()=>{
        if(restaurant && checks){
            setIsLoading(false)
        } else {
            setIsLoading(true)
        }
        console.log('in useEffect - restaurant', restaurant)
        console.log('in useEffect - checks', checks)
        console.log('is loading', isLoading)
    },[restaurant, checks])



	const setcheckTypeFilter = (newValue) => {
        if (newValue) global.set({ checkTypeFilter: newValue }); 
    }
    
    if(isLoading){
        return <div style={{width:'100%', height:'100%'}}><CircularProgress style={{position: 'absolute', top:'50%', left:'50%', height:'100px', width:'100px', marginTop:'-50px', marginLeft:'-50px', opacity:'20%'}}/></div>
    }

	return (
        <Grid container style={{ height:"100%", padding: smallScreen ? 2 : 10}}>


            {showTutorial && global.isCurbly() && 
                <TutorialCurbly />
            }


            {restaurant && <>
                <Box display="flex" style={{ width:'100%', height: smallScreen ? '52px' : '60px', overflow: 'none', padding: 5}}>
                    {showSearch && checks &&
                        <Grid container spacing={1}>
                            <Grid item xs>
                                <TodaySearch/>
                            </Grid>
                        </Grid>
                        
                    }
                </Box>

                <Grid container style={{ width:'100%', maxHeight:'100%', height: 'calc(100% - 50px)', overflow: 'none', }} >

    
                    {(smallScreen || showAll) && <>
                        <Grid item xs={12} container direction='row' style={{ width: '100%', height: '100%', minHeight: '100%', margin: 0, paddingBottom: smallScreen ? 2 : 10, overflow: 'hidden' }}>
                            <Grid item xs={12} style={{ height: '100%', width: '100%', padding: 5}} >
                                <Paper className={classes.paper} style={{overflow: 'hidden', height: '100%', width: '100%'}}>

                                    {showTabs && !todaySearchFilter &&
                                        <Tabs style={{width: '100%', borderBottom: 'solid 1px #DDD'}}
                                            // style={{ maxHeight: '3.5em', minHeight: '2.7em'}}
                                            value={selectedTab}
                                            variant="fullWidth"
                                            indicatorColor="primary"
                                            TabIndicatorProps={{
                                                style: {
                                                    backgroundColor: selectedTab == 'ordered' ? 'var(--dash-orange)' : selectedTab == 'arrived' ? 'var(--dash-purple)' : 'var(--dash-gray)',
                                                    height: 3,
                                                }
                                            }}
                                            onChange={(e, value) =>{
                                                setSelectedTab(value)
                                            }}
                                        >
                                            <Tab label="Ordered" value="ordered" />
                                            <Tab label={<Badge classes={{badge:classes.badge}} color="primary" badgeContent={arrivedChecks.length > 0 ? arrivedChecks.length : null}>Arrived</Badge>} value="arrived" />
                                            <Tab label="Picked Up" value="completed" />
                                        </Tabs>
                                    }

                                    <Grid container className={`${classes.scrolling} ${classes.checks}`}>
                                        {filteredChecks && filteredChecks.filter((rec) => { return selectedTab == "" || rec.status == selectedTab }).map((data, idx) => 
                                            <Grid item xs={12} md={6} lg={4} style={{padding: 0, paddingLeft: 8, paddingRight: 8}} onClick={() => showCheckDetails(data)}>
                                                <DashCardCurbly data={data} hideTopBorder={idx > 0 || smallScreen && idx == 0} />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </>}

                    {!showAll && !smallScreen && <>
                        <Grid item lg={12} md={12} container direction='row' style={{ width: '100%', height: '100%', minHeight: '0', margin: 0, paddingBottom: 5 }}>
                            {showOrderedColumn &&
                                <Grid item md style={{ height: '100%', padding: 5, paddingRight: 10, paddingBottom: 10, paddingTop: showTabs ? 0 : 5}} >
                                    <Paper className={classes.paper} style={{borderTop: 'solid 4px var(--dash-orange)'}}>
                                        <Grid item lg={12} className={classes.iconWrapper} justify="center">
                                            <Typography style={{ paddingTop: 3, fontSize: '1.4em', color:  theme().palette.text.primary }} display="inline" align="center">Ordered</Typography>
                                        </Grid>
                                        <Grid container className={`${classes.scrolling} ${classes.checks}`}>
                                            {orderedChecks && orderedChecks.map((data, idx) => 
                                                <Grid item lg={12} style={{ padding: 0, paddingLeft: 8, paddingRight: 8, width:'100%' }} onClick={() => showCheckDetails(data)}>
                                                    <DashCardCurbly data={data} hideTopBorder={idx > 0} />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Paper>
                                </Grid>
                            }{showArrivedColumn &&
                                <Grid item md style={{ maxHeight: '100%', padding: 5, paddingBottom: 10, paddingTop: showTabs ? 0 : 5 }} >
                                    <Paper className={classes.paper} style={{borderTop: 'solid 4px var(--dash-purple)'}}>
                                        <Grid item lg={12} className={classes.iconWrapper} justify="center">
                                            <Typography style={{ paddingTop: 3, fontSize: '1.4em', color:  theme().palette.text.primary }} display="inline" align="center">Arrived</Typography>
                                        </Grid>
                                        <Grid container className={`${classes.scrolling} ${classes.checks}`} >
                                            {arrivedChecks && arrivedChecks.map((data, idx) => 
                                                <Grid item lg={12} style={{ padding: 0, paddingLeft: 8, paddingRight: 8, width:'100%' }} onClick={() => showCheckDetails(data)}>
                                                    <DashCardCurbly data={data} hideTopBorder={idx > 0}/>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Paper>
                                </Grid>
                            }
                            <Grid item md={showCompletedColumn} style={{ width: !showCompletedColumn ? '65px' : 'inherit', maxHeight: '100%', padding: 5, paddingLeft: 10, paddingBottom: 10, paddingTop: showTabs ? 0 : 5 }} >
                                <Paper className={classes.paper} elevation={showCompletedColumn ? 1 : 3} style={{borderTop: 'solid 4px var(--dash-gray)', cursor: !showCompletedColumn ? 'pointer' : 'inherit'}} onClick={() => { if (!showCompletedColumn) setshowCompletedColumn(!showCompletedColumn) }  }>
                                    <Grid item lg={12} container alignItems="center" className={classes.columnHeader}>
                                        {showCompletedColumn && 
                                            <Grid item style={{width: 50}}></Grid>
                                        }
                                        {showCompletedColumn && 
                                            <Grid item xs container justify="center" alignItems="center" style={{cursor: 'pointer'}} onClick={() => setshowCompletedColumn(!showCompletedColumn)}>
                                                <Typography style={{ paddingTop: 3, fontSize: '1.4em', color:  theme().palette.text.primary }} display="inline">Picked Up</Typography>
                                            </Grid>
                                        }
                                        <Grid item style={{width: 50}} container alignItems="center">
                                            <IconButton onClick={() => setshowCompletedColumn(!showCompletedColumn)}>
                                                {showCompletedColumn && 
                                                    <ChevronRightIcon/>
                                                }
                                                {!showCompletedColumn && 
                                                    <ChevronLeftIcon/>
                                                }
                                            </IconButton>
                                        </Grid>
                                        
                                        
                                    </Grid>
                                    
                                    {!showCompletedColumn && 
                                        <Grid xs container style={{height: 'calc(100% - 48px)'}} justify="center" alignItems="center">
                                            <Typography style={{ paddingTop: 3, fontSize: '1.4em', color:  theme().palette.text.primary, transform: 'rotate(-90deg)', height: 35, width: '100%', whiteSpace: 'nowrap' }}>Picked Up</Typography>
                                        </Grid>
                                    }

                                    {showCompletedColumn && 
                                        <Grid container className={`${classes.scrolling} ${classes.checks}`} >
                                            {completedChecks && completedChecks.map((data, idx) =>
                                                <Grid item lg={12} style={{ padding: 0, paddingLeft: 8, paddingRight: 8, width:'100%', overflow: 'hidden' }} onClick={() => showCheckDetails(data)}>
                                                    <DashCardCurbly data={data} hideTopBorder={idx > 0} slideDirection='left' />
                                                </Grid>
                                            )}
                                        </Grid>
                                    }

                                </Paper>
                            </Grid>

                        </Grid>
                    </>}
                    <Grid item lg={12} >
                        {dialogContent}
                    </Grid>
                </Grid>
            </>
            }
        </Grid>
	);
};