import React, { useState, useEffect } from 'react';
import { Grid, Dialog, DialogActions, Button, Typography, Checkbox, Table, TableRow, TableCell, TableBody, TableHead } from '@material-ui/core';
import log from 'loglevel'
import moment from 'moment'
import DashCard from './DashCard';
import theme from '../../theme'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import alertCustomerForOrderUpApi from '../../actions/alertCustomerForOrderUpApi';
import reprintPaymentReceiptApi from '../../actions/reprintPaymentReceiptApi';
import RefundRequestWindow from "./RefundRequestWindow";

const styles = {
	infoBanner: {
		padding: 5,
		paddingTop: 15
	},
	paper: {
		padding: 10,
		width: '100%',
		height: '100%'
	},
	table: {
	}
}


export default function (props) {
	// alert(JSON.stringify(props.permissions))
	const [check, setCheck] = useState(props.check)
	const [showPaymentDetail, setShowPaymentDetail] = useState(false)
	const [dialogContent, setDialogContent] = useState()
	const isCallAheadCheck = (check.pickupDetails && check.pickupDetails.phoneNumber)
	const isPickedUp = (check.pickupDetails && check.pickupDetails.pickedUpAt)
	let tmpPayment
	let tipTotal = 0
	let loyaltyTotal = 0
	let taxesTotal = 0
	let dashTotal = 0
	let lastPaymentTime
	if (check.payments) {
		check.payments.filter(p => (p.fromDash && p.successful)).forEach(payment => {
			loyaltyTotal += payment.loyaltyRewardsAmount
			taxesTotal += payment.tax
			tipTotal += payment.tipAmount
			dashTotal += payment.paymentAmount
		})
		tmpPayment = check.payments.sort((a, b) => moment(a.createdAt) - moment(b.createdAt))[0]
		if (tmpPayment) {
			lastPaymentTime = tmpPayment.createdAt
		}
		loyaltyTotal = Number(loyaltyTotal/100).toFixed(2)
		taxesTotal = Number(taxesTotal/100).toFixed(2)
		tipTotal = Number(tipTotal/100).toFixed(2)
		dashTotal = Number(dashTotal/100).toFixed(2)
	}


	let paidAt = lastPaymentTime ? moment(lastPaymentTime).fromNow() : 'N/A'
	
	const smallScreen = useMediaQuery(theme().breakpoints.down('sm'));
	console.log("smallScreen: " + smallScreen)

	const closeContent = () => {
		setDialogContent(null)
	}

	const reprintPaymentReceipt = async (check) => {
		let data = await reprintPaymentReceiptApi(check)
		if (data) {
			props.close()
		}
	}

	useEffect(() => {
	}, [])

	const openRefundWindow = (payment) => {
		setDialogContent(
			<RefundRequestWindow check={check} payment={payment} close={closeContent} />
		)
	}




	return (
		<Dialog
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={true}
			onClose={ props.close }
			PaperProps={smallScreen ? {style:{margin: 0, width: '100%'}} : {style: {minWidth: '90%'}}}
		>
			<DialogActions style={{padding: 5 }}>
				<Grid container xs={12} style={{ borderBottom: '1px solid gray', marginBottom: 12 }}>
					<Grid container item xs={10} alignContent="space-around" >
						<Typography variant="h5"> Check: {check._id}</Typography>
					</Grid>
					<Grid item xs={2}>
						<Button style={{ padding: '10px 0px', float: 'right', fontSize: '1.2em' }} onClick={props.close}>
							X
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
			<Grid container style={{ width: '100%', padding: smallScreen ? '0px 10px' : '0px 20px' }}>
				{check && 
					<Grid item lg={4} style={{ width: '100%', overflow: 'scroll' }}>
						<Grid item style={{ width: '100%' }} lg={12} onClick={() => setShowPaymentDetail(!showPaymentDetail)}>
							<DashCard paymentDetail={true} showPaymentDetail={showPaymentDetail} data={check}/>
						</Grid>
						<Table>
							{tipTotal > 0 &&
								<TableRow>
									<TableCell>
										<Typography>Tip:</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography variant="h6">${tipTotal}</Typography>
									</TableCell>
								</TableRow>
							}
							{loyaltyTotal > 0 &&
								<TableRow>
									<TableCell>
										<Typography>Loyalty Amount:</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography variant="h5">${loyaltyTotal}</Typography>
									</TableCell>
								</TableRow>
							}
							{dashTotal > 0 &&
								<TableRow>
									<TableCell>
										<Typography>Total Paid:</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography variant="h4">${dashTotal}</Typography>
										<Typography variant="caption">{paidAt}</Typography>
									</TableCell>
								</TableRow>
							}
						</Table>
					</Grid>
				}
				
				<Grid item lg={8} style={{ width: '100%', overflow: 'scroll', padding:10}}>
					<Typography variant="h4">Payments</Typography>
					{check && check.payments && check.payments.length > 0 &&
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>ID</TableCell>
									<TableCell align="left">Amount</TableCell>
									<TableCell align="left">Refund Amount</TableCell>
									<TableCell align="left">Status</TableCell>
									<TableCell align="left">Payment Method</TableCell>
									<TableCell align="left">Paid By</TableCell>
									<TableCell align="left">Paid At</TableCell>
									<TableCell align="left">From Dash</TableCell>
									<TableCell align="left"></TableCell>
								</TableRow>
							</TableHead>
							{check.payments.map((payment) => {
									return (
										<TableRow>
											<TableCell>
												{payment._id}
											</TableCell>
											<TableCell>
												${Number(((payment.paymentAmount) / 100).toFixed(2))}
											</TableCell>
											<TableCell>
												${Number(((payment.refundAmount) / 100).toFixed(2))}
											</TableCell>
											<TableCell>
												{payment.status}
											</TableCell>
											<TableCell>
												{payment.paymentMethod}
											</TableCell>
											<TableCell>
												{payment.paidBy}
											</TableCell>
											<TableCell>
												{moment(payment.paidAt).fromNow()}
											</TableCell>
											<TableCell>
												<Checkbox checked={payment.fromDash}/>
											</TableCell>
											<TableCell>
												{dashTotal > 0 &&
													<Button onClick={() => openRefundWindow(payment)} variant="contained">Refund</Button>
												}
											</TableCell>
										</TableRow>
									)
								})
							}
						</Table>
					}{check.payments.length == 0 &&
						<center>
							<Typography variant="h6">No payments found</Typography>
						</center>
					}
				</Grid>
			</Grid>
			<DialogActions style={{borderTop: '1px solid var(--divider)', padding: '10px 15px' }}>
				<Grid container xs={12}>
					{isCallAheadCheck &&
						<Grid item xs={12}>
							{dashTotal > 0 && 
								<Button style={{ height: '100%', paddingLeft: '12px', float: 'left' }} color="primary" variant="contained" onClick={() => { reprintPaymentReceipt(check); }}>
									Reprint Payment Slip
								</Button>
							}
						</Grid>
					}
				</Grid>
			</DialogActions>
			<Grid item lg={12} >
				{dialogContent}
			</Grid>
		</Dialog>
	)

}
