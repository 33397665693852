import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, Paper, Drawer, Zoom } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import theme from '../theme'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import log from 'loglevel'
import history from '../history.js'
import _ from 'lodash';

import { withRouter } from 'react-router-dom';

import FeedbackCard from './components/FeedbackCard'
import CheckDetails from "./components/CheckDetails";
import getFeedbackDataApi from '../actions/getFeedbackDataApi';

import RateReviewIcon from '@material-ui/icons/RateReview';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import Layout from '../layouts/Layout.js';
import Login from '../admin/pages/login';
import TodaySearch from './components/TodaySearch';

import moment from 'moment'
import Sound from 'react-sound';

const styles = (theme) => ({
    root: {
    },
    scrolling: {
        overflow: 'auto',
        height: 'auto'
    },
    checks: {
        paddingLeft: 5,
        paddingRight: 5,
        maxHeight: 'calc(100% - 3em)',
    },
    paper: {
        // paddingRight:8,
        // paddingleft:8,
        // margin: 8,
        // minHeight: '100%',
        // maxHeight: '100%',
        width:'100%',
        height:'100%'
    },
    iconWrapper: {
        height: '2.7em', 
        display: 'flex',
        alignItems: 'center' 
    }
})


const Today = (props) => {
    let { classes } = props;
    let requireToken = !!props.requireToken
    let [restaurant] = global.get('restaurant')
    // let [restaurantId] = global.get('restaurantId')
    let [checks, setChecks] = global.get('checks')
    // let [openCallAheadChecks] = global.get('openCallAheadChecks')
    let [filteredChecks, setFilteredChecks] = React.useState([])
    let [drawerOpen, setDrawerOpen] = React.useState(false)
	let [dialogContent, setDialogContent] = useState()

	let [feedbackData] = global.get('feedbackData')

    let smallScreen = useMediaQuery(theme().breakpoints.down('sm'));
    let [textToPayEnabled] = global.get('textToPayEnabled')
    let [imHereEnabled] = global.get('imHereEnabled')
    let [orderUpEnabled] = global.get('orderUpEnabled')
    let [newCustomerArrival] = global.get('newCustomerArrival', false)
    const isMobile = useMediaQuery('(max-width:500px)')

    let showSearch = true


    const closeContent = () => {
        setDialogContent(null)
    }

    const showCheckDetails = (check) => {
        setDialogContent(
            <CheckDetails check={check} close={closeContent} />
        )
	}
	
	const fetchData = async () => {
		if (restaurant) {
			await getFeedbackDataApi(restaurant._id)
		}
	}
 
    useEffect(() => {
		fetchData()
	}, [restaurant]);
	
	useEffect(() => {
		fetchData()
	}, []);

	return (
        <>
			{feedbackData && feedbackData.length ? 
                <>
                    {/* <Box display="flex" style={{ width:'100%', height: '60px', overflow: 'none', padding: 5}}>
                        {showSearch && 
                            <Box display="flex" style={{ flexGrow: 1}}>
                                <Box style={{ flexGrow: 1}}>
                                    <TodaySearch />
                                </Box>
                            </Box>
                        }
                        {!todaySearchFilter && restaurant.backOffice &&
                            <Box style={{marginLeft: 15}}>
                                <Paper>
                                    <ToggleButtonGroup exclusive size="small" value={checkTypeFilter} onChange={(e, value) => {if (value) global.set({checkTypeFilter: value}) }} style={{height: 44}}>
                                        <ToggleButton key={0} value={'all'} style={{height: '100%', paddingLeft: 20, paddingRight: 20, borderTop: 'none', borderBottom: 'none', borderLeft: 'none'}}>All</ToggleButton>
                                        <ToggleButton key={1} value={'dineIn'} style={{height: '100%', paddingLeft: 20, paddingRight: 20, borderTop: 'none', borderBottom: 'none'}}>Dine-In</ToggleButton>
                                        <ToggleButton key={2} value={'toGo'} style={{height: '100%', paddingLeft: 20, paddingRight: 20, borderTop: 'none', borderBottom: 'none', borderRight: 'none'}}>To-Go</ToggleButton>
                                    </ToggleButtonGroup>
                                </Paper>
                            </Box>
                        }
                    </Box> */}
                    <div style={{padding: '0px 10px 5px 10px'}}>
                        <Grid container style={{ width:'100%', maxHeight:'100%', overflow: 'auto'}} >
                                    <Grid container className={`${smallScreen ? '' : classes.scrolling} ${classes.checks}`} style={{paddingTop: 10}}>
                                        {feedbackData && feedbackData.map(review => {
                                            return (<>
                                                    <Grid item xs={12} md={12} lg={12} style={{ padding: 8, height: 'min-content' }} onClick={() => showCheckDetails(review.check)}>
                                                        <FeedbackCard data={review}/>
                                                    </Grid>
                                                </>
                                            )
                                        })}
                                    </Grid>
                            <Grid item lg={12} >
                                {dialogContent}
                            </Grid>
                        </Grid>
                    </div>
                </>
            :
                // EMPTY STATE
                <Grid container justify="center" alignItems="center" style={{height:'100%', width:'100%'}}>
                    <Grid item style={{textAlign:'center', marginTop: isMobile ? '-120px' : 0}}>
                        <RateReviewIcon style={{fontSize:isMobile? '180px' : '300px', opacity:'10%'}}/>
                        <Typography variant="h5" style={{opacity: '15%'}}>Awaiting reviews</Typography>
                    </Grid>
                </Grid>
            }
       </> 
	);
};

Today.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withRouter(withStyles(styles, { withTheme: true })(Today
));