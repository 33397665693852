import React, { useState, useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import log from 'loglevel';
import _ from 'lodash';
import {
	Grid,
	Typography,
	Button,
	Tabs,
	Tab,
	MenuItem,
	FormControlLabel,
	FormControl,
	TableBody,
	InputLabel,
	Popover,
	Modal,
	TextField,
	Input,
	Switch,
	Slider,
	Divider,
	LinearProgress,
	IconButton,
	Tooltip,
	Box,
	TableContainer,
	useMediaQuery,
	Collapse,
	CircularProgress,
	Dialog,
	DialogContent,
} from '@material-ui/core';
import {
	Build,
	Visibility,
	AddCircleOutline,
	Delete,
	FileCopy,
	CheckCircleOutline,
	ErrorOutline
} from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import Select from 'react-select';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { NotificationManager } from 'react-notifications';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SelectMui from '@material-ui/core/Select';
import MuiTreeView from '../../layouts/components/MuiTreeView';
import DChart from '../../components/dash/DChart';
import DForm from '../../components/dash/DForm';
import DSwitch from '../../components/dash/DSwitch';
import DSubmitButton from '../../components/dash/DSubmitButton';
import DFlag from '../../components/dash/DFlag';
import DTextField from '../../components/dash/DTextField';
import RestaurantInfo from '../../dashboard/setup/restaurantInfo';
import dashFetch from '../../utils/dashFetch';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import history from '../../history'
import testTransactionOnGatewayApi from '../../actions/testTransactionOnGatewayApi'
import testPosServerConnectionApi from '../../actions/testPosServerConnectionApi'
import downloadRestaurantGroupExport from '../../actions/downloadRestaurantGroupExportApi'
import requestLogsApi from '../../actions/requestLogsApi'
import verifyGoLiveApi from '../../actions/verifyGoLiveApi'
import forceUpdateApi from '../../actions/forceUpdateApi'
import moment from 'moment-timezone';
import { useLocation } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import SmsIcon from '@material-ui/icons/Sms';
import SettingsIcon from '@material-ui/icons/Settings';
import StoreIcon from '@material-ui/icons/StoreMallDirectory';
import TelegramIcon from '@material-ui/icons/Telegram';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import PrintIcon from '@material-ui/icons/Print';
import PersonIcon from '@material-ui/icons/Person';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PaletteIcon from '@material-ui/icons/Palette';
import GetAppIcon from '@material-ui/icons/GetApp';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Support from '../../admin/pages/support';
import theme from '../../theme';
import updateRemoteDesktopApi from '../../actions/updateRemoteDesktopApi';
import stationRestartApi from '../../actions/stationRestartApi';
import sendInStationSettingApi from '../../actions/sendInStationSettingApi';
import fetchDBFilesApi from '../../actions/fetchDBFilesApi';
import fetchMicrosISLFilesApi from '../../actions/fetchMicrosISLFilesApi';
import fetchDBQueryApi from '../../actions/fetchDBQueryApi';
import deleteLocalSqlDBApi from '../../actions/deleteLocalSqlDBApi';
import forceRegisterStationApi from '../../actions/forceRegisterStationApi';
import ChartsIcon from '@material-ui/icons/BarChart';
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { ObjectID } from 'bson';
import uploadBulkRestaurantAddApi from '../../actions/uploadBulkRestaurantAdd';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Branding from '../../dashboard/setup/branding';
import SiteConfigForm from '../../dashboard/siteConfigForm';
import getLastConnectedAtApi from '../../actions/getLastConnectedAtApi';
import StreamLogsDialog from '../components/StreamLogsDialog';
import { fetchApiLastConnectedAtInterval, maxTestApiConnectionTime } from '../../config';
import attachRealmIdApi from '../../actions/attachRealmIdApi';
const uuidv1 = require('uuid/v1');

const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		height: '100%',
		width: '100%',
	},
	paper: {
		position: 'absolute',
		width: 400,
		backgroundColor: theme.palette.background.paper,
		boxShadow: ["none"],
	},
	tabs: {
		background: 'var(--background)',
		width: 60,
		borderRight: 'solid 1px #CCC',
		height: '100%',
	},
	addRestaurantIcon: {
		// position: 'relative',
		float: 'left',
		// top: theme.spacing.unit / 3,
		marginTop: 5,
		marginRight: 5,
		width: '20px',
		height: '20px'
	},
	buttonIcon: {
		padding:3,
		paddingLeft:0,
		paddingRight:5,
	},
	searchBarWrapper: {
		display: 'flex'
		// backgroundColor: 'gray'
	},
	restaurantForm: {
		// marginTop: '5.0%',
		// marginLeft: '2%',
		// marginRight: '2%',
	},
	searchBar: {
		width: '80%',
		'&$selected': {
			backgroundColor: '#5220A8'
		}
	},
	addRestaurantButton: {
		marginLeft: 20,
		marginTop: 15,
		width: 200,
		height: 45
	},
	restaurantFieldInputItem: {
		width: '100%',
		marginTop: '0px'
	},

	testGatewayButton: {
		width: 270,
		marginBottom: 5
	},

	fullWidthInput: {
		width: '100%',
		marginBottom:'15px'
	},
	divider: {
		margin:5
	},
	brandingInputs: {
		// width:'90% !important',
		// marginLeft: 30,
	},
	muiSelect: {
		marginBottom: '15px',
	},
	submitButton: {
		[theme.breakpoints.up('md')]: {
			position: 'fixed',
			bottom: 60,
			right: '50%',
			left: '50%',
			marginLeft: '-75px',
			marginTop: 0
		},
		[theme.breakpoints.up('xs')]: {
			marginTop: 20
		},
	},
	rootTable: {
		margin: 30
	},
	stationSettings: {
		margin: 10,
		padding: 10,
	},
	table: {
		width: '100%',
		minWidth: 650
	},
	locationInfoInput: {
		width: '45%'
	},
	subheadingText: {
		fontSize: '16px'
	},
	paymentGatewaySelect: {
		width: '80%',
		marginBottom: '15px'
	},
	addButton: {
		// backgroundColor: '#5220A8'
		margin: 10,
		minWidth: 28,
		backgroundColor: global.primaryColor,
		borderRadius: 15,
		padding: 2,
	},
	indicator: {
		backgroundColor: '#5220A8'
	},
	header: {
		width: '100%', 
		height: '110px', 
		overflow: 'hidden', 
		borderBottom: 'solid 1px #CCC', 
		background: 'var(--background)',
		overflowX: 'hidden', 
		overflowY: 'hidden',
	},
	headerLabel: {
		color: 'var(--dark-gray)', 
		fontSize: '1.2em',
		float: 'left',
		display: 'block',
	},
});

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: '75px',
		height: '75px',
		paddingLeft: '10px',
	}),
	indicatorsContainer: (provided) => ({
		...provided,
		height: '75px',
		paddingLeft: '10px',
	}),
	menuList: (base) => ({
		...base,
		minHeight: 'fit-content'
	}),
	clearIndicator: (provided) => ({
		...provided,
		padding: '5px'
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		padding: '5px'
	}),
	valueContainer: (provided) => ({
		height: '75px',
		paddingLeft: '10px',
	})
};

function camelize(str) {
	return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
		return index == 0 ? word.toLowerCase() : word.toUpperCase();
	}).replace(/\s+/g, '');
}


const Dash = (props) => {
	const isMobile = useMediaQuery(theme().breakpoints.down('xs'));
    const isTablet = useMediaQuery('@media only screen and (max-device-height : 768px) and (max-device-width : 1024px)');

	// Removes warnings from console
	const GENERAL_TAB = 0;
	const GATEWAY_TAB = 1;
	const STATIONS_TAB = 2;
	const CONFIG_TAB = 3;
	const SMS_TAB = 4;
	const BRANDING_TAB = 5;
	const SUPPORT_TAB = 6;
	const LOYALTY_TAB = 7;
	const CHARTS_TAB = 8;
	
	let smallScreen = useMediaQuery(theme().breakpoints.down('sm'));
	const [loading, setLoading] = useState("loading", true);
	const [activeTab, setActiveTab] = useState(GENERAL_TAB);
	const [restaurant, setRestaurant] = global.get('restaurant')
	const [restaurants] = global.get('restaurants')
	let [isNew, setIsNew] = global.get('isNew')
	let [hasChanges, setHasChanges] = global.get('hasChanges')
	let [editing, setEditing] = global.get('editing')
	if (editing == null) {
		// editing = true
	}

	const { classes } = props;
	const [spreedlyGatewayOptions, setSpreedlyGatewayOptions] = useState([]);
	// const [selectedGateway, setSelectedGateway] = useState({});
	const [restaurantGroups, setRestaurantGroups] = useState([]); // list of available groups
	const [loyaltyPrograms, setLoyaltyPrograms] = useState([]); // list of available groups
	const [posTypes, setPosTypes] = useState([]);
	const [gatewayTypes, setGatewayTypes] = useState([]);
	const [blastMessage, setBlastMessage] = useState('[firstName] [lastName]');
	const [imageUrl, setImageUrl] = useState('');
	const [testNumber, setTestNumber] = useState('');
	const [messageTest, setMessageTest] = useState(true);
	const [testTransactionProcessedSuccessfully, setTestTransactionProcessedSuccessfully] = useState(false);
	const [contest, setContest] = useState(true);
	const [masterGateways, setMasterGateways] = useState([]);
	const [restaurantGateways, setRestaurantGateways] = useState([]);
	const [agents, setAgents] = useState([]);
	const [agencies, setAgencies] = useState([])
	const [restaurantAccounts, setRestaurantAccounts] = useState([]);
	const [restaurantGroup, setRestaurantGroup] = useState({}) //for adding a new restGroup
	const [apiSite, setApiSite] = useState({}) //for adding a new apiSite
	const [restaurantWebsite, setRestaurantWebsite] = useState({})
	const [loyaltyProgram, setLoyaltyProgram] = useState({})
	const [selectedStation, setSelectedStation] = useState()
    const [stations, setStations] = useState([]);
    const [stationsFiltered, setStationsFiltered] = useState([]);
    const [stationsSetuped, setStationsSetuped] = useState([]);
    const [previousSelectedStation, setPreviousSelectedStation] = useState(false);
    const [showAllStations, setShowAllStations] = useState(false);
    const [slowSelectedStationId, setSlowSelectedStationId] = useState("");
	const [stationSettings, setStationSettings] = useState({isSetup:false, key:'', value:''})
	const [dbQueryParams, setDbQueryParams] = useState({query:'', transactionId:'', checkNumber:'', posTicketId:'', dateString:''})
	let [uploading, setUploading] = useState(false)
	let [file, setFile] = useState(null)
	const [dialogContent, setDialogContent] = useState()
	const [duplicateCurrent, setDuplicateCurrent] = useState(false)
	const [apiLastConnectedAt, setApiLastConnectedAt] = useState(null)
	const [testApiConnectionAt, setTestApiConnectionAt] = useState(null)
	const [testApiConnectionFailed, setTestApiConnectionFailed] = useState(false)
	const [realmId, setRealmId] = useState(null)
	const [unassociatedRealmIds, setUnassociatedRealmIds] = useState([])


	let utctimezones = moment.tz.zonesForCountry('US',true);
	utctimezones = [...utctimezones, moment.tz.zonesForCountry('CA',true)]
	utctimezones = utctimezones.sort((a, b) => a.offset > b.offset ? 1 : -1)

	const upload = async () => {
		setUploading(true)
		// console.log("---upload")
        await uploadBulkRestaurantAddApi(file)
        setUploading(false)
	}

	
	let logos = {
		logo: '',
		headerLogo: '',
		splashLogo: '',
		menuUrl: '',
	}

	const [restaurant2, setRestaurant2] = useState({});

	async function addRestaurant() {
		let restaurantObj = await newSchema('Restaurant');
		if (global.hasPermission('admin.restaurant.gateway')) { //add default gateway
			let gateway = await newSchema('Gateway');
			gateway.restaurant = restaurantObj._id
			let restGateways = []
			restGateways.push(gateway)
			restaurantObj.gatewayIds.push(gateway._id);
			if (global.hasPermission('admin.')) {
				setRestaurantGateways(restGateways)
				
			}
			addObjectToSave(gateway);
			addObjectToSave(restaurantObj);
			let restaurantWebsite = await newSchema('RestaurantWebsite')
			setRestaurantWebsite(restaurantWebsite);
		}
		if (duplicateCurrent) {
			setDuplicateCurrent(false)
			// We copied exactly 50% of the properties
			// so it wouldn't be shorter if I copy everything and delete those we dont want
			restaurantObj.name = restaurant2.name + ' - copy'
			restaurantObj.businessType = restaurant2.businessType
			restaurantObj.reportCutoffTime = restaurant2.reportCutoffTime
			restaurantObj.notes = restaurant2.notes ? `${moment().format('MM/DD/YYYY hh:mm:ss')} - Duplicated from ${restaurant2.name} by \${username_here} \nCopied notes:\n${restaurant2.notes}` : ''
			restaurantObj.status = "enrolled"
			restaurantObj.restaurantGroup = restaurant2.restaurantGroup
			restaurantObj.posType = restaurant2.posType
			restaurantObj.posTypeOther = restaurant2.posTypeOther
			restaurantObj.agent = restaurant2.agent
			restaurantObj.agency = restaurant2.agency
			restaurantObj.salesRep = restaurant2.salesRep
			restaurantObj.appName = restaurant2.appName
			restaurantObj.whiteLabelCode = restaurant2.whiteLabelCode
			restaurantObj.loyaltyProgram = restaurant2.loyaltyProgram
			restaurantObj.currency = restaurant2.currency
			restaurantObj.backOffice = restaurant2.backOffice
			restaurantObj.restaurantEnabled = restaurant2.restaurantEnabled
			restaurantObj.qrCodeEnabled = restaurant2.qrCodeEnabled
			restaurantObj.printQrCodeImage = restaurant2.printQrCodeImage
			restaurantObj.forcePrintQrCodeESCPOS = restaurant2.forcePrintQrCodeESCPOS
			restaurantObj.forcePrintQrCodeESCIQ = restaurant2.forcePrintQrCodeESCIQ
			restaurantObj.forcePrintQrCodeESCGSY = restaurant2.forcePrintQrCodeESCGSY
			restaurantObj.bypassAll = restaurant2.bypassAll
			restaurantObj.debugPrinting = restaurant2.debugPrinting
			restaurantObj.dashNowBackOfficeEnabled = restaurant2.dashNowBackOfficeEnabled
			restaurantObj.printEarlyTable = restaurant2.printEarlyTable
			restaurantObj.hideDashLines = restaurant2.hideDashLines
			restaurantObj.moveQrAboveThankYou = restaurant2.moveQrAboveThankYou
			restaurantObj.allowStackingDiscounts = restaurant2.allowStackingDiscounts
			restaurantObj.config = JSON.parse(JSON.stringify(restaurant2.config))
			restaurantObj.tosAcceptance = restaurant2.tosAcceptance
			restaurantObj.branding = JSON.parse(JSON.stringify(restaurant2.branding))
			restaurantObj.defaultStationSettings = restaurant2.defaultStationSettings
			restaurantObj.restaurantWebsite = restaurant2.restaurantWebsite
			restaurantObj.hasDashServiceFee = restaurant2.hasDashServiceFee
			restaurantObj.dashServiceFeeAmount = restaurant2.dashServiceFeeAmount
			restaurantObj.dashboardAutoFetchInterval = restaurant2.dashboardAutoFetchInterval
			restaurantObj.dashboardAutoFetchInterval = restaurant2.dashboardAutoFetchInterval
		}
		setRestaurant2(restaurantObj);
		fetchdata()
	}

	async function editRestaurant(restaurantId) {

		if (restaurantId) {
			history.replace(`/admin/restaurants/addEditRestaurant/${restaurantId}`)
		}
		
		let resp = await dashFetch(
			`${global.dashServerUrl}/dashboard/api/v1/dashboard/getRestaurantDetails`, { id: restaurantId },
			true
		);
		if (!resp || !resp.json || !resp.json.restaurant) {
			NotificationManager.warning('', 'Theres no site with that ID!', 3000);
			
			history.push(`/restaurantListing/`)
			return;
		} else {
			let restaurantObj = resp.json.restaurant

			if (global.hasPermission('admin.')) {
				let restGateways = resp.json.restaurantGateways
				if (!restGateways || restGateways.length == 0) {
					let gateway = await newSchema('Gateway')
					gateway.restaurant = restaurantObj._id
					restGateways.push(gateway)
					restaurantObj.gatewayIds.push(gateway._id);
					addObjectToSave(gateway);
				}
				setRestaurantGateways(restGateways)
			}
			let restaurantWebsite = resp.json.restaurantWebsite ? resp.json.restaurantWebsite : await newSchema('RestaurantWebsite')
			setRestaurantWebsite(restaurantWebsite);
            let stationList = resp.json.restaurantStations
            console.log('stationList', stationList)
			stationList.unshift({_id:'All', stationName:'All'})
			setStations(stationList.sortByProperty('stationName'));
			setLoyaltyProgram(resp.json.loyaltyProgram);
			if (restaurantObj.apiSite) {
				setApiSite(restaurantObj.apiSite)
			}
			setRestaurant2(restaurantObj);
			addObjectToSave(restaurantObj);
			setHasChanges(false)
		}
		fetchdata()
	}

	async function fetchdata() {
		let data = await dashFetch(`${global.dashServerUrl}/dashboard/api/v1/dashboard/getDropdownList`, {}, true);
		if (data?.json) {
			// log.info('TYLER ' + data?.json?.loyaltyProgram)
			setSpreedlyGatewayOptions(data?.json?.spreedlyGatewayTypes?.sort((a, b) => (a?.name?.toLowerCase() > b?.name?.toLowerCase()) ? 1 : -1));
			// setSelectedGateway(data?.json?.spreedlyGatewayTypes[0]);
			setRestaurantGroups(data?.json?.restaurantGroups ? data?.json?.restaurantGroups?.filter((a) => a?.name)?.sort((a, b) => (a?.name && b?.name && a?.name?.toLowerCase() > b?.name?.toLowerCase()) ? 1 : -1) : []);
			setLoyaltyPrograms(data?.json?.loyaltyPrograms ? data?.json?.loyaltyPrograms?.sort((a, b) => (a?.name?.toLowerCase() > b?.name?.toLowerCase()) ? 1 : -1) : []);
			setPosTypes(data?.json?.posTypes?.sort((a, b) => (a?.posType?.toLowerCase() > b?.posType?.toLowerCase()) ? 1 : -1));
			let sortedGatewayTypes = data?.json?.gatewayTypes?.sort((a, b) => (a?.name?.toLowerCase() > b?.name?.toLowerCase()) ? 1 : -1)
			setAgents(data?.json?.agents?.sort((a, b) => (a?.firstName?.toLowerCase() > b?.firstName?.toLowerCase()) ? 1 : -1))
			data?.json?.agencies && setAgencies(data?.json?.agencies?.sort((a, b) => (a?.name?.toLowerCase() > b?.name?.toLowerCase()) ? 1 : -1))
			setRestaurantAccounts(data?.json?.restaurantAccounts?.sort((a, b) => (a?.firstName?.toLowerCase() > b?.firstName?.toLowerCase()) ? 1 : -1))
			data?.json?.unassociatedRealmIds && setUnassociatedRealmIds(data?.json?.unassociatedRealmIds?.sort((a, b) => (a?.data?.companyName?.toLowerCase() ?? '' > b?.data?.companyName?.toLowerCase() ?? '') ? 1 : -1))
			if (global.hasPermission('admin.restaurant.gateway')) {
				setGatewayTypes(sortedGatewayTypes);
				log.info(data?.json?.masterGateways)
				setMasterGateways(data?.json?.masterGateways?.sort((a, b) => (a?.gatewayType && b?.gatewayType && a?.gatewayType?.name?.toLowerCase() > b?.gatewayType?.name?.toLowerCase()) ? 1 : -1));
			}
			// setDefaultPaymentMethods(sortedGatewayTypes)// not working yet
		}
		
		if (!restaurant2) setLoading(false)
	}

	async function fullPageScroll() {
		if(isMobile){
			var root = document.getElementById("root")
			if(root){
				var outerlayout = root.children[0]
				if(outerlayout){
					outerlayout.style.setProperty("height", "auto");
				}
			}
			document.getElementById("bodylayout").style.overflow = "initial"
		}
	}

	const attachRealmId = async () => {
		let data = await attachRealmIdApi(restaurant2._id, realmId._id)
		if (data.posConfig) handleApiSiteStateUpdate(null, 'posConfig', data.posConfig)
	}

	const fetchLastConnectedAt = async () => {
		let data = await getLastConnectedAtApi(restaurant2.code)
		setApiLastConnectedAt(data)
		return data
	}

	const testApiConnection = () => {
		if (!testApiConnectionAt || (apiLastConnectedAt && testApiConnectionAt.isBefore(apiLastConnectedAt)) || testApiConnectionFailed) {
			setTestApiConnectionFailed(false)
			setTestApiConnectionAt(moment())
		}
	}

	useEffect(() => {
		if (testApiConnectionAt) {
			let intervalId
			intervalId = setInterval(async () => {
				let t = await fetchLastConnectedAt()
				if (t && testApiConnectionAt.isBefore(t)) {
					clearInterval(intervalId)
				} else if (testApiConnectionAt.isBefore(moment().subtract(maxTestApiConnectionTime, 'seconds'))) {
					setTestApiConnectionFailed(true)
					clearInterval(intervalId)
				}
			}, fetchApiLastConnectedAtInterval * 1000)
		}
	}, [testApiConnectionAt])

	useEffect(() => {
		setApiLastConnectedAt(null)
		setTestApiConnectionAt(null)
		setTestApiConnectionFailed(false)
		if (restaurant2.apiSite && restaurant2.code) fetchLastConnectedAt()	
	}, [restaurant2.code])

	useEffect(() => {
		setLoading(true)
		let restaurantId = history.location.pathname ? history.location.pathname.split('/')[4] : ''
		if (restaurantId == 'new') {
			setIsNew(true)
			setEditing(true)
			setRestaurant(null)
			addRestaurant()
		}
	}, [])
	
	useEffect(() => {
		let restaurantId = restaurant ? restaurant._id : null
		let restaurantIdParam = history.location.pathname ? history.location.pathname.split('/')[4] : ''
		if (restaurant2._id != restaurantId) {
			setLoading(true)
			if (restaurantIdParam == 'new') {
				setIsNew(true)
				setEditing(true)
				setRestaurant(null)
				addRestaurant()
			} else {
				editRestaurant(restaurantId)
			}
		}
		fullPageScroll()
	}, [restaurant])

	useEffect(() => {
		if (restaurant2._id) {
			setLoading(false)
		}
	}, [restaurant2])
	
	useEffect(() => { //SETS ageny based on agent
		if (restaurant2.agent && agents && agents.length) {
			let val = restaurant2.agent
			let agent = agents.find(a=> a._id == val) 
			let agency = agent && agent.getVal('info.agency', null)
			handleRestaurantStateUpdate(null, 'agency', agency);
		}
	}, [restaurant2.agent])

	useEffect(() => {
		if (editing && restaurant2.posType && !restaurant2.apiSite) {
			setupApiSiteIfCloudBasedPosType()
		}
	}, [editing, restaurant2.posType])

	useEffect(() => {
		if (restaurantGateways[0]) {
			setTestTransactionProcessedSuccessfully(restaurantGateways[0].testTransactionProcessedSuccessfully)
		}
	}, [restaurantGateways])
	
	useEffect(() => {
		if (!selectedStation && stations.length > 0) {
			let allStation = stations.find(s => s._id == 'All')
			setSelectedStation(allStation)
		}
		filterStations()
	}, [stations])

	useEffect(() => {
		if (stations) {
			filterStations()
		}
	}, [showAllStations])

	const filterStations = () => {
		setStationsSetuped(stations.filter(s => s._id == 'All' || s.isSetup))
		setStationsFiltered(stations.filter(s => showAllStations ? true : (s._id == "All" || s.isSetup)))
	}
	
	useEffect(() => {
		if (selectedStation) {
			setStationSettings({...stationSettings, isSetup: (selectedStation._id == "All")? true: selectedStation.isSetup})
		}
	}, [selectedStation])

	if (global.hasPermission('admin.*')) {
        document.addEventListener('keypress', (e) => {
            if (restaurant){
                var keyName = e.key;
                // var code = e.code;
                // var altK = e.altKey;
                if(e.key === 'ç'){              //  ç is option/alt c
                    navigator.clipboard.writeText(`_id: ObjectId("${restaurant._id}")`);
                    global.showNotification("Copied RestaurantId", "success")
                }
                
                if (keyName === "∑") {          //  ∑ is option/alt w
                    //TODO add EIN, primaryContactAccountPasswordSetupLink
                    // let accessLink = global.generateDashboardUrl(restaurant.appName, `/login?a=${restaurant.primaryContactAccount._id}${global.f(restaurant, "primaryContactInfo.email") ? '&e=' + global.f(restaurant, "primaryContactInfo.email") : ''}`, restaurant.whiteLabelCode)  
                    let lastLogin = global.f(restaurant, "primaryContactAccount.lastLoginTime","Never")
                    if (lastLogin !== "Never") lastLogin = moment(restaurant.primaryContactAccount.lastLoginTime).format("M/D/YY h:mma")
                    let keyInfo = "Site\t\t" + global.f(restaurant, "name") + "\nContact\t" +
                            global.f(restaurant, "primaryContactAccount.fullName", "not specified") + "\n\tEmail:\t\t" + global.f(restaurant, "primaryContactAccount.email", "none") + "\n\t\Last login:\t" + 
                            lastLogin + "\n" +
                            "Agent\t" + global.f(restaurant, "agent.firstName", "none") + " " + global.f(restaurant, "agent.lastName", "") + "\t" + global.f(restaurant, "agent.email", "") + "\nPOS\t\t" + 
                            global.f(restaurant, "posType.posType", "none") + "\nApp\t\t" + 
                            global.f(restaurant, "appName") + ":" + global.f(restaurant, "whiteLabelCode") + "\nHas branding image: " +
                            (global.f(restaurant, "branding.logo") ? "Yes" : "No") + "\n\n\n" 
                    navigator.clipboard.writeText(keyInfo);
                    global.showNotification("Copied Restaurant: " +  global.f(restaurant, "name") + " info for email template", "success")
                }
            }
        })
    }

	// const setDefaultPaymentMethods = (sortedGatewayTypes) => {
	// 	let restaurantPaymentMethodsClone = { ...restaurant.paymentMethods }
	// 	let spreedlyGateway = _.find(sortedGatewayTypes, ['code', 'spreedly'])._id;
	// 	if (restaurantPaymentMethodsClone) {
	// 		for (var key in restaurantPaymentMethodsClone) {
	// 			
	// 			
	// 			if (!restaurantPaymentMethodsClone[key].gatewayType) {

	// 				
	// 				if (spreedlyGateway) {
	// 					
	// 					restaurantPaymentMethodsClone[key].gatewayType = spreedlyGateway._id;
	// 					// setRestaurant(restaurantClone)
	// 				}
	// 			}
	// 		}
	// 	}
	// }


	const [saveableObjects, setSaveableObjects] = useState({});

	const handleStationChangeToggle = (e) => {
		const updatedStations = [...stations];
		updatedStations[e.target.getAttribute('index')][e.target.getAttribute('dfieldname')] = !(
			e.target.value == 'true'
		);
		setStations(updatedStations);
		addObjectToSave(updatedStations[e.target.getAttribute('index')]);
	};

	const setupApiSiteIfCloudBasedPosType = async () => {
		if (global.f(restaurant2, 'posType.config.cloudBased', false)) {
			if (!restaurant2.apiSite) {
				let apiSite = await newSchema('ApiSite')
				apiSite.restaurant = restaurant2._id
				apiSite.posType = restaurant2.posType._id
				apiSite.enabled = true
				apiSite.posConfig = {}
				setApiSite(apiSite)
				handleRestaurantStateUpdate(null, 'apiSite', apiSite)
			}
		}
	}

	const addObjectToSave = (objectClone) => {
		//Generic method to add saveable object
		let objectCloneId = objectClone._id;
		setSaveableObjects({ ...saveableObjects, [objectCloneId]: objectClone });
		setHasChanges(true)
	};

	const handleRestaurantGroupStateUpdate = (e, fieldName = null, value = null) => {
		const val = (e && e.target && e.target.value) ? e.target.value : value;
		const restaurantGroupClone = { ...restaurantGroup };
		fieldName = fieldName ? fieldName : e.target.getAttribute('dfieldname');
		_.set(restaurantGroupClone, fieldName, val);
		setRestaurantGroup(restaurantGroupClone);
		addObjectToSave(restaurantGroupClone);
	};

	const handleLoyaltyProgramStateUpdate = (e, fieldName = null, value = null) => {
		const val = (e && e.target && e.target.value) ? e.target.value : value;
		const loyaltyProgramClone = { ...loyaltyProgram };
		fieldName = fieldName ? fieldName : e.target.getAttribute('dfieldname');
		_.set(loyaltyProgramClone, fieldName, val);
		setLoyaltyProgram(loyaltyProgramClone);
		if (!loyaltyProgramClone.restaurantGroup && restaurant2.restaurantGroup) {
			handleLoyaltyProgramStateUpdate(null, 'restaurantGroup', restaurant2.restaurantGroup)
		}
		addObjectToSave(loyaltyProgramClone);
	};
	
	const handleLoyaltyProgramToggleStateUpdate = (e) => {
		let fieldName = e.target.getAttribute('dfieldname');
		const val = !(e.target.value == 'true');
		const loyaltyProgramClone = { ...loyaltyProgram };
		_.set(loyaltyProgramClone, fieldName, val);
		setLoyaltyProgram(loyaltyProgramClone);
		addObjectToSave(loyaltyProgramClone);
	};

	const quickBookPosTypes = ['QuickBooks Desktop', 'QuickBooks Online']

	const serviceChargeFields = ['config.mobile.serviceCharges.ccServiceChargeAmount', 'config.mobile.serviceCharges.ccServiceChargePercent', 'config.mobile.serviceCharges.achServiceChargeAmount', 'config.mobile.serviceCharges.achServiceChargePercent']

	useEffect(() => {
		if (restaurant2.posType && apiSite.posType != restaurant2.posType._id) {
			if (restaurant2.posType.config.cloudBased) {
				handleApiSiteStateUpdate(null, 'posType', restaurant2.posType._id)
			} else {
				handleApiSiteStateUpdate(null, 'posType', null)
			}
		}
	}, [restaurant2.posType])

	const handleRestaurantStateUpdate = (e, fieldName = null, value = null) => {
		let val = (e && e.target && (e.target.value || (e.target.value == ""))) ? e.target.value : value;
		const restaurantClone = { ...restaurant2 };
		if (fieldName == "posType") {
			val = JSON.parse(val)
			if (val && quickBookPosTypes.includes(val.posType)) {
				for (let field of serviceChargeFields) {
					if (restaurant2.getVal(field, 0) > 0) {
						window.alert("Service charge settings are not supported for QuickBooks Desktop and QuickBooks Online users")
						for (let f of serviceChargeFields) {
							_.set(restaurantClone, f, 0);
						}
						break
					}
				}
			}
		}
		
		fieldName = fieldName ? fieldName : e.target.getAttribute('dfieldname');
		
		_.set(restaurantClone, fieldName, val);
		setRestaurant2(restaurantClone);
		addObjectToSave(restaurantClone);
	};

	const handleApiSiteStateUpdate =  async (e, fieldName = null, value = null) => {
		const val = e?.target?.value ? e.target.value : value;
		console.log('val: ' + val)
		console.log('fieldName: ' + fieldName)
		fieldName = fieldName ? fieldName : e.target.getAttribute('dfieldname');
		const apiSiteClone = { ...apiSite }
		_.set(apiSiteClone, `${fieldName}`, val)
		setApiSite(apiSiteClone)
		addObjectToSave(apiSiteClone)
	}

	const handleServiceChargeUpdate = (e, fieldName = null, value = null) => {
		let val = (e && e.target && (e.target.value || (e.target.value == ""))) ? e.target.value : value;

		if (val && val > 0 && restaurant2.posType && quickBookPosTypes.includes(restaurant2.posType.posType)) {
			window.alert("Service charge settings are not supported for QuickBooks Desktop and QuickBooks Online users")
			e.target.value = "0"
			handleRestaurantStateUpdate(e, fieldName, value)
		} else {
			handleRestaurantStateUpdate(e, fieldName, value)
		}
	}


	const handleRestaurantGatewayStateUpdate = (gw, e, fieldName = null, value = null) => {
		const val = (e && e.target && e.target.value) ? e.target.value : value;
		const restaurantGatewaysClone =  [...restaurantGateways]
		fieldName = fieldName ? fieldName : e.target.getAttribute('dfieldname');
		let index = restaurantGatewaysClone.indexOf(gw)

		//todo get the item that updated
		_.set(restaurantGatewaysClone, `[${index}]${fieldName}`, val);
		setRestaurantGateways(restaurantGatewaysClone);
		log.info('restaurantGatewaysClone[index]', restaurantGatewaysClone[index])
		addObjectToSave(restaurantGatewaysClone[index]);
	};

	const handleRestaurantStateArrayUpdate = (e, fieldName = null, value = null) => {
		
		
		
		
		
		const val = (e && e.target && e.target.value) ? e.target.value.split(",") : value.split(",");
		
		// 
		// 
		const restaurantClone = { ...restaurant2 };
		fieldName = fieldName ? fieldName : e.target.getAttribute('dfieldname');
		// 
		// 
		_.set(restaurantClone, fieldName, val);
		setRestaurant2(restaurantClone);
		addObjectToSave(restaurantClone);
	};

	const handleRestaurantToggleStateUpdate = (e) => {
		//dswitch
		let fieldName = e.target.getAttribute('dfieldname');
		const val = !(e.target.value == 'true');
		const restaurantClone = { ...restaurant2 };
		_.set(restaurantClone, fieldName, val);
		setRestaurant2(restaurantClone);
		addObjectToSave(restaurantClone);
	};

	const handleRestaurantWebsiteStateUpdate = (e, fieldName = null, value = null) => {
		const val = (e && e.target && e.target.value) ? e.target.value : value;
		const restaurantWebsiteClone = { ...restaurantWebsite };
		fieldName = fieldName ? fieldName : e.target.getAttribute('dfieldname');
		// 
		// 
		_.set(restaurantWebsiteClone, fieldName, val);
		setRestaurantWebsite(restaurantWebsiteClone);
		if (!restaurantWebsiteClone.restaurant) {
			handleRestaurantWebsiteStateUpdate(null, 'restaurant', restaurant2._id) 
		}
		addObjectToSave(restaurantWebsiteClone);
	};
	
	
	const handleRestaurantWebsiteToggleStateUpdate = (e) => {
		let fieldName = e.target.getAttribute('dfieldname');
		const val = !(e.target.value == 'true');
		const restaurantWebsiteClone = { ...restaurantWebsite };
		_.set(restaurantWebsiteClone, fieldName, val);
		setRestaurantWebsite(restaurantWebsiteClone);
		addObjectToSave(restaurantWebsiteClone);
	};


	const changeSpreedlyGatewayOption = async (e, field) => {
		const val = e.target.value;
		const newGateway = spreedlyGatewayOptions.filter((sgo) => val === sgo._id)[0];
		// setSelectedGateway(newGateway);
		handleRestaurantStateUpdate(e, field);
	};

	const [gatewayTestTransactionIframe, setGatewayTestTransactionIframe] = React.useState(null);
	const [iframeTicketId, setIframeTicketId] = React.useState(null)
	
	const openGatewayTestTransactionIframe = async (ticketId) => {
		if(ticketId){
			setGatewayTestTransactionIframe(true);
			setIframeTicketId(ticketId);
		}
	}
	
	function closeGatewayTestTransactionIframe(event) {
		setGatewayTestTransactionIframe(false);
	}

	const [restaurantGroupForm, setRestaurantGroupForm] = React.useState(null);
	
	const openRestaurantGroupForm = async (event) => {
		setRestaurantGroupForm(true);
		const restaurantGroupObj = await newSchema('RestaurantGroup');
		setRestaurantGroup(restaurantGroupObj)
	}
	
	function closeRestaurantGroupForm(event) {
		setRestaurantGroupForm(false);
	}
	
	
	const openLoyaltyProgramForm = async (event) => {
		setLoyaltyProgramForm(true);
		const loyaltyProgramObj = await newSchema('LoyaltyProgram');
		setLoyaltyProgram(loyaltyProgramObj)
	}
	
	function closeLoyaltyProgramForm(event) {
		setLoyaltyProgramForm(false);
	}

	const [loyaltyProgramForm, setLoyaltyProgramForm] = React.useState(null);

	const refreshLoyaltyPrograms = async (docs) => {
		let data = await dashFetch(`${global.dashServerUrl}/dashboard/api/v1/dashboard/getDropdownList`, {}, true);
		if (data.json) {
			setLoyaltyPrograms(data.json.loyaltyPrograms ? data.json.loyaltyPrograms.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1) : []);
		}
		if (loyaltyProgram._id && docs[loyaltyProgram._id]) handleRestaurantStateUpdate(null, 'loyaltyProgram', docs[loyaltyProgram._id]._id) // overload state method to pass in response from upsert
		// handleRestaurantStateUpdate(null, 'restaurantGroup.code', camelize(restaurantGroup.name))
		closeLoyaltyProgramForm()
	}

	const refreshRestaurantGroup = async (docs) => {
		let data = await dashFetch(`${global.dashServerUrl}/dashboard/api/v1/dashboard/getDropdownList`, {}, true);
		if (data.json) {
			setRestaurantGroups(data.json.restaurantGroups ? data.json.restaurantGroups.filter((a) => a.name).sort((a, b) => (a.name && b.name && a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1) : []);
		}
		if (restaurantGroup._id && docs[restaurantGroup._id]) handleRestaurantStateUpdate(null, 'restaurantGroup', restaurantGroup._id) // overload state method to pass in response from upsert
		// handleRestaurantStateUpdate(null, 'restaurantGroup.code', camelize(restaurantGroup.name))
		closeRestaurantGroupForm()
	}
	
	
	const refreshRestaurant = async (docs) => {

		let restaurantObj
		let restaurantGatewaysClone = JSON.parse(JSON.stringify(restaurantGateways))
		Object.keys(docs).forEach(key => {
			let record = docs[key]
			if (restaurant2._id == record._id) {
				restaurantObj = record
			} else {
				let index = _.findIndex(restaurantGatewaysClone, {_id: record._id})
				if (index > -1) {
					restaurantGatewaysClone.splice(index, 1, record)
				}
			}
		})

		
		setRestaurantGateways(restaurantGatewaysClone)

		
		if (restaurantObj) {
			history.replace(`/admin/restaurants/addEditRestaurant/${restaurantObj._id}`)
			setRestaurant2(restaurantObj)
			let updated = {restaurantId: restaurantObj._id, restaurant: restaurantObj}
			if (isNew) updated.restaurants = [...restaurants, restaurantObj]
			global.set(updated)
		}
		global.showNotification('Site Saved', 'success')
		setEditing(false)
		setIsNew(false)
		setHasChanges(false)
	}

	const validateGatewayToken = async (docs) => {
		log.info('validator')
		refreshRestaurant(docs)
	}
	
	
	const refreshRestaurantWebsite = async (docs) => {
		
		
		let restaurantWebsiteObj = docs[restaurantWebsite._id]
		if (!restaurantWebsiteObj) {
			restaurantWebsiteObj = docs[Object.keys(docs)[0]]
		}
		
		
		if (restaurantWebsiteObj) {
			setRestaurantWebsite(restaurantWebsiteObj)
		}
	}

	const refreshLoyaltyProgram = async (docs) => {
		let loyaltyProgObj = docs[loyaltyProgram._id]
		if (!loyaltyProgObj) {
			loyaltyProgObj = docs[Object.keys(docs)[0]]
		}
		
		
		if (loyaltyProgObj) {
			setLoyaltyProgram(loyaltyProgObj)
		}
	}

	const setRestGroupCode = async () => {
		handleRestaurantGroupStateUpdate(null, 'code', camelize(restaurantGroup.name)) //TODO: this doesnt get set before the save happens
		
	}
	
	
	const setStatus = async (e) => { //todo make this a separate component, sorry gotta move fast
		let status = e.target.value
		let allowSave = true
		if (status == 'live') {
			setDialogContent(
				<Dialog
					maxWidth={'md'}
					open={true}
					style={{ margin: 0, height: '100%', width: '100%'}}
					onClose={() => setDialogContent(null)}
				>
					<DialogContent>
						<CircularProgress/>
					</DialogContent>
				</Dialog>
			)
			let verifyResult = await verifyGoLiveApi(restaurant._id)
			let verified = verifyResult.success
			setDialogContent(
				<Dialog
					maxWidth={'md'}
					open={true}
					style={{ margin: 0, height: '100%', width: '100%'}}
					onClose={() => setDialogContent(null)}
				>
					<DialogContent dividers>
						{verified && <>
							<center><DFlag checked /></center>
							<br/>
							<span>Restaurant is ready for go live! Saving this status will Enable the restaurant </span>
							<br/>
						</>}
						{!verified && <>
							<center><DFlag checked={false}/></center>
							<br/>
							{verifyResult && verifyResult.getVal("data.message", false) && <>
								<span>{verifyResult.getVal("data.message")}</span>
								<br/>
								<br/>
							</>}
						</>}
						<br/>
						<center><Button onClick={() => setDialogContent(null)}>Close</Button></center>
					</DialogContent>
				</Dialog>
			)
			allowSave = verified
		} else if ((status != 'live' && restaurant.status == 'live') || status == "hyfin") {
			setDialogContent(
				<Dialog
					maxWidth={'md'}
					open={true}
					style={{ margin: 0, height: '100%', width: '100%'}}
					onClose={() => setDialogContent(null)}
				>
					<DialogContent dividers>
						<span>Setting this status will disable the restaurant</span>
						<br/>
						<br/>
						<center><Button onClick={() => setDialogContent(null)}>Close</Button></center>
					</DialogContent>
				</Dialog>
			)
		} else if (status == 'testing') {
			setDialogContent(
				<Dialog
					maxWidth={'md'}
					open={true}
					style={{ margin: 0, height: '100%', width: '100%'}}
					onClose={() => setDialogContent(null)}
				>
					<DialogContent dividers>
						<span>Setting this status will enable this restaurant until midnight of the restaurant's timezone</span>
						<br/>
						<br/>
						<center><Button onClick={() =>{ setDialogContent(null)}}>{'Close'}</Button></center>
					</DialogContent>
				</Dialog>
			)
		}
		if (allowSave) handleRestaurantStateUpdate(e, 'status')
	}

	function getModalStyle() {
		const top = 50
		const left = 50

		return {
			padding: 20,
			top: `${top}%`,
			left: `${left}%`,
			transform: `translate(-${top}%, -${left}%)`,
		};
	}
	const [modalStyle] = React.useState(getModalStyle);

	const copyToClipboard = (url) => {
		navigator.clipboard.writeText(url);
		global.showNotification("Copied", "success")
	}

	let zParam = "";
	if (restaurant && restaurant.code) {
		if (restaurant.branding.splashLogo) {
			zParam = "&z=1-";
		} else {
			zParam = "&z=0-";
		}
		zParam += restaurant.code;
	}
	const showGeneral = () => {
		return (
			<Grid container item>
				<DForm collection="Restaurant" dashkey={restaurant2._id}>
					<Grid item container spacing={4}>
						<Grid item lg={12} style={{ paddingTop:2, paddingBottom:2}}>
							<Typography variant="h6" style={{color:'var(--text)'}}>
								Site Info
							</Typography>
						</Grid>
						<Grid item xs={12} lg={5}>
							<div>
								<DTextField
									disabled={!editing} 
									type="text"
									className={classes.restaurantFieldInputItem}
									label="Site Name"
									value={restaurant2.name}
									onChange={handleRestaurantStateUpdate}
									dfieldname="name"
								/>
							</div>
							<div>
								<DTextField
									disabled={!editing} 
									type="text"
									className={classes.restaurantFieldInputItem}
									label="Location Name"
									value={restaurant2.locationName}
									onChange={handleRestaurantStateUpdate}
									dfieldname="locationName"
								/>
							</div>
							<div>
							<FormControl className={classes.restaurantFieldInputItem} >
									<InputLabel shrink htmlFor="">Business Type</InputLabel>
									<SelectMui
										disabled={!editing}
										label="Business Type"
										className={classes.muiSelect}
										value={restaurant2.businessType}
										inputProps={{id:'businessType'}}
										onChange={(e) => handleRestaurantStateUpdate(e, 'businessType')}
									>
										{/* <MenuItem disabled value=""><em>Site Group</em></MenuItem> */}
										{['restaurant', 'other'].map((bt) => <MenuItem value={bt}>{global.capitalize(bt)}</MenuItem>)}
									</SelectMui>
								</FormControl>
							</div>
							<div>
								<DTextField
									disabled={!editing}
									type="text"
									className={classes.restaurantFieldInputItem}
									label="Currency (ISO 4217)"
									value={restaurant2.currency}
									onChange={handleRestaurantStateUpdate}
									dfieldname="currency"
								/>
							</div>
							
							<div>
								<FormControl style={{ width: 'calc(100% - 50px)' }} className={classes.restaurantFieldInputItem} >
									<InputLabel shrink htmlFor="restaurantGroup">Site Group</InputLabel>
									<SelectMui
										disabled={!editing}
										label="Site Group"
										className={classes.muiSelect}
										value={restaurant2.restaurantGroup}
										inputProps={{id:'restaurantGroup'}}
										onChange={(e) => handleRestaurantStateUpdate(e, 'restaurantGroup')}
									>
										{/* <MenuItem disabled value=""><em>Site Group</em></MenuItem> */}
										{restaurantGroups.filter((rg) => rg.deleted === false).map((rg) => <MenuItem value={rg._id}>{rg.name}</MenuItem>)}
									</SelectMui>
								</FormControl>
								<Modal
									aria-labelledby="simple-modal-title"
									aria-describedby="simple-modal-description"
									open={restaurantGroupForm}
									onClose={closeRestaurantGroupForm}
								>
									<div style={modalStyle} className={classes.paper}>
										<DForm collection="RestaurantGroup" dashkey={restaurantGroup._id}>
											{/* <h2 id="simple-modal-title">Add a Site Group</h2> */}
											<Typography
												variant="overline"
												color="parimary"
												style={{}}
												className={classes.subheadingText}
											>
												Add a Site Group
											</Typography>
											<p id="simple-modal-description">
												<DTextField
													disabled={!editing}
													type="text"
													style={{ width: '100%' }}
													label="Site Group"
													value={restaurantGroup.name}
													onChange={handleRestaurantGroupStateUpdate}
													dfieldname={'name'}
												/>
											</p>
										</DForm>
										{editing && 
											<Grid alignContent="center" container direction="row" justify="center">
												<DSubmitButton
													disabled={!restaurantGroup || (!restaurantGroup.name || restaurantGroup.name.trim() == "") || restaurantGroups.find((rg) => rg.name === restaurantGroup.name)}
													parentState={{ saveableObjects: { ...saveableObjects } }}
													collectionsToSave={['RestaurantGroup']}
													next={refreshRestaurantGroup}
													// preSave={setRestGroupCode}
													/>

											</Grid>
										}
									</div>
								</Modal>
								{editing &&
									<Button size="small" variant="contained" color="primary" className={classes.addButton} onClick={openRestaurantGroupForm} color='primary'><Typography style={{color:'white'}}>+</Typography></Button>
								}
							</div>
							<div>
								<FormControl className={classes.restaurantFieldInputItem} >
									<InputLabel shrink htmlFor="posType">POS Type</InputLabel>
									<SelectMui
										disabled={!editing}
										className={classes.muiSelect}
										value={JSON.stringify(restaurant2.posType)}
										inputProps={{ id: 'posType' }}
										onChange={(e) => handleRestaurantStateUpdate(e, 'posType')}
										
									>
										{posTypes.map((pt) => <MenuItem value={JSON.stringify(pt)}>{pt.posType}</MenuItem>)}
									</SelectMui>
									<DForm collection="ApiSite" dashkey={apiSite._id}>
										{restaurant2.posType && restaurant2.posType.config && restaurant2.posType.config.cloudBased &&
											<>
												{restaurant2.posType.config.cloudBasedConfigFields.map((cbf) => {
													return cbf ?
														<DTextField
															style={{marginLeft: '10px', width: '95%'}}
															disabled={!editing}
															type="text"
															value={restaurant2.apiSite?.posConfig?.[cbf] ?? ''}
															className={classes.restaurantFieldInputItem}
															label={cbf}
															onChange={(e)=> handleApiSiteStateUpdate(e, `posConfig.${cbf}`)}
															dfieldname={cbf}
														/>
													: <></>
												})}
												{apiSite?.posConfig?.realmId &&
													<FormControl style={{marginLeft: '10px', width: '95%'}} className={classes.restaurantFieldInputItem}>
														<TextField disabled value={apiSite?.posConfig?.realmId} label="RealmId" />
													</FormControl>
												}
												{restaurant2.posType?.posType == 'QuickBooks Online' && !apiSite?.posConfig?.realmId &&
													<div style={{display: 'flex', alignItems: 'center', marginLeft: '10px'}}>
														<FormControl style={{ width: 'calc(100% - 200px)'}} className={classes.restaurantFieldInputItem}>
															<InputLabel shrink htmlFor="realmId">RealmId</InputLabel>
															<SelectMui
																disabled={!editing}
																className={classes.muiSelect}
																value={JSON.stringify(realmId)}
																inputProps={{ id: 'realmId' }}
																onChange={(e) => setRealmId(JSON.parse(e.target.value))}
																style={{width: "100%"}}
															>
																{unassociatedRealmIds.map((item) => <MenuItem value={JSON.stringify(item)}>{item.data?.companyName ?? item.realmId}</MenuItem>)}
																{unassociatedRealmIds.length == 0 && <MenuItem disabled>No unassociated realmId found</MenuItem>}
															</SelectMui>
														</FormControl>
														<Button disabled={!editing} color="primary" variant="contained" onClick={attachRealmId} style={{marginLeft: 16}}>Attach realmId</Button>
													</div>
												}
												{(restaurant2.apiSite && restaurant2.apiSite.posConfig) && 
													<div style={{marginLeft: '10px', width: '95%', paddingBottom: 10}}>
														<Typography variant="caption"> API connection {restaurant2.apiSite.connectionTested ? 'is' : 'is NOT'} tested, last connected at {apiLastConnectedAt ? apiLastConnectedAt.fromNow() : 'not in the last 6 hours'}</Typography>
														{!restaurant2.apiSite.connectionTested && <>
															<br/>
															<div style={{display: 'flex', alignItems: 'center'}}>
																<Button color="primary" variant="contained" onClick={testApiConnection} style={{marginRight: 16}}>TEST API Connection</Button>
																{
																	testApiConnectionAt && (
																		(testApiConnectionFailed && <ErrorOutline color={"error"} />)
																		||
																		(apiLastConnectedAt && testApiConnectionAt.isBefore(apiLastConnectedAt) && <CheckCircleOutline color={"primary"}/>)
																		||
																		<CircularProgress color={"primary"} size={24}></CircularProgress>
																	)
																}
															</div>
															<br/>
														</>}
													</div>
												}
											</>
										}
									</DForm>
								</FormControl>
							</div>

							<div>
								<FormControl style={{ width: 'calc(100% - 50px)' }} className={classes.restaurantFieldInputItem}>
									<InputLabel shrink htmlFor="loyaltyProgram">Loyalty Program</InputLabel>
									<SelectMui
										disabled={!editing}
										label="Loyalty Program"
										className={classes.muiSelect}
										value={restaurant2.loyaltyProgram ? restaurant2.loyaltyProgram : ""}
										inputProps={{ id: 'loyaltyProgram' }}
										onChange={(e) => handleRestaurantStateUpdate(e, 'loyaltyProgram')}
									>
										{/* <MenuItem disabled value=""><em>Site Group</em></MenuItem> */}
										{loyaltyPrograms.map((loyProg) => <MenuItem value={loyProg._id}>{loyProg.name}</MenuItem>)}
									</SelectMui>
								</FormControl>
								<Modal
									aria-labelledby="simple-modal-title"
									aria-describedby="simple-modal-description"
									open={loyaltyProgramForm}
									onClose={closeLoyaltyProgramForm}
								>
									<div style={modalStyle} className={classes.paper}>
										{loyaltyProgram &&
											<DForm collection="LoyaltyProgram" dashkey={loyaltyProgram._id}>
												{/* <h2 id="simple-modal-title">Add a Site Group</h2> */}
												<Typography
													variant="overline"
													color="parimary"
													style={{}}
													className={classes.subheadingText}
												>
													Add a Loyalty Program
												</Typography>
												<p id="simple-modal-description">
													<DTextField
														disabled={!editing}
														type="text"
														style={{ width: '100%' }}
														label="Loyalty Program Name"
														value={loyaltyProgram.name}
														onChange={handleLoyaltyProgramStateUpdate}
														dfieldname={'name'}
													/>
													<DTextField
														disabled={!editing}
														type="text"
														style={{ width: '100%' }}
														label="Logo"
														value={loyaltyProgram.logo}
														onChange={handleLoyaltyProgramStateUpdate}
														dfieldname={'logo'}
													/>
												</p>
											</DForm>
										}	
										{editing && 		
											<Grid alignContent="center" container direction="row" justify="center">
												<DSubmitButton
													parentState={{ saveableObjects: { ...saveableObjects } }}
													collectionsToSave={['LoyaltyProgram']}
													next={refreshLoyaltyPrograms}
												// preSave={setRestGroupCode}
												/>

											</Grid>
										}
									</div>
								</Modal>
								{editing &&
									<Button size="small" variant="contained" color="primary" className={classes.addButton} onClick={openLoyaltyProgramForm} color='primary'><Typography style={{ color: 'white' }}>+</Typography></Button>
								}
							</div>
						</Grid>
						<Grid item direction="column" lg={4}>
							{/* <Paper style={{padding:'10px'}}> */}
								<Grid container spacing={2}>

									<Grid item xs={12} lg={12}>
										<FormControl className={classes.restaurantFieldInputItem} style={{width:'95%'}}>
											<InputLabel shrink htmlFor="status">Status</InputLabel>
											<SelectMui
												disabled={!editing}
												// className={classes.muiSelect}
												value={restaurant2.status ? restaurant2.status : ""}
												inputProps={{ id: 'status' }}
												onChange={(e) => setStatus(e)}
											>
												{['enrolled', 'installed', 'testing', 'live', 'cancelled', 'hyfin', 'ftl'].map((status) => <MenuItem value={status}>{global.capitalize(status)}</MenuItem>)}
											</SelectMui>
										</FormControl>
									</Grid>
									{restaurant2.status == "testing" &&
										<Grid item xs={12} lg={12}>
											<DTextField
												disabled={!editing}
												type="number"
												min="1"
												max="24"
												style={{ width: '95%' }}
												label="Hours to test"
												value={restaurant2.testingEnabledTimeHours }
												onChange={handleRestaurantStateUpdate}
												dfieldname={'testingEnabledTimeHours'}
											/>
										</Grid>
									}
									<Grid item xs={12} lg={6}>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												disabled={!editing}
												autoOk
												initialFocusedDate=""
												showTodayButton
												format="MM/dd/yyyy"
												id="date-picker-inline"
												label="Date Enrolled"
												InputLabelProps={{
													shrink: true,
												}}    
												className={classes.restaurantFieldInputItem}
												value={restaurant2.dateEnrolled}
												onChange={(date) => handleRestaurantStateUpdate(null, 'dateEnrolled', date)}
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
											/>
										</MuiPickersUtilsProvider>
									</Grid>
									<Grid item xs={12} lg={6}>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												disabled={!editing}
												autoOk
												initialFocusedDate=""
												showTodayButton
												format="MM/dd/yyyy"
												id="date-picker-inline"
												label="Date Installed"
												InputLabelProps={{
													shrink: true,
												}}    
												className={classes.restaurantFieldInputItem}
												value={restaurant2.dateInstalled}
												onChange={(date) => handleRestaurantStateUpdate(null, 'dateInstalled', date)}
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
											/>
										</MuiPickersUtilsProvider>
									</Grid>
									<Grid item xs={12} lg={6}>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												disabled={!editing}
												autoOk
												initialFocusedDate=""
												showTodayButton
												format="MM/dd/yyyy"
												id="date-picker-inline"
												label="Go Live Date"
												InputLabelProps={{
													shrink: true,
												}}    
												className={classes.restaurantFieldInputItem}
												value={restaurant2.goLiveDate}
												onChange={(date) => handleRestaurantStateUpdate(null, 'goLiveDate', date)}
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
											/>
										</MuiPickersUtilsProvider>
									</Grid>
									<Grid item xs={12} lg={6}>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												disabled={!editing}
												autoOk
												initialFocusedDate=""
												showTodayButton
												format="MM/dd/yyyy"
												id="date-picker-inline"
												label="Date Cancelled"
												InputLabelProps={{
													shrink: true,
												}}    
												className={classes.restaurantFieldInputItem}
												value={restaurant2.dateCancelled}
												onChange={(date) => handleRestaurantStateUpdate(null, 'dateCancelled', date)}
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
											/>
										</MuiPickersUtilsProvider>
									</Grid>
								</Grid>
								<Grid item xs={12} style={{paddingTop:20}}>
									<DTextField
										disabled={!editing}
										type="text"
										variant="outlined"
										style={{ width: '95%' }}
										label="Internal Notes"
										multiline
										rowsMax={6}
										value={restaurant2.notes }
										onChange={handleRestaurantStateUpdate}
										dfieldname={'notes'}
									/>
								</Grid>
								<Grid item xs={12} style={{paddingTop:20}}>
									<DTextField
										disabled={!editing}
										type="text"
										variant="outlined"
										style={{ width: '95%' }}
										label="External Notes"
										multiline
										rowsMax={6}
										value={restaurant2.externalNotes }
										onChange={handleRestaurantStateUpdate}
										dfieldname={'externalNotes'}
									/>
								</Grid>
							{/* </Paper> */}
						</Grid>
						<Grid item lg={3} alignContent="right">
							<Paper style={{padding:'10px'}}>
								<Grid container>
									<Grid item container>
										<Grid xs={6} alignContent='flex-start'>
											<Typography
												variant="body"
												color="secondary"
												className={classes.subheadingText}
											>
												App Name:
											</Typography>
										</Grid>
										<Grid xs={6} alignContent='flex-start'>
											{!editing &&
												<Typography
													variant="body2"
													color="primary"
													className={classes.subheadingText}
												>
													{global.f(restaurant2, 'appName', '')}
												</Typography>
											}{ editing &&
												<SelectMui
													disabled={!editing}
													// className={classes.muiSelect}
													value={restaurant2.appName ? restaurant2.appName : ""}
													inputProps={{ id: 'appName' }}
													onChange={(e) => handleRestaurantStateUpdate(e, 'appName')}
												>
													{['DashNow', 'TextPay', 'Curbly'].map((appName) => <MenuItem value={appName}>{appName}</MenuItem>)}
												</SelectMui>
											}
										</Grid>
										<Grid item xs={12}>
											<Divider className={classes.divider}/>
										</Grid>
									</Grid>
									{restaurant2.appName == "TextPay" &&
										<Grid item container>
											<Grid xs={6} alignContent='flex-start'>
												<Typography
													variant="body"
													color="secondary"
													className={classes.subheadingText}
												>
													WhiteLabel:
												</Typography>
											</Grid>
											<Grid xs={6} alignContent='flex-start'>
												{!editing &&
													<Typography
														variant="body2"
														color="primary"
														className={classes.subheadingText}
													>
														{global.capitalize(global.f(restaurant2, 'whiteLabelCode', ''))}
													</Typography>
												}{ editing &&
													<SelectMui
														disabled={!editing}
														// className={classes.muiSelect}
														value={restaurant2.whiteLabelCode ? restaurant2.whiteLabelCode : ""}
														inputProps={{ id: 'whiteLabelCode' }}
														onChange={(e) => handleRestaurantStateUpdate(e, 'whiteLabelCode')}
													>
														{global.brandingKeys.map((whiteLabelCode) => <MenuItem value={whiteLabelCode}>{global.capitalize(whiteLabelCode)}</MenuItem>)}
													</SelectMui>
												}
											</Grid>
											<Grid item xs={12}>
												<Divider className={classes.divider}/>
											</Grid>
										</Grid>
									}
									<Grid item container>
										<Grid xs={6} alignContent='flex-start'>
											<Typography
												variant="body"
												color="secondary"
												className={classes.subheadingText}
											>
												Install Code:
											</Typography>
										</Grid>
										<Grid xs={6} alignContent='flex-start'>
											<Typography
												variant="body2"
												color="primary"
												className={classes.subheadingText}
											>
												{global.f(restaurant2, 'installCode', '')}
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<Divider className={classes.divider}/>
										</Grid>
									</Grid>
									<Grid item container>
										<Grid xs={6} alignContent='flex-start'>
											<Typography
												variant="body"
												color="secondary"
												// style={{float:'left'}}
												className={classes.subheadingText}
											>
												Site Code:
											</Typography>
										</Grid>
										<Grid xs={6} alignContent='flex-end'>
											<Typography
												variant="body2"
												color="primary"
												// style={{float:'right'}}
												className={classes.subheadingText}
											>
												{global.f(restaurant2, 'code', '').toUpperCase()}
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<Divider className={classes.divider}/>
										</Grid>
									</Grid>
									<Grid item container>
										<Grid xs={6}>
											<Typography
												variant="body"
												color="secondary"
												style={{}}
												className={classes.subheadingText}
											> Training Mode:
											</Typography>
										</Grid>
										<Grid xs={6} alignContent='flex-end'>
										<DFlag checked={global.f(restaurant2, 'trainingMode', false)}/>
										</Grid>
										<Grid item xs={12}>
											<Divider className={classes.divider}/>
										</Grid>
									</Grid>
									{restaurantGateways[0] && /* !restaurant2.trainingMode && */ <>
										<Grid item container>
											<Grid xs={6}>
												<Typography
													variant="body"
													color="secondary"
													style={{}}
													className={classes.subheadingText}
												> 1st Gateway is tested
												</Typography>
											</Grid>
											<Grid xs={6} alignContent='flex-end'>
												<DFlag checked={testTransactionProcessedSuccessfully} />
											</Grid>
											<Grid item xs={12}>
												<Divider className={classes.divider}/>
											</Grid>
										</Grid>						
										{restaurant2.merchantId &&
											<Grid item container>
												<Grid xs={6}>
													<Typography
														variant="body"
														color="secondary"
														style={{}}
														className={classes.subheadingText}
														> Merchant ID
													</Typography>
												</Grid>
												<Grid xs={6} alignContent='flex-end'>
													<Typography
														variant="body2"
														color="primary"
														// style={{float:'right'}}
														className={classes.subheadingText}
														>
														{ restaurant2.merchantId}
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<Divider className={classes.divider}/>
												</Grid>
											</Grid>
										}
										{testTransactionProcessedSuccessfully && <>
											<Grid item container>
												<Grid xs={6}>
													<Typography
														variant="body"
														color="secondary"
														style={{}}
														className={classes.subheadingText}
													> Transaction Amount
													</Typography>
												</Grid>
												<Grid xs={6} alignContent='flex-end'>
													<Typography
														variant="body2"
														color="primary"
														// style={{float:'right'}}
														className={classes.subheadingText}
													>
														${global.formatMoney((restaurantGateways[0].testTransactionProcessedAmount) ? restaurantGateways[0].testTransactionProcessedAmount : '')}
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<Divider className={classes.divider}/>
												</Grid>
											</Grid>
											<Grid item container>
												<Grid xs={6}>
													<Typography
														variant="body"
														color="secondary"
														style={{}}
														className={classes.subheadingText}
													> Transaction Date
													</Typography>
													
												</Grid>
												<Grid xs={6} alignContent='flex-end'>
													<Typography
														variant="body2"
														color="primary"
														// style={{float:'right'}}
														className={classes.subheadingText}
													>
														{ restaurantGateways[0].testTransactionDate ? moment(restaurantGateways[0].testTransactionDate).format('M/D/YY - h:mm a') : "" }
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<Divider className={classes.divider}/>
												</Grid>
											</Grid>
											<Grid item container>
												<Grid xs={6}>
													<Typography
														variant="body"
														color="secondary"
														style={{}}
														className={classes.subheadingText}
													> 1st Live Payment On
													</Typography>
													
												</Grid>
												<Grid xs={6} alignContent='flex-end'>
													<Typography
														variant="body2"
														color="primary"
														className={classes.subheadingText}
													>
														{restaurant2.firstLivePaymentOn ? moment(restaurant2.firstLivePaymentOn).format('M/D/YY - h:mm a') : "Wait for it"}
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<Divider className={classes.divider}/>
												</Grid>
											</Grid>
										
										</>}
									</>}
								</Grid>
								{/* <Typography variant="body2" color="primary" className={classes.subheadingText}>
									Features
								</Typography> */}
								{restaurant2 && <>
									<Tooltip title="Set site status to enable/disable">
										<FormControlLabel 
											style={{paddingLeft: 10}}
											label={"RESTAURANT ENABLED"}
											control={<DSwitch
												disabled={true}
												checked={global.f(restaurant2, 'restaurantEnabled')}
												value={restaurant2.restaurantEnabled}
												onChange={handleRestaurantToggleStateUpdate}
												dfieldname={'restaurantEnabled'}
											/>}
										/>
									</Tooltip>
									<Divider className={classes.divider}/>
									{!global.isProd && <>
										<FormControlLabel 
											style={{paddingLeft: 10}}
											label={"DEMO"}
											control={<DSwitch
												disabled={!editing}
												checked={global.f(restaurant2, 'demo')}
												value={restaurant2.demo}
												onChange={handleRestaurantToggleStateUpdate}
												dfieldname={'demo'}
											/>}
										/>
										{restaurant2.demo && restaurant2.code &&
											<Typography style={{ float: 'right', paddingRight:30, paddingBottom:10}}>
												<Button variant="contained" color="primary" onClick={ ()=> copyToClipboard(`https://demo.dashnow.com/?r=${restaurant2._id}&tb=1${zParam}`) }><AssignmentIcon style={{paddingRight: '10px'}}></AssignmentIcon>Copy Demo URL </Button>
											</Typography>
										}
										<Divider className={classes.divider}/>
									</>}

									<FormControlLabel 
										style={{paddingLeft: 10}}
										label={"Text to pay enabled"}
										control={<DSwitch
											disabled={!editing}
											checked={global.f(restaurant2, 'config.features.textToPay.enabled')}
											value={restaurant2.config.features.textToPay.enabled}
											onChange={handleRestaurantToggleStateUpdate}
											dfieldname={'config.features.textToPay.enabled'}
										/>}
									/>
									<br />
									<FormControlLabel 
										style={{paddingLeft: 10}}
										label={"I'm here enabled"}
										control={<DSwitch
											disabled={!editing}
											checked={global.f(restaurant2, 'config.features.imHere.enabled')}
											value={restaurant2.config.features.imHere.enabled}
											onChange={handleRestaurantToggleStateUpdate}
											dfieldname={'config.features.imHere.enabled'}
										/>}
									/>
									<br />
									<FormControlLabel 
										style={{paddingLeft: 10}}
										label={"I'm here Online (Arrived+)"}
										control={<DSwitch
											disabled={!editing}
											checked={global.f(restaurant2, 'config.features.imHere.onlineOrders')}
											value={restaurant2.config.features.imHere.onlineOrders}
											onChange={handleRestaurantToggleStateUpdate}
											dfieldname={'config.features.imHere.onlineOrders'}
										/>}
									/>
									<br />
									<FormControlLabel 
										style={{paddingLeft: 10}}
										label={"Order up enabled"}
										control={<DSwitch
											disabled={!editing}
											checked={global.f(restaurant2, 'config.features.orderUp.enabled')}
											value={restaurant2.config.features.orderUp.enabled}
											onChange={handleRestaurantToggleStateUpdate}
											dfieldname={'config.features.orderUp.enabled'}
										/>}
									/>
									<br />
									<Divider className={classes.divider}/>
									<FormControlLabel 
										style={{paddingLeft: 10}}
										label={"Allow split payments"}
										control={<DSwitch
											disabled={!editing}
											checked={global.f(restaurant2, 'config.mobile.allowSplitPayments', false)}
											value={restaurant2.config.mobile.allowSplitPayments}
											onChange={handleRestaurantToggleStateUpdate}
											dfieldname={'config.mobile.allowSplitPayments'}
										/>}
									/>
									<br />
									<FormControlLabel 
										style={{paddingLeft: 10}}
										label={"Allow partial payments"}
										control={<DSwitch
											disabled={!editing}
											checked={global.f(restaurant2, 'config.mobile.allowPartialPayments', false)}
											value={restaurant2.config.mobile.allowPartialPayments}
											onChange={handleRestaurantToggleStateUpdate}
											dfieldname={'config.mobile.allowPartialPayments'}
										/>}
									/>
									<br />
									<FormControlLabel 
										style={{paddingLeft: 10}}
										label={"Allow Tips"}
										control={<DSwitch
											disabled={!editing}
											checked={global.f(restaurant2, 'config.mobile.allowTips', false)}
											value={restaurant2.config.mobile.allowTips}
											onChange={handleRestaurantToggleStateUpdate}
											dfieldname={'config.mobile.allowTips'}
										/>}
									/>
									<br />
									<FormControlLabel 
										style={{paddingLeft: 10}}
										label={"Select Vehicle Enabled"}
										control={<DSwitch
											disabled={!editing}
											checked={global.f(restaurant2, 'config.features.imHere.selectVehicleEnabled')}
											value={restaurant2.config.features.imHere.selectVehicleEnabled}
											onChange={handleRestaurantToggleStateUpdate}
											dfieldname={'config.features.imHere.selectVehicleEnabled'}
										/>}
									/>
									<br />
									<FormControlLabel 
										style={{paddingLeft: 10}}
										label={"Select Location Enabled"}
										control={<DSwitch
											disabled={!editing}
											checked={global.f(restaurant2, 'config.features.imHere.selectLocationEnabled')}
											value={restaurant2.config.features.imHere.selectLocationEnabled}
											onChange={handleRestaurantToggleStateUpdate}
											dfieldname={'config.features.imHere.selectLocationEnabled'}
										/>}
									/>
									<br />
									{global.f(restaurant2, 'config.features.imHere.selectLocationEnabled') &&
										<FormControlLabel 
											style={{paddingLeft: 10}}
											label={"Pickup Locations (CSV)"}
											control={<DTextField
												disabled={!editing}
												value={restaurant2.config.features.imHere.pickupLocations}
												onChange={handleRestaurantStateArrayUpdate}
												dfieldname={'config.features.imHere.pickupLocations'}
											/>}
										/>
									}
									<br />
								</>}
							</Paper>
						</Grid>
					</Grid>
					<Grid container style={{ marginTop: '2.5%' }} spacing={1}>
						{/* Location Info */}
						<Grid item lg={7}>
							<Typography variant="body2" style={{color:'var(--text)'}}  className={classes.subheadingText}>
								Location Info
							</Typography>
							<Grid container>
								<Grid item xs={12} lg={6}>
									<DTextField
										disabled={!editing}
										type="text"
										style={{ width: '100%' }}
										label="Address Line 1"
										value={(restaurant2.locationInfo && restaurant2.locationInfo.addressLine1) ? restaurant2.locationInfo.addressLine1: ''}
										onChange={handleRestaurantStateUpdate}
										dfieldname={'locationInfo.addressLine1'}
									/>
								</Grid>
								<Grid item xs={12} lg={6}></Grid>

								<Grid item xs={12} lg={6}>
									<DTextField
										disabled={!editing}
										type="text"
										style={{ width: '100%' }}
										label="Address Line 2"
										value={(restaurant2.locationInfo && restaurant2.locationInfo.addressLine2) ? restaurant2.locationInfo.addressLine2 : ''}
										onChange={handleRestaurantStateUpdate}
										dfieldname={'locationInfo.addressLine2'}
									/>
								</Grid>
								<Grid item xs={12} lg={6}></Grid>
								<Grid item xs={12} lg={6}>
									<DTextField
										disabled={!editing}
										type="text"
										style={{ width: '100%', marginTop: '4px' }}
										label="City"
										value={(restaurant2.locationInfo && restaurant2.locationInfo.city) ? restaurant2.locationInfo.city : ''}
										onChange={handleRestaurantStateUpdate}
										dfieldname={'locationInfo.city'}
									/>
								</Grid>
								<Grid item xs={12} lg={3}>

									<FormControl style={{ width: '100%', marginTop: '4px' }} InputLabelProps={{ shrink: true }}>
										<InputLabel shrink htmlFor="state">State</InputLabel>
										<SelectMui
											disabled={!editing}
											InputLabelProps={{ shrink: true }}
											inputProps={{ id: 'state' }}
											value={(restaurant2.locationInfo.state) ? restaurant2.locationInfo.state : ''}
											onChange={(e) => handleRestaurantStateUpdate(e, 'locationInfo.state')}

										>
											<MenuItem disabled value=""><em>State</em></MenuItem>
											{states.map((pt) => <MenuItem value={pt.value} disabled={(pt.prop) ? true : false}>{pt.label}</MenuItem>)}
										</SelectMui>
									</FormControl>

								</Grid>
								<Grid item xs={12} lg={3}>
									<div style={{ marginTop: '4px' }}>
										<DTextField
											disabled={!editing}
											type="text"
											style={{ width: '80%' }}
											label="Zip"
											value={restaurant2.locationInfo.zipCode}
											InputLabelProps={{ shrink: true }}
											onChange={handleRestaurantStateUpdate}
											dfieldname={'locationInfo.zipCode'}
										/>
									</div>
								</Grid>
								<Grid item xs={12} lg={6}>
									<FormControl className={classes.restaurantFieldInputItem} style={{width:'95%'}}>
										<InputLabel shrink htmlFor="timeZone">UTC Time Zone</InputLabel>
										<SelectMui
											disabled={!editing}
											label="UTC Time Zone"
											className={classes.muiSelect}
											value={restaurant2.utcTimeZone ? restaurant2.utcTimeZone : ""}
											onChange={(e) => handleRestaurantStateUpdate(e, 'utcTimeZone')}
										>
											{utctimezones.map((tz) => {
												return tz.name ? <MenuItem value={tz.name}>{tz.name + " - " + tz.offset/60}</MenuItem> : <></>
											})}
										</SelectMui>
									</FormControl>
								</Grid>
								<Grid item xs={12} lg={6}>
									<MuiPickersUtilsProvider  utils={DateFnsUtils}>
										<KeyboardTimePicker
											disabled={!editing}
											className={classes.restaurantFieldInputItem}
											margin="normal"
											id="time-picker"
											label="Report Cuttoff Time"
											InputLabelProps={{ shrink: true }}
											value={moment(restaurant2.reportCutoffTime, "HH:mm")}
											onChange={(val) => handleRestaurantStateUpdate(null, 'reportCutoffTime', moment(val).format('HH:mm'))}
											KeyboardButtonProps={{
												'aria-label': 'change time',
											}}
										/>
									</MuiPickersUtilsProvider>
								</Grid>
								<Grid item xs={12} lg={6}>
									<div>
										<DTextField
											disabled={!editing}
											type="text"
											style={{ width: '100%' }}
											label="Business Phone Number"
											value={restaurant2.locationInfo.phone}
											InputLabelProps={{ shrink: true }}
											onChange={handleRestaurantStateUpdate}
											dfieldname={'locationInfo.phone'}
										/>
									</div>
								</Grid>
								<Grid item xs={12} lg={6}>
									<div>
										<DTextField
											disabled={!editing}
											type="text"
											style={{ width: '100%' }}
											label="Website URL"
											value={restaurant2.locationInfo.website}
											InputLabelProps={{ shrink: true }}
											onChange={handleRestaurantStateUpdate}
											dfieldname={'locationInfo.website'}
										/>
									</div>
								</Grid>
								<Grid item xs={12} lg={6}>
									<DTextField
										disabled={!editing}
										type="text"
										value={restaurant2.locationInfo.facebookLink}
										InputLabelProps={{shrink: true}}
										onChange={handleRestaurantStateUpdate}
										dfieldname={"locationInfo.facebookLink"}
										label="Facebook Link"
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<DTextField
										disabled={!editing}
										type="text"
										value={restaurant2.locationInfo.twitterLink}
										InputLabelProps={{shrink: true}}
										onChange={handleRestaurantStateUpdate}
										dfieldname={"locationInfo.twitterLink"}
										label="Twitter Link"
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<DTextField
										disabled={!editing}
										type="text"
										value={restaurant2.locationInfo.instagramLink}
										InputLabelProps={{shrink: true}}
										onChange={handleRestaurantStateUpdate}
										dfieldname={"locationInfo.instagramLink"}
										label="Instagram Link"
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<DTextField
										disabled={!editing}
										type="text"
										value={restaurant2.locationInfo.linkedInLink}
										InputLabelProps={{shrink: true}}
										onChange={handleRestaurantStateUpdate}
										dfieldname={"locationInfo.linkedInLink"}
										label="LinkedIn Link"
									/>
								</Grid>
								<Grid item xs={12} lg={12}>
									<div>
										<DTextField
											disabled={!editing}
											type="text"
											style={{ width: '95%' }}
											label="Site Store Identifier"
											value={restaurant2.locationInfo.restaurantCode}
											InputLabelProps={{ shrink: true }}
											onChange={handleRestaurantStateUpdate}
											dfieldname={'locationInfo.restaurantCode'}
										/>
									</div>
								</Grid>
							</Grid>
						</Grid>
						{/* Primary Contact Info */}
						<Grid item lg={5}>
							<Typography
								style={{ marginTop: '2px' }}
								variant="body2"
								color="primary"
								className={classes.subheadingText}
							>
							Contact Info
							</Typography>
							<Grid container spacing={1}>


								<Grid item lg={12}>
									<DTextField
										disabled={!editing}
										style={{ width: '95%' }}
										label="Title"
										type="text"
										value={restaurant2.primaryContactInfo ? restaurant2.primaryContactInfo.title : ''}
										InputLabelProps={{ shrink: true }}
										onChange={handleRestaurantStateUpdate}
										dfieldname={'primaryContactInfo.title'}
									/>
								</Grid>

								<FormControl className={classes.restaurantFieldInputItem} >
									<InputLabel shrink htmlFor="primaryContactAccount">Primary Contact</InputLabel>
									<SelectMui
										disabled={!editing}
										className={classes.muiSelect}
										value={restaurant2.primaryContactAccount ? restaurant2.primaryContactAccount : ''}
										inputProps={{ id: 'primaryContactAccount' }}
										onChange={(e) => handleRestaurantStateUpdate(e, 'primaryContactAccount')}
									>
										{restaurantAccounts.map((rec) => <MenuItem value={rec._id}>{rec.fullName}</MenuItem>)}
									</SelectMui>
								</FormControl>




								<FormControl className={classes.restaurantFieldInputItem} >
									<InputLabel shrink htmlFor="agent">Agent</InputLabel>
									<SelectMui
										disabled={!editing}
										className={classes.muiSelect}
										value={restaurant2.agent ? restaurant2.agent : ''}
										inputProps={{ id: 'agent' }}
										onChange={(e) => {
											handleRestaurantStateUpdate(e, 'agent');
										}}
									>
										{agents.map((rec) => <MenuItem value={rec._id}>{rec.fullName}</MenuItem>)}
									</SelectMui>
								</FormControl>
								
								<FormControl className={classes.restaurantFieldInputItem} >
									<InputLabel shrink htmlFor="agent">Agency</InputLabel>
									<SelectMui
										disabled={!editing || restaurant2.agent}
										className={classes.muiSelect}
										value={restaurant2.agency ? restaurant2.agency : ''}
										inputProps={{ id: 'agency' }}
										onChange={(e) => handleRestaurantStateUpdate(e, 'agency')}
									>
										{agencies.map((rec) => <MenuItem value={rec._id}>{rec.name}</MenuItem>)}
									</SelectMui>
								</FormControl>

								
							</Grid>
						</Grid>
					</Grid>
				</DForm>
				{editing && 
					<Grid alignContent="center" container direction="row" justify="center">
						<DSubmitButton
							className={classes.submitButton}
							parentState={{ saveableObjects: { ...saveableObjects } }}
							collectionsToSave={[ 'Restaurant', 'ApiSite' ]}
							next={refreshRestaurant}
						/>
					</Grid>
				}
				<Grid item lg={12} >
                    {dialogContent}
                </Grid>
			</Grid>
		);
	};

	const newSchema = async (schemaType) => {
		let resp = await dashFetch(`${global.dashServerUrl}/dashboard/api/v1/dashboard/newSchemaInstance`, { schema: schemaType }, true);
		return resp.json.instance;
	};

	const addGateway = async () => {
		if (restaurant2.trainingMode) {
			if (!window.confirm('You are adding a gateway to a site that is in training mode. If you proceed Training Mode will be set to false')) { return }
			restaurant2.trainingMode = false
		}
		let gateway = await newSchema('Gateway');
		gateway.restaurant = restaurant2._id

		const restaurantClone = { ...restaurant2 };
		restaurantClone.gatewayIds.push(gateway._id);
		setRestaurant2(restaurantClone);
		const restaurantGatewaysClone = JSON.parse(JSON.stringify(restaurantGateways))
		restaurantGatewaysClone.push(gateway)
		setRestaurantGateways(restaurantGatewaysClone)
	};
	const removeGateway = async (id) => {
		const restaurantGatewaysClone = JSON.parse(JSON.stringify(restaurantGateways))
		let rgItem = restaurantGatewaysClone.find(rg=> rg._id == id).deleted = true
		setRestaurantGateways(restaurantGatewaysClone)
		addObjectToSave(rgItem);

		if (restaurant2.gatewayIds) {
			const restaurantClone = { ...restaurant2 };
			restaurantClone.gatewayIds = global.removeItemFromArray(restaurant2.gatewayIds, id)
			setRestaurant2(restaurantClone);
			addObjectToSave(restaurantClone);
		}
	}

	const renderGateways = () => {
		if (restaurantGateways !== null) {
			// let uniqueRootGateways = _.uniq(_.map(restaurant2.gateways, 'rootGateway'))

			// 
			// 
			// if (uniqueRootGateways) {
				return (
					restaurantGateways.length && restaurantGateways.filter(rg=> !rg.deleted).length > 0 && restaurantGateways.filter(rg=> !rg.deleted).map((gw, idx) => {
						if (gw && gw.rootGateway && gw.rootGateway.gatewayType) {
							return (
								<MenuItem value={gw._id}>
									{idx+1}. {gw.rootGateway.gatewayType.name} {gw.rootGateway && gw.rootGateway.gatewayType && gw.rootGateway.gatewayType.code.indexOf("spreedly") > -1 && gw.spreedlyGatewayType && gw.spreedlyGatewayType.name && ' > ' + gw.spreedlyGatewayType.name}
								</MenuItem>
							)
						}
					})
				)
			// }
		}
	}

	const showGatewaySetup = () => {
		const showPaymentTypeOptions = restaurantGateways.length > 0;
		let editingGw = editing && global.hasPermission('admin.restaurant.gateway.edit') 
		
		if (global.hasPermission('admin.restaurant.gateway')) {
			return (
				<DForm collection="Restaurant" style={{ width: '100%', marginTop: 8 }} dashkey={restaurant2._id}>
					<Grid container spacing={1} style={{ width: '100%'}}>
						<Grid item lg={12} style={{ paddingTop:2, paddingBottom:2}}>
							<Typography variant="h6" style={{color:'var(--text)'}} >
								Gateways
							</Typography>
						</Grid>
						<Grid item lg={5} style={{ paddingRight: '2.5%' }}>
								{editingGw && 
									<Button style={{ float: 'right' }} variant="contained" color="primary" onClick={addGateway}>
										<AddCircleOutline className={classes.addRestaurantIcon} />
										Add Gateway
									</Button>
								}
							{restaurantGateways && restaurantGateways.length && restaurantGateways.filter(rg=> !rg.deleted).length > 0 && restaurantGateways.filter(rg=> !rg.deleted).map((gw, idx) => {
								let isSpreedly    = global.f(gw, 'rootGateway.gatewayType.code', '') === "spreedly" || global.f(gw, 'rootGateway.gatewayType.code', '') === "spreedlyTest" 
								let isCardConnect = global.f(gw, 'rootGateway.gatewayType.code', '') == "cardConnect" || global.f(gw, 'rootGateway.gatewayType.code', '') == "cardConnectTest"
								let isHeartland   = global.f(gw, 'rootGateway.gatewayType.code', '') == "heartland"
								let isWorldpay    = global.f(gw, 'rootGateway.gatewayType.code', '') == "worldpay" || global.f(gw, 'rootGateway.gatewayType.code', '') == "worldpayTest"
								let isTsys        = global.f(gw, 'rootGateway.gatewayType.code', '') == "tsys" || global.f(gw, 'rootGateway.gatewayType.code', '') == "tsysTest"
								let isDatacap     = global.f(gw, 'rootGateway.gatewayType.code', '') == "datacap"
								let isAuthNet     = global.f(gw, 'rootGateway.gatewayType.code', '') == "authnet"
								let isShift4      = global.f(gw, 'rootGateway.gatewayType.code', '') == "shift4" || global.f(gw, 'rootGateway.gatewayType.code', '') == "shift4Test"
								let isPayeezy     = global.f(gw, 'rootGateway.gatewayType.code', '') == "payeezy" || global.f(gw, 'rootGateway.gatewayType.code', '') == "payeezyTest"
								let isFusebox     = global.f(gw, 'rootGateway.gatewayType.code', '') == "fusebox"
								return (
									<Paper>
										<DForm collection="Gateway" dashkey={gw._id}>
											<div style={{ marginTop: '3%', marginBottom: '5%', padding: '3%' }}>
												{editingGw && idx > 0 &&
													<Button onClick={(e) => removeGateway(gw._id)} style={{ float: 'right' }}>
														<Delete color="secondary" />
													</Button>
												}
												<div>
													<Typography variant="headline" style={{color:'var(--text)'}} >
														Gateway {idx + 1}{' '}
														<Typography variant="overline">ID: {gw._id}</Typography>
													</Typography>
												</div>
												<div style={{paddingBottom: 10}}>
													<Typography variant="caption"> Gateway is tested <DFlag checked={gw.testTransactionProcessedSuccessfully}/></Typography>
													{!gw.testTransactionProcessedSuccessfully && <>
														<br/>
														Test Amount (IN CENTS, {"<100"})<input style={{width: 50}} type="number" id="testTransactionPaymentAmt" min="1" max="100" />
														<br/>
														<Button className={classes.testGatewayButton} color="primary" variant="contained" disabled={!editingGw} onClick={() => {
															let elem = document.getElementById("testTransactionPaymentAmt")
															testTransactionOnGatewayApi(restaurant2._id, elem.value, "globalToken").then((res) => {
																if(res && res.createIFrame && res.ticketId){
																	openGatewayTestTransactionIframe(res.ticketId);
																}
															})
														}}>TEST Gateway Global Token</Button>
														<br/>
														{isHeartland && <>
															<Button className={classes.testGatewayButton} color="primary" variant="contained" disabled={!editingGw} onClick={() => {
																let elem = document.getElementById("testTransactionPaymentAmt")
																testTransactionOnGatewayApi(restaurant2._id, elem.value, "hardcodedCC").then((res) => {
																	if(res && res.createIFrame && res.ticketId){
																		openGatewayTestTransactionIframe(res.ticketId);
																	}
																})
															}}>TEST Gateway Hardcoded CC</Button>
															<br/>
														</>}
														<Button className={classes.testGatewayButton} color="primary" variant="contained" disabled={!editingGw || (editingGw && !restaurant2.restaurantEnabled)} onClick={() => {
															let elem = document.getElementById("testTransactionPaymentAmt")
															testTransactionOnGatewayApi(restaurant2._id, elem.value, "manualToken").then((res) => {
																if(res && res.createIFrame && res.ticketId){
																	openGatewayTestTransactionIframe(res.ticketId);
																}
															})
														}}>TEST Gateway Manual Token{!restaurant2.restaurantEnabled ? " (Set site to testing first)" : ""}</Button>
														<br/>
													</>}
													{gw.testTransactionProcessedSuccessfully && <>
														<br/>
														<Typography variant="caption"> Transaction Amount ${global.formatMoney(gw.testTransactionProcessedAmount)}</Typography>
														<br/>
													</>}
												</div>
												<div>
													<FormControl style={{ width: '100%'}}>
														<InputLabel shrink htmlFor="gatewayType">Master Gateway</InputLabel>
														<SelectMui
															disabled={!editingGw}
															label="Master Gateway"
															inputProps={{ id: 'gatewayType' }}
															className={classes.paymentGatewaySelect}
															value={gw.rootGateway}
															style={{width: '100%'}}
															onChange={(e) => {
																let v = e.target.value
																if (v && v.gatewayType.code !== "spreedly") {
																	if (gw.spreedlyGateway) {
																		handleRestaurantGatewayStateUpdate(gw, null, `spreedlyGatewayType`, null) // Tyler has feeling this is dumb and should be removed
																	}
																}
																handleRestaurantGatewayStateUpdate(gw, e, `rootGateway`)
																handleRestaurantGatewayStateUpdate(gw, null, `gatewayType`, v.gatewayType)
																handleRestaurantGatewayStateUpdate(gw, null, `credentials`, {})
															}}
														>
															{masterGateways.map((mgw) => {
																if (gw.rootGateway && mgw._id === gw.rootGateway._id) {
																	mgw = gw.rootGateway
																}
																return (
																	<MenuItem value={mgw}>{mgw.gatewayType.name}</MenuItem>
																)
															})}
														</SelectMui>
													</FormControl>
												</div>
												{isSpreedly /* Spreedly gateway types */ && (
														<FormControl
															className={classes.paymentGatewaySelect}
															style={{width: '100%'}}
														>
														<InputLabel shrink htmlFor="spreedlyGatewayType">Spreedly Gateway Type</InputLabel>
															<SelectMui
																disabled={!editingGw}
																style={{width: '100%'}}
																onChange={(e) => {
																	handleRestaurantGatewayStateUpdate(//TODO TEST
																		gw,
																		e,
																		`spreedlyGatewayType`
																	)
																	handleRestaurantGatewayStateUpdate(gw, null, `credentials`, {})
																}}
																inputProps={{ id: 'spreedlyGatewayType' }}
																value={gw.spreedlyGatewayType}
															>
																{spreedlyGatewayOptions.map((gwo) => {
																	if (gw.spreedlyGatewayType && gwo._id === gw.spreedlyGatewayType._id) {
																		gwo = gw.spreedlyGatewayType
																	}
																	return (
																		<MenuItem value={gwo}>{gwo.name}</MenuItem>
																	)
																})}
															</SelectMui>
														</FormControl>
												)}
												{gw.spreedlyGatewayType && gw.spreedlyGatewayType.auth_modes && gw.spreedlyGatewayType.auth_modes.length > 0 /* Spreedly gateway type required fields */ &&
													gw.spreedlyGatewayType.auth_modes[0].credentials.map((cred) => (
														<div>
															<DTextField
																disabled={!editingGw}
																label={cred.label}
																type={editingGw ? '' : cred.label.toUpperCase().indexOf('PASSWORD') > -1 ? 'password' : ''}
																value={
																	gw.credentials &&
																	gw.credentials[cred.name] ? (
																		gw.credentials[cred.name]
																	) : (
																		''
																	)
																}
																onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `credentials[${cred.name}]`)}
															/>
														</div>
													))}
												{gw.spreedlyGatewayType && gw.spreedlyGatewayType.gateway_type == "first_pay" /* Spreedly first_pay gateway_specific_fields optional fields */ &&
													<div>
														<DTextField
															disabled={!editingGw}
															label={'processor_id'}
															type={''}
															value={
																global.f(gw, "spreedlyGatewaySpecificFields.first_pay.processor_id") ? global.f(gw, "spreedlyGatewaySpecificFields.first_pay.processor_id") : ''
															}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `spreedlyGatewaySpecificFields.first_pay.processor_id`)}
														/>
													</div>
												}
												<div>
													{gw.spreedlyGatewayToken && 
														<>
															<Typography variant="caption" style={{color:'var(--text)'}} >
																Spreedly GW Token: {gw.spreedlyGatewayToken}
															</Typography>
															<br/>
														</>
													}													
												</div>
												{isCardConnect /* Card Connect registration fields */ && (
													<div>
														<DTextField
															disabled={!editingGw}
															label="Merchant ID"
															value={gw.merchantId}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `merchantId`)}
														/>
														<DTextField
															disabled={!editingGw}
															label="Username"
															value={
																gw.credentials &&
																gw.credentials.username ? (
																	gw.credentials.username
																) : (
																	''
																)
															}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `credentials.username`)}
														/>
														<DTextField
															disabled={!editingGw}
															label="Password"
															type={editingGw ? '' : 'password'}
															value={
																gw.credentials &&
																gw.credentials.password ? (
																	gw.credentials.password
																) : (
																	''
																)
															}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `credentials.password`)}
														/>
													</div>
												)}
												{isHeartland && (
													<div>
														<DTextField
															disabled={!editingGw}
															label="Merchant ID"
															value={gw.merchantId}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `merchantId`)}
														/>
														<DTextField
															disabled={!editingGw}
															label="Secret API Key"
															value={global.f(gw, 'credentials.secretApiKey', '')}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `credentials.secretApiKey`)}
														/>
														<DTextField
															disabled={!editingGw}
															label="Public API Key"
															value={global.f(gw, 'heartlandPublicKey', '')}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `heartlandPublicKey`)}
														/>
													</div>
												)}
												{isTsys && (
													<div>
														<DTextField
															disabled={!editingGw}
															label="Username"
															value={gw.credentials.username}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `credentials.username`)}
														/>
														<DTextField
															disabled={!editingGw}
															label="Password"
															value={gw.credentials.password}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `credentials.password`)}
														/>
														<DTextField
															disabled={!editingGw}
															label="Merchant ID"
															value={gw.credentials.merchantId}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `credentials.merchantId`)}
														/>
														<DTextField
															disabled={!editingGw}
															label="Device ID"
															value={gw.credentials.deviceId}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `credentials.deviceId`)}
														/>
													</div>
												)}
												{isWorldpay && (
													<div>
														<DTextField
															disabled={!editingGw}
															label="Account ID"
															value={gw.credentials.accountId}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `credentials.accountId`)}
														/>
														<DTextField
															disabled={!editingGw}
															label="Account token"
															value={gw.credentials.accountToken}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `credentials.accountToken`)}
														/>
														<DTextField
															disabled={!editingGw}
															label="Acceptor ID"
															value={gw.credentials.acceptorId}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `credentials.acceptorId`)}
														/>
														<DTextField
															disabled={!editingGw}
															label="Application ID"
															value={gw.credentials.applicationId}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `credentials.applicationId`)}
														/>
													</div>
												)}
												{isDatacap && (
													<div>
														<DTextField
															disabled={!editingGw}
															label="Ecommerce MID"
															value={gw.credentials.merchantId}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `credentials.merchantId`)}
														/>
													</div>
												)}
												{isAuthNet && (
													<div>
														<DTextField
															disabled={!editingGw}
															label="API Login ID"
															value={global.f(gw, 'credentials.apiLoginKey', '')}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `credentials.apiLoginKey`)}
														/>
														<DTextField
															disabled={!editingGw}
															label="Transaction Key"
															value={global.f(gw, 'credentials.transactionKey', '')}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `credentials.transactionKey`)}
														/>
													</div>
												)}
												{isShift4 && (
													<div style={{marginTop:16}}>
														<DTextField
															disabled={!editingGw}
															label="Auth Token"
															value={gw.credentials.authToken}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `credentials.authToken`)}
														/>
														<DTextField
															disabled={!editingGw}
															label="Access Token"
															value={gw.credentials.accessToken}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `credentials.accessToken`)}
														/>
                                                        {/* will probably need more fields  */}
													</div>
												)}
												{isPayeezy && (
													<div>
														<DTextField
															disabled={!editingGw}
															label="Merchant Token"
															value={global.f(gw, 'credentials.merchantToken', '')}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `credentials.merchantToken`)}
														/>
														<DTextField
															disabled={!editingGw}
															label="Merchant ID"
															value={gw.merchantId}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `merchantId`)}
														/>
													</div>
												)}
												{isFusebox && (
													<div>
														<Typography variant="subtitle1" style={{color:'var(--text)'}}>Converge Lightbox (Authorization) Not Required unless provided</Typography>
														<DTextField
															disabled={!editingGw}
															label="Account ID/Vendor ID"
															value={gw.credentials.vendorId}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `credentials.vendorId`)}
														/>
														<DTextField
															disabled={!editingGw}
															label="User ID"
															value={gw.credentials.userId}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `credentials.userId`)}
														/>
														<DTextField
															disabled={!editingGw}
															label="pin"
															value={gw.credentials.pin}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `credentials.pin`)}
														/>
														<Typography variant="subtitle1" style={{color:'var(--text)'}}>Converge Lightbox (Authorization)</Typography>
														<DTextField
															disabled={!editingGw}
															label="Merchant ID"
															value={gw.merchantId}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `merchantId`)}
														/>
														<DTextField
															disabled={!editingGw}
															label="Location ID"
															value={gw.locationId}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `locationId`)}
														/>
														<DTextField
															disabled={!editingGw}
															label="Chain Code"
															value={gw.chainCode}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `chainCode`)}
														/>
														<DTextField
															disabled={!editingGw}
															label="Terminal ID"
															value={gw.terminalId}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `terminalId`)}
														/>
														<Typography variant="subtitle1" style={{color:'var(--text)'}}>Fusebox (Capture)</Typography>
														<DTextField
															disabled={!editingGw}
															label="Merchant ID"
															value={gw.merchantIdCapture}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `merchantIdCapture`)}
														/>
														<DTextField
															disabled={!editingGw}
															label="Location ID"
															value={gw.locationIdCapture}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `locationIdCapture`)}
														/>
														<DTextField
															disabled={!editingGw}
															label="Chain Code"
															value={gw.chainCodeCapture}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `chainCodeCapture`)}
														/>
														<DTextField
															disabled={!editingGw}
															label="Terminal ID"
															value={gw.terminalIdCapture}
															onChange={(e) => handleRestaurantGatewayStateUpdate(gw, e, `terminalIdCapture`)}
														/>
													</div>
												)}												
											</div>
										</DForm>
										<Modal
											aria-labelledby="simple-modal-title"
											aria-describedby="simple-modal-description"
											open={gatewayTestTransactionIframe}
											onClose={closeGatewayTestTransactionIframe}
										>
											<div style={modalStyle} className={classes.paper}>
												<div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
													<iframe id='iFramePreview' src={'https://' + (!global.isProd ? 'stage.' : '') + 'dnow.app/?t=' + iframeTicketId} height={props.height ? props.height : 600} width={props.width ? props.width : 300}></iframe>
												</div>
											</div>
										</Modal>
									</Paper>
								);
							})}
						</Grid>
						{showPaymentTypeOptions && (
							<Grid item lg={6}>
								<Typography
									variant="body2"
									color="primary"
									className={classes.subheadingText}
									style={{ marginLeft: '1.5%', marginBottom: '1.5%' }}
								>
									Payment Types
								</Typography>
								<div>
									<FormControlLabel
										control={
											<DSwitch
												disabled={!editingGw}
												checked={!!restaurant2.paymentMethods.creditCard.enabled}
												value={restaurant2.paymentMethods.creditCard.enabled}
												dfieldname={'paymentMethods.creditCard.enabled'}
												onChange={handleRestaurantToggleStateUpdate}
											/>
										}
										label="Credit Cards"
									/>
									<div style={{ paddingLeft: '8%', minHeight: 50 }}>
										{restaurant2.paymentMethods.creditCard.enabled && 
											<SelectMui
												disabled={!editingGw}
												onChange={(e) =>
													handleRestaurantStateUpdate(e, `paymentMethods.creditCard.gatewayId`)}
												value={restaurant2.paymentMethods.creditCard.gatewayId ? restaurant2.paymentMethods.creditCard.gatewayId : restaurant2.paymentMethods.creditCard.gatewayData ? restaurant2.paymentMethods.creditCard.gatewayData.gatewayId : ''}
												className={classes.paymentGatewaySelect}
											>

												{renderGateways()}

											</SelectMui>
										}
									</div>
								</div>
								<div>
									<FormControlLabel
										control={
											<DSwitch
												disabled={!editingGw}
												checked={!!restaurant2.paymentMethods.applePay.enabled}
												value={restaurant2.paymentMethods.applePay.enabled}
												dfieldname={'paymentMethods.applePay.enabled'}
												onChange={handleRestaurantToggleStateUpdate}
											/>
										}
										label="Apple Pay"
									/>
									<div style={{ paddingLeft: '8%', minHeight: 50 }}>
										{restaurant2.paymentMethods.applePay.enabled && 
											<SelectMui
												disabled={!editingGw}
												className={classes.paymentGatewaySelect}
												onChange={(e) =>
													handleRestaurantStateUpdate(e, `paymentMethods.applePay.gatewayId`)}
												value={restaurant2.paymentMethods.applePay.gatewayId ? restaurant2.paymentMethods.applePay.gatewayId : restaurant2.paymentMethods.applePay.gatewayData ? restaurant2.paymentMethods.applePay.gatewayData.gatewayId : ''}
											>

												{renderGateways()}

											</SelectMui>
										}
									</div>
								</div>
								<div>
									<FormControlLabel
										control={
											<DSwitch
												disabled={!editingGw}
												checked={!!restaurant2.paymentMethods.googlePay.enabled}
												value={restaurant2.paymentMethods.googlePay.enabled}
												dfieldname={'paymentMethods.googlePay.enabled'}
												onChange={handleRestaurantToggleStateUpdate}
											/>
										}
										label="Google Pay"
									/>
									<div style={{ paddingLeft: '8%', minHeight: 50 }}>
										{restaurant2.paymentMethods.googlePay.enabled && 
											<SelectMui
												disabled={!editingGw}
												className={classes.paymentGatewaySelect}
												onChange={(e) =>
													handleRestaurantStateUpdate(e, `paymentMethods.googlePay.gatewayId`)}
												value={restaurant2.paymentMethods.googlePay.gatewayId ? restaurant2.paymentMethods.googlePay.gatewayId : restaurant2.paymentMethods.googlePay.gatewayData ? restaurant2.paymentMethods.googlePay.gatewayData.gatewayId : ''}
											>
												{renderGateways()}

											</SelectMui>
										}
									</div>
								</div>
								<div>
									<FormControlLabel
										control={
											<DSwitch
												disabled={!editingGw}
												checked={!!restaurant2.paymentMethods.ach.enabled}
												value={restaurant2.paymentMethods.ach.enabled}
												dfieldname={'paymentMethods.ach.enabled'}
												onChange={handleRestaurantToggleStateUpdate}
											/>
										}
										label="ACH"
									/>
									<div style={{ paddingLeft: '8%', minHeight: 50 }}>
										{restaurant2.paymentMethods.ach.enabled && 
											<SelectMui
												disabled={!editingGw}
												className={classes.paymentGatewaySelect}
												onChange={(e) =>
													handleRestaurantStateUpdate(e, `paymentMethods.ach.gatewayId`)}
												value={restaurant2.paymentMethods.ach.gatewayId ? restaurant2.paymentMethods.ach.gatewayId : restaurant2.paymentMethods.ach.gatewayData ? restaurant2.paymentMethods.ach.gatewayData.gatewayId : ''}
											>
												{renderGateways()}

											</SelectMui>
										}
									</div>
								</div>
								<div>
									<div style={{ minHeight: 50 }}>
										<FormControl component="fieldset" style={{paddingTop: 4, paddingLeft: 5}}>
											<input type='file' accept={'.csv'} 
                                                disabled={!editingGw} 
												onChange={(e) => {
													if(e.target.files && e.target.files.length > 0){
														if(e.target.files[0].size <= (1024*1024*10) ){ //10MB
															setFile(e.target.files[0])
														} else {
															global.showNotification('The file size is too large, 10MB limit', 'error')
														}
													}
												}}
											/>
										</FormControl><br></br><br></br>
										<Button
											align="right"
											size="small"
											variant="contained"
											color="primary"
                                            disabled={!editingGw} 
											onClick={upload}
										>Bulk Restaurant Upload
										</Button><br></br><br></br>
										<Button 
											variant="contained" 
											color="primary" 
                                            disabled={!editingGw} 
											onClick={ () => {
													downloadRestaurantGroupExport(restaurant._id) 
												}
											}>
											<GetAppIcon style={{paddingRight: '10px'}}></GetAppIcon>
											Restaurant Group Export
										</Button>
									</div>
								</div>
							</Grid>
						)}
					</Grid>
					{editingGw && 
						<Grid alignContent="center" container direction="row" justify="center">
							<DSubmitButton
								className={classes.submitButton}
								parentState={{ saveableObjects: { ...saveableObjects } }}
								collectionsToSave={[ 'Restaurant', 'Gateway']}
								next={validateGatewayToken}
							/>
						</Grid>
					}
				</DForm>
			);
		}
	};

	const showSelectedStation = () => {
		return (
			<Grid item container xs={12} style={{backgroundColor:'var(--background)', height:`calc(100% )`}}>
				{global.hasPermission("admin.restaurant.stations.edit") &&<>
					{selectedStation._id && selectedStation._id == "All" && 
						<Grid item xs={12} sm={6} style={{paddingTop:10, paddingBottom:10, paddingLeft:10, paddingRight:0}}>
							<DForm collection="Restaurant" dashkey={restaurant2._id} style={{height:'100%'}}>
								<Paper style={{padding:10, height:'100%'}}>
									<Typography variant="h6" style={{height:'40px'}}>
										Settings
									</Typography>
									<Grid container style={{height:'calc(100% - 40px)'}}>
										<Grid item container xs={8}>
											<Grid item xs={12} sm={6}>


												<FormControl className={classes.restaurantFieldInputItem} >
													<InputLabel shrink htmlFor="defaultRestaurantStation">Default Station</InputLabel>
													<SelectMui
														disabled={!editing}
														label="Default Station"
														className={classes.muiSelect}
														value={restaurant2.defaultRestaurantStation}
														inputProps={{id:'defaultRestaurantStation'}}
														onChange={(e) => handleRestaurantStateUpdate(e, 'defaultRestaurantStation')}
													>
														{stationsSetuped.map((stat) => <MenuItem value={stat._id}>{stat.stationName}</MenuItem>)}
													</SelectMui>
												</FormControl>
											</Grid>
											<Grid item xs={12} sm={6}>
												<DTextField
													label="Default Port"
													disabled={!editing}
													style={{ width: '100%' }}
													dfieldname={'defaultRoutedPrinterPort'}
													onChange={handleRestaurantStateUpdate}
													value={restaurant2.defaultRoutedPrinterPort}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<FormControl className={classes.restaurantFieldInputItem} >
													<InputLabel shrink htmlFor="defaultToGoRestaurantStation">Default To Go Station</InputLabel>
													<SelectMui
														disabled={!editing}
														label="Default To Go Station"
														className={classes.muiSelect}
														value={restaurant2.defaultToGoRestaurantStation}
														inputProps={{id:'defaultToGoRestaurantStation'}}
														onChange={(e) => handleRestaurantStateUpdate(e, 'defaultToGoRestaurantStation')}
													>
														{stations.map((stat) => <MenuItem value={stat._id}>{stat.stationName}</MenuItem>)}
													</SelectMui>
												</FormControl>
											</Grid>
											<Grid item xs={12} sm={6}>
												<DTextField
													label="Default To Go Port"
													disabled={!editing}
													style={{ width: '100%' }}
													dfieldname={'defaultToGoRoutedPrinterPort'}
													onChange={handleRestaurantStateUpdate}
													value={restaurant2.defaultToGoRoutedPrinterPort}
												/>
											</Grid>
										</Grid>
										<Grid item container xs={12} sm={4} >
											<Grid item xs={8} style={{alignSelf:'center'}}>
												Qr Code Enabled
											</Grid>
											<Grid item xs={4} style={{alignSelf:'center'}}>
												<DSwitch
													checked={restaurant2 && restaurant2.qrCodeEnabled ? restaurant2.qrCodeEnabled: ''}
													value={restaurant2 && restaurant2.qrCodeEnabled ? restaurant2.qrCodeEnabled: ''}
													onChange={handleRestaurantToggleStateUpdate}
													disabled={!editing}
													dfieldname={'qrCodeEnabled'}
												/>
											</Grid> 
											<Grid item xs={8} style={{alignSelf:'center'}}>
												Bypass All
											</Grid>                                                           
											<Grid item xs={4} style={{alignSelf:'center'}}>
												<DSwitch
													disabled={!editing}
													checked={restaurant2 && restaurant2.bypassAll ? restaurant2.bypassAll: ''}
													value={restaurant2 && restaurant2.bypassAll ? restaurant2.bypassAll: ''}
													onChange={handleRestaurantToggleStateUpdate}
													dfieldname={'bypassAll'}
												/>
											</Grid>                                                        
											<Grid item xs={8} style={{alignSelf:'center'}}>
												Debug Printing
											</Grid>                                                           
											<Grid item xs={4} style={{alignSelf:'center'}}>
												<DSwitch
													disabled={!editing}
													checked={restaurant2 && restaurant2.debugPrinting ? restaurant2.debugPrinting: ''}
													value={restaurant2 && restaurant2.debugPrinting ? restaurant2.debugPrinting: ''}
													onChange={handleRestaurantToggleStateUpdate}
													dfieldname={'debugPrinting'}
												/>
											</Grid>                                                        
											<Grid item xs={8} style={{alignSelf:'center'}}>
												Back Office Enabled
											</Grid>                                                           
											<Grid item xs={4} style={{alignSelf:'center'}}>
												<DSwitch
													disabled={!editing}
													checked={restaurant2 && restaurant2.dashNowBackOfficeEnabled ? restaurant2.dashNowBackOfficeEnabled: ''}
													value={restaurant2 && restaurant2.dashNowBackOfficeEnabled ? restaurant2.dashNowBackOfficeEnabled: ''}
													onChange={handleRestaurantToggleStateUpdate}
													dfieldname={'dashNowBackOfficeEnabled'}
												/>
											</Grid>                                                        
										</Grid>
										<Grid item xs={12} alignContent="center" container justify="center">
											<DSubmitButton
												className={classes.submitButton}
												disabled={!editing}
												parentState={{ saveableObjects: { ...saveableObjects } }}
												collectionsToSave={[ 'Restaurant' ]}
												next={refreshRestaurant}
											/>
										</Grid>
									</Grid>
								</Paper>
							</DForm>
						</Grid>
					}
					{global.hasPermission('admin.restaurant.gateway.edit') &&
						<Grid item xs={12} sm={3} style={{padding:10}}>
							<Paper style={{padding:10,  height:'100%'}}>
								<Typography variant="h6">
									Station Overrides
								</Typography>
								<Grid container item xs justify="center" style={{height:'100%'}}>
									<Grid item container  xs={12} spacing={1}>
										<Grid item xs={12} sm={6}>
											<TextField style={{width:'100%', margin:3}} label="Key" value={stationSettings.key} onChange={(e)=> setStationSettings({...stationSettings, key:e.target.value})}></TextField>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField style={{width:'100%', margin:3}} label="Value" value={stationSettings.value} onChange={(e)=> setStationSettings({...stationSettings, value:e.target.value})}></TextField>
										</Grid>
									</Grid>
									<Grid item container xs={12} justify="center">
										<Grid item xs={12} style={{textAlign:"center", alignSelf:'center'}}>
											Is Setup <Switch color="primary" checked={stationSettings.isSetup} value={stationSettings.isSetup} onChange={(e)=> setStationSettings({...stationSettings, isSetup:!stationSettings.isSetup})}></Switch>
										</Grid>
									</Grid>
									<Grid item xs={12} justify="center" alignItems="flex-end" style={{textAlign:'center'}}>
										<Button variant="contained" color="primary" style={{display:'inline-block', margin:'16px 0px'}} disabled={!editing} onClick={sendInStationSetting}>
											Send Setting
										</Button>
									</Grid>

								</Grid>
							</Paper>
						</Grid>
					}
					{global.hasPermission('admin.restaurant.gateway.edit') && selectedStation && !!selectedStation.backOffice &&
						<Grid item xs={12} sm={6} style={{padding:10}}>
							<Paper style={{padding:10,  height:'100%'}}>
								<Typography variant="h6">
									Back Office Debugging
								</Typography>
								<Grid container item xs justify="center" style={{height:'100%'}}>
									<Grid item xs={12} justify="center" alignItems="flex-end" style={{textAlign:'center'}}>
										<Accordion>
											<AccordionSummary expandIcon={<ExpandMoreIcon />}>
												<Typography className={classes.heading}>Fetch DB Files</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<Grid item xs={12}>
													<TextField style={{width:'100%', margin:3}} label="DateString (MMDDYY - blank is today)" value={dbQueryParams.dateString} onChange={(e)=> setDbQueryParams({...dbQueryParams, dateString:e.target.value})}></TextField>
													<Button variant="contained" color="primary" style={{display:'inline-block', margin:'16px 0px'}} 
														disabled={!editing} onClick={fetchDBFiles}>
														Fetch DB Files
													</Button>
												</Grid>
											</AccordionDetails>
										</Accordion>
										<Accordion>
											<AccordionSummary expandIcon={<ExpandMoreIcon />}>
												<Typography className={classes.heading}>Fetch Micros ISL Files (QR codes)</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<Grid item xs={12}>
													<Button variant="contained" color="primary" style={{display:'inline-block', margin:'16px 0px'}} 
														disabled={!editing} onClick={fetchMicrosISLFiles}>
														Fetch Micros ISL Files (QR codes)
													</Button>
												</Grid>
											</AccordionDetails>
										</Accordion>
										<Accordion>
											<AccordionSummary expandIcon={<ExpandMoreIcon />}>
												<Typography className={classes.heading}>Fetch DB Query</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<Grid item xs={12}>
													<TextField style={{width:'100%', margin:3}} label="Query" value={dbQueryParams.query} onChange={(e)=> setDbQueryParams({...dbQueryParams, query:e.target.value})}></TextField>
													<FormControl style={{margin:3, width: '100%', height: '48px'}} >
														<InputLabel id="pre-made-queries-label">Pre Made Queries</InputLabel>
														<SelectMui
															disabled={!editing}
															labelId="pre-made-queries-label"
															className={classes.muiSelect}
															value={dbQueryParams.preMadeQuery}
															defaultValue = ""
															onChange={(e) => setDbQueryParams({...dbQueryParams, preMadeQuery:e.target.value})}
														>
															<MenuItem value="">-</MenuItem>
															<MenuItem value="pixelPointChecks">pixelPointChecks - TransactionId required</MenuItem>
															<MenuItem value="microsChecks">microsChecks - CheckNumber required</MenuItem>
															<MenuItem value="microsTickets">microsTickets - PosTicketId required</MenuItem>
															<MenuItem value="microsItems">microsItems - PosTicketId required</MenuItem>
														</SelectMui>
													</FormControl>
													<TextField style={{width:'100%', margin:3}} label="TransactionId" value={dbQueryParams.transactionId} onChange={(e)=> setDbQueryParams({...dbQueryParams, transactionId:e.target.value})}></TextField>
													<TextField style={{width:'100%', margin:3}} label="CheckNumber" value={dbQueryParams.checkNumber} onChange={(e)=> setDbQueryParams({...dbQueryParams, checkNumber:e.target.value})}></TextField>
													<TextField style={{width:'100%', margin:3}} label="PosTicketId" value={dbQueryParams.posTicketId} onChange={(e)=> setDbQueryParams({...dbQueryParams, posTicketId:e.target.value})}></TextField>
													<Button variant="contained" color="primary" style={{display:'inline-block', margin:'16px 0px'}} 
														disabled={!editing} onClick={fetchDBQuery}>
														Fetch DB Query
													</Button>
												</Grid>
											</AccordionDetails>
										</Accordion>
										<Accordion>
											<AccordionSummary expandIcon={<ExpandMoreIcon />}>
												<Typography className={classes.heading}>Delete Local SQL DB</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<Grid item xs={12}>
													<Button variant="contained" color="primary" style={{display:'inline-block', margin:'16px 0px'}} 
														disabled={!editing} onClick={deleteLocalSqlDB}>
														Delete Local SQL DB
													</Button>
												</Grid>
											</AccordionDetails>
										</Accordion>
									</Grid>

								</Grid>
							</Paper>
						</Grid>
					}
				</>}
				{global.hasPermission('admin.restaurant.stations.edit') &&
					<Grid xs={12} item container justify="space-between" style={{backgroundColor:'var(--background)', borderTop:'1px solid rgb(204, 204, 204)', padding:'6px'}}>
						<Grid item>
							<Button variant="contained" color="primary" style={{margin:'0px 6px'}} onClick={requestLogs}>
								FETCH LOGS
							</Button>
							<Button variant="contained" color="primary" style={{margin:'0px 6px'}} onClick={forceRegisterStation}>
								YOU THERE?
							</Button>
							<Button disabled={!selectedStation || selectedStation._id == 'All'} variant="contained" color="primary" style={{margin:'0px 6px'}} onClick={streamLogs}>
								WHATS GOING ON
							</Button>
						</Grid>
						<Grid item>
							<Button variant="contained" color="secondary" style={{margin:'0px 6px'}} onClick={updateRemoteDesktop}>
								REMOTE DESKTOP
							</Button>
							<Button variant="contained" color="primary" style={{margin:'0px 6px'}} onClick={forceStationUpdate}>
								UPDATE
							</Button>
							<Button variant="contained" color="primary" style={{margin:'0px 6px'}} onClick={stationRestart}>
								RESTART
							</Button>
						</Grid>
					</Grid>
				}
				<div>{dialogContent}</div>
			</Grid>
		)
	}

	const sortStations = (prop = '') => {
		let stationsCopy = [...stationsFiltered]
		stationsCopy.sortByProperty(prop)
		if (global._.isEqual(stations, stationsCopy)){
			stationsCopy.sortByProperty(prop, false)
		}
		setStationsFiltered(stationsCopy)
	}

	const showStations = () => {
        console.log('restaurant', restaurant)
        console.log('restaurant2', restaurant2)
		return (
			<Grid container item spacing={1}>
				<Grid item xs={12}>
					<Grid container justify="space-between">
						<Typography variant="h6" style={{color:'var(--text)'}} >
							Stations
						</Typography>
						<Grid item style={{ display: 'flex', alignItems: 'center', paddingRight:10}}>
							<Typography style={{color:"#373737"}}>
								Show All
							</Typography>
							<Switch style={{float:'right'}} color="primary" value={showAllStations} checked={showAllStations} onChange={() => setShowAllStations(!showAllStations)}>
							</Switch>
						</Grid>
					</Grid>

					<Grid container style={{ height:"100%", padding: 10}}>
						<Grid container style={{ width:'100%', maxHeight:'100%', height: 'calc(100% - 50px)', overflow: 'none'}} >


							<Grid item xs={12} container direction='row' style={{ width: '100%', height: smallScreen ? 'auto' : 'calc(100% - 50px)', minHeight: smallScreen ? 'calc(100% - 50px)' : '0', margin: 0 }}>
								<Grid item xs={12} style={{ height: smallScreen ? 'auto' : '100%', width: '100%', padding: 5}} >
									<Paper>
										<TableContainer style={{height: '100%' }}>
											<Table size="small" aria-label="a dense sticky table" stickyHeader>
												<TableHead>
													<TableRow>
														<TableCell onClick={() => sortStations('stationName')} style={{cursor:'pointer', backgroundColor: 'var(--white)'}}>Name</TableCell>
														<TableCell onClick={() => sortStations('_id')} style={{cursor:'pointer', backgroundColor: 'var(--white)'}}>ID</TableCell>
														<TableCell onClick={() => sortStations('version')} style={{cursor:'pointer', backgroundColor: 'var(--white)'}}>Version</TableCell>
														<TableCell onClick={() => sortStations('createdAt')} style={{cursor:'pointer', backgroundColor: 'var(--white)'}}>Created</TableCell>
														<TableCell onClick={() => sortStations('updatedAt')} style={{cursor:'pointer', backgroundColor: 'var(--white)'}}>Updated</TableCell>
														<TableCell style={{backgroundColor: 'var(--white)'}}>Computer Name</TableCell>
														{/* <TableCell style={{backgroundColor: 'var(--white)'}}>Station</TableCell> */}
														<TableCell align="right" style={{backgroundColor: 'var(--white)'}}>Is Setup</TableCell>
														<TableCell align="right" style={{backgroundColor: 'var(--white)'}}>Back Office</TableCell>
													</TableRow>
												</TableHead>

												{stationsFiltered && stationsFiltered.map(station => {
													return (
														<>
															<TableRow id={station._id} selected={selectedStation && station._id == selectedStation._id} onClick={() => {setSelectedStation(station)}} style={{ cursor: 'pointer' }}>
																<TableCell>{station.stationName}</TableCell>
																<TableCell>{station._id}</TableCell>
																<TableCell>{station.version}</TableCell>
																<TableCell>{station._id != 'All' ? moment(station.createdAt).format("L"): ''}</TableCell>
																<TableCell>{station._id != 'All' ? moment(station.updatedAt).format("M/D h:mma"): ''}</TableCell>
																<TableCell>{global.f(station, "os.computerName", "")}</TableCell>
																{/* <TableCell style={{minWidth: '200px'}}>{payment.check && payment.check.serverIdentifier ? payment.check.serverIdentifier : ''}</TableCell> */}
																<TableCell align="right">{station._id != 'All' && <DFlag checked={!!station.isSetup}/>}</TableCell>
																<TableCell align="right">{station._id != 'All' && <DFlag checked={!!station.backOffice}/>}</TableCell>
															</TableRow>
															{ selectedStation && station._id == selectedStation._id &&
																<TableRow>
																	<TableCell padding={'none'} colSpan={12}>
																		<Collapse in={true}>
																			<Grid item xs={12}>
																				{showSelectedStation()}
																			</Grid>
																		</Collapse>
																	</TableCell>
																</TableRow>
															}
														</>
													)
												})}
											</Table>
										</TableContainer>
									</Paper>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

				</Grid>
			</Grid>
		);
	};

	const requestLogs = async () => {
		let restaurantStations = (selectedStation._id == "All") ? stations.filter(s=> s._id != "All").map(s=> s._id) : [selectedStation._id]
		let success = await requestLogsApi(restaurant2._id, restaurantStations)
		setLoading(true)
		if (success) {
			global.showNotification('Logs requested', 'success')
		}
		setLoading(false)
	}
	
	const updateRemoteDesktop = async () => {
		let restaurantStations = (selectedStation._id == "All") ? stations.filter(s=> s._id != "All").map(s=> s._id) : [selectedStation._id]
		let success = await updateRemoteDesktopApi(restaurant2._id, restaurantStations)
		setLoading(true)
		if (success) {
			global.showNotification('Remote Desktop Updated', 'success')
		}
		setLoading(false)
	}

	const forceStationUpdate = async () => {
		let restaurantStations = (selectedStation._id == "All") ? stations.filter(s=> s._id != "All").map(s=> s._id) : [selectedStation._id]
		let success = await forceUpdateApi(restaurant2._id, restaurantStations)
		setLoading(true)
		if (success) {
			global.showNotification(`Station${restaurantStations && restaurantStations.length > 1 ? '(s)' : ''} updated`, 'success')
		}
		setLoading(false)
	}

	const stationRestart = async () => {
		let restaurantStations = (selectedStation._id == "All") ? stations.filter(s=> s._id != "All").map(s=> s._id) : [selectedStation._id]
		setLoading(true)
		let success = await stationRestartApi(restaurant2._id, restaurantStations)
		if (success) {
			global.showNotification(`Station${restaurantStations && restaurantStations.length > 1 ? '(s)' : ''} restarted`, 'success')
		}
		setLoading(false)
	}
	
	const sendInStationSetting = async () => {
		let restaurantStations = (selectedStation._id == "All") ? stations.filter(s=> s._id != "All").map(s=> s._id) : [selectedStation._id]
		setLoading(true)
		let success = await sendInStationSettingApi(restaurant2._id, restaurantStations, stationSettings.key, stationSettings.value, stationSettings.isSetup)
		if (success) {
			global.showNotification('Settings sent', 'success')
		}
		setLoading(false)
	}

	const fetchDBFiles = async () => {
		let restaurantStations = (selectedStation._id == "All") ? stations.filter(s=> s._id != "All").map(s=> s._id) : [selectedStation._id]
		setLoading(true)
		let success = await fetchDBFilesApi(dbQueryParams.dateString, restaurant2._id, restaurantStations)
		if (success) {
			global.showNotification('Fetched DB Files to s3', 'success')
		}
		setLoading(false)
	}

	const fetchMicrosISLFiles = async () => {
		let restaurantStations = (selectedStation._id == "All") ? stations.filter(s=> s._id != "All").map(s=> s._id) : [selectedStation._id]
		setLoading(true)
		let success = await fetchMicrosISLFilesApi(restaurant2._id, restaurantStations)
		if (success) {
			global.showNotification('Fetched Micros ISL files to s3', 'success')
		}
		setLoading(false)
	}

	const fetchDBQuery = async () => {
		let restaurantStations = (selectedStation._id == "All") ? stations.filter(s=> s._id != "All").map(s=> s._id) : [selectedStation._id]
		setLoading(true)
		let success = await fetchDBQueryApi(dbQueryParams, restaurant2._id, restaurantStations)
		if (success) {
			global.showNotification('Fetched DB Query to s3', 'success')
		}
		setLoading(false)
	}

	const deleteLocalSqlDB = async () => {
		let restaurantStations = (selectedStation._id == "All") ? stations.filter(s=> s._id != "All").map(s=> s._id) : [selectedStation._id]
		setLoading(true)
		let success = await deleteLocalSqlDBApi(restaurant2._id, restaurantStations)
		if (success) {
			global.showNotification('Deleted Local SQL DB', 'success')
		}
		setLoading(false)
	}

	const streamLogs = async () => {
		setDialogContent(<StreamLogsDialog onClose={() => setDialogContent(null)} restaurantId={restaurant2._id} stationId={selectedStation._id}/>);
	}

	const forceRegisterStation = async () => {
		let restaurantStations = (selectedStation._id == "All") ? stations.filter(s=> s._id != "All").map(s=> s._id) : [selectedStation._id]
		setLoading(true)
		let success = await forceRegisterStationApi(restaurant2._id, restaurantStations)
		if (success) {
			global.showNotification('Sent Register Request to Station', 'success')
		}
		setLoading(false)
	}



	const sendBlast = async () => {
		
		if (!messageTest) {
			if(!window.confirm('YOU BOUTTA HARRASS?')){ return }
		}
		let body = {
			message: blastMessage,
			imageUrl,
			restaurant: restaurant2._id,
			contest
		}
		if (messageTest) {
			// if (testNumber.indexOf(',') >= 0) {
			if (!window.confirm('YOU BOUTTA HARRASS Multiples?')) { return }
			body.testNumberList = testNumber.split(',')
		}

		let resp = await dashFetch(`${global.dashServerUrl}/api/v1/sms/contestBlaster`, body, true);
		
		if (resp.json.success) {
			alert('You just harassed lots of people!')
		}
	}
	const camelCaseToWords = function(str) {
		return str
			.match(/[A-z]+/g)
			.map(function(x) {
				return x[0].toUpperCase() + x.substr(1).toLowerCase();
			})
			.join(' ');
	};

	const getInputRowFromValue = (key, value) => {
		switch (typeof value) {
			case 'boolean':
				return (
					<DSwitch
						disabled={!editing}
						checked={value}
						value={value}
						onChange={handleRestaurantToggleStateUpdate}
						dfieldname={key}
					/>
				);
			case 'object': // ONLY FOR ARRAYS
				return (
					<DTextField
						disabled={!editing}
						rows={4}
						style={{ width: '100%' }}
						dfieldname={key}
						variant="outlined"
						onChange={(e) => {
							handleRestaurantStateUpdate(null, key, e.target.value.split(','))
						}}
						value={value}
					/>
				);
			case 'string':
			default:
				return (
					<DTextField
						disabled={!editing}
						rows={4}
						style={{ width: '100%' }}
						dfieldname={key}
						variant="outlined"
						onChange={serviceChargeFields.includes(key) ? handleServiceChargeUpdate : handleRestaurantStateUpdate}
						value={value}
					/>
				);
		}
	};

	const makeInputRow = (key, value) => {
		return (
			<div>
				<Typography variant="overline">
					{camelCaseToWords(key)}
					<div style={{ float: 'right', width: '30%' }}>{getInputRowFromValue(key, value)}</div>
				</Typography>
			</div>
		);
	};

	const generateConfigurationTreeJson = (keyPrefix, config) => {
		var tree = [];
		if (config) {
			for (const key of Object.keys(config)) {
				if (Array.isArray(config[key])) {
					tree.push({ value: makeInputRow(keyPrefix + '.' + key, config[key] ) });
				} else if (typeof config[key] == 'object') {
					tree.push({
						value: camelCaseToWords(key),
						nodes: generateConfigurationTreeJson(keyPrefix + '.' + key, config[key])
					});
				} else {
					tree.push({ value: makeInputRow(keyPrefix + '.' + key, config[key]) });
				}
			}
		}
		return tree;
	};

	const showConfiguration = () => {
		return (
			<DForm collection="Restaurant" style={{ width: '100%', paddingTop: 8 }} dashkey={restaurant2._id}>
				<Grid container>
					<Grid item lg={12} style={{ paddingTop:2, paddingBottom:2}}>
						<Typography variant="h6" style={{color:'var(--text)'}} >
							Configuration
						</Typography>
					</Grid>
					<MuiTreeView tree={generateConfigurationTreeJson("config", restaurant2.config)} />
				</Grid>
				{editing && 
					<Grid alignContent="center" container direction="row" justify="center">
						<DSubmitButton
							disabled={!editing}
							className={classes.submitButton}
							parentState={{ saveableObjects: { ...saveableObjects } }}
							collectionsToSave={['Restaurant']}
							next={refreshRestaurant}
						/>
					</Grid>
				}
			</DForm>
		)
	};

	const showCharts = () => {
		if (restaurant) {
			let chartSdk = new ChartsEmbedSDK({
				baseUrl: "https://charts.mongodb.com/charts-dash-zlcqc"
			})

			return (
				<Grid container style={{ width: '100%', paddingTop: 8 }} >
					<DChart chartSdk={chartSdk} chartId="16c535a1-5433-4bdb-8bf6-5c922bb19932" />
					<DChart chartSdk={chartSdk} chartId="92efabd6-8f37-4c87-838e-cc871cd9ac12" />
					<DChart chartSdk={chartSdk} chartId="6ac7b44c-3be4-42f3-8264-f12457745936" />
					<DChart chartSdk={chartSdk} chartId="f6d24718-a8c8-4deb-ad40-ba6f8a698c60" />
					<DChart chartSdk={chartSdk} chartId="6c4c4965-7e34-48aa-a6af-44b3a8544869" />
					<DChart chartSdk={chartSdk} chartId="da90b8a9-2f7e-490a-8ab4-27e52b1f3cba" />
					<DChart chartSdk={chartSdk} chartId="7517e7cd-8aa9-4c99-af08-907d23ead132" />
					<DChart chartSdk={chartSdk} chartId="1c0c1cfa-84a1-4835-a099-e31ced15542b" />
					<DChart chartSdk={chartSdk} chartId="b7673c43-76d9-4c5f-bcf0-f702c9bc7ced" />
				</Grid>
			)
		} else {
			return (
				<div>No restaurant selected</div>
			)
		}
	}

	const showContest = () => {
		return (

			<Grid container>
				<Grid alignContent="left" lg={12} direction="row" style={{marginBottom: 20}}justify="center">
					<Typography>Image URL (Optional)</Typography>
					<Input
						onChange={(event) => setImageUrl(event.target.value)}
						value={imageUrl}
						style={{width: 700}}
					/>
				</Grid>
				<Grid alignContent="left" lg={12} direction="row" justify="center">
					<TextField
						rows={4}
						style={{ width: '50%' }}
						variant="outlined"
						multiline
          				rows="4"
						onChange={(event) => setBlastMessage(event.target.value)}
						value={blastMessage}
					/>
					<Button variant="contained" color="primary" style={{height: '100%'}} onClick={sendBlast}>
						SEND BLAST
					</Button>
				</Grid>
				<Grid item lg={12}>
					<div style={{ textAlign: "left", marginBottom: 15}}>
						<em>Tip: Use `[columnName]` to get shit done</em>
					</div>
				</Grid>
				<Grid container >
					<Grid item lg={1}>
						<Typography>Test</Typography>
						<Switch
							inputProps={{
								'aria-label': 'primary checkbox'
							}}
							onChange={(e) => { setMessageTest(!messageTest)}}
							value={messageTest}
							checked={messageTest}
						/>
					</Grid>

					{messageTest &&
						<Grid item lg={11}>
							<Typography>Test Phone Number</Typography>
							<Input
								value={testNumber}
								onChange={(event) => setTestNumber(event.target.value)}
							/>
						</Grid>
					}
				</Grid>
				<Grid item lg={1}>
					{ contest ?
						<Typography>Contest</Typography>
					:
						<Typography>Loyalty</Typography>
					}
					<Switch
						inputProps={{
							'aria-label': 'primary checkbox'
						}}
						onChange={(e) => { setContest(!contest) }}
						value={contest}
						checked={contest}
					/>
				</Grid>
			</Grid>
		)
	};


	const addContentItem = async () => {
		return await newSchema('WebsiteConentItem');	
	};

	const addTopSeller = async () => {
		let contentItem = await addContentItem()
		const restaurantWebsiteClone = { ...restaurantWebsite };
		restaurantWebsiteClone.topSellers.push(contentItem);
		setRestaurantWebsite(restaurantWebsiteClone);
	};

	const removeTopSeller = async (id) => {
		if (restaurantWebsite.topSellers) {
			const restaurantWebsiteClone = { ...restaurantWebsite };
			_.remove(restaurantWebsiteClone.topSellers, (itm) => { return itm._id === id });
			setRestaurantWebsite(restaurantWebsiteClone);
			addObjectToSave(restaurantWebsiteClone);
		}
	}
	const addSpecial = async () => {
		let contentItem = await addContentItem()
		const restaurantWebsiteClone = { ...restaurantWebsite };
		restaurantWebsiteClone.specials.push(contentItem);
		setRestaurantWebsite(restaurantWebsiteClone);
	};
	const removeSpecial = async (id) => {
		if (restaurantWebsite.specials) {
			const restaurantWebsiteClone = { ...restaurantWebsite };
			_.remove(restaurantWebsiteClone.specials, (itm) => { return itm._id === id });
			setRestaurantWebsite(restaurantWebsiteClone);
			addObjectToSave(restaurantWebsiteClone);
		}
	}
	const addContent = async () => {
		let contentItem = await addContentItem()
		const restaurantWebsiteClone = { ...restaurantWebsite };
		restaurantWebsiteClone.content.push(contentItem);
		setRestaurantWebsite(restaurantWebsiteClone);
	};
	const removeContent = async (id) => {
		if (restaurantWebsite.content) {
			const restaurantWebsiteClone = { ...restaurantWebsite };
			_.remove(restaurantWebsiteClone.content, (itm) => { return itm._id === id });
			setRestaurantWebsite(restaurantWebsiteClone);
			addObjectToSave(restaurantWebsiteClone);
		}
	}


	const showWebsite = () => {
        return (
            <>
                <Grid container alignContent='flex-start' justify="center" style={{marginTop:16}}>
                    {restaurant._id && <>
						<Grid container item xs={12} sm={12} style={{borderRadius:'4px', border:'solid 1px #e2e8f0', padding:16, margin:16}} spacing={1}><Branding/></Grid>
                        <Grid container item xs={12} sm={6} style={{borderRadius:'4px', border:'solid 1px #e2e8f0', padding:16, margin:16}} spacing={1}>
                            <Grid item xs={12} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <Button variant="contained" color="primary" onClick={ ()=> copyToClipboard(`https://${!global.isProd ? 'stage.' : ''}dnow.app/?r=${restaurant._id}&tb=1`) }><AssignmentIcon style={{paddingRight: '10px'}}></AssignmentIcon>Copy Restaurant Table URL </Button>
                            </Grid>
                            <Grid item xs={12} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <Button variant="contained" color="primary" onClick={ ()=> copyToClipboard(`https://${!global.isProd ? 'stage-' : ''}dashboard.dashnow.com/?r=${restaurant._id}`) }><AssignmentIcon style={{paddingRight: '10px'}}></AssignmentIcon>Copy Dashboard URL </Button>
                            </Grid>
                            <Grid item xs={12} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <Button variant="contained" color="primary" onClick={ ()=> copyToClipboard(`https://${!global.isProd ? 'stage.' : ''}dnow.app/?z=2-${restaurant.code}&cn=<CHKNUM>`) }><AssignmentIcon style={{paddingRight: '10px'}}></AssignmentIcon>Copy Restaurant Check URL </Button>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} sm={6} style={{borderRadius:'4px', border:'solid 1px #e2e8f0', padding:16, margin:16}} spacing={1}>
                            <Grid item xs={12} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={ () => {
                                            downloadRestaurantGroupExport(restaurant._id) 
                                        }
                                    }
                                >
                                    <GetAppIcon style={{paddingRight: '10px'}}></GetAppIcon>
                                    Restaurant Group Export
                                </Button>
                            </Grid>
                        </Grid>
                    </>}
				</Grid>
            </>
		)

	};


		
	const addProgReward = async () => {
		let progReward = await newSchema('LoyaltyProgramReward');	
		const loyaltyProgramClone = { ...loyaltyProgram };
		loyaltyProgramClone.rewardPrograms.push(progReward);
		setLoyaltyProgram(loyaltyProgramClone);
	};
	const removeProgReward = async (id) => {
		if (loyaltyProgram.rewardPrograms) {
			const loyaltyProgramClone = { ...loyaltyProgram };
			_.remove(loyaltyProgramClone.rewardPrograms, (itm) => { return itm._id === id });
			setLoyaltyProgram(loyaltyProgramClone);
			addObjectToSave(loyaltyProgramClone);
		}
	}
	const addReward = async (idx) => {
		let reward = await newSchema('LoyaltyReward');
		
		
		const loyaltyProgramClone = { ...loyaltyProgram };
		loyaltyProgramClone.rewardPrograms[idx].rewards.push(reward);
		setLoyaltyProgram(loyaltyProgramClone);
	};
	const removeReward = async (idx, id) => {
		
		
		if (loyaltyProgram.rewardPrograms && loyaltyProgram.rewardPrograms[idx] && loyaltyProgram.rewardPrograms[idx].rewards) {
			const loyaltyProgramClone = { ...loyaltyProgram };
			_.remove(loyaltyProgramClone.rewardPrograms[idx].rewards, (itm) => { return itm._id === id });
			setLoyaltyProgram(loyaltyProgramClone);
			addObjectToSave(loyaltyProgramClone);
		}
	}

	const showLoyalty = () => {
		return (
			<>
				{loyaltyProgram && loyaltyProgram._id &&
					<DForm collection="LoyaltyProgram" style={{ width: '100%' }} dashkey={loyaltyProgram._id}>
						<Grid container spacing={1} style={{ width: '100%', marginTop: '2.5%' }}>
							
							<Grid item lg={5} style={{ paddingRight: '2.5%' }}>
								<DTextField
									disabled={!editing}
									label="Name"
									value={loyaltyProgram.name}
									onChange={handleLoyaltyProgramStateUpdate}
									dfieldname={`name`}
								/>
								<DTextField
									disabled={!editing}
									label="Legal Entity"
									value={loyaltyProgram.legalEntity}
									onChange={handleLoyaltyProgramStateUpdate}
									dfieldname={`legalEntity`}
								/>
								<DTextField
									disabled={!editing}
									label="Number Of Points"
									value={loyaltyProgram.rewardConversion ?  loyaltyProgram.rewardConversion.numberOfPoints : ''}
									onChange={handleLoyaltyProgramStateUpdate}
									dfieldname={`rewardConversion.numberOfPoints`}
								/>
								<DTextField
									disabled={!editing}
									label="Amount"
									value={loyaltyProgram.rewardConversion ?  loyaltyProgram.rewardConversion.amount : ''}
									onChange={handleLoyaltyProgramStateUpdate}
									dfieldname={`rewardConversion.amount`}
								/>
								<DTextField
									disabled={!editing}
									label="Logo"
									value={loyaltyProgram.logo}
									onChange={handleLoyaltyProgramStateUpdate}
									dfieldname={`logo`}
								/>
								<DTextField
									disabled={!editing}
									label="Logo Height"
									value={loyaltyProgram.logoHeight}
									onChange={handleLoyaltyProgramStateUpdate}
									dfieldname={`logoHeight`}
								/>
								<DTextField
									disabled={!editing}
									label="Logo Small"
									value={loyaltyProgram.logoSmall}
									onChange={handleLoyaltyProgramStateUpdate}
									dfieldname={`logoSmall`}
								/>
								<DTextField
									disabled={!editing}
									label="Terms And Conditions"
									value={loyaltyProgram.termsAndConditions}
									onChange={handleLoyaltyProgramStateUpdate}
									dfieldname={`termsAndConditions`}
								/>
							</Grid>
							<Grid item lg={7} style={{ paddingRight: '2.5%' }}>
								<Typography variant="body2" color="primary" className={classes.subheadingText}>
									ACTIONS
									<Button style={{ float: 'right' }} variant="contained" color="primary" onClick={addProgReward}>
										<AddCircleOutline className={classes.addRestaurantIcon} />
										Add Action
									</Button>
								</Typography>
								{loyaltyProgram && loyaltyProgram.rewardPrograms && loyaltyProgram.rewardPrograms.length > 0 && loyaltyProgram.rewardPrograms.map((rewardProgram, idx) => {
									return (
										<Paper>
											<div style={{ marginTop: '5%', marginBottom: '5%', padding: '3%' }}>
												<Button onClick={(e) => removeProgReward(rewardProgram._id)} style={{ float: 'right' }}>
													<Delete color="secondary" />
												</Button>
												<div>
													{/* {log.info(JSON.stringify(rewardProgram))} */}
													<Typography variant="headline">
														{rewardProgram.action}{' '}
														{/* <Typography variant="overline">ID: {topSeller._id}</Typography> */}
													</Typography>
												</div>
												<div>
													<DTextField
														disabled={!editing}
														label="Action"
														value={rewardProgram.action}
														onChange={handleLoyaltyProgramStateUpdate}
														dfieldname={`rewardPrograms[${idx}].action`}
													/>
													<br/>
													<Typography
														variant="body"
														color="secondary"
														style={{}}
														className={classes.subheadingText}
													> isRandom:
														<DSwitch
															label="isRandom"
															value={rewardProgram.isRandom}
															checked={rewardProgram.isRandom}
															onChange={handleLoyaltyProgramToggleStateUpdate}
															dfieldname={`rewardPrograms[${idx}].isRandom`}
														/>
													</Typography>
													<br/>
													{/* <Typography
														variant="body"
														color="secondary"
														style={{}}
														className={classes.subheadingText}
													> isActive:
														<DSwitch
															value={rewardProgram.isActive}
															checked={rewardProgram.isActive}
															onChange={handleLoyaltyProgramToggleStateUpdate}
															dfieldname={`rewardPrograms[${idx}].isActive`}
														/>
													</Typography>
													<br/> */}
													<DTextField
														disabled={!editing}
														label="expiresInDays"
														value={rewardProgram.expiresInDays}
														onChange={handleLoyaltyProgramStateUpdate}
														dfieldname={`rewardPrograms[${idx}].expiresInDays`}
													/>
												</div>
												<Button style={{ float: 'right' }} variant="contained" color="primary" onClick={(e) => {addReward(idx)}}>
													<AddCircleOutline className={classes.addRestaurantIcon} />
													Add Reward
												</Button>
												{rewardProgram && rewardProgram.rewards && rewardProgram.rewards.length > 0 && rewardProgram.rewards.map((reward, idx2) => {
													return (
														<>
															<Paper>
																<div style={{ marginTop: '5%', marginBottom: '5%', padding: '3%' }}>
																	<Button onClick={(e) => removeReward(idx, reward._id)} style={{ float: 'right' }}>
																		<Delete color="secondary" />
																	</Button>
																	<div>
																		<DTextField
																			disabled={!editing}
																			label="rewardType"
																			value={reward.rewardType}
																			onChange={handleLoyaltyProgramStateUpdate}
																			dfieldname={`rewardPrograms[${idx}].rewards[${idx2}].rewardType`}
																		/>
																		<DTextField
																			disabled={!editing}
																			label="value"
																			value={reward.value}
																			onChange={handleLoyaltyProgramStateUpdate}
																			dfieldname={`rewardPrograms[${idx}].rewards[${idx2}].value`}
																		/>
																		<DTextField
																			disabled={!editing}
																			label="odds"
																			value={reward.odds}
																			onChange={handleLoyaltyProgramStateUpdate}
																			dfieldname={`rewardPrograms[${idx}].rewards[${idx2}].odds`}
																		/>
																		<br/>
																		<Typography
																			variant="body"
																			color="secondary"
																			style={{}}
																			className={classes.subheadingText}
																		> perDollarSpent:
																			<DSwitch
																				value={reward.perDollarSpent}
																				checked={reward.perDollarSpent}
																				onChange={handleLoyaltyProgramToggleStateUpdate}
																				dfieldname={`rewardPrograms[${idx}].rewards[${idx2}].perDollarSpent`}
																			/>
																		</Typography>
																		<br />
																		<DTextField
																			disabled={!editing}
																			label="startsOn"
																			value={reward.startsOn}
																			onChange={handleLoyaltyProgramStateUpdate}
																			dfieldname={`rewardPrograms[${idx}].rewards[${idx2}].startsOn`}
																		/>
																		<DTextField
																			disabled={!editing}
																			label="endsOn"
																			value={reward.endsOn}
																			onChange={handleLoyaltyProgramStateUpdate}
																			dfieldname={`rewardPrograms[${idx}].rewards[${idx2}].endsOn`}
																		/>
																	</div>
																</div>
															</Paper>
														</>
													)
												})}
											</div>
										</Paper>
									);
								})}
							</Grid>
						</Grid>

						{editing && 
							<Grid alignContent="center" container direction="row" justify="center">
								<DSubmitButton
									className={classes.submitButton}
									parentState={{ saveableObjects: { ...saveableObjects } }}
									collectionsToSave={['LoyaltyProgram', 'LoyaltyProgramReward', 'LoyaltyReward']}
									next={refreshLoyaltyProgram}
								/>
							</Grid>
						}
					</DForm>
				}
		</>
		)
	};

	return (
		<div className={classes.root}>
			{loading && 
				<Grid item xs style={{}}>
					<LinearProgress />
				</Grid>
			}
			{restaurant2 && !loading && 
				<Grid container alignItems="flex-start" className={classes.header}>
					<Grid item style={{width: '100%', padding: 14, paddingLeft: 20}}>

						<Grid item xs={12} style={{padding: 0, lineHeight: 1}}>
							<Typography style={{color: 'var(--dark-gray)', fontWeight: '400', fontSize: '1.9em', lineHeight: 1}} disableGutters>{restaurant2.name}</Typography>
						</Grid>

						<Grid item xs={12} style={{paddingTop: 6, lineHeight: 1, height: 25}}>
							<Typography style={{color: 'var(--gray)', fontWeight: '400', fontSize: '1.2em', lineHeight: 1}} disableGutters>
								{restaurant2.locationInfo.addressLine1 && restaurant2.locationInfo.addressLine1}
								{restaurant2.locationInfo.addressLine2 && '  ' + restaurant2.locationInfo.addressLine2}
								{restaurant2.locationInfo.city && ' ' + restaurant2.locationInfo.city}
								{restaurant2.locationInfo.state && ', ' + restaurant2.locationInfo.state}
								{restaurant2.locationInfo.zipCode && ' ' + restaurant2.locationInfo.zipCode}
							</Typography>
						</Grid>

						<Grid item alignItems="center" container xs={12} style={{paddingTop: 10, lineHeight: 1}}>
							<Grid item>
								<Typography className={classes.headerLabel}>Code:</Typography>
								<Typography className={classes.headerLabel} style={{paddingLeft: 5, color: 'var(--purple)'}}>{global.f(restaurant2, 'code', '').toUpperCase()}</Typography>
							</Grid>
							<Grid item style={{paddingLeft: 30}}>
								<Typography className={classes.headerLabel}>Status:</Typography>
								<Typography className={classes.headerLabel} style={{paddingLeft: 5, color: 'var(--purple)'}}>{global.f(restaurant2, 'status', '').toProperCase()}</Typography>
							</Grid>
							{!isMobile && 
								<>
								<Grid item style={{paddingLeft: 30}}>
									<Typography className={classes.headerLabel}>Enrolled:</Typography>
									<Typography className={classes.headerLabel} style={{paddingLeft: 5, color: 'var(--purple)'}}>{restaurant2 && restaurant2.dateEnrolled ? moment(restaurant2.dateEnrolled).format('L') : ''}</Typography>
								</Grid>
								<Grid item style={{paddingLeft: 30}}>
									<Typography className={classes.headerLabel}>POS:</Typography>
									<Typography className={classes.headerLabel} style={{paddingLeft: 5, color: 'var(--purple)'}}>
										{restaurant2.getVal('posType', false) ? global.ifNull(posTypes.find(a => a._id == restaurant2.posType._id), {}).posType : ''}
									</Typography>
								</Grid>
								<Grid item style={{paddingLeft: 30}}>
									<PersonIcon />
								</Grid>
								<Grid item style={{paddingLeft: 5}}>
									<Typography className={classes.headerLabel}>Primary:</Typography>
									<Typography className={classes.headerLabel} style={{paddingLeft: 5, color: 'var(--purple)', minWidth: '100px'}}>
									{global.ifNull(restaurantAccounts.find(a => a._id == restaurant2.primaryContactAccount), {}).fullName}
									</Typography>
								</Grid>
								<Grid item style={{paddingLeft: 30}}>
									<PeopleAltIcon />
								</Grid>
								<Grid item style={{paddingLeft: 7}}>
									<Typography className={classes.headerLabel}>Agent:</Typography>
									<Typography className={classes.headerLabel} style={{paddingLeft: 5, color: 'var(--purple)', minWidth: '100px'}}>
										{global.ifNull(agents.find(a => a._id == restaurant2.agent), {}).fullName}
									</Typography>
								</Grid>
								</>
							}
							{!isNew && !hasChanges &&
								<Grid item align='right' style={{paddingLeft: 7, textAlign: 'right', position: 'absolute', right: 15, marginBottom: 15, top: isMobile ? '60px' : 'auto'}}>
									<Button style={{marginRight: 8}} size="small" variant="contained" color="primary" onClick={() => { 
										setDuplicateCurrent(true)
										global.set({restaurant: null})
										history.push(`/admin/restaurants/addEditRestaurant/new`)
									}} color='primary'><FileCopy className={classes.buttonIcon}/>Duplicate</Button>
									{editing && <Button size="small" variant="contained" color="primary" onClick={() => { setEditing(!editing) }} color='primary'><Visibility className={classes.buttonIcon}/>View</Button>}
									{!editing && <Button size="small" variant="contained" color="primary" onClick={() => { setEditing(!editing) }} color='primary'><Build className={classes.buttonIcon} />Edit</Button>}
								</Grid>
							}
						</Grid>
					</Grid>
				</Grid>
			}
			{restaurant2 && !loading &&
				<Grid container spacing={0} style={{width: '100%', height: 'calc(100% - 110px)'}}>
					<Grid item style={{width: isMobile ? '100%' : 60}}>
						<Tabs
							orientation={isMobile ? "horizontal" : "vertical"}
							value={activeTab}
							onChange={(e, n) => {
								setActiveTab(n);
							}}
							className={classes.tabs}
							indicatorColor='primary'
							style={{width: isMobile ? '100%' : 60}}
						>	
							<Tooltip title="Site Info">
								<Tab value={GENERAL_TAB} selected icon={<StoreIcon color={activeTab == GENERAL_TAB ? 'primary' : ''} />} style={{minWidth: 0}} />
							</Tooltip>

							{(global.hasPermission('admin.restaurant.gateway')) &&
								<Tooltip title="Gateway">
									<Tab value={GATEWAY_TAB} icon={<VpnKeyIcon color={activeTab == GATEWAY_TAB ? 'primary' : ''} />} style={{minWidth: 0}} />
								</Tooltip>
							}
							{global.hasPermission('admin.restaurant.stations') &&
								<Tooltip title="Stations">
									<Tab value={STATIONS_TAB} icon={<PrintIcon color={activeTab == STATIONS_TAB ? 'primary' : ''} />} style={{minWidth: 0}} />
								</Tooltip>
							}
							{global.hasPermission('admin.restaurant.config') &&
								<Tooltip title="Config">
									<Tab value={CONFIG_TAB} icon={<SettingsIcon color={activeTab == CONFIG_TAB ? 'primary' : ''} />} style={{minWidth: 0}} />
								</Tooltip>
							}
							{global.isAdmin() &&
								<Tooltip title="Support">
									<Tab value={SUPPORT_TAB} icon={<ContactSupportIcon color={activeTab == SUPPORT_TAB ? 'primary' : ''} />} style={{minWidth: 0}} />
								</Tooltip>
							}
							{/* {global.hasPermission('admin.restaurant.gateway') &&
								<Tooltip title="SMS">
									<Tab value="SMS" icon={<SmsIcon color={activeTab == 'SMS' ? 'primary' : ''} />} style={{minWidth: 0}} />
								</Tooltip>
							} */}
							{global.hasPermission('admin.*') &&
								<Tooltip title="Utils">
									<Tab value={BRANDING_TAB} icon={<AssignmentIcon color={activeTab == BRANDING_TAB ? 'primary' : ''} />} style={{minWidth: 0}} />
								</Tooltip>
							}

							{restaurant2.loyaltyProgram && global.hasPermission('admin.restaurant.gateway.edit') &&
								<Tooltip title="Loyalty">
									<Tab value={LOYALTY_TAB} icon={<LoyaltyIcon color={activeTab == LOYALTY_TAB ? 'primary' : ''} />} style={{minWidth: 0}} />
								</Tooltip>
							}
							{global.hasPermission('admin.restaurant.gateway.edit') &&
								<Tooltip title="Charts">
									<Tab value={CHARTS_TAB} icon={<ChartsIcon color={activeTab == CHARTS_TAB ? 'primary' : ''} />} style={{minWidth: 0}} />
								</Tooltip>
							}
							{global.hasPermission('admin.restaurant.gateways') &&
								<Tooltip title="Charts">
									<Tab value='Charts' icon={<ChartsIcon color={activeTab =='Charts' ? 'primary' : ''} />} style={{minWidth: 0}} />
								</Tooltip>
							}
						</Tabs>
					</Grid>
					<Grid container xs item id="addEditRestaurantContainer" className={classes.restaurantForm} style={{padding: isMobile ? 5 : 25, paddingTop: 5, height: isMobile ? 'auto' : '100%', overflowX: isMobile ? 'initial' : 'hidden', overflowY: isMobile ? 'initial' : 'auto'}}>
						{activeTab == GENERAL_TAB && showGeneral()}
						{activeTab == GATEWAY_TAB && showGatewaySetup()}
						{activeTab == STATIONS_TAB && showStations()}
						{activeTab == CONFIG_TAB && showConfiguration()}
						{activeTab == SMS_TAB && showContest()}
						{activeTab == BRANDING_TAB && showWebsite()}
						{activeTab == SUPPORT_TAB && <Support/>}
						{activeTab == CHARTS_TAB && showCharts()}
						{restaurant2.loyaltyProgram && loyaltyProgram && activeTab == LOYALTY_TAB && showLoyalty()}
					</Grid>
				</Grid>
			}
		</div>
	);
};

Dash.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withStyles(styles, { withTheme: true })(Dash);

const states = [
	{ value: 'AL', label: 'Alabama' },
	{ value: 'AK', label: 'Alaska' },
	{ value: 'AZ', label: 'Arizona' },
	{ value: 'AR', label: 'Arkansas' },
	{ value: 'CA', label: 'California' },
	{ value: 'CO', label: 'Colorado' },
	{ value: 'CT', label: 'Connecticut' },
	{ value: 'DE', label: 'Delaware' },
	{ value: 'FL', label: 'Florida' },
	{ value: 'GA', label: 'Georgia' },
	{ value: 'HI', label: 'Hawaii' },
	{ value: 'ID', label: 'Idaho' },
	{ value: 'IL', label: 'Illinois' },
	{ value: 'IN', label: 'Indiana' },
	{ value: 'IA', label: 'Iowa' },
	{ value: 'KS', label: 'Kansas' },
	{ value: 'KY', label: 'Kentucky' },
	{ value: 'LA', label: 'Louisiana' },
	{ value: 'ME', label: 'Maine' },
	{ value: 'MD', label: 'Maryland' },
	{ value: 'MA', label: 'Massachusetts' },
	{ value: 'MI', label: 'Michigan' },
	{ value: 'MN', label: 'Minnesota' },
	{ value: 'MS', label: 'Mississippi' },
	{ value: 'MO', label: 'Missouri' },
	{ value: 'MT', label: 'Montana' },
	{ value: 'NE', label: 'Nebraska' },
	{ value: 'NV', label: 'Nevada' },
	{ value: 'NH', label: 'New Hampshire' },
	{ value: 'NJ', label: 'New Jersey' },
	{ value: 'NM', label: 'New Mexico' },
	{ value: 'NY', label: 'New York' },
	{ value: 'NC', label: 'North Carolina' },
	{ value: 'ND', label: 'North Dakota' },
	{ value: 'OH', label: 'Ohio' },
	{ value: 'OK', label: 'Oklahoma' },
	{ value: 'OR', label: 'Oregon' },
	{ value: 'PA', label: 'Pennsylvania' },
	{ value: 'RI', label: 'Rhode Island' },
	{ value: 'SC', label: 'South Carolina' },
	{ value: 'SD', label: 'South Dakota' },
	{ value: 'TN', label: 'Tennessee' },
	{ value: 'TX', label: 'Texas' },
	{ value: 'UT', label: 'Utah' },
	{ value: 'VT', label: 'Vermont' },
	{ value: 'VA', label: 'Virginia' },
	{ value: 'WA', label: 'Washington' },
	{ value: 'WV', label: 'West Virginia' },
	{ value: 'WI', label: 'Wisconsin' },
	{ value: 'WY', label: 'Wyoming' },
	{ value: 'CD', label: 'Canadian Provinces', prop: 'disabled' },
	{ value: 'AB', label: 'Alberta' },
	{ value: 'BC', label: 'British Columbia' },
	{ value: 'MB', label: 'Manitoba' },
	{ value: 'NB', label: 'New Brunswick' },
	{ value: 'NL', label: 'Newfoundland and Labrador' },
	{ value: 'NS', label: 'Nova Scotia' },
	{ value: 'ON', label: 'Ontario' },
	{ value: 'PE', label: 'Prince Edward Island' },
	{ value: 'QC', label: 'Quebec' },
	{ value: 'SK', label: 'Saskatchewan' }
];