import React, { useState, useEffect } from 'react';
import DInput from '../../components/dash/DInput';
import DForm2 from '../../components/dash/DForm2';

import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, useMediaQuery } from '@material-ui/core';
import theme from '../../theme';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import PhoneIcon from '@material-ui/icons/Phone';
import PersonIcon from '@material-ui/icons/Person';
import SendIcon from '@material-ui/icons/Send';
import { Paper, Divider, IconButton, Button, Grid, InputAdornment, TextField } from '@material-ui/core';
import createOrAddToOrderApi from '../../actions/createOrAddToOrderApi';

const useStyles = makeStyles((theme) => ({
  root: {
    padding:'8px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 8,
  },
  divider: {
    // height: 28,
    // margin: 4,
  },
  DInput: {
      margin:0,
      height:''
  }
}));

export default function ManualCheckEntry() {
    const [show, setShow] = useState('checkEntry') //enum: search, checkEntry
    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(false)
    const [todaySearchFilter, setTodaySearchFilter] = global.get('todaySearchFilter', '')
    const [restaurant] = global.get('restaurant')
    let smallScreen = useMediaQuery(theme().breakpoints.up('sm'));
    let xSmallScreen = useMediaQuery(theme().breakpoints.down('xs'));
    const classes = useStyles();

    const handleToggle = (event, view) => {
        if (view) setShow(view);
    };

    useEffect(() => {
    }, [record])
    
    const createCheck = (record) => {
        let onValid = async () => {
            setLoading(true)
            let customer = {
                phone: record.phone,
                firstName: record.customerName
            }
            await createOrAddToOrderApi(customer, record.amount*100, restaurant? restaurant._id: '', record.orderNumber, '', localStorage.accountId)
            setRecord({})
            setLoading(false)
		}
        global.set({onValid: onValid, validateNow: true})
    }

  return (
    <Paper className={classes.root}>
        <Grid container spacing={1} style={{width:'100%', margin:0}} justify="space-between" alignItems="center">
            <Grid item textAlign="center" xs={xSmallScreen && show=='checkEntry'}>
                <ToggleButtonGroup size="small" value={show} exclusive onChange={handleToggle} style={{ minWidth: 50, height:40}}>
                    <ToggleButton value="search" >
                        <SearchIcon/>
                    </ToggleButton>
                    <ToggleButton value="checkEntry">
                        <KeyboardIcon/>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>

        {show == 'search' &&
            <>
                <Grid item xs>
                    <TextField variant="outlined" size="small" placeholder="Search" margin="dense"  type="text"
                        style={{width:'100%', height:'40px', margin:0, flexGrow:1, marginBottom:0}}
                        value={todaySearchFilter ? todaySearchFilter : ''}
                        onChange={e => setTodaySearchFilter(e.target.value)}
                        >
                    </TextField>
                </Grid>
                {todaySearchFilter &&
                <Grid item>
                    <Divider className={classes.divider} orientation="vertical" />
                    <IconButton color="primary" className={classes.iconButton} aria-label="directions">
                        <ClearIcon onClick={() => { setTodaySearchFilter('') }} />
                    </IconButton>
                </Grid>
                }
            </>
        }
        {show == 'checkEntry' &&
            <DForm2 record={record}>
                <Grid item xs={8} sm={2}>
                    <DInput noHelperText required name="phone" type="tel" format="(###) ###-####" fieldType="phonenumber"
                        placeholder="Phone"
                        style={{width:'100%'}}
                        marginBottom="0" variant="outlined"
                        InputProps={{margin:'dense', startAdornment: <InputAdornment position="start"><PhoneIcon style={{color: 'rgba(0, 0, 0, 0.38)', height:20, width:20}}/></InputAdornment>}}>
                    </DInput>
                </Grid>
                <Grid item  xs={6} sm={2}>
                    <DInput noHelperText required name="amount" type="tel"
                        placeholder="Amount"
                        type="number"
                        style={{width:'100%'}}
                        marginBottom="0" variant="outlined"
                        InputProps={{margin:'dense', variant:'outlined', startAdornment: <InputAdornment position="start">$</InputAdornment>}}>
                    </DInput>
                </Grid>
                <Grid item  xs={6} sm={2}>
                    <DInput name="orderNumber" type="tel"
                        placeholder={`Order ${!smallScreen ? '(optional)' : ''}`}
                        style={{width:'100%'}}
                        marginBottom="0" variant="outlined"
                        InputProps={{margin:'dense', startAdornment: <InputAdornment position="start">#</InputAdornment>}}>
                    </DInput>
                </Grid>
                <Grid item xs={9} sm={3}>
                    <DInput type="text" name="customerName" variant="outlined" marginBottom="0" 
                        style={{width:'100%'}}
                        placeholder={`Customer ${!smallScreen ? '(optional)' : ''}`}
                        InputProps={{margin:'dense', startAdornment: <InputAdornment position="start"><PersonIcon style={{color: 'rgba(0, 0, 0, 0.38)', height:20, width:20}}/></InputAdornment>}}>
                    </DInput>
                </Grid>
                <Grid item xs style={{textAlign:'center'}}>
                    <Button variant="contained" onClick={() => createCheck(record)} color="primary" style={{width:'100%', height:40, fontSize: smallScreen ? 'small' : 'x-small', lineHeight:'100%'}} marginBottom="0">{ loading ? <CircularProgress color="white" size={20} />: !xSmallScreen? 'TEXT PAY' : <SendIcon/>}</Button>
                </Grid>
            </DForm2>
        }
        </Grid> 
    </Paper>
  );
}
