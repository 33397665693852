import log from 'loglevel';
import dashFetch from '../utils/dashFetch';
import saveCardTokenApi from "./saveCardTokenApi";

export default async function (restaurant, data, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('tokenizeAch');
        const url = `https://core.spreedly.com/v1/payment_methods`;
        const redirectUrl = `${global.dashServerUrl}/api/v1/general/tokenResponse?`;
        
        let formData = new FormData();
        formData.append('redirect_url', redirectUrl);
        formData.append('environment_key', global.spreedlyEnvironmentKey);
        formData.append('utf8', '✓');
        formData.append('bank_account_type', 'checking');
        formData.append('payment_method_type', 'bank_account');
        formData.append('bank_account_holder_type', 'business');
        formData.append('full_name', data.ach.nameOnAccount);
        formData.append('bank_name', data.ach.bankName);
        formData.append('bank_routing_number', data.ach.routingNumber);
        formData.append('bank_account_number', data.ach.accountNumber);

        fetch(url, {method: 'POST', redirect: 'follow', body: formData, cache: "no-store"}, debug).then((res) => {
            if (res.status === 401) {
                log.info("401 ERROR")
            }
            if (res.ok) {
                res.json().then(async (json) => {
                    if (json.success) {
                        log.info("success")
                        log.info("spreedly ach token: " + json.token)
                        if (json.token) {

                            let routingNumberLast4 = data.ach.routingNumber
                            if (routingNumberLast4.length > 4) {
                                routingNumberLast4 = routingNumberLast4.substring(routingNumberLast4.length-4)
                            }

                            let accountNumberLast4 = data.ach.accountNumber
                            if (accountNumberLast4.length > 4) {
                                accountNumberLast4 = accountNumberLast4.substring(accountNumberLast4.length-4)
                            }

                            let data2 = {
                                restaurantId: restaurant._id,
                                paymentType: 'ach',
                                source: 'spreedly',
                                ach: {
                                    bankName: data.ach.bankName,
                                    nameOnAccount: data.ach.nameOnAccount,
                                    routingNumberLast4,
                                    accountNumberLast4,
                                },
                                token: json.token,
                            }
                            let resp
                            try {
                                resp = await saveCardTokenApi(data2, true);    
                            } catch (err) {
                                reject(err.message)
                            }
                            window.Spreedly.removeHandlers();

                            if (resp.encryptedCard) {
                                let rest = {...restaurant}
                                rest.billingInfo.encryptedCard = resp.encryptedCard
                                rest.billingInfo.encryptedCardStatus = ''
                                global.set({restaurant: rest})
                                resolve(resp.encryptedCard)
                            } else {
                                reject("Unable to save bank account info, please try again")
                            }
                        } else {
                            reject("Unable to save bank account info, please try again")
                        }
                    } else {
                        reject(json.error)
                    }
                }).catch((err) => {
                    reject(err.message);
                })
            }
        }).catch((err) => {
            reject(err.message);
        });



	})
}