import React, { useState, useEffect } from 'react';

const isTextPay = (window.location.href.indexOf('textpay') > -1)
const startPosition = window.location.href.indexOf('//') + 2

let whiteLabelCode = window.location.href.substr(startPosition, window.location.href.indexOf('.') - startPosition)
if (whiteLabelCode == 'local' || whiteLabelCode.indexOf('dashboard') > -1 || whiteLabelCode.indexOf('local') > -1) {
    whiteLabelCode = ''
}

whiteLabelCode = whiteLabelCode.replace('-test', '').replace('test', '')
let additionalParams = ''
let wlcParam = ''

if (whiteLabelCode) wlcParam = '?wlc='+whiteLabelCode
if (whiteLabelCode == 'heartland') {
    additionalParams = '&r=600611548d494b1f9c7178ea' // hardcoded stage site for heartland demo
}

let demoUrl = `https://demo.${isTextPay? 'textpay.app' : 'dashnow.com'}${wlcParam}${additionalParams}`


export default (props) => {

    useEffect(() =>{
        window.location.href = demoUrl
    }, [])


	return (
        <>
        </>
	);
};