import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import { Grid, Paper, Button, LinearProgress, Box, Container, CircularProgress, MenuItem } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import theme from '../theme'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {ReactComponent as HeartlandLogo} from '../assets/svgs/heartland-logo-white.svg';
import log from 'loglevel'
import _ from 'lodash';
import DForm2 from '../components/dash/DForm2';
import DRecord from '../components/dash/DRecord';
import DInput from '../components/dash/DInput';
import DFlag from '../components/dash/DFlag';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/HighlightOff';
import createTextPayOnboardingRequestApi from '../actions/createTextPayOnboardingRequestApi';
import ReactGA from 'react-ga';
import { CheckBox } from '@material-ui/icons';
import FisLogo from '../assets/svgs/fis-logo';
import MsgLogo from '../assets/svgs/msg-logo';
import getOnboardingDataByBrandApi from '../actions/getOnboardingDataByBrandApi';



const headerHeight = 80
const activeButtonBarHeight = 0

const useStyles = makeStyles((theme) => ({
    stepper: {
        width: '100%',
		'&$selected': {
            backgroundColor: '#8B6BD9',
            fontWeight: 600,
		}
    },
    header: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		height: `${headerHeight}px`,
		zIndex: 11111,
    },
    content: {
		position: 'absolute',
		top: `${headerHeight}px`,
		bottom: `${activeButtonBarHeight}px`,
		left: 0,
		right: 0,
		overflow: 'auto',
		paddingTop: 30,
		paddingLeft: 20,
		paddingRight: 20,
		paddingBottom: 20,
	},
    
}))


export default (props) => {
    const classes = useStyles();

    const isMobile = useMediaQuery('(max-width:600px)')
    const defaultPadding = isMobile? 15 : 25
    const [record, setRecord] = useState({ccEnabled:true, partner:global.branding, posType: '0'}) 
    const [formSubmitted, setFormSubmitted] = useState(false) 
    const [achAvailable, setAchAvailable] = useState(false) 
    const [products, setProducts] = useState([]) 



    let xs = useMediaQuery(theme().breakpoints.down('xs'));
    let sm = useMediaQuery(theme().breakpoints.down('sm'));
    let mediumScreen = useMediaQuery(theme().breakpoints.down('md'));

    const GA_SIGNUP = "Heartland Signup";

    const [loadingAsync, setLoadingAsync] = useState(false)


	let submitForm = () => {
		let onValid = async () => {
            setLoadingAsync(true)

            let recordOut = {...record}

            delete recordOut.dform

            let success = await createTextPayOnboardingRequestApi(recordOut, global.branding)
            setLoadingAsync(false)
            if (success) {
                setFormSubmitted(true)
            } else {
                global.showNotification('An error occurred, please try again', 'error')
            }
            ReactGA.event({
                category: GA_SIGNUP,
                action: `New ${global.branding} Account Added, success: ${!!success}`
            });

            
		}
		global.set({onValid: onValid, validateNow: true})
    }

    useEffect(() => {
        fetchData()
    }, [])
    
    const fetchData = async () => {
        let data = await getOnboardingDataByBrandApi(global.branding) // todo eventually we may need the agency code/ id
        if (data) {
            if (data.products) setProducts(data.products)
            if (data.achAvailable != undefined) setAchAvailable(data.achAvailable)
        }
    }
 
    const handleResubmit = () => {
        let recordNew = {
            repName: record.repName,
            repEmail: record.repEmail,
            posType:'0',
            ccEnabled:true
        }
        setRecord(recordNew)
        setFormSubmitted(false)
    }


    let posTypes = [
        // { value: 'quickbooksdesktop', label: 'TextPay for Quickbooks Desktop' }, //We are no longer supporting new QBD installs
    ]

    if (global.branding == 'heartland') {
        posTypes.push({value:'hyfin', label:"Hyfin Standalone"}, {value:'hyfin-qbo', label:'Hyfin for Quickbooks Online'})
    }
    
    const invalidBusinessName = (value) => value.length < 3
    const invalidPhoneNumber = (value) => !global.validatePhoneNumber(value)
    const invalidMID = (value) => global.branding == 'heartland' && !/^\d{14,16}$/.test(value)
    const invalidEmail = (value) => !global.validateEmail(value)

    const businessNameHelperText = "Business name has to be at least 3 characters long"
    const phoneNumberHelperText = "Please enter a valid phone number"
    const emailHelperText = "Please enter a valid email address"
    const MIDHelperText = global.branding == 'heartland' ? "Please enter a valid MID (14-16 numbers)" : "Please enter a valid MID"

    const brandCode = global.branding == "fis" ? global.branding.toUpperCase() : global.branding.toProperCase()
        
	return (
        <div style={{width: '100%', height: '100%', backgroundColor: theme().palette.background.default, overflow: 'hidden',}}>
            <Box className={classes.header} bgcolor={`var(--${global.branding})`}>
				{/* <Container className={classes.headerContainer} maxWidth={sm ? 'sm': xs ? 'xs' : 'lg'} style={{paddingTop: 16, paddingLeft: xs? 25: 50, paddingRight: xs? 25: 50}}> */}
					<div style={{display:'flex', justifyContent:'space-between', verticalAlign:'middle', padding:'15px 25px'}}>
                        {global.branding == 'heartland' && <HeartlandLogo width="188px" height="50px"/>}
                        {global.branding == 'fis' && <FisLogo inverted width="188px" height="50px"/>}
                        {global.branding == 'msg' && <MsgLogo inverted width="188px" height="50px"/>}

                        <Typography variant={sm ? "h6" : "h5"} align="right" style={{color: '#FFF', marginTop: 10}}>
                            New Account Setup
                        </Typography>

					</div>
				{/* </Container> */}
			</Box>
            <Grid container alignItems="stretch" direction={isMobile ? "column" : "row"} className={classes.content}>
                <Grid item sm={4} style={{paddingRight: isMobile? 5 : 25, paddingBottom: isMobile? 5 : 25, paddingTop: isMobile? 0 : 10, paddingLeft: isMobile? 0 : 10}}>
                    {!isMobile && 
                        <Grid direction="column" alignItems="stretch" container style={{height: '100%'}}>


                            <Grid item xs>
                                <Typography variant="h5" style={{color: 'var(--text)', marginTop: isMobile? 0 : 45}}>
                                    Welcome to the {brandCode} TextPay Onboarding Form,
                                </Typography>
                            
                                <br/><br/>
                                <Typography variant="h7" style={{color: 'var(--text)'}}>
                                    Please complete the form to create a new account. 
                                </Typography>

                            </Grid>

                            <Grid item container alignItems="flex-end">
                                <Grid item xs={12}>
                                    <Typography variant="h5" style={{color: 'var(--text)'}}>
                                        Having Trouble?
                                    </Typography>
                                    <Typography variant="h7" style={{color: 'var(--text)'}}>
                                        Contact our support team at <br/>
                                        <a style={{color: `var(--${global.branding})`}} href="mailto:support@dashnow.com">support@dashnow.com</a> or 913-370-7577
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Grid>
                    }
                </Grid>


                <Grid item xs sm={8}>
                    <Paper style={{height: '100%', padding: isMobile? 0 : 15, marginBottom: 15}}>
                        
                        <Grid direction="column" alignItems="stretch" container style={{height: '100%'}}>
                            <Grid item ></Grid>
                            <Grid xs item style={{overflowX: 'hidden', overflowY: 'auto'}}>
                                {loadingAsync && 
                                    <Grid container justify="center" alignItems="center" style={{height: '50%'}}>
                                        <LinearProgress style={{width: '75%', height: 6}} />
                                    </Grid>
                                }
                            
                                {!loadingAsync && !formSubmitted &&
                                    <DForm2 record={record} margin={35}>
                                        <Grid container style={{padding:defaultPadding}} spacing={3}>
                                            {/* <Grid item container xs={12} style={{paddingTop: defaultPadding, paddingBottom: defaultPadding}}>
                                                <Typography variant="h5">Please Add a Location</Typography>
                                            </Grid> */}
                                            <Grid item container xs={12} lg={10} xl={8} style={{paddingRight: isMobile? 15 : 45}}>
                                                <Typography variant="h6" color="primary" style={{marginBottom: 15}}>
                                                    Business Info
                                                </Typography>
                                                <Grid item xs={12}>
                                                    <DInput name="name" label="Business Name" required style={{maxWidth: isMobile?'90%':"95%"}} errorIf={invalidBusinessName} helperText={businessNameHelperText} />
                                                </Grid>
                                                {global.branding != "fis" &&
                                                    <Grid item xs={12}>
                                                        <DInput name="merchantId" label={`${brandCode ? brandCode : ''} MID`} required style={{maxWidth: isMobile?'90%':"95%"}} errorIf={invalidMID} helperText={MIDHelperText} />
                                                    </Grid>
                                                }
                                                <Grid item xs={12} sm={6}>
                                                    <DInput name="addressInfo.address" required label="Street Address" style={{maxWidth:'90%'}}/>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <DInput name="addressInfo.city" required label="City" style={{maxWidth:'90%'}}/>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <DInput name="addressInfo.state" required label="State" fieldType="Select" style={{maxWidth:'90%'}}>
                                                        <MenuItem disabled value=""><em>State</em></MenuItem>
                                                        {states.map((state) => <MenuItem value={state.value}>{state.label}</MenuItem>)}
                                                    </DInput>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <DInput name="addressInfo.zip" label="Zip" type="tel" required format='#####' errorIf={(value)=> {return !(/^\d{5}$/.test(value))}} helperText="Use 5 digit zip code" style={{maxWidth:'90%'}}/>
                                                </Grid>
                                                
                                                <Grid item xs={12}>
                                                    <DInput name="posType" label="Product Type" required rerender fieldType="Select" style={{maxWidth: isMobile?'90%':"95%"}}>
                                                        <MenuItem value="0" disabled>Please select the type of install</MenuItem>
                                                        {posTypes.map((type) => <MenuItem value={type.value}>{type.label}</MenuItem>)}
                                                    </DInput>
                                                </Grid>
                                                 {/* {global.branding == 'heartland' && */}
                                                {record.posType && record.posType.indexOf('quickbooks') > -1 &&
                                                    <Grid item xs={12}>
                                                        <DInput name="ein" label="EIN" type="tel" required format='##-#######' style={{maxWidth:'95%'}}/>
                                                    </Grid>
                                                }
                                                {achAvailable &&
                                                    <Grid item xs={12}>
                                                        <div style={{display:'flex', justifyContent:'flex-start', flexDirection:'column'}}>
                                                            <span variant="subtitle" style={{width:'100%', fontSize:12}}>
                                                                Payment Methods *
                                                            </span>
                                                            <div>
                                                                <DInput name="ccEnabled" label="Credit Cards" required={() => { return !record.getVal("ccEnabled") && !record.getVal("achEnabled") }} fieldType="checkbox" style={{maxWidth: isMobile?'90%':"95%"}}/>
                                                                <DInput name="achEnabled" label="Ach" required={() => { return !record.getVal("ccEnabled") && !record.getVal("achEnabled") }} fieldType="checkbox" style={{maxWidth: isMobile?'90%':"95%"}}/>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                }
                                                {products && products.length > 0 &&
                                                    <Grid item xs={12}>
                                                        <DInput name="product" label="Package Selection" required rerender fieldType="Select" required style={{maxWidth: isMobile?'90%':"95%"}}>
                                                            {products.map((prod) => <MenuItem value={prod.name}>{prod.name}</MenuItem>)}
                                                        </DInput>
                                                    </Grid>
                                                }
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" color="primary" style={{marginBottom: 15}}>
                                                        Primary Contact
                                                    </Typography>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                                            <DInput name="firstName" label="First Name" required style={{maxWidth: '90%'}}/>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                                            <DInput name="lastName" label="Last Name" required style={{maxWidth: '90%'}}/>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                                            <DInput name="email" label="Email" required style={{maxWidth: '90%'}} errorIf={invalidEmail} helperText={emailHelperText} />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={6} >
                                                            <DInput
                                                                required
                                                                format="(###) ###-####"
                                                                fieldType="phonenumber"
                                                                type="tel"
                                                                name="phoneNumber"
                                                                pattern="[0-9]*"
                                                                margin="normal"
                                                                label="Phone Number"
                                                                InputLabelProps={{ shrink: true }}
                                                                fullWidth={true}
                                                                // inputProps={{ maxLength: 14 }}
                                                                errorIf={invalidPhoneNumber}
                                                                helperText={phoneNumberHelperText}
                                                                style={{ paddingTop: 0, marginTop: '0px',maxWidth: '90%' }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                
                                               
                                                <Typography variant="h6" color="primary" style={{marginBottom: 15}}>
                                                    {brandCode} Rep
                                                </Typography>
                                                <Grid item xs={12}>
                                                    <DInput name="repName" label="Name" required style={{maxWidth:  isMobile?'90%':"95%"}} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <DInput name="repEmail" label="Email" required style={{maxWidth:  isMobile?'90%':"95%"}} errorIf={invalidEmail} helperText={emailHelperText} />
                                                </Grid>

                                                {global.branding == 'fis' && 
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={12} md={6} lg={6} >
                                                            <DInput
                                                                format="(###) ###-####"
                                                                fieldType="phonenumber"
                                                                type="tel"
                                                                name="repPhoneNumber"
                                                                pattern="[0-9]*"
                                                                margin="normal"
                                                                label="Phone Number"
                                                                InputLabelProps={{ shrink: true }}
                                                                fullWidth={true}
                                                                // inputProps={{ maxLength: 14 }}
                                                                errorIf={invalidPhoneNumber}
                                                                helperText={phoneNumberHelperText}
                                                                style={{ paddingTop: 0, marginTop: '0px',maxWidth: '90%' }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={6} >
                                                            <DInput name="repFisENumber" label="FIS e-Number" style={{maxWidth:'90%'}}/>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                {global.branding == "fis" && <>
                                                    <Typography variant="h6" color="primary" style={{marginBottom: 15}}>
                                                        Express Credentials
                                                    </Typography>
                                                    <Grid item xs={12}>
                                                        <DInput name="merchantId" label={`${brandCode ? brandCode : ''} Billing MID`} required style={{maxWidth: isMobile?'90%':"95%"}} errorIf={invalidMID} helperText={MIDHelperText} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <DInput name="expressAccountId" label={'Account Id'} required style={{maxWidth: isMobile?'90%':"95%"}} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <DInput name="expressTokenId" label={'Token Id'} required style={{maxWidth: isMobile?'90%':"95%"}} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <DInput name="expressAcceptorId" label={'Acceptor Id'} required style={{maxWidth: isMobile?'90%':"95%"}} />
                                                    </Grid>
                                                    <Typography variant="h6" color="primary" style={{marginBottom: 15}}>
                                                        Organizaton Details
                                                    </Typography>
                                                    <Grid item xs={12}>
                                                        <DInput name="agency" label="Channel (Agency)" required rerender fieldType="Select" required style={{maxWidth: isMobile?'90%':"95%"}}>
                                                            {fisAgencies.map((agency) => <MenuItem value={agency.name}>{agency.name}</MenuItem>)}
                                                        </DInput>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <DInput name="siteGroup" label="Group" style={{maxWidth: isMobile?'90%':"95%"}}/>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <DInput name="siteEnterprise" label="Enterprise" style={{maxWidth: isMobile?'90%':"95%"}}/>
                                                    </Grid>
                                                </>}
                                            </Grid>
                                        </Grid>
                                    </DForm2>
                                }
                                {!loadingAsync && formSubmitted &&
                                     <Grid item lg={12} style={{ textAlign: "center", padding: '8px', paddingTop: '12px' }}>
                                        <Typography variant="h4">
                                            SUCCESS!
                                        </Typography>
                                        <br/>
                                        <CheckCircleIcon color="primary" style={{ fontSize: 50}} />
                                        <br/>
                                        <Typography variant="h5" style={{padding:15}}>
                                            Your request has been submitted to DashNow
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>
                            
                            {!formSubmitted &&
                                <div item style={{padding:'10px 10px', display:'flex', justifyContent:'center',}} justify="space-between">
                                    <Button variant={'contained'} size="large" color="secondary" disabled={loadingAsync} onClick={() => submitForm()}>{loadingAsync? <CircularProgress color="white"/>:'Onboard Account'}</Button>
                                </div>
                            }
                            {formSubmitted &&
                                <div item style={{padding:'10px 10px', display:'flex', justifyContent:'center',}} justify="space-between">
                                    <Button variant={'contained'} size="large" color="secondary" disabled={loadingAsync} onClick={() => handleResubmit()}>Submit another</Button>
                                </div>
                            }

                        </Grid>


                    </Paper>
                </Grid>
            </Grid>
        </div>
	);
};


 


const fisAgencies = [
    {name:"Integrated Payments"},
    {name:"Core Direct  "},
    {name:"MSFI"},
    {name:"ISO"},
]
const states = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' }
]; 