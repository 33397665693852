import log from 'loglevel';
import axios from 'axios';
import dashFetchUpload from '../utils/dashFetchUpload';


export default async function (file, debug) {
	return new Promise( async(resolve, reject) => {
		log.info('uploadInvoicesApi api');
        const url = `${global.dashServerUrl}/dashboard/api/v1/billing/uploadInvoices`; 

		const formData = new FormData();
		formData.append('file', file);
		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}
		let response = await dashFetchUpload(url, formData)
		if (response.data.success) {
			resolve(response.data.data.invoiceDate);
		} else {
			resolve(false)
			log.info('FAILURE ')
		}
	})
}