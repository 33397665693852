import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import PropTypes from 'prop-types';
import SelectMui from '@material-ui/core/Select';
import { Container, Grid, TextField, Checkbox, Modal, Typography, Paper, FormControl, InputLabel, Button, TableSortLabel, TableHead, TableRow, TableCell, Table, TablePagination, Slide, CircularProgress, LinearProgress, IconButton, Menu, MenuItem, RadioGroup, Radio, FormControlLabel, FormLabel, TableContainer, Box, TableBody, useMediaQuery} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/core/styles';
import getSignupList from "../../actions/getSignupListApi";
import log from 'loglevel'
import { withRouter } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'
import DTableHead from '../../components/dash/DTableHead'
import InvoicePendingIcon from '@material-ui/icons/Autorenew';
import InvoiceOpenIcon from '@material-ui/icons/FilterNone'; 
import InvoiceClosedIcon from '@material-ui/icons/AttachMoney'; 
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import InputAdornment from '@material-ui/core/InputAdornment';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import getSignupListApi from '../../actions/getSignupListApi';
import theme from '../../theme';
  

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        padding: 15,
        height:'100%',
    },
    restaurantFieldInputItem: {
        width: '100%',
    },
	muiSelect: {
		width: '100%',
    },
    deletedRow: {
        color: '#DDD'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
})

const headCells = [
    { id: 'name', align: 'left', label: 'Group Name' },
    { id: 'siteName', align: 'left', label: 'Site Name' },
    { id: 'status', align: 'left', label: 'Status' },
    { id: 'createdAt', align: 'left', label: 'Created' },
    { id: 'updatedAt', align: 'left', label: 'Updated' },
    { id: 'agent', align: 'left', label: 'Agent' },
    { id: 'locationCount', align: 'left', label: '# of Locations' },
    { id: 'primaryContactAccount', align: 'left', label: 'Primary Contact' },
    { id: 'signupSource', align: 'left', label: 'Source' },
    { id: 'appName', align: 'right', label: 'App' },
];

const Admin = (props) => {

    let smallScreen = useMediaQuery(theme().breakpoints.down('sm'));

    let {classes} = props;
    let [order, setOrder] = React.useState('asc');
    let [orderBy, setOrderBy] = React.useState('restaurantName');
    let [anchorEl, setAnchorEl] = React.useState(null);
    let [signups, setSignups] = useState(null)
    let [selectedInvoice, setSelectedInvoice] = useState(null)
    let [showDiscountForm, setShowDiscountForm] = React.useState(false);
    let [page, setPage] = useState(0)
    let [rowsPerPage, setRowsPerPage] = useState(50)
    let [openModal, setOpenModal] = useState(false)
    let [file, setFile] = useState(null)
    let [filters, setFilters] = useState({appName:'Curbly'})

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
    
    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const handleOpen = (i) => {
        // console.log("i: " + i)
        // setSelectedInvoice(i);
        // setOpenModal(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

	useEffect(() => {
        fetchData()
    }, []);

    const fetchData = async () => {
        let signups = await getSignupListApi(filters, true)
        setSignups(signups)
        setPage(0)
    }


    return (

        <Grid container spacing={2} direction={smallScreen?"column":"row"} style={{height:'100%', padding:12}}>

            {!smallScreen &&
                <Grid item xs={smallScreen? false: true} container spacing={2} style={{height:smallScreen?"":'100%'}}>
                    <Grid item xs={12}>
                        <Paper elevation={1} style={{height:'100%'}}>
                            <Grid item xs={12} container justify="flex-start"  style={{padding: 15}}>

                                <Grid item style={{paddingBottom: 20, marginRight: 25}}>
                                    <FormControl style={{paddingRight:'10px'}}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                autoOk
                                                initialFocusedDate=""
                                                showTodayButton
                                                format="MM/dd/yyyy"
                                                id="date-picker-inline"
                                                label="Created From"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}    
                                                style={{width: 150}}
                                                value={filters.createdAtFrom ? filters.createdAtFrom : null}
                                                onChange={date => {setFilters({...filters, createdAtFrom: date})}} 
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </FormControl>
                                    <FormControl >
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                autoOk
                                                initialFocusedDate=""
                                                showTodayButton
                                                format="MM/dd/yyyy"
                                                id="date-picker-inline"
                                                label="Created Thru"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}    
                                                style={{width: 150}}
                                                value={filters.createdAtThru ? filters.createdAtThru : null}
                                                onChange={date => {setFilters({...filters, createdAtThru: date})}} 
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </FormControl>
                                </Grid>


                                <Grid item xs={12} style={{paddingBottom: 20}}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Status</FormLabel>
                                        <RadioGroup value={filters.status ? filters.status : 'all'} aria-label="status" name="customized-radios" onClick={e => {setFilters({...filters, status: e.target.value})}} style={{paddingLeft: 10}}>
                                            <FormControlLabel value="all" control={<Radio size="small" />} label="All" />
                                            <FormControlLabel value="enrolled" control={<Radio size="small" />} label="Enrolled" />
                                            <FormControlLabel value="ineligible" control={<Radio size="small" />} label="Ineligible" />
                                            <FormControlLabel value="isntalled" control={<Radio size="small" />} label="Installed" />
                                            <FormControlLabel value="live" control={<Radio size="small" />} label="Live" />
                                            <FormControlLabel value="cancelled" control={<Radio size="small" />} label="Cancelled" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} style={{paddingBottom: 20}}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Site</FormLabel>
                                        <RadioGroup value={filters.site ? filters.site : 'all'} aria-label="hasPrimaryContact" name="customized-radios" onClick={e => {setFilters({...filters, site: e.target.value})}} style={{paddingLeft: 10}}>
                                            <FormControlLabel value="all" control={<Radio size="small" />} label="All" />
                                            <FormControlLabel value="exists" control={<Radio size="small" />} label="Exists" />
                                            <FormControlLabel value="missing" control={<Radio size="small" />} label="Missing" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} style={{paddingBottom: 20}}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Primary Contact</FormLabel>
                                        <RadioGroup value={filters.hasPrimaryContact ? filters.hasPrimaryContact : 'all'} aria-label="hasPrimaryContact" name="customized-radios" onClick={e => {setFilters({...filters, hasPrimaryContact: e.target.value})}} style={{paddingLeft: 10}}>
                                            <FormControlLabel value="all" control={<Radio size="small" />} label="All" />
                                            <FormControlLabel value="exists" control={<Radio size="small" />} label="Exists" />
                                            <FormControlLabel value="missing" control={<Radio size="small" />} label="Missing" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} style={{paddingBottom: 20}}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Payment Method</FormLabel>
                                        <RadioGroup value={filters.hasPaymentMethod ? filters.hasPaymentMethod : 'all'} aria-label="hasPaymentMethod" name="customized-radios" onClick={e => {setFilters({...filters, hasPaymentMethod: e.target.value})}} style={{paddingLeft: 10}}>
                                            <FormControlLabel value="all" control={<Radio size="small" />} label="All" />
                                            <FormControlLabel value="exists" control={<Radio size="small" />} label="Exists" />
                                            <FormControlLabel value="missing" control={<Radio size="small" />} label="Missing" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} style={{paddingBottom: 10}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        // disabled={generatingFlag}
                                        onClick={fetchData}
                                    >
                                        Search
                                    </Button>
                                </Grid>

                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            }


            <Grid item xs md={9} container direction='column' spacing={2} alignItems="stretch" style={{height:'calc(100%-100px)', overflow:'hidden', paddingRight:smallScreen? 0: 'inherit'}}>
                <Grid item xs style={{width:'100%', height:'100%', overflow:'hidden', paddingRight:0}}>
                    <Paper elevation={1} style={{overflow:'hidden', height:'100%'}} >
                        <TableContainer style={{height: '100%', backgroundColor:'white'}}>
                            <Table size="small" aria-label="a dense table" stickyHeader style={{position: 'sticky'}} >  {/* height:'100%',  */}
                                <DTableHead
                                    classes={classes}
                                    order={order}
                                    headCells={headCells}
                                    style={{backgroundColor:'white'}}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                {signups && stableSort(signups, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(signup => {
                                    return (
                                        <TableRow id={signup._id} >
                                            <TableCell>
                                                {signup.name}
                                            </TableCell>
                                            
                                            <TableCell>
                                                {signup.siteName}
                                            </TableCell>

                                            <TableCell>
                                                {signup.status}
                                            </TableCell>
                                            
                                            <TableCell>
                                                {signup.createdAt ? moment(signup.createdAt).format('M/DD @ h:mma') : 'N/A'}
                                            </TableCell>
                                            
                                            <TableCell>
                                                {signup.updatedAt ? moment(signup.updatedAt).format('M/DD @ h:mma') : 'N/A'}
                                            </TableCell>

                                            <TableCell>
                                                {signup.agent}
                                            </TableCell>

                                            <TableCell>
                                                {signup.locationCount}
                                            </TableCell>
                                            
                                            <TableCell>
                                                {signup.primaryContactAccount}
                                            </TableCell>
                                        
                                            <TableCell>
                                                {signup.signupSource}
                                            </TableCell>
                                            
                                            <TableCell align="right">
                                                {signup.appName}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
                <Grid item style={{width:'100%', height:'75px'}}>
                    {signups && 
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={signups ? signups.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    }
                </Grid>
            </Grid>
        </Grid>

    );
};

Admin.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withRouter(withStyles(styles, { withTheme: true })(Admin));