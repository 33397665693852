import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, Tabs, Tab, Radio, RadioGroup, FormControlLabel, Checkbox, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import BillingAdmin from './pages/BillingAdmin'
// import BillingRestaurantInvoices from './pages/BillingRestaurantInvoices'

import log from 'loglevel'
import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
    root: {
        // display: 'flex',
        width: "100%",
        height: "100%",
        overflow: 'hidden',
    },
    tabs: {
        backgroundColor: 'var(--white)',
    }
})

const Billing = (props) => {
    const { classes } = props;
    let { page, subPage } = (props.match && props.match.params) ? props.match.params : props.props.match.params
    if (!subPage) {
        subPage = 'BillingAdmin'
    }
    const [activeTab, setActiveTab] = useState(subPage)

    const setPage = (pageName) => {
        props.history.push(`/admin/${page}/${pageName}`)
        setActiveTab(pageName);
        subPage = pageName
    }

	useEffect(() => {
        global.set({ headerTitle: 'BILLING' })
    }, []);
    
    return (
        <div className={classes.root}>
            <Box display="flex" flexDirection="column" style={{height: '100%'}}>
                <Box style={{ width:'100%', height: '100%'}} >
                    <Box style={{width: '100%', height: '100%'}}>
                        {subPage == 'BillingAdmin' && <BillingAdmin />}
                        {/* {activeTab == 2 && <BillingRestaurantInvoices />} */}
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

Billing.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withRouter(withStyles(styles, { withTheme: true })(Billing));