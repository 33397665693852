import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import log from 'loglevel';
import _ from 'lodash';
import {
	Grid,
	Button,
	CircularProgress,
	Container,
	Box,
	Paper,
	Typography,
	MenuItem, TextField
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from '../../theme'
import signupAffiliateApi from '../../actions/signupAffiliateApi';
import { urlParse } from '../../utils/urlParser'
import history from '../../history'
import getAffiliate from '../../actions/getAffiliate';
import DForm2 from '../../components/dash/DForm2';
import DRecord from '../../components/dash/DRecord';
import DInput from '../../components/dash/DInput';
import ReactGA from 'react-ga'
import accessUtils from '../../utils/accessUtils';

const headerHeight = 100
const activeButtonBarHeight = 0

const styles = (theme) => ({
	root: {
		backgroundColor: '#E86C00',
		overflow: 'hidden',
	},
	header: {
		position: 'absolute',
		top: 0,
		width: '100%',
		height: `${headerHeight}px`,
		zIndex: 11111,
		backgroundColor: '#E86C00',
	},
	content: {
		position: 'absolute',
		top: `${headerHeight}px`,
		bottom: `${activeButtonBarHeight}px`,
		width: '100%',
		overflow: 'auto',
	},
	box: {
		backgroundColor: 'var(--white)',
		border: '1px solid #EAEAEA',
		boxShadow: '0 0 10px 5px rgba(231,231,231,0.50)',
		padding: 30,
		marginBottom: 20,
		borderRadius: 6,
	},
	link: {
		textDecoration: 'none', 
		fontFamily: '"Open Sans", Arial, sans-serif',
		marginLeft: '24px',
		fontWeight: 600,
		fontSize: '14px',
		lineHeight: '14px',
		color: 'var(--white)',
	}
});

function camelize(str) {
	return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
		return index == 0 ? word.toLowerCase() : word.toUpperCase();
	}).replace(/\s+/g, '');
}

const Dash = (props) => {
	const GA_SIGNUP = "TextPay Affiliate Signup Form";
	const isMobile = useMediaQuery('(max-width:500px)')

	let [affiliate, setAffiliate] = useState({})
	const [signupSource, setSignupSource] = global.get('signupSource')
	const { classes } = props;
	const [posTypes, setPosTypes] = useState([]);
	const [saving, setSaving] = useState(false)
	const [loadingAsync, setLoadingAsync] = useState(true)

	let params = urlParse(document.location).params

	const [record, setRecord] = useState({
		appName: global.isCurbly() ? 'Curbly' : global.isTextPay() ? 'TextPay' : 'DashNow',
		signupSource: signupSource,
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
	})

	const submit = async () => {
		log.info('submitting form')
		setLoadingAsync(true)
		// await publishApi(request)
		setLoadingAsync(false)
	}

	let saveForm = () => {
        setSaving(true)
		ReactGA.event({
			category: GA_SIGNUP,
			action: "Affiliate Signup Form Submitted"
		});
		let onValid = async () => {
			setSaving(false)
			let recordCopy = {...record}
			delete recordCopy.dform
			recordCopy.affiliate = affiliate ? affiliate._id : null
			let resp = await signupAffiliateApi(recordCopy)
			if (resp) {
				delete localStorage.lastRequestId
				let newAccount = resp.account
				history.push('/login?a=' + newAccount._id + '&e=' + recordCopy.email)
			}
		}
		let onError = async () => {
			setSaving(false)
		}
		global.set({onValid, onError, validateNow: true})
	};


	useEffect(() => {
		accessUtils.logout(false)

		let updated = false
		if (params.src && signupSource != params.src) {
			setSignupSource(params.src)
			updated = true
		}
		
		async function fetchdata() {
			if (params.ref) {
				let data = await getAffiliate(params.ref);
				if (data) {
					affiliate = data
					setAffiliate(affiliate)
				}
			}
			setLoadingAsync(false)
		}
		fetchdata()
		ReactGA.event({
			category: GA_SIGNUP,
			action: "Affiliate Signup Initiated"
		});
		global.set({ headerTitle: 'SIGNUP' })

		if (updated) {
			setRecord({...record, signupSource: params.src})
		}

	}, [])

	const sm = useMediaQuery(theme().breakpoints.down('sm'));
	const xs = useMediaQuery(theme().breakpoints.down('xs'));

	return (
		<div className={classes.root}>
			<Box className={classes.header}>
				<Container maxWidth={sm ? 'sm': xs ? 'xs' : false} style={{height: '100%', maxWidth: '1080px', paddingRight: 20}} disableGutters={true}>
					<Grid container justify="space-between" alignItems="center" style={{height: '100%', width: '100%'}}>
						<Grid item xs={6}>
							<a href="https://local.textpay.app:5500/">
								<img src="https://www.textpay.app/images/logo/textpay-logo-dark.svg" alt="Curbly" id="logo" style={{height: 100, float: 'left'}} />
							</a>
						</Grid>
						<Grid item xs={6} justify="flex-end" container>
							<a href="https://curbly.app" className={classes.link}>Home</a>
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Box className={classes.content}>
				<Container maxWidth={sm ? 'sm': xs ? 'xs' : false} style={{maxWidth: '1080px'}} disableGutters={true}>
					<Grid container justify="center" spacing={0}>
						<Grid>
							<Container>
								{loadingAsync && <Grid container justify="center"><CircularProgress /></Grid>}
								{!loadingAsync && 

									<DForm2 record={record} >
										<Grid container>
											<Grid container>
												<Grid item xs={12}>
													<Typography variant="h4" color="primary" style={{marginTop: 25, marginBottom: 15}}>
														Signup Today!
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<Paper className={classes.box}>
														<Grid container spacing={3}>

															<Grid item xs={12}>
																<Typography variant="h6" color="primary" style={{marginBottom: 15}}>
																	Please tell us about you
																</Typography>
																<Grid container spacing={3}>
																	<DRecord record={record} />
																	<Grid item xs={12} sm={6} md={6} lg={6}>
																		<div>
																			<DInput name="firstName" label="First Name" required />
																		</div>
																	</Grid>
																	<Grid item xs={12} sm={6} md={6} lg={6}>
																		<div>
																			<DInput name="lastName" label="Last Name" required />
																		</div>
																	</Grid>
																	<Grid item xs={12} sm={12} md={6} lg={6}>
																		<div>
																			<DInput required type="phone" label="Mobile Phone" name="phone" />
																		</div>
																	</Grid>
																	<Grid item xs={12} sm={12} md={6} lg={6}>
																		<div>
																			<DInput name="email" type="email" label="Email" required />
																		</div>
																	</Grid>
																</Grid>
															</Grid>
															<Grid item xs={12}>
																<Button variant={'contained'} color="primary" onClick={event => { saveForm() } } type='submit'>Sign Up</Button>
															</Grid>
														</Grid>
													</Paper>
												</Grid>
											</Grid>
										</Grid>
									</DForm2>

								}
							</Container>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</div>
	);
};

Dash.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withStyles(styles, { withTheme: true })(Dash);
