import { Grid, Typography, useMediaQuery, withStyles } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PersonIcon from '@material-ui/icons/Person';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import theme from '../../theme'
import moment from 'moment'


const styles = (theme) => ({
    header: {
        width: '100%', 
        height: '110px', 
        overflow: 'hidden', 
        borderBottom: 'solid 1px var(--divider)', 
        backgroundColor: 'var(--background)',
        overflowX: 'hidden', 
        overflowY: 'hidden',
    },
    headerLabel: {
        color: 'var(--dark-gray)', 
        fontSize: '1.2em',
        float: 'left',
        display: 'block',
    },
});


const SiteHeader = (props) => {
   let restaurant = props.site 
   const isMobile = useMediaQuery(theme().breakpoints.down('xs'));
   const { classes } = props;
   
    return (<>
       <Grid container alignItems="flex-start" className={classes.header}>
            <Grid item style={{width: '100%', padding: 14, paddingLeft: 20}}>

                <Grid item xs={12} style={{padding: 0, lineHeight: 1}}>
                    <Typography style={{color: 'var(--dark-gray)', fontWeight: '400', fontSize: '1.9em', lineHeight: 1}} disableGutters>{restaurant.name}</Typography>
                </Grid>

                <Grid item xs={12} style={{paddingTop: 6, lineHeight: 1, height: 25}}>
                    <Typography style={{color: 'var(--gray)', fontWeight: '400', fontSize: '1.2em', lineHeight: 1}} disableGutters>
                        {restaurant.locationInfo.addressLine1 && restaurant.locationInfo.addressLine1}
                        {restaurant.locationInfo.addressLine2 && '  ' + restaurant.locationInfo.addressLine2}
                        {restaurant.locationInfo.city && ' ' + restaurant.locationInfo.city}
                        {restaurant.locationInfo.state && ', ' + restaurant.locationInfo.state}
                        {restaurant.locationInfo.zipCode && ' ' + restaurant.locationInfo.zipCode}
                    </Typography>
                </Grid>

                <Grid item alignItems="center" container xs={12} style={{paddingTop: 10, lineHeight: 1}}>
                    <Grid item>
                        <Typography className={classes.headerLabel}>Code:</Typography>
                        <Typography className={classes.headerLabel} style={{paddingLeft: 5, color: 'var(--purple)'}}>{global.f(restaurant, 'code', '').toUpperCase()}</Typography>
                    </Grid>
                    <Grid item style={{paddingLeft: 30}}>
                        <Typography className={classes.headerLabel}>Status:</Typography>
                        <Typography className={classes.headerLabel} style={{paddingLeft: 5, color: 'var(--purple)'}}>{global.f(restaurant, 'status', '').toProperCase()}</Typography>
                    </Grid>
                    {!isMobile && 
                        <>
                        <Grid item style={{paddingLeft: 30}}>
                            <Typography className={classes.headerLabel}>Enrolled:</Typography>
                            <Typography className={classes.headerLabel} style={{paddingLeft: 5, color: 'var(--purple)'}}>{restaurant && restaurant.dateEnrolled ? moment(restaurant.dateEnrolled).format('L') : ''}</Typography>
                        </Grid>
                        {/* <Grid item style={{paddingLeft: 30}}>
                            <Typography className={classes.headerLabel}>POS:</Typography>
                            <Typography className={classes.headerLabel} style={{paddingLeft: 5, color: 'var(--purple)'}}>
                                {restaurant.getVal('posType', false) ? global.ifNull(posTypes.find(a => a._id == restaurant.posType._id), {}).posType : ''}
                            </Typography>
                        </Grid> */}
                        <Grid item style={{paddingLeft: 30}}>
                            <PersonIcon />
                        </Grid>
                        <Grid item style={{paddingLeft: 5}}>
                            <Typography className={classes.headerLabel}>Primary:</Typography>
                            <Typography className={classes.headerLabel} style={{paddingLeft: 5, color: 'var(--purple)', minWidth: '100px'}}>
                                {restaurant.getVal('primaryContactAccount.fullName')}
                            </Typography>
                        </Grid>
                        <Grid item style={{paddingLeft: 30}}>
                            <PeopleAltIcon />
                        </Grid>
                        <Grid item style={{paddingLeft: 7}}>
                            <Typography className={classes.headerLabel}>Agent:</Typography>
                            <Typography className={classes.headerLabel} style={{paddingLeft: 5, color: 'var(--purple)', minWidth: '100px'}}>
                                {restaurant.getVal('agent.fullName')}
                            </Typography>
                        </Grid>
                        </>
                    }
                </Grid>
            </Grid>
        </Grid>
    </>)
}
SiteHeader.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withStyles(styles, { withTheme: true })(SiteHeader);