import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import addDiscountToInvoiceApi from "../../../actions/addDiscountToInvoiceApi";
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import theme from '../../../theme';
import { TextField } from '@material-ui/core';

export default function FormDialog(props) {

    let [values, setValues] = React.useState({ amount: 0, priceType: 'amount'})
    let [error, setError] = React.useState(false)
    let subTotal = props.getVal('invoice.totals.subTotal', 0)/100

    const addDiscountToInvoice = async (event) => {
        if (props.invoice._id && values.description && values.amount && values.priceType) {
            let invoice = await addDiscountToInvoiceApi(props.invoice._id, values.description, values.amount, values.priceType)
            if (invoice) {
                global.showNotification('Successfully added discount to invoice', 'success')
                props.close(invoice)
            }
        } else {
            global.showNotification('Missing data')
        }
    }

    useEffect(() => {
        let discountFriendly = ""
        let amount = 0
        if (values.amount) {
            amount = Number(values.amount)    
        }
        if (values.priceType == 'percent') {
            discountFriendly = `($${(amount).toFixed(0)})`
            
        } else {
            discountFriendly = `(${(amount*100/subTotal).toFixed(0)}%)`
        }

        setError(amount <= 0 || (values.priceType == "percent" && amount > 100) || values.priceType == "amount" && amount > subTotal)
        setValues({...values, discountFriendly})
        
    }, [values.amount, values.priceType])

    const updateField = (e) => {
        console.log(e)
        values = {...values}
        values[e.target.id] = e.target.value
        setValues(values)
    }

    return (
        <div>
            {props.invoice && 
                <Dialog open={props.open} onClose={() => { props.close() }} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Add Discount</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            How much discount would you like to apply to invoice #{props.invoice.invoiceNumber}?
                        </DialogContentText>

                        <FormControl fullWidth style={{marginBottom: 35}}>
                            <InputLabel htmlFor="standard-adornment-description">Description</InputLabel>
                            <Input autoFocus margin="dense" id="description" label="Description" fullWidth onChange={updateField} style={{marginRight: 15}} error={!values || !values.description}/>
                        </FormControl>

                        
                        <Grid container style={{marginBottom: 35}} alignItems="center">
                            <Grid item xs={5}>
                                <FormControl fullWidth style={{marginTop: 10, width:'100%'}}>
                                    <TextField
                                        InputProps={{margin:'dense', startAdornment: values.priceType != 'percent' && <InputAdornment position="start">$</InputAdornment>, endAdornment: values.priceType == 'percent' && <InputAdornment position="end">%</InputAdornment>}}
                                        id="amount"
                                        label="Discount Amount" 
                                        type="number" 
                                        error={error}
                                        onChange={updateField} style={{width: 200}}
                                        InputLabelProps={{shrink:true}}
                                        label={"Discount Amount " + values.discountFriendly }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={7}>
                                <FormControl fullWidth>
                                    <ToggleButtonGroup exclusive size="small" value={values.priceType}>
                                        <ToggleButton style={{ color: global.primaryColor }} key={0} style={{ background: 'white'}} value='amount' onClick={() => {updateField({target: {id: 'priceType', value: 'amount'}})}}>
                                            &nbsp;$&nbsp; 
                                        </ToggleButton>
                                        <ToggleButton style={{ color: global.primaryColor }} key={0} style={{ background: 'white'}} value='percent' onClick={() => {updateField({target: {id: 'priceType', value: 'percent'}})}}>
                                            %&nbsp;
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </FormControl>
                            </Grid>
                        </Grid>


                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => { props.close() }} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={addDiscountToInvoice} disabled={error || !values || !values.description} color="primary">
                        Add Discount
                    </Button>
                    </DialogActions>
                </Dialog>
            }
        </div>
    )
}