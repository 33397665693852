import log from 'loglevel';
import dashFetch from '../utils/dashFetch';


export default async function (invoiceId, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('payInvoiceApi');
		const url = `${global.dashServerUrl}/dashboard/api/v1/billing/payInvoice`;

		const body = {
            invoiceId
		};

		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info('>>> payInvoiceApi JSON');
			log.info(json)
			if (json.success) {
				resolve(json.data);
			} else {
				if (json.error) {
					global.showNotification(json.error, 'error')
				}
				reject(json.error);
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}