import log from 'loglevel';
import _ , { rest } from 'lodash'
import moment from 'moment'
import accessUtils from '../utils/accessUtils';
import getRestaurantCheckListApi from '../actions/getRestaurantCheckListApi';

export default async function (restaurantId, dashboardAutoFetchInterval = 0, checkLookbackInDays = 1, checkLookbackDateRange, debug) {
    let checks = []
	let restaurantCheckIns = []
    let logs = {}
    return new Promise((resolve, reject) => {
        
        let timer = null;
        let lastUpdatedAt

        const getCheckList = async () => {
            log.info(' Getting check list')
            let newChecks
            if (lastUpdatedAt && debug) log.info('refreshing due to restaurant refetch interval')
            let newChecksData = await getRestaurantCheckListApi(restaurantId, lastUpdatedAt, checkLookbackInDays, checkLookbackDateRange)
            if (newChecksData) {
                if (newChecksData.checks) {
                    newChecks = newChecksData.checks
                }
                if (newChecksData.lastUpdatedCheckTime) {
                    lastUpdatedAt = newChecksData.lastUpdatedCheckTime
                }
            }

            if (checks && checks.length > 0) {
                addUpdateChecks(newChecks)
            } else {
                checks = newChecks
                global.set({checks})
            }
        }
        
        const addUpdateChecks = (incomingChecks = []) => {
            for (let incomingCheck of incomingChecks) {
                let index = _.findIndex(checks, { _id: incomingCheck._id })
                if (index > -1) {
                    console.log("UPDATING CHECK: " + incomingCheck._id)
                    let existingCheck = checks[index]
                    checks.splice(index, 1, incomingCheck);
                    if (existingCheck) {
                        if (!existingCheck.pickupDetails.arrivedAt && incomingCheck.pickupDetails.arrivedAt && !incomingCheck.pickupDetails.pickedUpAt && !existingCheck.pickupDetails.pickedUpAt) {
                            global.set({newCustomerArrival: true})
                        }
                    }
                } else {
                    console.log("ADDING CHECK: " + incomingCheck._id)
                    checks.push(incomingCheck) 
                }
            }
            checks = _.orderBy(checks, [(global.appName == "TextPay" ? 'updatedAt' : 'createdAt')], ['desc'])
            global.set({checks})
        }

        const addLog = (restaurantStationId, newLog) => {
            if (!logs[restaurantStationId]) {
                logs[restaurantStationId] = []
            }
            logs[restaurantStationId].push(newLog)
            global.set({logs})
        }

        const addUpdateRestaurantCheckIns = (addUpdatedRestaurantCheckIns = []) => {
			global.set({addUpdatedRestaurantCheckIns})
        }

        if (global.restaurantClient) {
            console.log("CLOSE WS BEFORE OPENING NEW ONE")
            global.restaurantClient.onclose = null
            global.restaurantClient.close()
            global.restaurantClient = null
            clearInterval(timer);
            if (global.autoRefetchTimer) clearInterval(global.autoRefetchTimer)
        }

        let firstConnect = true

        const connect = () => {
            console.log("OPENING WEB SOCKET CONNECTION TO CHANNEL: " + restaurantId)
            let token = localStorage.token
            // log.info("CONNECTING TO WS WITH TOKEN: " + token)
            const client = new WebSocket(`${global.dashServerWsUrl}?channel=${restaurantId}&token=${token}`);
            global.restaurantClient = client
            if (firstConnect) {
                firstConnect = false
                getCheckList()
            }

            client.onopen = () => {
                log.info('Opening websocket connection')
                //ping the websocket to prevent the automatic 60 second timeout from ELB

                timer = setInterval(() => {
                    client.send("ping")
                }, 30000);

                if (dashboardAutoFetchInterval && dashboardAutoFetchInterval > 0) {
                    global.autoRefetchTimer = setInterval(() => {
                        getCheckList()
                    }, dashboardAutoFetchInterval * 1000);
                }
            }

            client.onclose = () => {
                log.info('Closing websocket connection')
                clearInterval(timer);
                if (global.autoRefetchTimer) clearInterval(global.autoRefetchTimer)
                //ATTEMPT TO AUTO RE-CONNECT TO THE WS SERVER EVERY 2 SECONDS IF DISCONNECTED
                setTimeout(async function () {
                    connect()
                }, 2000)
            }

            client.onmessage = async (message) => {
                let json = JSON.parse(message.data)
                if (json.action == 'newCheck') {
                    console.log("NEW/UPDATED CHECK JSON")
                    console.log(JSON.stringify(json.data.check))

                    let incomingCheck = json.data.check
                    let updatedCheck = false

                    addUpdateChecks([incomingCheck])
                } else if (json.action == 'updateDashbaord' || json.action == 'updateDashboard') { // Yes. Tyler is dumb and he cant spell
                    document.location.reload();
                } else if (json.action == 'logoutDashbaord') {
                    accessUtils.logout()
                } else if (json.action == 'restaurantCheckIn') {
                    let restaurantCheckIn = json.data.restaurantCheckIn
                    console.log("NEW/UPDATED CheckIn JSON")
                    console.log(JSON.stringify(restaurantCheckIn))
                    addUpdateRestaurantCheckIns([restaurantCheckIn])
                } else if (json.action == 'newLog') {
                    addLog(json.data.restaurantStationId, json.data.content)
                }
                
            }
        }
        connect()

        resolve(true);

    })
    

}