import React, { useState, useEffect } from 'react';
import { Grid, Dialog, DialogActions, Button, Typography, Checkbox, Table, TableRow, TableCell, TableBody } from '@material-ui/core';
import flagCheckAsCompletedApi from '../../actions/flagCheckAsCompletedApi';
import log from 'loglevel'
import moment from 'moment'
import DashCard from './DashCard';
import theme from '../../theme'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import alertCustomerForOrderUpApi from '../../actions/alertCustomerForOrderUpApi';
import undoFlagCheckAsCompletedApi from '../../actions/undoFlagCheckAsCompletedApi';
import reprintPaymentReceiptApi from '../../actions/reprintPaymentReceiptApi';
import RefundRequestWindow from './RefundRequestWindow';
import accessUtils from '../../utils/accessUtils';

const styles = {
	infoBanner: {
		padding: 5,
		paddingTop: 15
	},
	paper: {
		padding: 10,
		width: '100%',
		height: '100%'
	},
	table: {
	}
}


export default function (props) {
	// alert(JSON.stringify(props.permissions))
	const [check, setCheck] = useState(props.check)
	const [orderUpEnabled] = global.get('orderUpEnabled')
	const [restaurant] = global.get('restaurant')
	const [showPaymentDetail, setShowPaymentDetail] = useState(false)
	const [windowContent, setOverlayWindowContent] = useState()
	let signedIn = accessUtils.isLoggedIn()
	
	const isCallAheadCheck = (check.pickupDetails && check.pickupDetails.phoneNumber)
	const isComplete = (check.status == 'completed')
	// const tip = (check.totals && check.totals.tipsDashNow) ? Number(((check.totals.tipsDashNow) / 100)).toFixed(2) : 0
	// const loyalty = (check.totals && check.totals.loyaltyRewardsAmount) ? Number(((check.totals.loyaltyRewardsAmount) / 100)).toFixed(2) : 0
	// const paidDashNow = (check.totals && check.totals.paidDashNow) ? Number(((check.totals.paidDashNow) / 100)).toFixed(2) : 0
	// const loyaltyTaxAmountRemoved = (check.totals && check.totals.loyaltyTaxAmountRemoved) ? Number(((check.totals.loyaltyTaxAmountRemoved) / 100).toFixed(2)) : 0
	// const total = (Number(paidDashNow) + Number(tip) - Number(loyalty) - Number(loyaltyTaxAmountRemoved)).toFixed(2)
	
	// const taxesPaid = (check.totals && check.totals.taxesPaid) ? Number(((check.totals.taxesPaid) / 100).toFixed(2)) : 0
	let tmpPayment
	let tipTotal = 0
	let loyaltyTotal = 0
	let taxesTotal = 0
	let dashTotal = 0
	let lastPaymentTime
	if (check.payments) {
		for (let payment of check.payments) {
			loyaltyTotal += payment.loyaltyRewardsAmount
			taxesTotal += payment.tax
			tipTotal += payment.tipAmount
			dashTotal += payment.paymentAmount
			
		}
		tmpPayment = check.payments.sort((a, b) => moment(a.createdAt) - moment(b.createdAt))[0]
		if (tmpPayment) {
			lastPaymentTime = tmpPayment.createdAt
		}
		loyaltyTotal = Number(loyaltyTotal/100).toFixed(2)
		taxesTotal = Number(taxesTotal/100).toFixed(2)
		tipTotal = Number(tipTotal/100).toFixed(2)
		dashTotal = Number(dashTotal/100).toFixed(2)
	}

	let paidAt = lastPaymentTime ? moment(lastPaymentTime).fromNow() : 'N/A'
	
	const smallScreen = useMediaQuery(theme().breakpoints.down('sm'));
	console.log("smallScreen: " + smallScreen)

	const sendPickedUp = async (check) => {
		let data = await flagCheckAsCompletedApi(check._id)
		if (data) {
			props.close()
		}
	}

	const sendOrderUp = async (check) => {
		let data = await alertCustomerForOrderUpApi(check._id)
		if (data) {
			props.close()
		}
	}

	const sendUndoComplete = async (check) => {
		let data = await undoFlagCheckAsCompletedApi(check._id)
		if (data) {
			props.close()
		}
	}
	
	const reprintPaymentReceipt = async (check) => {
		let data = await reprintPaymentReceiptApi(check)
		if (data) {
			props.close()
		}
	}

	const showRefundWindow = async (check) => {
		setOverlayWindowContent(
			<RefundRequestWindow check={check} close={closeOverlayWindow} />
		);
	}

    const closeOverlayWindow = () => {
      setOverlayWindowContent(null);
    };


	useEffect(() => {
	}, [])



	return (
		<Dialog
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={true}
			onClose={ props.close }
			PaperProps={smallScreen ? {style:{margin: 0, width: '100%'}} : {style: {minWidth: 600}}}
		>
			<DialogActions style={{padding: 0 }}>
				<Grid container xs={12}>
					<Grid item xs={12}>
						<Button style={{padding: '10px 0px', float: 'right', fontSize: '1.2em'}} onClick={props.close}>
							X
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
			{check && 
				<Grid container style={{ width: '100%', overflow: 'scroll', padding: smallScreen ? '0px 10px' : '0px 20px' }}>
					<Grid item style={{ width: '100%' }} lg={12} onClick={() => setShowPaymentDetail(!showPaymentDetail)}>
						<DashCard paymentDetail={true} showPaymentDetail={showPaymentDetail} data={check}/>
					</Grid>
					<Table>
						{tipTotal > 0 &&
							<TableRow>
								<TableCell>
									<Typography>Tip:</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography variant="h6">${tipTotal}</Typography>
								</TableCell>
							</TableRow>
						}
						{loyaltyTotal > 0 &&
							<TableRow>
								<TableCell>
									<Typography>Loyalty Amount:</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography variant="h5">${loyaltyTotal}</Typography>
								</TableCell>
							</TableRow>
						}
						{dashTotal > 0 &&
							<TableRow>
								<TableCell>
									<Typography>Total Paid:</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography variant="h4">${dashTotal}</Typography>
									<Typography variant="caption">{paidAt}</Typography>
								</TableCell>
							</TableRow>
						}
					</Table>
				</Grid>
			}
			<DialogActions style={{borderTop: '1px solid var(--divider)', padding: '5px 10px' }}>
				<Grid container>
					
					<Grid containter direction="column" style={{ padding: 5 }} item xs={12} sm={6} md={6} lg={6}>
						{!isComplete && orderUpEnabled && isCallAheadCheck && !check.pickupDetails.orderUpAt &&
							<Button style={{ width: '100%', height: 'auto', marginBottom: 10, float: 'left' }} color="primary" variant="contained" onClick={() => { sendOrderUp(check); }}>
								Order Ready (Alert Customer)
							</Button>
						}
						{!isComplete && orderUpEnabled && isCallAheadCheck && check.pickupDetails.orderUpAt &&
							<Button style={{ width: '100%', height: 'auto', marginBottom: 10, float: 'left' }} color="primary"  onClick={() => { sendOrderUp(check); }}>
								Order Ready (Resend Alert)
							</Button>
						}
						{dashTotal > 0 && 
							<Button style={{ width: '100%', height: 'auto', float: 'left' }} color="primary" variant="outlined" onClick={() => { reprintPaymentReceipt(check); }}>
								Reprint Payment Slip
							</Button>
						}
					</Grid>
					<Grid container direction="column" style={{padding:5}} item xs={12} sm={6} md={6} lg={6}>
						{!isComplete && (isCallAheadCheck || !restaurant.backOffice) &&
							<Button style={{ width: '100%', height: 'auto', float: 'right', marginBottom: 10}} color="primary" variant="contained" onClick={() => { sendPickedUp(check); }}>
								Complete (Picked Up)
							</Button>
						}
						{isComplete && (isCallAheadCheck || !restaurant.backOffice) &&
							<Button style={{ width: '100%', height: 'auto', float: 'right', marginBottom: 10}} color="primary" variant="contained" onClick={() => { sendUndoComplete(check); }}>
								Undo Complete (Not Picked Up)
							</Button>
						}
						{dashTotal > 0 && signedIn && global.hasPermission('restaurant.refund') && 
							<Button style={{ width: '100%', height: 'auto', float: 'right' }} color="primary" variant="outlined" onClick={() => { showRefundWindow(check); }}>
								Refund Payment
							</Button>
						}
					</Grid>
				</Grid>
			</DialogActions>
			{windowContent}
		</Dialog>
	)

}
