import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import { Grid, Paper, Drawer, Zoom, Divider, Stepper, Step, StepButton, Tooltip, Button, IconButton, MenuItem, LinearProgress, CircularProgress, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import theme from '../theme'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import log from 'loglevel'
import history from '../history.js'
import _ from 'lodash';
import DForm2 from '../components/dash/DForm2';
import DRecord from '../components/dash/DRecord';
import DInput from '../components/dash/DInput';
import DFlag from '../components/dash/DFlag';
import DeleteIcon from '@material-ui/icons/HighlightOff';
import getPosTypesApi from '../actions/getPosTypesApi';
import getRestaurantGroupsApi from '../actions/getRestaurantGroupsApi';
import addLocationApi from '../actions/addLocationApi';
import signAgreementAndGoLiveApi from '../actions/signAgreementAndGoLiveApi';
import uploadRestaurantLogoApi from '../actions/uploadRestaurantLogoApi';
import { Document, Page } from 'react-pdf';
import PaymentMethod from './components/PaymentMethod';
import AddCardPanel from './components/AddCardPanel';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ReactGA from 'react-ga';
import LockIcon from '@material-ui/icons/Lock';
import PublishIcon from '@material-ui/icons/Publish';
import upsertBranding from '../actions/upsertBrandingApi';

const useStyles = makeStyles((theme) => ({
    stepper: {
        width: '100%',
		'&$selected': {
            backgroundColor: '#8B6BD9',
            fontWeight: 600,
		}
    },
    
}))


export default (props) => {
    const classes = useStyles();
    const [restaurant] = global.get('restaurant')
    const [restaurants] = global.get('restaurants')

    const isMobile = useMediaQuery('(max-width:600px)')
    const defaultPadding = isMobile? 15 : 25
    const [record, setRecord] = useState({
        _id: null,
        restaurantGroup: null,
        name: '',
        posType: null,
        posTypeOther: '',
        askForVehicleInfo: false,
        askForPickupLocations: false,
        pickupLocations: [{}, {}],
        branding: {enabled: false, logo: '', headerLogo: '', splashLogo: ''},
    }) 

    let [retryCount, setRetryCount] = useState(0)


    let smallScreen = useMediaQuery(theme().breakpoints.down('sm'));
    let mediumScreen = useMediaQuery(theme().breakpoints.down('md'));

    const GA_SIGNUP = "Curbly Signup";

    const [pdfFile, setPdfFile] = useState(true)
    const [loadingAsync, setLoadingAsync] = useState(true)
    const [verifying, setVerifying] = useState(false)
    const [verified, setVerified] = useState()
    const [posTypes, setPosTypes] = useState([]);
    const [showStepper, setShowStepper] = useState(true)
    const [showButtons, setShowButtons] = useState(true)
	const [showBackButton, setShowBackButton] = useState(false)
    const [showNextButton, setShowNextButton] = useState(true)
    const [editingPaymentMethod, setEditingPaymentMethod] = useState(true)
    
    const [activeStep, setActiveStep] = useState(0);
    const [steps, setSteps] = useState(['Location Details', 'Install', 'Payment Info', 'Agreement'])
    const [stepName, setStepName] = useState('Company Info');
    const [completed, setCompleted] = useState(new Set());

    const [logoPreview, setLogoPreview] = useState('');
    const [logo, setLogo] = useState(record.branding.logo);


    const handleStep = (step) => {
		setActiveStep(step);
		setStepName(steps[step])
			
		let showBackBtn = (step > 0)
		let showNextBtn = true
		setShowBackButton(showBackBtn)
		setShowNextButton(showNextBtn)
		if (showBackBtn || showNextBtn) {
			setShowButtons(true)
		}
	};
	
	let backStep = (step) => {
		if (step > 0) {
			handleStep(--step);
		} else {
			setShowStepper(true)
		}
    };
    
    let generatePdf = async (event) => {
        if (restaurant) {
            const url = `${global.dashServerUrl}/pdf/agreement/${restaurant._id}`;
            let options = {
                method: 'GET',
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/pdf',
                }
            }
            fetch(url, options, false).then((res => res.blob())).then(blob => {
                if (blob) {
                    setPdfFile(window.URL.createObjectURL(blob))
                } else {
                    //error
                }
            }).catch((err) => {
                log.error(err)
            })
        }
    }

	let nextStep = (step) => {
		console.log("forward step")
		step++
		let onValid = async () => {
            setLoadingAsync(true)

            if (activeStep == 0) {
                let recordOut = {...record}
                recordOut.posType = recordOut.posType && recordOut.posType != -1 ? recordOut.posType : null
                recordOut.posTypeOther = recordOut.posTypeOther && recordOut.posType == -1 ? recordOut.posTypeOther : ''
                recordOut.config = {features: {imHere: {available: true, enabled: true, selectVehicleEnabled: record.askForVehicleInfo, selectLocationEnabled: record.askForPickupLocations, pickupLocations: record.pickupLocations.map(r => r.location)}}}
                if (logo) {
                    recordOut.branding.enabled = true
                }
                delete recordOut.pickupLocations
                delete recordOut.askForVehicleInfo
                delete recordOut.askForPickupLocations
                delete recordOut.dform

                let restaurant = await addLocationApi(recordOut)
                if (restaurant) {
                    // restaurants.push(restaurant)
                    // global.set({restaurant, restaurantId: restaurant._id, restaurants})
                    if (logo && logo !== record.branding.logo) {
                        upsertBranding(restaurant._id, 'restaurant', { logo: logo, headerLogo: logo })
                    }
                    global.set({restaurant, restaurantId: restaurant._id, refreshRestaurants: true})
                    handleStep(step);
                }
                ReactGA.event({
                    category: GA_SIGNUP,
                    action: `New Location Added, success: ${!!restaurant}`
                });
            } else if (activeStep == 1 && verified) {
                handleStep(step);
                ReactGA.event({
                    category: GA_SIGNUP,
                    action: `New Location Install, success: true`
                });
            } else if (activeStep == 3) {
                let success = await signAgreementAndGoLiveApi(restaurant._id)
                if (success) {
                    let restaurantNew = {...restaurant}
                    restaurantNew.status = 'live'
                    restaurantNew.goLiveDate = new Date()
                    let restaurantsNew = [...restaurants]
                    restaurantsNew.replaceItem(restaurantNew)
                    localStorage.showTutorial = true
                    global.set({restaurant: restaurantNew, restaurants: restaurantsNew, showTutorial: localStorage.showTutorial})
                }
                ReactGA.event({
                    category: GA_SIGNUP,
                    action: `New Location Signed, success: ${success}`
                });
            } else {
                handleStep(step);
            }

            setLoadingAsync(false)
		}
		global.set({onValid: onValid, validateNow: true})
    }

    let verifyInstall = () => {
        setVerifying(true)
        global.set({refreshRestaurants: true})
    }
    
    let cancelPaymentForm = () => {
        setEditingPaymentMethod(false)
    }
    
    let expYear = restaurant && restaurant.billingInfo && restaurant.billingInfo.encryptedCard ? restaurant.billingInfo.encryptedCard.expYear : ''
	if (expYear && expYear > 2000) {
		expYear = expYear - 2000
	}
    
    function isStepComplete(step) {
		return completed.has(step);
	}

    useEffect(() => {
		async function fetchdata() {
			let data = await getPosTypesApi();
			if (data) {
				setPosTypes(data.sort((a, b) => (a.posType.toLowerCase() > b.posType.toLowerCase()) ? 1 : -1));
            }

            let rgs = await getRestaurantGroupsApi();
			if (rgs && rgs[0]) {
                let rg = rgs[0]
                setRecord({...record, name: (record && record.name) ? record.name: rg.name, posType: rg.posTypeOther ? -1 : rg.posType ? rg.posType : null, posTypeOther: rg.posTypeOther ? rg.posTypeOther : '', restaurantGroup: rg._id})
            }
            setTimeout(() => {
                setLoadingAsync(false)    
            }, 500);
		}
        fetchdata()
    }, [])
    
    useEffect(() => {
        let timer
		if (restaurant) {
            record._id = restaurant._id
            record.restaurantGroup = restaurant.restaurantGroup ? restaurant.restaurantGroup._id : null
            record.name = restaurant.name
            record.posType = restaurant.posTypeOther ? -1 : restaurant.posType ? restaurant.posType._id : null
            record.posTypeOther = restaurant.posTypeOther ? restaurant.posTypeOther : ''
            record.askForVehicleInfo = restaurant.config.features.imHere.selectVehicleEnabled
            record.askForPickupLocations = restaurant.config.features.imHere.selectLocationEnabled
            record.pickupLocations = restaurant.config.features.imHere.pickupLocations.map(rec => { return {location: rec} })
            record.branding = restaurant.branding
            setEditingPaymentMethod(!restaurant.getVal('billingInfo.encryptedCard',''))
            setRecord({...record})
            if (verifying) {
                let pass = (restaurant.status != 'enrolled' && restaurant.status != 'ineligible')
                if (!pass && retryCount < 6) {
                    retryCount++
                    timer = setTimeout(() => {
                        setRetryCount(retryCount)
                        verifyInstall()    
                    }, 1000);
                } else {
                    clearTimeout(timer)
                    setVerifying(false)
                    setVerified(pass)
                    setRetryCount(0)
                }
            } else if (restaurant.status != 'enrolled' && restaurant.status != 'ineligible') {
                setVerifying(false)
                setVerified(true)
                generatePdf()
            } else {
                setVerifying(false)
                setVerified(null)
            }
        }

        return () => {
            clearTimeout(timer)
        }

    }, [restaurant])

    useEffect(()=>{
        updatePreviews()
    },[record])

    const updatePreviews = () => {
        if (logo !== record.branding.logo){
            setLogo(record.branding.logo)
        }
    }

    function generatePreview(event) {
        const input = event.target;
        const reader = new FileReader();
        let img;
        const file = input.files && input.files.length > 0 ? input.files[0] : null
        if(file){ 
            if(file.size <= (1024*1024*5) ){ //5MB
                setLogo(file)
                reader.onload = function(){
                    img = reader.result;
                    setLogoPreview(img)
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
            } else {
                global.showNotification('The file size is too large, 5MB limit', 'error')
            }
        }
        
    };
    
	return (
        <Grid container alignItems="stretch" direction={isMobile ? "column" : "row"} style={{padding: isMobile? 10 : 15, height:'100%', overflow: 'hidden'}}>
            <Grid item sm={4} style={{paddingRight: isMobile? 5 : 25, paddingBottom: isMobile? 5 : 25, paddingTop: isMobile? 0 : 10, paddingLeft: isMobile? 0 : 10}}>
                {!isMobile && 
                    <Grid direction="column" alignItems="stretch" container style={{height: '100%'}}>


                        <Grid item xs>
                            <Typography variant="h5" style={{color: 'var(--text)', marginTop: isMobile? 0 : 45}}>
                                Welcome to Curbly,
                            </Typography>
                            
                            <Typography variant="h7" style={{color: 'var(--text)'}}>
                                The curbside pick-up management solution
                            </Typography>
                            <br/><br/>
                            <Typography variant="h7" style={{color: 'var(--text)'}}>
                                Please complete the following steps to finish setting up your account.
                            </Typography>

                            {restaurant && activeStep > 0 && 
                                <Box m={1}>
                                    <Typography style={{fontSize: '1.2em', fontWeight: 400, color: 'var(--text)', marginTop: 30}}>
                                        Location Name
                                    </Typography>
                                    <Typography style={{fontSize: '1.1em', fontWeight: 400, color: 'var(--text)', color: 'var(--purple)'}}>
                                        {restaurant.name}
                                    </Typography>
                                    <Typography style={{fontSize: '1.2em', fontWeight: 400, color: 'var(--text)', marginTop: 15}}>
                                        Point-of-Sale
                                    </Typography>
                                    <Typography style={{fontSize: '1.1em', fontWeight: 400, color: 'var(--text)', color: 'var(--purple)'}}>
                                        {restaurant.posType && restaurant.posType.posType}
                                    </Typography>
                                </Box>
                            }
                        </Grid>

                        <Grid item container alignItems="flex-end">
                            <Grid item xs={12}>
                                <Typography variant="h5" style={{color: 'var(--text)'}}>
                                    Having Trouble?
                                </Typography>
                                <Typography variant="h7" style={{color: 'var(--text)'}}>
                                    Contact our support team at <br/>
                                    <a style={{color: 'var(--purple)'}} href="mailto:support@dashnow.com">support@dashnow.com</a> or 913-370-7577
                                </Typography>
                            </Grid>
                        </Grid>

                    </Grid>
                }
            </Grid>


            <Grid item xs sm={8}>
                <Paper style={{height: '100%', padding: isMobile? 0 : 15, marginBottom: 15}}>
                    
                    <Grid direction="column" alignItems="stretch" container style={{height: '100%'}}>

                        <Grid item style={{height: isMobile ? '46px' : '100px'}}>
                            {activeStep != steps.length &&
                                <Stepper className={classes.stepper} alternativeLabel nonLinear style={{padding: isMobile? 4 : 15, paddingTop: isMobile? 12: ""}} activeStep={activeStep}>
                                    {steps.map((label, index) => {
                                        const stepProps = {};
                                        const buttonProps = {};
                                        return (
                                            <Step key={label} {...stepProps}>
                                                <StepButton
                                                    completed={isStepComplete(index)}
                                                    {...buttonProps}
                                                >
                                                    {isMobile ? '' : label}
                                                </StepButton>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            }
                        </Grid>


                        <Grid xs item style={{overflowX: 'hidden', overflowY: 'auto', borderTop: 'solid 1px #CCC', borderBottom: 'solid 1px #CCC'}}>
                            {loadingAsync && 
                                <Grid container justify="center" alignItems="center" style={{height: '50%'}}>
                                    <LinearProgress style={{width: '75%', height: 6}} />
                                </Grid>
                            }
                        
						    {!loadingAsync && 
                                <DForm2 record={record} margin={35}>
                                    {activeStep == 0 && 
                                        <Grid container style={{padding:defaultPadding}} spacing={3}>
                                            <Grid item container xs={12} style={{paddingTop: defaultPadding, paddingBottom: defaultPadding}}>
                                                <Typography variant="h5">Please Add a Location</Typography>
                                            </Grid>
                                            <Grid item container xs={12} lg={8} style={{paddingRight: isMobile? 15 : 45}}>
                                                <Grid item xs={12}>
                                                    <DInput name="name" label="Location Name" required style={{maxWidth: 350}} />
                                                </Grid>

                                                <Grid item xs={12} container>
                                                    <Grid item xs>
                                                        <DInput fieldType="Select" name="posType" label="POS Type" required rerender style={{maxWidth: 350}}>
                                                            {posTypes.map((p) => <MenuItem value={p._id}>{p.posType}</MenuItem>)}
                                                            <MenuItem value='-1'>Other</MenuItem>
                                                        </DInput>
                                                    </Grid>
                                                    {record.posType == '-1' &&
                                                        <Grid item xs style={{paddingLeft: 20}}>
                                                            <DInput name="posTypeOther" label="Other" style={{maxWidth: 250}} required />
                                                        </Grid>
                                                    }
                                                </Grid>

                                                <Grid item container xs={12} alignItems="center">
                                                    <div style={{border: 'solid 1px #ccc', borderRadius: 5, width: '100%', padding: 20, paddingTop: 0, marginTop: 20, marginBottom: 20}}>
                                                        <div style={{top: -15, backgroundColor: 'var(--white)', paddingLeft: 10, paddingRight: 10, width: 'auto', display: 'inline-block', position: 'relative'}}>
                                                            <Typography variant="subtitle1">
                                                                Pick Up Options
                                                            </Typography>
                                                        </div>
                                                        <Grid container xs={12}>
                                                            <Typography variant="body" gutterBottom style={{color: '#777', marginBottom: 10}}>
                                                                Please choose the options that apply for your location
                                                            </Typography>
                                                            <Grid item xs={12}>
                                                                <DInput fieldType="toggle" name="askForVehicleInfo" label="Ask the customer for their vehicle type and color?" required={() => { return !record.askForVehicleInfo && !record.askForPickupLocations }} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <DInput fieldType="toggle" name="askForPickupLocations" label="Ask the customer where they are parked?" rerender required={() => { return !record.askForVehicleInfo && !record.askForPickupLocations }} />
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </Grid>

                                                {record.askForPickupLocations && 
                                                    <Grid item container xs={12} alignItems="center">
                                                        <div style={{border: 'solid 1px #ccc', borderRadius: 5, width: '100%', padding: 20, paddingTop: 0, marginTop: 20, marginBottom: 20}}>
                                                            <div style={{top: -15, backgroundColor: 'var(--white)', paddingLeft: 10, paddingRight: 10, width: 'auto', display: 'inline-block', position: 'relative'}}>
                                                                <Typography variant="subtitle1">
                                                                    Pick Up Locations
                                                                </Typography>
                                                            </div>
                                                            <Grid container xs={12} alignItems="center">
                                                                <Typography variant="body" gutterBottom style={{color: '#777', marginBottom: 10}}>
                                                                    Please input your pick up locations
                                                                </Typography>
                                                                {record.pickupLocations.map((row, x) => 
                                                                    <DRecord record={record.pickupLocations[x]}>
                                                                        <Grid item xs={12} container>
                                                                            <Grid item style={{width: 10, paddingTop: 6, marginRight: 12, color: '#777'}}>
                                                                                {x+1}.
                                                                            </Grid>
                                                                            <Grid item xs>
                                                                                <DInput name='location' required marginBottom={10} />
                                                                            </Grid>
                                                                            <Grid item style={{width: 16, marginLeft: 6}}>
                                                                                <IconButton aria-label="delete" style={{padding: 5}}>
                                                                                    {x > 0 &&
                                                                                        <DeleteIcon onClick={() => { setRecord({...record, pickupLocations: record.pickupLocations.removeItemAtIndex(x)}); }} />
                                                                                    }
                                                                                </IconButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </DRecord>
                                                                )}

                                                                <Grid item xs={12}>
                                                                    <Button variant={'contained'} color="secondary" onClick={() => { record.pickupLocations.push({}); setRecord({...record}) }} style={{marginTop: 10, marginLeft: 20}}>+ ADD LOCATION</Button>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                }
                                                    
                                            </Grid>
                                            <Grid item container xs={12} lg={4} justify="flex-start" style={{paddingRight: isMobile? 15 : mediumScreen ? 45 : 5}}>
                                                <Grid item xs={12}>
                                                    <div style={{border: 'solid 1px #ccc', borderRadius: 5, width: '100%', padding: 20, paddingTop: 0, marginTop: 20, marginBottom: 20}}>
                                                        <div style={{top: -15, backgroundColor: 'var(--white)', paddingLeft: 10, paddingRight: 10, width: 'auto', display: 'inline-block', position: 'relative'}}>
                                                            <Typography variant="subtitle1">
                                                                Company Logo
                                                            </Typography>
                                                        </div>
                                                        <Grid container xs={12} alignItems="center" direction="column">
                                                            <input type='file' accept={'image/png, image/jpeg, image/jpg, image/gif'} id="company-logo" style={{display:'none'}} 
                                                                onChange={(e) => {
                                                                    if(e.target.files && e.target.files.length > 0){
                                                                        if(e.target.files[0].size <= (1024*1024*5) ){ //5MB
                                                                            generatePreview(e)
                                                                        } else {
                                                                            global.showNotification('The file size is too large, 5MB limit', 'error')
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                                <Grid item container justify="center" alignItems="center">
                                                                    {record.branding && record.branding.logo && record.branding.logo.indexOf('https://dash-files') > -1 && !logoPreview &&
                                                                        <img src={record.branding.logo} alt="record-logo" style={{maxWidth: '100%', maxHeight: 200, height: 'auto', width: 'auto', padding: 12}}></img>
                                                                    }
                                                                    {logoPreview &&
                                                                        <img src={logoPreview} alt="logo-preview" style={{maxWidth: '100%', maxHeight: 200, height: 'auto', width: 'auto', padding: 12}} />
                                                                    }
                                                                    
                                                                </Grid>
                                                            <Grid item xs container justify={isMobile ? 'flex-start' : 'center'} alignItems="center" style={{height: '100%', marginTop: 15}}>
                                                                <Button variant="contained" color="secondary" onClick={() => document.querySelector('#company-logo').click()}><PublishIcon style={{marginRight: 10, marginLeft: -2}}/>
                                                                    {logoPreview && 'CHANGE'}
                                                                    {!logoPreview && 'UPLOAD'}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    }



                                    {activeStep == 1 && 
                                        <Grid container style={{padding: defaultPadding}} spacing={3}>
                                            {restaurant.status != 'ineligible' && <>
                                                <Grid item container xs={12} style={{paddingTop: defaultPadding, paddingBottom: defaultPadding}}>
                                                    <Typography variant="h5">Please Follow the Steps Below To Install Curbly</Typography>
                                                </Grid>

                                                <Grid item container alignItems="flex-start">

                                                    <Grid item container alignItems="center" style={{marginBottom: 8}}>
                                                        <Box style={{width: 35, height: 35, borderRadius: '50%', backgroundColor: 'var(--purple)', color: '#FFF', fontSize: '1.4em', fontWeight: 500, textAlign: 'center', paddingTop: 4, marginRight: 10}}>
                                                            1
                                                        </Box>
                                                        <Grid item xs style={{fontSize: '1.4em'}}>
                                                            Download and Install the <b>Curbly Hub</b> Software
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item style={{marginBottom: '2.8em', marginLeft: '2.8em'}}>
                                                        <div>
                                                            On your primary (back office) point-of-sale computer, visit this URL to download the Curbly Hub software.
                                                        </div>
                                                        <div style={{marginTop: 5}}>
                                                            <a style={{fontSize: '1.3em', color: 'var(--purple)'}} href="https://curbly.app/install">https://curbly.app/install</a>
                                                        </div>
                                                    </Grid>


                                                    <Grid item container alignItems="center" style={{marginBottom: 8}}>
                                                        <Box style={{width: 35, height: 35, borderRadius: '50%', backgroundColor: 'var(--purple)', color: '#FFF', fontSize: '1.4em', fontWeight: 500, textAlign: 'center', paddingTop: 4, marginRight: 10}}>
                                                            2
                                                        </Box>
                                                        <Grid item xs style={{fontSize: '1.4em'}}>
                                                            Enter Your Location Install Code
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item style={{marginBottom: '2.8em', marginLeft: '2.8em'}}>
                                                        <div>
                                                            During the install process, you will be prompted for an install code. Use the install code found below. 
                                                        </div>
                                                        <div style={{marginTop: 5}}>
                                                            <Grid item container>
                                                                <Box p={1} pl={2} pr={2} style={{border: 'dashed 1px #AAA', borderRadius: 5, fontSize: '1.4em', color: 'var(--purple)'}}>
                                                                    {restaurant.installCode}
                                                                </Box>
                                                            </Grid>
                                                        </div>
                                                    </Grid>


                                                    <Grid item container alignItems="center" style={{marginBottom: 8}}>
                                                        <Box style={{width: 35, height: 35, borderRadius: '50%', backgroundColor: 'var(--purple)', color: '#FFF', fontSize: '1.4em', fontWeight: 500, textAlign: 'center', paddingTop: 4, marginRight: 10}}>
                                                            3
                                                        </Box>
                                                        <Grid item xs style={{fontSize: '1.4em'}}>
                                                            Verify Install
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item style={{marginLeft: '2.8em'}}>
                                                        <div>
                                                            Once you've completed the install, click the button below to verify the installation. 
                                                        </div>
                                                        <div style={{marginTop: 5}}>
                                                            <Grid container alignItems="center">
                                                                {!verified && 
                                                                    <Button variant={'contained'} style={{marginRight: 20}} size="large" color="primary" disabled={loadingAsync || verifying || verified == true} onClick={() => verifyInstall()} type='submit'>Verify Install</Button>
                                                                }
                                                                {verifying && <>
                                                                    <CircularProgress size={30} />
                                                                    <Typography style={{fontSize: '.9em', fontStyle: 'italic', marginLeft: 12}}>Verifying install, please wait... this could take up to 1 minute.</Typography>
                                                                </>}

                                                                {!verifying && verified == false && <>
                                                                    <DFlag fontSize="large" style={{marginRight: 5, marginBottom: 'inherit'}} checked={false} />
                                                                    <Typography style={{fontSize: '1.3em'}}>UNABLE TO DETECT INSTALL</Typography>
                                                                </>}

                                                                {!verifying && verified == true && <>
                                                                    <DFlag fontSize="large" style={{marginRight: 5, marginBottom: 'inherit'}} checked={true} />
                                                                    <Typography color="primary" style={{fontSize: '1.3em'}}>INSTALL SUCCESSFUL</Typography>
                                                                </>}

                                                            </Grid>
                                                        </div>
                                                    </Grid>

                                                    
                                                </Grid>
                                            </>}
                                            {restaurant.status == 'ineligible' && <>
                                                <Grid item container xs={12} style={{paddingTop: defaultPadding, paddingBottom: defaultPadding}}>
                                                    <Typography variant="h5">We're sorry, </Typography>
                                                </Grid>
                                                <Grid item container xs={12} style={{paddingTop:12}}>
                                                    <Typography>Curbly does not currently support your POS Type. </Typography>
                                                </Grid>
                                                <Grid item container xs={12} style={{paddingTop:12}}>
                                                    <Typography>We will contact you when support becomes available for {restaurant.posType ? (restaurant.posType != -1) ? restaurant.posType.posType: restaurant.posTypeOther : 'your POS Type'} </Typography>
                                                </Grid>
                                                <Grid item container xs={12} style={{paddingTop:12}}>
                                                    <Typography>Want Curbly sooner? Help us integrate with your POS by contacting  <a style={{color: 'var(--purple)'}} href="mailto:support@dashnow.com">support@dashnow.com</a></Typography>
                                                </Grid>
                                            </>}

                                        </Grid>
                                    }



                                    {activeStep == 2 && 
                                        <Grid container style={{padding: defaultPadding}} spacing={3}>
                                            <Grid item container xs={12} style={{paddingTop: defaultPadding, paddingBottom: defaultPadding}}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5">Please Setup Your Payment Method</Typography>
                                                </Grid>
                                                <Grid item xs={12} alignItems="center" container style={{marginTop: 15}}>
                                                    <Grid item style={{marginRight: 8}}>
                                                        <LockIcon />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography variant="body2">All payment information is securely stored through our partner Spreedly, with the highest level of PCI compliance.</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item container xs={12} lg={8} style={{paddingRight: '2.8em'}}>
                                                

                                            {restaurant && restaurant.getVal('billingInfo.encryptedCard','') && !editingPaymentMethod && 
                                                <Box>
                                                    {restaurant.billingInfo.encryptedCard.paymentType == "creditCard" && 
                                                        <Grid container style={{marginBottom: 25}}>
                                                            <Grid item xs={12}>
                                                                <Typography style={{padding: 5}}>{restaurant.billingInfo.encryptedCard.fullName}</Typography>
                                                            </Grid>
                                                            <Grid container item xs={12}>
                                                                <Grid item>
                                                                    <CreditCardIcon fontSize="medium" />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography style={{padding: 5, paddingRight: 30}}>{restaurant.billingInfo.encryptedCard.type.toUpperCase()}</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography style={{padding: 5}}>********** {restaurant.billingInfo.encryptedCard.lastFour}</Typography>
                                                                </Grid>    
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography style={{padding: 5}}>(Expires {restaurant.billingInfo.encryptedCard.expMonth}/{expYear})</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    <Button variant="contained" color="primary" onClick={() => setEditingPaymentMethod(true) }>
                                                        Update
                                                    </Button>
                                                </Box>
                                            }

                                            {restaurant && editingPaymentMethod && 
                                                <AddCardPanel cancelForm={() => cancelPaymentForm()} hideCancelButton={restaurant && !restaurant.getVal('billingInfo.encryptedCard', '')} />
                                            }


                                            </Grid>

                                        </Grid>
                                    }


                                    {activeStep == 3 && 
                                        <Grid container style={{height: '100%', padding: defaultPadding}} spacing={3}>

                                            <Grid item container direction="column" alignItems="stretch" style={{paddingTop: defaultPadding, height: '100%'}}>

                                                <Grid item container alignItems="flex-start" style={{height: 40}}>
                                                    <Typography variant="h5">Software User License Agreement</Typography>
                                                </Grid>

                                                <Grid xs item alignItems="flex-start">
                                                    <iframe id='iframe' src={pdfFile} width='100%' height='100%' />
                                                </Grid>

                                                <Grid item style={{height: 20, paddingTop: 15, paddingBottom: 15}}>
                                                    <DInput fieldType="checkbox" name="acceptTos" label={`I Accept the ${global.getAppName()} Software License Agreement`} required />
                                                    
                                                </Grid>

                                            </Grid>


                                        </Grid>
                                    }


                                </DForm2>
                            }
                        </Grid>

                        { showStepper && showButtons &&  
                            <Grid item style={{height: '50px', padding: isMobile? '0px 5px 0px 5px' : 0}} justify="space-between" alignItems={isMobile? "center" : "flex-end"} container>
                                <Grid item>
                                    {showBackButton && <Button variant={'contained'} size="large" color="secondary" disabled={loadingAsync} onClick={() => backStep(activeStep)} style={{marginLeft: 1}}>Back</Button>}
                                </Grid>
                                <Grid item>
                                    {showNextButton && <Button variant={'contained'} size="large" color="primary" disabled={loadingAsync || (activeStep == 1 && verified != true) || (activeStep == 2 && editingPaymentMethod)} onClick={() => nextStep(activeStep)} type='submit'>
                                        {(activeStep != steps.length -1) && 'Next'}
                                        {(activeStep == steps.length -1) && 'Finish'}
                                    </Button>}
                                </Grid>
                            </Grid>
                        }

                    </Grid>


                </Paper>
            </Grid>
        </Grid>
	);
};