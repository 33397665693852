import _ from 'lodash';
import React, {useState, useEffect} from 'react';
import { ListItem, List, Typography, ListItemText, Paper, Grid } from '@material-ui/core';

import EmptyStarIcon from '../../assets/svgs/EmptyStarSvg';
import StarIcon from '../../assets/svgs/GoldStarSvg';
import WaiterIcon from '../../assets/svgs/WaiterIcon';
import TableIcon from '../../assets/svgs/TableIcon';

import star from '../../assets/images/star.png'
import moment from 'moment'

const styles = {
	infoBanner: {
		padding: 5,
		paddingTop: 15
	},
	paper: {
		padding: 10,
		width: '100%',
		height: '100%'
	},
	table: {
		overflowY: 'scroll',
	}
}

export default function (props) {
	// console.log(JSON.stringify(props.data))
	const data = props.data
	const [feedbackData] = global.get('feedbackData')

	useEffect(() => {
		// setData(props.data)
	}, [])

	const renderFSA = (fsa) => {
		return (
			<table style={{ paddingLeft: '15px', paddingTop: '10px' }}>
				{ fsa && (
					<>
						<tr>
							<td style={{ color: 'rgba(0, 0, 0, 0.70)' }}>Food:</td>
							<td>{_.times(fsa.food, () => (
								<StarIcon />
							))}
								{_.times(5 - fsa.food, () => (
									<EmptyStarIcon />
								))}</td>
						</tr>
						<tr>
							<td style={{ color: 'rgba(0, 0, 0, 0.70)' }}>Service:</td>
							<td>{_.times(fsa.service, () => (
								<StarIcon />
							))}
								{_.times(5 - fsa.service, () => (
									<EmptyStarIcon />
								))}</td>
						</tr>
						<tr>
							<td style={{ color: 'rgba(0, 0, 0, 0.70)' }}>Atmosphere:</td>
							<td>{_.times(fsa.atmosphere, () => (
								<StarIcon />
							))}
								{_.times(5 - fsa.atmosphere, () => (
									<EmptyStarIcon />
								))}</td>
						</tr>
					</>
				)}
			</table>
		);
	}

	const renderTableInfo = (comment) => {
		return (
			<table style={styles.table}>
				{comment && (
					<tr>
						{comment.serverIdentifier &&
							<>
								<td><WaiterIcon width={'25px'} height={'35px'} fill={'rgba(0, 0, 0, 0.70)'} /></td>
								<td style={{ minWidth: '30px', maxWidth: '50px' }}>{comment.serverIdentifier}</td>
							</>
						}
						{comment.tableNumber &&
							<>
								<td style={{ paddingLeft: '30px' }}><TableIcon width={'25px'} height={'25px'} /></td>
								<td>{comment.tableNumber}</td>
							</>
						}
					</tr>
				)}
			</table>
		);
	}

	const renderComments = (data) => {
		return (
			<List style={{ maxHeight: '1000px', overflowY: 'scroll' }}>
				{
					data && data.map(comment => {
						let fullName = "Guest"
						if (comment.account && comment.account.firstName && comment.account.lastName) {
							fullName = comment.account.firstName + ' ' + comment.account.lastName
						}
						return (
							<ListItem
								divider
							>
								<ListItemText
									primary={
										<React.Fragment>
											<Typography component="span" color="textPrimary">
												<p style={{ textAlign: 'left' }}>
													<b style={{ color: 'rgba(0, 0, 0, 0.70)' }}>{fullName}</b>
													<div style={{ float: 'right', backgroundColor: '#E8E8E8', borderRadius: '5px', padding: '3px 15px 3px 15px' }}> {moment(comment.createdAt).fromNow()} </div>
												</p>
											</Typography>
										</React.Fragment>
									}
									secondary={
										<React.Fragment>
											<Typography>
												{renderTableInfo(comment.check)}
											</Typography>
											<Typography>
												{comment.comment}
											</Typography>
											{renderFSA(comment.ratings)}
										</React.Fragment>
									}
								/>
							</ListItem>
						)
					})
				}
			</List>
		);
	}



	return (
		<Grid container style={styles.infoBanner}>
			<Paper style={styles.paper}>
				<Typography variant="h5">Recent Feedback</Typography>
					{ data &&  renderComments(data)}
			</Paper>
		</Grid>
	);
}
