import React, { useState, useEffect, createRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import log from 'loglevel';
import _ from 'lodash';
import {
    Grid,
    Typography,
    Paper,
    Checkbox,
    Button, FormControlLabel
} from '@material-ui/core';
import DForm2 from '../../../components/dash/DForm2';
import DInput from '../../../components/dash/DInput';
import moment from 'moment';
import SignatureCanvas from 'react-signature-canvas'
import PriceScheduleTable from '../../../components/dash/PriceScheduleTable';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const styles = (theme) => ({
    root: {},
    paper: {
        padding: '30px',
        margin: '1%',
    },
    disabledField: {
        width: '85%',
    },
    bottomless: {
        borderBottom: 'none'
    }
});

const SummaryAgreement = (props) => {
    const { classes } = props;
    const [ isChecked, setCheckBox ] = useState(false);
    const [ dataUrl, setDataUrl ] = useState(global.f(props.record, 'agreement.signature.dataUrl', false));
    const [ signatureData, setSignatureData ] = useState(global.f(props.record, 'agreement.signature.signatureData', false));
    const [ startedSigning, setHasStartedSigning ] = useState(false);
    let sigCanvas = React.createRef()
    const isMobile = useMediaQuery('(max-width:400px)')
    
    function saveSignature() {
        if(sigCanvas.isEmpty()) {
            global.showNotification('Signature is required to proceed', 'warning')
        } else if (sigCanvas) {
            const arr = sigCanvas.toData();
            const length = arr.reduce((acc, e) => acc + e.length, 0) - 1;
            const str = [
                `1,#FFFFFF,0.5,300,180,true,${length},input_2_60;`,
                ...arr.map(
                    e =>
                        `2,#000000 ${e
                            .map(
                                ({ x, y }) =>
                                    `${Math.floor(x)},${Math.floor(y)}`,
                            )
                            .join(' ')};`,
                ),
            ].join(' ');
            const dataUrl = sigCanvas.toDataURL();
            setDataUrl(dataUrl)
            const signatureData = btoa(str);
            setSignatureData(signatureData)
            // setTimeout(props.nextStep(), 500)
        }
        props.setNextIsDisabled(false)
    }

    useEffect(()=>{
        //ensure 'Next' button is enabled or disabled correctly
        if (dataUrl && isChecked) {
            props.setNextIsDisabled(false)
            setHasStartedSigning(true)
        }
        if (!dataUrl || !signatureData || !isChecked) {
            props.setNextIsDisabled(true)
        }
    },[isChecked, dataUrl])

    const clearSigPad = () => {
        props.setNextIsDisabled(true)
        if(dataUrl){
            setDataUrl(null)
            setSignatureData(null)
        } else {
            sigCanvas.clear()
            setHasStartedSigning(false)
        }
    }

    const handleCheckAccept = () => {
        setDataUrl(null)
        setSignatureData(null)
        setCheckBox(!isChecked)
    }

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Typography variant="h6" color="primary" className={styles.subheadingText} gutterBottom>
                            TextPay Merchant Agreement
                        </Typography>

                        <DForm2 record={props.record.agreement} validate={false} >
                            <Grid container justify="center">
                                <Grid item xs={12} style={{margin:'auto'}}>
                                    {/* <div style={{maxWidth:'100%', maxHeight:'300px', overflow:'scroll', margin:'auto', display:'block', border: '1px solid var(--dash-gray)', padding: '4px', boxShadow: 'inset 1px 1px 3px var(--dash-gray)'}}> */}
                                        { props.record && props.record.agreement && props.record.agreement.content && 
                                            <iframe style={{width:'100%', minHeight:'300px'}} src={props.record.agreement.content}/>
                                        }
                                    {/* </div> */}
                                </Grid>
                                <Grid item xs={12} style={{textAlign:'center'}}>
                                    <div style={{textAlign: 'left', width:'100%'}}>
                                        <PriceScheduleTable style={{paddingBottom:30}} backgroundColor={'white'} data={props.record.pricing}/>
                                    </div>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked={isChecked}
                                            onChange={()=> handleCheckAccept()}
                                            inputProps={{'aria-label': 'primary checkbox'}}
                                        />}
                                        label="I accept the terms and conditions"
                                    />
                                        <DInput name="acceptedOn" overrideValue={isChecked ? moment.utc().format(): false} style={{ display:'none' }}></DInput>
                                </Grid>
                            
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    {isChecked &&
                                        <>
                                            <Typography variant="h7" color="primary" className={styles.subheadingText} gutterBottom>
                                                Please sign your signature below:
                                            </Typography>
                                            <br />

                                            {!dataUrl ?
                                                <div style={{border:'1px solid var(--dash-gray)', width: isMobile ? 240 : 450, margin:'auto'}}>
                                                    <SignatureCanvas penColor='black'
                                                        canvasProps={{width: isMobile? 240 : 450, height: 200, className: 'sigCanvas'}}
                                                        ref={(ref) => { sigCanvas = ref }}
                                                        onEnd={()=> setHasStartedSigning(true)} 
                                                    />

                                                </div>
                                                :
                                                <img src={dataUrl} style={{margin:'auto'}}></img>
                                            }
                                            <br />

                                            <Button 
                                                onClick={() => clearSigPad()} 
                                                disabled={!startedSigning}
                                                variant="outlined" color="secondary">
                                                    Re-sign
                                            </Button>

                                            <Button
                                                variant="contained" color="primary"
                                                onClick={saveSignature}
                                                style={{ marginLeft: '4px' }}
                                                disabled={!startedSigning}>
                                                    Save
                                            </Button>

                                            <DInput name="signature.dataUrl" overrideValue={dataUrl} style={{ display: 'none' }}></DInput>
                                            <DInput name="signature.signatureData" overrideValue={signatureData} style={{ display: 'none' }}></DInput>
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </DForm2>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(SummaryAgreement);
