import React, { useState, useEffect } from 'react';
import { Grid, Dialog, DialogActions, DialogContent, Button, Typography, Checkbox, Table, TableRow, TableCell, Tabs, Tab, DialogTitle, TextField, TableHead, InputAdornment, CircularProgress, Popper } from '@material-ui/core';
import flagCheckAsCompletedApi from '../../actions/flagCheckAsCompletedApi';
import DialogContentText from '@material-ui/core/DialogContentText';
import log from 'loglevel'
import moment from 'moment'
import theme from '../../theme'
import dashFetch from '../../utils/dashFetch';


export default function (props) {
	let [configSettings, setConfigSettings] = useState(null)


    let getData = async () => {
        let serverConfig = await dashFetch(`${global.dashServerUrl}/dashboard/api/v1/dashboard/getAdminServerSettings`, {})
        log.info("WOO!")
        log.info(JSON.stringify(serverConfig.json.data))
        setConfigSettings(serverConfig.json.data)
    }

    let saveSettings = async () => {
        let confirmed = window.confirm('Are you sure you want to override dashserver configuration? This is permanent.')
        if (confirmed) {
            let resp = await dashFetch(`${global.dashServerUrl}/dashboard/api/v1/dashboard/saveAdminServerSettings`, { configSettings })
            getData()
            alert('Congrats, you probably fucked it up!')

        }
    }

    useEffect(() => {   
        getData()
    }, [])

    const handleSettingChange = (e) => {
        try {
            let newJson = JSON.parse(e.target.value)
            log.info('newJSON')
            log.info(JSON.stringify(newJson))
            setConfigSettings(newJson)
        } catch (e) {
            alert("FAILED TO PARSE JSON - NOT SAVING")
        }
    }

	return (
		<>
            <TextField multiline fullWidth value={JSON.stringify(configSettings, null, 4)} onChange={(e) => handleSettingChange(e)} />
            <Button onClick={() => saveSettings()} color="primary" variant="contained" >Save Configuration</Button>
        </>
	)

}
