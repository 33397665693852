import log from 'loglevel';
import dashFetch from '../utils/dashFetch';


export default async function (restaurant, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('fetchSiteCustomersApi api');
		const url = `${global.dashServerUrl}/dashboard/api/v1/textToPay/fetchSiteCustomers`;

		const body = {
            restaurant,
			debug
		};

		let options = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		}

		fetch(url, options, debug).then((res) => {
			if (res.ok) {
				res.json().then((json) => {
					if (json.success) {
						if (debug) log.info('success');
						resolve(json.customers);

					} else {
						log.error(json.errors)
						resolve(false);
					}
				}).catch((err) => {
					reject(err.message);
				})
			} else {
				reject(res);
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}