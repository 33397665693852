import log from 'loglevel';


export default async function (check, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('reprintPaymentReceiptApi api');
		const url = `${global.dashServerUrl}/api/v1/check/reprintPaymentReceipt`;

		const body = {
			check,
		};
		let options = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		}


		fetch(url, options, debug).then((res) => {
			log.info('>>> reprintPaymentReceiptApi JSON');
			if (res.ok) {
				res.json().then((json) => {
					if (json.success) {
						resolve(json.success);
					} else {
						resolve(false)
					}
				}).catch((err) => {
					reject(err.message);
				})
			} else {
				reject(res)
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}