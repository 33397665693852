import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import { Grid, Dialog, DialogActions, DialogContent, Button, Typography, Checkbox, Input, FormControlLabel, FormGroup } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import SelectMui from '@material-ui/core/Select';
import upsertProductPackageGroupApi from '../../actions/upsertProductPackageGroupApi';
import DialogTitle from '../../dashboard/components/DialogTitle';

import log from 'loglevel'

const styles = {
	infoBanner: {
		padding: 5,
		paddingTop: 15
	},
	paper: {
		padding: 10,
		width: '100%',
		height: '100%'
	},
	table: {
	}
}

export default function (props) {
	// alert(JSON.stringify(props.permissions))
	const [record, setRecord] = useState(props.record)
	const [productPackages, setProductPackages] = useState(props.productPackages)
	let [productPackageGroups] = global.get('productPackageGroups')

	if (!record.productPackages) {
        setRecord({...record, productPackages: []})
	}

	const upsertRecord = async (record) => {
		try {
			record = await upsertProductPackageGroupApi(record)
			let idx = _.findIndex(productPackageGroups, {_id: record._id})
			if (idx > -1) {
				productPackageGroups[idx] = record
			} else {
				productPackageGroups.push(record)
			}
			global.set({productPackageGroups: productPackageGroups})
			props.close()
		} catch (err) {
			log.error(err)
		}
	}

	const selectProductPackage = (event) => {
		let selectedId = event.target.name
		let checked = event.target.checked
		if (selectedId) {
			if (checked) {
				let productPackage = _.find(productPackages, {_id: selectedId})
				if (productPackage && record) {
					record.productPackages.push(productPackage)
					setRecord({...record})
				}
			} else {
				_.remove(record.productPackages, {_id: selectedId})
				setRecord({...record})
			}
		}
	};

	return (
		<Dialog
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			maxWidth={'md'}
			open={true}
			style={{ margin: 0, height: '100%', width: '100%'}}
			onClose={ props.close }
		>
			{record && 
			<>
				<DialogTitle>
					NEW PRODUCT PACKAGE GROUP
				</DialogTitle>
				<DialogContent dividers>
				<Grid container>
					<Grid item lg={12} style={{ padding: 20 }} container spacing={2}>

						<Grid item lg={12}>
							<Typography>Name</Typography>
							<Input
								style={{ width: '100%' }}
								value={record.name}
								onChange={(e) => setRecord({ ...record, name: e.target.value })}
							/>
						</Grid>

						<Grid item lg={12}>
							<Typography>Description</Typography>
							<Input
								style={{ width: '100%' }}
								value={record.description}
								onChange={(e) => setRecord({ ...record, description: e.target.value })}
							/>
						</Grid>

						<Grid item lg={12}>
							<Typography><strong>Select Product Packages</strong></Typography>
							<FormGroup row>
								{productPackages && productPackages.map(productPackage => {
									return (
										<FormControlLabel control={<Checkbox checked={_.findIndex(record.productPackages, {_id: productPackage._id }) > -1} onChange={selectProductPackage} name={productPackage._id} />} label={productPackage.name + (productPackage.description && productPackage.description !== productPackage.name ? " (" + productPackage.description + ")" : '')} />
									)
								})}
							</FormGroup>
						</Grid>

                        <Grid item lg={6}>
							<Typography>Effective Date</Typography>
							<Input
                                type="date"
								style={{ width: '100%' }}
								value={record.effectiveDate}
								onChange={(e) => setRecord({ ...record, effectiveDate: e.target.value })}
							/>
						</Grid>

                        <Grid item lg={6}>
							<Typography>Termination Date</Typography>
							<Input
                                type="date"
								style={{ width: '100%' }}
								value={record.terminationDate}
								onChange={(e) => setRecord({ ...record, terminationDate: e.target.value })}
							/>
						</Grid>
						
					</Grid>
				</Grid>
				</DialogContent>
			</>}
			<DialogActions>
				<Button onClick={props.close}>
					Close
				</Button>
				<Button onClick={() => {upsertRecord(record);}}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	)

}
