import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import { Grid, Paper, Drawer, Zoom, MenuItem, Menu, Badge, IconButton, Dialog, DialogContent, DialogActions, Slide } from '@material-ui/core';

import DialogTitle from '../dashboard/components/DialogTitle'
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Button, TextField, Checkbox, Box, CircularProgress, Tabs, Tab, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import QRCode from 'qrcode.react';
import PublishIcon from '@material-ui/icons/Publish'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import theme from '../theme'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import log from 'loglevel'
import history from '../history.js'
import _ from 'lodash';


import DashCard from './components/DashCard'
import CheckDetails from "./components/CheckDetails";

import fetchSiteCustomersApi from '../actions/fetchSiteCustomersApi';
import TodaySearch from './components/TodaySearch';
import accessUtils from '../utils/accessUtils';

import moment from 'moment'
import Sound from 'react-sound';
import AddEditOrder from './components/AddEditOrder';
import Link from '@material-ui/icons/Link';
import { ArrowBackIos, GetApp, Print } from '@material-ui/icons';
import payments from './payments';
import PaymentsTable from './components/PaymentsTable';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    scrolling: {
        overflow: 'auto',
        height: 'auto'
    },
    checks: {
        maxHeight: 'calc(100% - 3em)',
    },
    addRestaurantIcon: {
		float: 'left',
		// marginTop: 5,
		// marginRight: 5,
		// width: '20px',
		height: '20px'
	},
    paper: {
        // paddingRight:8,
        // paddingleft:8,
        // margin: 8,
        // minHeight: '100%',
        // maxHeight: '100%',
        width:'100%',
        height:'100%'
    },
    iconWrapper: {
        height: '2.7em', 
        display: 'flex',
        alignItems: 'center' 
    },
    fabButtonMobile: {
        position: 'absolute',
        zIndex: 1000,
        left: 0,
        right: 0,
        margin: '0 auto',
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1000,
        // left: 0,
        right: 40,
        margin: '0 auto',
    },

}))


export default (props) => {
    const classes = useStyles();
    const { ClipboardItem } = window
    let [restaurant] = global.get('restaurant')
    let [checks, setChecks] = global.get('checks')
    let [filteredChecks, setFilteredChecks] = React.useState([])
    let [selectedCheck, setSelectedCheck] = useState()
    let [showAddOrder, setShowAddOrder] = React.useState(global.hasPermission('check.create'))
    let [dialogContent, setDialogContent] = useState()
    let [sentFilter, setSentFilter] = useState(['sent'])

    if (!localStorage.tabSelected) localStorage.tabSelected = 'ordered'

    let [selectedTab, setSelectedTab] = React.useState(localStorage.tabSelected)

    let smallScreen = useMediaQuery(theme().breakpoints.down('sm'));
    let mobile = useMediaQuery(theme().breakpoints.down('xs'));
    let [todaySearchFilter] = global.get('todaySearchFilter', '')
    let [checkTypeFilter] = global.get('checkTypeFilter')

    let [showAll, setShowAll] = global.get('showAll', false)
    
    let [showOrderedColumn, setShowOrderedColumn] = React.useState(true)
    let [showPaidColumn, setShowPaidColumn] = React.useState(true)
    let [showClosedColumn, setshowClosedColumn] = React.useState(true)
    let [expandedClosedColumn, setExpandedClosedColumn] = React.useState(false)
    let [isLoading, setIsLoading] = React.useState(false)
    let [canViewOthersOrders, setCanViewOthersOrders] = React.useState(global.hasPermission('check.view.all'))
    
    let [numberOfColumns, setNumberOfColumns] = React.useState(3)
    let [checkLookback, setCheckLookback] = global.get('checkLookback')
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    let showSearch = true


    const showCheckDetails = (check = null) => {
        if (check) {
            setDialogContent(
                <CheckDetails check={check} close={closeContent} isTextPay/>
            )
        } else {
            setDialogContent(
                <AddEditOrder check={check} paymentLinks close={closeContent} />
            )
        }
    }

    let showTabs = smallScreen
    useEffect(() => {
        showTabs = smallScreen
    }, [smallScreen]);
    
    
    useEffect(() => {
        let checkLookbackTmp = localStorage.getItem('checkLookback') ? localStorage.getItem('checkLookback') : 7
        if (!checkLookback) {
            setCheckLookback(checkLookbackTmp)
        } else {
            localStorage.setItem('checkLookback', checkLookback)
        }
    }, [checkLookback]);


    useEffect(() => {
        if (checks) {
            // REMOVE CHECKS OLDER THAN 24 HOURS FROM THE CHECKS ARRAY TO KEEP MEMORY USAGE DOWN
            let origChecksLength = checks.length
            checks = checks.filter(rec => !rec.deleted)

            checks = canViewOthersOrders ? checks : checks.filter(rec => rec.createdBy && rec.createdBy._id == localStorage.accountId)
            if (origChecksLength != checks.length) {
                setChecks(checks)
            }

            filteredChecks = checks

            filteredChecks = filteredChecks.filter(c=> c.persists)

            if (todaySearchFilter) {
                setShowAll(true)
                setSelectedTab("")
                let todaySearchFilterUpperCase = todaySearchFilter.toUpperCase()
                todaySearchFilterUpperCase = todaySearchFilterUpperCase.replace(/-/g, "").replace(/\./g, "")
                filteredChecks = filteredChecks.filter(rec => {
                    return (
                        (rec.checkNumber && rec.checkNumber.toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                        || (rec.orderNumber && rec.orderNumber.toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                        || (rec.tableNumber && rec.tableNumber.toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                        || (rec.totals.total && rec.totals.total.toString().toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                        || (rec.pickupDetails && rec.pickupDetails.guestName && rec.pickupDetails.guestName.toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                        || (rec.pickupDetails && rec.pickupDetails.phoneNumber && rec.pickupDetails.phoneNumber.replace(/-/g, "").replace(/\./g, "").toUpperCase().indexOf(todaySearchFilterUpperCase) > -1)
                    )
                })
            } else {
                if (selectedTab == "") {
                    setSelectedTab(localStorage.tabSelected) // sets it back to pre cleared value
                }
                setShowAll(false)
            }


            setFilteredChecks(filteredChecks.sort(c=> c.createdAt))
        }
    }, [checks, checkTypeFilter, todaySearchFilter, selectedTab, showTabs, sentFilter]);


    useEffect(() => {
        let numOfCols = 0
        if (showOrderedColumn) numOfCols++
        if (showPaidColumn) numOfCols++
        if (showAddOrder) numOfCols++
        setNumberOfColumns(numOfCols)
	}, [showClosedColumn, showOrderedColumn, showPaidColumn, showAddOrder])

    useEffect(()=>{
        if(restaurant && checks){
            setIsLoading(false)
        } else {
            setIsLoading(true)
        }
        console.log('in useEffect - restaurant', restaurant)
        console.log('in useEffect - checks', checks)
        console.log('is loading', isLoading)
    }, [restaurant, checks])
    

    useEffect(() => {
        if (selectedCheck && checks) {
            let checkMatch = checks.find(c=> c._id == selectedCheck._id)
            if (checkMatch) {
                setSelectedCheck(checkMatch)
            }
        }
    }, [checks])
    
    useEffect(() => {
        if (!selectedCheck && filteredChecks && !mobile) {
            setSelectedCheck(filteredChecks[0])
        }
        if (mobile) {
            setSelectedCheck(null)
        }
    }, [filteredChecks, mobile, restaurant])
    
    useEffect(()=>{
        if (selectedCheck) {
            if (mobile) setDialogContent(renderLinkDialog())
        }
    }, [selectedCheck])

    if(isLoading){
        return <div style={{width:'100%', height:'100%'}}><CircularProgress style={{position: 'absolute', top:'50%', left:'50%', height:'100px', width:'100px', marginTop:'-50px', marginLeft:'-50px', opacity:'20%'}}/></div>
    }

    const downloadQR = () => {
        const canvas = document.getElementById("qrcode");
        const pngUrl = canvas
            .toDataURL("image/png")
            // .replace("image/png", "image/octet-stream");

        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${selectedCheck.orderNumber}-QR.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
    
    const copyQR = () => {
        const canvas = document.getElementById("qrcode");
        canvas.toBlob((blob) => { 
            const item = new ClipboardItem({ "image/png": blob });
            navigator.clipboard.write([item])
        });
        global.showNotification('Copied QR Code', 'success')
    };
    
    const printQR = () => {
        const canvas = document.getElementById("qrcode");
        const pngUrl = canvas
            .toDataURL("image/png")
            // .replace("image/png", "image/octet-stream");


            let windowContent = '<!DOCTYPE html>';
            windowContent += '<html>';
            windowContent += '<head><title>' + selectedCheck.orderNumber + '</title></head>';
            windowContent += '<body>';
            windowContent += '<img src="' + pngUrl + '">';
            windowContent += '</body>';
            windowContent += '</html>';

            const printWin = window.open('', '', 'width=' + 1000 + ',height=' + 800);
            printWin.document.open();
            printWin.document.write(windowContent); 

            printWin.document.addEventListener('load', function() {
                printWin.focus();
                printWin.print();
                printWin.document.close();
                // printWin.close();            
            }, true);

    };

    const closeContent = () => {
        setSelectedCheck(null);
        setDialogContent(null)
    }

    // const Transition = React.forwardRef(function Transition(props, ref) {
    //     return <Slide direction="up" ref={ref} {...props} />;
    // });


    const renderLinkDialog = () => {
        return (
            <Dialog
                style={smallScreen ? {position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 99999} : {}}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={true}
                // TransitionComponent={Transition}
                onClose={closeContent}
                PaperProps={smallScreen ? {style:{maxHeight: 'inherit', maxWidth: 'inherit', margin: 0, width: '100%', height: '100%', borderRadius: 0}} : {style: {width: 450, minWidth: 450}}}
            >
                <DialogTitle onClose={closeContent} id="customized-dialog-title" style={{borderBottom: 'solid 1px #CCC'}}>
                    {selectedCheck && selectedCheck.orderNumber }
                </DialogTitle>
                <DialogContent style={{padding: 0, paddingBottom: 25}}>

                    {renderLinkContent()}
                </DialogContent>
                {/* <DialogActions style={{borderTop: '1px solid var(--divider)', padding: '5px 10px' }}>
                    <Grid container justify='center'>
                        {props.close && renderButton()}
                    </Grid>
                </DialogActions> */}
            </Dialog>
        )
    }

    const renderLinkContent = () => {
        return (
            
            <Grid container style={{padding:25, paddingTop: 15, paddingBottom:15, height:'auto'}} justify='flex-start' spacing={3}>
                {mobile && <>
                    <Grid item xs={12} style={{paddingRight: smallScreen ? 0 : 20,}}>
                        <span>{selectedCheck && selectedCheck.description}</span>
                    </Grid>
                    {/* <div id="top" style={{borderBottom:'1px solid var(--divider)'}}>
                        <IconButton color="primary"  onClick={() => { setSelectedCheck(null); setDialogContent(null) }} aria-label="directions">
                            <ArrowBackIos/> <span style={{fontSize:14, color:'var(--text)'}}>BACK</span>
                        </IconButton>
                    </div> */}
                </>}
                <Grid item xs={12} style={{paddingRight: smallScreen ? 0 : 20,}}>
                    <div style={{borderRadius:5, border:'1px solid var(--divider)', display:'flex', alignItems:'center', width: '100%', cursor:'pointer'}}>
                        <span style={{marginLeft:8, fontStyle:'italic', width:'100%'}} onClick={() => global.copyToClipboard(selectedCheck? selectedCheck.shortUrl: '')}>{selectedCheck? selectedCheck.shortUrl: ''}</span>
                        <Button disabled={!selectedCheck} variant="contained" color="primary" onClick={() => global.copyToClipboard(selectedCheck? selectedCheck.shortUrl: '')} style={{borderTopLeftRadius:0, borderBottomLeftRadius:0}}><Link/></Button>
                    </div>
                </Grid>
                <Grid item xs={12} style={{paddingRight: smallScreen ? 0 : 20}}>
                    <div style={{borderRadius:5, border:'1px solid var(--divider)', display:'flex', alignItems:'center', width: '100%'}}>
                        <div className="HpQrcode" style={{display:'flex', flexDirection:'row', padding:20, width:'100%', justifyContent: 'space-between'}}>

                            <div>
                                <QRCode id="qrcode" value={selectedCheck? selectedCheck.shortUrl : ''} size={200} level={"H"} includeMargin={true}/>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', }}>
                                <Button disabled={!selectedCheck} style={{marginBottom:5}} variant="contained" color="secondary" onClick={()=> downloadQR()}> <GetApp/></Button>
                                <Button disabled={!selectedCheck} style={{marginBottom:5}} variant="contained" color="secondary" onClick={()=> copyQR()}> <Link/></Button>
                                <Button disabled={!selectedCheck} style={{marginBottom:5}} variant="contained" color="secondary" onClick={()=> printQR()}> <Print/></Button>
                            </div>
                        </div>

                    </div>
                </Grid>
                <Grid item xs={12} style={{paddingRight: smallScreen ? 0 : 20}}>
                    <div style={{borderRadius:5, border:'1px solid var(--divider)', display:'flex',  width: '100%', flexWrap: 'wrap'}}>
                        <div style={{top: -15, right:-5, backgroundColor: 'var(--white)', paddingLeft: 10, paddingRight: 10, width: 'auto', display: 'inline-block', position: 'relative'}}>
                            <Typography variant="subtitle1">
                                Payments
                            </Typography>
                        </div>
                        <div style={{padding:8, width:'100%', marginTop:-15}}>
                            {selectedCheck && selectedCheck.payments && <>
                                {selectedCheck.payments.length == 0 &&
                                    <div style={{ display:'flex', paddingBottom:4, paddingLeft:4, flexDirection:'row', justifyContent:'space-between', alignItems:'center', width:'100%', backgroundColor:'none'}}>
                                        <span style={{fontStyle:'italic'}}>No payments received</span>
                                    </div>
                                }
                                {selectedCheck.payments.sort(p => p.successful).map((payment, index) => {
                                    if (!payment.successful || payment.paymentAmount > 0) {
                                        return (
                                            <div style={{ display:'flex', paddingBottom:4, paddingTop:4, flexDirection:'row', justifyContent:'space-between', alignItems:'center', width:'100%', borderBottom: selectedCheck.payments.length > 1 && '1px solid var(--divider)', backgroundColor:'none'}}>
                                                
                                                <span>${global.formatMoney(payment.paymentAmount)}</span>
                                                <span>{payment.paidBy ? payment.paidBy : "Customer" }</span>
                                                {/* <span>{payment.fromDash ? payment.successful ? `TextPay` : 'FAILED' : global.isTextPay() ? 'External' : global.f(payment, 'card.type', 'Card')}</span> */}
                                                <span style={{fontSize:'12px', color:'gray'}}> {moment(payment.createdAt).format('M/D @ hh:mm a') + ' '}</span>
                                            </div>
                                        );
                                    }
                                })}
                            </>}
                        </div>
                    </div>
                </Grid>
            </Grid>
        )
    }


	return (
        <Grid container style={{ height:"100%", padding: smallScreen ? 2 : 10}}>
            {restaurant && <>
                <Box display="flex" style={{ width:'100%', height: smallScreen ? '52px' : '60px', overflow: 'none', padding: 5}}>
                    {showSearch && checks &&
                        <Grid container spacing={1}>
                            <Grid item xs>
                                <TodaySearch overrideText="Search by Title, Description or Amount"/>
                            </Grid>
                        </Grid>
                        
                    }
                </Box>

                <Grid container style={{ width:'100%', maxHeight:'100%', height: 'calc(100% - 50px)', overflow: 'none', }} >


                    {!showAll && <>
                        <Grid item lg={12} sm={12} container direction='row' style={{ width: '100%', height: '100%', minHeight: '0', margin: 0, paddingBottom: 5 }}>
                            {showAddOrder && !smallScreen &&
                                <Grid item sm={!expandedClosedColumn} style={{ height: '100%', width: expandedClosedColumn ? '65px' : '100%', padding: 5, paddingRight: 10, paddingBottom: 10, paddingTop: showTabs ? 0 : 5, cursor: expandedClosedColumn ? 'pointer' : 'inherit'}} onClick={() => { if (expandedClosedColumn) setExpandedClosedColumn(!expandedClosedColumn) }}>
                                    <Paper className={classes.paper} > {/* style={{borderTop: 'solid 4px var(--dark-gray)'}} */}
                                        <Grid item lg={12} container alignItems="center" className={classes.columnHeader}>
                                            {!expandedClosedColumn && 
                                                <Grid item style={{width: 50}}></Grid>
                                            }
                                            {!expandedClosedColumn && 
                                                <Grid item xs container justify="center" alignItems="center" onClick={() => { if (expandedClosedColumn) setExpandedClosedColumn(!expandedClosedColumn)}}>
                                                    <Typography style={{ paddingTop: 3, fontSize: '1.4em', color:  theme().palette.text.primary }} display="inline">Create Payment Link</Typography>
                                                </Grid>
                                            }
                                            <Grid item style={{width: 50}} container alignItems="center">
                                                {expandedClosedColumn && 
                                                    <IconButton onClick={() => {if (expandedClosedColumn) setExpandedClosedColumn(!expandedClosedColumn)}}>
                                                        <ChevronRightIcon/>
                                                    </IconButton>
                                                }
                                            </Grid>
                                            
                                            
                                        </Grid>
                                        
                                        {expandedClosedColumn && 
                                            <Grid xs container style={{height: 'calc(100% - 42px)'}} justify="center" alignItems="center">
                                                <Typography style={{ paddingTop: 3, fontSize: '1.4em', color:  theme().palette.text.primary, transform: 'rotate(-90deg)', height: 35, width: '100%', whiteSpace: 'nowrap' }}>Request Payment</Typography>
                                            </Grid>
                                        }

                                        {!expandedClosedColumn && 
                                            <div style={{display:'flex', justifyContent:'flex-start', height: 'calc(100% - 32px)'}}>
                                                <AddEditOrder paymentLinks/>
                                            </div>
                                        }
                                    </Paper>
                                </Grid>
                            }

                            <Grid item xs={smallScreen? 12: 8} style={{ display:'flex', flexDirection:'row', height: '100%', width: '100%', padding: 5, paddingRight: 5, paddingBottom: 10, paddingTop: showTabs ? 0 : 5}} >
                                {/* <Paper className={classes.paper} style={{borderTop: 'solid 4px var(--dash-orange)'}}> */}
                                <Grid item sm={6} xs={12}>
                                    {mobile &&
                                        <Grid item lg={12} className={classes.iconWrapper} justify="center">
                                            <Typography style={{ paddingTop: 3, fontSize: '1.4em', color:  theme().palette.text.primary }} display="inline" align="center">Payment Links</Typography>
                                        </Grid>
                                    }
                                    <Grid container className={`${classes.scrolling} ${classes.checks}`}>
                                        {filteredChecks && filteredChecks.map(data => 
                                            <Grid item lg={12} style={{ padding: 0, paddingLeft: 0, paddingRight: 0, width:'100%' }} onClick={() => {setSelectedCheck(data);}}>
                                                <DashCard paymentLink data={data} selected={selectedCheck && selectedCheck._id == data._id}/>
                                            </Grid>
                                        )}
                                        {(!filteredChecks || !filteredChecks.length > 0) &&
                                            <div style={{display:'flex', justifyContent:'center', width:'100%'}}>
                                                <span style={{fontSize:22}}>Create a payment link to begin</span>
                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                                {!mobile && 
                                    <Grid item sm={6} style={{height:'100%'}}>
                                        <Paper style={{margin: 0, height:'100%', borderTopLeftRadius:0, borderTop:'solid 1px #E5E5E5'}}>
                                            {renderLinkContent()}
                                        </Paper>
                                    </Grid>
                                }
                                {/* </Paper> */}
                            </Grid>
                            {/* <Grid item sm style={{ width: '100%', maxWidth:`calc(100% / ${numberOfColumns - 1})`, maxHeight: '100%', padding: 5, paddingBottom: 10, paddingTop: showTabs ? 0 : 5 }} >
                                <Paper className={classes.paper} style={{borderTop: `solid 4px var(--${global.branding})`}}>
                                    <Grid item lg={12} className={classes.iconWrapper} justify="center" >
                                        <Typography style={{ paddingTop: 3, fontSize: '1.4em', color:  theme().palette.text.primary }} display="inline" align="center">CONTENT</Typography>
                                    </Grid>
                                    <Grid container className={`${classes.scrolling} ${classes.checks}`} >
                                        {paidChecks && paidChecks.map(data => 
                                            <Grid item lg={12} style={{ padding: 0, paddingLeft: 8, paddingRight: 8, width:'100%' }} onClick={() => showCheckDetails(data)}>
                                                <DashCard showBalanceDue={restaurant.backOffice || allowPartialPayments} data={data}/>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Paper>
                            </Grid> */}
                        </Grid>
                    </>}
                    <Grid item lg={12} >
                        {dialogContent}
                    </Grid>
                </Grid>
                {smallScreen && !dialogContent && global.hasPermission('check.create') &&
                    <Fab color="primary" aria-label="add" className={smallScreen? classes.fabButtonMobile :classes.fabButton} style={{bottom: smallScreen ? 30 : 75}} onClick={() => {showCheckDetails(null)}}>
                        <AddIcon />
                    </Fab>
                }
            </>
            }
        </Grid>
	);
};