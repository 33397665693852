import React, { useState, useEffect, useRef } from 'react';
import theme from '../theme'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { IconButton, Grid, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Box } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import DashIcon from '../assets/svgs/dashIcon';
import {ReactComponent as CurblyLogo} from '../assets/svgs/curbly-logo.svg';
import {ReactComponent as TextPayLogo} from '../assets/svgs/textpay-logo.svg';
import TextPayLogoMobile from '../assets/svgs/textpay-logo-mobile.js';
import {ReactComponent as HeartlandLogo} from '../assets/svgs/heartland-logo.svg';

import shift4Logo from '../assets/images/shift4-logo.png'
import MenuIcon from '@material-ui/icons/Menu'
import CropFreeIcon from '@material-ui/icons/CropFree';
import HomeIcon from '@material-ui/icons/Home';
import TodayIcon from '@material-ui/icons/Today';
import PaymentIcon from '@material-ui/icons/Payment';
import LinkIcon from '@material-ui/icons/Link';
import RateReviewIcon from '@material-ui/icons/RateReview';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import HelpIcon from '@material-ui/icons/Help';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Footer from './Footer'
import RestaurantSelector from '../components/dash/RestaurantSelector';
import _ from 'lodash';
import PaletteIcon from '@material-ui/icons/Palette';
import history from '../history';
import accessUtils from '../utils/accessUtils';
import AlertIcon from '../dashboard/components/NewCheckList';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import EarnMoneyDialog from '../components/EarnMoneyDialog'
import FisLogo from '../assets/svgs/fis-logo';
import MsgLogo from '../assets/svgs/msg-logo';
import ElavonLogo from '../assets/svgs/elavon-logo';
import ViewListIcon from '@material-ui/icons/ViewList';
import TermsOfUseDialog from '../components/TermsOfServiceDialog'
import QboConnectionDialog from '../components/QboConnectionDialog'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1,
    },
    drawer: {
        zIndex: 150
    },
    
}));

export default function Header(props) {
    const classes = useStyles();
    const [showDrawer, setShowDrawer] = React.useState(false);
	const [showEarnMoneyDialog, setShowEarnMoneyDialog] = React.useState(false);
    const [restaurant] = global.get('restaurant')
    const [showTopNav] = global.get('showTopNav')
    const [navBar] = global.get('navBar')
    const [payAtTableEnabled] = global.get('payAtTableEnabled')
    const [paymentLinksEnabled] = global.get('paymentLinksEnabled')
    const [textToPayEnabled] = global.get('textToPayEnabled')
    const [feedbackEnabled] = global.get('feedbackEnabled')
    const [showTermsOfUse, setShowTermsOfUse] = global.get('showTermsOfUse')
    const [showQboConnectionDialog, setShowQboConnectionDialog] = global.get('showQboConnectionDialog')
    const [darkMode] = global.get('darkMode')
	
    const isSmall = useMediaQuery(theme(global.primaryColor).breakpoints.down('sm'));
    const isMobile = useMediaQuery('(max-width:600px)')

    const isSetup = window.location.pathname.indexOf("/setup") == 0
    const showRestaurantSelector = true
    const showMenu = true

    const toggleDrawer = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setShowDrawer(!showDrawer);
    };



    const links = [
        {
            text: global.isDashNow() ? 'Today' : 'Home',
            show: restaurant && !restaurant.driveThruOnly && restaurant.status != 'hyfin',
            permission: 'restaurant.*',
            icon: global.isDashNow() ? <TodayIcon /> : <HomeIcon />,
            linkTo: () => history.push('/today')
        },
        {
            text: 'Payment Links',
            show: global.isTextPay() && paymentLinksEnabled && (restaurant && restaurant.status != 'hyfin') ,
            permission: 'restaurant.*',
            icon: <LinkIcon />,
            linkTo: () => history.push('/links')
        },
        {
            text: 'Payments',
            show: (payAtTableEnabled || textToPayEnabled) && (!global.isDashNow() || isMobile),
            permission: 'restaurant.payments',
            icon: <PaymentIcon />,
            linkTo: () => history.push('/payments')
        },
        {
            text: 'Reviews',
            show: (feedbackEnabled) && (!global.isDashNow() || isMobile),
            permission: 'restaurant.reviews',
            icon: <RateReviewIcon />,
            linkTo: () => history.push('/reviewsListing')
        },
        {
            text: 'Billing',
            permission: 'restaurant.billing.*',
            show: global.branding == 'dashnow',//todo this only works to hide it from textpay (heartland) need a better way todo: improve billing enabled flag on non-billable products
            icon: <CreditCardIcon />,
            linkTo: () => history.push('/billingSummary')
        },
        {
            text: 'Setup',
            permission: 'restaurant.branding.*',
            show: (restaurant && restaurant.status != 'hyfin') && (!global.isDashNow() || (global.isDashNow() && isMobile)),
            icon: <SettingsApplicationsIcon />,
            linkTo: () => history.push('/restaurantInfo')
        },
		{
            text: 'Earn Money',
            permission: 'restaurant.branding.*',
            show: global.isTextPay() && localStorage.affiliateCode,
            icon: <AttachMoneyIcon />,
            linkTo: () => {
				setShowEarnMoneyDialog(true)
			}
        },
		{
            text: 'List Sites',
            permission: 'agent.sites',
            show: true,
            icon: <ViewListIcon />,
            linkTo: () => history.push('/siteListing')
        },
        {
            text: 'Tutorial',
            show: global.isCurbly(),
            icon: <HelpIcon />,
            linkTo: () => {
                global.set({showTutorial: true})
            }
        },
        {
            text: 'Admin',
            permission: 'admin.*',
            show: (!global.isDashNow() || isMobile) || !showTopNav && navBar != 'admin',
            icon: <SettingsIcon />,
            linkTo: () => {
                global.set({navBar: 'admin', showTopNav: true})
                history.push(`/admin/restaurants/addEditRestaurant`)
            }
        },
        {
            text: 'Logout',
            show: accessUtils.isLoggedIn(),
            icon: <ExitIcon />,
            linkTo: () => accessUtils.logout()
        },
        {
            text: 'Login',
            show: !accessUtils.isLoggedIn(),
            icon: <AccountCircle />,
            linkTo: () => accessUtils.logout()
        },
        {
            text: darkMode ? 'Light Mode' : 'Dark Mode',
            permission: 'admin.*',
            show: true,
            icon: <Brightness4Icon />,
            linkTo: () => {localStorage.darkMode = !darkMode;global.set({darkMode: !darkMode})}
        },
    ]

    const list = () => (
        <div
            role="presentation"
            onClick={toggleDrawer}
        >
            <List style={{ paddingBottom: 0 }}>
                <Divider />
                {links.map((item, index) => (
                    <>{item.show && (item.permission && global.hasPermission(item.permission) || !item.hasOwnProperty('permission')) && <>
                        <ListItem button key={`${item.text}+${item.index}`}
                            onClick={item.linkTo}
                        >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItem>
                        {index < links.length - 1 ? <Divider /> : null}
                    </>}</>
                ))}
            </List>
        </div>
    );

    useEffect(() => {
		setShowDrawer(false)
	}, [restaurant])

    return (
        <>
            <AppBar position="sticky" classes={classes.root} elevation={0} style={{borderBottom: 'solid 1px var(--divider)', backgroundColor: 'var(--white)'}}>
                <Grid container justify="space-between" alignItems='center' style={{height: 49, maxHeight: 49}}>

                    <Grid item xs sm={4} container alignItems="center" style={{paddingLeft: 14}}>

                        {global.isDashNow() && 
                            <>
                                <DashIcon width={36} height={36} viewBox={'0, 0, 34, 34'} />
                                {!isMobile &&
                                    <Typography variant='h5' style={{ fontSize: '24px', marginLeft: 8, color:'var(--text)'}}>dash<span style={{ color: 'var(--dash-purple)' }}>board</span></Typography>
                                }
                            </>
                        }

                        {global.isTextPay() && <>
                            {(global.branding == 'dashnow' || global.branding == 'heartland') && <TextPayLogoMobile style={{ marginTop:0}} />} {/* this is for the textpay default logo */}
                            {/* {global.branding == 'heartland' && <HeartlandLogo style={{ marginTop: 3}} />} */}
                            {/* {global.branding == 'heartland' && <HeartlandLogo />} */}
                            {global.branding == 'shift4' && <img wdith="100%" height="100%" src={shift4Logo} alt="logo" />}
                            {global.branding == 'fis' && <div style={{ marginTop: 5}}><FisLogo/></div>}
                            {global.branding == 'msg' && <div style={{ marginTop: 5}}><MsgLogo/></div>}
                            {global.branding == 'elavon' && <div style={{ marginTop: 5}}><ElavonLogo/></div>}
                            {!isMobile &&
                                <Typography variant='h5' style={{ fontSize: '24px', marginLeft: 8, paddingLeft:8, borderLeft:'1px solid #ccc', color:'var(--text)'}}>text<span style={{ color: `var(--${global.branding})` }}>pay</span></Typography>
                            }
                        </>}
                        
                        {global.isCurbly() && 
                            <CurblyLogo style={{height: '36px', width: '93px'}} />
                        }
                        {isMobile &&
                            <div xs item container style={{position:'absolute', top:0, right:0, left:0}}>
                                <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
                                    {restaurant && <Typography variant='h5' style={{ fontSize: '18px', color:'var(--text)', paddingTop:12, paddingLeft:8, maxWidth:'50%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{restaurant.name}</Typography>}
                                </div>
                            </div>
                        }
                    </Grid>

                    <Grid item xs sm={8} container alignItems="center" justify="flex-end">
                        <Grid item>
                            <div style={{marginBottom: 4, marginRight: 5, borderLeft: restaurant ? 'solid 1px #DDD' : '', borderRight: 'solid 1px #DDD', paddingLeft: 10, paddingRight: 10, minWidth: isMobile ? '220px' : isSmall ? '280px' : '350px', display: showRestaurantSelector && !isMobile ? 'inherit' : 'none'}}>
                                <RestaurantSelector />
                            </div> 
                        </Grid>

                        {global.isDashNow() && 
                            <Grid item style={{paddingRight:10}}>
                                <AlertIcon/>
                            </Grid>
                        }

                        {showMenu && 
                            <Grid item>
                                <IconButton style={{marginRight:5, color:'var(--text)'}} onClick={toggleDrawer}>
                                    <MenuIcon color={showDrawer ? 'primary' : ''} />
                                </IconButton>
                            </Grid>
                        }

                    </Grid>
                    
                </Grid>
            </AppBar>


            {showDrawer && isMobile && showRestaurantSelector && 
                <AppBar position="sticky" classes={classes.appBar} elevation={0} style={{ height: '30px', backgroundColor: 'var(--white)'}}>
                    <Toolbar style={{ height: '48px', minHeight: '48px', paddingRight: '10px', borderBottom: '1px solid #CCC', borderTop: '1px solid #E5E5E5', backgroundColor:'var(--white)' }}>
                        <Grid container width="100%">
                            <Grid item style={{ flexGrow: 1, paddingTop: '0px', paddingRight: '0px', display: 'inline-block', marginTop: '4px', marginBottom: '10px' }}>
                                <RestaurantSelector fullWidth={true} />
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            }

            <Drawer disableEnforceFocus anchor={isMobile ? 'top' : 'right'} open={showDrawer} onClose={toggleDrawer} elevation={0} classes={{ paper: classes.drawer }} variant="temporary" style={{zIndex: 150}}>
                <div style={{ height:'100%',}}>
                    <div style={{ height: isMobile && showRestaurantSelector ? '88px' : '42px', minWidth: 220 }}></div>
                    <div style={{ display:'flex', height: 'calc(100% - 42px)', justifyContent:'space-between', flexDirection:'column'}}>
                        {list('top')}
                        <Footer />
                    </div>
                </div>
            </Drawer>
			
			{showEarnMoneyDialog && <EarnMoneyDialog open={showEarnMoneyDialog} onClose={setShowEarnMoneyDialog} />}
            {showTermsOfUse && <TermsOfUseDialog/>}
            {restaurant?.qboConnectionFailed && showQboConnectionDialog && <QboConnectionDialog/>}
        </>
    );
}