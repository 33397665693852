import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Tab, Tabs, Dialog, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
	AddCircleOutline,
} from '@material-ui/icons';
import { Button, TextField, Checkbox } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import AddEditProductPackageGroup from "../components/AddEditProductPackageGroup";
import moment from 'moment'


import ReactTable from "react-table";
import "react-table/react-table.css";

const styles = (theme) => ({
    root: {
    },
	addIcon: {
		float: 'left',
		marginTop: 5,
		marginRight: 5,
		width: '20px',
		height: '20px'
	},
	addButton: {
		float: 'right',
		marginLeft: 20,
		marginRight: 20,
		marginTop: 10,
		marginBottom: 10,
		width: 200,
		height: 45
    },
    tabs: {
        width: '100%'
    }
    
})

const ProductPackageGroupListing = (props) => {
	const { classes } = props;

    const [productPackages] = global.get('productPackages')
    const [records] = global.get('productPackageGroups')

    const [dialogContent, setDialogContent] = useState()
    
	useEffect(() => {
        global.set({ headerTitle: 'PACKAGEGroupS' })
	}, []);

	const upsertRecord = (record) => {
        setDialogContent(
            <AddEditProductPackageGroup record={record} productPackages={productPackages} close={closeContent}/>
        )
    }
    
    const getColumnWidth = (rows, accessor, headerText) => {
        const maxWidth = 400
        const magicSpacing = 10
        const cellLength = Math.max(
          ...rows.map(row => (`${row[accessor]}` || '').length),
          headerText.length,
        )
        return Math.min(maxWidth, cellLength * magicSpacing)
    }

    const closeContent = () => {
        setDialogContent(null)
    }

    const showRecords = () => {
        return (
            <div  className={classes.listing}>
                <Grid container>
                    <Paper style={{ width: '100%' }} className={classes.rootTable}>
                        <div>
                            <Grid container>
                                <Grid item xs={12} sm={9} style={{ padding: 14, textAlign:'left', marginTop:'-8px'}}>
                                    <Typography variant="h6" color="primary">
                                        PackageGroups
                                    </Typography>
                                </Grid>
                                <Grid item lg={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.addButton}
                                        onClick={upsertRecord}
                                    >
                                        <div>
                                            <AddCircleOutline className={classes.addIcon} />
                                            <Typography variant="subtitle1" style={{ color: 'white', width: 190 }}>
                                                Add Package Group
                                            </Typography>
                                        </div>
                                    </Button>
                                </Grid>
                            </Grid>
                            {records && records.length > 0 && 
                                <ReactTable
                                    data={records}
                                    filterable
                                    defaultFilterMethod={(filter, row) =>
                                        String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
                                    }
                                    defaultSorted={[
                                        {
                                            id: "name",
                                            desc: true
                                        }
                                    ]}
                                    columns={[
                                        {
                                            Header: "Product PackageGroups",
                                            columns: [
                                                {
                                                    Header: "Name",
                                                    accessor: "name",
                                                    width: getColumnWidth(records, 'accessor', 'Name                   '),
                                                },
                                                {
                                                    Header: "Description",
                                                    accessor: "description",
                                                    width: getColumnWidth(records, 'accessor', 'Description                   '),
                                                },
                                                {
                                                    id: "effectiveDate",
                                                    Header: "Effective Date",
                                                    accessor: "effectiveDate",
                                                    width: getColumnWidth(records, 'accessor', 'Effective Date'),
                                                    accessor: rec => {
                                                        return rec.effectiveDate ? moment(rec.effectiveDate).local().format("MM/DD/YYYY") : ''
                                                    },
                                                },
                                                {
                                                    id: "terminationDate",
                                                    Header: "Termination Date",
                                                    width: getColumnWidth(records, 'accessor', 'Termination Date'),
                                                    accessor: rec => {
                                                        return rec.terminationDate ? moment(rec.terminationDate).local().format("MM/DD/YYYY") : ''
                                                    },
                                                },
                                                {
                                                    Header: "Product Packages",
                                                    accessor: "productPackageNamesFriendly",
                                                    // width: getColumnWidth(records, 'accessor', 'Products'),
                                                },
                                            ]
                                        },
                                    ]}
                                    getTrProps={(state, rowInfo) => {
                                        if (rowInfo && rowInfo.row) {
                                            // console.log('TYLER ' + JSON.stringify(rowInfo))
                                            // console.log('TYLER 1 ' + JSON.stringify(rowInfo.row))
                                            return {
                                                onClick: (e) => {
                                                    upsertRecord(rowInfo.row._original)
                                                },
                                                // style: {
                                                //     background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                                                //     color: rowInfo.index === this.state.selected ? 'white' : 'black'
                                                // }
                                            }
                                        } else {
                                            return {}
                                        }
                                    }}
                                    defaultPageSize={15}
                                    className="-striped -highlight"
                                />
                            }
                            <br />
                        </div>
                    </Paper>
                    <div style={{ textAlign: "center" }}>
                        <em>Tip: Hold shift when sorting to multi-sort!</em>
                    </div>
                </Grid>
            </div>
        )
    }

    return (
        <Grid container >
            <div className={styles.root} style={{ width: '100%' }}>

                <Grid item lg={12} >
                    {showRecords()}
                </Grid>
                <Grid item lg={12} >
                    {dialogContent}
                </Grid>
            </div>
        </Grid>
    );
};

export default withRouter(withStyles(styles, { withTheme: true })(ProductPackageGroupListing));