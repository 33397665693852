import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, Divider, CircularProgress, IconButton, Dialog, DialogTitle, DialogActions } from '@material-ui/core';
import theme from '../../theme'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import log from 'loglevel'
import _ from 'lodash';
import { ChromePicker } from 'react-color';
import upsertBranding from '../../actions/upsertBrandingApi';
import CropFreeIcon from '@material-ui/icons/CropFree';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    paper: {
        width:'100%',
        height:'100%'
    },
    divider: {
        margin: '20px',
        width: '100%'
    },
    rowContainer: {
        height:'100px',
        justifyContent: 'center',

    },
    colorPickerCell:{
        height:'300px',
        marginTop: '0px'
    },
    imgPreview:{
        maxHeight:'80px',
    },
}))

export default (props) => {
    const classes = useStyles();
    const [masterBrandingSave] = global.get('masterBrandingSave')
    const [restaurant] = global.get('restaurant')
    const [entity, setEntity] = useState(props.group ? props.group : props.agency ? props.agency : restaurant)
    const modelType = props.group ? 'restaurantGroup' : props.agency ? 'agency' : 'restaurant';
    
    const [brandingColor, setBrandingColor] = useState(global.f(entity, 'branding.primaryColor'));
    const [pickerColor, setPickerColor] = useState(global.f(entity, 'branding.primaryColor'));
    const [seeColorPicker, toggleSeeColorPicker] = useState(false);
    const [logo, setLogo] = useState(null);
    const [logoPreview, setLogoPreview] = useState(global.f(entity, 'branding.logo'));    
    const [splashLogo, setSplashLogo] = useState(null)
    const [splashLogoPreview, setSplashLogoPreview] = useState(global.f(entity, 'branding.splashLogo'))
    const [loading, setLoading] = useState(false)
    const [hasChanges, setHasChanges] = useState(false)
    const [chainedSave] = useState(props.save)

    useEffect(() => {
        if (masterBrandingSave && hasChanges) {
            saveBrandingConfiguration()
        }
    }, [masterBrandingSave])

    useEffect(()=> {
        if (!entity) {
            const selectedEntity = props.group ? props.group : props.agency ? props.agency : restaurant
            setEntity(selectedEntity)
            setBrandingColor(global.f(selectedEntity, 'branding.primaryColor'))
            setLogoPreview(global.f(selectedEntity, 'branding.logo'))
            setSplashLogoPreview(global.f(selectedEntity, 'branding.splashLogo'))
            setHasChanges(false)
        }
    }, [restaurant])

    const saveBrandingConfiguration = async () => {
        setLoading(true)
        const changesObj = {}
        if ( brandingColor !== global.f(entity, 'branding.primaryColor' )) {
            changesObj.primaryColor = brandingColor
        }
        let uploads = { logo, splashLogo, headerLogo: logo } // NOTE: we save logo twice on purpose smh
        for (let [key, value] of Object.entries(uploads)) { // only attach changes to changesObj
            if (value) {
                changesObj[`${key}`] = value
            }
        }
        const id = entity._id
        await upsertBranding(id, modelType, changesObj)
        const iFrame = document.getElementById('iFramePreview')
        if(iFrame) {
            iFrame.src = document.getElementById('iFramePreview').src //reload iFrame with new branding
        }
        setHasChanges(false)
        setLoading(false)
        if (props.refresh) props.refresh(true)
    }

    function generatePreview(previewHook, fileHook, event) {
        setHasChanges(true)
        const input = event.target;
        const reader = new FileReader();
        let img;
        const file = input.files && input.files.length > 0 ? input.files[0] : null
        if(file){
            if(file.size <= (1024*1024*5) ){ //5MB
                fileHook(file)
                reader.onload = function(){
                    img = reader.result;
                    previewHook(img)
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
            } else {
                global.showNotification('The file size is too large, 5MB limit', 'error')
            }
        }   
    };

    const showColorPicker = () => {
        return (
            <Dialog
                aria-labelledby="customized-dialog-title"
                aria-describedby="simple-modal-description"
                maxWidth={'lg'}
                open={seeColorPicker}
                style={{ margin: 0, height: '100%', width: '100%', index:99999}}
                onClose={ ()=>toggleSeeColorPicker(!seeColorPicker) }
            >
                <ChromePicker
                    color={pickerColor ? pickerColor: '#000000'}
                    onChangeComplete={
                        (e) => {
                            if (e && e.hex) {
                                setPickerColor(e.hex)
                            }
                        }
                    }
                />
                <DialogActions>
                    <Button variant="contained" onClick={()=>toggleSeeColorPicker(false)}>CANCEL</Button>
                    <Button variant="contained" style={{backgroundColor:pickerColor}} 
                        onClick={()=> {
                            if (pickerColor != brandingColor) {
                                setHasChanges(true)
                                setBrandingColor(pickerColor)
                            }
                            toggleSeeColorPicker(false)
                        }}
                    >
                        SELECT
                    </Button>
                </DialogActions>
            </Dialog>   
        )
    }

    const brandingItems = [
        {
            text: 'Brand Color',
            value: brandingColor,
            show: true,
            preview: (
                <div className={classes.imgPreview}
                    style={{width: '80px', height: '80px', backgroundColor: `${brandingColor}`, filter:'drop-shadow(1px 1px 1px var(--dash-gray)'}} >
                </div>
            ),
            action: ()=> toggleSeeColorPicker(!seeColorPicker)
        },
        {
            text: 'Logo',
            value: logoPreview,
            show: true,
            preview: <img src={logoPreview} className={classes.imgPreview} style={{filter:'drop-shadow(1px 1px 1px var(--dash-gray)'}}/>,
            action: ()=>{document.querySelector('#input-logo').click()}
        },
        {
            text: 'Splash Logo',
            caption: ' (.png required)',
            value: splashLogoPreview,
            show: restaurant && restaurant.appName != "TextPay" && !props.hideSplash,
            preview: <img src={splashLogoPreview} className={classes.imgPreview} style={{filter:'drop-shadow(1px 1px 1px var(--dash-gray)'}}/>,
            action: ()=>{document.querySelector('#input-splash').click()},
        },
    ]

	return (
        <>
            <div style={{width:'100%', display:'flex', flexWrap:'wrap', justifyContent:"center"}}>
                {brandingItems.filter(i => i.show).map((item, index) => {
                    return (
                        <div style={{display:'flex', flexGrow:1, flexDirection:'column', alignItems:'stretch', padding:'5px 0px 16px 8px', margin: 4, marginRight:(index == brandingItems.filter(i => i.show).length -1)? 0 : 4, marginLeft:(index == 0) ? 0 : 4, borderRadius:'4px', border:'solid 1px #e2e8f0'}}>
                            {/* CARD HEADER */}
                            <div style={{display:'flex', justifyContent:'space-between', alignContent:'center', paddingLeft:8}}>
                                <div style={{top: -15, backgroundColor: 'var(--white)', paddingLeft: 10, paddingRight: 10, width: 'auto', display: 'inline-block', position: 'relative'}}>
                                    <b>{item.text}</b>
                                    <p>{item.caption ? item.caption : null}</p>
                                </div>
                                <div>
                                    <IconButton>
                                        <EditIcon onClick={item.action} style={{color:'rgba(0, 0, 0, 0.54)'}}/>
                                    </IconButton>
                                </div>
                            </div>
                            <div style={{padding:0, display:'flex', justifyContent:'center', alignItems:'center'}}>
                                {item.value ? item.preview : <CropFreeIcon style={{opacity:'.1', height:'32px', width:'32px'}}/>}
                            </div>        
                        </div>
                    )
                })}
            </div>

            {/* hidden native file uploaders, accessed via querySelector */}
            <input id='input-logo' type='file' accept={'image/png, image/jpeg, image/jpg, image/gif'} 
                onChange={(e) => generatePreview(setLogoPreview, setLogo, e)} 
                style={{display:'none'}}
            />
                <input id="input-splash" type='file' accept={'image/png'} 
                onChange={(e) => generatePreview(setSplashLogoPreview, setSplashLogo, e)} 
                style={{display:'none'}}
            />
            {chainedSave == undefined && // not passed in
                <div style={{display:'flex', justifyContent:'center', marginTop:8}}>
                    <Button disabled={ !(logo || splashLogo || (brandingColor !== global.f(entity, 'branding.primaryColor'))) } variant="contained" color="primary" style={{margin: 'auto', height:'36px', width:'180px', zIndex:1}} onClick={()=>saveBrandingConfiguration()}>
                        {loading ? <CircularProgress size={15} color="#000000" style={{zIndex:2000}}/> : <span style={{fontSize:'13px'}}>Save changes</span> }
                    </Button>
                </div>
            }

            {showColorPicker()}
        </>
	);
};
