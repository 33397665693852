import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Bar } from 'react-chartjs-2';
import moment from 'moment'
import DashNowLogo from '../../assets/svgs/dashNowLogo';
import {
	Typography,
    Button,
    Chip,
    Grid,
    Paper,
    Table, 
    TableHead, 
    TableRow,
    TableCell,
} from '@material-ui/core';
import { Save } from '@material-ui/icons';
import Banner from './../../dashboard/components/Banner'
import {ReactComponent as PaidStamp} from '../../assets/svgs/PaidStamp.svg';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {ReactComponent as PastDueStamp} from '../../assets/svgs/PastDueStamp.svg';
import {ReactComponent as VerificationFailedStamp} from '../../assets/svgs/VerificationFailedStamp.svg';
import DConfirm from '../dash/DConfirm';

const useStyles = makeStyles((theme) => ({
    padLeft: {
        paddingLeft: '30px !important',
    },
    padLeft2: {
        paddingLeft: '50px !important',
    },
}))

export default function Invoice(props) {

    // console.log("invoice")
    // console.log(JSON.stringify(props))
    
    const classes = useStyles();

    const [labels, setLabels] = useState([]);
    const [confirmOpen, setConfirmOpen] = useState(false);

    let transactions = global.f(props, 'invoice.usageTotals.transactions', null) ? props.invoice.usageTotals.transactions.all.filter(p => p.date != 'all') : []
    if (transactions && transactions.length) {
        transactions = global._.sortBy(transactions, 'date')
    }

    let discounts = props.invoice.discounts && global._.filter(props.invoice.discounts, {deleted: false})

    const data = {
        labels: transactions && transactions.length && transactions.map(p => moment(p.date).utc().format('M/D')),
        datasets: [
            {
                label: ' # Transactions',
                fill: 'origin',
                backgroundColor: '#7746C199', 
                borderWidth: '0',
                pointBackgroundColor: '#9575CD45',
                pointHoverBackgroundColor: '#9575CD75',
                pointBorderColor: 'var(--white)',
                pointBorderWidth: '.6',
                lineTension: 0.3,
                data: transactions && transactions.length && transactions.map(p => p.count),
            },
            //  {
            //     label: 'Other Customers',
            //     fill: 'origin',
            //     backgroundColor: '#dbdbdb85',
            //     borderColor: '#DDDDDD85',
            //     borderWidth: '2',
            //     pointBackgroundColor: '#DDDDDD',
            //     pointHoverBackgroundColor: '#DDDDDD',
            //     pointBorderColor: 'var(--white)',
            //     pointBorderWidth: '.75',
            //     lineTension: 0.3,
            //     data: global.f(props, 'invoice.usageTotals.payments', null) ? props.invoice.usageTotals.payments.map(p => p.ticketGuestCount) : [400, 385, 400, 350, 362, 335, 340, 330],
            // },
        ]
    };

    const barChartOptions = {
        plugins: {
            datalabels: {
                display: false,
            }
        },
        legend: {
            display: false
        },
        scales: {
            xAxes: [
                {
                    stacked: true,
                    barPercentage: 0.69
                }
            ],
            yAxes: [
                {
                    stacked: true,
                    ticks: {
                        stepSize: 25
                    },
                    scaleLabel: {
                        display: true,
                        labelString: '# Transactions'
                    }
                },

            ]
        }
    };

    
    // const [invoice, setInvoice] = useState(null)

    // useEffect(() => {
        // setInvoice(props.invoice)
    // }, [props.invoice])

	return (
		<Grid item style={{display: 'flex', flexGrow: 1}}>
            <Paper elevation={props.elevation ? props.elevation : 1} style={{padding: 50, width: '100%'}}>
                {props.invoice.deleted && <Typography style={{color: 'red'}}>Deleted</Typography>}
                {!props.invoice.deleted &&
                    <Grid container xs={12}>
                        <Grid item xs={4} align="left">
                            <DashNowLogo width={150} height={150} />
                        </Grid>

                        <Grid item xs={4} align="center">
                            {global.hasPermission('admin.billing.*') && !props.showDetails && <>
                                {!props.invoice.payment.paid && props.invoice.dueDate && props.invoice.totals.total > 0 && moment(props.invoice.dueDate).utc().isBefore(new Date()) && <PastDueStamp height={150} />}
                                {props.invoice.payment.paid && props.invoice.payment.verified != false && <PaidStamp height={150} />}
                                {props.invoice.payment.paid && props.invoice.payment.verified == false && <VerificationFailedStamp height={150} />}
                            </>}
                        </Grid>

                        <Grid item xs={4} align="right">
                            <Typography display="block" variant="body2" style={{fontSize: 18}}>Dash Now, LLC</Typography>
                            <Typography display="block" variant="body2">8711 Penrose Lane #200</Typography>
                            <Typography display="block" variant="body2">Lenexa, KS 66219</Typography>
                            <Typography display="block" variant="body2">800-846-7730</Typography>
                            <Typography display="block" variant="body2">info@dashnow.com</Typography>
                        </Grid>


                        <Grid item xs={6} style={{marginTop: 30, marginBottom: 30}}>
                            <Typography display="block" variant="body2" style={{fontSize: 22}}>{props.invoice.restaurant.name}</Typography>
                            <Typography display="block" variant="body2">{props.invoice.restaurant.locationInfo && props.invoice.restaurant.locationInfo.addressLine1 ? props.invoice.restaurant.locationInfo.addressLine1 : ''}</Typography>
                            <Typography display="block" variant="body2">{props.invoice.restaurant.locationInfo && props.invoice.restaurant.locationInfo.addressLine2 ? props.invoice.restaurant.locationInfo.addressLine2 : ''}</Typography>
                            <Typography display="block" variant="body2">
                                {props.invoice.restaurant.locationInfo && props.invoice.restaurant.locationInfo.city ? props.invoice.restaurant.locationInfo.city : ''}
                                {props.invoice.restaurant.locationInfo && props.invoice.restaurant.locationInfo.state ? ', ' + props.invoice.restaurant.locationInfo.state : ''}
                                {props.invoice.restaurant.locationInfo && props.invoice.restaurant.locationInfo.zipCode ? ' ' + props.invoice.restaurant.locationInfo.zipCode : ''}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{marginTop: 30, marginBottom: 30}} align="right">
                            <Typography display="block" variant="body2" style={{fontSize: 18}}>
                                {props.invoice.status.toUpperCase() == "INPROGRESS" && `IN-PROGRESS`}
                                {props.invoice.status.toUpperCase() != "INPROGRESS" && `Invoice # ${props.invoice.invoiceNumber}`}
                            </Typography>
                            <Typography display="block" variant="body2">
                                {props.invoice.status.toUpperCase() != "INPROGRESS" && `Date: ${moment(props.invoice.invoiceDate).utc().format("MM/DD/YYYY")}`}
                            </Typography>
                            <Typography display="block" variant="body2">Period: {moment(props.invoice.fromDate).utc().format("MM/DD/YYYY")} - {moment(props.invoice.thruDate).utc().format("MM/DD/YYYY")}</Typography>
                        </Grid>

                        <Grid item xs={12}>

                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontSize: 16}}>
                                            Description
                                        </TableCell>
                                        <TableCell align="right" style={{fontSize: 16}}>
                                            Price
                                        </TableCell>
                                        <TableCell align="center" style={{fontSize: 16}}>
                                            Qty
                                        </TableCell>
                                        <TableCell align="right" style={{fontSize: 16}}>
                                            Total
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {props.invoice && props.invoice.invoiceItemGroups && props.invoice.invoiceItemGroups.map(iig => {
                                    return (
                                        <>
                                            <TableRow>
                                                <TableCell className={classes.padLeft}>
                                                    {iig.description}
                                                    {iig.waived &&
                                                        <Chip label="Waived" size="small" style={{ marginLeft: 10, backgroundColor: '#FFC64D', borderRadius: 3 }} />
                                                    }
                                                </TableCell>
                                                <TableCell align="right">
                                                    {iig.rollUpTotalOnInvoice && ('$' + (iig.price/100).toFixed(2))}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {iig.rollUpTotalOnInvoice && iig.qty}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {iig.rollUpTotalOnInvoice && ('$' + (iig.totalPrice/100).toFixed(2))}
                                                </TableCell>
                                            </TableRow>
                                            {iig.items && global._.filter(iig.items, {showOnInvoice: true}).map(ii => {
                                                return (
                                                    <TableRow>
                                                        <TableCell className={classes.padLeft2}>
                                                            {ii.description}
                                                            {ii.waived &&
                                                                <Chip label="Waived" size="small" style={{ marginLeft: 10, backgroundColor: '#FFC64D', borderRadius: 3 }} />
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {(ii.price/100).toFixed(2)}
                                                        </TableCell>
                                                        <TableCell align="center"> 
                                                            {ii.qty}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {(ii.totalPrice/100).toFixed(2)}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </>
                                    )
                                })}

                                {discounts && discounts.length > 0 && 
                                    <TableRow>
                                        <TableCell className={classes.padLeft}>Discounts</TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                }
                                {discounts && discounts.length > 0 && discounts.map(discount => {
                                    return (
                                        <TableRow>
                                            <TableCell className={global.hasPermission('admin.billing') ? classes.padLeft : classes.padLeft2}>
                                                <Grid container alignItems="center">
                                                    {global.hasPermission('admin.billing') && !props.invoice.payment.paid &&
                                                        <DeleteForeverIcon style={{cursor:'pointer'}} color="secondary" onClick={() => setConfirmOpen(true)}/>
                                                    }
                                                    {discount.description}
                                                    <DConfirm
                                                        title="Remove Discount?"
                                                        open={confirmOpen}
                                                        setOpen={setConfirmOpen}
                                                        onConfirm={() => props.removeDiscount(props.invoice._id, discount._id)}
                                                    >
                                                        Are you sure you want to delete this discount?
                                                    </DConfirm>
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="right">
                                                {discount.priceType == 'percent' && (discount.price/100).toFixed(0) + '%'}
                                            </TableCell>
                                            <TableCell align="center">
                                                {/* {discount.qty} */}
                                            </TableCell>
                                            <TableCell align="right">
                                                {(discount.totalPrice/100).toFixed(2)}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}

                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontSize: 16}}>
                                            Total
                                        </TableCell>
                                        <TableCell align="right" style={{fontSize: 16}}>
                                        </TableCell>
                                        <TableCell align="center" style={{fontSize: 16}}>
                                        </TableCell>
                                        <TableCell align="right" style={{fontSize: 16}}>
                                            ${(props.invoice.totals.total/100).toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </Grid>

                        {transactions && transactions.length > 0 && !props.invoice.internal && 
                            <Grid item xs={12} style={{marginTop: 40}}>
                                <Typography display="block" style={{fontSize: 20, marginBottom: 15}}>Daily Transaction Details</Typography>
                                <Bar data={data} options={barChartOptions} height={60} />
                            </Grid>
                        }

                    </Grid>
                }
            </Paper>
        </Grid>
	)
}
