import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
	AddCircleOutline,
} from '@material-ui/icons';
import { Button, TextField, Checkbox } from '@material-ui/core';
import moment from 'moment'
import getCustomerListApi from '../actions/getCustomerListApi'
import { withRouter } from 'react-router-dom';

import ReactTable from "react-table";
import "react-table/react-table.css";

const styles = (theme) => ({
    root: {
    },
	addRequestIcon: {
		float: 'left',
		marginTop: 5,
		marginRight: 5,
		width: '20px',
		height: '20px'
	},
	addRequestButton: {
		float: 'right',
		marginLeft: 20,
		marginRight: 20,
		marginTop: 10,
		marginBottom: 10,
		width: 200,
		height: 45
	},
})

const CustomerListing = (props) => {
    const { classes } = props;
    const [restaurant] = global.get('restaurant', null)

	const [customers, setCustomers] = React.useState([])

    async function fetchdata() {
        if (restaurant) {
            let data = await getCustomerListApi(restaurant._id, true)
            if (data) {
                global.set({ dashboardCustomerData: data })
                setCustomers(formatCustomers(data))
            }
        }
    }



	useEffect(() => {
        fetchdata();
        global.set({headerTitle: 'CUSTOMER LISTING'})
	}, []);
    
    useEffect(() => {
        fetchdata();
    }, [restaurant]);


    const formatCustomers = (customers) => {
        console.log('customers')
        console.log(customers)
        return customers.map((customer) => {
            console.log('customer')
            console.log(customer)
			return {
                // id: customer._id,
                fullName: customer.firstName + ' ' + customer.lastName,
                visitCount: customer.customerPaymentCount,
                paidTotal: customer.customerPaymentDollars ? `$${Number(customer.customerPaymentDollars).toFixed(2)}`: '',
                lastVisited: customer.lastVisit ? moment(customer.lastVisit).fromNow() : '',
                averagePayment: customer.customerPaymentDollars && customer.customerPaymentCount ? `$${Number((customer.customerPaymentDollars/customer.customerPaymentCount)).toFixed(2)}` : 'N/A'
                
			};
		});
	};

	const addRequest = async () => {
		navigateToCustomer(null)
	};

	const navigateToCustomer = async (id) => {
		// console.log("NAVIGATE TO CUSTOMER " + id)
        // global.selectedRestaurant = id
        
        //TODO open dialog
        // history.push(`/custommer/?c=${id}`)
	}

	return (
		<div  className={classes.listing}>
			<Grid container>
                <Grid item style={{padding:8, paddingLeft:12}} lg={12}>
                    <Typography variant="h4">Customer Insight</Typography>
                </Grid>
                <Paper style={{ width: '100%' }} className={classes.rootTable}>
                    <div>
                        <Grid container>
                            <Grid item lg={9}></Grid>
                            <Grid item lg={3}>

                            </Grid>
                        </Grid>
                        <ReactTable
                            data={customers}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
                            }
                            defaultSorted={[
                                {
                                    id: "createdAt",
                                    desc: true
                                }
                            ]}
                            columns={[
                                {
                                    Header: "Requests",
                                    columns: [
                                        {
                                            Header: "Name",
                                            accessor: "fullName"
                                        },
                                        {
                                            Header: "# of Visits",
                                            accessor: "visitCount"
                                        },
                                        {
                                            Header: "Total Spent",
                                            accessor: "paidTotal"
                                        },
                                        {
                                            Header: "Last Visited",
                                            accessor: "lastVisited"
                                        },
                                        {
                                            Header: "Average Spend",
                                            accessor: "averagePayment"
                                        },
                                    ]
                                },
                            ]}
                            getTrProps={(state, rowInfo) => {
                                if (rowInfo && rowInfo.row) {
                                    // console.log('TYLER ' + JSON.stringify(rowInfo))
                                    // console.log('TYLER 1 ' + JSON.stringify(rowInfo.row))
                                    return {
                                        onClick: (e) => {
                                            navigateToCustomer(rowInfo.row)
                                        },
                                        // style: {
                                        //     background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                                        //     color: rowInfo.index === this.state.selected ? 'white' : 'black'
                                        // }
                                    }
                                } else {
                                    return {}
                                }
                            }}
                            defaultPageSize={15}
                            className="-striped -highlight"
                        />
                        <br />
                    </div>
                </Paper>
                <div style={{ textAlign: "center" }}>
                    <em>Tip: Hold shift when sorting to multi-sort!</em>
                </div>;
            </Grid>
        </div>
	);
};

CustomerListing.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withRouter(withStyles(styles, { withTheme: true })(CustomerListing));