import log from 'loglevel';
import dashFetch from '../utils/dashFetch';


export default async function (request, requestType, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('publish api');
		const url = `${global.dashServerUrl}/dashboard/api/v1/request/publish`;
		let deviceId = localStorage.deviceId;

		const body = {
			request,
			requestType,
		};
		let options = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		}

		fetch(url, options, debug).then((res) => {
			if (res.ok) {
				res.json().then((json) => {
					if (json.success) {
						resolve(true);
					} else {
						global.showNotification(json.error, "error")
						resolve(false);
					}
				}).catch((err) => {
					reject(err.message);
				})
			} else {
				reject(res);
			}
		}).catch((err) => { 
			log.error(err)
			reject(err.message);
		})
	})
}