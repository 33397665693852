import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Paper, Drawer, Zoom, TableHead, TableBody, TableRow, TableCell, Table, TableContainer, TablePagination, CircularProgress, LinearProgress, Popper, Button, ClickAwayListener, Menu } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import DFlag from '../components/dash/DFlag'
import { Box } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import getPaymentDataApi from '../actions/getPaymentDataApi';
import theme from '../theme'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import log from 'loglevel'
import _ from 'lodash';
import DateRangeIcon from '@material-ui/icons/DateRange';

import PaymentDetails from "./components/PaymentDetails";
import RefundRequestWindow from "./components/RefundRequestWindow";
import PaymentSearch from './components/PaymentSearch';
import exportTransactionListApi from '../actions/exportTransactionListApi';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';


import moment from 'moment-timezone'

const useStyles = makeStyles((theme) => ({
    root: {
    },
    scrolling: {
        overflow: 'auto',
        height: 'auto'
    },
    payments: {
        paddingLeft: 5,
        paddingRight: 5,
        maxHeight: 'calc(100% - 3em)',
    },
    paper: {
        // paddingRight:8,
        // paddingleft:8,
        // margin: 8,
        // minHeight: '100%',
        // maxHeight: '100%',
        width:'100%',
        height:'100%'
    },
    iconWrapper: {
        height: '2.7em', 
        display: 'flex',
        alignItems: 'center' 
    }
}))


export default (props) => {
    const maxRangeDays = 30
    const classes = useStyles();
    let [restaurant] = global.get('restaurant')
    let [payments] = global.get('payments')
    let [orderTerminology] = global.get('orderTerminology')
    let [filteredPayments, setFilteredPayments] = useState(payments)
    let [dialogContent, setDialogContent] = useState()
    let [loading, setLoading] = useState(true)
    let [showVerified, setShowVerified] = useState(false)
    let [showFilters, setShowFilters] = useState(false)
    let [refreshResults, setRefreshResults] = useState(false)

    let smallScreen = useMediaQuery(theme().breakpoints.down('sm'));
    let [paymentSearchFilter] = global.get('paymentSearchFilter', '')
    let [periodFilter, setPeriodFilter] = global.get('periodFilter')


    let canRefund = global.hasPermission('restaurant.refund')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)

    const [startDate, setStartDate] = useState(moment().clone().startOf('day'))
    const [endDate, setEndDate] = useState(periodFilter ? moment().clone().subtract(periodFilter,'days').endOf('day') : moment().clone().endOf('day'))
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [maxRange, setMaxRange] = useState();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    
    const closeContent = (reload = false) => {
        setDialogContent(null)
        if (reload == true) {
            fetchData()
        }
    }

    const showDetails = (payment) => {
        setDialogContent(
            <PaymentDetails payment={payment} close={closeContent} />
        )
    }

    const showRefundWindow = async (payment) => {
        if (payment.check && payment.status == 'captured') {
            let check = {_id: payment.check._id, payments: [payment], checkNumber: payment.checkNumber, checkNumberDisplay: payment.checkNumberDisplay, orderNumber: payment.orderNumber}
            setDialogContent(
                <RefundRequestWindow check={check} close={closeContent} />
            );
        }
    }

    let [verifiedData, setVerifiedData] = useState({
        verifiedCount             : 0,
        verifiedAmountPaidTotal   : 0.0,
        unverifiedCount           : 0,
        unverifiedAmountPaidTotal : 0.0
    })

    const fetchData = async () => {
        setPage(0)
        setLoading(true)
        if (restaurant) {
            payments = await getPaymentDataApi(restaurant._id, startDate, endDate)
            if (payments && payments.length > 0) {
                if (payments.length > 999) {
                    global.showNotification("Max amount 1000 records returned", 'info')
                }
                let verifiedCount             = 0
                let verifiedAmountPaidTotal   = 0.0
                let unverifiedCount           = 0
                let unverifiedAmountPaidTotal = 0.0
                for (let filteredPayment of payments) {
                    log.info("filteredPayment.paymentAmount: " + filteredPayment.paymentAmount)
                    let verified = filteredPayment.verification && filteredPayment.verification.verified
                    if (verified) {
                        verifiedCount += 1
                        verifiedAmountPaidTotal += filteredPayment.paymentAmount
                    } else {
                        unverifiedCount += 1
                        unverifiedAmountPaidTotal += filteredPayment.paymentAmount
                    }
                }
                setVerifiedData({verifiedCount, verifiedAmountPaidTotal, unverifiedCount, unverifiedAmountPaidTotal})
            }
            global.set({'payments': payments })
        }
        setLoading(false)
    }



    const filterData = (paymentSearchFilter) => {
        if (!paymentSearchFilter) {
            paymentSearchFilter = ''
            setFilteredPayments(payments)
            setPage(0)
        } else {
            setLoading(true)

            const paymentSearchFilterTUC = paymentSearchFilter.toUpperCase().replace('.', '')
            if (payments) {
                let fp = payments.filter(payment => {
                    return (
                        (payment.checkNumber && payment.checkNumber.toString().toUpperCase().includes(paymentSearchFilterTUC)) 
                        ||  (payment.orderNumber && payment.orderNumber.toString().toUpperCase().includes(paymentSearchFilterTUC)) 
                        ||  (payment.tableNumber && payment.tableNumber.toString().toUpperCase().includes(paymentSearchFilterTUC)) 
                        ||  (payment.paidBy && payment.paidBy.toString().toUpperCase().includes(paymentSearchFilterTUC)) 
                        ||  (payment.serverIdentifier && payment.serverIdentifier.toUpperCase().includes(paymentSearchFilterTUC)) 
                        ||  (payment.checkAmount && payment.checkAmount.toString().toUpperCase().includes(paymentSearchFilterTUC))
                        ||  (payment.tipAmount && payment.tipAmount.toString().toUpperCase().includes(paymentSearchFilterTUC)) 
                        ||  (payment.paymentAmount && payment.paymentAmount.toString().toUpperCase().includes(paymentSearchFilterTUC))
                    )
                })
                setFilteredPayments(fp)
            }
            setLoading(false)
        }
    }

    const generateExportFile = () => {
        exportTransactionListApi(restaurant, true, startDate, endDate)
    }

    const setPeriodDates = () => {

        setMaxRange('');
        let date = periodFilter == 1 ? moment().clone() : moment().clone().subtract(periodFilter,'days')
        let startDateTz = date.startOf('day')
        setStartDate(startDateTz)

        // endDate - always today
        setEndDate(moment().clone().endOf('day'))

        if (!showFilters) {
            setRefreshResults(true)
        }
    }

    const setStartAndEndDate = (sdate, edate) => {
        // Clear warning and period
        setPeriodFilter(null)
        setMaxRange('');

        // Move date if diff between Start and End more than maxRange
        if (Math.abs(sdate.diff(edate, 'days')) > maxRangeDays){
            
            // Move end date back
            if (!moment(sdate).isSame(startDate, 'day')){
                edate = moment(sdate).clone().add(maxRangeDays,'days')
            }

            // Move start date up
            if (!moment(edate).isSame(endDate, 'day')){
                sdate = moment(edate).clone().subtract(maxRangeDays,'days')
            }

            setMaxRange('Max range is 30 days');
        }

        setStartDate(moment(sdate).clone().startOf('day'));
        setEndDate(moment(edate).clone().endOf('day'));
    }

    useEffect(() => {
        console.log('Setting Period Filter', periodFilter);
        if (restaurant) {
            if (periodFilter) setPeriodDates()
            setShowVerified(restaurant.backOffice && !(restaurant.config.pos.autoCloseAvailable && restaurant.config.pos.autoClosePayments)) //periodFilter == 1 && 
        }
    }, [restaurant, periodFilter])




    useEffect(() => {
        if (refreshResults) {
            fetchData()
            setRefreshResults(false)
        }
    }, [refreshResults])
    
    useEffect(() => {
        setRefreshResults(true)
    }, [restaurant])

    let localLang = 'en-US'
    if (restaurant && restaurant.currency) {
        switch (restaurant.currency) {
            case 'CAD':
                localLang = 'en-CA'
                break;
            default:
                localLang = 'en-US'
                break;
        }

    }
    
    useEffect(() => {

        filterData(paymentSearchFilter)
    }, [paymentSearchFilter, payments])


    const showHideFilters = (e) => {
        setShowFilters(!showFilters)
        setAnchorEl(!showFilters ? e.currentTarget : null);
    }


    const menuContent = () => {
        return (
            <Paper style={{backgroundColor:'var(--background)', padding:5,}}>

                <ToggleButtonGroup exclusive size="small" 
                value={periodFilter} 
                onChange={(e, value) => {if (value) global.set({periodFilter: value}); showHideFilters(e)}} 
                style={{height: 44, marginBottom:5, width:'100%'}}>

                    <ToggleButton key={0} value={1} style={{height: '100%', paddingLeft: 20, paddingRight: 20, borderTop: 'none', width:'100%', borderLeft: 'none'}}>Today</ToggleButton>
                    <ToggleButton key={1} value={7} style={{height: '100%', paddingLeft: 20, paddingRight: 20, borderTop: 'none', width:'100%', borderLeft: 'none'}}>{!smallScreen ? "Last" : ""} 7 days</ToggleButton>
                    <ToggleButton key={2} value={30} style={{height: '100%', paddingLeft: 20, paddingRight: 20, borderTop: 'none', width:'100%'}}>{!smallScreen ? "Last" : ""} 30 days</ToggleButton>
                    {/* <ToggleButton key={3} value={'90'} style={{height: '100%', paddingLeft: 20, paddingRight: 20, borderTop: 'none'}}>{!smallScreen ? "Last" : ""} 90 days</ToggleButton> */}
                </ToggleButtonGroup>
                <Paper style={{display:'flex', justifyContent:'space-between'}}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            autoOk
                            initialFocusedDate=""
                            showTodayButton
                            format="MM/dd/yyyy"
                            id="date-picker-inline"
                            label="Start Date"
                            InputLabelProps={{
                                shrink: true,
                            }}    
                            style={{width: 150, margin:'10px'}}
                            value={startDate}
                            onChange={date => { 
                                setStartAndEndDate(moment(date).clone().startOf('day'), endDate)
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            // minDate={endDate ? endDate.clone().endOf('day').subtract(32,'days') : moment().clone().endOf('day').subtract(32,'days')}
                            maxDate={endDate ? endDate.clone().endOf('day') : null}
                            helperText={maxRange}
                        />
                        
                        <KeyboardDatePicker
                            autoOk
                            initialFocusedDate=""
                            showTodayButton
                            format="MM/dd/yyyy"
                            id="date-picker-inline"
                            label="End Date"
                            InputLabelProps={{
                                shrink: true,
                            }}    
                            style={{width: 150, margin:'10px'}}
                            value={endDate}
                            onChange={date => { 
                                setStartAndEndDate(startDate, moment(date).clone().endOf('day')) 
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            // helperText={maxRange}
                            minDate={startDate ? startDate.clone().startOf('day') : null}
                            //maxDate={startDate && startDate.isBefore(moment().clone().startOf('day').subtract(32, 'days')) ? startDate.clone().endOf('day').add(32,'days') : moment().clone().endOf('day')}
                            maxDate={moment().clone().endOf('day')}
                        />
                    </MuiPickersUtilsProvider>
                    <div style={{display:'flex', alignItems:'center', padding:5}}>
                        <Button variant="contained" color="primary" onClick={() => {setRefreshResults(true); showHideFilters();}}>Apply</Button>
                    </div>
                </Paper>
                
            </Paper>
        )
    }

	return (
        <Grid container style={{ height:"100%", padding: 10}}>
            <Box display="flex" style={{ width:'100%', height: '60px', overflow: 'none', padding: 5}}>
                <Box display="flex" style={{ flexGrow: 1}}>
                    <Box style={{ flexGrow: 1}}>
                        <PaymentSearch />
                    </Box>
                </Box>
                <Box style={{marginLeft: 15}}>
                    <Paper>
                        <IconButton onClick={showHideFilters} color="primary" style={{padding: 10, height:44}} aria-label="export-custom-range">
                            <DateRangeIcon/>
                            {startDate && endDate &&
                                <>&nbsp;
                                    <Typography color="secondary">
                                        {periodFilter == '1' ? 'Today' :
                                            moment(endDate).endOf('day').isSame(moment().endOf('day')) && periodFilter ? 'Last ' + periodFilter.toString().toUpperCase() :
                                            startDate.endOf('day').isSame(endDate.endOf('day')) ? startDate.format('MM/DD') : startDate.format('MM/DD') + ' - ' + endDate.format('MM/DD')
                                        }
                                    </Typography>
                                </>
                            }
                        </IconButton>
                        <Menu
                            id="simple-Menu"
                            anchorEl={anchorEl}
                            keepMounted
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            MenuListProps={{style:{padding:0}}}
                            open={showFilters}
                            style={{padding:0, margin:0}}
                            onClose={showHideFilters}
                        >
                            {menuContent()}
                        
                        </Menu>
                    </Paper>
                </Box>
                <Box style={{marginLeft: 15}}>
                    <Paper>
                        <IconButton onClick={generateExportFile} color="primary" style={{padding: 10}} aria-label="export-custom-range">
                            <GetAppIcon/>
                        </IconButton>

                    </Paper>
                </Box>
            </Box>
            <Grid container style={{ width:'100%', maxHeight:'100%', height: 'calc(100% - 50px)', overflow: 'none'}} >


                <Grid item xs={12} container direction='row' style={{ width: '100%', height: smallScreen ? 'auto' : 'calc(100% - 50px)', minHeight: smallScreen ? 'calc(100% - 50px)' : '0', margin: 0 }}>
                    <Grid item xs={12} style={{ height: smallScreen ? 'auto' : '100%', width: '100%', padding: 5}} >
                        <Paper className={classes.paper}>
                            {loading && <LinearProgress />}
                            {!loading && filteredPayments &&
                                <TableContainer style={{height: '100%' }}>
                                    <Table id="payments-table" size="small" aria-label="a dense sticky table" stickyHeader style={{position: 'sticky'}}>
                                        <TableHead id="payments-table-head">
                                            <TableRow>
                                                <TableCell style={{backgroundColor: 'var(--white)'}}>Date</TableCell>
                                                <TableCell style={{backgroundColor: 'var(--white)'}}>Status</TableCell>
                                                <TableCell style={{backgroundColor: 'var(--white)'}}>{global.isTextPay() ? "Created By": "Server Name"}</TableCell>
                                                {!global.isTextPay() && <TableCell style={{backgroundColor: 'var(--white)'}}>Table Number</TableCell>}
                                                <TableCell style={{backgroundColor: 'var(--white)'}}>{orderTerminology} Number</TableCell>
                                                <TableCell style={{backgroundColor: 'var(--white)'}}>Guest Name</TableCell>
                                                <TableCell style={{backgroundColor: 'var(--white)'}}>Payment Method</TableCell>
                                                <TableCell style={{backgroundColor: 'var(--white)'}} align="right">{orderTerminology} Amount</TableCell>
                                                {restaurant.getVal('config.mobile.allowTips', false) && <TableCell style={{backgroundColor: 'var(--white)'}} align="right">Tip Amount</TableCell>}
                                                <TableCell style={{backgroundColor: 'var(--white)'}} align="right">Payment Amount</TableCell>
                                                <TableCell style={{backgroundColor: 'var(--white)'}} align="right">Refund Amount</TableCell>
                                                {showVerified && <TableCell style={{backgroundColor: 'var(--white)'}} align="right">Verified</TableCell>}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody id="payments-table-body">
                                            {filteredPayments && filteredPayments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(payment => {

                                                let canRefundPayment = canRefund && (payment.dashnowRefundable == true || payment.dashnowRefundable == undefined || payment.dashnowRefundable == null)

                                                let tipAmount = payment.tipAmount ? payment.tipAmount : 0
                                                tipAmount += payment.autoTipAmount

                                                return (
                                                    <>
                                                        <TableRow id={payment._id} selected={restaurant && restaurant._id == payment._id} onClick={canRefundPayment ? () => showRefundWindow(payment) : false} style={canRefundPayment ? payment.status == 'captured' ? { cursor: 'pointer' } : { cursor:'not-allowed', backgroundColor:'var(--background)'}: { cursor: 'none' }} className={payment.deleted && classes.deletedRow}>
                                                            <TableCell style={{minWidth: '185px'}}>{payment.createdAt ? restaurant.utcTimeZone ? moment(payment.createdAt).tz(restaurant.utcTimeZone).format("MM/DD/YYYY h:mm a") : moment(payment.createdAt).format("MM/DD/YYYY h:mm a") : ''}</TableCell>
                                                            <TableCell>{payment.status.toProperCase()}</TableCell>
                                                            <TableCell style={{minWidth: '200px'}}>{payment.serverIdentifier ? payment.serverIdentifier : ''}</TableCell>
                                                            {!global.isTextPay() && <TableCell>{payment.tableNumber ? payment.tableNumber : ''}</TableCell>}
                                                            <TableCell>{payment.checkNumberDisplay ? payment.checkNumberDisplay : payment.orderNumber ? payment.orderNumber : (payment.checkNumber ? payment.checkNumber : '')}</TableCell>
                                                            <TableCell>{payment.paidBy ? payment.paidBy : ''}</TableCell>
                                                            <TableCell>{payment.paymentMethod ? payment.paymentMethod.replace("creditCard", "Credit Card").replace("applePay", "Apple Pay").replace("googlePay", "Google Pay") : ''}</TableCell>
                                                            <TableCell align="right">{payment.checkAmount ? (payment.checkAmount/100).toLocaleString(localLang, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ''}</TableCell>
                                                            {restaurant.getVal('config.mobile.allowTips', false) && <TableCell align="right">{tipAmount ? (tipAmount/100).toLocaleString(localLang, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ''}</TableCell>}
                                                            <TableCell align="right">{payment.paymentAmount ? (payment.paymentAmount/100).toLocaleString(localLang, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ''}</TableCell>
                                                            <TableCell align="right">{payment.refundAmount ? (payment.refundAmount/100).toLocaleString(localLang, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ''}</TableCell>
                                                            {showVerified && <TableCell align="center">{payment.verification && payment.verification.verified && <DFlag checked={payment.verification && payment.verification.verified} />}</TableCell>}
                                                        </TableRow>
                                                    </>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                    {filteredPayments && showVerified &&
                                        <>
                                            <div  id={"verificationTotals"} selected={false} onClick={false} style={{ display: 'flex', justifyContent:'space-between', padding:12 }} className={"verificationRow"}>
                                                <span style={{fontWeight: 500, display:'flex', alignContent:'center'}} >Verified Count: {verifiedData.verifiedCount}</span>
                                                <span style={{fontWeight: 500, display:'flex', alignContent:'center'}} >Verified Paid: ${(verifiedData.verifiedAmountPaidTotal/100).toLocaleString(localLang, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</span>
                                                <span style={{fontWeight: 500, display:'flex', alignContent:'center'}} >Unverified Count: {verifiedData.unverifiedCount}</span>
                                                <span style={{fontWeight: 500, display:'flex', alignContent:'center'}} >Unverified Paid: ${(verifiedData.unverifiedAmountPaidTotal/100).toLocaleString(localLang, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</span>
                                            </div>
                                        </>
                                    }
                                </TableContainer>
                            }
                        </Paper>
                    </Grid>
                </Grid>

                {filteredPayments && filteredPayments.length > rowsPerPage && 
                    <Grid container item md={12} style={{ width: '100%', padding: 0, marginTop: -5}} justify='flex-end'>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            align='right'
                            component="div"
                            count={filteredPayments ? filteredPayments.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Grid>
                }

                <Grid item lg={12} >
                    {dialogContent}
                </Grid>
            </Grid>
        </Grid>
	);
};
