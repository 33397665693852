import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { urlParse } from './utils/urlParser'
import Layout from './layouts/Layout.js';
import Login from './admin/pages/login';
import { Typography } from '@material-ui/core';
import accessUtils from './utils/accessUtils';

export default ({ component: Component, permission: permission, topNav: topNav = true, requireToken: requireToken = true, showHeader: showHeader = true, showTopNav: showTopNav = true, showFooter: showFooter = true, ...rest }) => {
    let signedIn = accessUtils.isLoggedIn()
    let params = urlParse(window.location.href).params
    let restaurantId = params.r
    return (
        <Route
        {...rest}
        render={(props) => {
            return (requireToken || signedIn ) ? ( //|| !restaurantId
                signedIn ? (
                    ((permission && global.hasPermission(permission))) || !permission ?
                        <Layout topNav={topNav} showHeader={showHeader} showTopNav={showTopNav} showFooter={showFooter}>
                            <Component props={{...props, ...rest.props}} />
                        </Layout>
                    :
                        <Layout topNav={topNav} showHeader={showHeader} showTopNav={showTopNav} showFooter={showFooter}>
                            <br/><br/><br/>
                            <center>
                                <Typography variant='h4'>
                                    You do not have access to this page.
                                </Typography>
                            </center>
                        </Layout>
                ) : (
                    <Layout>
                        <Login path="/login" exact component={Login} props={props} />
                    </Layout>
                )
            ) : (
                <Layout topNav={false} showHeader={showHeader} showTopNav={showTopNav} showFooter={showFooter}>
                    <Component requireToken={false} props={{ ...props, ...rest.props }} />
                </Layout>
            )}
        }
        />
    )
}