import { Button, Container, Paper } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Sound from 'react-sound';
import DFlag from '../components/dash/DFlag'



export default (props) => {
    const [soundPlaying, setSoundPlaying] = useState(false)
    const [soundPosition, setSoundPosition] = useState(0)

    const handlePlaying = (e) => {
        setSoundPosition(e.position)
    }
    let canPlayAudio = document.createElement('audio').canPlayType('audio/mp3')

	return (
        <div style={{padding:10, height:'100%', width:'auto'}}>
            <Paper style={{padding:'15px'}}>
                <Container>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <span style={{fontSize:'20px',}} > Sound Test</span>
                        <div style={{display:'flex', alignContent:'center', padding:'10px 0px'}}>
                            <span style={{paddingRight:10}} > Sound is {!canPlayAudio ? 'not': ''} supported in your browser</span>
                            <DFlag checked={!!canPlayAudio} />
                        </div>
                        <Sound url='/Classic-doorbell-sound.mp3' ignoreMobileRestrictions={true} position={soundPosition} onPlaying={handlePlaying} playStatus={soundPlaying ? Sound.status.PLAYING : Sound.status.STOPPED} onFinishedPlaying={() => {setSoundPosition(0)} } />
                        <Button variant="contained" disabled={!canPlayAudio} onClick={() => setSoundPlaying(!soundPlaying)}>{soundPlaying? 'Stop Playing' : 'Play Sound'}</Button>
                    </div>
                </Container>
            </Paper>
        </div>
	);
};