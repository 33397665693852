import React, { useState, useEffect } from 'react';
import { useGlobal, setGlobal } from 'reactn';
import log from 'loglevel';
import _ from 'lodash';

const uuidv4 = require('uuid/v4');

export default function (props) {
	let [drecordId, setDrecordId] = useState(uuidv4())
	let [validateNow] = global.get("validateNow")
	let [dform] = global.get('dform');
	let [hasChanges, setHasChanges] = global.get('hasChanges');
	let [allRecords] = global.get('allRecords');
	
	// log.info("RENDERING DRECORD: " + (props.name ? props.name : '') + " > " + drecordId)

	useEffect(() => {
		// log.info("USE EFFECT DRECORD: " +  props.name)
		if (props.record) {
			if (!props.record.dform) {
				props.record.dform = {}
			}
			props.record.dform.hidden = false
			return () => {
				// log.info("USE EFFECT DRECORD IN RETURN: " +  props.name)
				if(props?.record && props?.record?.dform) {
					props.record.dform.hidden = true
				}
			}
		}
	}, [])

	if (props.record && (!props.record.dform || !props.record.dform.drecordId)) {
		if (!props.record.dform) {
			props.record.dform = {}
		}
		props.record.dform.afterSetVal = props.afterSetVal
		props.record.dform.setRecord = props.setRecord
		props.record.dform.name = props.name
		props.record.dform.errors = {}
		props.record.dform.requireds = {}
		props.record.dform.hidden = false
		props.record.dform.readyToValidate = false
		props.record.dform.dformId = dform.dformId
		props.record.dform.drecordId = drecordId
		props.record.dform.validate = dform.validate
		// log.info("GENERATING drecordId FOR RECORD: " + drecordId)
		// log.info(JSON.stringify(props.record))
		// log.info("")
	}

	if (props.record && props.record.dform) {
		props.record.dform.drecordId = drecordId
		props.record.dform.dformId = dform && dform.dformId ? dform.dformId : null
	}

	if (dform) {
		try {
			let recs = dform.records[dform.dformId] ? dform.records[dform.dformId] : {}
			// log.info("dform.dformId:    " + dform.dformId)
			// log.info("dform.lastFormId: " + dform.lastFormId)
			if (dform.dformId != dform.lastFormId) {
				// log.info("NEW FORM FOUND")
				// recs = {}
			}
			// log.info("recs: " + JSON.stringify(recs))
			recs[props.record.dform.drecordId] = props.record
			dform.records[dform.dformId] = recs
			dform.lastRecord = props.record
			// log.info("SETTING LAST RECORD: " + props.record.dform.drecordId)
			dform.lastFormId = dform.dformId
			allRecords = recs
		} catch (err) {
			log.error(err)
		}
	}
	


	let setDVal = (setRecord, name, value, rerender) => { 

        if(name && (name !== undefined)){
			let origVal = _.get(props.record, name)
			if (origVal != undefined && origVal != value) {
				setHasChanges(true)
			}
			_.set(props.record, name, value);
            // log.info("SETTING VALUE: " + props.record.dform.drecordId + " > " + name + " = " + value)
            if (props.record.dform.setRecord) {
                props.record.dform.setRecord({...props.record})
            } else if (rerender) {
                global.set({
                    // allRecords: {...allRecords}
                    dform: {...global.dform}  //VERY IMPORTANT - DO NOT REMOVE - THIS IS THE MAGIC THAT MAKES ALL FIELDS UPDATE
                })
            } else {
                setRecord({...props.record})
            }
    
            if (props.record.dform.afterSetVal) {
                props.record.dform.afterSetVal({name, value})
            }
            return props.record
        }
	}
	if (props.record) {
		props.record.dform.setDVal = setDVal
	}

	return (
		<>
			{props.children}
		</>
	)
}




class Stack{
	constructor(... args ){
	  this.store = [... args.reverse()];
	  this.count = 0
	}
	peek() {
		let res = this.store[0]
		// log.info("IN PEEK: " + res ? res.drecordId : '')
	  	return res;
	}
	push(value) {
		this.count++
		let val = this.store.unshift(value)
		// log.info("COUNT: " + this.count)
	  	return val;
	}
	pop() {
	 return this.store.shift();
	}
	get(record) {
		if (record) {
			let rec = _.find(this.store, {drecordId: record.dform.drecordId})
			return rec;
		} else {
			return null;
		}
	}
}