import log from 'loglevel';
import dashFetch from '../utils/dashFetch';
import moment from 'moment'


export default async function (debug) {
	return new Promise( async(resolve, reject) => {
		log.info('downloadPartnerResiduals api');
        const url = `${global.dashServerUrl}/dashboard/api/v1/billing/downloadPartnerResiduals`; 
		let options = {
			method: 'POST',
			headers: {
				Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'x-access-token': localStorage.token,
			},
		}

		try {
			let response = await fetch(url, options)
			let blob = await response.blob()
			let objectUrl = window.URL.createObjectURL(blob);
			
			let dateText = moment().subtract(1, 'month').startOf('month').format('MMMM YYYY')
			let fileName = `Partner Residuals - ${dateText}.xlsx`
			
			let a = document.createElement('a');
			a.href = objectUrl;
			a.download = fileName
			document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
			a.click();
			a.remove();  //afterwards we remove the element again         
			return resolve(true)
		} catch (e) {
			log.info(e)
		}
		resolve(false)
	})
}