import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import createAccountApi from '../../actions/createAccountApi'
import sendResetCodeApi from '../../actions/sendResetCodeApi'
import resetPasswordApi from '../../actions/resetPasswordApi'
import setNewPasswordApi from '../../actions/setNewPasswordApi'
import loginApi from '../../actions/loginApi'
import checkAccountSetup from '../../actions/checkAccountSetup'
import log from 'loglevel'
import { urlParse } from '../../utils/urlParser'
import DashIcon from '../../assets/svgs/dashIcon';
import {ReactComponent as CurblyLogo} from '../../assets/svgs/curbly-logo.svg';
import {ReactComponent as HeartlandLogo} from '../../assets/svgs/Heartland-TextPay-logo.svg'//todo move to images// sorry goin fast
import shift4Logo from '../../assets/images/shift4-logo.png'
import TextPayLogoMobile from '../../assets/svgs/textpay-logo-mobile.js';
import accessUtils from '../../utils/accessUtils';
import ReactGA from 'react-ga';
import { LinearProgress, useMediaQuery } from '@material-ui/core';

const styles = (theme) => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	paper: {
		marginTop: "80",
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	paperWrapper: {
		paddingTop: "80px",
		paddingBottom: "80px",
		// display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		height: '100%'
	},
	avatar: {
		margin: "10",
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: "10",
	},
	submit: {
		margin: "30, 0, 20",
	},
});

const Login = (props) => {

	const { classes } = props;

	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState("");
	const [isAccountSetup, setIsAccountSetup] = useState(false);
	const [email, setEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [phoneNumberFormatted, setPhoneNumberFormatted] = useState("");
	const [fullName, setFullName] = useState("");
	const [password1, setPassword1] = useState("");
	const [resetCode, setResetCode] = useState("");
	const [message, setMessage] = useState("")
	const [account, setAccount] = useState("");
	const [buttonText, setButtonText] = useState("");
	const [buttonAction, setButtonAction] = useState('login');
	const [signUp, setSignUp] = useState(false);
	let signedIn = accessUtils.isLoggedIn()
	const GA_SIGNUP = "Curbly Signup";
	const isMobile = useMediaQuery('(max-width:600px)')


	const [show, setShow] = useState({
		email: false,
		password: false,
		confirmPassword: false,
		resetCode: false,
		phoneNumber: false,
		fullName: false,
		forgotPassword: false,
	});

	useEffect(() => {
		fetchData()
	}, [signedIn])

	let params = urlParse(window.location.href).params
	const fetchData = async () => {
		if (!signedIn) {
			showLogin()
			let accountIdIn = params.a
			if (accountIdIn) {
				let isAccountSetup = await checkAccountSetup(accountIdIn)
				if (!(isAccountSetup)) {
					localStorage.accountIdIn = accountIdIn
					let emailIn = decodeURIComponent(params.e)
					let showEmail = true
					if (emailIn) {
						setEmail(emailIn)
						showEmail = false
					}
					showSetPassword(showEmail)
					setIsAccountSetup(isAccountSetup)
				} else {
					document.location = '/'
				}
			}
		} else {
			document.location = '/'
		}
	}

	const signup = async () => {
		console.log("SUBMITTING AUTH TOKEN ")
		// await createAccount(email, phoneNumber, )
	}

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleSubmit()
		}
	}



	const hasErrors = () => {
		// log.info(' email ' + !errorEmail(email))
		// log.info(' password ' + !errorPassword(password))
		// log.info(' fullName ' + !errorFullname(fullName))
		// log.info(' phoneNumber ' + !errorPhoneNumber(phoneNumber))
		// log.info(' password1 ' + !errorConfirmPassword(password1))
		return !(!errorEmail(email) && !errorPassword(password) && !errorFullname(fullName) && !errorPhoneNumber(phoneNumber) && !errorConfirmPassword(password1))
	}

	const errorEmail = (email) => {
		const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return email ? !regexp.test(email) : false
	}

	const errorPassword = (password) => {
		return password && show.confirmPassword ? password.length < 8 : false
	}

	const errorConfirmPassword = (password1) => {
		return password && password1 ? password != password1 : false
	}

	const errorResetCode = (phoneNumber) => {
		return resetCode ? resetCode.length < 6 : false
	}

	const errorPhoneNumber = (phoneNumber) => {
		return phoneNumber ? phoneNumber.length < 10 : false

	}
	const errorFullname = (fullName) => {
		return fullName ? !fullName.split(" ")[1] : false
	}


	const handleSetPhoneNumber = (phoneNumberStr) => {
		// remove non numeric values from string & set state
		const newPhoneNumber = phoneNumberStr.replace(/\D/g, '');
		if (newPhoneNumber !== phoneNumber) {
			setPhoneNumber(newPhoneNumber);
			setPhoneNumberFormatted(formatPhoneNumber(newPhoneNumber));
		}
	}

	const formatPhoneNumber = (phoneNumberStr) => {
		if (phoneNumberStr.length < 4) {
			phoneNumberStr = phoneNumberStr.replace(/(\d{1,3})/g, "($1");
		} else if (phoneNumberStr.length < 7) {
			phoneNumberStr = phoneNumberStr.replace(/(\d{3})(\d{1,3})/g, "($1) $2");
		} else if (phoneNumberStr.length <= 10) {
			phoneNumberStr = phoneNumberStr.replace(/(\d{3})(\d{3})(\d{1,4})/g, "($1) $2-$3");
		}
		return phoneNumberStr;
	}

	const login = async () => {
		setLoading(true)
		let success = await loginApi(email, password)
		if (!success) {
			setMessage('Email or password was incorrect')
		} else {
			accessUtils.login()
		}
		setTimeout(() => {
			setLoading(false)	
		}, 1000);
	}
	const createAccount = async () => {
		// console.log("CREATING ACCOUNT ")
		// let success = await createAccountApi(email, phoneNumber, null, false) //TODO accType
		// if (!success) {
		// 	setMessage('Email or password was incorrect')
		// } else {
		// 	accessUtils.login()
		// }
	}

	const resetPassword = async () => {
		console.log("SENDING RESET CODE ")
		let success = await resetPasswordApi(email, password, resetCode)
		if (!success) {
			setMessage('reset code was incorrect')
		} else {
			document.location = '/'
		}
	}

	const setNewPassword = async () => {
		console.log("SETTING NEW PASSWORD ")
		if (params.e) { //curbly signup
			ReactGA.event({
				category: GA_SIGNUP,
				action: "New User Set Password"
			});
		}
		let success = await setNewPasswordApi(email, password)
		if (!success) {
			setMessage('Something went wrong, please try again')
		} else {
			accessUtils.login()
		}
	}

	const sendResetCode = async () => {
		console.log("SENDING RESET CODE ")

		let success = await sendResetCodeApi(email)
		if (!success) {
			setMessage('Email was incorrect')
		} else {
			showEnterResetCode()
		}
	}



	//ALL THE SHOW HIDE DEPENDING ON PAGE TYPE
	const showLogin = () => {
		setShow({
			email: true,
			password: true,
			confirmPassword: false,
			resetCode: false,
			phoneNumber: false,
			fullName: false,
			forgotPassword: true,
		})
		setButtonText('Log In')
		setButtonAction('login')
	}

	const showResetPassword = () => {
		setShow({
			email: true,
			password: false,
			confirmPassword: false,
			resetCode: false,
			phoneNumber: false,
			fullName: false,
			forgotPassword: false,
		})
		setButtonText('Send Reset Code')
		setButtonAction('sendResetCode')
	}

	const showEnterResetCode = () => {
		setShow({
			email: false,
			password: true,
			confirmPassword: true,
			resetCode: true,
			phoneNumber: false,
			fullName: false,
			forgotPassword: false,
		})
		setMessage(`Check ${email} for the reset code`)
		setButtonText('Log In')
		setButtonAction('resetPassword')
	}

	const showSetPassword = (showEmail) => {
		setShow({
			email: showEmail,
			password: true,
			confirmPassword: true,
			resetCode: false,
			phoneNumber: false,
			fullName: false,
			forgotPassword: false,
		})
		setMessage('Please choose a password to finish setting up your account')
		setButtonText('Save Password')
		setButtonAction('setNewPassword')
	}

	const showCreateAccount = () => {
		setShow({
			email: true,
			password: true,
			confirmPassword: true,
			resetCode: false,
			phoneNumber: true,
			fullName: true,
			forgotPassword: false,
		})
		setButtonText('Create Account')
		setButtonAction('createAccount')
	}

	const handleSubmit = () => {
		if (!hasErrors()) {
			switch (buttonAction) {
				case "login": login(); break;
				case "createAccount": createAccount(); break;
				case "sendResetCode": sendResetCode(); break;
				case "setNewPassword": setNewPassword(); break;
				case "resetPassword": resetPassword(); break;
			}
		}
	}


	return (
		<>
			{loading && <LinearProgress /> }
			{!loading && 
				<Paper className={classes.paperWrapper} component="main" maxwidth="xs">
					<CssBaseline />
					<div className={classes.paper}>
						<br />
						{global.branding == 'dashnow' && window.location.href.indexOf('dashnow') > -1 &&
							<DashIcon width={75} height={75} viewBox={'0, 0, 34, 34'} />
						}
						{(global.isCurbly() || global.branding == 'curbly') && 
							<CurblyLogo style={{height: '72px', width: '186px'}} />
						}
						{window.location.href.indexOf('textpay') > -1 && <>
							{/* {global.branding == 'dashnow' && <> */}
								<TextPayLogoMobile style={{ marginTop: 3, height:'auto'}} />
							{/* </>} */}
							{/* {global.branding == 'heartland' && <HeartlandLogo style={{height:'auto'}}/>} */}
							{global.branding == 'shift4' && <>
								{/* <img wdith="100%" height="100%" src={shift4Logo} alt="logo" /> TODO: get shift 4 combined logo */}
							</>}
							<Typography variant='h5' style={{ fontSize: '24px', marginLeft: 8}}>text<span style={{ color: `var(--${global.branding})` }}>pay</span></Typography>
						</>}
						{/* <Avatar className={classes.avatar}>
					<LockOutlinedIcon />
					</Avatar> */}
						<br />
						<Typography component="h1" variant="h5">
							{signUp && 'Sign Up'}
							{!signUp && isAccountSetup && 'Login'}
						</Typography>
						{message &&
							<Grid item lg={12} sm={8}>
								<br />
								<Box p={3}>
									{message}
								</Box>
							</Grid>
						}
						<Grid container>
							<Grid item xs={1} md={4}></Grid>
							<Grid item xs={10} md={4}>
								<form
									className={classes.form}
									onKeyPress={handleKeyPress}
								>
									<Grid item xs={12} alignItems="center" justify="center" container>
										{show.email &&
											<TextField
												InputLabelProps={{shrink: true}}
												variant="outlined"
												margin="normal"
												required
												fullWidth
												error={errorEmail(email)}
												helperText={errorEmail(email) ? "Must be a valid email" : ""}
												id="email"
												label="Email"
												name="email"
												onChange={(event) => setEmail(event.target.value ? event.target.value.toLowerCase() : "")}
												autoFocus
												value={email}
											/>
										}{show.phoneNumber &&
											<TextField
												InputLabelProps={{shrink: true}}
												onChange={(event) => { handleSetPhoneNumber(event.target.value); }}
												value={phoneNumberFormatted}
												type="tel"
												pattern="[0-9]*"
												margin="normal"
												error={errorPhoneNumber(phoneNumber)}
												helperText={errorPhoneNumber(phoneNumber) ? "Must be valid phone number" : ""}
												label="Phone Number"
												name="phoneNumber"
												inputProps={{ maxLength: 14 }}
											/>
										}{show.fullName &&
											<TextField
												InputLabelProps={{shrink: true}}
												variant="outlined"
												margin="normal"
												required
												fullWidth
												error={errorFullname(fullName)}
												helperText={errorFullname(fullName) ? "Must enter First and Last Name" : ""}
												name="fullName"
												label="Full Name"
												type="Full Name"
												id="Full Name"
												autoComplete="current-fullName"
												onChange={(event) => setFullName(event.target.value)}
											/>
										}{show.password &&
											<TextField
												InputLabelProps={{shrink: true}}
												variant="outlined"
												margin="normal"
												required
												fullWidth
												error={errorPassword(password)}
												helperText={errorPassword(password) ? "Password must be at least 8 characters" : ''}
												name="password"
												label="Password"
												type="password"
												id="password"
												autoComplete="current-password"
												onChange={(event) => setPassword(event.target.value)}
											/>
										}{show.confirmPassword &&
											<TextField
												InputLabelProps={{shrink: true}}
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="confirmPassword"
												error={errorConfirmPassword(password1)}
												helperText={errorConfirmPassword(password1) ? "Passwords do not match" : ""}
												label="Confirm Password"
												type="password"
												id="password"
												autoComplete="current-password1"
												onChange={(event) => setPassword1(event.target.value)}
											/>
										}{show.resetCode &&
											<TextField
												InputLabelProps={{shrink: true}}
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="resetCode"
												error={errorResetCode(resetCode)}
												helperText={errorResetCode(resetCode) ? "Reset code not valid" : ""}
												label="Reset Code"
												type="redet-code"
												id="redet-code"
												autoComplete="current-reset-code"
												onChange={(event) => setResetCode(event.target.value)}
											/>
										}
										<Button
											disabled={hasErrors()}
											variant="contained"
											color="primary"
											className={classes.submit}
											onClick={handleSubmit}
											style={{marginTop: 10}}
											size="large"
										>
											{buttonText}
										</Button>
										<Grid container>
											{show.forgotPassword &&
												<Grid item xs style={{marginTop: 25}} container justify="center">
													<Typography style={{ textDecoration: 'underline', paddingTop: '.3em' }} href="#" onClick={showResetPassword} variant="body2">
														Forgot password?
													</Typography>
												</Grid>
											}
											<Grid item>
												{/* <Typography style={{textDecoration: 'underline'}} href="#" onClick={(e) => {setSignUp(!signUp)}} variant="body2">
													{ !signUp && "Don't have an account? Sign Up"}
													{ signUp && "Already have an account? Log In"}
												</Typography> */}
											</Grid>
										</Grid>
									</Grid>
								</form>	
							</Grid>
						</Grid>
					</div>
				</Paper>
			}
		</>
	);
}
export default withStyles(styles, { withTheme: true })(Login);