import log from 'loglevel';
import dashFetch from '../utils/dashFetch';


export default async function (order, termDate, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('termOrder api');
		const url = `${global.dashServerUrl}/dashboard/api/v1/billing/termOrder`;

		const body = {
            order,
            termDate
		};

		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info('>>> termOrder JSON');
			log.info(json)
			if (json.success) {
				resolve(json.data.order);
			} else {
				resolve(false)
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}