import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import { Grid, Dialog, DialogActions, Button, Typography, Checkbox, Input, MenuItem, RadioGroup, FormControlLabel, Radio, TextField, DialogContent } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import SelectMui from '@material-ui/core/Select';
import upsertProductApi from '../../actions/upsertProductApi';
import DialogTitle from '../../dashboard/components/DialogTitle';

import log from 'loglevel'

const styles = {
	infoBanner: {
		padding: 5,
		paddingTop: 15
	},
	paper: {
		padding: 10,
		width: '100%',
		height: '100%'
	},
	table: {
	}
}

const unitTypes = {
	location: "Location",
	transaction: "Transaction",
	sms: "SMS",
}


export default function (props) {
	// alert(JSON.stringify(props.permissions))
	const [record, setRecord] = useState(props.record)
	let [products] = global.get('products')
	let [smsTemplateTypes] = global.get('smsTemplateTypes')
	

	const upsertRecord = async (record) => {
		try {
			record = await upsertProductApi(record)
			let idx = _.findIndex(products, {_id: record._id})
			if (idx > -1) {
				products[idx] = record
			} else {
				products.push(record)
			}
			global.set({products: products})
			props.close()
		} catch (err) {
			log.error(err)
		}
	}


	return (
		<Dialog
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			maxWidth={'md'}
			open={true}
			style={{ margin: 0, height: '100%', width: '100%'}}
			onClose={ props.close }
		>
			{record && 
				<>
				<DialogTitle onClose={ props.close} id="customized-dialog-title">
					NEW PRODUCT
				</DialogTitle>
				<DialogContent dividers>
					<Grid container>
						<Grid item lg={12} style={{ padding: 20 }} container spacing={2}>
							<Grid item lg={6}>
								<Typography>Name</Typography>
								<Input
									style={{ width: '100%' }}
									value={record.name}
									onChange={(e) => setRecord({ ...record, name: e.target.value })}
								/>
							</Grid>
							
							<Grid item lg={6}>
								<Typography>Product Type</Typography>
								<SelectMui
									label="Product Type"
									value={record.type}
									style={{ width: '100%' }}
									onChange={(e) => setRecord({ ...record, type: e.target.value })}
								>
									{/* software|discount|merch|promotion|labor|support|processing */}
									<MenuItem value={"software"}>{"Software"}</MenuItem>
									<MenuItem value={"discount"}>{"Discount"}</MenuItem>
									<MenuItem value={"merchandise"}>{"Merchandise"}</MenuItem>
									<MenuItem value={"promotion"}>{"Promotion"}</MenuItem>
									<MenuItem value={"labor"}>{"Labor"}</MenuItem>
									<MenuItem value={"support"}>{"Support"}</MenuItem>
									<MenuItem value={"processing"}>{"Processing"}</MenuItem>
								</SelectMui>
							</Grid>

							<Grid item lg={6}>
								<Typography>Invoice Label</Typography>
								<Input
									style={{ width: '100%' }}
									value={record.nameOnInvoice}
									onChange={(e) => setRecord({ ...record, nameOnInvoice: e.target.value })}
								/>
							</Grid>

							<Grid item lg={6}>
								<Typography>Description</Typography>
								<Input
									style={{ width: '100%' }}
									value={record.description}
									onChange={(e) => setRecord({ ...record, description: e.target.value })}
								/>
							</Grid>

							<Grid item lg={6}>
								<Typography>Price Type</Typography>
								<RadioGroup row
									value={record.priceType}
									onChange={(e) => setRecord({ ...record, priceType: e.target.value })}
								>
									<FormControlLabel value={'amount'} labelPlacement="end" label={'Amount'} control={<Radio color="primary" />} />
									<FormControlLabel value={'percent'} labelPlacement="end" label={'Percent'} control={<Radio color="primary" />} />
								</RadioGroup>
							</Grid>

							<Grid item lg={3}>
								<Typography>Taxable</Typography>
								<Checkbox
									color="primary"
									checked={record.taxable}
									onChange={(e) => setRecord({ ...record, taxable: e.target.checked })}
								>
								</Checkbox>
							</Grid>

							<Grid item lg={3}>
								<Typography>Waived</Typography>
								<Checkbox
									color="primary"
									checked={record.waived}
									onChange={(e) => setRecord({ ...record, waived: e.target.checked })}
								>
								</Checkbox>
							</Grid>

							<Grid item lg={2} style={{paddingRight: 10}}>
								<Typography>Price</Typography>
								<Input
									startAdornment={(!record.priceType || record.priceType == "amount") && <InputAdornment position="start">$</InputAdornment>}
									endAdornment={(record.priceType == "percent") && <InputAdornment position="end">%</InputAdornment>}
									style={{ width: '100%' }}
									value={record.unitPrice}
									onChange={(e) => setRecord({ ...record, unitPrice: e.target.value })}
								/>
							</Grid>

							<Grid item lg={record.unitType == 'sms' ? 2 : 4}>
								<Typography>Per</Typography>
								<SelectMui
									value={record.unitType}
									style={{ width: '100%' }}
									onChange={(e) => setRecord({ ...record, unitType: e.target.value })}
								>
									{/* location | transaction | sms */}
									{Object.keys(unitTypes).map(unitType => {
										return (
											<MenuItem value={unitType}>{unitTypes[unitType]}</MenuItem>
										)
									})}
								</SelectMui>
							</Grid>

							{record.unitType == 'sms' && 
									<Grid item lg={2}>
									<Typography>SMS Type</Typography>
									<SelectMui
										value={record.smsTemplate}
										style={{ width: '100%' }}
										onChange={(e) => setRecord({ ...record, smsTemplate: e.target.value })}
									>
										{/* software|discount|merch|promotion|labor|support */}
										{smsTemplateTypes.map(smsTemplateType => {
											return (
												<MenuItem value={smsTemplateType._id}>{smsTemplateType.name}</MenuItem>
											)
										})}
									</SelectMui>
								</Grid>
							}

							<Grid container item lg={6} justify="space-between" alignItems="flex-end">
								{record.priceType == "amount" && <>
									<Grid item lg={3} style={{paddingRight: 10}}>
										<Typography>Min</Typography>
										<Input
											style={{ width: '100%' }}
											value={record.billMinUsageCount}
											onChange={(e) => setRecord({ ...record, billMinUsageCount: e.target.value })}
										/>
									</Grid>
									<Grid item lg={3} style={{paddingRight: 10}}>
										<Typography>Max</Typography>
										<Input
											style={{ width: '100%' }}
											value={record.billMaxUsageCount}
											onChange={(e) => setRecord({ ...record, billMaxUsageCount: e.target.value })}
										/>
									</Grid>
									<Grid item lg={6} style={{paddingRight: 10}}>
										<Typography>&nbsp;</Typography>
										<Typography>{unitTypes[record.unitType] ? unitTypes[record.unitType] + "s" : ''}</Typography>
									</Grid>
								</>}
							</Grid>

							<Grid item lg={3}>
								<Typography>Billing Frequency</Typography>
								<SelectMui
									value={record.billingFrequency}
									style={{ width: '100%' }}
									onChange={(e) => {
										let newProps = {billingFrequency: e.target.value}
										if (e.target.value !== "monthly") {
											newProps.mininumPrice = 0
										}
										setRecord({ ...record, ...newProps });
									}}
								>
									{/* software|discount|merch|promotion|labor|support */}
									<MenuItem value={"oneTime"}>{"One Time"}</MenuItem>
									<MenuItem value={"monthly"}>{"Monthly"}</MenuItem>
								</SelectMui>
							</Grid>

							{record.billingFrequency == "monthly" &&
								<Grid item lg={3}>
									<Typography>Minimum Total Price</Typography>
									<Input
										startAdornment={<InputAdornment position="start">$</InputAdornment>}
										style={{ width: '100%' }}
										value={record.mininumPrice}
										onChange={(e) => setRecord({ ...record, mininumPrice: e.target.value })}
									/>
								</Grid>
							}
						</Grid>
					</Grid>
				</DialogContent>
			</>}
			<DialogActions>
				<Button onClick={props.close}>
					Close
				</Button>
				<Button onClick={() => {upsertRecord(record);}}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	)

}
