import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { useMediaQuery } from '@material-ui/core';
import theme from '../../theme';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    flex: 1,
  },
  iconButton: {
    paddingRight: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function CheckInSearch(props) {
  let smallScreen = useMediaQuery(theme().breakpoints.down('sm'));
  const classes = useStyles();
//   let [value, setValue] = useState('')

  return (
    <Grid className={classes.root}>
      <IconButton className={classes.iconButton} aria-label="menu">
        <SearchIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder={smallScreen ? 'Search' : 'Search by name, number, or party size'}
        inputProps={{ 'aria-label': 'Search by name, number, or party size' }}
        value={props.value ? props.value : ''}
        onChange={e => { if (props.onChange) { props.onChange(e, e.target.value)};  }}
        pattern="[a-zA-Z0-9\s]*"
      />
      {props.value && 
        <>
          <IconButton color="primary" className={classes.iconButton} aria-label="directions">
              <ClearIcon onClick={e => { if (props.onChange) { props.onChange(e, '') };   }} />
          </IconButton>
        </>
      }
    </Grid>
  );
}
