import React, { useState, useEffect } from 'react';
import { Grid, Dialog, DialogActions, DialogContent, Button, Typography, Checkbox, Table, TableRow, TableCell, Tabs, Tab, TextField, TableHead, InputAdornment, CircularProgress, Popper, makeStyles, FormControlLabel, IconButton, FormControl } from '@material-ui/core';
import flagCheckAsCompletedApi from '../../actions/flagCheckAsCompletedApi';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '../../dashboard/components/DialogTitle'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import log from 'loglevel'
import moment from 'moment'
import theme from '../../theme'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import alertCustomerForOrderUpApi from '../../actions/alertCustomerForOrderUpApi';
import undoFlagCheckAsCompletedApi from '../../actions/undoFlagCheckAsCompletedApi';
import RefundRequestWindow from './RefundRequestWindow';
import accessUtils from '../../utils/accessUtils';
import CloseIcon from '@material-ui/icons/Close';
import createOrAddToOrderApi from '../../actions/createOrAddToOrderApi';
import SmsIcon from '@material-ui/icons/Sms';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SendIcon from '@material-ui/icons/Send';
import EmailIcon from '@material-ui/icons/Email';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CropFreeIcon from '@material-ui/icons/CropFree';
import {ReactComponent as QrCodeIcon} from '../../assets/svgs/QRIcon2.svg';
import { Sms, Person } from '@material-ui/icons';
import { set } from 'lodash';
import { Autocomplete, createFilterOptions, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';
import GetAppIcon from '@material-ui/icons/GetApp';
import upsertSiteCustomerApi from '../../actions/upsertSiteCustomerApi';
import uploadBulkInvoicesApi from '../../actions/uploadBulkInvoicesApi';
import PublishIcon from '@material-ui/icons/Publish';
import LinkIcon from '@material-ui/icons/Link';
import KeyboardIcon from '@material-ui/icons/Keyboard';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
const filter = createFilterOptions({
	ignoreCase: true,
	trim: true,
	stringify: (option) => option.phone + option.firstName +" "+ option.lastName + option.email + option.accountName
});

const styles = makeStyles(theme =>({
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
}))


export default function (props) {
	// alert(JSON.stringify(props.permissions))
	const [check, setCheck] = useState(props.check? props.check : {phone:'', itemsGrouped:[]})
	const [orderUpEnabled] = global.get('orderUpEnabled')
	const [restaurant] = global.get('restaurant')
	const [customers] = global.get('customers')
	const [textOnHoldEnabled] = global.get('textOnHoldEnabled')
	const [emailToPayEnabled] = global.get('emailToPayEnabled')
	const [showCustomerAccountName] = global.get('showCustomerAccountName')
	const [textToPayEnabled] = global.get('textToPayEnabled')
	const [orderTerminology] = global.get('orderTerminology')
	const [selectedCustomer, setSelectedCustomer] = useState(null)
	const [error, setError] = useState(false)
	const [date, setDate] = useState(moment())
	const [time, setTime] = useState(moment().add(1,'hour').hour())
	const [scheduledText, setScheduledText] = useState(false)
	const [loading, setLoading] = useState(false)
	const [uploading, setUploading] = useState(false)
	const [invoiceFile, setInvoiceFile] = useState(null)
	const [windowContent, setOverlayWindowContent] = useState()
	const [amountFormatted, setAmountFormatted] = useState("$0.00")
	let [data, setData] = React.useState({sendEmail:true, sendText:true})
	const [phoneNumberFormatted, setPhoneNumberFormatted] = React.useState(data.phone);
	let [selectedTab, setSelectedTab] = React.useState('sendNow')
	const [open, toggleOpen] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [dialogValue, setDialogValue] = useState({
		customerName: '',
		phone: '',
	});


	let paymentLinks = props.paymentLinks
	
	const handleClose = () => {
		setDialogValue({
			customerName: '',
			phone: '',
		});
		setPhoneNumberFormatted("")
		setError(false)
		toggleOpen(false);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		let badEmail = dialogValue.email ? errorEmail(dialogValue.email) : true
		let badPhone = (dialogValue.phone.length > 15 || dialogValue.phone.length < 10)
		if ((badEmail && badPhone) || !dialogValue.customerName || (showCustomerAccountName && !dialogValue.accountName)) {
			setError(true)
			global.showNotification("Please enter required fields", "error")
			return
		}
		let firstName
		let lastName
		if (dialogValue.customerName && dialogValue.customerName.split(' ').length > 0) {
			firstName = dialogValue.customerName.split(' ')[0]
			lastName = dialogValue.customerName.split(' ')[1] != undefined ? dialogValue.customerName.split(' ')[1] : ''
		}

		let customerToSave = {
			accountName: dialogValue.accountName ? dialogValue.accountName.toString() : '',
			firstName,
			lastName,
			phone: dialogValue.phone.toString(),
			email: dialogValue.email.toString(),
			deliveryPreferences: {
				text:!!dialogValue.phone,
				email:!!dialogValue.email
			},
            restaurant: restaurant._id
		}

        if (isEdit && dialogValue && dialogValue._id) customerToSave._id = dialogValue._id 

		// setData({...data, sendEmail:!!newCustomer.email, sendText:!!newCustomer.phone})
        let customersCopy = [...customers]

        const record = await upsertSiteCustomerApi(customerToSave)
        if (record && record._id) {
            
            if (isEdit) {
                customersCopy.replaceItem(customerToSave)
            } else { // new customer
                customerToSave._id = record._id // use id from newly created customer record
                customersCopy.push(customerToSave)
            }
    
            global.set({customers: customersCopy})
            setSelectedCustomer(customerToSave)
    
            setIsEdit(false)
            handleClose();

        } else {
            global.showNotification("An error occurred, please try again", "error")
        }
	};

	let tmpPayment
	let tipTotal = 0
	let loyaltyTotal = 0
	let taxesTotal = 0
	let dashTotal = 0
	let lastPaymentTime
	if (check && check.payments) {
		check.payments.filter(p => (p.fromDash && p.successful)).forEach(payment => {
			loyaltyTotal += payment.loyaltyRewardsAmount
			taxesTotal += payment.tax
			tipTotal += payment.tipAmount
			dashTotal += payment.paymentAmount
		})
		tmpPayment = check.payments.sort((a, b) => moment(a.createdAt) - moment(b.createdAt))[0]
		if (tmpPayment) {
			lastPaymentTime = tmpPayment.createdAt
		}
		loyaltyTotal = Number(loyaltyTotal/100).toFixed(2)
		taxesTotal = Number(taxesTotal/100).toFixed(2)
		tipTotal = Number(tipTotal/100).toFixed(2)
		dashTotal = Number(dashTotal/100).toFixed(2)
	}

	let paidAt = lastPaymentTime ? moment(lastPaymentTime).fromNow() : 'N/A'

	const smallScreen = useMediaQuery(theme().breakpoints.down('sm'));

	const addEditOrder = async () => {
		setLoading(true)
		if (!global.hasPermission('check.create')) {
			setError(true)
			global.showNotification("You do not have permission!", "error")
			setLoading(false)
			return
		}
		let resp
		if (selectedTab != 'upload') {
			if ((!paymentLinks && (!data.phone && !data.email)) || !data.amount) {
				setError(true)
				global.showNotification("Please enter required fields", "error")
				setLoading(false)
				return
			}

			let firstName = data.customerName && data.customerName.split(' ')[0] ? data.customerName.split(' ')[0]: ''
			let lastName = data.customerName && data.customerName.split(' ')[1] ? data.customerName.split(' ')[1]: ''
			let customer = {}
			let textOnHoldUntil
			if (!paymentLinks) {
				customer = {
					_id: selectedCustomer._id,
					accountName: selectedCustomer.accountName,
					phone: data.phone,
					email: data.email,
					firstName,
					lastName,
				}
	
		
				if ((selectedTab == 'schedule') && (date && time)) {
					textOnHoldUntil = moment(moment(date).startOf('day').add(time, 'hours')).tz(restaurant.utcTimeZone? restaurant.utcTimeZone: 'America/Chicago')
				}

			}
			resp = await createOrAddToOrderApi(customer, data.amount, restaurant._id, data.orderNumber, data.description, localStorage.accountId, textOnHoldUntil, data.attachment, data.sendEmail, data.sendText, paymentLinks)
		} else if (selectedTab == 'upload') {
			if (!invoiceFile) {
				setError(true)
				global.showNotification("Please attach a csv", "error")
				setLoading(false)
				return
			}
			resp = await uploadBulkInvoicesApi(invoiceFile, restaurant._id)
			setInvoiceFile(null)
			global.set({refreshCustomers: true})
			if (resp) {
				global.showNotification("All requests were uploaded successfully", "success")
			} else {
				global.showNotification("Some requests failed to upload successfully, check the downloaded .csv for detail", "info")
			}

		}
		clearForm()
		setLoading(false)

		if (resp && props.close) {
			props.close()
		}
	}

    const closeOverlayWindow = () => {
      setOverlayWindowContent(null);
    };


	const handleSetPhoneNumber = (phoneNumberStr) => {
		// remove non numeric values from string & set state
		const newPhoneNumber = phoneNumberStr.replace(/\D/g, '').substr(0, 15);
		if (newPhoneNumber !== data.phoneNumber) {
			// setData({...data, phone:newPhoneNumber});
			setDialogValue({ ...dialogValue, phone: newPhoneNumber })
			setPhoneNumberFormatted(global.formatPhoneNumber(newPhoneNumber));
		}
	}

	const uploadInvoices = async () => {
		setUploading(true)
		await uploadBulkInvoicesApi(invoiceFile, restaurant._id)
		setUploading(false)
	}

	const downloadBulkInvoicesTemplate = async () => {

		const url = `${global.dashServerUrl}/dashboard/api/v1/export/exportBulkInvoicesTemplate`;

		const body = {
		};

		let options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		}

		fetch(url, options, false)
		.then((res => res.blob()))
		.then(blob => {
			log.info('>>> JSON');
			if (blob) {
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement('a');
				a.href = url;
				a.download = `bulkInvoiceUploadTemplate.csv`;
				document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
				a.click();
				a.remove();  //afterwards we remove the element again         
				log.info("SUCCESS!")
			} else {
				log.info("FAILURE!")
			}
		}).catch((err) => {
			log.error(err)

		})
	}

	useEffect(() => {
		if (global.f(check, 'totals.total', false)) {
			setData({...data, amount:check.totals.total, orderNumber: check.orderNumber, description:check.description})
			setAmountFormatted('$' + global.formatMoney(check.totals.total))
			if (check.posLabel) {
				setSelectedCustomer(customers.find(c=> c.phone == check.posLabel))
			}
		}
	}, [check])
	
	useEffect(() => {
		if (selectedCustomer) {
			setData({...data, customerName: selectedCustomer.firstName + " " + selectedCustomer.lastName, phone:selectedCustomer.phone, email:selectedCustomer.email, sendEmail:selectedCustomer.getVal("deliveryPreferences.email", false), sendText:selectedCustomer.getVal("deliveryPreferences.text", false)})
		} else {
			setData({...data, customerName: "", phone:"", email: ""})
		}
	}, [selectedCustomer])

	useEffect(() => {
		clearForm()
	}, [restaurant])

	const clearForm = () => {
		setData({sendEmail:true, sendText:true, orderNumber:'', amount:'', description:''})
		setSelectedCustomer(null)
		setAmountFormatted('')
	}

	const handleSetAmount = (amountFormatted) => {
		// remove non numeric values from string & set state
		let amountSafe = amountFormatted.replace(/\D/g, '');
		if (amountSafe !== data.amount) {
			setData({...data, amount:amountSafe})
			setAmountFormatted('$' + global.formatMoney(amountSafe))
		}
	
	}
	

	const addItem = () => {
		let price = 0
		let item = {name:"", qty:1, price, basePrice: 0, friendlyPrice: '$' + price.toFixed(2)}
		let itemsGrouped = check.itemsGrouped? check.itemsGrouped : []
		itemsGrouped.push(item)
		setCheck({...check, itemsGrouped})
	}

	const removeItem = (item) => {
		setCheck({...check, itemsGrouped: check.itemsGrouped.removeItem(item)})
    }
    
    const customPopper = function (props) {
        const parent = document.querySelector('#customerField')
        const parentWidth = parent && parent.offsetWidth
        return (<Popper {...props} style={{zIndex:999999, width: smallScreen? '90%' : parentWidth? parentWidth : 400}}/>)
    }

	const errorEmail = (email) => {
		const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return email ? !regexp.test(email) : false
	}

    const clickEdit = () => {
        setIsEdit(true)
        setDialogValue({...selectedCustomer, customerName: selectedCustomer.firstName + ' ' + selectedCustomer.lastName})
        setPhoneNumberFormatted(global.formatPhoneNumber(selectedCustomer.phone))
        toggleOpen(true)
    }

	const renderButton = () => {
		return (
			<>
				{loading && 
					<Button style={{ margin: 5, padding: '10px 18px 10px 18px', width:!props.close?"100%":"inherit", height:60, alignSelf:'flex-end'}} color="primary" variant="contained" onClick={() => { }}>
						<CircularProgress color="white"/>
					</Button>
				}
				{!loading && <>
					<Button style={{ margin: 5, padding: '10px 18px 10px 18px', width:!props.close?"100%":"inherit", height:60, alignSelf:'flex-end'}} color="primary" variant="contained" onClick={() => { addEditOrder(); }}>
						{!paymentLinks ? <>
							{<SmsIcon style={{marginRight: 8}} />}
							<span style={{fontSize:18}}>{selectedTab =='sendNow' ? `SEND ` : selectedTab == 'schedule' ? 'SCHEDULE ' : 'Upload '}Request{selectedTab == 'upload' && '(s)'}</span>
						</>
						:
						<>
							{<LinkIcon style={{marginRight: 8}} />}
							<span style={{fontSize:18}}>Create Link</span>
						</>}
					</Button>
				</>}
			</>
		)
	}

	const renderDialogContent = () => {
		return (
			<Dialog
				style={smallScreen ? {position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 99999} : {}}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={true}
				onClose={ props.close }
				PaperProps={smallScreen ? {style:{maxHeight: 'inherit', maxWidth: 'inherit', margin: 0, width: '100%', height: '100%', borderRadius: 0}} : {style: {width: 450, minWidth: 450}}}
			>
				<DialogTitle onClose={ props.close } id="customized-dialog-title" style={{borderBottom: 'solid 1px #CCC'}}>
					{check && check.orderNumber ? `${orderTerminology} #` + check.orderNumber : paymentLinks ? "Create Payment Link" : "Request Payment"}
				</DialogTitle>
				<DialogContent style={{padding: 0, paddingBottom: 25}}>

					{renderFormContent()}
				</DialogContent>
				<DialogActions style={{borderTop: '1px solid var(--divider)', padding: '5px 10px' }}>
					<Grid container justify='center'>
						{props.close && renderButton()}
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}


    const renderCustomer = (option, isListItem = false) => {
        return (
            <Grid container alignContent="center" alignItems="center" style={{width:'100%'}}>
                {(option.phone || option.email) && <>
                    <Grid item style={{padding:8, paddingTop:6}}>
                        <Person />
                    </Grid>
                    <Grid item xs>
                        {isListItem && <>
                            {option.accountName && 
                                <Typography variant="body2" >
                                    {option.accountName}
                                </Typography>
                            }
                            {option.firstName && 
                                <Typography variant="body2" >
                                    {option.firstName + " " + option.lastName}
                                </Typography>
                            }
                            </>
                        }
                        {option.phone && 
                            <Typography variant="body2" color="textSecondary">
                                {global.formatPhoneNumber(option.phone)}
                            </Typography>
                        }
                        {option.email && 
                            <Typography variant="body2" color="textSecondary" style={{overflowWrap: 'anywhere'}}>
                                {option.email}
                            </Typography>
                        }
                    </Grid>
                </>}
                {!isListItem && 
                    <Grid item>
                        <IconButton style={{margin:'auto'}} onClick={()=>clickEdit()}>
                            <EditIcon />
                        </IconButton>
                    </Grid>
                }
            </Grid>
        );
    }

    const renderAddEditDialog = () => {
        return (
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" style={{ zIndex: 99999999999999999 }}>
                <form onSubmit={handleSubmit}>
                    <DialogTitle id="form-dialog-title">{(isEdit ? 'Edit' : 'Add a') + ' customer'}</DialogTitle> 
                    <DialogContent>
                        <div style={{ display: 'flex', flexDirection: 'column', width: 'auto' }}>
                            {showCustomerAccountName &&
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    required
                                    style={{ paddingRight: 8, width: smallScreen ? '100%' : 300 }}
                                    error={error && !dialogValue.accountName}
                                    value={dialogValue.accountName}
                                    onChange={(event) => setDialogValue({ ...dialogValue, accountName: event.target.value })}
                                    inputProps={{ maxLength: 50 }}
                                    label="Account"
                                    type="text"
                                />
                            }
                            <TextField
                                autoFocus={!showCustomerAccountName}
                                margin="dense"
                                id="name"
                                required
                                style={{ paddingRight: 8, width: smallScreen ? '100%' : 300 }}
                                error={error && !dialogValue.customerName}
                                value={dialogValue.customerName}
                                onChange={(event) => setDialogValue({ ...dialogValue, customerName: event.target.value })}
                                inputProps={{ maxLength: 50 }}
                                label="Customer Name"
                                type="text"
                            />
                            {textToPayEnabled &&
                                <TextField
                                    margin="dense"
                                    id="name"
                                    type="tel"
                                    style={{ paddingRight: 8, width: smallScreen ? '100%' : 300 }}
                                    pattern="[0-9]*"
                                    required={!emailToPayEnabled}
                                    error={error && (dialogValue.phone.length > 10 || dialogValue.phone.length < 10)}
                                    value={phoneNumberFormatted}
                                    onChange={(event) => handleSetPhoneNumber(event.target.value)}
                                    label="Phone Number"
                                />
                            }
                            {emailToPayEnabled &&
                                <TextField
                                    margin="dense"
                                    id="email"
                                    type="email"
                                    error={error && errorEmail(dialogValue.email)}
                                    style={{ paddingRight: 8, width: smallScreen ? '100%' : 300}}
                                    required={!textToPayEnabled}
                                    value={dialogValue.email}
                                    onChange={(event) => setDialogValue({ ...dialogValue, email: event.target.value })}
                                    inputProps={{ maxLength: 255 }}
                                    label="Email"
                                />
                            }
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
					    </Button>
                        <Button type="submit" color="primary">
                            {isEdit ? 'Save' : 'Add'}
						</Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
	const renderFormContent = () => {
		return (
			<Grid container justify="space-between">
				<div style={{width:'100%', padding:0}}>
					{!paymentLinks && <>
						<Grid item xs={12} md={12} xl={12} style={{width:'100%', padding:0}}>
							<Tabs style={{width: '100%', paddingTop:6, borderBottom: 'solid 1px #CCC'}}
								value={selectedTab}
								variant="fullWidth"
								indicatorColor="primary"
								textColor="primary"
								onChange={(e, value) =>{
									setSelectedTab(value)
								}}
							>
								<Tab label="Send" value="sendNow" icon={<SmsIcon />} style={{minWidth: 'inherit'}} />
								{textOnHoldEnabled && <Tab label="Schedule" value="schedule" icon={<ScheduleIcon />} style={{minWidth: 'inherit'}} />}
								{global.hasPermission('restaurant.uploadChecks') && <Tab label="Upload" value="upload" icon={<PublishIcon/>} style={{minWidth: 'inherit'}} />}
							</Tabs>
						</Grid>
						{selectedTab == 'upload' &&
							<Grid item xs={12} style={{padding:'0px 25px', marginBottom:15, display:'flex', alignItems:'center', justifyContent:'space-between', cursor:'pointer', width:'100%', backgroundColor:'var(--background)', borderBottom:'1px solid var(--divider)'}} onClick={downloadBulkInvoicesTemplate}>
								<span style={{textDecoration:'underline'}}>Download Payment Request Template</span>
								<IconButton>
									<GetAppIcon/>
								</IconButton>
							</Grid>
						}
					</>}
					<Grid container style={{padding:25, paddingTop: 0, paddingBottom:15, height:'min-content'}} justify='space-between'>
						{selectedTab != 'upload' && <>
							<Grid item xs={12} md={6} style={{paddingRight: smallScreen ? 0 : 20}}>
								<TextField
									// variant="outlined"
									fullWidth={true}
									placeholder=""
									error={error && (!data.amount || data.amount <=0)}
									onChange={(event) => {handleSetAmount(event.target.value)}}
									value={amountFormatted}
									type="tel"
									margin="normal"
									label="Amount"
									required
									// InputLabelProps={{shrink: true}}
									inputProps={{maxLength: 15}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									// variant="outlined"
									fullWidth={true}
									placeholder=""
									onChange={(event) => {setData({...data, orderNumber: event.target.value})}}
									value={data.orderNumber}
									margin="normal"
									type="text"
									error={error && (!data.orderNumber)}
									required
									label={!paymentLinks ? "Order #" : "Title"}
									// InputLabelProps={{shrink: true}}
									inputProps={{maxLength: 25}}
								/>
							</Grid>
							{!paymentLinks &&
								<Grid item xs={12} md={12}>
									<Autocomplete
										id="customerField"
										filterSelectedOptions
										// error={!selectedCustomer || !selectedCustomer.phone}
										value={selectedCustomer}
										getOptionSelected={(option, value) => (option.phone == value || option.email == value)}
										style={{paddingTop:16}}
										options={customers}
										freeSolo
										selectOnFocus
										clearOnBlur
										handleHomeEndKeys
										PopperComponent={customPopper} //keeps dropdown on top of modal
										filterOptions={(options, params) => {
											params.inputValue = params.inputValue.replace(/[()-]/g, '') 
											const filtered = filter(options, params);
									
											if (params.inputValue !== '' && !customers.find(c => c.phone == params.inputValue) && !customers.find(c => c.email == params.inputValue.toLowerCase()) && !customers.find(c => (c.firstName + " " + c.lastName).toLowerCase() == params.inputValue.toLowerCase()) && !customers.find(c => c.accountName == params.inputValue.toLowerCase()) ) {
												filtered.push({
													inputValue: params.inputValue,
													phone: `Add "${params.inputValue}"`,
												});
											}
									
											return filtered;
										}}
										renderOption={(option) => renderCustomer(option, true)}
										getOptionLabel={(option) => option.accountName ? option.getVal('accountName', "N/A") : option.firstName ? option.getVal('firstName', "N/A") + " " + option.getVal('lastName', "") : ''} //formatPhoneNumber(
										// getOptionValue ={(option) => option.phone ? option.phone : ''}
										renderInput={(params) => {
											return (
												// CUSTOMER
												<div style={selectedCustomer && {borderRadius:'4px', display:'flex', flexDirection:'column', justifyContent:"space-between", alignItems:"stretch", width:'100%', border:'solid 1px #e2e8f0', backgroundColor:'var(--white)', padding:8}}>
													<div>
														<TextField
															fullWidth={true}
															required
															type="text"
															// pattern="[0-9]*"
															error={error && (!selectedCustomer)}
															inputProps={{...params.inputProps, maxLength: 10, style:{zIndex:220}}}
															{...params}
															// inputProps={{maxLength: 10}}
															label={"Customer"} />
													</div>
													<div style={{marginTop:16, marginBottom:8}}>
														{selectedCustomer && renderCustomer(selectedCustomer)}
													</div>
												</div>
											)
										}}
										inputProps={{maxLength: 14}}
										onChange={(event, newValue) => {
											let phone
											let accountName
											let customerName
											let email
											if (typeof newValue === 'string') { // new customer entry
												// timeout to avoid instant validation of the dialog's form.
												phone = !isNaN(newValue.replace(/[()-]/g, '')) ? newValue.replace(/[()-]/g, '') : ""
												accountName = showCustomerAccountName && isNaN(newValue.replace(/[()-]/g, '')) && newValue.indexOf('@') == -1 ? newValue : ""
												customerName = !accountName && isNaN(newValue.replace(/[()-]/g, '')) && newValue.indexOf('@') == -1 ? newValue : ""
												email = emailToPayEnabled && isNaN(newValue.replace(/[()-]/g, '')) && newValue.indexOf('@') > -1 ? newValue : ''
												setTimeout(() => {
													toggleOpen(true);
													setError(false)
													setDialogValue({
														phone,
														accountName,
														customerName,
														email
													});
												});
											} else if (newValue && newValue.inputValue) { // new customer when one is already selectedCustomer
												phone = !isNaN(newValue.inputValue.replace(/[()-]/g, '')) ? newValue.inputValue.replace(/[()-]/g, '') : ""
												accountName = showCustomerAccountName && isNaN(newValue.inputValue.replace(/[()-]/g, '')) && newValue.inputValue.indexOf('@') == -1 ? newValue.inputValue : ""
												customerName = !accountName && isNaN(newValue.inputValue.replace(/[()-]/g, '')) && newValue.inputValue.indexOf('@') == -1 ? newValue.inputValue : ""
												email = emailToPayEnabled && isNaN(newValue.inputValue.replace(/[()-]/g, '')) && newValue.inputValue.indexOf('@') > -1 ? newValue.inputValue : ''
												toggleOpen(true);
												setError(false)
												setDialogValue({
													phone,
													accountName,
													customerName,
													email
												});
											} else {
												setSelectedCustomer(newValue)
											}
											if (phone) {
												setPhoneNumberFormatted(global.formatPhoneNumber(phone));
											}
										}}
										// onInputChange={(event, v) => {handleSetPhoneNumber(v)}}
									/>
									{renderAddEditDialog()}
								</Grid>
							}
							{/* {selectedTab == 'email' &&
								<Grid item xs={12} md={6} style={{paddingRight: smallScreen ? 0 : 20}}>
									<TextField
										// variant="outlined"
										fullWidth={true}
										placeholder=""
										onChange={(event) => {setData({...data, email: event.target.value})}}
										value={data.email}
										type="tel"
										pattern="[0-9]*"
										margin="normal"
										label="Customer Email"
										required
										inputProps={{maxLength: 30}}
									/>
								</Grid>
							} */}
							<Grid item xs={12}>
								<TextField
									// variant="outlined"
									fullWidth={true}
									placeholder=""
									onChange={(event) => {setData({...data, description: event.target.value})}}
									value={data.description}
									margin="normal"
									label="Description"
									// InputLabelProps={{shrink: true}}
									inputProps={{}}
									// rows={3}
									multiline
								/>
							</Grid>
						
							<Grid item xs={12}>
								<div style={{ borderBottom:'1px solid var(--light-gray)', display:'flex', alignItems:'center', width: '100%', cursor:'pointer', marginTop: '32px', marginBottom: '8px'}} onClick={() => document.querySelector('#invoice-attachment').click()}> {/* smallScreen? '100%' : '75%', */}
									<AttachFileIcon style={{marginBottom:2}}/>
									<span style={{marginLeft:2, width:'100%', fontSize:16, marginBottom:2}}>{data.getVal('attachment.name')? data.attachment.name.split(/(\\|\/)/g).pop() : 'Attach File'}</span>
									<input type='file' id="invoice-attachment" accept={'*'} style={{ display: 'none' }} 
										onChange={(e) => {
											if(e.target.files && e.target.files.length > 0){
												if(e.target.files[0].size <= (1024*1024*5) ){ //5MB
													setData({...data, attachment:(e.target.files[0])})
												} else {
													global.showNotification('The file size is too large, 5MB limit', 'error')
												}
											}
										}}
									/>
								</div>
								{/* <div style={{borderRadius:5, border:'1px solid var(--divider)', display:'flex', alignItems:'center', width: '100%', cursor:'pointer'}}>
									<Button variant="contained" color="secondary" onClick={() => document.querySelector('#invoice-attachment').click()} style={{borderTopRightRadius:0, borderBottomRightRadius:0}}><AttachFileIcon/></Button>
									<span style={{marginLeft:8, fontStyle:'italic', width:'100%'}} onClick={() => document.querySelector('#invoice-attachment').click()}>{data.getVal('attachment.name')? data.attachment.name.split(/(\\|\/)/g).pop() : 'Add attachment'}</span>
									<input type='file' id="invoice-attachment" accept={'*'} style={{ display: 'none' }} 
										onChange={(e) => {
											if(e.target.files && e.target.files.length > 0){
												if(e.target.files[0].size <= (1024*1024*5) ){ //5MB
													setData({...data, attachment:(e.target.files[0])})
												} else {
													global.showNotification('The file size is too large, 5MB limit', 'error')
												}
											}
										}}
									/>
								</div> */}
							</Grid>
						</>}
						
						{textOnHoldEnabled && selectedTab == 'schedule' &&<>
							{/* <Grid item xs={12} style={{paddingTop:10}}>
								<ToggleButtonGroup value={scheduledText} onChange={(e,v)=> setScheduledText(!!v)} style={{width:'100%'}} exclusive >
									<ToggleButton value={false} style={{width:'50%', backgroundColor:!scheduledText?'var(--light-gray)': 'var(--white)'}}>
										<Typography style={{display:'flex', alignItems:'center', justifyContent:'space-between', verticalAlign:'middle', width:'100%'}}>Send Now<SendIcon style={{marginTop:-3}}/></Typography>
									</ToggleButton>
									<ToggleButton value={true} style={{width:'50%', backgroundColor:scheduledText?'var(--light-gray)' : 'var(--white)'}}>
										<Typography style={{display:'flex', alignItems:'center', justifyContent:'space-between', verticalAlign:'middle', width:'100%'}}>Send Later<ScheduleIcon style={{marginTop:-3}}/></Typography>
									</ToggleButton>
								</ToggleButtonGroup>
							</Grid> */}
							<>

								<Grid item container xs={12} style={{display:'flex', justifyContent:'space-between'}}>
									{/* date */}
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<KeyboardDatePicker
											style={{width:'50%', paddingRight:10}}
											margin="normal"
											autoOk
											initialFocusedDate=""
											showTodayButton
											format="MM/dd/yyyy"
											disablePast
											id="date-picker-inline"
											label="Text Send Date"
											InputLabelProps={{
												shrink: true,
											}}    
											// style={{width: 150}}
											value={date}
											onChange={date => {setDate(date)}} 
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											DialogProps={{
												style:{zIndex: 888888}
											}}
											/>
									</MuiPickersUtilsProvider>
								{/* time */}
									<MuiPickersUtilsProvider  utils={DateFnsUtils}>
										<KeyboardTimePicker
											style={{width:'50%', paddingLeft:10}}
											margin="normal"
											minutesStep={60}
											views={["hours"]}
											id="time-picker"
											label="Text Send Time"
											InputLabelProps={{ shrink: true }}
											value={moment(time, "HH:mm")}
											onChange={(val) => setTime(moment(val).format('HH:mm'))}
											KeyboardButtonProps={{
												'aria-label': 'change time',
											}}
											DialogProps={{
												style:{zIndex: 888888}
											}}
											/>
									</MuiPickersUtilsProvider>
								</Grid>
								<Grid item container xs={12}>
									<Grid item xs={6}/>
									<Grid item xs={6}>
										<span style={{fontStyle:'italic', paddingLeft:10}}>{restaurant.utcTimeZone ? restaurant.utcTimeZone + 'Time' : ""}</span>
									</Grid>
								</Grid>
							</>

						</>}
						{selectedTab == 'upload' && <>

							<Grid item xs={12}>
								<div style={{borderBottom:'1px solid var(--light-gray)', display:'flex', alignItems:'center', width: '100%', cursor:'pointer'}} onClick={() => document.querySelector('#invoice-upload').click()}>
									<AttachFileIcon style={{marginBottom:2}}/>
									<span style={{marginLeft:2, width:'100%', fontSize:16, marginBottom:2}}>{invoiceFile && invoiceFile.getVal('name')? invoiceFile.name.split(/(\\|\/)/g).pop() : `Import Payment Requests`}</span>
									<input type='file' id="invoice-upload" accept={'.csv'} style={{ display: 'none' }} 
										onChange={(e) => {
											if(e.target.files && e.target.files.length > 0){
												if(e.target.files[0].size <= (1024*1024*5) ){ //5MB
													setInvoiceFile(e.target.files[0])
												} else {
													global.showNotification('The file size is too large, 5MB limit', 'error')
												}
											}
										}}
									/>
								</div>
							</Grid>
						</>}
						{selectedCustomer && selectedCustomer.email && selectedCustomer.phone &&
							<Grid item xs={12}>
								<FormControlLabel
									// style={{display:'block'}}
									label={'Send Email'} //selectedCustomer.email
									control={
										<Checkbox
											fullWidth={true}
											color="primary"
											onChange={(event) => {setData({...data, sendEmail: event.target.checked})}}
											checked={data.sendEmail}
											value={data.sendEmail}
											margin="normal"
											label="Send Email"
										/>
									}
								/>
							</Grid>
						}
						{selectedCustomer && selectedCustomer.phone && selectedCustomer.email &&
							<Grid item xs={12}>
								<FormControlLabel
									// style={{display:'block'}}
									label={'Send Text' } //formatPhoneNumber(selectedCustomer.phone)
									control={
										<Checkbox
											fullWidth={true}
											color="primary"
											onChange={(event) => {setData({...data, sendText: event.target.checked})}}
											// checked={selectedCustomer.getVal('deliveryPreferences.text')}
											checked={data.sendText}
											value={data.sendText}
											margin="normal"
											label="Send Text"
										/>
									}
								/>
							</Grid>
						}
					</Grid>
				</div>
				{!props.close && renderButton()}
			</Grid>
		)
	}
	
	return (
		<>
			{props.close && renderDialogContent()}
			{!props.close && renderFormContent()}
			{windowContent}
		</>
	)

}
