import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import theme from '../../theme';
import { Typography, Grid, Paper } from '@material-ui/core';
import moment from 'moment';

let noStateLabels = [];
for (let i = 0; i < 8; i++) {
	noStateLabels.unshift(moment().subtract(i, 'days').format('MMM Do'));
}
export default function (props) {
	const [ labels, setLabels ] = useState([]);
	const [dashboardData] = global.get('dashboardData')

	useEffect(() => {
		setLabels(noStateLabels);
	}, []);
	const data = {
		labels: labels,
		datasets: [
			{
				label: 'Loyal Customers',
				fill: 'origin',
				backgroundColor: '#9575CD98',
				borderColor: '#9575CD85',
				borderWidth: '2',
				pointBackgroundColor: '#9575CD45',
				pointHoverBackgroundColor: '#9575CD75',
				pointBorderColor: '#FFF',
				pointBorderWidth: '.75',
				lineTension: 0.3,
				data: dashboardData ? dashboardData.map(i => i.loyaltyTotalCustomers) : [ 10, 40, 52, 84, 100, 118, 146, 196 ],
			}, {
				label: 'Other Customers',
				fill: 'origin',
				backgroundColor: '#dbdbdb85',
				borderColor: '#DDDDDD85',
				borderWidth: '2',
				pointBackgroundColor: '#DDDDDD',
				pointHoverBackgroundColor: '#DDDDDD',
				pointBorderColor: '#FFF',
				pointBorderWidth: '.75',
				lineTension: 0.3,
				data: dashboardData ? dashboardData.map(i => i.ticketGuestCount) : [400, 385, 400, 350, 362, 335, 340, 330],
			},
		]
	};
	const barChartOptions = {
		plugins: {
			datalabels: {
			   display: false,
			}
		},
		legend: {
			display: true
		},
		scales: {
			xAxes: [
				{
					stacked: true
				}
			],
			yAxes: [
				{
					stacked: true,
					ticks: {
						stepSize: 100
					},
				},
				
			]
		}
	};

	const styles = {
		infoBanner: {
			// width: '97%', //TODO
			padding: 15
		},
		paper: {
			padding: '1em'
		}
	};
	// console.log("element " + Element)
	return (
		<Grid style={styles.infoBanner}>
			<Paper style={styles.paper}>
				<Grid container>
					<Grid item lg={12}>
						<Typography align="center" variant="h5">
							Total Loyal Customers by Day
						</Typography>
						<Bar data={data} options={barChartOptions} height="100" />
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
}
