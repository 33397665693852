import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import { TextField, Grid, Paper, Button, LinearProgress, Box, Container, CircularProgress, IconButton, InputAdornment, MenuItem } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import theme from '../theme'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {ReactComponent as HeartlandLogo} from '../assets/svgs/heartland-logo-white.svg';
import log from 'loglevel'
import _ from 'lodash';
import DForm2 from '../components/dash/DForm2';
import DRecord from '../components/dash/DRecord';
import PoweredByDashnow from '../layouts/components/PoweredByDashnow'
import DInput from '../components/dash/DInput';
import DFlag from '../components/dash/DFlag';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/HighlightOff';
import updateSiteConfigApi from '../actions/updateSiteConfigApi';
import Branding from './setup/branding';
import moment from 'moment'
import getRestaurantPaymentMethodsApi from '../actions/getRestaurantPaymentMethodsApi';
import verifyTestTransactionAmountApi from '../actions/verifyTestTransactionAmountApi';
import ConfigArray from './components/ConfigArray';

const headerHeight = 45
const activeButtonBarHeight = 0

const useStyles = makeStyles((theme) => ({
    stepper: {
        width: '100%',
		'&$selected': {
            backgroundColor: '#8B6BD9',
            fontWeight: 600,
		}
    },
    header: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		height: `${headerHeight}px`,
		zIndex: 11111,
    },
    content: {
		position: 'absolute',
		top: `${headerHeight}px`,
		bottom: `${activeButtonBarHeight}px`,
		left: 0,
		right: 0,
		overflow: 'auto',
		paddingTop: 30,
		paddingLeft: 20,
		paddingRight: 20,
		paddingBottom: 20,
    },
    italic: {
        color:'var(--dark-gray)',
        fontStyle:'italic'
    }
    
}))

export default (props) => {
    const classes = useStyles();

    const isMobile = useMediaQuery('(max-width:600px)')
    const defaultPadding = isMobile? 15 : 25
    const [restaurant, setRestaurant] = global.get("restaurant")
    const [masterBrandingSave, setmasterBrandingSave] = global.get("masterBrandingSave")
    const [hasChanges, setHasChanges] = global.get("hasChanges")
    const [orderTerminology] = global.get("orderTerminology")
    const [paymentMethods, setPaymentMethods] = useState({})
    const [testTransactionAmount, setTestTransactionAmount] = useState("")

    useEffect(() => {
        if (masterBrandingSave == undefined) setmasterBrandingSave(false)
        if (masterBrandingSave == true) setmasterBrandingSave(false)
    }, [masterBrandingSave])
    
    // useEffect(() => {
    //     alert(hasChanges)
    // }, [hasChanges])

    let xs = useMediaQuery(theme().breakpoints.down('xs'));
    let sm = useMediaQuery(theme().breakpoints.down('sm'));
    let mediumScreen = useMediaQuery(theme().breakpoints.down('md'));

    const [loadingAsync, setLoadingAsync] = useState(false)

	let submitForm = () => {
        if (restaurant && hasChanges) {
            let onValid = async () => {
                setLoadingAsync(true)
                setmasterBrandingSave(true)

                let recordOut = {...restaurant}

                delete recordOut.dform

                let resp = await updateSiteConfigApi(recordOut)
                if (resp && resp.restaurant) {
                    global.set({restaurant:resp.restaurant})
                    global.showNotification('Site settings successfuly saved!', 'success')
                } else {
                    global.showNotification('An error occurred, please try again', 'error')
                }
                setLoadingAsync(false)
            }
            global.set({onValid: onValid, validateNow: true})
        }
    }

    const setImHereRecord = (key, value) => {
        setHasChanges(true)
        setRestaurant({...restaurant, config: {...restaurant.config, features: {...restaurant.config.features, imHere: {...restaurant.config.features.imHere, [key]: value}}}})
    }
    
    const setTextToPayRecord = (key, value) => {
        setHasChanges(true)
        setRestaurant({...restaurant, config: {...restaurant.config, features: {...restaurant.config.features, textToPay: {...restaurant.config.features.textToPay, [key]: value}}}})
    }

    useEffect(() => {
        getRestaurantPaymentMethods()
    }, [restaurant._id])

    const handleChangeTestTransactionAmount = (e) => {
        if (e.target.value) {
            let val = e.target.value.replace(/\D+/g, '')
            setTestTransactionAmount(global.formatMoney(val))
        } else {
            setTestTransactionAmount("0.00")
        }
    }

    const getRestaurantPaymentMethods = async () => {
        if (restaurant) {
            let data = await getRestaurantPaymentMethodsApi(restaurant._id)
            setPaymentMethods(data.paymentMethods)
        } else {
            setPaymentMethods({})
        }
    }

    const verifyTestTransactionAmount = async () => {
        let confirmed = window.confirm("Please make sure to double check the amount, only one attempt is allowed")
        if (confirmed) {
            let amount = parseInt(testTransactionAmount.replace(/\D+/g, ''))
            let json = await verifyTestTransactionAmountApi(restaurant._id, amount)
            if (json.data.restaurant) {
                global.set({restaurant: json.data.restaurant})
            }
            if (json.success) {
                global.showNotification('Test transaction amount verified!', 'success')
            } else if (json.data.message) {
                global.showNotification(json.data.message, 'error')
            } else {
                global.showNotification('An error occurred, please contact support team', 'error')
            }
            
        }
    }

    let hasCredentialAndTestTransaction = false
    let showConfirmAmount = false
    let statusMessage = ''
    let isPrimaryContact = false
    if (restaurant) {
        if (restaurant.status != 'live') {
            
            let gatewayData = paymentMethods.getVal("creditCard.gatewayData", false)

            if (gatewayData) {
                if (!gatewayData.testTransactionDate || !gatewayData.testTransactionProcessedSuccessfully) {
                    statusMessage = 'Test transaction has not been made yet. '
                } else {
                    statusMessage = `Test transaction made on ${moment(gatewayData.testTransactionDate).format("MM/DD/YYYY hh:mm:ss")}. `
                    if (restaurant.testTransactionVerificationFailed) {
                        statusMessage += "Previous verify amount attempt failed. Please contact our support team for help. "
                    } else if (restaurant.testTransactionAmountInput) {
                        statusMessage += "Transaction amount had been verified successfully. "
                    } else {
                        if (restaurant.primaryContactAccount && restaurant.primaryContactAccount._id == localStorage.accountId) {
                            isPrimaryContact = true
                            showConfirmAmount = true
                            statusMessage += `Verify the test transaction amount to start using ${restaurant.appName}`
                        } else {
                            statusMessage += "Waiting for primary contact to verify amount. "
                        }
                    }
                }
            } else {
                statusMessage = "Waiting on gateway credentials. "
            }
        } else {
            statusMessage = 'Live'
        }
    }

	return (
        <div style={{width: '100%', height: '100%', backgroundColor: theme().palette.background.paper, overflow: 'scroll'}}>

            {restaurant && <>

                <DForm2 record={restaurant} margin={20}>
                    <Grid item container xs={12} lg={10} xl={8} style={{padding:defaultPadding, paddingTop: 10, paddingRight: isMobile? 15 : 45}}>
                    {props.agent &&
                        <> 
                            {/* Agent Info */}
                            <Grid item xs={12}>
                                <Typography variant="h6" color="primary">
                                    Agent Info
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{marginBottom: 5}}>
                                {/* <span className={classes.italic}>Give us some details about your business</span> */}
                            </Grid>
                            <div style={{border: 'solid 1px #e2e8f0', borderRadius: 5, width: '100%', padding: 20, paddingTop: 0, marginTop: 8, marginBottom: 10}}>
                                <div style={{top: -12, backgroundColor: 'var(--white)', paddingLeft: 10, paddingRight: 10, width: 'auto', display: 'inline-block', position: 'relative'}}>
                                    {/* <b>Location Info</b>     */}
                                </div>
                                <Grid container justify="space-between">
                                    <Grid container item xs={12} lg={6}  justify="flex-start">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={10}>
                                                <DInput name="externalNotes" disabled label="Notes" multiline rowsMax={6}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </>
                    }
                    {!props.hideBusinessInfo &&
                        <> 
                            {/* Business Info */}
                            <Grid item xs={12}>
                                <Typography variant="h6" color="primary">
                                    Business Info
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{marginBottom: 5}}>
                                {/* <span className={classes.italic}>Give us some details about your business</span> */}
                            </Grid>
                            <div style={{border: 'solid 1px #e2e8f0', borderRadius: 5, width: '100%', padding: 20, paddingTop: 0, marginTop: 8, marginBottom: 10}}>
                                <div style={{top: -12, backgroundColor: 'var(--white)', paddingLeft: 10, paddingRight: 10, width: 'auto', display: 'inline-block', position: 'relative'}}>
                                    {/* <b>Location Info</b>     */}
                                </div>
                                <Grid container justify="space-between">
                                    <Grid container item xs={12} spacing={2} style={{marginBottom: 16}}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1">
                                                Status:&nbsp;{statusMessage}
                                            </Typography>
                                        </Grid>
                                        {showConfirmAmount &&
                                            <Grid item container xs={12} alignItems="center">
                                                <TextField
                                                    label="Test transaction amount"
                                                    value={testTransactionAmount}
                                                    onChange={handleChangeTestTransactionAmount}
                                                    placeholder={"0.00"}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    }}
                                                    >
                                                </TextField>
                                                <div style={{marginLeft: 10}}><Button color="primary" variant="contained" onClick={verifyTestTransactionAmount}>VERIFY</Button></div>
                                            </Grid>
                                        }
                                    </Grid>
                                    
                                    <Grid container item xs={12} lg={6}  justify="flex-start">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={10}>
                                                <DInput name="name" label="Business Name" required/>
                                            </Grid>
                                            <DRecord record={restaurant.locationInfo} >
                                                <Grid item xs={12} md={10} style={{display:'flex', justifyContent:'flex-start'}}>
                                                    <DInput
                                                        format="(###) ###-####"
                                                        fieldType="phonenumber"
                                                        type="tel"
                                                        name="phone"
                                                        pattern="[0-9]*"
                                                        margin="normal"
                                                        label="Business Phone"
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth={true}
                                                        style={{ paddingTop: 0, marginTop: '0px', marginBottom:20}}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={10}>
                                                    <DInput name="website" label="Website"/>
                                                </Grid>
                                            </DRecord>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} lg={6}>
                                        <DRecord record={restaurant.locationInfo}>
                                            <Grid container justify="flex-start" spacing={2}>
                                                <Grid item xs={12} md={11}>
                                                    <DInput name="addressLine1" label="Address"/>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <DInput name="city" label="City"/>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <DInput name="state" label="State" fieldType="Select">
                                                        <MenuItem disabled value=""><em>State</em></MenuItem>
                                                        {states.map((state) => <MenuItem disabled={(state.prop) ? true : false} value={state.value}>{state.label}</MenuItem>)}
                                                    </DInput>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <DInput name="zipCode" label="Zip Code" type="tel" format='#####' errorIf={(value)=> {return !(/^\d{5}$/.test(value))}} helperText="Use 5 digit zip code"/>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <DInput name="country" label="Country"/>
                                                </Grid>
                                            </Grid>
                                        </DRecord>
                                    </Grid>
                                </Grid>
                            </div>
                            <Grid item container xs={12} alignItems="center" id="social-media-links">
                                <DRecord record={restaurant.locationInfo}>
                                    <div style={{border: 'solid 1px #ccc', borderRadius: 5, width: '100%', padding: 20, paddingTop: 0, marginTop: 20, marginBottom: 20}}>
                                        <div style={{top: xs ? -28 : -15, backgroundColor: 'var(--white)', paddingLeft: 10, paddingRight: 10, width: 'auto', display: 'inline-block', position: 'relative'}}>
                                            <Grid container alignItems="center">
                                                <Typography variant="subtitle1" style={{paddingRight: 10}}>
                                                    Social Media Links
                                                </Typography>
                                                <Typography variant="caption">(Displayed at the bottom of emailed receipts and invoices)</Typography>
                                            </Grid>
                                        </div>
                                        <Grid container xs={12} justify="space-between">
                                            <Grid item container xs={12} md={6} justify="flex-start">
                                                <Grid item xs={11}>
                                                    <DInput name="facebookLink" errorIf={(value)=> {return !(/^(https?\:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9]+\/?$/.test(value))}} label="Facebook Link"/>
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <DInput name="twitterLink" label="Twitter Link"/>
                                                </Grid>
                                            </Grid>
                                            <Grid item container xs={12} md={6} justify="flex-start">
                                                <Grid item xs={11}>
                                                    <DInput name="instagramLink" label="Instagram Link"/>
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <DInput name="linkedInLink" label="LinkedIn Link"/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </DRecord>
                            </Grid>
                        </>
                    }
                    {!props.hideBranding &&
                        <Branding save={false} hideSplash={props.hideSplash}/>
                    }
                        {restaurant.getVal('config.features', false) && <>

                            {/* I'M HERE  */}
                            <DRecord record={restaurant.config.features.imHere} name="imHere"/>
                            
                            {(restaurant.appName == "Curbly" || restaurant.appName == "DashNow") && restaurant.config.getVal('features.imHere.enabled', false) && <>
                                <Grid item xs={12} style={{paddingTop:props.hideBusinessInfoAndBranding ? 0 : 20}}>
                                    <Typography variant="h6" color="primary">
                                        I'm Here
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{marginBottom: 5}}>
                                    <span className={classes.italic}>
                                        These settings will affect how your customer's notifies you when they arrive.
                                    </span>
                                </Grid>
                                <Grid item container xs={12} alignItems="center">
                                    <div style={{border: 'solid 1px #ccc', borderRadius: 5, width: '100%', padding: 20, paddingTop: 0, marginTop: 20, marginBottom: 20}}>
                                        <div style={{top: -15, backgroundColor: 'var(--white)', paddingLeft: 10, paddingRight: 10, width: 'auto', display: 'inline-block', position: 'relative'}}>
                                            <Typography variant="subtitle1">
                                                Pick Up Options
                                            </Typography>
                                        </div>
                                        <Grid container xs={12}>
                                            <Typography variant="body" gutterBottom style={{color: '#777', marginBottom: 10}}>
                                                Please choose the options that apply for your location
                                            </Typography>
                                            <Grid item xs={12}>
                                                <DInput fieldType="toggle" name="selectVehicleEnabled" label="Ask the customer for their vehicle type and color?" required={() => { return !restaurant.config.getVal("features.imHere.selectVehicleEnabled") && !restaurant.config.getVal("features.imHere.selectLocationEnabled") }} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DInput fieldType="toggle" name="selectLocationEnabled" label="Ask the customer where they are parked?" rerender required={() => { return !restaurant.config.getVal("features.imHere.selectVehicleEnabled") && !restaurant.config.getVal("features.imHere.selectLocationEnabled") }} />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>

                                {restaurant.config.getVal("features.imHere.selectLocationEnabled", false) && 
                                    <Grid item container xs={12} alignItems="center">
                                        <div style={{border: 'solid 1px #e2e8f0', borderRadius: 5, width: '100%', padding: 20, paddingTop: 0, marginTop: 20, marginBottom: 20}}>
                                            <div style={{top: -12, backgroundColor: 'var(--white)', paddingLeft: 10, paddingRight: 10, width: 'auto', display: 'inline-block', position: 'relative'}}>
                                                <b>Pick Up Locations</b>    
                                                {/* <Typography variant="subtitle1">
                                                    Pick Up Locations
                                                </Typography> */}
                                            </div>
                                            <Grid container xs={12} alignItems="center">
                                                <Typography variant="body" gutterBottom style={{color: '#777', marginBottom: 10}}>
                                                    Please input your pick up locations
                                                </Typography>
                                                <DRecord record={restaurant.config.features.imHere.pickupLocations}>
                                                    {restaurant.config.getVal('features.imHere.pickupLocations', false) && restaurant.config.features.imHere.pickupLocations.length > 0 && restaurant.config.features.imHere.pickupLocations.map((row, x) => 
                                                        <Grid item xs={12} container>
                                                            <Grid item style={{width: 10, paddingTop: 6, marginRight: 12, color: '#777'}}>
                                                                {x+1}.
                                                            </Grid>
                                                            <Grid item xs>
                                                                <DInput name={`${x}`} required marginBottom={10} />
                                                            </Grid>
                                                            <Grid item style={{width: 16, marginLeft: 6}}>
                                                                <IconButton aria-label="delete" style={{padding: 5}}>
                                                                    {x > 0 &&
                                                                        <DeleteIcon onClick={() => { setImHereRecord('pickupLocations', restaurant.config.getVal("features.imHere.pickupLocations", []).removeItemAtIndex(x)); }} />
                                                                    }
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </DRecord>

                                                <Grid item xs={12}>
                                                    <Button variant={'contained'} color="secondary" onClick={() => { setImHereRecord('pickupLocations', restaurant.config.getVal("features.imHere.pickupLocations", []).concat([''])) }} style={{marginTop: 10, marginLeft: 20}}>+ ADD LOCATION</Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                }
                            </>}
                            {/* Mobile Config */}
                            {restaurant.appName != 'TextPay' && <> {/* Disabling for textpay until more feedback/mobile features are available */}
                                <Grid item xs={12}>
                                    <Typography variant="h6" color="primary" style={{paddingTop:20}}>
                                        Mobile
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{marginBottom: 5}}>
                                    <span className={classes.italic}>
                                        These settings will affect your customer's experience with this platform
                                    </span>
                                </Grid>

                                {restaurant.config.getVal('mobile', false) && <> 
                                    <DRecord record={restaurant.config.mobile} name="mobile"/>
                                    <div style={{border: 'solid 1px #e2e8f0', borderRadius: 5, width: '100%', padding: 20, paddingTop: 10, marginTop: 8, marginBottom: 5}}>
                                        <Grid item container xs={12} alignItems="flex-start">
                                            {/* <Typography variant="subtitle1">
                                                Tip options
                                            </Typography> */}
                                            <Grid item xs={12}>
                                                <DInput fieldType="toggle" name="allowTips" label="Ask the customer for a tip?" rerender />
                                            </Grid>
                                            {restaurant.config.getVal('mobile.allowTips') && <>
                                                <Grid item xs={12}>
                                                    <DInput fieldType="toggle" name="allowNoTipButton" label="Can your customer tip in cash?" />
                                                </Grid>
                                                {restaurant.appName != 'TextPay' && // for now this is because textpay doesn't have tax
                                                    <Grid item xs={12}>
                                                        <DInput fieldType="toggle" name="tipsIncludeTax" label="Do you want to include tax when calculating the tip?" rerender />
                                                    </Grid>
                                                }
                                                
                                            </>}
                                            <DRecord record={restaurant.config.mobile} name="mobile"/>
                                            <Grid item xs={12}>
                                                <DInput fieldType="toggle" name="allowFeedback" label="Do you want to prompt your customer for feedback?" rerender />
                                            </Grid>
                                            {/* {!record.getVal('mobile.allowTips') &&
                                                <Grid item xs={12}>
                                                    <DInput fieldType="toggle" name="skipReceiptPage" label="Do you want your customer to skip the order summary?" />
                                                </Grid>
                                            } */}
                                        </Grid>
                                    </div>
                                    {restaurant.config.getVal('mobile.allowTips') &&
                                        <DRecord record={restaurant.config.mobile.tipButtonPercentages} name="tipButtonPercentages">
                                            <Grid container item xs={12}>
                                                <div style={{border: 'solid 1px #e2e8f0', borderRadius: 5, width: '100%', padding: 20, paddingTop: 0, marginTop: 10, marginBottom: 5}}>
                                                    <div style={{top: -12, backgroundColor: 'var(--white)', paddingLeft: 10, paddingRight: 10, width: 'auto', display: 'inline-block', position: 'relative'}}>
                                                        <b>Tip Suggestions</b>    
                                                        {/* <Typography variant="subtitle1">
                                                            Tip Options
                                                        </Typography> */}
                                                    </div>
                                                    <Grid container spacing={2} style={{height:65}}>
                                                        <Grid item xs={4}>
                                                            <DInput InputProps={{margin:'dense', endAdornment: <InputAdornment position="end">%</InputAdornment>}} type="number" min={0} max={100} name="left" label="Tip #1" />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <DInput InputProps={{margin:'dense', endAdornment: <InputAdornment position="end">%</InputAdornment>}} type="number" min={0} max={100} name="center" label="Tip #2" />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <DInput InputProps={{margin:'dense', endAdornment: <InputAdornment position="end">%</InputAdornment>}} type="number" min={0} max={100} name="right" label="Tip #3" />
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </DRecord>
                                    }
                                </>}
                            </>}
                            {/* Generic TextPay settings */}
                            {restaurant.appName == 'TextPay' &&
                                <Grid item container xs={12} alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography variant="h6" color="primary">
                                            TextPay Settings
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8} xl={6} style={{marginTop:15}}>
                                        <DRecord record={restaurant.config.features.textToPay} name="">
                                            <DInput name="orderTerminology" label={xs? 'What do you call your Invoices?' :"What do you call your Invoices? (Order, Bill, Check, etc.)"}/>
                                        </DRecord>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DRecord record={restaurant.config.mobile} name="">
                                            <div style={{marginTop: 15}}>
                                                <DInput fieldType="toggle" name="allowPartialPayments" label={'Do you want to allow partial payments?'} rerender/>
                                            </div>
                                        </DRecord>
                                        <DRecord record={restaurant.config.features.textToPay} name="textToPay"/>
                                    </Grid>
                                <ConfigArray
                                    header="Payment Confirmation Email List"
                                    helperText="Please enter the emails you would like to receive confirmations when {orderTerminology}s get paid"
                                    globalModelName="restaurant"
                                    setCallback={setTextToPayRecord}
                                    path="config.features.textToPay.paymentConfirmationEmails"
                                    addButtonLabel="+ ADD EMAIL"
                                />
                                <ConfigArray
                                    header="Delivery Failed Notification Email List"
                                    helperText="Please enter the emails you would like to receive notifications when email or text delivery fails"
                                    globalModelName="restaurant"
                                    setCallback={setTextToPayRecord}
                                    path="config.features.textToPay.deliveryFailedNotificationEmails"
                                    addButtonLabel="+ ADD EMAIL"
                                />
                                </Grid>
                            }
                        </>}   
                    </Grid>
                </DForm2>
            </>}
            {(hasChanges || loadingAsync) && <>
                {xs ?
                    <div item style={{padding:'10px 10px', display:'flex', justifyContent:'center'}} justify="space-between">
                            <Button variant={'contained'} size="large" color="secondary" disabled={loadingAsync} onClick={() => submitForm()}>{loadingAsync? <CircularProgress color="white"/>:'Save Settings'}</Button>
                    </div>
                :    
                    <div item style={{padding:'10px 10px', position:'absolute', bottom:30, right:'calc(41.7% - 80px)'}} justify="space-between">
                            <Button variant={'contained'} size="large" color="secondary" disabled={loadingAsync} onClick={() => submitForm()}>{loadingAsync? <CircularProgress color="white"/>:'Save Settings'}</Button>
                    </div>
                }       
            </>}
        </div>
	);
};

const states = [
	{ value: 'AL', label: 'Alabama' },
	{ value: 'AK', label: 'Alaska' },
	{ value: 'AZ', label: 'Arizona' },
	{ value: 'AR', label: 'Arkansas' },
	{ value: 'CA', label: 'California' },
	{ value: 'CO', label: 'Colorado' },
	{ value: 'CT', label: 'Connecticut' },
	{ value: 'DE', label: 'Delaware' },
	{ value: 'FL', label: 'Florida' },
	{ value: 'GA', label: 'Georgia' },
	{ value: 'HI', label: 'Hawaii' },
	{ value: 'ID', label: 'Idaho' },
	{ value: 'IL', label: 'Illinois' },
	{ value: 'IN', label: 'Indiana' },
	{ value: 'IA', label: 'Iowa' },
	{ value: 'KS', label: 'Kansas' },
	{ value: 'KY', label: 'Kentucky' },
	{ value: 'LA', label: 'Louisiana' },
	{ value: 'ME', label: 'Maine' },
	{ value: 'MD', label: 'Maryland' },
	{ value: 'MA', label: 'Massachusetts' },
	{ value: 'MI', label: 'Michigan' },
	{ value: 'MN', label: 'Minnesota' },
	{ value: 'MS', label: 'Mississippi' },
	{ value: 'MO', label: 'Missouri' },
	{ value: 'MT', label: 'Montana' },
	{ value: 'NE', label: 'Nebraska' },
	{ value: 'NV', label: 'Nevada' },
	{ value: 'NH', label: 'New Hampshire' },
	{ value: 'NJ', label: 'New Jersey' },
	{ value: 'NM', label: 'New Mexico' },
	{ value: 'NY', label: 'New York' },
	{ value: 'NC', label: 'North Carolina' },
	{ value: 'ND', label: 'North Dakota' },
	{ value: 'OH', label: 'Ohio' },
	{ value: 'OK', label: 'Oklahoma' },
	{ value: 'OR', label: 'Oregon' },
	{ value: 'PA', label: 'Pennsylvania' },
	{ value: 'RI', label: 'Rhode Island' },
	{ value: 'SC', label: 'South Carolina' },
	{ value: 'SD', label: 'South Dakota' },
	{ value: 'TN', label: 'Tennessee' },
	{ value: 'TX', label: 'Texas' },
	{ value: 'UT', label: 'Utah' },
	{ value: 'VT', label: 'Vermont' },
	{ value: 'VA', label: 'Virginia' },
	{ value: 'WA', label: 'Washington' },
	{ value: 'WV', label: 'West Virginia' },
	{ value: 'WI', label: 'Wisconsin' },
	{ value: 'WY', label: 'Wyoming' },
	{ value: 'CD', label: 'Canadian Provinces', prop: 'disabled' },
	{ value: 'AB', label: 'Alberta' },
	{ value: 'BC', label: 'British Columbia' },
	{ value: 'MB', label: 'Manitoba' },
	{ value: 'NB', label: 'New Brunswick' },
	{ value: 'NL', label: 'Newfoundland and Labrador' },
	{ value: 'NS', label: 'Nova Scotia' },
	{ value: 'ON', label: 'Ontario' },
	{ value: 'PE', label: 'Prince Edward Island' },
	{ value: 'QC', label: 'Quebec' },
	{ value: 'SK', label: 'Saskatchewan' }
];