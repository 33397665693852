import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import log from 'loglevel';
import _, { truncate } from 'lodash';
import {
	Grid,
	Stepper,
	Step,
	StepButton,
	Button,
	CircularProgress,
	Container,
	Box,
	Paper,
	Typography,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {ReactComponent as DashNowLogo} from '../../assets/svgs/dash-now-logo-white.svg';
import theme from '../../theme'
// import SelectPackagePage from './pages/SelectPackagePage'
import OwnerInfo from './pages/OwnerInfo'
import BankInfo from './pages/BankInfo'
import CompanyInfo from './pages/CompanyInfo'
import RequestComplete from '../requestComplete';
import dashFetch from '../../utils/dashFetch'
import publishApi from '../../actions/publishApi';
import goToPageApi from '../../actions/goToPageApi';
import { urlParse } from '../../utils/urlParser'
import findRequestApi from '../../actions/findRequestApi'
import createMerchantSetupRequestApi from '../../actions/createMerchantSetupRequestApi'
import history from '../../history'
import getDropdownListApi from '../../actions/getDropdownListApi';
import SummaryAgreement from './pages/SummaryAgreement';
import SalesInfo from './pages/SalesInfo';
import Dloader from '../../components/dash/DLoader';
import Slide from '@material-ui/core/Slide';
import DLoader from '../../components/dash/DLoader';
import PriceScheduleTable from '../../components/dash/PriceScheduleTable';
import DashIcon from '../../assets/svgs/dashIcon';
import TextPayIcon from '../../assets/svgs/textPayIcon';

const headerHeight = 80
const activeButtonBarHeight = 52

const styles = (theme) => ({
	root: {
		width: '100%',
		height: '100%',
		backgroundColor: 'var(--background)',
		overflow: 'hidden',
	},
	stepper: {
		width: '100%',
		backgroundColor: 'var(--background)',
		'&$selected': {
			backgroundColor: '#5220A8'
		},
	},
	lowerPage: {
		height: '100%',
		width: '100%',
		// marginTop: stepperHeight + 50,
	},
	header: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		height: `${headerHeight}px`,
		zIndex: 11111,
	},
	content: {
		position: 'absolute',
		top: `${headerHeight}px`,
		bottom: `${activeButtonBarHeight}px`,
		left: 0,
		right: 0,
		overflow: 'auto',
		paddingTop: 30,
		paddingBottom: 20,
	},
	actionButtonBar: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
		height: `${activeButtonBarHeight}px`,
		zIndex: 11111,
		paddingTop: 8,
	},
});

function camelize(str) {
	return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
		return index == 0 ? word.toLowerCase() : word.toUpperCase();
	}).replace(/\s+/g, '');
}

const Dash = (props) => {
	const { classes } = props;
	const [steps, setSteps] = useState([])
	const [ownersClone, setOwnersClone ] = useState([])
	const [posTypes, setPosTypes] = useState([]);
	const [activeStep, setActiveStep] = useState(0);
	const [stepName, setStepName] = useState('Company Info');
	const [loadingAsync, setLoadingAsync] = useState(true)
	const [showStepper, setShowStepper] = useState(true)
    const [completed, setCompleted] = useState(new Set());
    const [buttonClicked, setIsButtonClicked] = useState(false)
	const [hideShell, setHideShell] = useState(props.hideShell)

	let [request, setRequest] = useState({})
	
	const requestType = "MerchantSetupRequest"

	const [showButtons, setShowButtons] = useState(true)
	const [showBackButton, setShowBackButton] = useState(true)
    const [showNextButton, setShowNextButton] = useState(true)
    const [nextIsDisabled, setNextIsDisabled] = useState(false)

	let params = urlParse(window.location.href).params
	if (!hideShell && params.shell == "hide") {
		setHideShell(true)
	}

	const handleStep = (step) => {
        setActiveStep(step);
        setStepName(steps[step])
		let showBackBtn = (step != steps.length)
		let showNextBtn = (step != steps.length)
		setShowBackButton(showBackBtn)
		setShowNextButton(showNextBtn)
		if (showBackBtn || showNextBtn) {
			setShowButtons(true)
        }
	};
	
	let backStep = (step) => {
		if (step > 0) {
			handleStep(--step);
		} else {
			setShowStepper(true)
		}
	};

	let nextStep = (step) => {
        console.log("forward step")
        const stepPlusOne = 1 + step

        if ((stepName == "Bank Info") && request && request.data.bankInfo && (!request.data.bankInfo.voidedCheckDataURL || request.data.bankInfo.voidedCheckDataURL==true)) {
            global.showNotification('Please upload a photo of a voided check', 'warning')
            return
        }

		let onValid = async () => {
            setLoadingAsync(true)

			if (goToPageApi(request, stepPlusOne, requestType)) {
				handleStep(stepPlusOne);
				if (step > steps.length -1) {
					submit()
                }
            }
            setLoadingAsync(false)
		}
		global.set({onValid: onValid, validateNow: true})
	};

	const submit = async () => {
		log.info('submitting request')
		setLoadingAsync(true)
		await publishApi(request, requestType)
		setLoadingAsync(false)
	}

	function isStepComplete(step) {
		return completed.has(step);
    }
    
    useEffect(()=>{
        //ensure 'Next' button is enabled for all pages by default except for 'Sign & Agree'
        if (stepName !== 'Sign & Agree'){
            setNextIsDisabled(false)
        }
    },[stepName])

	useEffect(() => {
		
        async function fetchRequest() {
			let data
			let requestId = params.req ? params.req : localStorage.lastRequestId
			if (requestId) {
				data = await findRequestApi(requestId)
			} else {
				const textPay = window.location.href.indexOf('textpay') > -1 
				let createSiteType = (textPay) ? 'TextPay' : 'DashNow' // for now if we come here for textPay, we are creating a new site	
				createSiteType = params.createSite == '1' ? createSiteType : '' // only create if createSite=1
				let priceTemplateCode = props.priceTemplateCode ? props.priceTemplateCode : params.pt
				let processorCode = props.processorCode ? props.processorCode : params.p
				data = await createMerchantSetupRequestApi(processorCode, priceTemplateCode, createSiteType)
			}
			if (params.acnt) {
				data.accountId = params.acnt
			}
			let thereq = data.request
			requestId = thereq._id
			if (requestId) {
				localStorage.lastRequestId = requestId
			}
			let steps = data && data.request && data.request.pages
			let ownersTemplate = thereq && thereq.data && thereq.data.owners && thereq.data.owners[0]
			let oc = ownersTemplate ? JSON.parse(JSON.stringify(ownersTemplate)) : {}
            // alert(JSON.stringify(rc))
			setOwnersClone(oc)
			setRequest(thereq)
			setSteps(steps)
			// history.push(`?req=${thereq._id}`)
		}
		fetchRequest()

		global.set({ headerTitle: 'RESTAURANT ONBOARDING' })
	}, [])

	useEffect(() => {
		if (request && request.pageIndex > -1 && steps && steps.length > 0) {
            handleStep(request.pageIndex)
			setLoadingAsync(false)
		}
    }, [steps, request])

	const sm = useMediaQuery(theme().breakpoints.down('sm'));
    const xs = useMediaQuery(theme().breakpoints.down('xs'));

	return (
		<div className={classes.root} style={{backgroundColor: hideShell ? '#FFF' : 'inherit'}}>
			<Box className={classes.header} bgcolor="var(--dash-purple)" style={{display: hideShell ? 'none' : ''}}>
				<Container className={classes.headerContainer} maxWidth={sm ? 'sm': xs ? 'xs' : 'lg'} style={{paddingTop: 16, paddingLeft: xs? 25: 50, paddingRight: xs? 25: 50}}>
					<Grid container justify="space-between">
						<Grid item xs={6} sm={4} md={4} lg={4}>
                            <div onClick={() => {window.location.href = global.isDashNow() ? 'https://dashnow.com': 'https://textpay.app'}}>
								{global.isDashNow() && <DashIcon fill='white' height='50px'/>}
								{global.isTextPay() && <TextPayIcon inverted height='50px'/>}
                            </div>
						</Grid>
						<Grid item xs={6} sm={8} md={8} lg={8}> 
							<Typography variant={sm ? "h6" : "h5"} align="right" style={{color: '#FFF', marginTop: xs ? 0 : 10}}>
								New Merchant Setup
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Container className={classes.content} maxWidth={sm ? 'sm': xs ? 'xs' : 'lg'} style={{top: hideShell ? 0 : headerHeight, maxWidth: hideShell ? 'inherit' : ''}}>
				<Grid container spacing={0}>
					{!showStepper &&
						<></>
					}
					{showStepper && 
					<>
						{activeStep != steps.length &&
							<Stepper className={classes.stepper} style={{backgroundColor: hideShell ? '#FFF' : 'inherit', padding: xs ? 0 : 'auto'}} alternativeLabel nonLinear activeStep={activeStep}>
								{steps && steps.map((label, index) => {
									const stepProps = {};
									const buttonProps = {};
									return (
										<Step key={label} {...stepProps}>
											<StepButton
                                                disableTouchRipple
                                                style={{cursor:'default'}}
												completed={isStepComplete(index)}
												{...buttonProps}
											>
												{label}
											</StepButton>
										</Step>
									);
								})}
							</Stepper>
						}
						<Grid className={classes.lowerPage}>
							{loadingAsync && <DLoader/>}
							{!loadingAsync && stepName == "Company Info"  && 
								(<Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={{enter:200}}>
									<Container style={{padding:xs?5:'auto'}}>
										<PriceScheduleTable style={{paddingBottom:30}} data={request.data.pricing}/>
										<CompanyInfo 
											record={request.data}
											ownersClone={ownersClone}
											dropDownOptions={{posTypes}}/>
									</Container>
								</Slide>
							)}
							{!loadingAsync && stepName == "Owner Info"  && 
								(<Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={{enter:200}}>
									<Container style={{padding:xs?5:'auto'}}>
										<OwnerInfo 
											record={request.data}
											dropDownOptions={{posTypes}} 
											setRequest={setRequest}
										/>
									</Container>
								</Slide>
							)}
							{!loadingAsync && stepName == "Sales Info"  && 
								(<Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={{enter:200}}>
									<Container style={{padding:xs?5:'auto'}}>
										<SalesInfo 
											record={request.data}
											dropDownOptions={{posTypes}}/>
									</Container>
								</Slide>
							)}
							{!loadingAsync && stepName == "Bank Info"  && 
								( <Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={{enter:200}}>
									<Container style={{padding:xs?5:'auto'}}>
										<BankInfo 
										record={request.data}
										dropDownOptions={{}}
                                        goToPageApi = {goToPageApi}
                                        request={request}
                                        />
									</Container>
								</Slide>
							)}
							{!loadingAsync && stepName == "Sign & Agree"  && 
								(<Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={{enter:200}}>
									<Container style={{padding:xs?5:'auto'}}>
										<SummaryAgreement
											record={request.data}
                                            dropDownOptions={{}} 
                                            setNextIsDisabled={setNextIsDisabled}
											nextStep={()=> nextStep(activeStep)}
                                        />
									</Container>
								</Slide>
							)}
							{!loadingAsync && steps.length > 0 && activeStep == (steps.length) && 
								(<Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={{enter:200}}>
									<Container style={{padding:xs?5:'auto'}}>
										<RequestComplete 
											request={request}
										/>
									</Container>
								</Slide>
							)}
						</Grid>
					</>}
				</Grid>
			</Container>
			{ showStepper && showButtons && 
				<Box className={classes.actionButtonBar} bgcolor="#F8F8F8" style={{borderTop: 'solid 1px #EEE', boxShadow: '0 0 10px 5px rgba(231,231,231,0.50)'}}>
					<Container maxWidth={sm ? 'sm': xs ? 'xs' : ''}>
						<Grid container justify="space-between" alignItems="center">
							<Grid item>
								{showBackButton && <Button variant={'contained'} color="primary" onClick={() => backStep(activeStep)} style={{marginLeft: 1}}>Back</Button>}
							</Grid>
							<Grid item>
								{showNextButton && <Button variant={'contained'} color="primary" onClick={() => nextStep(activeStep)} type='submit' disabled={nextIsDisabled}>Next</Button>}
							</Grid>
						</Grid>
					</Container>
				</Box>
			}
		</div>
	);
};

Dash.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withStyles(styles, { withTheme: true })(Dash);
