import React from 'react';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

export default function DTextField(props) {
	let [checked] = React.useState(props.checked)

	let props2 = {...props}

	props2.style = {...props2.style, marginBottom: props2.style && props2.style.marginBottom ? props2.style.marginBottom : 0}
	props2.style = {...props2.style, marginRight: props2.style && props2.style.marginRight ? props2.style.marginRight : 5}
	props2.style = {...props2.style, display: props2.style && props2.style.display ? props2.style.display : 'inline-block'}

	return (<>
		{checked ?
			<CheckCircleOutlineIcon color={'primary'} {...props2} />
			:	
			<NotInterestedIcon color="secondary" {...props2} />
		}
	</>);
}
