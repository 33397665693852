import React, { useState, useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import log from 'loglevel';
import _ from 'lodash';
import {
	Grid,
	Typography,
	Button,
	Tabs,
	Tab,
	MenuItem,
	FormControlLabel,
	FormControl,
	TableBody,
	InputLabel,
	Popover,
	Modal,
	TextField,
	Input,
	Switch,
	Slider,
	Divider,
	LinearProgress,
	IconButton,
	Tooltip,
	Box,
	TableContainer,
	useMediaQuery,
	Collapse,
} from '@material-ui/core';
import {
	Build,
	Visibility,
	AddCircleOutline,
	Delete
} from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import Select from 'react-select';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { NotificationManager } from 'react-notifications';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SelectMui from '@material-ui/core/Select';
import MuiTreeView from '../../layouts/components/MuiTreeView';
import DChart from '../../components/dash/DChart';
import DForm from '../../components/dash/DForm';
import DSwitch from '../../components/dash/DSwitch';
import DSubmitButton from '../../components/dash/DSubmitButton';
import DFlag from '../../components/dash/DFlag';
import DTextField from '../../components/dash/DTextField';
import RestaurantInfo from '../../dashboard/setup/restaurantInfo';
import dashFetch from '../../utils/dashFetch';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import moment from 'moment-timezone';
import { useLocation } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';

import GroupWorkIcon from '@material-ui/icons/GroupWork';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

import theme from '../../theme';
import ChartsIcon from '@material-ui/icons/BarChart';

import AddEditRestaurantGroup from '../components/AddEditRestaurantGroup'

import ReactTable from "react-table";
import "react-table/react-table.css";


const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		height: '100%',
		width: '100%',
	},
	tabs: {
		background: 'var(--background)',
		width: 60,
		borderRight: 'solid 1px #CCC',
		height: '100%',
	},
	buttonIcon: {
		padding:3,
		paddingLeft:0,
		paddingRight:5,
	},
	searchBarWrapper: {
		display: 'flex'
		// backgroundColor: 'gray'
	},
	searchBar: {
		width: '80%',
		'&$selected': {
			backgroundColor: '#5220A8'
		}
	},
	restaurantFieldInputItem: {
		width: '90%',
		marginTop: '0px'
	},

	fullWidthInput: {
		width: '100%',
		marginBottom:'15px'
	},
	divider: {
		margin:5
	},
	rootTable: {
		margin: 12
	},
	table: {
		width: '100%',
		minWidth: 650
	},
	subheadingText: {
		fontSize: '16px'
	},
	addButton: {
		// backgroundColor: '#5220A8'
		margin: 10,
		minWidth: 28,
		backgroundColor: theme.palette.primary.main,
		borderRadius: 15,
		padding: 2,
	},
	indicator: {
		backgroundColor: '#5220A8'
	},
	header: {
		width: '100%', 
		height: '110px', 
		overflow: 'hidden', 
		borderBottom: 'solid 1px #CCC', 
		background: 'var(--background)',
		overflowX: 'hidden', 
		overflowY: 'hidden',
	},
	headerLabel: {
		color: 'var(--dark-gray)', 
		fontSize: '1.2em',
		float: 'left',
		display: 'block',
    },
    addIcon: {
		float: 'left',
		marginTop: 5,
		marginRight: 5,
		width: '20px',
		height: '20px'
	},
	addButton: {
		marginLeft: 20,
		marginRight: 20,
		marginTop: 10,
		marginBottom: 10,
		width: 200,
		height: 45
    },
});

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: '75px',
		height: '75px',
		paddingLeft: '10px',
	}),
	indicatorsContainer: (provided) => ({
		...provided,
		height: '75px',
		paddingLeft: '10px',
	}),
	menuList: (base) => ({
		...base,
		minHeight: 'fit-content'
	}),
	clearIndicator: (provided) => ({
		...provided,
		padding: '5px'
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		padding: '5px'
	}),
	valueContainer: (provided) => ({
		height: '75px',
		paddingLeft: '10px',
	})
};

const RestaurantGroups = (props) => {
    
	const TABLE_TAB = 1;
	const BRANDING_TAB = 2;

	// let smallScreen = useMediaQuery(theme().breakpoints.down('sm'));
	const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(TABLE_TAB);
    const [dialogContent, setDialogContent] = useState()
    const [ restaurantGroups, setRestaurantGroups ] = useState([])
    const [ selectedGroup, setSelectedGroup ] = useState({})
    // const [ posTypes, setPosTypes ] = useState({})

    const { classes } = props;

    const fetchData = async () => {
        let data = await dashFetch(`${global.dashServerUrl}/dashboard/api/v1/dashboard/getDropdownList`, {}, true);
        if (data.json) {
            let posTypes = {}
            if (data.json.posTypes) {
                for (let type of data.json.posTypes) {
                    const id = type._id
                    posTypes = { ...posTypes, [id]:type }
                }
            }

            let accounts = {}
            // if (data.json.restaurantAccounts) {
            //     for (let account of data.json.restaurantAccounts) {
            //         const id = account._id
            //         accounts = {...accounts, [id]:account}
            //     }
            // }

            if (data.json.restaurantGroups) {
                for (let group of data.json.restaurantGroups) {
                    // attach POS info to restaurantGroup
                    if (group.posType && posTypes[group.posType]) {
                        group.posTypeObj = posTypes[group.posType]
                    }
                }
            }
            setRestaurantGroups(data.json.restaurantGroups ? 
                data.json.restaurantGroups.sort((a, b) => {
                    if (a.name && b.name) {
                        return (
                            (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1
                        )
                    }
                }) 
            : 
                []);        
        }
    }
    console.log(restaurantGroups)

    useEffect(()=>{
        fetchData()
    },[])
    
    const openAddEditWindow = (group) => {
        setDialogContent(
            <AddEditRestaurantGroup group={group} close={closeContent} refreshList={fetchData}/>
            // <AddEditRestaurantGroup refresh={setRefreshAccounts} account={account} create={newAccount} roles={accountRoles} close={closeContent} />
        )
    }

    const closeContent = () => {
        setDialogContent(null)
        fetchData()
    }
    

    const tableView = () => {
        return (
            <div className={classes.listing}>
            <Grid container>
                <Paper style={{ width: '100%', height: '100%' }} className={classes.rootTable}>
                    <div>
                        <Grid container>
                            <Grid item xs={12} style={{ padding: '14px 14px 0px 14px', textAlign:'left', marginTop:'-8px'}}>
                                <Typography variant="h6" color="primary">
                                    Restaurant Groups
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.addButton}
                                    onClick={()=> openAddEditWindow({})}
                                >
                                    <div>
                                        <AddCircleOutline className={classes.addIcon} />
                                        <Typography variant="subtitle1" style={{ color: 'white', width: 190 }}>
                                            Add Group
                                        </Typography>
                                    </div>
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <ReactTable
                                    data={restaurantGroups}
                                    filterable
                                    defaultFilterMethod={(filter, row) =>
                                        String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
                                    }
                                    defaultSorted={[
                                        {
                                            id: "createdAt",
                                            desc: false
                                        }
                                    ]}
                                    columns={[
                                        {
                                            Header: "Restaurant Groups",
                                            columns: [
                                                {
                                                    Header: "Group Name",
                                                    accessor: "name"
                                                },
                                                {
                                                    Header: "ID",
                                                    accessor: "_id",
                                                },
                                                {
                                                    Header: "Locations",
                                                    accessor: "locationCount",
                                                },
                                                {
                                                    Header: "Primary Contact Account",
                                                    accessor: "primaryContactInfo",
                                                    Cell: props => <span>{props.original.primaryContactInfo.firstName} {props.original.primaryContactInfo.lastName}</span>
                                                },
                                                {
                                                    Header: "POS Type",
                                                    accessor: "posType.posType"
                                                },
                                                {
                                                    Header: "Agent",
                                                    accessor: "agent"
                                                },
                                                {
                                                    Header: "Sales Rep",
                                                    accessor: "salesRep"
                                                }
                                            ]
                                        },
                                    ]}
                                    defaultPageSize={15}
                                    className="-striped -highlight"
                                    style={{width:'100%', height:'100%'}}
                                    getTrProps={(state, rowInfo) => {
                                        if (rowInfo && rowInfo.row) {
                                            return {
                                                onClick: (e) => {
                                                    openAddEditWindow(rowInfo.row._original, false)
                                                }
                                            }
                                        } else {
                                            return {}
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
            </Grid>
            </div>


            
        )
    }

	return (
		<div className={classes.root}>
			{loading && 
				<Grid item xs style={{}}>
					<LinearProgress />
				</Grid>
			}
			{!loading && 
				<Grid container spacing={0} style={{width: '100%', height: '100%'}}>
                    <Grid item lg={12} >
                        {tableView()}
                    </Grid>
                    <Grid item lg={12} >
                        {dialogContent}
                    </Grid>
				</Grid>
			}
		</div>
    );
};

export default withStyles(styles, { withTheme: true })(RestaurantGroups);