import React, { useState } from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import star from '../../assets/images/star.png'
import Arrow from './Arrow'
import moment from 'moment'


export default function AverageFSA(props) {
	const initialFilter = () => Number(localStorage.filterDays) ||  7
	const [activeDateRange, setActiveDateRange] = useState(initialFilter)
	const [feedbackData] = global.get('feedbackData')

	const { foodScore, serviceScore, atmosphereScore, foodPercentScore, servicePercentScore, atmospherePercentScore } = getScores(feedbackData, activeDateRange)

	return (
		<Grid container style={styles.infoBanner}>
			<Paper style={styles.paper}>
				<table style={styles.tableWrap}>
					<tr>
						<td style={styles.starContainer}>
							<img src={star} style={styles.starImage} alt="Star" />
							<div style={styles.overlayText}>{foodScore}</div>
						</td>
						<td style={styles.starContainer}>
							<img src={star} style={styles.starImage} alt="Star" />
							<div style={styles.overlayText}>{serviceScore}</div>
						</td>
						<td style={styles.starContainer}>
							<img src={star} style={styles.starImage} alt="Star" />
							<div style={styles.overlayText}>{atmosphereScore}</div>
						</td>
					</tr>
					<tr>
						<td style={styles.starTitle}><Typography>Food</Typography></td>
						<td style={styles.starTitle}><Typography>Service</Typography></td>
						<td style={styles.starTitle}><Typography>Atmosphere</Typography></td>
					</tr>
					<tr>
						<td style={styles.starContainer2}><center><Arrow percent={foodPercentScore}/></center></td>
						<td style={styles.starContainer2}><Arrow percent={servicePercentScore}/></td>
						<td style={styles.starContainer2}><Arrow percent={atmospherePercentScore}/></td>
					</tr>
				</table>
			</Paper>
		</Grid>
	);
}

const getScores = (feedbackData, daysBack) => {
	let foodScore = 0, serviceScore = 0, atmosphereScore = 0
	let foodScore2 = 0, serviceScore2 = 0, atmosphereScore2 = 0
	let foodScoreCount = 0, serviceScoreCount = 0, atmosphereScoreCount = 0

	if (feedbackData && (Object.keys(feedbackData).length > 0)) {
		let scoresInRange = feedbackData.filter((a) => (moment(a.createdAt) > moment().subtract(daysBack, 'd')))
		if (scoresInRange && (scoresInRange.length > 0)) {
			let scoresOutOfRange = feedbackData.filter((a) => (moment(a.createdAt) < moment().subtract(daysBack, 'd')))

			scoresInRange.forEach(fback => {
				if (fback.ratings.food > 0) {
					foodScoreCount++
					foodScore += fback.ratings.food
				}
				if (fback.ratings.service > 0) {
					serviceScoreCount++
					serviceScore += fback.ratings.service
				}
				if (fback.ratings.atmosphere > 0) {
					atmosphereScoreCount++
					atmosphereScore += fback.ratings.atmosphere
				}
			})

			foodScore /= foodScoreCount // you may be asking, does this work? The answer: yes.
			serviceScore /= serviceScoreCount
			atmosphereScore /= atmosphereScoreCount

			foodScoreCount = 0
			serviceScoreCount = 0
			atmosphereScoreCount = 0
			scoresOutOfRange.forEach(fback => {
				if (fback.ratings.food > 0) {
					foodScoreCount++
					foodScore2 += fback.ratings.food
				}
				if (fback.ratings.service > 0) {
					serviceScoreCount++
					serviceScore2 += fback.ratings.service
				}
				if (fback.ratings.atmosphere > 0) {
					atmosphereScoreCount++
					atmosphereScore2 += fback.ratings.atmosphere
				}
			})

			foodScore2 /= foodScoreCount
			serviceScore2 /= serviceScoreCount
			atmosphereScore2 /= atmosphereScoreCount

			return { foodScore: foodScore.toFixed(1), serviceScore: serviceScore.toFixed(1), atmosphereScore: atmosphereScore.toFixed(1),
				foodPercentScore: !!((foodScore - foodScore2) / foodScore2 * 100) ? ((foodScore - foodScore2) / foodScore2 * 100).toFixed(2) : 0,
				servicePercentScore: !!((serviceScore - serviceScore2) / serviceScore2 * 100) ? ((serviceScore - serviceScore2) / serviceScore2 * 100).toFixed(2) : 0,
				atmospherePercentScore: !!((atmosphereScore - atmosphereScore2) / atmosphereScore2 * 100) ? ((atmosphereScore - atmosphereScore2) / atmosphereScore2 * 100).toFixed(2): 0,
			}
		}
	} 

	return { foodScore, serviceScore, atmosphereScore, 
		foodPercentScore: 0,
		servicePercentScore: 0,
		atmospherePercentScore: 0
	}

}

const styles = {
	infoBanner: {
		padding: 15,
		// paddingTop: 15
	},
	paper: {
		padding: '10px 5px 10px 15px',

		width: '100%',
		height: '100%'
	},
	tabRoot: {
		maxHeight: '35px',
		minHeight: '35px'
	},
	flexBox: {
		display: 'flex'
	},
	tableWrap: {
		width: '100%'
	},
	tabbedChart: {
		padding: '0 0 0 35px'
	},
	starContainer: {
		position: 'relative',
		textAlign: 'center',
		color: 'white'
	},
	starContainer2: {
		// width: '100%',
		textAlign: 'center',
	},
	overlayText: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		fontSize: '25px',
		fontWeight: 'bold'
	},
	starTitle: {
		textAlign: 'center'
	},
	starImage: {
		height: '4em'
	}
}
