import log from 'loglevel';
import dashFetchUpload from '../utils/dashFetchUpload';

export default async function (id, modelType, changesObj, debug) {
	return new Promise( async(resolve, reject) => {
        try {
            const url = `${global.dashServerUrl}/dashboard/api/v1/dashboard/upsertBranding`;
    
            const formData = new FormData();
            formData.append('id', id);
            formData.append('modelType', modelType); //'restaurant', 'restaurantGroup', 'agency'
    
            for (let [key, value] of Object.entries(changesObj)) {
                formData.append(key, value);
            }
    
            let response = await dashFetchUpload(url, formData)
    
            if (response.data.success) {
                global.showNotification('Branding updated successfully', 'success', 2000) 
                resolve(true);
            } else {
                global.showNotification('An error occurred, please try again', 'warning', 2000) 
                if (response.errors) log.info(response.errors)
                resolve(false)
            }
        }
        catch (e) {
            log.info(e)
            global.showNotification('An error occurred, please try again', 'warning', 2000) 
        }
	})
}