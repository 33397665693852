import log from 'loglevel';
import dashFetch from '../utils/dashFetch';


export default async function (restaurant, debug) {
	return new Promise((resolve, reject) => {
		log.info('>>> updateSiteConfig api');
		if (debug) log.info('updateSiteConfig api');
		const url = `${global.dashServerUrl}/api/v1/restaurant/updateSiteConfig`;

		const body = {
            restaurant,
            debug
		};

		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info('>>> updateSiteConfig JSON');
			log.info(json)
			if (json.success) {
				resolve(json);
			} else {
				resolve(false)
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}