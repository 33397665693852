import React, { useState, useEffect } from 'react';
import { Grid, Dialog, DialogActions, Button, Typography, Checkbox, DialogContent } from '@material-ui/core';
import upsertPermissionApi from '../../actions/upsertPermissionApi';
import DTextField from '../../components/dash/DTextField';
import DSwitch from '../../components/dash/DSwitch';
import log from 'loglevel'
import DialogTitle from '../../dashboard/components/DialogTitle';

const styles = {
	infoBanner: {
		padding: 5,
		paddingTop: 15
	},
	paper: {
		padding: 10,
		width: '100%',
		height: '100%'
	},
	table: {
	}
}


export default function (props) {
	// alert(JSON.stringify(props.permissions))
	const [permission, setPermission] = useState(props.permission)



	const upsertPermission = async (permission) => {
		let data = await upsertPermissionApi(permission)
		if (data) {
			window.location.reload()
		}
	}


	return (
		<Dialog
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={true}
			style={{ margin: 0, height: '100%', width: '100%' }}
			onClose={props.close}
		>
			{permission &&
				<>
				<DialogTitle onClose={ props.close} id="customized-dialog-title">
					NEW PERMISSION
				</DialogTitle>
				<DialogContent dividers>
					<Grid container style={{ width: '100%' }}>
						<Grid item style={{ padding: 20 }} lg={12}>
							<DTextField
								type="text"
								style={{ width: '45%' }}
								label="Name"
								value={permission.name}
								onChange={(e) => setPermission({ ...permission, name: e.target.value })}
							/>
							<DTextField
								type="text"
								style={{ width: '45%' }}
								label="Code"
								value={permission.code}
								onChange={(e) => setPermission({ ...permission, code: e.target.value })}
							/>
							<DTextField
								type="text"
								style={{ width: '45%' }}
								label="description"
								value={permission.description}
								onChange={(e) => setPermission({ ...permission, description: e.target.value })}
							/>
							<Typography
								variant="body"
								color="secondary"
							> Deleted:
								<DSwitch
									checked={permission.deleted}
									value={permission.deleted}
									onChange={(e) => setPermission({ ...permission, deleted: !permission.deleted })}
									label="deleted"
								/>
							</Typography>
						</Grid>
					</Grid>
				</DialogContent>
				</>
			}
			<DialogActions>
				<Button onClick={props.close}>
					Close
				</Button>
				<Button onClick={() => { upsertPermission(permission); }}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	)

}
