import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Tab, Tabs, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import ServerConfig from './serverConfig';
import AgencyListing from './agencyListing';
import PosListing from "./posListing";

import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import StorageIcon from '@material-ui/icons/Storage';
import GroupIcon from '@material-ui/icons/Group';

const styles = (theme) => ({
    root: {
    },
	addIcon: {
		float: 'left',
		marginTop: 5,
		marginRight: 5,
		width: '20px',
		height: '20px'
	},
	addButton: {
		float: 'right',
		marginLeft: 20,
		marginRight: 20,
		marginTop: 10,
		marginBottom: 10,
		width: 200,
		height: 45
    },
    tabs: {
        width: '100%',
        background: 'var(--background)',
		width: 60,
		borderRight: 'solid 1px #CCC',
		height: '100%',
    }
    
})

const Config = (props) => {
	const { classes } = props;
    const [activeTab, setActiveTab] = useState('Agencies')

    const subPages = [
        {
            title: 'POS Types',
            value: 'POS Types',
            icon: <DesktopWindowsIcon color={activeTab == 'POS Types' ? 'primary' : ''}/>,
            permission: global.adminPermissionCode,
            component: <PosListing/>
        },
        {
            title: 'Server Config',
            value: 'Server Config',
            icon: <StorageIcon color={activeTab == 'Server Config' ? 'primary' : ''}/>,
            permission: global.adminPermissionCode,
            component: <ServerConfig/>
        },
        {
            title: 'Agencies',
            value: 'Agencies',
            icon: <GroupIcon color={activeTab == 'Agencies' ? 'primary' : ''}/>,
            permission: 'admin.*',
            component: <AgencyListing/>
        },
        // {
        //     title: 'New tab here',
        //     value: 'Agencies',
        //     icon: <GroupIcon color={activeTab == 'Agencies' ? 'primary' : ''}/>,
        //     permission: 'admin.',
        //     component: <AgencyListing/>
        // },
    ]

    return (
        <div style={{width:'100%', height:'100%', textAlign:'center'}}>

            <Grid container spacing={0} style={{width: '100%', height: '100%'}}>
                <Grid item style={{width: 60}}>
                    <Tabs
                        orientation="vertical"
                        value={activeTab}
                        onChange={(e, n) => {
                            setActiveTab(n);
                        }}
                        className={classes.tabs}
                        indicatorColor='primary'
                    >	
                    
                        { subPages.map((item, index) => {
                            return (
                                <>
                                    { global.hasPermission(item.permission) &&
                                        <Tooltip title={item.title} onChange={(e, n) => {setActiveTab(n)}}>
                                            <Tab value={item.value} icon={item.icon} style={{minWidth: 0}} />
                                        </Tooltip>
                                    }
                                </>
                            )
                        })}

                    </Tabs>
                </Grid>
                <Grid container xs item style={{padding:14, height: '100%', overflowX: 'hidden', overflowY: 'auto'}}>

                    {subPages.map((item) => {
                        return (
                            <>
                                { global.hasPermission(item.permission) && activeTab == item.value && item.component }
                            </>
                        )
                    })}

                </Grid>
			</Grid>
        </div>
    );
};

Config.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withRouter(withStyles(styles, { withTheme: true })(Config));