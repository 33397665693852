import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, CircularProgress, Button, IconButton, Dialog } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import log from 'loglevel'
import _ from 'lodash';
import getRestaurantMenusApi from '../../actions/getRestaurantMenusApi';
import deleteRestaurantMenu from '../../actions/deleteRestaurantMenu';
import moment from 'moment';
import AddEditMenu from './addEditMenu';
import getRestaurantGroupMenusApi from '../../actions/getRestaurantGroupMenusApi';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import history from '../../history';
import theme from '../../theme'

export default function Menus (props) {
    const isMobile = useMediaQuery(theme().breakpoints.down('xs'));
    const isTablet = useMediaQuery('@media only screen and (max-device-height : 768px) and (max-device-width : 1024px)');
    const isMenusOnly = window.location.pathname.indexOf("/menus") == 0

    let [restaurant] = global.get('restaurant')
    const [group, setGroup] = useState(props.group ? props.group : null);
    const entity = group ? group : restaurant;

    let [showAddEdit, toggleAddEdit] = useState(false);
    let [isLoading, setLoading] = useState(false);
    let [menus, setMenus] = useState([])
    const restaurantWebsite = restaurant ? restaurant.website : '';
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    
    const fetchData = async () => {
        const entityId = entity ? entity._id : null;
        setLoading(true)
        let data;
        if (group) {
            data = await getRestaurantGroupMenusApi(entityId)
        } else { //restaurant
            data = await getRestaurantMenusApi(entityId);
        }
        if (!data) {
            setLoading(false)
            return
        }
        setMenus(data)
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [showAddEdit, restaurant]);

    const handleDelete = async (menuId) => {
        deleteRestaurantMenu(menuId).then((res)=> {
            fetchData()
        })    
    }

    const removeElementById = (id) => {    
        const el = document.getElementById(id)
        if (el && el.style) el.style.display = 'none'
    }

    const showPage = () => {
        return (
            <>
                {isMenusOnly || props.group && <div style={{height: isMenusOnly? '300px' : props.group ? '100px' : 0}}></div>}
                <div id="menuListContainer" 
                    style={{
                        overflowY:'auto', display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start', alignContent:'flex-start', alignSelf:'flex-start',
                        // maxHeight:!isMobile && isTablet ? '220px' : isMobile ? undefined : '400px', 
                        // borderBottom: (isTablet && !isMobile && menus.length > 2 || !isMobile && menus.length > 5 ) && 'solid 2px var(--dash-purple)', 
                    }}
                >
                    {menus && menus.length == 0 ? 

                        <div style={{display:'flex', justifyContent:'center', alignItems:'stretch', color:'var(--light-gray)'}}>
                            <h6><i>No menus are currently uploaded</i></h6>
                        </div>

                        : menus.map((menu, index) => {
                            return (
                                // MENU CARD
                                <div style={{padding:8, margin:'4px 0px 4px 0px', borderRadius:'4px', display:'flex', justifyContent:"space-between", alignItems:"center", width:'100%', flexDirection: isMobile? 'column':'row', border:'solid 1px #e2e8f0', backgroundColor:'var(--white)', height:!isMobile && '90px'}}>

                                    <div style={{textAlign:'center', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow:'hidden', width: isMobile ? '100%' : 150, justifyContent:'center', alignItems:'center'}}>
                                        <img id={`img-${index}`} src={menu.menuUrl} style={{height:'50px', margin: 'auto', filter:'drop-shadow(1px 1px 1px var(--dash-gray)'}}
                                            onError={()=>removeElementById(`img-${index}`)}
                                        >
                                        </img>
                                        <span style={{fontWeight:700, marginLeft:'8'}}>{menu && menu.menuUrl.split('/')[7] || menu.menuUrl}</span>
                                    </div> 

                                    <div style={{flexGrow:3, textAlign:'center'}}>
                                            {`${menu.startTime && menu.startTime !== 'undefined' ? 
                                            moment(menu.startTime, ['HH:mm']).format('h:mm A') : '00:00'} - 
                                            ${menu.endTime && menu.endTime !== 'undefined' ? moment(menu.endTime, ['HH:mm']).format('h:mm A') : '23:59'}`}
                                            <br/>
                                            <div style={{textAlign:'center', display:'inline-block'}}>
                                                {menu && menu.daysPicked && menu.daysPicked.map((day, index) => {
                                                    const abbrev = {
                                                        Monday: 'Mon',
                                                        Tuesday: 'Tues',
                                                        Wednesday: 'Wed',
                                                        Thursday: 'Thurs',
                                                        Friday: 'Fri',
                                                        Saturday: 'Sat',
                                                        Sunday: 'Sun'
                                                    }
                                                    return <span>{abbrev[day]}{index !== (menu.daysPicked.length - 1) && ', '}</span>
                                                })}
                                            </div>
                                    </div>
                                    <div style={{display:'flex', justifyContent:'center', flexGrow:1, textAlign:'center'}}>
                                        <span style={{fontStyle:'italic'}}>uploaded {moment.utc(menu.updatedAt).format("MM/DD")}</span>
                                    </div>
                                    <div style={{textAlign:'right', color:'#303030', flexGrow:0, display:'flex', flexDirection: props.group && 'column'}}>
                                        <IconButton><EditIcon onClick={()=>toggleAddEdit(menu)}/></IconButton>
                                        <IconButton><DeleteForeverIcon onClick={() => handleDelete(menu._id)}/></IconButton>
                                    </div>
                                </div>
                            )
                        }  
                    )}
                    <div style={{display:'flex', flexGrow:1, justifyContent:'center', alignItems:'center', margin:'auto', marginTop:8}}>
                        <Button variant="contained" color="primary" style={{margin: 'auto', textAlign:'start',}} onClick={()=> toggleAddEdit('newMenu')}>
                                <AddCircleIcon fontSize="small" style={{marginRight: 10, marginLeft: -2}} /> 
                                Add Menu
                        </Button>
                    </div>
                </div>
            </>
        )    
    }


    const showLoader = () => {
        return (
            <Grid container
                style={{height:'100%', width:'100%'}}
                justify="center"
                alignItems="center"
                direction="row"><Grid item ><CircularProgress/></Grid>
            </Grid>
        )
    }

    const showDialog = () => {
        return (
            <Dialog
                aria-labelledby="customized-dialog-title"
                aria-describedby="simple-modal-description"
                maxWidth={'md'}
                open={showAddEdit}
                style={{ margin: 0, height: '100%', width: '100%', index:99999}}
                onClose={ ()=>toggleAddEdit(!showAddEdit) }
            >
            <AddEditMenu menu={showAddEdit} restaurant={restaurant} group={props.group ? props.group : null}
                toggleAddEdit={
                    () => {
                        toggleAddEdit('')
                        fetchData()
                    }
                }
            />
            </Dialog>   
        )
    }

    if (props.hidePaper) {
        // AddEditRestaurantGroup
        return (
            <div style={{display:'flex', justifyContent:'center', alignItems:'flex-start'}}>
                {isLoading ? showLoader() : showPage()}
                {showAddEdit && showDialog()}
            </div>
        )
    } else {
        return (
            <div style={{display:'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection:'column', height:'100%', width:'100%', padding:4}}>
                <div style={{ width:'100%'}}>
                    {isLoading ? showLoader() : showPage()}
                    {showAddEdit && showDialog()}
                </div>
            </div>
        )
    }
};
