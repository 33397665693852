import React, { useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@material-ui/core";
import sendInStationSettingApi from "../../actions/sendInStationSettingApi";

export default (props) => {
    const [logs] = global.get('logs')
    const restaurantId = props.restaurantId
    const stationId = props.stationId
    const onClose = props.onClose

    useEffect(() => {
        let element = document.getElementById('dialoooooog-content')
        if (element) element.scrollTop = element.scrollHeight
    })

    useEffect(() => {
        sendInStationSettingApi(restaurantId, [stationId], "streamLogs.enabled", true);
        return () => {
            sendInStationSettingApi(restaurantId, [stationId], "streamLogs.enabled", false);
            global.set({logs: []})
        }
    }, []);

    return <Dialog
        fullScreen={true}
        open={true}
        onClose={onClose}
    >
        <DialogTitle>STREAM LOGS</DialogTitle>
        <DialogContent id="dialoooooog-content">
            <DialogContentText>
                <pre>
                    {logs && logs[stationId] ?
                        logs[stationId].join('\n') :
                        "It's so quiet"}
                </pre>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" color="primary" onClick={onClose}>Close</Button>
        </DialogActions>
    </Dialog>
}