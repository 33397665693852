import log from 'loglevel';
import dashFetch from '../utils/dashFetch';

export default async function (groupId, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('getRestaurantGroupMenusApi api');
		const url = `${global.dashServerUrl}/api/v1/restaurantGroup/getRestaurantGroupMenus`;

		const body = {
			groupId
		};

		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info('>>> getRestaurantGroupMenus JSON');
			if (json.success) {
				resolve(json.data);
			} else {
				resolve(false)
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}