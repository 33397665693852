import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Paper, Button, LinearProgress, Box, Container, CircularProgress} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import theme from '../../theme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import log from 'loglevel'
import _ from 'lodash';
import VerticalMenu from '../components/VerticalMenu';
import PaletteIcon from '@material-ui/icons/Palette';
import Branding from './branding';
import Menus from './menus';
import SiteConfigForm from '../siteConfigForm';
import IFrameDemo from '../components/iFrameDemo';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import CropFreeIcon from '@material-ui/icons/CropFree';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import QRCodes from './qrCodes';
import history from '../../history';
import Collapse from '@material-ui/core/Collapse';
import ContactForm from './contactForm';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

import AccountSetup from './accountSetup';

export default (props) => {
    let xs = useMediaQuery(theme().breakpoints.down('xs'));
    let sm = useMediaQuery(theme().breakpoints.down('sm'));
    let mediumScreen = useMediaQuery(theme().breakpoints.down('md'));
    const headerHeight = xs ? 26 : global.isDashNow() ? 88 : 35
    const activeButtonBarHeight = 0

    const useStyles = makeStyles((theme) => ({
        header: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: `${headerHeight}px`,
            zIndex: 11111,
        },
        content: {
            position: 'absolute',
            top: `${headerHeight}px`,
            bottom: `${activeButtonBarHeight}px`,
            left: 0,
            right: 0,
            overflow: 'auto',
            paddingTop: 30,
            paddingLeft: xs ? 0 : 20,
            paddingRight: xs ? 10 : 20,
            paddingBottom: xs ? 10 : 20,
        },
        italic: {
            color:'var(--dark-gray)',
            fontStyle:'italic'
        },
        
    }))

    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:600px)')
    const [show, setShow] = useState('branding')
    const [supportEmail, setSupportEmail] = useState('')
    const [supportPhone, setSupportPhone] = useState('')
    const [navBar] = global.get('navBar')
    const [restaurant] = global.get('restaurant')
    const [activePages] = global.get('activePages')
    const [payAtTableEnabled] = global.get('payAtTableEnabled')

    let menuItemsTmp = [
        {
            text: 'Branding',
            icon: <PaletteIcon />,
            clickAction: () => navigateTo('branding'),
            permission: 'restaurant.branding.*',
            value: 'branding',
            show: true
        },
        {
            text: 'Menus',
            icon: <RestaurantMenuIcon />,
            clickAction: () => navigateTo('menus'),
            permission: 'restaurant.branding.menu',
            value: 'menus',
            show: payAtTableEnabled
        },
        {
            text: 'Settings',
            icon: <SettingsIcon />,
            clickAction: () => navigateTo('settings'),
            permission: 'restaurant.branding.*',
            value: 'settings',
            show: true
        },
        {
            text: 'QR Codes',
            icon: <CropFreeIcon />,
            clickAction: () => navigateTo('qrcodes'),
            permission: 'restaurant.qrcodes',
            value: 'qrcodes',
            show: payAtTableEnabled
        },
        {
            text: 'Users',
            icon: <PeopleIcon />,
            clickAction: () => navigateTo('accounts'),
            permission: 'restaurant.users', 
            value: 'accounts',
            show: true
        },
        {
            text: 'Help',
            icon: <ContactSupportIcon />,
            clickAction: () => navigateTo('help'),
            permission: 'restaurant.branding.*', 
            value: 'help',
            show: true
        }
    ]
    
    const [menuItems, setMenuItems] = useState(menuItemsTmp)

    useEffect(()=> {
        if (navBar !== 'basic') {
            global.set({navBar: 'basic'})
        }
    }, [])

    useEffect(() => {
        // if (navBar != 'basic') global.set({navBar:'basic'})
        let activePage = history.location.pathname.replace('/', '');
        let vars
		if (activePage.indexOf('/') > -1) {
            vars = activePage.split('/')
            const page = vars.filter(v => v !== 'restaurantInfo')
            if (page && page.length > 0 && page[0] !== "" && show != page[0]) {
                setShow(page[0])
                global.set({activePages: { admin:'admin/restaurants/addEditRestaurant', basic:'restaurantInfo'}}) // this is hacky and i hate it
            }
        }
    }, [history.location.pathname, activePages]);

    const navigateTo = (page) => {
        history.replace(`/restaurantInfo/${page}`)
    }
    

    useEffect(() => {
        setMenuItems(menuItemsTmp)
        if (restaurant) {
            setSupportEmail(restaurant.getVal('branding.supportEmailAddress') ? restaurant.getVal('branding.supportEmailAddress') : restaurant.getVal('restaurantGroup.branding.supportEmailAddress') ? restaurant.getVal('restaurantGroup.branding.supportEmailAddress') : restaurant.getVal('agency.branding.supportEmailAddress') ? restaurant.getVal('agency.branding.supportEmailAddress') : 'support@dashnow.com')
            setSupportPhone(restaurant.getVal('branding.supportPhone') ? restaurant.getVal('branding.supportPhone') : restaurant.getVal('restaurantGroup.branding.supportPhone') ? restaurant.getVal('restaurantGroup.branding.supportPhone') : restaurant.getVal('agency.branding.supportPhone') ? restaurant.getVal('agency.branding.supportPhone') : '913-370-7577')
        }
    }, [restaurant])

	return (
        <div style={{width: '100%', height: '100%', backgroundColor: theme().palette.background.default, display:'flex'}}>
            <Grid container alignItems="stretch" direction={isMobile ? "column" : "row"} className={classes.content}>
                {restaurant && <>
                    <Grid item sm={2} style={{paddingRight: isMobile? 5 : 0, paddingBottom: isMobile? 5 : 0,}}>
                        {!isMobile && 
                            <Grid direction="column" alignItems="stretch" container style={{height: '100%'}}>
                                <Grid item xs style={{paddingRight:0}}>
                                    <VerticalMenu items={menuItems} selected={show}/>
                                </Grid>

                                <Grid item container alignItems="flex-end">
                                    <Grid item xs={12}>
                                        <Typography variant="h5" style={{color: 'var(--text)'}}>
                                            Having Trouble?
                                        </Typography>
                                        <Typography variant="h7" style={{color: 'var(--text)'}}>
                                            Contact our support team at <br/>
                                            <a style={{color: `var(--${global.branding})`}} href={`mailto:${supportEmail}`}>{supportEmail}</a><br/> or {supportPhone}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>

                
                    <Grid item xs sm={10}>

                        {!xs ?
                            <>
                                {show === 'branding' &&
                                        <Paper style={{height: '100%', marginBottom: 15, borderTopLeftRadius:0,}}>
                                            <Grid direction="column" alignItems="stretch" container style={{height: '100%'}}>
                                                <Grid xs item style={{overflowX: 'hidden', overflowY: 'auto', padding: isMobile? 0 : '25px 40px'}}>
                                                    <Typography variant="h6" color="primary">
                                                        Branding
                                                    </Typography>
                                                    <br/>
                                                    <Branding/>
                                                    <br/>
                                                    {!global.isTextPay() && <IFrameDemo/>}
                                                </Grid>
                                            </Grid>
                                        </Paper>    
                                }
                                {show === 'menus' &&
                                    <Paper style={{height: '100%', marginBottom: 15}}>
                                        <Grid direction="column" alignItems="stretch" container style={{height: '100%'}}>
                                            <Grid xs item style={{overflowX: 'hidden', overflowY: 'auto', padding: isMobile? 0 : '25px 40px'}}>
                                                <Typography variant="h6" color="primary">
                                                    Menus
                                                </Typography>
                                                <Menus/>
                                            </Grid>
                                        </Grid>
                                    </Paper>    
                                }
                                {show === 'settings' &&
                                    <Paper style={{height: '100%', marginBottom: 15}}>
                                        <Grid direction="column" alignItems="stretch" container style={{height: '100%'}}>
                                            <Grid xs item style={{overflowX: 'hidden', overflowY: 'auto', padding: isMobile? 0 : 15}}>
                                                <SiteConfigForm hideBranding/>
                                            </Grid>
                                        </Grid>
                                    </Paper>    
                                }
                                {show === 'qrcodes' &&
                                    <Paper style={{height: '100%', padding: isMobile? 0 : '25px 40px', marginBottom: 15}}>
                                        <Grid direction="column" alignItems="stretch" container style={{height: '100%'}}>
                                            <Grid xs item style={{overflowX: 'hidden', overflowY: 'auto'}}>
                                            <Typography variant="h6" color="primary">
                                                    QR Codes
                                                </Typography>
                                                <QRCodes/>
                                            </Grid>
                                        </Grid>
                                    </Paper>    
                                }
                                {show === 'accounts' &&
                                    <Paper style={{height: '100%', marginBottom: 15}}>
                                        <Grid direction="column" alignItems="stretch" container style={{height: '100%'}}>
                                            <Grid xs item style={{overflowX: 'hidden', overflowY: 'auto', padding: isMobile? 0 : '25px 40px'}}>
                                                <AccountSetup isAdmin={false}></AccountSetup>
                                            </Grid>
                                        </Grid>
                                    </Paper>    
                                }
                                {show === 'help' &&
                                    <Paper style={{height: '100%', marginBottom: 15}}>
                                        <Grid direction="column" alignItems="stretch" container style={{height: '100%'}}>
                                            <Grid xs item style={{overflowX: 'hidden', overflowY: 'auto', padding: isMobile? 0 : '25px 40px'}}>
                                                <Typography variant="h6" color="primary">
                                                    Contact Us
                                                </Typography>
                                                <ContactForm/>
                                            </Grid>
                                        </Grid>
                                    </Paper>    
                                }
                            </>

                            :
                            // MOBILE VIEW
                            <>  
                                {global.hasPermission('restaurant.branding.*') &&
                                    <div style={{padding:8}}>
                                    <Paper style={{height: '100%', padding: 15, borderTopLeftRadius:0,}}>
                                        <Typography variant="h6" color="primary">
                                            Branding
                                        </Typography>
                                        <span className={classes.italic}>
                                            These settings will affect how your customers notify you when they arrive.
                                        </span>
                                        <div style={{height:10, width:'100%'}}></div>
                                        <Branding />
                                    </Paper>    
                                </div>
                                }

                                {global.hasPermission('restaurant.branding.*') && !global.isTextPay() && // turned off preview for textpay. may take it off for all
                                <div style={{padding:8}}>
                                    <Paper style={{height: '100%', padding: 15, borderTopLeftRadius:0,}}>
                                        <Typography variant="h6" color="primary">
                                            Preview
                                        </Typography>
                                        <br/>
                                        <IFrameDemo height={400} width={200}/>
                                    </Paper>    
                                </div>
                                }
                                
                                {global.hasPermission('restaurant.branding.menu') && payAtTableEnabled &&
                                    <div style={{padding:8}}>
                                        <Paper style={{height: '100%', padding: 15}}>
                                            <Typography variant="h6" color="primary">
                                                Menus
                                            </Typography>
                                            <Menus/>
                                        </Paper>   
                                    </div>
                                }

                                {global.hasPermission('restaurant.branding.*') &&
                                    <div style={{padding:8}}>
                                        <Paper style={{height: '100%', padding: '5px 0px'}}>
                                            <SiteConfigForm hideBranding/>
                                        </Paper>    
                                    </div>
                                }

                                {global.hasPermission('restaurant.qrcodes') && payAtTableEnabled &&
                                    <div style={{padding:8}}>
                                        <Paper style={{height: '100%', padding: 15}}>
                                        <Typography variant="h6" color="primary">
                                                QR Codes
                                            </Typography>
                                        <QRCodes/>
                                        </Paper>    
                                    </div>
                                }

                                {global.hasPermission('restaurant.users') && 
                                    <div style={{padding:8}}>
                                        <Paper style={{height: '100%', padding: isMobile? 8 : 15}}>
                                            <div style={{padding:8}}>
                                                <AccountSetup isAdmin={false}></AccountSetup>
                                            </div>
                                        </Paper>
                                    </div>
                                }

                                <div style={{padding:8}}>
                                    <Paper style={{height: '100%', padding: isMobile? 8 : 15}}>
                                        <Typography variant="h6" color="primary">
                                            Contact US
                                        </Typography>
                                        <div style={{padding:8}}>
                                            <ContactForm/>
                                        </div>
                                    </Paper>
                                </div>    
                            </>
                        }
                    </Grid>
                </>}
            </Grid>
        </div>
	);
};