import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import { Grid, Dialog, DialogActions, DialogContent, Button, Typography, Checkbox, Input, FormControlLabel, FormGroup, MenuItem, Select } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import upsertProductPackageApi from '../../actions/upsertProductPackageApi';
import DialogTitle from '../../dashboard/components/DialogTitle';

import log from 'loglevel'

const styles = {
	infoBanner: {
		padding: 5,
		paddingTop: 15
	},
	paper: {
		padding: 10,
		width: '100%',
		height: '100%'
	},
	table: {
	}
}

export default function (props) {
	// alert(JSON.stringify(props.permissions))
	const [record, setRecord] = useState(props.record)
	const [products, setProducts] = useState(props.products)
	let [productPackages] = global.get('productPackages')
	let [productPackageTypes] = global.get('productPackageTypes')

	if (!record.products) {
        setRecord({...record, products: []})
	}

	const upsertRecord = async (record) => {
		try {
			record = await upsertProductPackageApi(record)
			let idx = _.findIndex(productPackages, {_id: record._id})
			if (idx > -1) {
				productPackages[idx] = record
			} else {
				productPackages.push(record)
			}
			global.set({productPackages: productPackages})
			props.close()
		} catch (err) {
			log.error(err)
		}
	}

	const selectProduct = (event) => {
		let selectedId = event.target.name
		let checked = event.target.checked
		if (selectedId) {
			if (checked) {
				let product = _.find(products, {_id: selectedId})
				if (product && record) {
					record.products.push(product)
					setRecord({...record})
				}
			} else {
				_.remove(record.products, {_id: selectedId})
				setRecord({...record})
			}
		}
	};

	return (
		<Dialog
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			maxWidth={'md'}
			open={true}
			style={{ margin: 0, height: '100%', width: '100%'}}
			onClose={ props.close }
		>
			{record && 
			<>	
				<DialogTitle>
					NEW PRODUCT PACKAGE
				</DialogTitle>
				<DialogContent dividers>
					<Grid container>
						<Grid item lg={12} style={{ padding: 20 }} container spacing={2}>
							<Grid item lg={6}>
								<Typography>Name</Typography>
								<Input
									style={{ width: '100%' }}
									value={record.name}
									onChange={(e) => setRecord({ ...record, name: e.target.value })}
								/>
							</Grid>

							<Grid item lg={4}>
								<Typography>Package Type</Typography>
								<Select
									label="Package Type"
									value={record.productPackageType}
									style={{ width: '100%' }}
									onChange={(e) => setRecord({ ...record, productPackageType: e.target.value })}>

									{productPackageTypes && productPackageTypes.map(ppt => {
										return (<MenuItem value={ppt}>{ppt.name}</MenuItem>)
									})}
								</Select>
							</Grid>

							<Grid item lg={2}>
								<Typography>Trial Period</Typography>
								<Input
									style={{ width: '100%' }}
									value={record.trialPeriodInDays}
									onChange={(e) => setRecord({ ...record, trialPeriodInDays: e.target.value })}
								/>
							</Grid>

							<Grid item lg={9}>
								<Typography>Invoice Label</Typography>
								<Input
									style={{ width: '100%' }}
									value={record.nameOnInvoice}
									onChange={(e) => setRecord({ ...record, nameOnInvoice: e.target.value })}
								/>
							</Grid>

							<Grid item lg={3} alignItems="center">
								<Typography>Show Products on Invoice</Typography>
								<Checkbox
									color="primary"
									checked={record.showProductsOnInvoice}
									onChange={(e) => setRecord({ ...record, showProductsOnInvoice: e.target.checked })}
								>
								</Checkbox>
							</Grid>

							<Grid item lg={12}>
								<Typography>Description</Typography>
								<Input
									style={{ width: '100%' }}
									value={record.description}
									onChange={(e) => setRecord({ ...record, description: e.target.value })}
								/>
							</Grid>

							<Grid item lg={12}>
								<Typography><strong>Select Products</strong></Typography>
								<FormGroup row>
									{products && products.map(product => {
										return (
											<FormControlLabel style={{width: '100%'}} control={<Checkbox checked={_.findIndex(record.products, {_id: product._id }) > -1} onChange={selectProduct} name={product._id} />} label={product.name + (product.description && product.description !== product.name ? " (" + product.description + ")" : '')} />
										)
									})}
								</FormGroup>
							</Grid>

							<Grid item lg={6}>
								<Typography>Effective Date</Typography>
								<Input
									type="date"
									style={{ width: '100%' }}
									value={record.effectiveDate}
									onChange={(e) => setRecord({ ...record, effectiveDate: e.target.value })}
								/>
							</Grid>

							<Grid item lg={6}>
								<Typography>Termination Date</Typography>
								<Input
									type="date"
									style={{ width: '100%' }}
									value={record.terminationDate}
									onChange={(e) => setRecord({ ...record, terminationDate: e.target.value })}
								/>
							</Grid>
							
						</Grid>
					</Grid>
				</DialogContent>
			</>}
			<DialogActions>
				<Button onClick={props.close}>
					Close
				</Button>
				<Button onClick={() => {upsertRecord(record);}}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	)

}
