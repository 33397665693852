import log from 'loglevel';
import dashFetch from '../utils/dashFetch';  


export default async function (restaurant, lastUpdatedCheckTime = null, lookbackInDays = null, lookbackDateRange = null, debug = false) {
	return new Promise((resolve, reject) => {
		log.info('>>> getRestaurantCheckListApi api');
		if (debug) log.info('getRestaurantCheckListApi api');
		const url = `${global.dashServerUrl}/dashboard/api/v1/dashboard/getRestaurantCheckList`;

		
		const body = {
			restaurant,
			lastUpdatedCheckTime,
			lookbackInDays,
			lookbackDateRange,
			debug
		};

		let options = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		}

		fetch(url, options, debug).then((res) => {
			if (res.ok) {
				res.json().then((json) => {
					if (json.success) {
						if (debug) log.info('success');
						log.info(json.data);
						resolve(JSON.parse(JSON.stringify(json.data)));

					} else {
						log.error(json.errors)
						resolve(false);
					}
				}).catch((err) => {
					reject(err.message);
				})
			} else {
				reject(res);
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}