import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import log from 'loglevel'
import theme from '../../theme';


export default function (props) {
    let cardTypeKeys = ["discover", "masterCard", "visa", "americanExpress"]
    let pricing = props.data
    let creditCardFees = pricing.getVal('creditCard')
    const priceType = creditCardFees.priceType // 'flat-rate' or 'interchange-plus

    const [groupCreditCardFees, setGroupCreditCardFees] = useState(true)
    let [renderData, setRenderData] = useState({})

    const checkIfCardFeesMatch = () => {
        let objA
        let objB
        cardTypeKeys.forEach((cardType, idx) => {
            if (idx == 0) {
                objA = creditCardFees[cardType]
            } else {
                objB = creditCardFees[cardType]
                if (!_.isEqual(objA, objB)) setGroupCreditCardFees(false)
            }
        })

    }

    useEffect(() => {
        checkIfCardFeesMatch()
        flattenDataToRender()
    }, [])

    const flattenDataToRender = () => { // turns price template into flattened list of elemtns with key and value {'Discover':"2.8%", 'Authorization Fee':".15¢"}
        
        let tmpData = {}
        let itemName
        let fee
        if (creditCardFees) {
            if (groupCreditCardFees) cardTypeKeys = ['discover'] // only render one row if they all match
            for (let key in creditCardFees) {
                let ccObj = creditCardFees[key]
                if (ccObj) {
                    if (cardTypeKeys.indexOf(key) > -1) { // creditCard fees
                        itemName = groupCreditCardFees ? "All Card Types" : _.startCase(key)
                        let discountFee = ccObj.discountFee
                        let transactionFee = ccObj.transactionFee ? ccObj.transactionFee.toFixed(2) : ''
                        fee = `${discountFee}%`
                        if (priceType == 'interchange-plus') fee = `Actual Cost + ${fee}`
                        if (transactionFee) fee += ` +  $${transactionFee} / transaction`

                    } else if (["authorizationFee", "avsFee"].indexOf(key) > -1) {
                        fee = `$${ccObj}`
                        itemName = _.startCase(key)
                    }
                    log.info(JSON.stringify(fee)) 
                    if (fee) {
                        tmpData[itemName] = fee
                    }
                }
            }                
        }

        if (pricing.scheduledFees) {
            pricing.scheduledFees.forEach(feeObj => {
                if (feeObj.period && feeObj.label && !isNaN(feeObj.amount)) {
                    itemName = feeObj.label
                    fee = `$${global.formatMoney(feeObj.amount)} / ${_.startCase(feeObj.period)}`
                    tmpData[itemName] = fee
                }
            })
        }
        // alert(JSON.stringify(tmpData))
        setRenderData(tmpData)
    }

    return (<>
        {creditCardFees &&
            <div style={props.style}>
                <div style={{top: 10, paddingLeft: 10, paddingRight: 10, width: 'auto', display: 'inline-block', position: 'relative'}}>
                    <span style={{fontSize:'1em', color:'var(--dash-purple)', backgroundColor: props.backgroundColor ? props.backgroundColor : "#f8f8f8", padding:5}}>Processing Fees</span>
                </div>
                <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column', padding: 12, paddingTop: 12, border: '1px solid lightgray', borderRadius:'5px'}}>
                    <span style={{fontSize:'2em', color:'var(--dash-purple)'}}>{pricing && pricing.name}</span>
                    <table style={{width:'100%', height:'100%'}}>
                        <thead style={{borderBottom:'1px solid lightgray'}}>
                            <td width="50%" style={{fontWeight:500}}>Item</td>
                            <td width="50%" style={{fontWeight:500}}>Fee</td>
                        </thead>
                        <tbody>
                        {Object.keys(renderData).map(key => {
                            return (
                                <tr>
                                    <td style={{paddingTop:8}}>{key}</td>
                                    <td style={{paddingTop:8}}>{renderData[key]}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        }
    </>)
}