import React, {useState, useEffect} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { Divider, makeStyles, IconButton, Grid, TableCell, Table, TableBody, TableRow, Slide, ButtonBase } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import moment from 'moment'
import log from 'loglevel'

import PaymentIcon from '@material-ui/icons/Payment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DashCarIcon from '../components/DashCarIcon'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import NotificationsIcon from '@material-ui/icons/Notifications';
import theme from '../../theme'
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {ReactComponent as StatusOpenIcon} from '../../assets/svgs/status/status-open.svg';
import {ReactComponent as StatusPaidIcon} from '../../assets/svgs/status/status-paid.svg';
import {ReactComponent as StatusClosedIcon} from '../../assets/svgs/status/status-closed.svg';
import {ReactComponent as StatusCancelledIcon} from '../../assets/svgs/status/status-cancelled.svg';


const useStyles = makeStyles(theme => ({
	root: {
		// borderRadius: 8,
		// minWidth: 200,
		textAlign: 'center',
		// backgroundColor: 'rgb(238, 238, 238)'
	},
	list: {
		padding: '20px',
	},
	button: {
		margin: theme.spacing(1),
	},
	action: {
		display: 'flex',
		justifyContent: 'space-around',
	},
}));


const DashCard = (props) => {
	const largeScreen = useMediaQuery(theme().breakpoints.up('lg'));
	const smallScreen = useMediaQuery(theme().breakpoints.down('sm'));
	const [data, setData] = useState(props.data)
	const [orderUpEnabled] = global.get('orderUpEnabled')
	const paymentDetail = !!props.paymentDetail
	const [textToPayEnabled] = global.get('textToPayEnabled')
	const [payAtTableEnabled] = global.get('payAtTableEnabled')

	let lastPaymentTime
	let tmpPayment
	if (data.payments) {
		tmpPayment = data.payments.sort((a, b) => moment(a.createdAt) - moment(b.createdAt))[0]
		if (tmpPayment) {
			lastPaymentTime = tmpPayment.createdAt
		}
	}

	let orderedAt = data.createdAt
	let paidAt = data.paidInFull && lastPaymentTime ? lastPaymentTime : ''
	let arrivedAt = data.pickupDetails.arrivedAt ? data.pickupDetails.arrivedAt : ''
	let completedAt = data.isToGo && data.pickupDetails.pickedUpAt ? data.pickupDetails.pickedUpAt : data.closedAt ? data.closedAt : ''
	let statusDate = orderedAt
	let [ statusDateStr, setStatusDateStr ] = useState(statusDate)

	useEffect(()=>{
		if (data !== props.data){
			setData(props.data)
		}
		let updatedStatus = ''
		if (data.status == 'paid') {
			updatedStatus = paidAt ? paidAt : statusDate
		} else if (data.status == 'arrived') {
			updatedStatus = arrivedAt ? arrivedAt : statusDate
		} else if (data.status == 'completed') {
			updatedStatus = completedAt ? completedAt : statusDate
		} else {
			updatedStatus = data.createdAt
		}
		setStatusDateStr(updatedStatus)
	}, [props.data, data.status, data.createdAt])

	let statusIcon = <AccessTimeIcon fontSize="small" style={{marginRight:'.4em', marginTop: '.1em'}} />
	if (data.status == 'ordered' && data.isToGo) {
		statusIcon = <PhoneIcon fontSize="small" style={{marginRight:'.4em', marginTop: '.1em'}} />
	} else if (data.status == 'paid') {
		statusIcon = <PaymentIcon fontSize="small" style={{marginRight:'.4em', marginTop: '.1em'}} />
	} else if (data.status == 'arrived') {
		// statusIcon = <DashCarIcon fontSize="small" style={{marginRight:'.4em', marginTop: '.1em'}} />
	} else if (data.status == 'completed') {
		statusIcon = <CheckCircleIcon fontSize="small" style={{marginRight:'.4em', marginTop: '.1em'}} />
	}

	let showPaymentDetail = !!props.showPaymentDetail // sending state in because XP doesnt like nested onClicks
	let classes = useStyles();

	let guestName = (data.getVal('pickupDetails.guestName', '').trim() && data.pickupDetails.guestName != 'undefined') ? data.pickupDetails.guestName : ""
	let customerName = (data.getVal('customer.firstName', '').trim()) ? data.customer.firstName + " " + data.customer.lastName : guestName

	let color = 'gray'
	if (data.status == 'ordered') {
		color = '#FFAE1B'
	} else if (data.totals && data.totals.paidDashNow > 0) {
		color = 'var(--dash-purple)'
	}

	function findTotalPaid(check) {
		const tip = (check.totals && check.totals.tipsDashNow) ? Number(((check.totals.tipsDashNow) / 100)).toFixed(2) : 0
		const paidDashNow = (check.totals && check.totals.paidDashNow) ? Number(((check.totals.paidDashNow) / 100)).toFixed(2) : 0
		const total = Number(paidDashNow) + Number(tip)
		return total;
	}

	return (
		<Slide in direction={props.slideDirection ? props.slideDirection : 'right'}>
			<ButtonBase style={{width:'100%'}} focusRipple>
				<Grid className={classes.root} style={{ width: '100%', border: 'none', borderTop: (props.hideTopBorder) ? '' : 'solid 1px #E5E5E5', borderBottom: 'solid 1px #E5E5E5', padding: '10px 14px 14px 10px', overflow: 'hidden'}}> 
					<Grid container direction="row" >
						<Grid item xs={1} style={{}} alignItems="center" container>
							{(data.status == 'ordered' || data.status == 'paid') && <StatusOpenIcon />}
							{data.status == 'arrived' && <StatusPaidIcon />}
							{data.status == 'completed' && <StatusClosedIcon />}
						</Grid>
						<Grid container direction="row" alignContent="flex-start" item xs={4}>
							{data.checkNumber &&
								<Typography align="left" style={{fontFamily: 'Roboto', fontSize: '20px'}}>
									{data.checkNumberDisplay ? data.checkNumberDisplay : data.checkNumber}
								</Typography>
							}
							{customerName && 
								<Grid container direction="row" alignContent="flex-start" justify="flex-start">
									<Typography align="left" style={{fontSize: data.checkNumber ? '16px' : '20px', color: 'var(--gray)'}}>
										{customerName}
									</Typography>
								</Grid>
							}
							<Grid container direction="row" alignContent="flex-start" justify="flex-start">
								{false && <PhoneIcon />}
								<Typography align="left" style={{fontSize: '16px', color: 'var(--gray)'}}>
									{(data.pickupDetails || data.customer.phone) && `${data.pickupDetails.phoneNumber ? global.formatPhoneNumber(data.pickupDetails.phoneNumber) : data.customer.phone ? global.formatPhoneNumber(data.customer.phone) : ''}`}
								</Typography>
							</Grid>
						</Grid>
						<Grid container direction="column" justify="center" alignItems="center" item xs>
							{ (data.pickupDetails.vehicleType || data.pickupDetails.pickupLocation) && <>
								{data.pickupDetails && data.pickupDetails.vehicleColor && data.pickupDetails.vehicleType &&
									<DashCarIcon data={data.pickupDetails} />
								}
								{data.pickupDetails && data.pickupDetails.vehicleType == 'other' && data.pickupDetails.otherDescription &&
									<Typography variant="subtitle1" style={{fontFamily: 'Roboto-Light', fontSize: '16px', color: 'var(--dark-gray)'}} align="center">
										{data.pickupDetails.otherDescription}
									</Typography>
								}
								{data.pickupDetails && data.pickupDetails.pickupLocation &&
									<Typography variant="subtitle1" style={{fontFamily: 'Roboto-Light', fontSize: '16px', color: 'var(--dark-gray)'}} align="center">
										{data.pickupDetails.pickupLocation}
									</Typography>
								}
							</>}
						</Grid>
						<Grid item container alignContent='center' justify='flex-start' xs={5}>
							<Grid item xs={12}>
								<Typography display="inline" style={{float: 'right', fontFamily: 'Roboto', fontSize: '30px'}}>{global.formatMoney(data.totals.total)}</Typography>
								<Typography display="inline" style={{float: 'right', fontFamily: 'Roboto', fontSize: '20px', marginTop: 4, marginRight: 2}}>$</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography align="right" style={{fontFamily: 'Roboto-Light', fontSize: '15px', color: 'var(--dark-gray)'}}>{moment(statusDateStr).fromNow()}</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</ButtonBase>
		</Slide>
	);
};


export default DashCard;