import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import theme from '../../theme'
import { Typography, Grid, Paper } from '@material-ui/core';

export default function RevenueChart(props) {
	let data = {
		labels: [
			'< $30',
			'< $20',
			'< $12',
			'> $50',
		],
			datasets: [{
				data: props.data,
				backgroundColor: [
					global.primaryColor,
					'#36A2EB',
					'#FFCE56',
					'#A3A3A3'
				],
				hoverBackgroundColor: [
					global.primaryColor,
					'#36A2EB',
					'#FFCE56',
					'#A3A3A3'
				]
			}]
	}

	const styles = {
		infoBanner: {
			width: '92%', //TODO
			padding: 15
		},
		paper: {
			padding: '.5em'
		}
	};

	return (
		<Grid style={styles.infoBanner}>
			<Paper style={styles.paper}>
				<Grid container>
					<Grid item lg={12}>
						<Typography align='center' variant="h5">Cover Breakdown</Typography>
						<Doughnut data={data} height="278" />
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
}
