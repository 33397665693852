import log from 'loglevel';	
import moment from 'moment';


export default async function (restaurantId, restaurantGroupId, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('exportRestaurantGroupInfo api');
		const url = `${global.dashServerUrl}/dashboard/api/v1/export/exportRestaurantGroupInfo`;

		const body = {
			restaurantId,
			restaurantGroupId
		};

		let options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		}

		let id = restaurantId ? restaurantId : restaurantGroupId
		id = id.replace("&", "").replace("'", "").replace("\"", "")
		let exportFileName = `${id}-weekly-${moment().format('DD-MM-YYYY')}.xlsx`

		fetch(url, options, debug)
		.then((res => res.blob()))
		.then(blob => {
			if (blob) {
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement('a');
				a.href = url;
				a.download = exportFileName;
				document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
				a.click();
				a.remove();  //afterwards we remove the element again         
				resolve(true)
			} else {
				reject(false)
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}