import { Grid } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import SiteHeader from './SiteHeader'
import SiteConfigForm from '../../dashboard/siteConfigForm'


export default function (props) {
   let site = props.site 

    return (<>
       <Grid container alignItems="flex-start">
           <SiteHeader site={site}/>
            <div class='content' style={{padding:10}}>
                <SiteConfigForm hideSplash agent/>
            </div>
        </Grid>
    </>)
}