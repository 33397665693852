import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import log from 'loglevel';
import _ from 'lodash';
import {
	Grid,
	Paper,
	Typography,
	Slider,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core';
import DTextField from '../../../components/dash/DTextField';
import { Alert } from '@material-ui/lab';

const styles = (theme) => ({
	root: {},
	paper: {
		padding: '1%'
	},
	explanation: {
		paddingBottom: '1%'
	},
	slider: {
		width: '80%',
		
	},
	bottomless: {
		borderBottom: 'none'
	}
});

const Dash = (props) => {
	const { classes, request, setRequest } = props;

	useEffect(() => {
	}, [])

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				{!request.data.restaurantGroup.productSelection.chosenLoyaltyProgram &&
					<Alert severity="info" color="warning">
						This information is only relevant if Loyalty is selected in Product Selection.
					</Alert>
				}
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>
								<Typography variant="body1" align="center">
									Sign Up Bonus
								</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="body1" align="center">
									Point to Cash Reward Conversion
								</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="body1" align="center">
									Reward Expiration
								</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell className={classes.bottomless}>
								<Typography variant="body2" className={classes.explanation}>
									As part of the loyalty program administered by Dash Now,
									restaurants are required to provide customers with an 
									incentive for enrolling into their loyalty program. Currently
									this incentive is a cash reward which can only be redeemed
									at one of your participating restaurants.

									Please enter the sign up bonus you would like to 
									reward your customers when they join the loyalty program.
								</Typography>
							</TableCell>
							<TableCell className={classes.bottomless}>
								<Typography variant="body2" className={classes.explanation}>
									As part of the loyalty program administered by Dash Now,
									customers are rewarded with 1 point for every $1 they spend at
									one of your restaurants. These points automatically convert
									into cash rewards which can only be redeemed at one of your
									participating restaurants.

									Please enter the cash amount you would like to award your customer
									for every 100 points they earn.
								</Typography>
							</TableCell>
							<TableCell className={classes.bottomless}>
								<Typography variant="body2" className={classes.explanation}>
									As part of the loyalty program administered by Dash Now, restaurants
									have the option to specify how long rewards last before they automatically
									expire if they haven't been redeemed. Please specify the time period you
									would like your rewards to expire after in yearly intervals.
								</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell className={classes.bottomless}>
								<Typography align="center">${request.data.loyaltyProgram.signUpRewardInDollars} dollars</Typography>
								<Grid container justify="center">
									<Slider
										className={classes.slider}
										step={1}
										min={1}
										max={10}
										value={request.data.loyaltyProgram.signUpRewardInDollars}
										onChange={(e,v) => setRequest(null, 'data.loyaltyProgram.signUpRewardInDollars', v)}
										marks={[{value: 5, label: '$5'}]}
									/>
								</Grid>
							</TableCell>
							<TableCell className={classes.bottomless}>
								<Typography align="center">100 Points convert to ${request.data.loyaltyProgram.cashRewardPerHundredPoints}</Typography>
								<Grid container justify="center">
									<Slider
										className={classes.slider}
										step={1}
										min={1}
										max={10}
										value={request.data.loyaltyProgram.cashRewardPerHundredPoints}
										onChange={(e,v) => setRequest(null, 'data.loyaltyProgram.cashRewardPerHundredPoints', v)}
										marks={[{value: 5, label: '$5'}]}
									/>
								</Grid>
							</TableCell>
							<TableCell className={classes.bottomless}>
								<Typography variant="body1" align="center">
									Rewards expire after {request.data.loyaltyProgram.rewardExpirationInYears} year{request.data.loyaltyProgram.rewardExpirationInYears > 1 ? 's' : ''}
								</Typography>
								<Grid container justify="center">
									<Slider 
										className={classes.slider}
										value={request.data.loyaltyProgram.rewardExpirationInYears}
										min={1}
										max={3}
										step={1}
										onChange={(e,v) => setRequest(null, 'data.loyaltyProgram.rewardExpirationInYears', v)}
										marks={[{value: 2, label:'2 years'}]}
									/>
								</Grid>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</Paper>
		</div>
	);
};

Dash.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withStyles(styles, { withTheme: true })(Dash);
