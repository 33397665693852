import React, { useState, useEffect } from 'react';
import { Grid, Dialog, DialogActions, Button, Typography, Popper, Checkbox, Table, TableHead, TableRow, TableCell, TableBody, DialogContent, Paper, Box, AppBar, Toolbar, Tabs, Tab, IconButton, Tooltip } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import flagCheckAsCompletedApi from '../../actions/flagCheckAsCompletedApi';
import log from 'loglevel'
import moment from 'moment'
import theme from '../../theme'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import alertCustomerForOrderUpApi from '../../actions/alertCustomerForOrderUpApi';
import undoFlagCheckAsCompletedApi from '../../actions/undoFlagCheckAsCompletedApi';
import reprintPaymentReceiptApi from '../../actions/reprintPaymentReceiptApi';
import RefundRequestWindow from './RefundRequestWindow';
import accessUtils from '../../utils/accessUtils';
import CloseIcon from '@material-ui/icons/Close';
import HistoryIcon from '@material-ui/icons/History';
import OrderDetailTable from './OrderDetailTable';
import { makeStyles } from '@material-ui/core/styles';
import PaymentsTable from './PaymentsTable';
import PickupDetails from './PickupDetails';
import NotificationsIcon from '@material-ui/icons/Notifications';
import InfoIcon from '@material-ui/icons/Info';
import GetApp from '@material-ui/icons/GetApp';
import getFeedbackDataApi from '../../actions/getFeedbackDataApi';
import downloadAttachmentApi from '../../actions/downloadAttachmentApi';
import DashCarIcon from './DashCarIcon';
import _ from 'lodash';
import FeedbackCard from './FeedbackCard';
import sendPaymentTextOnCheckApi from '../../actions/sendPaymentTextOnCheckApi';
import cancelCheckApi from '../../actions/cancelCheckApi';

const useStyles = makeStyles((theme) => ({
    root: {
        color: 'var(--dark-gray)'
    },
    tableHeader: {
        fontSize: '12px',
        lineHeight: '1.1'
    },
    sectionHeader: {
        fontSize: '16px',
        padding: '6px 8px 8px 8px'
    },
    typography: {
        fontSize: '14px'
    },
    pickupTableCell: {
        borderBottom: '0px solid white'
    }
}));

export default function (props) {
    const classes = useStyles()
    // alert(JSON.stringify(props.permissions))
    const [check, setCheck] = useState(props.check)
    const [checkId, setCheckId] = useState(check._id)
    const [checks] = global.get('checks')
    const [orderUpEnabled] = global.get('orderUpEnabled')
    const [orderTerminology] = global.get('orderTerminology')
    const [imHereEnabled] = global.get('imHereEnabled');
    const [payAtTableEnabled] = global.get('payAtTableEnabled');
    const [emailToPayEnabled] = global.get('emailToPayEnabled')
    const [restaurant] = global.get('restaurant')
    const [showPaymentDetail, setShowPaymentDetail] = useState(false)
    const [windowContent, setOverlayWindowContent] = useState()
    const [showFeedbackModal, setShowFeedbackModal] = useState(false)
    const [checkHistoryPopperAnchor, setCheckHistoryPopperAnchor] = useState(null)
    const awaitingACHVerification = check && check.awaitingACHVerification

    const isTextPay = global.isTextPay()
    let signedIn = accessUtils.isLoggedIn()

    let [feedback, setFeedback] = useState()
    let [comment, setComment] = useState()

    useEffect(() => {
        let checkMatch = checks.find(c=> c._id == checkId)
        if (checkMatch) {
            setCheck(checkMatch)
        }
    }, [checks])
    
    const hasCustomerPhone = (check.customer && check.customer.phone) || (check.pickupDetails && check.pickupDetails.phoneNumber)
    const hasCustomerContact = (hasCustomerPhone || (isTextPay && check.getVal('customer.email')))
    const isComplete = (check.status == 'completed')
    
    let tmpPayment
    let tipTotal = 0
    let loyaltyTotal = 0
    let taxesTotal = 0
    let dashTotal = 0
    let lastPaymentTime

    if (check.payments) {
        check.payments.filter(p => (p.fromDash && p.successful)).forEach(payment => {
            loyaltyTotal += payment.loyaltyRewardsAmount
            taxesTotal += payment.tax
            tipTotal += payment.tipAmount
            dashTotal += payment.paymentAmount
        })
        tmpPayment = check.payments.filter((p) => p.successful).sort((a, b) => moment(a.createdAt) - moment(b.createdAt))[0]
        if (tmpPayment) {
            lastPaymentTime = tmpPayment.createdAt
        }
        loyaltyTotal = Number(loyaltyTotal / 100).toFixed(2)
        taxesTotal = Number(taxesTotal / 100).toFixed(2)
        tipTotal = Number(tipTotal / 100).toFixed(2)
        dashTotal = Number(dashTotal / 100).toFixed(2)
    }

    let paidAt = lastPaymentTime ? moment(lastPaymentTime).fromNow() : 'N/A'
    let paidTime = lastPaymentTime ? moment(lastPaymentTime).format('M/DD @ h:mma') : 'N/A'
    
    const smallScreen = useMediaQuery(theme().breakpoints.down('sm'));
    const xsSmallScreen = useMediaQuery(theme().breakpoints.down('xs'));
    const isMobile = useMediaQuery('(max-width:600px)')
    console.log("smallScreen: " + smallScreen)
    const shortScreen = useMediaQuery('(max-height:800px)')

    const sendPickedUp = async (check) => {
        let data = await flagCheckAsCompletedApi(check._id)
        if (data) {
            props.close()
        }
    }

    const sendOrderUp = async (check) => {
        let data = await alertCustomerForOrderUpApi(check._id)
        if (data) {
            props.close()
        }
    }

    const sendPaymentTextOnCheck = async (check) => {
        let data = await sendPaymentTextOnCheckApi(check._id)
        if (data) {
            props.close()
            global.showNotification("Text Sent", 'success')
        }
    }

    const sendUndoComplete = async (check) => {
        let data = await undoFlagCheckAsCompletedApi(check._id)
        if (data) {
            props.close()
        }
    }

    const cancelCheck = async (check) => {
        let data = await cancelCheckApi(check._id)
        if (data) {
            props.close()
        }
    }

    const reprintPaymentReceipt = async (check) => {
        let data = await reprintPaymentReceiptApi(check)
        if (data) {
            props.close()
        }
    }

    const showRefundWindow = async (check, payment) => {
        setOverlayWindowContent(
            <RefundRequestWindow check={check} close={closeOverlayWindow} payment={payment} />
        );
    }

    const closeOverlayWindow = () => {
        setOverlayWindowContent(null);
    };

    const fetchData = async () => {
        if (restaurant && feedbackEnabled && !check.isToGo) {
            const allFeedback = await getFeedbackDataApi(restaurant._id)
            const checkFeedback = allFeedback.filter(data => data.check.id.includes(check.id))
            if (checkFeedback.length) {
                setComment(checkFeedback[0].comment)
                setFeedback(checkFeedback[0])
            }
        }
    }

    useEffect(() => {
        fetchData()
    }, [restaurant, check]);

    const feedbackEnabled = global.f(restaurant, 'config.features.feedback.enabled', false) && signedIn && (!paidTime || (paidTime && dashTotal > 0))

    let color = 'gray'
    if (check.status == 'ordered') {
        color = '#FFAE1B'
    } else if (check.totals && check.totals.paidDashNow > 0) {
        color = 'var(--dash-purple)'
    }

    if (global.hasPermission('admin.*')) {
        document.addEventListener('keypress', (e) => {
            if(e.key == 'ç'){
                navigator.clipboard.writeText(`_id: ObjectId("${check._id}")`);
                global.showNotification("Copied CheckId", "success")
            }
        })
    }


    const viewCheckInWeb = () => {
        let url = global.generateWebUrl(restaurant.appName, restaurant.whiteLabelCode)
        window.open(`${url}/?c=${check._id}${(restaurant && restaurant.code) ? (restaurant.branding.splashLogo) ? "&z=1-" + restaurant.code : "&z=0-" + restaurant.code : ""}&fromDashboard=1`, '_blank')
    }

    let checkHistoryRows = []
    checkHistoryRows.push({time: moment(check.createdAt), label: 'Created'})
    if (check.textDeliveredTime) {
        checkHistoryRows.push({time: moment(check.textDeliveredTime), label: 'Text delivered'})
    } else if (check.textSendTime) {
        checkHistoryRows.push({time: moment(check.textSendTime), label: 'Text sent'})
        if (check.textDeliveryFailed) {
            checkHistoryRows.push({time: moment(check.textSendTime).add(1, 'second'), label: 'Text delivery failed'})
        }
    }
    if (check.emailDeliveredTime) {
        checkHistoryRows.push({time: moment(check.emailDeliveredTime), label: 'Email delivered'})
    } else if (check.emailSendTime) {
        checkHistoryRows.push({time: moment(check.emailSendTime), label: 'Email sent'})
        if (check.emailDeliveryFailed) {
            checkHistoryRows.push({time: moment(check.emailSendTime).add(1, 'second'), label: 'Email delivery failed'})
        }
    }
    if (check.textOnHoldUntil && !check.emailSendTime && !check.textSendTime) {
        if (moment(check.textOnHoldUntil).isAfter(moment().subtract(1, 'years'))) {
            if (check.customer.email && check.customer.phone) {
                checkHistoryRows.push({time: moment(check.textOnHoldUntil), label: 'Email and text on hold'})
            } else if (check.customer.email) {
                checkHistoryRows.push({time: moment(check.textOnHoldUntil), label: 'Email on hold'})
            } else if (check.customer.phone) {
                checkHistoryRows.push({time: moment(check.textOnHoldUntil), label: 'Text on hold'})
            }
        } else {
            if (check.customer.email && check.customer.phone) {
                checkHistoryRows.push({time: moment(check.textOnHoldUntil), label: 'Email and text are scheduled to be sent'})
            } else if (check.customer.email) {
                checkHistoryRows.push({time: moment(check.textOnHoldUntil), label: 'Email is scheduled to be sent'})
            } else if (check.customer.phone) {
                checkHistoryRows.push({time: moment(check.textOnHoldUntil), label: 'Text is scheduled to be sent'})
            }
        }
    }
    if (check.viewedByUserAt) {
        checkHistoryRows.push({time: moment(check.viewedByUserAt), label: 'Check viewed'})
    }
    if (check.payments) {
        for (let payment of check.payments) {
            if (payment.successful) {
                checkHistoryRows.push({time: moment(payment.createdAt), label: `Paid $${global.formatMoney(payment.paymentAmount)}`})
            }
        }
    }
    if (check.cancelled && check.cancelledAt) {
        checkHistoryRows.push({time: moment(check.cancelledAt), label: 'Cancelled'})
    } else if (check.closed && check.closedAt) {
        checkHistoryRows.push({time: moment(check.closedAt), label: 'Closed'})
    }
    checkHistoryRows = checkHistoryRows.sort((a, b) => a.time < b.time ? -1 : 1)

    return (
        <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={true}
            onClose={props.close}
            PaperProps={{ style: {minWidth: isMobile? '97%' : '80vw'}}}
        >
            <MuiDialogTitle
                id="customized-dialog-title"
                style={{ backgroundColor: color, width: '100%', color: 'var(--white)', margin: 0, borderRadius: '0px', padding:12}}>
                <div style={{display:'flex', justifyContent:'space-between', alignItems: 'center'}}>
                    <Typography variant="h6" style={{paddingLeft:4, maxWidth:'50%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{check.orderNumber ? check.orderNumber : (check.checkNumberDisplay ? check.checkNumberDisplay : check.checkNumber) } {!check.closed && dashTotal > 0 &&<NotificationsIcon fontSize="small" />}{check.attachment ? <IconButton><GetApp style={{color:'var(--white)'}} onClick={() => downloadAttachmentApi(check)}/></IconButton>: ''}</Typography>
                    <div style={{display:'flex', justifyContent:'flex-start', padding: '0px 6px'}}>
                        <Button variant="text" style={{color:'white'}} onClick={() => viewCheckInWeb()}>View as Customer</Button>
                        {/* <Button variant="text" style={{color:'white'}}>View in Support</Button> */}
                    </div>
                    <IconButton style={{ fontSize: '1.2em', marginRight:-8}} onClick={props.close}>
                        <CloseIcon style={{ color: 'white' }} />
                    </IconButton>
                </div>

            </MuiDialogTitle>

            <DialogContent style={{ backgroundColor: 'var(--background)', padding:0, overflow: isMobile || smallScreen|| shortScreen? 'auto' : 'hidden', height:'100%' }} >
                <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', width: '100%', height:'100%'}} id="detailscontainer">
                    {/* TOP STATUS BAR */}
                    <Grid container direction="row" item spacing={1} justify="flex-start" alignItems="center" xs={12}
                        style={{ backgroundColor: 'var(--white)', minHeight: '100px', width: '100%', padding: isMobile? 5 : '14px 10px 10px 10px', borderBottom: '1px solid rgb(220,220,220)', margin:0, position: 'relative'}}
                    >

                        {!isTextPay &&
                            <Grid item xs={6} sm={3}>
                                Order Type: <span style={{ color: 'var(--dash-purple)' }}>{check.isToGo ? 'To-go' : 'Dine-in'}</span>
                            </Grid>
                        }
                        <Grid item xs={6} sm={3}>
                            Opened: <span style={{ color: 'var(--dash-purple)' }}>{check.createdAt ? moment(check.createdAt).format('M/DD @ h:mma') : 'N/A'}</span>
                        </Grid>
                        {payAtTableEnabled && <>
                            <Grid item xs={6} sm={3}>
                                Table #: <span style={{ color: 'var(--dash-purple)' }}>{check.tableNumber ? check.tableNumber : 'N/A'}</span>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                Server: <span style={{ color: 'var(--dash-purple)' }}>{global.f(check, "waiter.firstName", false) || global.f(check, "waiter.lastName", false) ? global.f(check, "waiter.firstName", "") + " " + global.f(check, "waiter.lastName", "") : (check.serverIdentifier ? check.serverIdentifier : 'N/A')}</span>
                            </Grid>
                        </>}
                        {!payAtTableEnabled &&
                            <Grid item xs={6} sm={3}>
                                Created By: <span style={{ color: 'var(--dash-purple)' }}>{global.f(check, "createdBy", false) ? global.f(check, "createdBy.firstName", "") + " " + global.f(check, "createdBy.lastName", "") : "N/A"}</span>
                            </Grid>
                        }
                        <Grid item xs={6} sm={3}>
                            Paid: <span style={{ color: 'var(--dash-purple)' }}>{paidTime}</span>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {check.cancelled ? "Cancelled:" : "Closed:"} <span style={{ color: 'var(--dash-purple)' }}>{check.closedAt ? moment(check.closedAt).format('M/DD @ h:mma') : 'N/A'}</span>
                        </Grid>
                        {check.textOnHoldUntil && !check.textSendTime && !check.emailSendTime ?
                            <Grid item xs={6} sm={3}>
                                On hold until:<span style={{ color: `var(--${global.branding})` }}>{check.textOnHoldUntil ? moment(check.textOnHoldUntil).format('MM/DD/YYYY @ h:mm a') : 'N/A'}</span>
                            </Grid>
                            :
                            <>
                                {check.textDeliveryFailed ?
                                    <Grid item xs={6} sm={3}>
                                        Text Delivery Failed
                                    </Grid>
                                    :
                                    check.textDeliveredTime ?
                                        <Grid item xs={6} sm={3} >
                                            Text Delivered: <span style={{ color: `var(--${global.branding})` }}>{check.textDeliveredTime ? moment(check.textDeliveredTime).format('M/DD @ h:mma') : 'N/A'}</span>
                                        </Grid>
                                        :
                                        <Grid item xs={6} sm={3}>
                                            Text Sent: <span style={{ color: `var(--${global.branding})` }}>{check.textSendTime ? moment(check.textSendTime).format('M/DD @ h:mma') : 'N/A'}</span>
                                        </Grid>}
                                {check.emailDeliveryFailed ?
                                    <Grid item xs={6} sm={3}>
                                        Email Delivery Failed
                                    </Grid>
                                    :
                                    check.emailDeliveredTime ?
                                        <Grid item xs={6} sm={3} >
                                            Email Delivered: <span style={{ color: `var(--${global.branding})` }}>{check.emailDeliveredTime ? moment(check.emailDeliveredTime).format('M/DD @ h:mma') : 'N/A'}</span>
                                        </Grid>
                                        :
                                        <Grid item xs={6} sm={3}>
                                            Email Sent: <span style={{ color: `var(--${global.branding})` }}>{check.emailSendTime ? moment(check.emailSendTime).format('M/DD @ h:mma') : 'N/A'}</span>
                                        </Grid>
                                }
                            </>
                        }
                        <Grid item xs={6} sm={3}>
                            Check Viewed: <span style={{ color: `var(--${global.branding})` }}>{check.viewedByUserAt ? moment(check.viewedByUserAt).format('M/DD @ h:mma') : 'N/A'}</span>
                        </Grid>
                        <div style={{position: 'absolute', top: 0, right: 0}}>
                            <div 
                                // onClick={(e) => setCheckHistoryPopperAnchor(checkHistoryPopperAnchor ? null : e.currentTarget)} 
                                onMouseEnter={(e) => {log.info('entered'); setCheckHistoryPopperAnchor(document.getElementById('history-icon'))}} 
                                onMouseLeave={(e) => {log.info('left'); setCheckHistoryPopperAnchor(null)}} 
                                style={{padding: 16}}>
                                <HistoryIcon id="history-icon"></HistoryIcon>
                                <Popper container={document.getElementById('detailscontainer')} placement='bottom-end' open={!!checkHistoryPopperAnchor} anchorEl={checkHistoryPopperAnchor}>
                                    <Paper style={{padding: 16, minWidth: 250}}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <span style={{paddingRight: 8}}><HistoryIcon></HistoryIcon></span>
                                            <span>History</span>
                                        </div>
                                        <div style={{paddingTop: 8}}>
                                            {checkHistoryRows.map((row) => 
                                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <span style={{paddingRight: 16}}>{row.time > moment().subtract(1, 'hours') ? row.time.fromNow() : row.time.format('MM/DD hh:mm a')}</span>
                                                    <span>{row.label}</span>
                                                </div>)}
                                        </div>
                                    </Paper>
                                </Popper>
                            </div>
                        </div>
                    </Grid>

                    <div style={{width: '100%', height:'calc(100% - 100px)', display:'flex', flexDirection: isMobile || smallScreen ? 'column' : 'row', alignItems:'stretch', padding:4, overflowY:'scroll'}}>
                        {/* ORDER DETAIL */}
                        <div style={{flexGrow:2, padding: 4}}>
                            <Paper style={{ padding: 0}}>
                                <Typography className={classes.sectionHeader}>
									{global.f(restaurant, 'config.features.textToPay.orderTerminology', "Order")} Detail
                                </Typography>
                                <OrderDetailTable data={check} />
                            </Paper>
                        </div>


                        <div style={{flexGrow:3, display:'flex', height:'100%', flexDirection:'column'}}>
                            {/* PAYMENTS */}

                            <div style={{padding:4, flexGrow:4}}>
                                <PaymentsTable data={check} showRefundWindow={showRefundWindow} showLoyalty={loyaltyTotal > 0} fullHeight={!(feedbackEnabled || hasCustomerContact)}/>
                            </div>

                            {(feedbackEnabled || hasCustomerContact) &&

                                <div onClick={()=> {if(feedback) setShowFeedbackModal(true)}} style={{flexGrow:2, padding:4}}>
                                    <Paper style={{padding: 8 }}>
                                        <>
                                            {/* PICKUP */}
                                            {hasCustomerContact ?
                                                <div style={{minHeight: isMobile? 60 : 150}}>
                                                    <PickupDetails data={check}/>
                                                </div>
                                                :
                                                    <>
                                                        {/* FEEDBACK */}
                                                        <div>
                                                            <div>
                                                                <Typography className={classes.sectionHeader} style={{ padding: '0px', marginBottom: '14px' }}>
                                                                    Feedback
                                                                </Typography>
                                                                {feedbackEnabled && feedback ?
                                                                    <Grid item xs={12}>
                                                                        <FeedbackCard checkDetails shortenComments data={feedback}/>
                                                                    </Grid>
                                                                :
                                                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', minHeight: isMobile? 60 : 150, width:'100%'}}>
                                                                        <Typography variant="body2" style={{ padding: '0px', marginBottom: '14px', fontStyle: 'italic', color: 'var(--dark-gray)' }}>
                                                                            No feedback has been left for this check
                                                                        </Typography>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        {showFeedbackModal &&
                                                            <Dialog
                                                                aria-labelledby="simple-modal-title"
                                                                aria-describedby="simple-modal-description"
                                                                open={true}
                                                                style={{margin: 'auto', padding:'16px'}}
                                                                onClose={ ()=> setShowFeedbackModal(false) }
                                                                aria-labelledby="customized-dialog-title"
                                                            >
                                                                <DialogContent dividers style={{padding: 16, display:'flex', justifyContent:'center', alignItems:'center', minHeight:100, minWidth:200, wordBreak:'break-all', flexDirection:'column'}}>
                                                                    <div style={{fontSize:16, width:'100%', textAlign:'left', marginBottom:8}}>
                                                                        Feedback
                                                                    </div>
                                                                    <FeedbackCard checkDetails data={feedback}/>
                                                                </DialogContent>
                                                            </Dialog>
                                                        }
                                                    </>
                                            }
                                        </>
                                    </Paper>
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </DialogContent>

            
            <DialogActions style={{ borderTop: '1px solid var(--divider)', padding: '10px 8px', minHeight: '60px' }}>
                <Grid container height="100%">
                    <Grid style={{ padding: 5 }} item xs={4} sm={6}>
                        {!isTextPay && dashTotal > 0 &&
                            <Button style={{ height: 'auto', float: 'left' }} color="primary" variant="outlined" onClick={() => { reprintPaymentReceipt(check); }}>
                                {!smallScreen ?
                                    <span>Reprint Payment Slip</span>
                                    :
                                    <span>Reprint</span>
                                }
                            </Button>
                        }

                        {isTextPay && dashTotal <= 0 && !check.cancelled &&
                            <Button style={{ height: 'auto', float: 'left' }} color="primary" variant="outlined" onClick={() => { cancelCheck(check); }}>
                                {!smallScreen ?
                                    <span>Cancel {orderTerminology}</span>
                                    :
                                    <span>Cancel</span>
                                }
                            </Button>
                        }
                    </Grid>

                    <Grid style={{ padding: 5, textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }} item xs={8} sm={6}>
                        {!isComplete && orderUpEnabled && hasCustomerContact &&
                            <Button style={{ height: '36px', marginRight: 12, fontSize: smallScreen ? '12px' : '14px' }} color="primary" variant="contained" onClick={() => { sendOrderUp(check); }}>
                                {!smallScreen ?
                                    <span>{orderTerminology} Ready ({check.pickupDetails.orderUpAt ? "Re-notify":"Notify"} Customer)</span>
                                    :
                                    <span>{orderTerminology} Ready {check.pickupDetails.orderUpAt ? "(Again)":""}</span>
                                }
                            </Button>
                        }

                        {isTextPay && !isComplete && (hasCustomerContact || !restaurant.backOffice) && !check.paidInFull && 
							<Button style={{ height: '36px', marginRight: 12, width:'fit-content' }} color="primary" variant="contained" onClick={() => { sendPaymentTextOnCheck(check); }}>
                                <span style={{minWidth:'max-content'}}>{!check.textSendTime && !check.emailSendTime ? "Send": "Re-send"}</span>
							</Button>
						}

                        {!isComplete && (hasCustomerContact || !restaurant.backOffice) && (!isTextPay || (dashTotal > 0)) &&
							<Button style={{ height: '36px' }} color="primary" variant="contained" onClick={() => { sendPickedUp(check); }}>
                                {imHereEnabled ? "Picked Up" : isTextPay ? "Close" : "Complete"} {!smallScreen && isTextPay && !imHereEnabled ? orderTerminology : ""}
							</Button>
						}
						{isComplete && (hasCustomerContact || !restaurant.backOffice) && !check.cancelled &&
							<Button style={{ height: '36px' }} color="primary" variant="contained" onClick={() => { sendUndoComplete(check); }}>
								 Undo {imHereEnabled ? "Picked Up" : isTextPay ? "Close" : "Complete"}
							</Button>
						}
                    </Grid>
                </Grid>
            </DialogActions>
            {windowContent}
        </Dialog>
    )

}
