import log from 'loglevel';
import dashFetch from '../utils/dashFetch';


export default async function (restaurantCheckInId, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('cancelCheckIn api');
		const url = `${global.dashServerUrl}/dashboard/api/v1/dashboard/cancelCheckIn`;

		const body = {
            restaurantCheckInId,
			debug
		};

		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info('>>> cancelCheckIn JSON');
			log.info(json)
            resolve(json.success);
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}