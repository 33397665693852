import log from 'loglevel';
import dashFetch from '../utils/dashFetch';


export default async function (options, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('getSignupList api');
		const url = `${global.dashServerUrl}/dashboard/api/v1/signup/getSignupList`;

		const body = {
            options,
		};

		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info('>>> getSignupList JSON');
			log.info(json)
			if (json.success) {
				resolve(json.data);
			} else {
				resolve(false)
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}