import React, { useState, useEffect } from 'react';
import { Grid, Dialog, DialogActions, Button, Typography, Checkbox, DialogContent } from '@material-ui/core';
import upsertRoleApi from '../../actions/upsertRoleApi';
import DTextField from '../../components/dash/DTextField';
import DSwitch from '../../components/dash/DSwitch';
import log from 'loglevel'
import DialogTitle from '../../dashboard/components/DialogTitle';

const styles = {
	infoBanner: {
		padding: 5,
		paddingTop: 15
	},
	paper: {
		padding: 10,
		width: '100%',
		height: '100%'
	},
	table: {
	}
}


export default function (props) {
	// alert(JSON.stringify(props.permissions))
	const [role, setRole] = useState(props.role)
	const [permissions, setPermissions] = useState(props.permissions)


	const upsertRole = async (role) => {
		let data = await upsertRoleApi(role)
		if (data) {
			window.location.reload()
		}
	}

	const addRemovePermission = (permission) => {
		// alert('permission: ' + JSON.stringify(role.permissions))

		let newPermissions = []
		if (role.permissions) {
			newPermissions = [...role.permissions]
		}
		if (role.permissions && role.permissions.filter((perm)=> JSON.stringify(perm) === JSON.stringify(permission)).length > 0) {
			//REMOVE
			newPermissions = role.permissions.filter((perm) => { return JSON.stringify(perm) !== JSON.stringify(permission) })
		} else {
			//ADD
			newPermissions.push(permission)

		}
		// log.info('newPermissions')
		// log.info(JSON.stringify(newPermissions))
		setRole({...role, permissions: newPermissions})
	} 

	return (
		<Dialog
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={true}
			style={{ margin: 0, height: '100%', width: '100%' }}
			onClose={ props.close }
		>
			{role && 
			<>
				<DialogTitle onClose={ props.close} id="customized-dialog-title">
						New Role
				</DialogTitle>
				<DialogContent dividers>
				<Grid container style={{width:'100%'}}>
					<Grid item style={{ padding: 20 }} lg={12}>
					
					
						{/* <Typography>Name</Typography> */}
						<DTextField
							type="text"
							style={{ width: '45%' }}
							label="Name"
							value={role.name}
							onChange={(e) => setRole({...role, name: e.target.value})}
						/>
						<DTextField
							type="text"
							style={{ width: '45%' }}
							label="Code"
							value={role.code}
							onChange={(e) => setRole({ ...role, code: e.target.value})}
						/>
						<DTextField
							type="text"
							style={{ width: '45%' }}
							label="description"
							value={role.description}
							onChange={(e) => setRole({ ...role, description: e.target.value})}
						/>
						<Typography
							variant="body"
							color="secondary"
						> Deleted:
							<DSwitch
								checked={role.deleted}
								value={role.deleted}
								onChange={(e) => setRole({ ...role, deleted: !role.deleted})}
								label="deleted"
							/>
						</Typography>
					</Grid>

					<Grid item style={{ paddingLeft: 12, paddingRight: 12}} lg={12}>
						<Typography>Permissions:</Typography>
						{permissions && permissions.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1: -1).map((permission) => {
							return (
								<Grid style={{borderBottom: '1px solid gray'}} container >
									<Grid item lg={2}>
										<Checkbox
											color="primary"
											value={role.permissions && role.permissions.filter((perm) => JSON.stringify(perm) == JSON.stringify(permission)).length > 0}
											checked={role.permissions && role.permissions.filter((perm) => JSON.stringify(perm) == JSON.stringify(permission)).length > 0}
											onChange={() => addRemovePermission(permission)}
										/>
									</Grid>
									<Grid item style={{paddingTop: 10}} lg={10}>
										<Typography >{permission.name}</Typography>
									</Grid>
								</Grid>
							)
						})}
					</Grid>
				</Grid>
				</DialogContent>
			</>}
			<DialogActions>
				<Button onClick={props.close}>
					Close
				</Button>
				<Button onClick={() => {upsertRole(role);}}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	)

}
