import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import history from '../history'
import { Grid, IconButton, Paper, Typography, Slide } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import getPosTypesApi from '../actions/getPosTypesApi';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute', 
        top: 0, 
        bottom: 0, 
        left: 0, 
        right: 0, 
        backgroundColor: '#AAAAAA90', 
        zIndex: 99999, 
        padding: 20, 
        display: 'flex'
    },
    dot: {
        height: 8,
        width: 8,
        borderRadius: '50%',
        display: 'inline-block',
        margin: 3,
        backgroundColor: '#DDD',
      }
}))

export default (props) => {
    const classes = useStyles();

    let [restaurant] = global.get('restaurant')
    let [stepNumber, setStepNumber] = React.useState(0)
    let numberOfSteps = 5

    useEffect(() => {
        
    }, [])

    let complete = () => {
        delete localStorage.showTutorial 
        global.set({showTutorial: false})
    }

    let getPhoneInputField = () => {
        let text = 'check description'
        if (restaurant) {
            switch(restaurant.posType.code) {
                case "digitaldining": text = "check name"; break;
                case "micros": text = "check id"; break;
                case "microssimphony": text = "check id"; break;
                case "positouch": text = "check id"; break;
                case "northstar": text = "check name"; break;
                case "pixelpoint": text = "label"; break;
            }
        }
        return text
    }

    let getNextStepText = () => {
        let text = ''
        if (restaurant) {
            switch(restaurant.posType.code) {
                case "digitaldining": text = "then press finish and send"; break;
                case "micros": text = "then save the check"; break;
                case "microssimphony": text = ""; break;
                case "positouch": text = ""; break;
                case "northstar": text = ""; break;
                case "pixelpoint": text = "then press save check"; break;
            }
        }
        if (text && text.length > 0 && text.substring(0,1) != ' ') {
            text = ' ' + text
        }
        return text
    }

    let getPosType = () => restaurant && restaurant.posType ? restaurant.posType.posType : ''


	return (
        <div className={classes.root}>
            <Grid container justify="center" alignItems="center">
                <Paper style={{width: 600, maxWidth: 600, height: 300, maxHeight: 300, backgroundColor: 'var(--white)', padding: 40}}>

                    <Grid container style={{height: '100%'}}>
                        <Grid xs item style={{padding: 15}}>
                            <Grid direction="column" justify="center" container style={{height: '100%'}}>
                                <Grid xs item>
                                    {stepNumber == 0 && <> 
                                        <Typography display="inline" variant="h5" style={{fontWeight: 300}}>Welcome to your</Typography>
                                        <Typography display="inline" variant="h5" style={{paddingLeft: 5, paddingRight: 5}} color="primary">Curbly</Typography>
                                        <Typography display="inline" variant="h5" style={{fontWeight: 300}}>dashboard</Typography>
                                        <Typography style={{fontWeight: 300, marginTop: 10, lineHeight: '1.8'}}>All phone and online orders within {getPosType()} that have a customer phone number will show up in your Curbly dashboard.</Typography>
                                        <Typography style={{fontWeight: 300, marginTop: 20, color: 'var(--text)', fontSize: '.95em'}}><i>Press the right arrow to begin the tutorial.</i></Typography>
                                    </>}

                                    {stepNumber == 1 && <> 
                                        {/* <Typography display="inline" variant="h5" style={{fontWeight: 300}}>For Call-In Orders</Typography> */}
                                        <Typography style={{fontWeight: 300, marginTop: 10, lineHeight: '1.8'}}>When taking an order over the phone, simply ask the customer for their mobile phone number and enter it into the <span style={{fontWeight: 400}}>{getPhoneInputField()}</span> field in {getPosType()}{getNextStepText()}.</Typography>
                                    </>}

                                    {stepNumber == 2 && <> 
                                        {/* <Typography display="inline" variant="h5" style={{fontWeight: 300}}>For Call-In Orders</Typography> */}
                                        <Typography style={{fontWeight: 300, marginTop: 10, lineHeight: '1.8'}}>When an order shows up in the Curbly dashboard, your customer will receive a text message with a link allowing them to notify you when they arrive.</Typography>
                                    </>}

                                    {stepNumber == 3 && <> 
                                        {/* <Typography display="inline" variant="h5" style={{fontWeight: 300}}>For Online Orders</Typography> */}
                                        <Typography style={{fontWeight: 300, marginTop: 10, lineHeight: '1.8'}}>When your customer arrives you will receive a real-time notification in the Curbly dashboard including their{restaurant.has('config.features.imHere.selectVehicleEnabled') && ' vehicle '} {restaurant.has('config.features.imHere.selectVehicleEnabled') && restaurant.has('config.features.imHere.selectLocationEnabled') && ' and '} {restaurant.has('config.features.imHere.selectLocationEnabled') && ' pickup location '}details.</Typography>
                                    </>}

                                    {stepNumber == 4 && <> 
                                        <Typography style={{fontWeight: 300, marginTop: 10, lineHeight: '1.8'}}>Once the customer has received their order, simply flag it as picked up.</Typography>
                                    </>}

                                </Grid>
                                <Grid item justify="center" alignItems="flex-end" container onClick={() => { if (stepNumber > 0) setStepNumber(stepNumber-1) }}>
                                    {_.times(numberOfSteps, x => <span className={classes.dot} style={x == stepNumber ? {backgroundColor: '#777'} : {}}></span>)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item style={{alignSelf: 'center', width: 24}}>
                            <IconButton style={{backgroundColor: 'var(--purple)', color: '#FFF', padding: 5}}>
                                <ChevronRightIcon onClick={() => {
                                    if (stepNumber+1 == numberOfSteps) {
                                        complete()
                                    } else {
                                        setStepNumber(stepNumber + 1) }
                                    }
                                } />
                            </IconButton>
                        </Grid>
                    </Grid>

                    
                </Paper>
            </Grid>
        </div>
	);
};