import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, Paper, Tab, Tabs, Dialog } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
	AddCircleOutline,
} from '@material-ui/icons';
import { Button, TextField, Checkbox } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import AddEditProduct from "../components/AddEditProduct";


import ReactTable from "react-table";
import "react-table/react-table.css";

const styles = (theme) => ({
    root: {
    },
	addIcon: {
		float: 'left',
		marginTop: 5,
		marginRight: 5,
		width: '20px',
		height: '20px'
	},
	addButton: {
		float: 'right',
		marginLeft: 20,
		marginRight: 20,
		marginTop: 10,
		marginBottom: 10,
		width: 200,
		height: 45
    },
    tabs: {
        width: '100%'
    }
    
})

const ProductListing = (props) => {
	const { classes } = props;

    const [records] = global.get('products')

	// const [records, setRecords] = useState([])
    const [dialogContent, setDialogContent] = useState()
    
	useEffect(() => {
        // fetchdata();
        global.set({ headerTitle: 'RESTAURANT LISTING' })
    }, []);
    

    if (records) {
        records.map(record => {
            if (!record.updated) {
                if (!record.billingFrequency) record.billingFrequency = "monthly"
                if (!record.unitType) record.unitType = "transaction"

                if (record.unitPrice) {
                    record.unitPrice = (record.unitPrice/100).toFixed(2)
                }

                if (record.mininumPrice) {
                    record.mininumPrice = (record.mininumPrice/100).toFixed(2)
                }
                record.updated = true
            }
        })
    }
    

	const upsertRecord = (record) => {
        // alert('role ' + JSON.stringify(role))
        setDialogContent(
            <AddEditProduct record={record} close={closeContent}/>
        )
    }
    
    const getColumnWidth = (rows, accessor, headerText) => {
        const maxWidth = 400
        const magicSpacing = 10
        const cellLength = Math.max(
          ...rows.map(row => (`${row[accessor]}` || '').length),
          headerText.length,
        )
        return Math.min(maxWidth, cellLength * magicSpacing)
    }
    


    const closeContent = () => {
        setDialogContent(null)
    }

    const showRecords = () => {
        return (
            <div  className={classes.listing}>
                <Grid container>
                    <Paper style={{ width: '100%' }} className={classes.rootTable}>
                        <div>
                            <Grid container>
                                <Grid item xs={12} sm={9} style={{ padding: 14, textAlign:'left', marginTop:'-8px'}}>
                                    <Typography variant="h6" color="primary">
                                        Products
                                    </Typography>
                                </Grid>
                                <Grid item lg={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.addButton}
                                        onClick={upsertRecord}
                                    >
                                        <div>
                                            <AddCircleOutline className={classes.addIcon} />
                                            <Typography variant="subtitle1" style={{ color: 'white', width: 190 }}>
                                                Add Product
                                            </Typography>
                                        </div>
                                    </Button>
                                </Grid>
                            </Grid>
                            {records && records.length > 0 && 
                                <ReactTable
                                    data={records}
                                    filterable
                                    defaultFilterMethod={(filter, row) =>
                                        String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
                                    }
                                    defaultSorted={[
                                        {
                                            id: "name",
                                            desc: true
                                        }
                                    ]}
                                    columns={[
                                        {
                                            Header: "Products",
                                            columns: [
                                                {
                                                    Header: "Name",
                                                    accessor: "name",
                                                    // width: getColumnWidth(records, 'accessor', 'Name'),
                                                },
                                                {
                                                    Header: "Type",
                                                    accessor: "type",
                                                    width: getColumnWidth(records, 'accessor', 'Type'),
                                                },
                                                {
                                                    Header: "Billing Type",
                                                    accessor: "billingFrequency",
                                                    width: getColumnWidth(records, 'accessor', 'Billing Type'),
                                                },
                                                {
                                                    Header: "Price Type",
                                                    accessor: "priceType",
                                                    width: getColumnWidth(records, 'accessor', 'Price Type'),
                                                },
                                                {
                                                    Header: "Price",
                                                    accessor: "unitPriceFriendly",
                                                    width: getColumnWidth(records, 'accessor', 'Price'),
                                                },
                                                {
                                                    Header: "Units",
                                                    accessor: "units",
                                                    width: getColumnWidth(records, 'accessor', 'Units'),
                                                },
                                                {
                                                    Header: "Unit Type",
                                                    accessor: "unitType",
                                                    width: getColumnWidth(records, 'accessor', 'Unit Type     '),
                                                },
                                                {
                                                    Header: "Description",
                                                    accessor: "description",
                                                    // width: getColumnWidth(records, 'accessor', 'Description'),
                                                },
                                                {
                                                    Header: "Mininum Total Price",
                                                    accessor: "minimumPriceFriendly",
                                                    width: getColumnWidth(records, 'accessor', 'Mininum Total Price'),
                                                },
                                            ]
                                        },
                                    ]}
                                    getTrProps={(state, rowInfo) => {
                                        if (rowInfo && rowInfo.row) {
                                            // console.log('TYLER ' + JSON.stringify(rowInfo))
                                            // console.log('TYLER 1 ' + JSON.stringify(rowInfo.row))
                                            return {
                                                onClick: (e) => {
                                                    upsertRecord(rowInfo.row._original)
                                                },
                                                // style: {
                                                //     background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                                                //     color: rowInfo.index === this.state.selected ? 'white' : 'black'
                                                // }
                                            }
                                        } else {
                                            return {}
                                        }
                                    }}
                                    defaultPageSize={15}
                                    className="-striped -highlight"
                                />
                            }
                            <br />
                        </div>
                    </Paper>
                    <div style={{ textAlign: "center" }}>
                        <em>Tip: Hold shift when sorting to multi-sort!</em>
                    </div>
                </Grid>
            </div>
        )
    }

    return (
        <Grid container >
            <div className={styles.root} style={{ width: '100%' }}>

                <Grid item lg={12} >
                    {showRecords()}
                </Grid>
                <Grid item lg={12} >
                    {dialogContent}
                </Grid>
            </div>
        </Grid>
    );
};

ProductListing.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withRouter(withStyles(styles, { withTheme: true })(ProductListing));