import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Paper, Button, LinearProgress, Box, Container, CircularProgress, IconButton, InputAdornment, MenuItem } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import theme from '../theme'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {ReactComponent as HeartlandLogo} from '../assets/svgs/heartland-logo-white.svg';
import log from 'loglevel'
import _ from 'lodash';
import PoweredByDashnow from '../layouts/components/PoweredByDashnow'
import SiteConfigForm from './siteConfigForm';

const headerHeight = 45
const activeButtonBarHeight = 0

const useStyles = makeStyles((theme) => ({
    stepper: {
        width: '100%',
		'&$selected': {
            backgroundColor: '#8B6BD9',
            fontWeight: 600,
		}
    },
    header: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		height: `${headerHeight}px`,
		zIndex: 11111,
    },
    content: {
		position: 'absolute',
		top: `${headerHeight}px`,
		bottom: `${activeButtonBarHeight}px`,
		left: 0,
		right: 0,
		overflow: 'auto',
		paddingTop: 30,
		paddingLeft: 20,
		paddingRight: 20,
		paddingBottom: 20,
    },
    italic: {
        color:'var(--dark-gray)',
        fontStyle:'italic'
    }
    
}))

export default (props) => {
    const classes = useStyles();

    const isMobile = useMediaQuery('(max-width:600px)')
    const defaultPadding = isMobile? 15 : 25
    const [restaurant, setRestaurant] = global.get("restaurant")

    let xs = useMediaQuery(theme().breakpoints.down('xs'));
    let sm = useMediaQuery(theme().breakpoints.down('sm'));
    let mediumScreen = useMediaQuery(theme().breakpoints.down('md'));

	return (
        <div style={{width: '100%', height: '100%', backgroundColor: theme().palette.background.default, overflow: 'hidden',}}>
            <Grid container alignItems="stretch" direction={isMobile ? "column" : "row"} className={classes.content}>
                <Grid item sm={4} style={{paddingRight: isMobile? 5 : 25, paddingBottom: isMobile? 5 : 25, paddingTop: isMobile? 0 : 10, paddingLeft: isMobile? 0 : 10}}>
                    {!isMobile && 
                        <Grid direction="column" alignItems="stretch" container style={{height: '100%'}}>
                            <Grid item xs>
                                {global.isTextPay() && <>
                                    <Typography variant="h5" style={{color: 'var(--text)', marginTop: isMobile? 0 : 15}}>
                                        Welcome to the {global.capitalize(global.branding)} TextPay Platform,
                                    </Typography>   
                                    {(global.branding != "dashnow") &&
                                        <PoweredByDashnow left noLogo noCopy style={{alignSelf:'flex-start'}}/>
                                    }
                                </>}
                                {global.isDashNow() &&
                                    <Typography variant="h5" style={{color: 'var(--text)', marginTop: isMobile? 0 : 15}}>
                                        Welcome to the DashNow Platform,
                                    </Typography>   
                                }
                            
                                <br/><br/>
                                <span className={classes.italic}>
                                    Please complete this form to finish setting up your account. 
                                </span>

                            </Grid>

                            <Grid item container alignItems="flex-end">
                                <Grid item xs={12}>
                                    <Typography variant="h5" style={{color: 'var(--text)'}}>
                                        Having Trouble?
                                    </Typography>
                                    <Typography variant="h7" style={{color: 'var(--text)'}}>
                                        Contact our support team at <br/>
                                        <a style={{color: `var(--${global.branding})`}} href="mailto:support@dashnow.com">support@dashnow.com</a> or 913-370-7577
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Grid>
                    }
                </Grid>

                <Grid item xs sm={8}>
                    <Paper style={{height: '100%', padding: isMobile? 0 : 15, marginBottom: 15}}>
                        <Grid direction="column" alignItems="stretch" container style={{height: '100%'}}>
                            <Grid item ></Grid>
                            <Grid xs item style={{overflowX: 'hidden', overflowY: 'auto'}}>
                                {!restaurant && 
                                    <Grid container justify="center" alignItems="center" style={{height: '50%'}}>
                                        <LinearProgress style={{width: '75%', height: 6}} />
                                    </Grid>
                                }
                                
                                {restaurant &&
                                    <SiteConfigForm hideSplash/>
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
	);
};