import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import PropTypes from 'prop-types';
import SelectMui from '@material-ui/core/Select';
import { Container, Grid, TextField, Box, Typography, Paper, FormControl, InputLabel, Button, TableHead, TableRow, TableCell, Table, TableContainer, TablePagination, Slide, CircularProgress, LinearProgress, IconButton, RadioGroup, Radio, FormControlLabel, FormLabel, Accordion, AccordionSummary, AccordionDetails, MenuItem} from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/core/styles';
import log from 'loglevel'
import { withRouter } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'
import RestaurantPendingIcon from '@material-ui/icons/Autorenew';
import RestaurantOpenIcon from '@material-ui/icons/FilterNone';
import RestaurantClosedIcon from '@material-ui/icons/AttachMoney';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DForm2 from '../../../components/dash/DForm2';
import DRecord from '../../../components/dash/DRecord';
import DInput from '../../../components/dash/DInput';
import DToggleButtonGroup from '../../../components/dash/DToggleButtonGroup';
import BillingRestaurantDetails from './BillingInvoiceOrders';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap'
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../theme';

const useStyles = (theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        padding: 15,
        margin: 0,
        width: "100%",
        height: "100%",
        // overflow: 'hidden',
    },
    deletedRow: {
        color: '#DDD'
    },
    padding:{
        margin:12,
        padding:50,
        backgroundColor:'green'
    }
})

const BillingRestaurantList = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [allRecords] = global.get('restaurants', []);
    const [checkedByRestaurant] = global.get('checkedByRestaurant', false);
    let [restaurant, setRestaurant] = global.get('restaurant', null);
    const [records, setRecords] = useState([])
    const [asOfDate, setAsOfDate] = useState(moment())
    // const [selectedRestaurant, setSelectedRestaurant] = useState(null)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [groupBy1, setGroupBy1] = React.useState(0);
    const [filters, setFilters] = useState({})
    const [lastSetVal, afterSetVal] = useState({})
    const [showDetails, setShowDetails2] = React.useState(false);
    const isMobile = useMediaQuery('(max-width:500px)')
    const [topPanelHeight, setTopPanelHeight] = useState('55px')
    const [bottomPanelHeight, setBottomPanelHeight] = useState('calc(100% - 55px)')

    
    let lastRestGroup = ''

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const setShowDetails = (value) => {
        setShowDetails2(value)
    }
    

	useEffect(() => {
        fetchData()
    }, [allRecords]);

    useEffect(() => {
        fetchResults(allRecords, setRecords)
    }, [lastSetVal]);

    let fetchResults = async (allRecords, setFunction) => {
        // log.info("FILTERS UPDATED")
        // log.info(filters)
        allRecords = _.filter(allRecords, (r) => {return !r.trainingMode && !r.deleted && global.f(r, 'restaurantGroup.code', '') != 'dashoffice'} )

        if (filters) {
            let matchingRecords = _.filter(allRecords, (r) => {
                let match = true
                if (filters.name && r.name.toUpperCase().indexOf(filters.name.toUpperCase()) == -1) {
                    match = false
                }
                if (filters.status && filters.status.length > 0 && !_.includes(filters.status, r.status)) {
                    match = false
                }

                if (filters.billingEnabled != null) {
                    if (filters.billingEnabled && !r.billingInfo.enabled) {
                        match = false
                    } else if (!filters.billingEnabled && r.billingInfo.enabled) {
                        match = false
                    }
                }

                if (filters.billingType != null) {
                    if (r.billingInfo.billingType != filters.billingType) {
                        match = false
                    }
                }

                if (filters.hasPrimaryContact != null) {
                    if (filters.hasPrimaryContact && !r.primaryContactAccount) {
                        match = false
                    } else if (!filters.hasPrimaryContact && r.primaryContactAccount) {
                        match = false
                    }
                }

                if (filters.hasPaymentMethod != null) {
                    if (filters.hasPaymentMethod && (!r.billingInfo || !r.billingInfo.encryptedCard || r.billingInfo.encryptedCardStatus)) {
                        match = false
                    } else if (!filters.hasPaymentMethod && r.billingInfo && r.billingInfo.encryptedCard && !r.billingInfo.encryptedCardStatus) {
                        match = false
                    }
                }
                return match
            })
            setFunction(matchingRecords)
        }
    }

    

    const selectRestaurant = (restaurant) => {
        if (!checkedByRestaurant) {
            global.showNotification("You are filtered by 'All' invoices, switch to filter by 'Site' to see invoices for this site", "warning")
        }
        // setSelectedRestaurant(restaurant)
        setRestaurant(restaurant)
    }
    
    const fetchData = async () => {
        setRecords(allRecords)
        setPage(0)
    }

    let groupByOptions = [
        {name: "Site Group", code: "restaurantGroup.name"},
        {name: "Status", code: "status"},
        {name: "Site", code: "restaurant.name"},
    ]

    let groupBy = []

    const handlePanelResize = (panel) => {
        if (panel === 'top') {
            if (bottomPanelHeight !== '63px') {
                setTopPanelHeight('calc(100% - 63px)')
                setBottomPanelHeight('63px')
            } else {
                setTopPanelHeight('35%')
                setBottomPanelHeight('65%')
            }
        }
        if (panel === 'bottom') {
            if (bottomPanelHeight === '60%') {
                setBottomPanelHeight('calc(100% - 55px)')
                setTopPanelHeight('55px')
            } else {
                setTopPanelHeight('35%')
                setBottomPanelHeight('65%')
            }
        }
    }

    const showFilterList = () => {
        let filterList = filters
        delete filterList.dform
        let formattedFilterList = []
        for (const [key, value] of Object.entries(filterList)) {
            console.log({key})
            console.log({value})
            console.log({formattedFilterList})
            formattedFilterList.push(` [ ${key}: ${value} ] `)
        }
        return formattedFilterList;
    }

    return (
            <div style={{height:'100%', padding:8}}>
                    {!showDetails && 
                        <div style={{height:topPanelHeight, padding:8}}>
                            <Paper elevation={1} style={{width: '100%', height: '100%', overflow: 'hidden' }}>
                            <Accordion elevation={0} style={{padding:0}}>
                                <AccordionSummary
                                    style={{padding:4, height: 40, minHeight:0}}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center', paddingLeft:10}}>
                                        <Typography variant="h6">Sites</Typography>
                                        <div style={{color:'var(--light-gray'}} >
                                            {showFilterList()}
                                        </div>
                                        <div style={{alignSelf:'right !important'}}>
                                            <Button size="small" style={{color:'var(--light-gray'}} 
                                                onClick={(e)=> {
                                                    e.stopPropagation()
                                                    setFilters({})
                                                }}>Clear</Button>
                                            <Button size="small">Filters</Button>
                                            <IconButton color="primary" aria-label="directions"
                                                onClick={(e) => { 
                                                    e.stopPropagation()
                                                    handlePanelResize('top') 
                                                }}>
                                                <ZoomOutMapIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails style={{ backgroundColor: 'var(--background)', borderBottom:'1px solid #ccc', borderTop:'1px solid #ccc'}}>
                                    <DForm2 record={filters} afterSetVal={afterSetVal} validate={false} >
                                        <div style={{display:'flex', justifyContent:'flex-start', alignContent:'center', width:'100%', flexWrap:'wrap'}}>
                                            <div style={{padding:8}}>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Status</FormLabel>
                                                    <DInput fieldType="toggleGroup" name="status" style={{width: 'fit-content', marginBottom: 20}}> 
                                                        <ToggleButton value="enrolled" size="small">
                                                            <Typography color="primary">Enrolled</Typography>
                                                        </ToggleButton>
                                                        <ToggleButton value="installed" size="small">
                                                            <Typography color="primary">Installed</Typography>
                                                        </ToggleButton>
                                                        <ToggleButton value="live" size="small">
                                                            <Typography  color="primary">Live</Typography>
                                                        </ToggleButton>
                                                        <ToggleButton value="cancelled" size="small">
                                                            <Typography color="primary">Cancelled</Typography>
                                                        </ToggleButton>
                                                    </DInput>
                                                </FormControl>
                                            </div>
                                            <div style={{padding:8}}>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Billing Enabled</FormLabel>
                                                    <DInput fieldType="toggleGroup" name="billingEnabled" style={{width: 'fit-content', marginBottom: 20}} exclusive> 
                                                        <ToggleButton value={true} size="small">
                                                            <Typography color="primary">Yes</Typography>
                                                        </ToggleButton>
                                                        <ToggleButton value={false} size="small">
                                                            <Typography color="primary">No</Typography>
                                                        </ToggleButton>
                                                    </DInput>
                                                </FormControl>
                                            </div>
                                            <div style={{padding:8}}>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Billing Type</FormLabel>
                                                    <DInput fieldType="toggleGroup" name="billingType" style={{width: 'fit-content', marginBottom: 20}} exclusive> 
                                                        <ToggleButton value={''} size="small" style={{whiteSpace: 'nowrap'}}>
                                                            <Typography color="primary">Not Setup</Typography>
                                                        </ToggleButton>
                                                        <ToggleButton value={'processing'} size="small" style={{whiteSpace: 'nowrap'}}>
                                                            <Typography color="primary">Processing</Typography>
                                                        </ToggleButton>
                                                        <ToggleButton value={'direct'} size="small" style={{whiteSpace: 'nowrap'}}>
                                                            <Typography color="primary">Direct</Typography>
                                                        </ToggleButton>
                                                    </DInput>
                                                </FormControl>
                                            </div>
                                            <div style={{padding:8}}>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Has Primary Contact</FormLabel>
                                                    <DInput fieldType="toggleGroup" name="hasPrimaryContact" style={{width: 'fit-content', marginBottom: 20}} exclusive> 
                                                        <ToggleButton value={true} size="small">
                                                            <Typography color="primary">Yes</Typography>
                                                        </ToggleButton>
                                                        <ToggleButton value={false} size="small">
                                                            <Typography color="primary">No</Typography>
                                                        </ToggleButton>
                                                    </DInput>
                                                </FormControl>
                                            </div>
                                            <div style={{padding:8}}>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Has Payment Info</FormLabel>
                                                    <DInput fieldType="toggleGroup" name="hasPaymentMethod" style={{width: 'fit-content', marginBottom: 20}} exclusive> 
                                                        <ToggleButton value={true} size="small">
                                                            <Typography color="primary">Yes</Typography>
                                                        </ToggleButton>
                                                        <ToggleButton value={false} size="small">
                                                            <Typography color="primary">No</Typography>
                                                        </ToggleButton>
                                                    </DInput>
                                                </FormControl>

                                            </div>
                                        </div>
                                    </DForm2>

                                </AccordionDetails>
                            </Accordion>
                            
                                <TableContainer style={{height: '100%', overflow:'scroll'}}>
                                    <Table size="small" stickyHeader>
                                        <TableHead stickyHeader>
                                            <TableRow>
                                                <TableCell style={{backgroundColor:'white'}}>Applies To</TableCell>
                                                <TableCell style={{backgroundColor:'white'}}>Status</TableCell>
                                                <TableCell style={{backgroundColor:'white'}}>Enrolled</TableCell>
                                                <TableCell style={{backgroundColor:'white'}}>Installed</TableCell>
                                                <TableCell style={{backgroundColor:'white'}}>Go Live</TableCell>
                                                <TableCell style={{backgroundColor:'white'}}>Billing Type</TableCell>
                                                <TableCell style={{backgroundColor:'white'}} align="center">Billing Enabled</TableCell>
                                                {/* <TableCell># Orders</TableCell> */}
                                                <TableCell style={{backgroundColor:'white'}} align="center">Primary Contact</TableCell>
                                                <TableCell style={{backgroundColor:'white'}} align="center">Payment Method</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        {/* {records && records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(rest => { */}
                                        {records && records.map(rest => {
                                            let showRestGroup = false
                                            let restaurantGroupName = rest?.restaurantGroup ? rest?.restaurantGroup.name : 'NO GROUP'
                                            if (lastRestGroup != restaurantGroupName) {
                                                showRestGroup = true
                                            }
                                            lastRestGroup = restaurantGroupName

                                            return (
                                                <>
                                                    {false && showRestGroup && 
                                                        <TableRow>
                                                            <TableCell colSpan={9}><b>{restaurantGroupName}</b></TableCell>
                                                        </TableRow>
                                                    }
                                                    
                                                    <TableRow id={rest?._id} selected={restaurant && restaurant._id == rest?._id} onClick={(event) => selectRestaurant(rest) } style={{cursor: 'pointer'}} className={rest?.deleted && classes.deletedRow}>
                                                        <TableCell style={{paddingLeft: 35, minWidth: '250px'}}>{rest?.friendlyName}</TableCell>
                                                        <TableCell>{rest?.status?.toProperCase()}</TableCell>
                                                        <TableCell>{rest?.dateEnrolled ? new Date(rest?.dateEnrolled).toLocaleDateString() : ''}</TableCell>
                                                        <TableCell>{rest?.dateInstalled ? new Date(rest?.dateInstalled).toLocaleDateString() : ''}</TableCell>
                                                        <TableCell>{rest?.goLiveDate ? new Date(rest?.goLiveDate).toLocaleDateString() : ''}</TableCell>
                                                        <TableCell>{rest?.billingInfo && rest?.billingInfo.billingType ? rest?.billingInfo.billingType.toProperCase() : ''}</TableCell>
                                                        <TableCell align="center">{rest?.billingInfo?.enabled ? <CheckCircleOutlineIcon color="primary" /> : ''}</TableCell>
                                                        {/* <TableCell>{rest?.orders.length > 0 ? rest?.orders.length : ''}</TableCell> */}
                                                        <TableCell align="center">{rest?.primaryContactAccount ? <CheckCircleOutlineIcon color="primary" /> : ''}</TableCell>
                                                        <TableCell align="center">{(rest?.billingInfo?.encryptedCard && !rest?.billingInfo.encryptedCardStatus) ? <CheckCircleOutlineIcon color="primary" /> : ''}</TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        })}
                                    </Table>
                                    <div style={{height:10}}></div>
                                    {/* {records && 
                                        <TablePagination
                                            rowsPerPageOptions={[25, 50, 100]}
                                            component="div"
                                            count={records ? records.length : 0}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    } */}
                                </TableContainer>
                            </Paper>
                        </div>
                    }
                        <div style={{height: bottomPanelHeight, padding:8}}>
                            <Paper elevation={1} style={{height: '100%', width: '100%', overflow: 'hidden' }}>
                                <BillingRestaurantDetails selectedRestaurant={restaurant} showDetails={showDetails} setShowDetails={setShowDetails} collapse={bottomPanelHeight==='60px'} resize={()=>handlePanelResize('bottom')}/>
                            </Paper>
                            {isMobile ? <RestaurantOpenIcon style={{height:"136px", visibility:'invisible'}}/> : null }
                        </div>


            </div>
    );
};

export default (BillingRestaurantList);