import React from 'react';

import Typography from '@material-ui/core/Typography';

export const configuredLayout = {
	currentLayout: 'dash',
	notificationsOpen: false
};

const iconStyle = {
	fontSize: 25
};

const styleOfMenu = {
	boardColor: {
		color: '#6C3082'
	}
}


export let spreedlyEnvironmentKeys = {
	dev: 'STahfxlRS9swSIMS7wWYnk55eDp',
	local: 'STahfxlRS9swSIMS7wWYnk55eDp',
	prod: 'STahfxlRS9swSIMS7wWYnk55eDp',
	stage: 'STahfxlRS9swSIMS7wWYnk55eDp',
}

export let domains = {
	prod: 'https://prod-server.dashnow.com',
	stage: 'https://stage-server.dashnow.com',
	dev: 'https://local.dashnow.com:3000',

};

export let wsDomains = {
	stage: 'wss://stage-server.dashnow.com',
	dev: 'wss://local.dashnow.com:3000',
	prod: 'wss://prod-server.dashnow.com',
};
export const debug = false;


export const fetchApiLastConnectedAtInterval = 5
export const maxTestApiConnectionTime = 35

// export const menuItemsDash = [{
// 	title: <Typography style={{fontSize: '30px'}}>dash<span style={styleOfMenu.boardColor}>board</span></Typography>,
// 	href: '/',
// 	icon: <DashboardIcon style={iconStyle} fill={'#C0C4CA'} />,
// }, {
// 	title: <Typography style={{fontSize: '30px'}}>dash<span style={styleOfMenu.boardColor}>board</span></Typography>,
// 	href: '/restaurantListing',
// 	icon: <CustomersIcon style={iconStyle} />
// }, {
// 	title: <Typography style={{fontSize: '30px'}}>dash<span style={styleOfMenu.boardColor}>board</span></Typography>,
// 	href: '/registerSpreedly',
// 	icon: <PromotionsIcon />,
// }, ];