import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import { Divider, makeStyles, IconButton, Grid, TableCell, Table, TableBody, TableRow, ButtonBase, Slide, Paper, Box, Dialog, DialogContent } from '@material-ui/core';
import moment from 'moment';
import log from 'loglevel';
import EmptyStarIcon from '../../assets/svgs/EmptyStarSvg';
import StarIcon from '../../assets/svgs/GoldStarSvg';
import theme from '../../theme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
	root: {
		borderRadius: 2,
		textAlign: 'center',
	},
	header: {
		padding:4
	},
	list: {
		padding: '20px',
	},
	button: {
		margin: theme.spacing(1),
	},
	action: {
		display: 'flex',
		justifyContent: 'space-around',
	},
	typography: {
		fontWeight: 300
	},
	typographyHeader: {
		fontWeight: 400,
		fontSize: '1.3em'
	},
	table: {
        padding:2,
        alignSelf:'center'
	},
	col1: {
		width: '15%',
		borderBottom: 'none',
		padding: 0,
		paddingBottom: 5,
	},
	col2: {
		borderBottom: 'none',
		padding: 0,
        paddingBottom: 5,
        paddingLeft:4
	}
}));

const FeedbackCard = (props) => {
    const classes = useStyles();
	const largeScreen = useMediaQuery(theme().breakpoints.up('lg'));
	const smallScreen = useMediaQuery(theme().breakpoints.down('sm'));
	const feedback = props.data
	let accountName = feedback ? feedback.getVal('account.firstName', '') + feedback.getVal('account.lastName', '') : ''
    const guestName = accountName ? accountName : feedback ? feedback.getVal('check.pickupDetails.guestName', 'Guest') : ''

    const renderRatings = (feedback) => {

        const ratings = feedback.ratings
        let ratingsList = []

        if (Array.isArray(ratings)) { //use newer schema
            ratingsList = ratings
        } else {
            for (const [key, value] of Object.entries(ratings)) { // convert old schema
                ratingsList.push({questionText: key, fieldType:'stars', value: value})
            }
        }

        return (
            <>
                {ratingsList.map((item, index) => {
                    return (
                        <div style={{margin:4, display:'flex', flexDirection: item.fieldType === 'string' ? 'column' : 'row', justifyContent:'space-between', maxWidth:260}}>
                           
                            <span style={{fontSize:14}}>{global.capitalize(item.questionText)}: </span>

                            {item.fieldType === 'stars' && 
                                <div style={{padding:4, display:'flex', flexWrap:'nowrap'}}>
                                    {_.times(item.value, () => (
                                        <StarIcon />
                                    ))}
                                    {_.times(5 - item.value, () => (
                                        <EmptyStarIcon />
                                    ))}
                                </div>
                            }

                            {item.fieldType === 'string' && 
                                <>
                                    {props.checkDetails ?
                                        <div style={{whiteSpace: props.shortenComments && 'nowrap', overflow: props.shortenComments && 'hidden', textOverflow: props.shortenComments && 'ellipsis', height:smallScreen && '30', width: 200, paddingTop:4, alignItems:'center', wordBreak:'normal'}}>
                                            <i>{item.value ? item.value : ""}</i>                               
                                        </div>
                                    :
                                    <div>
                                        {item.value ? item.value : ""}      
                                    </div>
                                    }
                                </>
                            }
                        </div>
                    )
                })}
            </>
        )
    }

	const renderTableInfo = (check) => {
		return (
			<Table className={classes.table}>
				{check && (<>
					<TableRow>
						<TableCell className={classes.col1}>
							<Typography className={classes.typography} variant="body1">Guest:</Typography>
						</TableCell>
						<TableCell className={classes.col2} align="left">
							{guestName &&
								<Typography className={classes.typographyHeader}>{guestName}</Typography>
							}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell className={classes.col1}>
							<Typography className={classes.typography} variant="body1">Server:</Typography>
						</TableCell>
						<TableCell className={classes.col2} align="left">
							{check.serverIdentifier &&
								<Typography className={classes.typographyHeader}>{check.serverIdentifier}</Typography>
							}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell className={classes.col1}>
							<Typography className={classes.typography} variant="body1">Table:</Typography>
						</TableCell>
						<TableCell className={classes.col2} align="left">
							{check.tableNumber &&
								<Typography className={classes.typographyHeader}>{check.tableNumber}</Typography>
							}
						</TableCell>
					</TableRow>
				</>)}
			</Table>
		);
    }
    
    const renderCardContent = () => {
        return (
            <div style={{display:'flex', flexWrap:'wrap', flexDirection:'row', height:'100%'}}>

                <div style={{flexGrow:2, flexDirection:'column', flexWrap:'wrap'}}>
                    {renderRatings(feedback)}
                </div>

                <div style={{flexGrow:2}}>
                    {feedback.comment && 
                        <div style={{padding:4}}>
                            <span style={{fontSize:14}}>Comment:</span>
                            <div style={{padding: '8px 8px 8px 8px', borderTop: '1px solid #ddd',  overflow: props.shortenComments && 'hidden', textOverflow: props.shortenComments && 'ellipsis', height:smallScreen && '30', width: props.checkDetails ? 250 : 500, wordBreak:feedback.comment.length < 100 ? 'break-word' : 'normal', whiteSpace: props.shortenComments && 'nowrap'}}>
                                {feedback.comment}
                            </div>
                        </div>
                    }
                </div>

            </div>
        )
    }

    if (props.checkDetails) { //for the CheckDetails feedback panel
        if (!props.feedback) {
            return ''
        }
        return (
            renderCardContent()
        )
    } else {
        return (
            <Slide direction='right' in>
                <Paper>
                    <Grid item style={{ borderBottom: '1px solid #ddd', backgroundColor: '#ddd', borderTopLeftRadius:4, borderTopRightRadius:4, justifyContent: 'space-around', zIndex:1000}} xs={12}>
                        <Typography style={{ padding: 5, paddingLeft: 12, fontWeight:450}}>{moment(feedback.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</Typography>
                    </Grid>

                    <div style={{display:'flex', justifyContent:'space-around', alignContent: 'center', width:'100%', padding:16, flexWrap:'wrap'}}>
                        
                        <div style={{flexGrow:1, display:'flex',justifyContent:'flex-start', alignItems:'flex-start'}}>
                            <div>
                                {renderTableInfo(feedback.check)}
                            </div>
                        </div>

                        <div style={{flexGrow:2, display: 'flex', flexDirection:'column', flexWrap:'wrap', justifyContent:'center'}}>
                            {renderCardContent()}
                        </div>

                    </div>
                </Paper>
            </Slide>
        )
    }
};


export default FeedbackCard;