import React from 'react';
import theme from '../theme'
import moment from 'moment'
import acceptTermsOfServiceApi from '../actions/acceptTermsOfServiceApi'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import log from 'loglevel';

export default function EarnMoneyDialog(props) {
    const [showQboConnectionDialog, setShowQboConnectionDialog] = global.get('showQboConnectionDialog')



    const reconnectLater = async() => {
        setShowQboConnectionDialog(false)
    }

  	return (
        <Dialog open={showQboConnectionDialog} aria-labelledby="form-dialog-title" width={800}>
            <DialogTitle id="form-dialog-title">Quickbooks Online Disconnected</DialogTitle>
            <DialogContent dividers style={{ overflowX: 'hidden', overflowY: 'hidden', height: '100%', width: '100%' }}>
                <span style={{fontWeight:500}}>Please click the button below to reconnect</span>
                <br/><br/>
                <iframe src={'https://' + (global.isProd ? 'prod' : 'stage') + '-pos-server.dashnow.com/quickbooks/'} height={250} width={300}></iframe>
            </DialogContent>
            <DialogActions>
                <Button onClick={reconnectLater} color="primary">
                    Re-connect Later
                </Button>
            </DialogActions>
        </Dialog>
  	);
}

