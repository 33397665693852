import { red } from '@material-ui/core/colors';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// A custom theme for this app
const theme = (primaryColor, darkMode) => { 
	if (!primaryColor) primaryColor = global.primaryColor ? global.primaryColor : '#8B6BD9'
	if (darkMode == undefined) darkMode = localStorage.darkMode == 'true'

	let t = createMuiTheme({
		palette: {
			type: darkMode?'dark' : 'light',
			primary: {
				main: primaryColor,
			},
			secondary: {
				main: darkMode ? "#FFF" : '#838383',
			},
			white: {
				main: darkMode ? "#2c2c2c" : '#fafafa'
			},
			dark: {
				main: darkMode ? "#fff" : '#373636'
			},
			error: {
				main: red.A400,
			},
			background: {
				default: darkMode ? '#555': '#F5F5F5',
				paper: darkMode ? '#2c2c2c': '#fff',
			},
			text: {
				primary: darkMode ? '#f8f8f8' : '#303030',
			},
		},
		overrides: {
			MuiRadio: {
			  root: {
				paddingTop: 3,
				paddingBottom: 3,
			  }
			}, 
			MuiToggleButtonGroup: {
				root: {
					backgroundColor: darkMode ? "#555" : "#FFFFFF",
				},
				grouped: {
					paddingTop: 5,
					paddingBottom: 3,
					paddingLeft: 12,
					paddingRight: 12,
				}
			},
			MuiToggleButton: {
				root: {
					'&$selected': {
						backgroundColor: primaryColor,
						color: darkMode ? "#555 !important" : "#FFFFFF !important;",
						'& p': {
							color: darkMode ? "#555 !important" : "#FFFFFF !important;",
							fontWeight: '500 !important',
						}
					}
				},
			},
			MuiFormLabel: {
				root: {
					marginBottom: 5,
					color: darkMode ? '#f8f8f8' : '#303030',
				}
			}
		}
	})
	return t
}
// let theme = createMuiTheme(darkMode ? darkTheme : lightTheme);
// theme = responsiveFontSizes(theme);

export default theme;