import log from 'loglevel';
import dashFetch from '../utils/dashFetch';
import moment from 'moment'


export default async function (restaurant, startDate, endDate, debug) {
	return new Promise((resolve, reject) => {
		log.info('>>> getPaymentDataApi api');
		if (debug) log.info('getPaymentDataApi api');
		const url = `${global.dashServerUrl}/dashboard/api/v1/dashboard/getPaymentData`;

		const body = {
            restaurant, 
			startDate: moment(startDate).format("MM/DD/YYYY"),
			endDate: moment(endDate).format("MM/DD/YYYY"),
            debug
		};

		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info('>>> getPaymentDataApi JSON');
			log.info(json)
			if (json.success) {
				resolve(json.data);
			} else {
				resolve(false)
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}