import React, { useState, useEffect } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import theme from '../../theme';
import { Typography, Grid, Paper } from '@material-ui/core';
import moment from 'moment';
import 'chartjs-plugin-datalabels';


export default function LoyaltyChart(props) {
	const [ dashboardWaiterData ] = global.get('dashboardWaiterData')

	let names = dashboardWaiterData ? dashboardWaiterData.map(w => w.waiterName) : []
	let scores = dashboardWaiterData ? dashboardWaiterData.map(w => (w.waiterServiceStars / (w.waiterReviewCount ? w.waiterReviewCount : 1)).toFixed(2)) : []
	names = names.slice(0,15);
	scores = scores.slice(0,15);

	const data = {
		labels: names,
		datasets: [
			{
				label: 'Service Score',
				fill: 'origin',
				backgroundColor: '#9575CD98',
				borderColor: '#9575CD85',
				borderWidth: '2',
				pointBackgroundColor: '#9575CD45',
				pointHoverBackgroundColor: '#9575CD75',
				pointBorderColor: '#FFF',
				pointBorderWidth: '.75',
				lineTension: 0.3,
				data: scores,
				plugins: {
					datalabels: {
					   display: true,
					   inside: true,
					   enabled: true,
					   color: 'white',
					   align: 'left'
					}
				},
			}, 
		]
	};

	const barChartOptions = {
		plugins: {
			datalabels: {
			   display: true,
			   inside: true,
			   enabled: true,
			   color: 'white',
			   align: 'left'
			}
		},
		legend: {
			display: false
		},
		scales: {
			xAxes: [
				{
					stacked: true,
					position: 'right',
					display: false,
					gridLines: {
						display: false
					}
				}
			],
			yAxes: [
				{
					stacked: true,
					gridLines: {
						display: false
					}
				},
				// {
				// 	display: true,
				// 	position: 'right',
				// 	ticks: {
				// 		beginAtZero: true
				// 	}
				// }
			]
		}
	};

	const styles = {
		infoBanner: {
			width: '96%', //TODO
			padding: 15
		},
		paper: {
			padding: '1em'
		}
	};
	// console.log("element " + Element)
	return (
		<Grid style={styles.infoBanner}>
			<Paper style={styles.paper}>
				<Grid container>
					<Grid item xs={12}>
						<Typography align="center" variant="h5">
							Top Wait Staff
						</Typography>
						<HorizontalBar data={data} options={barChartOptions} height="100" />
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
}
