import React, { useState, useEffect } from 'react';
import SelectMui from '@material-ui/core/Select';
import { Grid, MenuItem, Select, Checkbox, ListItemText, Paper, Button, Input, Dialog, Typography, DialogActions, TextField, Divider, DialogContent, Table, TableHead, TableBody, TableRow, TableCell, useMediaQuery } from '@material-ui/core';
// import upsertAccountApi from '../../actions/upsertAccountApi';
import createAccountApi from '../../actions/createAccountApi'
import deactivateAccountApi from '../../actions/deactivateAccountApi'
import createAgencyApi from '../../actions/createAgencyApi'
import getDropdownApi from '../../actions/getDropdownApi'
import dashFetch from '../../utils/dashFetch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AssignmentIcon from '@material-ui/icons/Assignment';
import log from 'loglevel'
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '../../dashboard/components/DialogTitle';
import DFlag from '../../components/dash/DFlag';
import DConfirm from '../../components/dash/DConfirm';
import { DeleteForever } from '@material-ui/icons';
import theme from '../../theme';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
	root: {
	  margin: 0,
	  padding: theme.spacing(2),
	},
	closeButton: {
	  position: 'absolute',
	  right: theme.spacing(1),
	  top: theme.spacing(1),
	  top: theme.spacing(1),
	  top: theme.spacing(1),
	  color: theme.palette.grey[500],
	},
	addButton: {
		// backgroundColor: '#5220A8'
		margin: 10,
		minWidth: 28,
		backgroundColor: theme.palette.primary.main,
		borderRadius: 15,
		padding: 2,
	},
	agencyField :{
		width: 400,
		float: 'left',
	},
  }));

export default function (props) {
	// alert(JSON.stringify(props.permissions))
	const classes = useStyles();
	const [account, setAccount] = useState(props.account)

	let accountRolesWithAdmin = props.roles
	let accountRoles = props.roles
	let isAdmin = props.isAdmin
	const [restaurant] = global.get('restaurant')
	const [payAtTableEnabled] = global.get('payAtTableEnabled')
	if(isAdmin){
		accountRoles = accountRoles.filter(r => r.code != 'admin')
	} else {
		accountRoles = accountRoles.filter(r => ((r.code != 'admin') && (r.code != 'textpay-agent')))
	}

	const createUser = props.create

	const [ restaurants, setRestaurants ] = useState(props.sites ? props.sites: [])
	const [ restaurantGroups, setRestaurantGroups ] = useState(props.siteGroups ? props.siteGroups : [])
	const [ agencies, setAgencies ] = useState([])
	const [ agency, setAgency ] = useState([])
	const [ newAgencyName, setNewAgencyName ] = useState("")
	const [ apiKeyDescription, setApiKeyDescription ] = useState("")
	const [ phoneNumberFormatted, setPhoneNumberFormatted ] = useState("")
	const [ filteredRestaurants, setFilteredRestaurants ] = useState([])
	const [ filteredRestaurantGroups, setFilteredRestaurantGroups ] = useState([])
	const [ showAddAgencyForm, setShowAddAgencyForm ] = useState(false)
	const [ showConfirmDelete, setShowConfirmDelete ] = useState(false)
	const [ loading, setLoading ] = useState(false)
	const [ accessBySite, setAccessBySite ] = useState(true)
	const [ tableData, setTableData ] = useState({})
	const [ agentPartnerID, setAgentPartnerID ] = useState("")

	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	const checkedIcon = <CheckBoxIcon fontSize="small" />;

	const fetch = async () => {
		if (createUser) {
			let resp = await dashFetch(`${global.dashServerUrl}/dashboard/api/v1/dashboard/newSchemaInstance`, { schema: 'Account' }, true);
			setAccount(resp.json.instance)
		}

		if (isAdmin) {
			let res = await getDropdownApi('restaurantGroup')
			if (res) {
				setRestaurantGroups(res)
			}
			res = await getDropdownApi('restaurant')
			if (res) {
				setRestaurants(res)
			}
			res = await getDropdownApi('agency')
			if (res) {
				setAgencies(res)
			}
		}
	}

	const addApiKeyForUser = async (accountId, description) => {
		if (!description || !accountId || !isAdmin) {
			alert('Missing description or accountId')
			return
		} 
		let resp = await dashFetch(`${global.dashServerUrl}/api/v1/account/generateApiToken`, { accountId, description })
		alert('Success: ' + JSON.stringify(resp))
	}

	useEffect(() => {
		fetch()
		// log.info('account.info')
		// log.info(account.info)
	},[])
	
	useEffect(() => {
		if (account && restaurant) {
			if (!isAdmin && (account.appName != restaurant.appName || account.whiteLabelCode != restaurant.whiteLabelCode)) {
				setAccount({...account, appName: restaurant.appName, whiteLabelCode: restaurant.whiteLabelCode}) // if not admin set it based on the site
			} else if (isAdmin && (!account.appName || (createUser && account.appName == 'DashNow' && restaurant.appName != account.appName))) { // if admin set default based on site
				setAccount({...account, appName: restaurant.appName, whiteLabelCode: restaurant.whiteLabelCode})
			}
		}
	}, [restaurant, account])

	useEffect(() => {
		if (restaurants && account && account.info) {
			setFilteredRestaurants(restaurants.filter(rest => account.info.restaurants.indexOf(rest._id) >= 0));
		}
	},[restaurants, account])

	useEffect(() => {
		let data = (restaurant && restaurant.appName == 'TextPay') ? textPayUserTableData : (restaurant && restaurant.driveThruOnly) ? ckeUserTableData : dashNowUserTableData
		setTableData(data)
	}, [restaurant])

	useEffect(() => {
		if (restaurantGroups && account && account.info) {
			setFilteredRestaurantGroups(restaurantGroups.filter(rg => account.info.restaurantGroups.indexOf(rg._id) >= 0));
		}
	},[restaurantGroups, account])

	const upsertAccount = async (account) => {
		let email = account.email
		email = email.trim()

		let data = await createAccountApi(account.firstName, account.lastName, account.email, account.phoneNumber, account.roles, account.info.restaurantGroups, account.info.restaurants, account.info.agency, createUser, account.appName, account.whiteLabelCode, account.affiliate, false)
		if (data) {
			props.refresh(true)
		}
		props.close();
	};
	
	const errorEmail = (email) => {
		const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return email ? !regexp.test(email) : false
	}

	const formatPhoneNumber = (phoneNumberStr) => {
		if (phoneNumberStr.length < 4) {
			phoneNumberStr = phoneNumberStr.replace(/(\d{1,3})/g, "($1");
		} else if (phoneNumberStr.length < 7) {
			phoneNumberStr = phoneNumberStr.replace(/(\d{3})(\d{1,3})/g, "($1) $2");
		} else if (phoneNumberStr.length <= 10) {
			phoneNumberStr = phoneNumberStr.replace(/(\d{3})(\d{3})(\d{1,4})/g, "($1) $2-$3");
		}
		return phoneNumberStr;
	}

	const handleSetPhoneNumber = (phoneNumberStr) => {
		// remove non numeric values from string & set state
		const newPhoneNumber = phoneNumberStr.replace(/\D/g, '');
		if (newPhoneNumber !== account.phoneNumber) {
			setAccount({ ...account, phoneNumber: newPhoneNumber })
			setPhoneNumberFormatted(formatPhoneNumber(newPhoneNumber));
		}
	}

	const saveNewAgency = async () => {
		if (newAgencyName) {
			let newAgency = await createAgencyApi(newAgencyName)
			if (newAgency && newAgency._id) {
				// console.log("AGENCY: " + JSON.stringify(newAgency))
				setAgencies([...agencies, newAgency])
				setAgency(newAgency)
				setAccount({...account, info: {...account.info, agency: newAgency}})
			}
			setShowAddAgencyForm(false)
		}
	}

	let handleSetAgency = (event, value) => {
		if (value) {
			setAgency(value)
			setAccount({...account, info: {...account.info, agency: value}})
		}
	}

	const copyToClipboard = (e) => {
		navigator.clipboard.writeText(global.generateDashboardUrl(account.appName, `/login?a=${account._id}${account.email ? '&e=' + encodeURIComponent(account.email) : ''}`, account.whiteLabelCode));
		global.showNotification("Copied", "success")
	}

	const copyToClipboard2 = (e) => {
		navigator.clipboard.writeText(`https://www.textpay.app?ref=${account.affiliateCode}`);
		global.showNotification("Copied", "success")
	}

	if (global.hasPermission('admin.*') && account && !createUser) {
        document.addEventListener('keypress', (e) => {
            if(e.key == 'ç'){
                navigator.clipboard.writeText(`_id: ObjectId("${account._id}")`);
                global.showNotification("Copied AccountId", "success")
            }
        })
    }
	
	const deactivateAccount = async () => {
		setLoading(true)
		let success = await deactivateAccountApi(account._id)
		setLoading(false)
		setShowConfirmDelete(false)
		if (success) {
			props.refresh(true)
		}
		props.close();
	}

	const dashNowUserTableData = {
		rows: [ //order matters!!!
			{name:"Billing", show: true}, //0
			{name:"User Management", show: true}, //1
			{name:"Branding, Menus, and Reviews", show: true}, //2
			{name:"Generate Table QR Codes", show: payAtTableEnabled}, //3
			{name:"Refund/Void Payments", show: true}, //4
			{name:"View/Export Payments", show: true}, //5
		],
		columns: [
			{name:"", values:[]},
			{name:"Owner", values:[true, true, true, true, true, true]},
			{name:"GM", values:[false, true, true, true, true, true]},
			{name:"Manager", values:[false, false, false, false, true, true]},
			{name:"Manager no void", values:[false, false, false, false, false, true]},
			{name:"Marketing", values:[false, false, true, false, false, false], offset:true},
			{name:"Accounting", values:[true, false, false, false, true, true], offset:true},
		]
	}
	
	const textPayUserTableData = {
		rows: [ //order matters!!!
			{name:"User Management", show: true}, //0
			{name:"Location Settings", show: true}, //1
			{name:"View All Orders", show: true}, //2
			{name:"Refund/Void Payments", show: true}, //3
			{name:"View/Export Payments", show: true}, //4
			{name:"Create Orders", show: true}, //5
		],
		columns: [
			{name:"", values:[]},
			{name:"Owner", values:[true, true, true, true, true, true], offset:true},
			{name:"Manager", values:[false, false, true, true, true, true], offset:true},
			{name:"Technician", values:[false, false, false, false, false, true], offset:true},
		]
	}

	const ckeUserTableData = {
		rows: [ //order matters!!!
			{name:"Billing", show: true}, //0
			{name:"User Management", show: true}, //1
			{name:"Location Settings", show: true}, //3
			{name:"Refund/Void Payments", show: true}, //4
			{name:"View/Export Payments", show: true}, //5
		],
		columns: [
			{name:"", values:[]},
			{name:"CKE Product Manager", values:[true, true, true, true, true], offset:true},
			{name:"CKE Marketing", values:[false, false, true, true, false], offset:true},
			{name:"CKE Accounting", values:[true, false, false, true, true], offset:true},
			{name:"Corporate Store iPad", values:[false, false, false, true, true], offset:true},
		]
	}


	const accountHasRole = (role) => {
		let accountHasRoles = account.roles && account.roles.length > -1
		let foundMatchingRole = accountHasRoles && accountRoles.filter((a) => ( account.roles.indexOf(a._id) > -1 && a.code && a.code.indexOf(role) > -1)).length > 0
		return foundMatchingRole
	}

	let columnsLength = (tableData && tableData.columns && tableData.columns.length) ? tableData.columns.length : 0
	let column1Width = restaurant && restaurant.appName == 'dashnow' ? 22 : 35
 	return (
		<Dialog
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			maxWidth={'lg'}
			open={true}
			style={{ margin: 0, height: '100%', padding:'20px'}}
			onClose={ props.close }
			aria-labelledby="customized-dialog-title"
		>
			<DialogTitle onClose={ props.close } id="customized-dialog-title">
				{createUser? "ADD" : "EDIT"} USER
			</DialogTitle>
			<DialogContent dividers style={{backgroundColor:'var(--background)', overflowY:'auto', overflowX: 'hi'}}>
				<Grid style={{ marginLeft: 0,}} container xs={12} spacing={2}>
					<br/>
					{account &&
						<Grid item container xs={12} md={6}>
							<Paper style={{width:'100%', padding: 20,}}>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<Typography style={{fontSize:14, color:'gray'}}>First Name</Typography>
										<Input
											value={account.firstName}
											fullWidth
											onChange={(event) => setAccount({...account, firstName: event.target.value})}
										/>

									</Grid>
									<Grid item xs={6}>
										<Typography style={{fontSize:14, color:'gray'}}>Last Name</Typography>
										<Input
											fullWidth
											value={account.lastName}
											onChange={(event) => setAccount({...account, lastName: event.target.value})}
										/>
									</Grid>
									<Grid item xs={12}>
										<Typography style={{fontSize:14, color:'gray'}}>Account Phone #</Typography>
										<Input
											value={phoneNumberFormatted ? phoneNumberFormatted : account.phoneNumber ? formatPhoneNumber(account.phoneNumber) : ""}
											inputProps={{ maxLength: 14 }}
											type="tel"
											fullWidth
											error={account.phoneNumber && account.phoneNumber.length < 10}
											pattern="[0-9]*"
											onChange={(event) => handleSetPhoneNumber(event.target.value)}
										/>
									</Grid>
									<Grid item xs={12}>
										<Typography style={{fontSize:14, color:'gray'}}>Email *</Typography>
										<Input
											value={account.email}
											fullWidth
											error={errorEmail(account.email)}
											onChange={(event) => setAccount({...account, email: event.target.value.trim()})}
										/>
									</Grid>
									<Grid item xs={12}>
										<Typography style={{fontSize:14, color:'gray'}}>Role *</Typography>
										{accountHasRole('admin') ?
											<Input
												value={"Admin"}
												fullWidth
												disabled
											/>	
										:
											<Select
												label="Role"
												required
												// style={{width:'100%'}}
												fullWidth
												value={account && account.roles}
												onChange={(e) => setAccount({ ...account, roles: e.target.value})}
											>
												{accountRoles.map((accountRole) => <MenuItem value={accountRole._id}>{(restaurant.appName == 'TextPay' && !isAdmin) ? accountRole.name.replace('TextPay ', '') : accountRole.name}</MenuItem>)}
											</Select>
										}
									</Grid>
									{isAdmin && 
									<>
										<Grid item xs={12} container spacing={2}>
											<span style={{color:'var(--dark-gray)', fontStyle:'italic' }}>These settings affect the link that is sent to the user</span>
											<Grid item xs={6}>
												<SelectMui
													fullWidth
													label="App Name"
													// className={classes.muiSelect}
													value={account && account.appName}
													onChange={(e) => setAccount({ ...account, appName: e.target.value})}
												>
													{['DashNow', 'TextPay', 'Curbly'].map((appName) => <MenuItem value={appName}>{appName}</MenuItem>)}
												</SelectMui>
											</Grid>
											<Grid item xs={6}>
												{account && account.appName == 'TextPay' &&
													<SelectMui
														fullWidth
														label="WhiteLabel"
														// className={classes.muiSelect}
														value={account && account.whiteLabelCode}
														onChange={(e) => setAccount({ ...account, whiteLabelCode: e.target.value})}
													>
														{global.brandingKeys.map((whiteLabelCode) => <MenuItem value={whiteLabelCode}>{whiteLabelCode}</MenuItem>)}
													</SelectMui>
												}	
											</Grid>
										</Grid>

										{account.appName == "TextPay" && <>
											<Grid item xs={3}>
												<Typography style={{fontSize:14, color:'gray'}}>Affiliate</Typography>
												<Checkbox
													color="primary"
													checked={account.affiliate}
													onChange={(event) => setAccount({...account, affiliate: event.target.checked})}
												>
												</Checkbox>
											</Grid>

											<Grid item xs={4}>
												<Typography style={{fontSize:14, color:'gray'}}>Affiliate Code</Typography>
												<Input
													value={account && account.affiliate && account.affiliateCode ? account.affiliateCode : ''}
													joe
													inputProps={{ maxLength: 14 }}
													fullWidth
													disabled
												/>
											</Grid>

											<Grid item xs={5}>
												<Typography style={{fontSize:14, color:'gray'}}>Referred By</Typography>
												<Input
													value={account && account.referredBy ? account.referredBy.firstName + ' ' + account.referredBy.lastName : ''}
													inputProps={{ maxLength: 14 }}
													fullWidth
													disabled
												/>
											</Grid>
										</>}
									</>
									}
									<Grid item xs={12}>
										{account.roles && account.roles.length > 0 && account.info &&
											<>
												<Grid item xs={12} style={{paddingTop:10}}>
													<Typography style={{fontSize:14, color:'gray'}}>Access by:</Typography>
													<ToggleButtonGroup value={accessBySite} onChange={(e,v)=> setAccessBySite(!accessBySite)} style={{width: '100%',}} exclusive> 
														<ToggleButton value={true} style={{width:'50%'}}>
															<Typography color="primary">Location</Typography>
														</ToggleButton>
														<ToggleButton value={false} style={{width:'50%'}}>
															<Typography color="primary">Group</Typography>
														</ToggleButton>
													</ToggleButtonGroup>
												</Grid>
												<br/>
												{!accessBySite &&
													<Autocomplete
														multiple
														filterSelectedOptions
														required
														value={filteredRestaurantGroups ? filteredRestaurantGroups : []}
														options={restaurantGroups ? restaurantGroups.filter((rg) => !rg.deleted).sort((a, b) => (b.name && a.name) ? -b.name.localeCompare(a.name) : 0) : []}
														getOptionLabel={(option) => option.name ? option.name : ''}
														renderInput={(params) => <TextField {...params} label="Select group(s)" variant="outlined" />}
														onChange={(e,rgs) => {setAccount({...account, info:{...account.info, restaurantGroups: rgs.map((rg) => rg._id)}})}}
													/>
												}

												{/* <div style={{margin: '10px'}}><Typography textAlign="center">OR</Typography></div> */}
												{accessBySite && 
													<Autocomplete
														multiple
														id="tags-outlined"
														required
														options={restaurants && restaurants.filter((rg) => !rg.deleted).sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)}
														getOptionLabel={(option) => option.name}
														value={filteredRestaurants ? filteredRestaurants : []}
														filterSelectedOptions
														renderInput={(params) => (
														<TextField
															{...params}
															variant="outlined"
															label="Select location(s)"
														/>
														)}
														onChange={(e,restaurants) => setAccount({...account, info: {...account.info, restaurants: restaurants.map((rest) => rest._id)}})}
													/>
												}
											</>
										}
									</Grid>
									{!createUser &&
										<Grid item lg={12} container justify="flex-end" style={{width:'100%'}}>
											<Button variant="contained" onClick={() => {setShowConfirmDelete(true)}} color="secondary"><DeleteForever style={{paddingRight:6}}/>Deactivate User</Button>
											<DConfirm
												title="Permanently Deactivate User?"
												open={showConfirmDelete}
												setOpen={setShowConfirmDelete}
												loading={loading}
												onConfirm={() => deactivateAccount()}
											>
												Are you sure you want to deactivate this account permanently?
											</DConfirm>
										</Grid>
									}
									<Grid item lg={12}>
										{isAdmin && account.roles && account.info && accountRolesWithAdmin.filter((a) => (a.name == 'Restaurant' || a.name == 'Agent' || a.name == 'Admin') && a._id == account.roles).length > 0 &&
											<>
												<Typography variant="h6">Add API Token</Typography>
												{account.apiTokens && <Typography>API Token Keys: {account.apiTokens}</Typography>}

												<Typography>Description</Typography>
												<Input
													style={{ width: '50%' }}
													value={apiKeyDescription}
													onChange={(event) => setApiKeyDescription(event.target.value)}
												/>
												<br/>
												<Button variant="contained" style={{marginTop: '15px'}} onClick={() => addApiKeyForUser(account._id, apiKeyDescription)} color="primary">Add API Key</Button>
												<br/><br/>
												{account.roles && account.info && accountRolesWithAdmin.filter((a) => (a.name == 'Agent') && a._id == account.roles).length > 0 &&
													<>
														<Autocomplete
															className={classes.agencyField}
															value={account.info.agency ? account.info.agency : null}
															options={agencies ? agencies.sort((a, b) => (a.name && b.name) ? -b.name.localeCompare(a.name) : 0) : []}
															getOptionLabel={(option) => option.name ? option.name : ''}
															renderInput={(params) => <TextField {...params} label="Agency" variant="outlined" />}
															onChange={(event, value) => handleSetAgency(event, value)}
														/>
														<Button size="small" variant="contained" color="primary" className={classes.addButton} onClick={() => setShowAddAgencyForm(true)} color='primary'><Typography style={{color:'white'}}>+</Typography></Button>
														<br/><br/>
														{showAddAgencyForm && 
															<Grid item xs={12}>
																<Typography>Add Agency</Typography>
																<Input
																	style={{ width: '50%' }}
																	value={newAgencyName}
																	onChange={(event) => (event.target.value.trim() != "") ? setNewAgencyName(event.target.value) : null}
																/>
																<Button variant="contained" style={{marginLeft: '7px', marginBottom: '8px'}} onClick={() => saveNewAgency()} color="primary">Save Agency</Button>
															</Grid>
														}
													</>
												}
											</>
										}
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					}
					<Grid item container xs={12} md={6}>
						{tableData && tableData.columns &&
							<Table size='small' padding="none">
								<TableHead>
									{tableData.columns[0] && <TableCell style={{ border:'none', transform:`${tableData.columns[0].offset ?'translate(-8px, 0px)':''} rotate(-60deg)`, height:80, width:`${column1Width}%`}}>{tableData.columns[0].name}</TableCell>}
									{tableData.columns[1] && <TableCell style={{ border:'none', transform:`${tableData.columns[1].offset ?'translate(-8px, 0px)':''} rotate(-60deg)`, height:80, width:`${(100-column1Width)/(columnsLength)}%`}}>{tableData.columns[1].name}</TableCell>}
									{tableData.columns[2] && <TableCell style={{ border:'none', transform:`${tableData.columns[2].offset ?'translate(-8px, 0px)':''} rotate(-60deg)`, height:80, width:`${(100-column1Width)/(columnsLength)}%`}}>{tableData.columns[2].name}</TableCell>}
									{tableData.columns[3] && <TableCell style={{ border:'none', transform:`${tableData.columns[3].offset ?'translate(-8px, 0px)':''} rotate(-60deg)`, height:80, width:`${(100-column1Width)/(columnsLength)}%`}}>{tableData.columns[3].name}</TableCell>}
									{tableData.columns[4] && <TableCell style={{ border:'none', transform:`${tableData.columns[4].offset ?'translate(-8px, 0px)':''} rotate(-60deg)`, height:80, width:`${(100-column1Width)/(columnsLength)}%`}}>{tableData.columns[4].name}</TableCell>}
									{tableData.columns[5] && <TableCell style={{ border:'none', transform:`${tableData.columns[5].offset ?'translate(-8px, 0px)':''} rotate(-60deg)`, height:80, width:`${(100-column1Width)/(columnsLength)}%`}}>{tableData.columns[5].name}</TableCell>}
									{tableData.columns[6] && <TableCell style={{ border:'none', transform:`${tableData.columns[6].offset ?'translate(-8px, 0px)':''} rotate(-60deg)`, height:80, width:`${(100-column1Width)/(columnsLength)}%`}}>{tableData.columns[6].name}</TableCell>}
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell colSpan={columnsLength+1} style={{width:'100%', height:15, borderTop:'none', borderBottom:'1px solid #ccc'}}/>
									</TableRow>
									{tableData.rows.filter(r=>r.show).map((row, index) => {
										return (
											<TableRow >
												{tableData.columns.map((c, i) => {
													let cellVal = c.values[index] 
													if (i == 0) {
														return <TableCell style={{padding:5, fontSize:14}}>{row.name}</TableCell>
													} else {
														return <TableCell style={{ paddingLeft:!cellVal?10:''}}>{cellVal ? <DFlag style={{margin:0,}} checked={cellVal}/> :'-'}</TableCell>
													}
												})}
											</TableRow>
										)
									})}
								</TableBody>
							</Table>
						}
					</Grid>
				</Grid>
			</DialogContent>
						
			<DialogActions >
				{account &&
					<div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
						<div>
							{account._id && !createUser &&
								<Button style={{ float: 'left'}} variant="outlined" color="primary" onClick={ ()=> copyToClipboard() }><AssignmentIcon style={{paddingRight: '10px'}}></AssignmentIcon>Copy Access Link </Button>
							}
							{account._id && !createUser && account.affiliate && account.affiliateCode && account.appName == 'TextPay' &&
								<Button style={{ float: 'left', marginLeft: 15}} variant="outlined" color="primary" onClick={ ()=> copyToClipboard2() }><AssignmentIcon style={{paddingRight: '10px'}}></AssignmentIcon>Copy Referral Link </Button>
							}
						</div>
						<div>
							<Button onClick={props.close} style={{marginLeft: '10px'}}>
								Close
							</Button>
							<Button variant="contained" color="primary" style={{marginLeft: '10px'}} disabled={!account.email || errorEmail(account.email) || (!account.roles || account.roles.length == 0) || (!isAdmin && !(account.getVal('info.restaurants') || account.getVal('info.restaurantGroups'))) } onClick={() => {upsertAccount(account, false);}}>
								{createUser? 'Create' : 'Save'}
							</Button>
						</div>
					</div>
				}
			</DialogActions>
		</Dialog>
	)

}
