import log from 'loglevel';
import dashFetch from '../utils/dashFetch';

export default async function (restaurantId, unassociatedRealmIdId, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('attachRealmId api');
        const url = `${global.dashServerUrl}/dashboard/api/v1/dashboard/attachRealmId`;

		const body = {
            restaurantId,
			unassociatedRealmIdId,
			debug
		};

		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info('>>> attachRealmId JSON');
			log.info(json)
            if (json.success && json.data) {
                resolve(json.data);
            }
            resolve(json);
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}