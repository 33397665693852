import log from 'loglevel';
import dashFetchUpload from '../utils/dashFetchUpload';


export default async function (file, restaurantId, debug) {
	return new Promise( async(resolve, reject) => {
		log.info('bulkAddChecks api');
		const url = `${global.dashServerUrl}/api/v1/check/bulkAddChecks`; 
		const formData = new FormData();
		formData.append('file', file);
        formData.append('restaurantId', restaurantId);
		console.log(formData)
		
		try {
			let response = await dashFetchUpload(url, formData)
			if (response && response.data) {
				// alert(JSON.stringify(response))
				if (response.data.success) {
					resolve(true)
				} else {
					if (response.data.errors && response.data.errors.length) {
						let csvContent = "data:text/csv;charset=utf-8," 
						+ encodeURIComponent(response.data.errors.map(e => e.join(",")).join("\n"));
						var encodedUri = csvContent
						var link = document.createElement("a");
						link.setAttribute("href", encodedUri);
						link.setAttribute("download", "errors.csv");
						document.body.appendChild(link); // Required for FF
						link.click(); //
					} 
					if (response.data.error) {
						global.showNotification(response.data.error, 'error')
					}
					resolve(false)
				}
			} else {
				log.info('bulkAddChecks   FAILURE')
				resolve(false)
			}
		} catch (e) {
			log.error(e)
			resolve(false)
		}
	})
}