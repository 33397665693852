import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useGlobal, setGlobal } from 'reactn';
import { MuiThemeProvider, createMuiTheme, createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { configuredLayout, configuredTheme } from './config'
import { ThemeProvider } from '@material-ui/styles'
import {urlParse} from './utils/urlParser'
import AppRoute from './appRoute';
import theme from './theme';

// DASHBOARD ROUTE
import RestaurantEnrollment from './requests/restaurantEnrollment/restaurantEnrollment';
import MerchantSetup from './requests/merchantSetup/merchantSetup';
import RestaurantOnboarding from './requests/restaurantOnboarding/restaurantOnboarding';
import SignupCurbly from './requests/signup/signupCurbly';
import SignupTextPay from './requests/signup/signupTextPay';
import SignupDashNow from './requests/signup/signupDashNow';
import SoundTest from './dashboard/soundTest';

import BillingSummary from './dashboard/billingSummary';
import RestaurantListing from './admin/pages/restaurantListing';
import RequestListing from './requests/requestListing';
import DashboardSummary from './dashboard/dashboardSummary';
import CustomerListing from './dashboard/customerListing';
import Today from './dashboard/today';
import CheckIn from './dashboard/checkIn';
import Setup from './dashboard/setup';
import Onboard from './dashboard/onboard';
import Demo from './dashboard/demo';
import ReviewsListing from './dashboard/reviewsListing';
import payments from './dashboard/payments';
import SiteList from './dashboard/siteList';
import Status from './dashboard/status';

import TransactionListing from './dashboard/transactionListing';
import Admin from './admin/pages/admin';
import Login from './admin/pages/login';
import accessUtils from './utils/accessUtils';
import history from './history'

import RestaurantInfo from './dashboard/setup/restaurantInfo';
import openRestaurantWebSocket from './actions/openRestaurantWebSocket';
import Invoice from './components/billing/Invoice';
import SiteSignupList from './admin/pages/SiteSignupList';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment'
import PaymentLinks from './dashboard/paymentLinks';


const uuidv4 = require('uuid/v4');

let deviceId = localStorage.deviceId;
if (deviceId === undefined || deviceId === '') {
	deviceId = uuidv4()
	localStorage.deviceId = deviceId
}

localStorage.latestTermsOfService = moment('6/30/2021').toDate()

// global.url = 'http://192.168.10.96:3000'

global.get = useGlobal;
global.set = setGlobal;
// global.pageStack = [];

export default function App(props) {

	const [dashboardData] = global.get('dashboardData')
	const [restaurant] = global.get('restaurant')
	let [dform] = global.get('dform');
	let signedIn = accessUtils.isLoggedIn()
	const [loading, setLoading] = React.useState(true);
	const [activePages] = global.get('activePages')
	const [checkLookback] = global.get('checkLookback');
	const [checkLookbackDateRange] = global.get('checkLookbackDateRange');
	const [darkMode] = global.get('darkMode');
	const [primaryColor, setPrimaryColor] = global.get('primaryColor')
	let [appName, setAppName] = global.get('appName')
	
	let signupForm = SignupDashNow
	if (appName == "TextPay") {
		signupForm = SignupTextPay
	} else if (appName == "Curbly") {
		signupForm = SignupCurbly
	}
	
	const isMobile = useMediaQuery('(max-width:500px)')
	if (isMobile) {
		moment.locale('en', {
			relativeTime: {
				future: 'in %s',
				past: '%s ago',
				s:  'seconds',
				ss: '%s second',
				m:  'a min',
				mm: '%d min',
				h:  'an hour',
				hh: '%d hours',
				d:  'a day',
				dd: '%d day',
				w:  "a week",
				ww: "%d weeks",
				M:  "a month",
				MM: "%d months",
				y:  "a year",
				yy: "%d years"
			}
		});
	} else {
		moment.locale('en', {});
	}


	useEffect(() => {
		if (restaurant && restaurant.appName && restaurant.appName != appName) {
			setAppName(restaurant.appName)
			appName = restaurant.appName
		}
		if ((!restaurant || !restaurant.appName) && global.appName != appName) {
			setAppName(global.appName)	
			appName = global.appName
		}

		['DashNow', 'TextPay', 'Curbly'].forEach(key => {
			global[`is${key}`] = () => appName == key
		})
		global.getAppName = () => appName


		let branding = {
			"heartland": "#BA0C13",
			"shift4" : "#007cba",
			"fis": "#8DC63F",
			"msg": "#3A90CE",
			"elavon": "#0075C9",
			"dashnow": "#8B6BD9"
		}

		global.brandingKeys = Object.keys(branding)
		

		for (let brand of Object.keys(branding)) {
			if ((window.location.href.indexOf(brand) > -1) || (restaurant && restaurant.whiteLabelCode == brand)) {
				global.branding = brand
				break
			}
		}


		if (!global.branding) global.branding = 'dashnow'
		global.primaryColor = branding[global.branding]

		if (global.isTextPay()) {
			if (global.branding == 'dashnow') global.primaryColor = '#E86C00'
			document.title = 'TextPay'
			document.querySelector("link[rel='shortcut icon']").href = "./textpay.ico";
			document.querySelector("link[rel='apple-touch-icon']").href = "./textpay-icon.png";
		} else if (global.isCurbly()) {
			document.title = 'curbly'
			document.querySelector("link[rel='shortcut icon']").href = "./curbly.ico";
		} else {
			document.title = 'dashboard'
			document.querySelector("link[rel='shortcut icon']").href = "./dashNow.ico";
		}

		if (global.branding != 'dashnow') {
			document.querySelector("link[rel='shortcut icon']").href = `./${global.branding}.ico`;
		}

		if (!global.isProd) {
			document.title = `[${global.environment}] ` + document.title 
		}

		document.documentElement.style.setProperty('--purple', `${global.primaryColor}`);
		document.documentElement.style.setProperty('--dash-purple', `${global.primaryColor}`);
		document.documentElement.style.setProperty('--dashnow', `${global.primaryColor}`);
		document.documentElement.style.setProperty('--purple-text', `${global.primaryColor}`);
		
		
		setPrimaryColor(global.primaryColor)

	}, [restaurant, appName])

	useEffect(() => {
		
		if (darkMode != undefined) {

			// localStorage.setItem('darkMode', darkMode)
			if (darkMode) {
				document.documentElement.style.setProperty('--black', '#FFF');
				document.documentElement.style.setProperty('--white', '#2c2c2c');
				document.documentElement.style.setProperty('--background', "#555");
				document.documentElement.style.setProperty('--text', "#f8f8f8");
				document.documentElement.style.setProperty('--dark-gray', "#d2d2d2");
				document.documentElement.style.setProperty('--light-gray', "#C9C9C9");
				document.documentElement.style.setProperty('--gray', "#c8c6c6");
			} else {
				document.documentElement.style.setProperty('--black', '#222222;');
				document.documentElement.style.setProperty('--white', '#ffffff;');
				document.documentElement.style.setProperty('--background', "#F5F5F5;");
				document.documentElement.style.setProperty('--text', "#373737;");
				document.documentElement.style.setProperty('--dark-gray', "#4c4c4c");
				document.documentElement.style.setProperty('--light-gray', "#838383");
				document.documentElement.style.setProperty('--gray', "#666666");
			}
		} else {
			global.set({darkMode:localStorage.darkMode == 'true'})
		}

	}, [darkMode])

	

	useEffect(() => {
		['DashNow', 'TextPay', 'Curbly'].forEach(key => {
			global[`is${key}`] = () => appName == key
		})

		let params = urlParse(window.location.href).params

		let accountIdIn = params.a
		if (accountIdIn) {
			localStorage.accountIdIn = accountIdIn
			if (signedIn) {
				if (accountIdIn && accountIdIn == localStorage.accountId) {
					history.push('/')
				} else {
					accessUtils.logout()
				}
			}
		}

		let restaurantIdIn = params.r
		if (restaurantIdIn) {
			localStorage.restaurantIdIn = restaurantIdIn
			global.set({restaurantId: restaurantIdIn})
		}

		setLoading(false)
		if (!activePages || (activePages && !Object.keys(activePages).length > 0)) {
			global.set({activePages: {'basic': 'today', 'admin': 'admin/restaurants/addEditRestaurant'}}) 
		}
	}, [])

	useEffect(() => {
		if (restaurant) {
			openRestaurantWebSocket(restaurant._id, restaurant.dashboardAutoFetchInterval, checkLookback, checkLookbackDateRange)
			if(restaurant?.qboConnectionFailed) global.showQboConnectionDialog()
		}
	}, [restaurant, checkLookback, checkLookbackDateRange])

	useEffect(() => {
		if (signedIn) {
			if (!localStorage.tosAcceptance || localStorage.tosAcceptance == 'undefined' || moment(localStorage.latestTermsOfService).clone() > moment(localStorage.tosAcceptance).clone()) {
				global.showNotification('You must agree to Terms Of Service before continuing', 'info')
				global.showTermsOfUse()
			}
		}

	}, [signedIn])

	return (
		<React.Fragment>
			<CssBaseline />
			{!loading && 
				<ThemeProvider theme={ theme() }>
					{/*  TODO determine way to change layouts from here without breaking the left nav stuff */}
					<AppRoute path="/" exact component={Today} props={props} requireToken={false} />
					<AppRoute path="/checkIn" exact component={CheckIn} props={props} requireToken={true} permission={'restaurant.*'} />
					<AppRoute path="/today" exact component={Today} props={props} permission={'restaurant.*'} />
					<AppRoute path="/links" exact component={PaymentLinks} props={props} permission={'restaurant.*'} />
					<AppRoute path="/setup" exact component={Setup} props={props} showTopNav={false} permission={'restaurant.*'} />
					<AppRoute path="/payments" exact component={payments} props={props} permission={'restaurant.payments'}/>
					<AppRoute path="/reviewsListing" exact component={ReviewsListing} props={props} permission={'restaurant.reviews'} />
					<AppRoute path="/dashboard" exact component={DashboardSummary} props={{...props, dashboardData}} permission={'dashboard.view'}/>
					<AppRoute path="/login" exact component={Login} props={props} />
					<AppRoute path="/restaurantListing" exact component={RestaurantListing} props={props} permission={'admin.restaurant.view'}/>
					<AppRoute path="/siteListing" exact component={SiteList} props={props} requireToken={true} permission={'agent.sites.view'}/>
					{/* <AppRoute path="/signupListing" exact component={SiteSignupList} props={props} permission={'admin.restaurant.view'}/> */}
					<AppRoute path="/requestListing" exact component={RequestListing} props={props} permission={'request.view'}/>
					<AppRoute path="/customerListing" exact component={CustomerListing} props={props} permission={'restaurant.customers'}/>
					<AppRoute path="/transactionListing" exact component={TransactionListing} props={props} permission={'restaurant.*'}/>
					<AppRoute path="/admin/:page" exact component={Admin} props={props} permission={'admin.*'}/>
					<AppRoute path="/admin/:page/:subPage" exact component={Admin} props={props} permission={'admin.*'}/>
					<AppRoute path="/admin/:page/:subPage/:id" exact component={Admin} props={props} permission={'admin.*'}/>
					<AppRoute path="/restaurantEnrollment" exact component={RestaurantEnrollment} props={props} showHeader={false} showTopNav={false} requireToken={false}/>
					<AppRoute path="/soundTest" exact component={SoundTest} props={props} showTopNav={true} requireToken={false}/>
					<AppRoute path="/restaurantOnboarding" exact component={RestaurantOnboarding} props={props} showTopNav={false} requireToken={true}/>
					<AppRoute path="/onboard" exact component={Onboard} props={props} showTopNav={false} showHeader={false} requireToken={false}/>
					<AppRoute path="/demo" exact component={Demo} props={props} showTopNav={false} showHeader={false} requireToken={false}/>
					<AppRoute path="/signup" exact component={signupForm} props={props} showTopNav={false} showHeader={false} showFooter={false} requireToken={false}/> 
					<AppRoute path="/merchantSetup" exact component={MerchantSetup} props={props} showTopNav={false} showHeader={false} requireToken={false}/>
					<AppRoute path="/billingSummary" exact component={BillingSummary} props={props} permission={'restaurant.billing.*'} requireToken={true}/>
					<AppRoute path="/restaurantinfo" exact component={RestaurantInfo} props={props} permission={'restaurant.branding'}/>
					<AppRoute path="/restaurantinfo/:page" exact component={RestaurantInfo} props={props} permission={'restaurant.branding'}/>
					<AppRoute path="/invoice/:id/:graph" exact component={Invoice} props={props} showTopNav={false} requireToken={false} /> 
					<AppRoute path="/status" exact component={Status} props={props} showTopNav={false} showHeader={false} requireToken={false} /> 
				</ThemeProvider>
			}
		</React.Fragment>
	);
};