import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from '../theme'
import { Container, Grid, TextField, Typography, Paper, FormControl, InputLabel, Button, TableHead, TableRow, TableCell, Table, TablePagination, Slide, CircularProgress, LinearProgress, IconButton, Menu, MenuItem} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import getRestaurantOrdersAndInvoices from "../actions/getRestaurantOrdersAndInvoicesApi";
import log from 'loglevel'
import { withRouter } from 'react-router-dom';
import moment from 'moment'
import MasterInvoice from "../components/billing/MasterInvoice";
import Invoice from "../components/billing/Invoice";
import PaymentMethod from '../dashboard/components/PaymentMethod'
import cancelOrderApi from "../actions/cancelOrderApi";
import DConfirm from '../components/dash/DConfirm'; 
import GetAppIcon from '@material-ui/icons/GetApp';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        padding: 15,
        margin: 0,
        width: "100%",
        height: "100%",
        overflow: 'auto',
    },
    deletedRow: {
        display: 'none',
        color: '#DDD'
    }
})

const Admin = (props) => {

    let {classes} = props;
    let [restaurant] = global.get('restaurant', null);
    let [orders, setOrders] = useState(null) 
    let [orderToCancel, setOrderToCancel] = useState(null) 
    let [invoices, setInvoices] = useState(null)
    let [selectedInvoice, setSelectedInvoice] = useState(null)
    let [loading, setLoading] = useState(false)
    let [downloading, setDownloading] = useState(false)
    let [hasInProgressInvoice, setHasInProgressInvoice] = useState(true)
    let [confirmOpen, setConfirmOpen] = useState(false);

    let smallScreen = useMediaQuery(theme().breakpoints.down('sm'));

    let [page, setPage] = useState(0)
    let [rowsPerPage, setRowsPerPage] = useState(5)

    // GET EncryptedCard from billingAppliesTo source
    let encryptedCard = restaurant && restaurant.billingInfo && restaurant.billingInfo.encryptedCard ? restaurant.billingInfo.encryptedCard : null
	if (restaurant && restaurant?.billingInfo?.billingAppliesTo !== "restaurant") {
		if (restaurant?.billingInfo?.billingAppliesTo === "restaurantGroup") {
			encryptedCard = restaurant?.restaurantGroup?.billingInfo?.encryptedCard
		} else if (restaurant?.billingInfo?.billingAppliesTo === "agency") {
			encryptedCard = restaurant?.agent?.info?.agency?.billingInfo.encryptedCard
		}
	}

    let handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    let handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        updateSelectedRestaurant(restaurant)
    }, [restaurant]);


    const updateSelectedRestaurant = async (rest) => {
        setOrders(null)
        setInvoices(null)
        setSelectedInvoice(null)
        if (rest) {
            setLoading(true)
            let res = await getRestaurantOrdersAndInvoices(rest, true, false)
            let orders = res.orders ? _.sortBy(res.orders, ['_id']).reverse() : []
            let invoices = res.invoices ? _.sortBy(res.invoices, ['_id']).reverse() : []
            setHasInProgressInvoice(_.find(invoices, {status: "inProgress", deleted: false}))
            setOrders(orders)
            setInvoices(invoices)
            if (invoices) {
                setSelectedInvoice(invoices[0])
            }
            setLoading(false)
            setPage(0)
        }
            
    }

    let openCancelWindow = async (order) => {
        setConfirmOpen(true)
        setOrderToCancel(order)
        console.log("ORDER TO CANCEL: " + order._id)
    }

    const downloadInvoice = async (invoice) => {
        if (invoice) {
            setDownloading(true)
            const url = `${global.dashServerUrl}/pdf/invoice/${invoice._id}`;
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                }
            }
            fetch(url, options, false)
            .then((res => res.blob()))
            .then(blob => {
                if (blob) {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = (restaurant.name + "-invoice-" + moment(invoice.invoiceDate).utc().format('L') + ".pdf").replace(/[&']/g, "").replace(/[\s\"/]/g, "-");
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                } else {
                    global.showNotification("An error occured, please try again", "error")
                }
            }).catch((err) => {
                log.error(err)
            }).finally(() => setDownloading(false))
            
        } else {
            global.showNotification("An error occured, please try again", "error")
        }
    }

    const cancelOrder = async () => {
        if (orderToCancel) {
            let orderResp = await cancelOrderApi(orderToCancel)
            if (orderResp) {
                orders.removeItem(orderToCancel)
                let recs = [...orders]
                recs = orders ? _.sortBy(orders, ['_id']).reverse() : []
                setOrders(recs)
            }
        }
    }

    return (
        <div className={classes.root}>
            {restaurant && restaurant?.billingInfo?.billingType && restaurant?.billingInfo?.billingType != 'direct' && 
                <Grid container spacing={2}>
                    <Grid item xs={12} container>
                        <Grid item style={{display: 'flex', flexGrow: 1, marginBottom: 15}}>
                            <Paper elevation={1} style={{width: '100%', padding: 35}}>
                                Billing for this account has not been setup yet or the account will be billed on your monthly Card Connect merchant processing statement.  
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            }

            {restaurant && restaurant?.billingInfo?.billingType && restaurant?.billingInfo?.billingType == 'direct' &&
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} container>
                        <Grid item style={{display: 'flex', flexGrow: 1, marginBottom: 15}}>
                            <Paper elevation={1} style={{width: '100%'}}>
                                <Typography align="center" variant="h5" style={{paddingTop: 10, paddingBottom: 20}}>Statements</Typography>

                                <Table size="small" aria-label="a dense table" style={{}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Statement #
                                            </TableCell>
                                            <TableCell>
                                                Statement Date
                                            </TableCell>
                                            <TableCell>
                                                Amount
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    {(!invoices || invoices.length < 1) &&
                                        <TableRow>
                                            <TableCell colSpan={3}>
                                                There are no invoices available at this time.
                                            </TableCell>
                                        </TableRow>
                                    }
                                    {invoices && invoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(i => {
                                        return (
                                            <TableRow id={i._id} selected={selectedInvoice && selectedInvoice._id == i._id} onClick={(event) => setSelectedInvoice(i)} style={{cursor: 'pointer'}} className={i.deleted && classes.deletedRow}>
                                                <TableCell>
                                                    {i.status.toUpperCase() == "INPROGRESS" && "In-Progress"}
                                                    {i.status.toUpperCase() != "INPROGRESS" && i.invoiceNumber}
                                                </TableCell>
                                                <TableCell>
                                                    {i.status.toUpperCase() != "INPROGRESS" && `${moment(i.invoiceDate).utc().format("MM/DD/YYYY")}`}
                                                </TableCell>
                                                <TableCell style={{paddingRight:2}}>
                                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                                        ${(i.totals.total/100).toFixed(2)}
                                                        {i.status.toUpperCase() != "INPROGRESS" &&<>
                                                            {downloading &&
                                                                <div style={{padding:'2px 3px 0px 3px'}}>
                                                                    <CircularProgress color="primary" size={17}/>
                                                                </div>
                                                            }
                                                            {!downloading &&
                                                                <GetAppIcon color="primary" style={{paddingLeft:2}} onClick={() => downloadInvoice(i)}/>
                                                            }
                                                        </>}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </Table>
                                {(invoices && invoices.length > 0) && 
                                    <TablePagination
                                        rowsPerPageOptions={[5]}
                                        component="div"
                                        count={invoices ? invoices.length : 0}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                }
                            </Paper>
                        </Grid>

                        {global.hasPermission('restaurant.billing.updatePaymentMethod') && <PaymentMethod />}

                        {/* Orders */}
                        <Grid item style={{display: 'flex', flexGrow: 1, marginTop: 15}} xs={12}>
                            <Paper elevation={1} style={{width: '100%'}}>
                                <Typography align="center" variant="h5" style={{paddingTop: 10, paddingBottom: 20}}>Plans</Typography>

                                {orders &&
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Order Date
                                                </TableCell>
                                                <TableCell>
                                                    Plan Name
                                                </TableCell>
                                                <TableCell>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {!orders && 
                                            <TableRow><TableCell align="center" colSpan={3}><LinearProgress style={{marginTop: 10, marginBottom: 10, paddingTop: 2, paddingBottom: 2}} /></TableCell></TableRow>
                                        }
                                        {orders && orders.map((o) => {
                                            return (
                                                <>
                                                    {o.orderProductPackages && o.orderProductPackages.map((opp, index) => {
                                                        return (
                                                            <TableRow className={opp.terminationDate && classes.deletedRow}>
                                                                <TableCell>
                                                                    {new Date(opp.effectiveDate ? opp.effectiveDate : opp.createdAt).toLocaleDateString()}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {opp.name}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {!o.cancelled && !global.isDashNow() && !opp.productPackageType.isAddOn &&
                                                                        <Button variant="contained" color="primary" onClick={() => openCancelWindow(o)}>
                                                                            Cancel
                                                                        </Button>
                                                                    }
                                                                    {o.cancelled && !global.isDashNow() &&
                                                                        'Canceled'
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })} 

                                                </>
                                            )
                                        })}
                                    </Table>
                                }
                                <DConfirm
                                    title="Cancel Order"
                                    open={confirmOpen}
                                    setOpen={setConfirmOpen}
                                    onConfirm={() => cancelOrder()}
                                >
                                    Are you sure you want to cancel this order?
                                </DConfirm>
                            </Paper>
                        </Grid>

                    </Grid>

                    {/* INVOICES */}
                    <Grid item xs={12} md={8} container style={{overflowY:'none', maxHeight:'100%'}}>
                        {selectedInvoice && !selectedInvoice.isMasterInvoice &&
                            <Invoice invoice={selectedInvoice} />
                        }

                        {selectedInvoice && selectedInvoice.isMasterInvoice &&
                            <MasterInvoice invoice={selectedInvoice} />
                        }

                        {!selectedInvoice &&
                            <Grid item style={{display: 'flex', flexGrow: 1, overflowY:'auto'}}>
                                <Paper elevation={props.elevation ? props.elevation : 1} style={{padding: 50, width: '100%'}}>
                                    {!loading &&
                                        <span>There are no invoices available at this time. {!encryptedCard && global.hasPermission('restaurant.billing.updatePaymentMethod') && ' In the meantime, please update your payment method.'}</span>
                                    }{loading &&
                                        <LinearProgress/>
                                    }
                                </Paper>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            }
        </div>
    );
};

Admin.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withRouter(withStyles(styles, { withTheme: true })(Admin));