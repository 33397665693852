import log from 'loglevel';
import dashFetch from '../utils/dashFetch';


export default async function (paymentId, refundAmount, refundReason, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('requestRefundApi');
		const url = `${global.dashServerUrl}/api/v1/payment/requestRefund`;

		const body = {
			paymentId,
			refundAmount,
			refundReason,
			debug
		};

		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info('>>> requestRefundApi JSON');
			log.info(json)
			if (json.success && json.success == true && !json.errors) {
				global.showNotification("Successfully refunded", "success", 5000)
			} else if (json.errors) {
				global.showNotification("ERROR " + JSON.stringify(json.errors), "error", 5000)
			} else {
				global.showNotification("ERROR: Refund not successful", "error", 5000)
			}
			//TODO: return the whole refundResponse
			resolve(json.success)
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		})
	})
}