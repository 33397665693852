import React, {useEffect, useState} from 'react';
import { Line } from 'react-chartjs-2';
import theme from '../../theme'
import { Typography, Grid, Paper} from '@material-ui/core';
import moment from 'moment'
import log from 'loglevel'
// import _ from lodash

let noStateLabels = []
for (let i = 0; i < 8; i++) {
	noStateLabels.unshift(moment().subtract(i, 'days').format("MMM Do"))
}
export default function LoyaltyChart(props) {
	const [labels, setLabels] = useState([])
	const [dashboardData] = global.get('dashboardData')

	useEffect(() => {
		setLabels(noStateLabels)
	})
	let data = {
		labels: dashboardData ? dashboardData.map(i => moment(i.aggregateDate).format('MM/DD') ): global._.times(7, (i) => moment().subtract('days', i).format("MM/DD")).reverse(),
		datasets: [
			{
				label: 'Total Loyal Customers',
				fill: 'origin',
				backgroundColor: '#dbdbdb85',
				borderColor: '#9575CD85',
				borderWidth: '2',
				pointBackgroundColor: '#9575CD45',
				pointHoverBackgroundColor: '#9575CD75',
				pointBorderColor: '#FFF',
				pointBorderWidth: '.75',
				lineTension: 0.3,
				data: dashboardData ? dashboardData.map(i => i.loyaltyTotalCustomers) : [1, 3, 7, 11, 15, 18, 21],
			},
			{
				label: 'New Loyal Customers',
				fill: 'origin',
				backgroundColor: '#9575CD98',
				borderColor: '#9575CD85',
				borderWidth: '2',
				pointBackgroundColor: '#9575CD45',
				pointHoverBackgroundColor: '#9575CD75',
				pointBorderColor: '#FFF',
				pointBorderWidth: '.75',
				lineTension: 0.3,
				data: dashboardData ? dashboardData.map(i => i.loyaltySignupRewardCount) : [1, 3, 7, 11, 15, 18, 21],
			},
		]
	}
	const options = {
		plugins: {
			datalabels: {
				display: false,
			}
		},
		scales: {
			yAxes: [{
				stacked: true,
				ticks: {
					// stepSize: 25
				},
			}],
		}
	}
	const styles = {
		infoBanner: {
			// width: '97%', //TODO
			padding: 15,
		},
		paper: {
			padding:'1em',
		},
	}
	// console.log("element " + Element)
	return (
		<Grid style={styles.infoBanner}>
			<Paper style={styles.paper}>
				<Grid container>
					<Grid item lg={12}>
						<Typography align='center' variant="h5">Loyal Customers</Typography>
						<Line data={data} options={options} height="100" />
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
}
