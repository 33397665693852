import React from 'react';

export default (props) => {
    return (
        <svg width={props.width ? props.width : "60px"} height={props.height ? props.height :"32px"} viewBox="0 0 30 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink">
            <title>Group</title>
            <desc>Created with Sketch.</desc>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Group" fill-rule="nonzero">
                    <path d="M33.8315864,5.25844461 C33.8317505,5.05650977 33.7519539,4.86278922 33.6097679,4.71994143 C33.4675822,4.57709363 33.2746669,4.49683287 33.0735036,4.49683287 L24.3202776,4.49683287 C22.7242811,4.49683287 21.4268765,5.73150117 21.4268765,7.33392981 C21.4268765,8.93635845 22.7242811,10.1707161 24.3199681,10.1707161 L31.3500256,10.1707161 C32.034466,10.1707161 32.5904966,10.7981451 32.5904966,11.4845896 C32.5904966,12.1710341 32.034466,12.7990843 31.350335,12.7990843 L22.5983465,12.7990843 C22.185722,12.8074538 21.8555786,13.1456277 21.8555786,13.5599195 C21.8555786,13.9742113 22.185722,14.3123852 22.5983465,14.3207547 L31.3506444,14.3207547 C32.9466409,14.3207547 34.245283,13.0867076 34.245283,11.4845896 C34.245283,9.88185036 32.9466409,8.64842449 31.3506444,8.64842449 L24.3202776,8.64842449 C23.6373841,8.64842449 23.0807348,8.02006373 23.0807348,7.33392981 C23.0807348,6.6474853 23.6373841,6.02005637 24.3202776,6.02005637 L33.0731941,6.02005637 C33.2743574,6.02005644 33.4672727,5.9397956 33.6094585,5.79694782 C33.7516444,5.65410002 33.8314413,5.46037948 33.8312769,5.25844461 M18.3945455,1.10716362 C18.3945455,0.711612159 18.1843276,0.346107535 17.8430789,0.1483318 C17.5018302,-0.0494439333 17.0813945,-0.0494439333 16.7401458,0.1483318 C16.3988971,0.346107535 16.1886792,0.711612159 16.1886792,1.10716362 C16.1886792,1.71863316 16.6824792,2.21432715 17.2916123,2.21432715 C17.9007455,2.21432715 18.3945455,1.71863316 18.3945455,1.10716362" id="Shape" fill={props.inverted? "#FFFFFF" :"#8DC63F"}></path>
                    <path d="M13.8018868,2.28301887 C13.1714485,2.28301887 12.6603774,1.77194769 12.6603774,1.14150943 C12.6603774,0.511071182 13.1714485,0 13.8018868,0 C14.4323251,0 14.9433962,0.511071182 14.9433962,1.14150943 C14.9433962,1.77194769 14.4323251,2.28301887 13.8018868,2.28301887" id="Path" fill="#A3CF62"></path>
                    <path d="M10.2735849,2.28301887 C9.64314665,2.28301887 9.13207547,1.77194769 9.13207547,1.14150943 C9.13207547,0.511071182 9.64314665,0 10.2735849,0 C10.9040232,0 11.4150943,0.511071182 11.4150943,1.14150943 C11.4150943,1.77194769 10.9040232,2.28301887 10.2735849,2.28301887" id="Path" fill="#B9D989"></path>
                    <path d="M6.74528302,2.28301887 C6.11484476,2.28301887 5.60377358,1.77194769 5.60377358,1.14150943 C5.60377358,0.511071182 6.11484476,0 6.74528302,0 C7.37572128,0 7.88679245,0.511071182 7.88679245,1.14150943 C7.88679245,1.77194769 7.37572128,2.28301887 6.74528302,2.28301887" id="Path" fill="#CFE5AE"></path>
                    <path d="M3.21762172,2.28301905 C2.58718356,2.28337247 2.07582566,1.77258816 2.07547151,1.14215001 C2.0751181,0.511711857 2.58590241,0.000353961774 3.21634057,-1.80258613e-07 C3.62416254,-0.000228675784 4.00112767,0.217130287 4.20523686,0.57020005 C4.40934604,0.923269813 4.40959023,1.35841068 4.20587744,1.7117093 C4.00216463,2.06500792 3.62544369,2.28278983 3.21762172,2.28301905" id="Path" fill="#E5F1D4"></path>
                    <g transform="translate(0.000000, 4.358491)" fill={props.inverted? "#FFFFFF" :"#8DC63F"}>
                        <path d="M12.5151517,0.0797594635 C12.4912465,0.0797594635 12.4685831,0.0847217893 12.4459197,0.0865826614 L12.4459197,0.0797594635 L4.97756071,0.0797594635 C2.010521,0.0797594635 -7.44803706e-16,2.02002887 -7.44803706e-16,4.98408813 L-7.44803706e-16,9.12918094 C-7.44803706e-16,9.58677832 0.371328975,9.9577343 0.82938648,9.9577343 C1.28744399,9.9577343 1.65877296,9.58677832 1.65877296,9.12918094 L1.65877296,4.98408813 C1.65877296,2.92906495 2.90308553,1.59947176 4.97756071,1.59947176 L12.4459197,1.59947176 L12.4459197,1.59264856 C12.4685831,1.59481957 12.4912465,1.59947176 12.5151517,1.59947176 C12.9348899,1.59947176 13.2757719,1.25893214 13.2757719,0.83961561 C13.2757719,0.420299075 12.9348899,0.0797594635 12.5151517,0.0797594635" id="Path"></path>
                        <path d="M2.48893558,4.98408813 C2.48893545,5.40384556 2.82937644,5.74422218 3.24955579,5.74456457 L10.8551369,5.74456457 C11.0569736,5.74456457 11.2505344,5.66442346 11.3931963,5.5217886 C11.5358581,5.37915373 11.6159219,5.18572197 11.6157576,4.98408813 C11.6157576,4.78250803 11.5357393,4.58915595 11.3930864,4.4465882 C11.2504335,4.30402044 11.0569197,4.22392184 10.8551369,4.22392184 L3.24924532,4.22392184 C2.82918717,4.22426409 2.48879629,4.56445182 2.48862514,4.98408813 M16.5945597,0.83961561 L16.5945597,9.12856065 C16.5945597,9.58651541 16.9659967,9.95785711 17.4244118,9.95819952 C17.8828772,9.95785723 18.2544032,9.5865656 18.2545745,9.12856065 L18.2545745,0.83961561 C18.2544922,0.6197747 18.1669727,0.408975109 18.0112781,0.253611377 C17.8555834,0.0982476428 17.6444738,0.0110527707 17.4244118,0.0112168732 C16.9663897,0.0110459662 16.5949025,0.381743572 16.5945597,0.839305463" id="Shape"></path>
                    </g>
                </g>
            </g>
        </svg>
        
    )
}