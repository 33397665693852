import React, { useState } from 'react';
import {
	Paper,
	ListItem,
	List,
	Typography,
	ListItemText,
	Tabs,
	Badge,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TablePagination,
	TableFooter,
	CardContent,
	Grid
} from '@material-ui/core';
import moment from 'moment'

const tabs = [
	{
		title: 'F / S / O',
		alertCount: 3
	},
	{
		title: 'Comments',
		alertCount: 1
	}
];

const styles = {
	infoBanner: {
		width: '100%',
		padding: 15
	},
	paper: {
		width: '100%',
		padding: '1em'
	}
};


export default () => {
	let [ page, setPage ] = useState(0);
	const [loyalCustomerData, setLoyalCustomerData] = global.get('dashboardCustomerData');
	let [ rowsPerPage, setRowsPerPage ] = useState(5);
	const [activeTab, setActiveTab] = useState(0);

	const setTab = (e, ntv) => {
		setActiveTab(ntv)
		if (loyalCustomerData) {
			setLoyalCustomerData(loyalCustomerData.sort((a, b) => ntv == 1 ? b.customerPaymentCount - a.customerPaymentCount : b.customerPaymentDollars - a.customerPaymentDollars))
		}
	}

	return (
		<Grid container style={styles.infoBanner}>
			<Paper style={styles.paper}>
				<CardContent color="primary">
					<Typography variant="h5" align="center">
						Top Loyal Customers
					</Typography>
				</CardContent>
				<Tabs
					indicatorColor="primary"
					value={activeTab}
					onChange={setTab}
				>
					<Tab
						label={<Badge style={styles.padding} color="secondary"><span style={{textColor: '#000000'}}>{"By Spend"}</span></Badge>}
					/>
					<Tab
						label={<Badge style={styles.padding} color="secondary"><span style={{textColor: '#000000'}}>{"By Visits"}</span></Badge>}
					/>
				</Tabs>
				<Table style={{ tableLayout: 'auto', width: '100%' }}>
					<TableHead>
						<TableRow>
							<TableCell style={{ fontWeight: 'bold' }}>Customer</TableCell>
							<TableCell style={{ fontWeight: 'bold' }} align='right'>Total Spent</TableCell>
							<TableCell style={{ fontWeight: 'bold' }} align='right'>Avg. Spent</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}># of Visits</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}>Last Visited</TableCell>
						</TableRow>
					</TableHead>
					<TableBody style={{ width: '100%' }}>
						{loyalCustomerData && loyalCustomerData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n) => (
							<TableRow key={n.user}>
								<TableCell>{n.firstName + " " + n.lastName}</TableCell>
								<TableCell align='right'>${(Number(n.customerPaymentDollars)).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}</TableCell>

								<TableCell align='right'>${(Number(n.customerPaymentDollars / n.customerPaymentCount)).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}</TableCell>
								<TableCell align='center'>{n.customerPaymentCount}</TableCell>
								<TableCell align='center'>{moment(n.lastVisit).format('MM/DD')}</TableCell>
							</TableRow>
						))}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								colSpan={4}
								count={loyalCustomerData ? loyalCustomerData.length : 10}
								rowsPerPage={rowsPerPage}
								rowsPerPageOptions={[ 5, 10, 15 ]}
								page={page}
								backIconButtonProps={{
									'aria-label': 'Previous Page'
								}}
								onChangeRowsPerPage={(e) => setRowsPerPage(e.target.value)}
								nextIconButtonProps={{
									'aria-label': 'Next Page'
								}}
								onChangePage={(e, p) => setPage(p)}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</Paper>
		</Grid>
	);
};
