import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import log from 'loglevel';
import _ from 'lodash';
import {
    Grid,
    Button,
    Typography,
    FormControl,
    MenuItem,
    Paper,
    InputLabel,
    TextField
} from '@material-ui/core';
import DForm2 from '../../../components/dash/DForm2';
import DRecord from '../../../components/dash/DRecord';
import DInput from '../../../components/dash/DInput';
import AddIcon from '@material-ui/icons/Add';
import InputAdornment from '@material-ui/core/InputAdornment';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import encryptFieldCommissionBuddies from '../../../actions/encryptFieldCommissionBuddies';

const styles = (theme) => ({
    root: {
        height: '100%',
        width: '100%',
    },
    restaurantFieldInputItem: {
        width: '100%',
        // marginTop: '15px'
    },
    muiSelect: {
        marginBottom: '15px',
    },
    subheadingText: {
        fontSize: '16px',
    },
    box: {
        backgroundColor: 'var(--white)',
        border: '1px solid #EAEAEA',
        boxShadow: '0 0 10px 5px rgba(231,231,231,0.50)',
        padding: 30,
        marginBottom: 20,
        borderRadius: 6,
    },
});

const Dash = (props) => {
    const { classes } = props;
    const [formattedSSN, setFormattedSSN] = useState({})
    const [record, setRecord] = useState(props.record ? props.record : {})
    const [encryptedSSN, setEncryptedSSN] = useState({});
    const [ssnMasked, setSsnMasked] = useState({});
    let [globalRequestObj] = global.get('request', null)
    
    function addOwner() {
        let ownersClone = [...props.record.owners];
        let recordClone = { ...props.record };
        ownersClone.push({})
        recordClone.owners = ownersClone
        props.setRequest({...globalRequestObj, data:recordClone})
    }

    const removeOwner = async (index) => {
        let ownersClone = [...props.record.owners];
        ownersClone.removeItemAtIndex(index)
        let recordClone = { ...props.record };
        recordClone.owners = ownersClone;
        props.setRequest({...globalRequestObj, data:recordClone})
    }

    function hyphenateSSN(input, index) {
        if (input) {
            let value = input.toString()
            var val = value.replace(/\D/g, '');
            var newVal = '';
            if (val.length > 4) {
                value = val;
            }
            if ((val.length > 3) && (val.length < 6)) {
                newVal += val.substr(0, 3) + '-';
                val = val.substr(3);
            }
            if (val.length > 5) {
                newVal += val.substr(0, 3) + '-';
                newVal += val.substr(3, 2) + '-';
                val = val.substr(5);
            }
            newVal += val;
            value = newVal;
            return value
        }
    }

    useEffect(() => {
        const SSNs = {}
        props.record.owners.map((owner, index) => {
            SSNs[index] = owner.ssnLast4
        })
        setFormattedSSN(SSNs)
    }, [])
    
    const handleSetSSN = async (event, index) => {
        
        const value = hyphenateSSN(event.target.value)
        setFormattedSSN({ ...formattedSSN, [index]: value })
        if (value && value.length == 11) {
            setSsnMasked({ ...ssnMasked, [index]: hideSocial(value)})
            let ssnEncryptedStr = await encryptFieldCommissionBuddies(value)
            setEncryptedSSN({ ...encryptedSSN, [index]: ssnEncryptedStr})
        }
    }

    const clearSsn = (index) => {
        if (formattedSSN[index] && formattedSSN[index].indexOf('*') > -1) {
            setFormattedSSN({...formattedSSN, [index]: ''})
        }
    }

    const hideSocial = (social) => social.substring(0,6).replace(/\d/g,'*') + social.substring(6,11)
    
    return (
        <>
            {props.record && props.record.owners && 
                <DForm2 record={props.record.owners}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="subtitle1" color="primary" gutterBottom>
                                Company Owner(s) Information
                            </Typography>
                        </Grid>

                        {props.record && props.record.owners && (props.record.owners.length > 0) && props.record.owners.map((owner, index) => {
                            return (
                                <DRecord rerender record={props.record.owners[index]}>
                                    <Paper className={classes.box}>
                                        <Typography variant="h6" color="primary" className={styles.subheadingText} gutterBottom>
                                            Contact  
                                            {index !== 0 &&
                                                <Button onClick={() => removeOwner(index)}
                                                    style={{ marginLeft: '4px', height:'30px', width:'30px', float:'right', padding:0 }}
                                                    variant="outlined" color="primary" size="small"><DeleteForeverIcon/>
                                                </Button>
                                            }
                                        </Typography>
                                        <Grid container spacing={3}>
                                            {/* Owner Contact Info */}
                                            <Grid item lg={12}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={6}>
                                                        <DInput name="firstName" label="First Name" required />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <DInput name="lastName" label="Last Name" required />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <DInput name="title" label="Title" required />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <DInput name="email" label="Email Address" required />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <DInput
                                                            required
                                                            format="(###) ###-####"
                                                            fieldType="phonenumber"
                                                            type="tel"
                                                            name="mobilePhone"
                                                            pattern="[0-9]*"
                                                            margin="normal" 
                                                            label="Phone Number"
                                                            InputLabelProps={{ shrink: true }}
                                                            fullWidth={true}
                                                            // inputProps={{ maxLength: 14 }}
                                                            style={{ paddingTop: 0, marginTop: '0px' }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={4}>
                                                        <TextField
                                                            id="ssn-field"
                                                            placeholder=""
                                                            label="Social Security Number"
                                                            type="tel"
                                                            pattern="[0-9]*"
                                                            inputProps={{ maxLength: 11 }}
                                                            required
                                                            fullWidth={true} 
                                                            onFocus={() => clearSsn(index)}
                                                            InputLabelProps={{ shrink: true }}
                                                            value={formattedSSN[index]}
                                                            onChange={(event) => handleSetSSN(event, index)}>
                                                        </TextField>
                                                        <DInput name="ssn" validateId="ssn-field" overrideValue={encryptedSSN[index]} required style={{ display: 'none' }}></DInput>
                                                        <DInput name="ssnLast4" overrideValue={ssnMasked[index]} required style={{ display: 'none' }}></DInput>
                                                    </Grid>
                                                    <Grid item xs={6} sm={4}>
                                                        <DInput name="dateOfBirth" InputLabelProps={{ shrink: true }} label="Date of Birth" fieldType="date" required />

                                                        {/* {moment(dateValue).format('DD/MM/YYYY')} */}
                                                    </Grid>
													<Grid item xs={6} sm={4}>
                                                        <DInput name="ownershipPercent" label="Ownership Percentage" type="number" max={100} required InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} />
                                                    </Grid>
                                                    {/* Location Infos */}
                                                    <Grid item lg={12}>
                                                        <Grid container spacing={3}>
                                                        <DRecord record={props.record.owners[index]}></DRecord>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h6" color="primary" className={styles.subheadingText} gutterBottom>
                                                                    Address
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                <DInput name="addressInfo.address" label="Address" required />
                                                            </Grid>
                                                            <Grid item xs={12} sm={5}>
                                                                <DInput name="addressInfo.city" label="City" required />
                                                            </Grid>
                                                            <Grid item xs={6} sm={4} alignItems="flex-end">
                                                            <FormControl style={{ width: '100%' }}>
                                                                <DInput name="addressInfo.state" label="State" required fieldType="Select">
                                                                    <MenuItem disabled value=""><em>State</em></MenuItem>
                                                                    {states.map((state) => <MenuItem value={state.value}>{state.label}</MenuItem>)}
                                                                </DInput>
                                                            </FormControl>
                                                            
                                                            </Grid>
                                                            <Grid item xs={6} sm={3}>
                                                                <DInput type="tel" name="addressInfo.zip" label="Zip" required format='#####' errorIf={(value)=> {return !(/^\d{5}$/.test(value))}}/>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} style={{textAlign:'center'}}>
                                                        {index == (record.owners.length - 1) &&
                                                            <Button onClick={() => addOwner()}
                                                                variant="contained" size="small" style={{paddingTop:'4px'}}>
                                                                    <AddIcon style={{ marginLeft: '-6px' }} />
                                                                    Add Additional Owner
                                                            </Button>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </DRecord>
                            )
                        })}
                    </Grid>
                </DForm2>
            }
        </>
    );
};

export default withStyles(styles, { withTheme: true })(Dash);

const states = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' }
];