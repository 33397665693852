import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import log from 'loglevel';
import _ from 'lodash';
import {
	Grid,
	Button,
	Typography,
	FormControl,
	MenuItem,
	Modal,
	Paper,
	InputLabel,
    InputAdornment,
} from '@material-ui/core';
import SelectMui from '@material-ui/core/Select';
import DForm2 from '../../../components/dash/DForm2';
import DRecord from '../../../components/dash/DRecord';
import DInput from '../../../components/dash/DInput';
import DSwitch from '../../../components/dash/DSwitch';

const styles = (theme) => ({
	root: {
		height: '100%',
        width: '100%',
	},
	restaurantFieldInputItem: {
		width: '100%',
		// marginTop: '15px'
	},
	muiSelect: {
		marginBottom: '15px',
	},
	subheadingText: {
		fontSize: '16px',
	},
	box: {
		backgroundColor: 'var(--white)',
        border: '1px solid #EAEAEA',
        boxShadow: '0 0 10px 5px rgba(231,231,231,0.50)',
        padding: 30,
        marginBottom: 20,
        borderRadius: 6,
	},
});

const Dash = (props) => {
	const { classes, incrementLocationCount } = props;
    const returnPolicies = ["All Sales Final", "15 Days", "30 Days", "60 Days", "90 Days"]
	return (
		<DForm2 name="locationInfo" record={props.record.salesInfo}>
            <Grid container>
				<Grid item xs={12} sm={12} md={12}>
					<Typography variant="subtitle1" color="primary" gutterBottom>
						Please provide details of your business
					</Typography>
				</Grid>
                <Paper className={classes.box}>
                    <Typography variant="h6" color="primary" className={styles.subheadingText} gutterBottom>
                        Sales Info 
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={4}>
                            <DInput InputProps={{margin:'dense', startAdornment: <InputAdornment position="start">$</InputAdornment>}} type="number" name="annualVolumeEstimate" type="number" label="Annual Volume Est. (Ex: 1,000,000)" required />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <DInput InputProps={{margin:'dense', startAdornment: <InputAdornment position="start">$</InputAdornment>}} type="number" name="averageTransactionAmount" type="number" label="Average Transaction Amount" required/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <DInput InputProps={{margin:'dense', startAdornment: <InputAdornment position="start">$</InputAdornment>}} type="number" name="maximumTransactionAmount" type="number" label="Highest Transaction Amount" required />
                        </Grid>
						<Grid item xs={12}>
							<Typography variant="h6" color="primary" className={styles.subheadingText} gutterBottom>
								% of Sales 
							</Typography>
						</Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <DInput type="number" name="inPersonPercentOfSales" max={100} InputProps={{margin:'dense', endAdornment:<InputAdornment position="end">%</InputAdornment>}} label="In Person" />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <DInput type="number" name="telephonePercentOfSales" max={100} InputProps={{margin:'dense', endAdornment:<InputAdornment position="end">%</InputAdornment>}} label="Phone" required />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <DInput type="number" name="onlinePercentOfSales" max={100} InputProps={{margin:'dense', endAdornment:<InputAdornment position="end">%</InputAdornment>}} label="Online" required />
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                            <DInput name="productsServicesSold" label="Products/Services Sold" required />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                                <FormControl style={{ width: '100%' }}>
                                    <DInput name="returnPolicy" label="Return Policy" required fieldType="Select">
                                        <MenuItem disabled value=""><em>Return Policy</em></MenuItem>
                                        {returnPolicies.map((type) => <MenuItem value={type}>{type}</MenuItem>)}
                                    </DInput>
                                </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
		</DForm2>
	);
};

export default withStyles(styles, { withTheme: true })(Dash);
