import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, Paper, InputLabel } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
	AddCircleOutline, CheckBox,
} from '@material-ui/icons';
import { Button, TextField, Checkbox, IconButton} from '@material-ui/core';
import moment from 'moment'
import CheckDetails from "./components/CheckDetails";
// import getTransactionListApi from '../actions/getTransactionListApi'
import log from 'loglevel'
import { withRouter } from 'react-router-dom';
import StopIcon from '@material-ui/icons/Stop';

import ReactTable from "react-table";
import "react-table/react-table.css";

const styles = (theme) => ({
    root: {
    },
	addRequestIcon: {
		float: 'left',
		marginTop: 5,
		marginRight: 5,
		width: '20px',
		height: '20px'
	},
	addRequestButton: {
		float: 'right',
		marginLeft: 20,
		marginRight: 20,
		marginTop: 10,
		marginBottom: 10,
		width: 200,
		height: 45
	},
})

const TransactionListing = (props) => {
    const { classes } = props;
    
    const pickedUp = props.checks ? true : false


    const [transactions, setTransactions] = React.useState([])
    const [transactionsFiltered, setTransactionsFiltered] = React.useState([])
    const [restaurant] = global.get('restaurant')
    const [checks] = global.get('checks')
    const [textToPayEnabled] = global.get('textToPayEnabled')
    const [hasPosIntegration] = global.get('hasPosIntegration')
    const [dialogContent, setDialogContent] = useState()
    const [includeClosed, setIncludeClosed] = useState(true)
    const [includeUnpaid, setIncludeUnpaid] = useState(true)
    const [callAheadFilter, setCallAheadFilter] = useState(!!props.callAhead)


    const closeContent = () => {
        setDialogContent(null)
    }

    const showCheckDetails = (check) => {
        setDialogContent(
            <CheckDetails check={check} close={closeContent} />
        )
    }

	useEffect(() => {
        if (checks) {
            // log.info('checks ' + JSON.stringify(checks))
            setTransactions(formatTransactions(checks))
        }
        // setFilteredData()
        global.set({headerTitle: 'TRANSACTION LISTING'})
    }, []);
    

    
    useEffect(() => {
        setFilteredData()
    }, [includeClosed, includeUnpaid, transactions, restaurant]);

    useEffect(() => {
        if (checks) {
            setTransactions(formatTransactions(checks))
        }
    }, [checks]);

    
    
    const setFilteredData = () => {
        setTransactionsFiltered(
            transactions.filter((rec) => {
                if (rec.deleted) {
                    return false
                }
                if (!includeClosed && (rec.origCheck.closed)) {
                    return false
                }
                if (!includeUnpaid && (rec.paidAt == 'N/A')) { 
                    return false
                }
                if (callAheadFilter) {
                    if ( !rec.origCheck.pickupDetails || !rec.origCheck.pickupDetails.phoneNumber ){ //only show call ahead orders
                        return false
                    } else if (rec.origCheck.pickupDetails.pickedUpAt || (moment(rec.origCheck.createdAt) < moment().subtract(12, 'hours'))) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    if ((rec.origCheck.paidDashNow == 0 && !restaurant.backOffice)) { //if the callAhead filter is not set, only return paid dash now checks
                        return false
                    } else if (rec.origCheck.status == 'completed' || (moment(rec.origCheck.createdAt) < moment().subtract(12, 'hours'))) {
                        return true
                    } else {
                        return false
                    }
                }
            }
        ))
    }


    const formatTransactions = (transactions) => {
        return transactions.map((transaction) => {
            let checkAmount = (transaction.totals && transaction.totals.total) ? Number(((transaction.totals.total)/100)).toFixed(2) : 0
            let tip = (transaction.totals && transaction.totals.tipsDashNow) ? Number(((transaction.totals.tipsDashNow)/100)).toFixed(2) : 0
            let loyalty = (transaction.totals && transaction.totals.loyaltyRewardsAmount) ? Number(((transaction.totals.loyaltyRewardsAmount)/100)).toFixed(2) : 0
            let paidDashNow = (transaction.totals && transaction.totals.paidDashNow) ? Number(((transaction.totals.paidDashNow) / 100)).toFixed(2) : 0
            let loyaltyTaxAmountRemoved = (transaction.totals && transaction.totals.loyaltyTaxAmountRemoved) ? Number(((transaction.totals.loyaltyTaxAmountRemoved) / 100).toFixed(2)) : 0
            let total = (Number(paidDashNow) + Number(tip) - Number(loyalty) - Number(loyaltyTaxAmountRemoved)).toFixed(2)
            let tmpPayment
            let lastPaymentTime 
            if (transaction.payments) {
                tmpPayment = transaction.payments.sort((a, b) => moment(a.createdAt) - moment(b.createdAt))[0]
                if (tmpPayment) {
                    lastPaymentTime = tmpPayment.createdAt
                }
            } 

            let paidDashNowFlag = <center key={paidDashNow > 0}><Checkbox checked={paidDashNow > 0} style={{padding: 0}} color="primary" /></center> 
                
            let paidAt = lastPaymentTime ? moment(lastPaymentTime).fromNow() : 'N/A'
            return {
                // id: transaction._id,
                checkNumber: transaction.checkNumberDisplay ? transaction.checkNumberDisplay : transaction.checkNumber,
                orderNumber: transaction.orderNumber,
                tableNumber: transaction.tableNumber,
                phoneNumber: transaction.pickupDetails.phoneNumber,
                guestName: transaction.pickupDetails.guestName != 'undefined' ? transaction.pickupDetails.guestName : '',
                checkAmount: `$${checkAmount}`, 
                tip: `$${tip}`,
                loyalty: `$${loyalty}`,
                total: `$${total}`,
                paidAt,
                paidDashNow,
                serverIdentifier: transaction.serverIdentifier,
                paidDashNowFlag,
                origCheck: transaction,
                createdAt: moment(transaction.createdAt).valueOf()
                
            };
		});
	};

	return (
        <div className={classes.listing} style={{ width: '100%' }}>
			<Grid container style={{width:'100%'}}>
                <Paper style={{ width: '100%' }} className={classes.rootTable}>
                    <div>
                        <Grid container>
                            {/* {{!(props.callAhead == true) &&
                                <>
                                <Grid item lg={8}>
                                    <Typography style={{ margin: 10 }} variant="h5">Dash Now Payments</Typography>
                                </Grid>
                                <Grid container item lg={4} justify="flex-end" style={{ maxHeight: 40, float: 'right' }}>
                                    <InputLabel style={{ marginLeft: 10, marginTop:15 }}>Include Unpaid</InputLabel>
                                    <Checkbox style={{float:'right'}}
                                        color="primary"
                                        value={includeUnpaid}
                                        checked={includeUnpaid}
                                        onChange={(e) => setIncludeUnpaid(!includeUnpaid)}
                                    >
                                    </Checkbox>
                                    { restaurant && restaurant.backOffice && <>
                                        <InputLabel style={{ marginLeft: 10, marginTop: 15 }}>Include Closed</InputLabel>
                                        <Checkbox
                                            color="primary"
                                            value={includeClosed}
                                            checked={includeClosed}
                                            onChange={(e) => setIncludeClosed(!includeClosed)}
                                        >
                                        </Checkbox>
                                    </>}
                                </Grid>
                            </>
                            }} */}
                        </Grid>
                        <ReactTable
                            data={transactionsFiltered}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
                            }
                            defaultSorted={[
                                {
                                    id: "createdAt",
                                    desc: false
                                }
                            ]}
                            columns={[
                                {
                                    Header: "Transactions",
                                    columns: [
                                        {
                                            Header: "Check Number",
                                            accessor: "checkNumber"
                                        },
                                        {
                                            Header: "Order Number",
                                            accessor: "orderNumber",
                                            show: textToPayEnabled && !hasPosIntegration
                                        },
                                        {
                                            Header: "Table Number",
                                            accessor: "tableNumber"
                                        },
                                        {
                                            Header: "Phone Number",
                                            accessor: "phoneNumber"
                                        },
                                        {
                                            Header: "Guest Name",
                                            accessor: "guestName"
                                        },
                                        {
                                            Header: "Server Name",
                                            accessor: "serverIdentifier"
                                        },
                                        {
                                            Header: "Check Amount",
                                            accessor: "checkAmount"
                                        },
                                        {
                                            Header: "Tip",
                                            accessor: "tip"
                                        },
                                        {
                                            Header: "Total",
                                            accessor: "total"

                                        },
                                        {
                                            Header: "Paid",
                                            accessor: "paidAt"
                                        },
                                        {
                                            Header: "Dash Now",
                                            accessor: "paidDashNowFlag",
                                            width: 100,
                                            sortMethod: (a,b) => {
                                                if (a.key == b.key) {
                                                    return 0
                                                }
                                                return (a.key > b.key) ? 1 : -1
                                            },
                                            show: callAheadFilter // TODO remove this if we go back to showing all transactions
                                        },
                                    ]
                                },
                            ]}
                            getTrProps={(state, rowInfo) => {
                                if (rowInfo && rowInfo.row) {
                                    // console.log('TYLER ' + JSON.stringify(rowInfo))
                                    // console.log('TYLER 1 ' + JSON.stringify(rowInfo.row))
                                    return {
                                        onClick: (e) => {
                                            showCheckDetails(rowInfo.row._original.origCheck)
                                        },
                                        // style: {
                                        //     background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                                        //     color: rowInfo.index === this.state.selected ? 'white' : 'black'
                                        // }
                                    }
                                } else {
                                    return {}
                                }
                            }}
                            defaultPageSize={10}
                            className="-striped -highlight"
                        />
                        <br />
                    </div>
                </Paper>
                {/* <div style={{ textAlign: "center" }}>
                    <em>Tip: Hold shift when sorting to multi-sort!</em>
                </div>; */}
                 <Grid item lg={12} >
                    {dialogContent}
                </Grid>
            </Grid>
        </div>
	);
};

TransactionListing.propTypes = {
	classes: PropTypes.shape({}).isRequired
};

export default withRouter(withStyles(styles, { withTheme: true })(TransactionListing));