import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function TimePickers(props) {
  const classes = useStyles();

  const setTime = (e) => {
    props.updateState(e.target.value)
  }
    
  return (
    <form className={classes.container} noValidate> 
      <TextField
        onChange={setTime}
        id="time"
        label={props.label}
        type="time"
        defaultValue={props.default || "00:00"}
        disabled={props.disabled}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
      />
    </form>
  );
}